import { useRef } from 'react'

import { AgGridReact } from 'ag-grid-react'
import uniqBy from 'lodash/uniqBy'
import { DiagnosisCodeFragment } from 'types/graphql'

import AutomationNotification from 'src/components/molecules/AutomationNotification/AutomationNotification'
import Table from 'src/components/molecules/Table/Table'

import { getColumnDefs, RowData } from './columnDefs'
import useBillingSidepanels from './useBillingSidepanels'

interface BillingTableProps {
  encounterId: string
  chargeItems: RowData[]
  primaryDiagnosis: DiagnosisCodeFragment
  includeOrderColumn?: boolean
  testId?: string
  isEditable?: boolean
}

const BillingTable: React.FC<BillingTableProps> = ({
  encounterId,
  chargeItems,
  primaryDiagnosis,
  includeOrderColumn,
  testId,
  isEditable,
}) => {
  const gridRef = useRef<AgGridReact>()
  const { editBillingCode } = useBillingSidepanels({ encounterId })

  const columnDefs = getColumnDefs(primaryDiagnosis, editBillingCode)
  const columnsToUse = columnDefs
    .filter((columnDef) => includeOrderColumn || columnDef.colId !== 'order')
    .filter((columnDef) => isEditable || columnDef.colId !== 'actions')

  // Sometimes we generate two charge items per rule (like for vaccine administration first and additional components)
  // we only want to show one alert in this case.
  const automationRulesApplied = uniqBy(
    chargeItems.flatMap((chargeItem) => chargeItem.automationActions),
    (action) => action.ruleId
  )

  return (
    <>
      {automationRulesApplied.map((action) => {
        return (
          <AutomationNotification
            testId={`${testId}-alerts`}
            key={action.ruleId}
            action={action}
          />
        )
      })}

      <Table<RowData>
        testId={testId}
        rowData={chargeItems}
        innerRef={gridRef}
        domLayout="autoHeight"
        noMinHeight
        pagination={false}
        columnDefs={columnsToUse}
        defaultColDef={{
          cellStyle: { overflow: 'hidden', display: 'flex' },
        }}
      />
    </>
  )
}

export default BillingTable
