import { PlusIcon } from '@heroicons/react/20/solid'
import type { CreateContactOrganizationInput } from 'types/graphql'

import { useFieldArray, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import InheritedAddressField from 'src/components/Address/InheritedAddressField/InheritedAddressField'
import OptionalAddressField from 'src/components/Address/OptionalAddressField/OptionalAddressField'
import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import Button from 'src/components/atoms/Button/Button'
import ContactOrganizationTypeSelectField from 'src/components/atoms/ContactOrganizationTypeSelectField/ContactOrganizationTypeSelectField'
import InputField from 'src/components/atoms/InputField/InputField'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const CREATE_CONTACT_ORGANIZATION_MUTATION = gql`
  mutation CreateContactOrganization($input: CreateContactOrganizationInput!) {
    createContactOrganization(input: $input) {
      id
    }
  }
`

const CreateContactOrganization = () => {
  const { closeSidePanel } = useSidepanel()
  const [
    createContactOrganization,
    {
      loading: creatingContactOrganization,
      error: createContactOrganizationError,
    },
  ] = useMutation(CREATE_CONTACT_ORGANIZATION_MUTATION, {
    onCompleted: () => {
      toast.success('Organization created')
      closeSidePanel()
    },
    refetchQueries: ['ContactOrganizationsQuery'],
  })
  const formMethods = useForm<CreateContactOrganizationInput>({
    shouldUnregister: true,
  })
  const { fields, append, remove } = useFieldArray({
    control: formMethods.control,
    name: 'contacts',
  })

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Add Contact',
        submitting: creatingContactOrganization,
      }}
      formMethods={formMethods}
      error={createContactOrganizationError}
      onSubmit={async (value) => {
        if (
          value.primaryContact?.address &&
          Object.entries(value.primaryContact.address).every(
            ([key, value]) => value === null || key === 'country'
          )
        ) {
          delete value.primaryContact.address
        }
        if (Object.values(value.primaryContact).every((v) => v === null)) {
          delete value.primaryContact
        }
        createContactOrganization({ variables: { input: value } })
      }}
    >
      <FormInputList
        items={[
          {
            name: 'name',
            label: 'Organization name',
            required: true,
            formInputComponent: InputField,
          },
          {
            name: 'type',
            label: 'Organization type',
            required: true,
            formInputComponent: ContactOrganizationTypeSelectField,
          },
          {
            name: 'hipaaCovered',
            label: 'Is this contact a HIPAA covered entity?',
            formInputComponent: BooleanSelectField,
            required: true,
            inputProps: {
              trueDisplay: 'Yes',
              falseDisplay: 'No',
            },
          },
          {
            name: 'primaryContact.address',
            label: 'Address',
            formInputComponent: OptionalAddressField,
          },
          {
            name: 'primaryContact.faxNumber',
            label: 'Fax number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'primaryContact.emailAddress',
            label: 'Email address',
            formInputComponent: InputField,
          },
          {
            name: 'primaryContact.mobileNumber',
            label: 'Mobile number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'primaryContact.homeNumber',
            label: 'Home number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'primaryContact.workNumber',
            label: 'Work number',
            formInputComponent: PhoneInputField,
          },
        ]}
      />
      {fields.map((item, index) => {
        const name = formMethods.watch(`contacts.${index}.name`)
        return (
          <StackView key={item.id} space={75} divider>
            <StackView
              direction="row"
              className="mt-4"
              justifyContent="between"
            >
              <Typography textStyle="heading">
                {name || 'Organization Contact Details'}
              </Typography>
              <Button buttonStyle="danger" onClick={() => remove(index)}>
                remove
              </Button>
            </StackView>
            <FormInputList
              items={[
                {
                  name: `contacts.${index}.name`,
                  label: 'Contact name',
                  required: true,
                  formInputComponent: InputField,
                },
                {
                  name: `contacts.${index}.address`,
                  label: 'Address',
                  formInputComponent: InheritedAddressField,
                  inputProps: {
                    useInheritedAddressName: `contacts.${index}.useOrganizationAddress`,
                    useInheritedAddressDescription:
                      'Same as organization address',
                  },
                },
                {
                  name: `contacts.${index}.faxNumber`,
                  label: 'Fax number',
                  formInputComponent: PhoneInputField,
                },
                {
                  name: `contacts.${index}.emailAddress`,
                  label: 'Email address',
                  formInputComponent: InputField,
                },
                {
                  name: `contacts.${index}.mobileNumber`,
                  label: 'Mobile number',
                  formInputComponent: PhoneInputField,
                },
                {
                  name: `contacts.${index}.homeNumber`,
                  label: 'Home number',
                  formInputComponent: PhoneInputField,
                },
                {
                  name: `contacts.${index}.workNumber`,
                  label: 'Work number',
                  formInputComponent: PhoneInputField,
                },
              ]}
            />
          </StackView>
        )
      })}
      <div className="my-core-space-75">
        <Button
          icon={PlusIcon}
          buttonStyle="secondary"
          text="Org Contact"
          onClick={() => append({ name: null, useOrganizationAddress: false })}
          className="w-full"
        />
      </div>
    </SidepanelForm>
  )
}

export default CreateContactOrganization
