import { useState } from 'react'

import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { formatDollarsToCents, formatMoneyInCents } from 'common/utils'
import { compact } from 'lodash'
import {
  PatientBillingEncounters,
  PaymentLineItemType,
  PaymentMethodType,
} from 'types/graphql'

import { useFieldArray, useForm, useFormContext } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box'
import Button, { Submit } from 'src/components/atoms/Button'
import Divider from 'src/components/atoms/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown'
import InputField from 'src/components/atoms/InputField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import PaymentLineItemSelectField from 'src/components/atoms/PaymentLineItemSelectField.tsx/PaymentLineItemSelectField'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField'
import Typography from 'src/components/atoms/Typography'
import BillingEncounterSelectField, {
  getEncounter,
  getEncounterType,
} from 'src/components/molecules/BillingEncounterSelectField/BillingEncounterSelectField'
import FormInputList from 'src/components/molecules/FormInputList'
import { InputFieldWithUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import TilledCardDetails, {
  useTilledCardDetailsRef,
} from 'src/components/TilledCardDetails/TilledCardDetails'
import {
  useCreateManualTilledPatientPaymentMutation,
  useCreatePatientPaymentMutation,
  useCreatePatientTerminalPaymentMutation,
  usePatientBillingEncountersQuery,
  usePaymentTerminalsQuery,
} from 'src/hooks/usePatientPayments/usePatientPayments'
import { useLocalSettings } from 'src/providers/context/LocalSettingsContext'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

type PaymentFormProps = {
  paymentCollectionMethod: PaymentMethodType
  paymentLineItems: {
    amount: number
    lineItem: PaymentLineItemType
    lineItemId: string
  }[]
  terminalId: string
  cardHolder?: string
  zip?: string
  comments?: string
}

const PaymentLineItemFormInputList = ({
  patientId,
  billingEncounters,
  appointmentsWithoutBillingEncounters,
  loading,
}: {
  patientId: string
  billingEncounters: PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters']
  appointmentsWithoutBillingEncounters: PatientBillingEncounters['patient']['appointments']
  loading: boolean
}) => {
  const { control, watch, setValue } = useFormContext()
  const { fields, append, remove } = useFieldArray({
    name: 'paymentLineItems',
    control: control,
  })

  const paymentLineItems = watch('paymentLineItems')

  if (loading) return <LoadingSpinner />

  return (
    <StackView space={100}>
      {fields.map((field, index) => {
        const fieldName = `paymentLineItems.${index}`

        const lineItemId = paymentLineItems[index].lineItemId

        const encounter = getEncounter({
          encounters: billingEncounters,
          lineItemId,
        })

        const encounterBalanceCents =
          encounter?.claims?.reduce(
            (acc, claim) => acc + claim.remainingPatientResponsibilityCents,
            0
          ) ?? 0

        const encounterType = getEncounterType(encounter)

        return (
          <FormInputList
            key={field.id}
            title={index > 0 ? `Additional charge details` : 'Charge details'}
            subtitle="Please fill out all of the required charge details."
            divider={false}
            action={
              fields.length > 1 && (
                <Button
                  buttonStyle="secondary"
                  text="Remove"
                  icon={XMarkIcon}
                  onClick={() => remove(index)}
                />
              )
            }
            items={[
              {
                name: `${fieldName}.lineItemId`,
                label: 'Charge',
                message:
                  'Select one charge that you would like to record a payment for.',
                required: true,
                formInputComponent: BillingEncounterSelectField,
                inputProps: {
                  patientId,
                  filteredEncounters: billingEncounters,
                  filteredAppointments: appointmentsWithoutBillingEncounters,
                  onSelection: () => {
                    const encounterAmountOwedDollars = (
                      encounterBalanceCents > 0
                        ? encounterBalanceCents / 100
                        : 0
                    ).toFixed(2)

                    setValue(
                      `${fieldName}.amount`,
                      encounterAmountOwedDollars,
                      {
                        shouldDirty: true,
                      }
                    )
                  },
                },
                direction: 'col',
              },
              {
                name: `${fieldName}.amount`,
                label: 'Payment amount',
                formInputComponent: InputFieldWithUnit,
                required: true,
                hide: !lineItemId,
                message:
                  'The amount above is automatically pre-filled with the amount owing for the charge selected. This amount can’t be modified for paying the remaining balance of a payment plan.',
                inputProps: {
                  unit: 'USD',
                  type: 'number',
                  min: 0.01,
                  step: 0.01,
                  placeholder: '0.00',
                },
                direction: 'col',
              },
              {
                name: `${fieldName}.lineItem`,
                label: 'Payment type',
                formInputComponent: PaymentLineItemSelectField,
                required: encounterType === 'UNSUBMITTED_CLAIM',
                hide: !lineItemId || encounterType !== 'UNSUBMITTED_CLAIM',
                inputProps: {
                  encounterType,
                },
                direction: 'col',
              },
            ]}
          />
        )
      })}

      <Button
        buttonStyle="secondary"
        icon={PlusIcon}
        text="Add charge"
        onClick={() =>
          append({ lineItemId: null, amount: 0, lineItem: 'CO_PAY' })
        }
      />

      <Divider />

      <Box verticalPadding={75} horizontalPadding={100} border rounded>
        <StackView direction="row" space={100} justifyContent="between">
          <Typography
            textStyle="interface-strong-s"
            color="text-base-color-fg-muted"
          >
            Total
          </Typography>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
            testId="payment-total"
          >
            {formatMoneyInCents(
              formatDollarsToCents(
                paymentLineItems.reduce(
                  (sum: number, field: { amount: number }) =>
                    sum + field.amount,
                  0
                )
              )
            )}
          </Typography>
        </StackView>
      </Box>
    </StackView>
  )
}

const SidepanelMakePayment = ({ patientId }: { patientId: string }) => {
  const { currentUser } = useEmrAuth()
  const { createPayment, loading: creatingPayment } =
    useCreatePatientPaymentMutation()
  const { createTerminalPayment, loading: creatingTerminalPayment } =
    useCreatePatientTerminalPaymentMutation()
  const { createManualTilledPayment, loading: creatingManualTilledPayment } =
    useCreateManualTilledPatientPaymentMutation()
  const {
    billingEncounters,
    appointmentsWithoutBillingEncounters,
    loading: loadingBillingEncounters,
  } = usePatientBillingEncountersQuery({ patientId })

  const { data: paymentTerminals } = usePaymentTerminalsQuery()
  const { lastUsedTerminalId, saveSettings } = useLocalSettings()
  const { closeSidePanel, afterSidepanelFormSubmit } = useSidepanel()

  const [creatingPaymentMethod, setCreatingPaymentMethod] =
    useState<boolean>(false)

  const tilledCardDetailsRef = useTilledCardDetailsRef()

  const formMethods = useForm<PaymentFormProps>({
    defaultValues: {
      paymentCollectionMethod: currentUser.featureFlags.includes(
        'TERMINAL_PAYMENTS'
      )
        ? 'TERMINAL'
        : 'CASH',
      paymentLineItems: [{ lineItemId: null, amount: 0, lineItem: 'CO_PAY' }],
      terminalId: lastUsedTerminalId,
      cardHolder: null,
      zip: null,
    },
  })

  const paymentCollectionMethod = formMethods.watch('paymentCollectionMethod')

  const onSubmit = async (data: PaymentFormProps) => {
    const { paymentLineItems, paymentCollectionMethod, comments } = data

    const paymentLineItemsToSend = paymentLineItems.map((payment) => {
      const encounter = getEncounter({
        encounters: billingEncounters,
        lineItemId: payment.lineItemId,
      })

      const encounterType = getEncounterType(encounter)

      return {
        appointmentId:
          encounterType === 'UNSUBMITTED_CLAIM'
            ? payment.lineItemId
            : undefined,
        directPatientChargeEncounterId:
          encounterType === 'DIRECT_PATIENT_CHARGE_ENCOUNTER'
            ? payment.lineItemId
            : undefined,
        appointmentEncounterId:
          encounterType === 'APPOINTMENT_ENCOUNTER'
            ? payment.lineItemId
            : undefined,
        lineItemType:
          encounterType === 'APPOINTMENT_ENCOUNTER'
            ? 'OUTSTANDING_BALANCE'
            : encounterType === 'DIRECT_PATIENT_CHARGE_ENCOUNTER'
              ? 'PATIENT_BILL'
              : payment.lineItem,
        amountCents: formatDollarsToCents(payment.amount),
      }
    })

    if (paymentCollectionMethod === 'TERMINAL') {
      await createTerminalPayment({
        variables: {
          input: {
            patientId,
            paymentLineItems: paymentLineItemsToSend,
            terminalId: data.terminalId,
            comments,
          },
        },
        onCompleted: (data) => {
          afterSidepanelFormSubmit?.(data['createPatientTerminalPayment'].id)
          closeSidePanel()
        },
        onError: (error) => {
          toast.error(error.message)
        },
      })
    } else if (paymentCollectionMethod === 'TILLED_MANUAL') {
      setCreatingPaymentMethod(true)
      const customerPaymentMethod =
        await tilledCardDetailsRef?.current?.createPaymentMethod({
          cardHolder: data.cardHolder,
          zip: data.zip,
        })
      setCreatingPaymentMethod(false)
      if (!customerPaymentMethod.ok) return

      await createManualTilledPayment({
        variables: {
          input: {
            patientId,
            paymentLineItems: paymentLineItemsToSend,
            paymentMethodId: customerPaymentMethod.id,
            comments,
          },
        },
        onCompleted: (data) => {
          afterSidepanelFormSubmit?.(
            data['createManualTilledPatientPayment'].id
          )
          closeSidePanel()
        },
        onError: (error) => {
          toast.error(error.message)
        },
      })
    } else {
      await createPayment({
        variables: {
          input: {
            patientId,
            paymentLineItems: paymentLineItemsToSend,
            paymentMethodType: paymentCollectionMethod,
            comments,
          },
        },
        onCompleted: () => {
          toast.success(`Payment submitted`)
          closeSidePanel()
        },
        onError: (error) => {
          toast.error(error.message)
        },
      })
    }
  }

  if (!patientId) return <SidepanelNotAvailable />

  return (
    <SidepanelPage
      testId="sidepanel-payment"
      header="Record a payment"
      description="Record and/or process a payment for the patient’s balance."
    >
      <SidepanelForm
        onSubmit={onSubmit}
        formMethods={formMethods}
        footerElement={
          <StackView space={50} direction="row" justifyContent="end">
            <Button
              testId="button-footer-secondary-btn"
              buttonStyle="ghost"
              onClick={closeSidePanel}
            >
              Cancel
            </Button>
            <Submit
              testId="button-footer-submit-btn"
              buttonStyle="primary"
              loading={
                creatingPayment ||
                creatingTerminalPayment ||
                creatingPaymentMethod ||
                creatingManualTilledPayment
              }
              disabled={!formMethods.formState.isValid}
            >
              Record payment
            </Submit>
          </StackView>
        }
      >
        <StackView space={100} className="py-core-space-100">
          <FormInputList
            title="Payment methods"
            subtitle="Specify how the caregiver or patient is paying."
            divider={false}
            items={[
              {
                name: 'paymentCollectionMethod',
                label: 'Payment method',
                subtitle:
                  'Select a payment processing method for payments made in person, online away from the practice.',
                required: true,
                direction: 'col',
                formInputComponent: MultiRadioButtonField,
                inputProps: {
                  values: compact([
                    currentUser.featureFlags.includes('TERMINAL_PAYMENTS') && {
                      value: 'TERMINAL',
                      label: 'Credit or debit card terminal',
                      description:
                        'Process and record a payment using the terminal to tap, swipe, or insert a credit or debit card.',
                    },
                    currentUser.featureFlags.includes(
                      'TILLED_MANUAL_ENTRY_PAYMENTS'
                    ) &&
                      currentUser.tilledAccountId && {
                        value: 'TILLED_MANUAL',
                        label: 'Credit or debit card manual entry',
                        description:
                          'Process and record a payment with manually entered card details.',
                      },
                    {
                      value: 'MANUAL',
                      label: 'Separately completed payment',
                      description:
                        'Record a payment previously completed outside of Develo.',
                    },
                    {
                      value: 'CASH',
                      label: 'Cash',
                      description:
                        'In person, dropped off, or mailed in cash payment.',
                    },
                    {
                      value: 'CHECK',
                      label: 'Check',
                      description:
                        'In person, dropped off, or mailed in check payment.',
                    },
                  ]),
                  radioStyle: 'card',
                  direction: 'col',
                  radioPosition: 'right',
                },
              },
              {
                name: 'terminalId',
                label: 'Which terminal is in use?',
                required: true,
                direction: 'col',
                formInputComponent: DropdownField,
                hide: paymentCollectionMethod !== 'TERMINAL',
                message:
                  'Select the terminal that you will use to process the payment.',
                inputProps: {
                  options:
                    paymentTerminals?.getPaymentTerminals.map((terminal) => ({
                      value: terminal.id,
                      name: terminal.description,
                    })) ?? [],
                  validation: {
                    shouldUnregister: true,
                  },
                  onSelection: (terminal) => {
                    saveSettings({ lastUsedTerminalId: terminal?.value })
                  },
                },
              },
            ]}
          />

          {paymentCollectionMethod === 'TILLED_MANUAL' && (
            <StackView space={100}>
              <FormInputList
                divider={false}
                items={[
                  {
                    name: 'cardHolder',
                    label: 'Cardholder full name',
                    required: paymentCollectionMethod === 'TILLED_MANUAL',
                    direction: 'col',
                    formInputComponent: InputField,
                  },
                ]}
              />

              <TilledCardDetails
                tilledCardDetailsRef={tilledCardDetailsRef}
                tilledAccountId={currentUser?.tilledAccountId}
              />

              <FormInputList
                items={[
                  {
                    name: 'zip',
                    label: 'Billing zip code',
                    required: paymentCollectionMethod === 'TILLED_MANUAL',
                    direction: 'col',
                    formInputComponent: InputField,
                  },
                ]}
              />
            </StackView>
          )}

          <Divider />

          <PaymentLineItemFormInputList
            billingEncounters={billingEncounters}
            appointmentsWithoutBillingEncounters={
              appointmentsWithoutBillingEncounters
            }
            patientId={patientId}
            loading={loadingBillingEncounters}
          />
          <StackView space={25}>
            <Typography textStyle="title-xs">Comments</Typography>
            <TextAreaField name="comments" />
          </StackView>
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelMakePayment
