import { useRef, useEffect, useState } from 'react'

import { PencilIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import capitalize from 'lodash/capitalize'
import {
  PatientDocumentFragment,
  PatientDiagnosticReportFragment,
  DocumentType,
} from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import EFaxIcon from 'src/components/icons/EFaxIcon/EFaxIcon'
import MultiSelectHoverBar from 'src/components/molecules/MultiSelectHoverBar/MultiSelectHoverBar'
import Table from 'src/components/molecules/Table/Table'
import { sidepanelRoute } from 'src/lib/routes'

import { usePatientDocumentsQuery } from './usePatientDocuments'

const ActionsCellRenderer = ({ data }) => {
  const location = useLocation()
  const params = useParams()
  const canEdit = data.__typename === 'Document'
  const canFax = data.docStatus === 'FINAL'

  let documentType: DocumentType
  switch (data.__typename) {
    case 'DiagnosticReport':
      documentType = 'DIAGNOSTIC_REPORT'
      break
    case 'Document':
      documentType = 'BINARY'
      break
    default:
      // no actions for non-binary or diagnostic report documents
      return null
  }

  return (
    <StackView space={50} direction="row">
      {canFax && (
        <Button
          buttonStyle="secondary"
          icon={EFaxIcon}
          tooltip="E-Fax"
          tooltipPosition="right"
          tooltipPadding={50}
          tooltipMargin={25}
          innerRef={(ref) => {
            if (!ref) return
            ref.onclick = (e) => {
              e.stopPropagation()
              navigate(
                sidepanelRoute(
                  {
                    route: '/send-fax',
                    documents: JSON.stringify([
                      { documentId: data.id, documentType },
                    ]),
                  },
                  location,
                  params
                )
              )
            }
          }}
        />
      )}
      {canEdit && (
        <Button
          testId="patient-document-row-edit-button"
          buttonStyle="secondary"
          icon={PencilIcon}
          tooltip="Edit"
          tooltipPosition="left"
          tooltipPadding={50}
          tooltipMargin={25}
          innerRef={(ref) => {
            if (!ref) return
            ref.onclick = (e) => {
              e.stopPropagation()
              navigate(
                sidepanelRoute(
                  {
                    route:
                      data.docStatus === 'PRELIMINARY'
                        ? data.detailRoute
                        : `/documents/patient-document/${data.id}/edit`,
                  },
                  location,
                  params
                )
              )
            }
          }}
        />
      )}
    </StackView>
  )
}

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'cursor-pointer',
  resizable: true,
  minWidth: 180,
}

const columnDefs: ColDef[] = [
  {
    colId: 'type',
    headerName: 'Type',
    checkboxSelection: true,
    showDisabledCheckboxes: true,
    headerCheckboxSelection: true,
    cellRenderer: ({ data }) => (
      <Typography className="whitespace-normal">{data.type}</Typography>
    ),
    resizable: true,
    autoHeight: true,
    minWidth: 180,
    sortable: true,
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = nodeA.data.type?.toLowerCase()
      const b = nodeB.data.type?.toLowerCase()
      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    getQuickFilterText: ({ data }) => {
      return data.type
    },
  },
  {
    colId: 'subType',
    headerName: 'Sub-type',
    cellRenderer: ({ data }) => (
      <StackView direction="row" space={25} wrap>
        <Typography className="whitespace-normal">{data.subType}</Typography>

        {data.docStatus === 'PRELIMINARY' && (
          <>
            {data.subType && (
              <Typography className="whitespace-normal">-</Typography>
            )}
            <Typography
              className="whitespace-normal"
              fontWeight="bold"
              color="text-base-color-fg-attention"
            >
              DRAFT
            </Typography>
          </>
        )}
      </StackView>
    ),
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = nodeA.data.subType?.toLowerCase()
      const b = nodeB.data.subType?.toLowerCase()
      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    resizable: true,
    autoHeight: true,
    minWidth: 180,
    sortable: true,
    getQuickFilterText: ({ data }) => {
      return data.subType
    },
  },
  {
    colId: 'title',
    headerName: 'Title',
    sortable: true,
    cellRenderer: ({ data }) => (
      <Typography className="whitespace-normal">{data.title}</Typography>
    ),
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = nodeA.data.title.toLowerCase()
      const b = nodeB.data.title.toLowerCase()
      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    resizable: true,
    autoHeight: true,
    minWidth: 180,
    getQuickFilterText: ({ data }) => {
      return data.title
    },
  },
  {
    colId: 'createdBy',
    headerName: 'Created by',
    sortable: true,
    cellRenderer: ({ data }) => {
      if (!data.authoredBy) return
      return (
        <Typography className="whitespace-normal">
          {formatDisplayName(data.authoredBy)}
        </Typography>
      )
    },
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = formatDisplayName(nodeA.data.authoredBy).toLowerCase()
      const b = formatDisplayName(nodeB.data.authoredBy).toLowerCase()
      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    resizable: true,
    autoHeight: true,
    minWidth: 140,
    getQuickFilterText: ({ data }) => {
      if (!data.authoredBy) return
      return formatDisplayName(data.authoredBy)
    },
  },
  {
    headerName: 'Created on',
    cellRenderer: ({ data }) => (
      <Typography>{format(data.createdAt, 'PP')}</Typography>
    ),
    sortable: true,
    resizable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 150,
    sort: 'desc',
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = nodeA.data.createdAt
      const b = nodeB.data.createdAt

      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    getQuickFilterText: ({ data }) => {
      return format(data.createdAt, 'PP')
    },
  },
  {
    headerName: 'Last Modified',
    cellRenderer: ({ data }) =>
      data.updatedAt && <Typography>{format(data.updatedAt, 'PP')}</Typography>,
    sortable: true,
    resizable: false,
    wrapText: true,
    autoHeight: true,
    minWidth: 150,
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = nodeA.data.updatedAt
      const b = nodeB.data.updatedAt

      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    getQuickFilterText: ({ data }) => {
      return format(data.updatedAt, 'PP')
    },
  },
  {
    headerName: '',
    cellRenderer: ActionsCellRenderer,
    autoHeight: true,
    minWidth: 150,
    maxWidth: 150,
  },
]

const convertToRowData = (
  item: PatientDocumentFragment | PatientDiagnosticReportFragment
) => {
  return item.__typename === 'DiagnosticReport'
    ? {
        id: item.id,
        createdAt: new Date(item.issuedAt),
        updatedAt: item?.updatedAt ? new Date(item.updatedAt) : undefined,
        title: item.name ?? item.presentedForm?.title,
        type: 'Clinical result',
        subType: item.category ? capitalize(item.category) : undefined,
        detailRoute: `/documents/diagnostic-report/${item.id}`,
        docStatus: 'FINAL',
        authoredBy: item.reviews?.[item.reviews.length - 1]?.reviewer,
        __typename: item.__typename,
      }
    : {
        id: item.id,
        createdAt: new Date(item.createdAt),
        updatedAt: new Date(item.updatedAt),
        title: item.title,
        type: item.type?.display,
        subType: item.subType?.display,
        detailRoute:
          item.docStatus === 'PRELIMINARY'
            ? `/documents/patient-document/create/${item.id}`
            : `/documents/patient-document/${item.id}`,
        docStatus: item.docStatus,
        authoredBy: item.authoredBy,
        __typename: item.__typename,
      }
}

const DocumentsTable = ({
  patientId,
  searchInput,
}: {
  patientId: string
  searchInput?: string
}) => {
  const gridRef = useRef<AgGridReact>()
  const { documents, diagnosticReports } = usePatientDocumentsQuery(patientId)
  const params = useParams()
  const location = useLocation()

  useEffect(() => {
    gridRef.current?.api?.setQuickFilter(searchInput)
  }, [searchInput])

  const [selected, setSelected] = useState([])

  const rowData = [...(documents ?? []), ...(diagnosticReports ?? [])].map(
    convertToRowData
  )

  return (
    <StackView space={100} className="relative h-full">
      <Table<(typeof rowData)[number]>
        testId="documents-table"
        innerRef={gridRef}
        rowSelection="multiple"
        gridOptions={{
          getRowId: ({ data }) => data.id,
          onRowClicked: ({ data }) => {
            navigate(
              sidepanelRoute(
                {
                  route: data.detailRoute,
                  width: data.docStatus === 'PRELIMINARY' ? 'small' : 'medium',
                  overlay: true,
                },
                location,
                params
              )
            )
          },
          suppressMovableColumns: true,
          rowSelection: 'multiple',
          suppressRowClickSelection: true,
          suppressCellFocus: true,
        }}
        rowData={rowData}
        onSelectionChanged={({ api }) => {
          setSelected(
            api.getSelectedRows().map((x) => ({
              id: x.id,
              documentId: x.id,
              documentType:
                x.__typename === 'Document' ? 'BINARY' : 'DIAGNOSTIC_REPORT',
            }))
          )
          api.sizeColumnsToFit()
        }}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        noRowsOverlayComponentParams={{ message: 'No documents found' }}
      />
      {selected.length !== 0 && (
        <MultiSelectHoverBar
          selected={selected}
          deselectAll={() => gridRef.current.api.deselectAll()}
          itemName="document"
          actions={[
            {
              key: 'efax',
              text: 'E-fax selected',
              icon: EFaxIcon,
              onClick: (selected) =>
                navigate(
                  sidepanelRoute(
                    {
                      route: '/send-fax',
                      documents: JSON.stringify(
                        selected.map((s) => ({
                          documentId: s.documentId,
                          documentType: s.documentType,
                        }))
                      ),
                    },
                    location,
                    params
                  )
                ),
            },
          ]}
        />
      )}
    </StackView>
  )
}

export default DocumentsTable
