import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'

import { useParams, navigate, useLocation } from '@redwoodjs/router'

import { useSetUserSettingsMutation } from 'src/hooks/useUserSettings/useUserSettings'

export type OutboundEfaxFilter =
  | 'outboundEfaxStatusFilter'
  | 'outboundEfaxSenderFilter'

const getFilterValue = (filter: OutboundEfaxFilter, value) => {
  if (
    filter === 'outboundEfaxStatusFilter' ||
    filter === 'outboundEfaxSenderFilter'
  ) {
    return value
  }

  if (Array.isArray(value)) {
    return value.join(',')
  }
}

export const outboundEfaxStatusFilterOptions = [
  {
    name: 'In Progress',
    value: 'IN_PROGRESS',
  },
  {
    name: 'Failed',
    value: 'FAILED',
  },
  {
    name: 'Successful',
    value: 'SUCCEEDED',
  },
] as const

type OutboundEfaxStatusFilterOption =
  (typeof outboundEfaxStatusFilterOptions)[number]['value']

const parseIds = (filter?: string) => {
  if (isNil(filter)) {
    return undefined
  }

  if (isEmpty(filter)) {
    return []
  }

  return filter.split(',').filter((id) => id != '')
}

const parseOutboundEfaxStatuses = (filter?: string) => {
  if (isNil(filter)) {
    return undefined
  }

  if (isEmpty(filter)) {
    return []
  }

  return filter
    .split(',')
    .filter(Boolean)
    .flatMap((status) => status as OutboundEfaxStatusFilterOption)
}

export const useOutboundEfaxFilter = () => {
  const params = useParams()
  const { outboundEfaxSenderFilter, outboundEfaxStatusFilter } = params

  return {
    faxSenderUserIds: parseIds(outboundEfaxSenderFilter),
    includeStatuses: parseOutboundEfaxStatuses(outboundEfaxStatusFilter),
  }
}

export const useUpdateFilter = () => {
  const params = useParams()
  const location = useLocation()
  const [setOutboundEfaxUserSettings] = useSetUserSettingsMutation()

  const paramsWithoutGlob = { ...params }
  if (paramsWithoutGlob.glob) {
    delete paramsWithoutGlob.glob
  }

  return (filter: OutboundEfaxFilter, value: string[] | Date | string) => {
    const newParams = new URLSearchParams({
      ...paramsWithoutGlob,
      [filter]: getFilterValue(filter, value),
    })

    setOutboundEfaxUserSettings({
      variables: {
        input: {
          [filter]: getFilterValue(filter, value),
        },
      },
    })
    navigate(`${location.pathname}?${newParams.toString()}`)
  }
}
