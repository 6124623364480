import { PrinterIcon } from '@heroicons/react/20/solid'
import { Outlet } from 'react-router-dom'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import TabNav from 'src/components/molecules/TabNav/TabNav'
import { EmrCurrentUser } from 'src/lib/auth'
import { sidepanelRoute } from 'src/lib/routes'

import CaregiversTab from './CaregiversTab/CaregiversTab'
import InsurancesTab from './InsurancesTab/InsurancesTab'
import PatientTab from './PatientTab/PatientTab'
import SiblingsTab from './SiblingsTab/SiblingsTab'

export const demographicsNavConfig = (_currentUser: EmrCurrentUser) =>
  [
    {
      label: 'Patient',
      to: 'patient',
      element: <PatientTab />,
    },
    {
      label: 'Caregiver',
      to: 'caregiver',
      element: <CaregiversTab />,
    },
    {
      label: 'Insurance',
      to: 'insurance',
      element: <InsurancesTab />,
    },

    {
      label: 'Siblings',
      to: 'siblings',
      element: <SiblingsTab />,
    },
  ].filter(Boolean)

const Nav = () => {
  const { id } = useParams()

  const { currentUser } = useEmrAuth()
  const rootPath = routes.patientChartsGlob({
    id,
    glob: 'demographics',
  })

  return (
    <StackView
      className="w-[240px]"
      space={100}
      fullWidth={false}
      data-testid="patient-demographics-nav"
    >
      <TabNav
        rootPath={rootPath}
        style="button"
        vertical
        tabs={demographicsNavConfig(currentUser).map(({ label, to }) => ({
          name: label,
          key: to,
          to: `${rootPath}/${to}`,
        }))}
      />
    </StackView>
  )
}

const PatientDemographics = () => {
  const params = useParams()
  const location = useLocation()

  return (
    <StackView className="p-core-space-150" gap={150}>
      <StackView
        direction="row"
        justifyContent="between"
        alignItems="center"
        className="border-b-core-border-width-10 border-base-color-border-subtle pb-core-space-100"
      >
        <Typography textStyle="title" fontWeight="semibold">
          Demographics
        </Typography>

        <Button
          buttonStyle="secondary"
          text="Print"
          icon={PrinterIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                {
                  route: `/print-patient-demographics/${params.id}`,
                  width: 'medium',
                },
                location,
                params
              )
            )
          }
        />
      </StackView>
      <StackView gap={100} className="overflow-hidden" direction="row">
        <Nav />
        <StackView className="overflow-y-auto" alignItems="center">
          <Outlet />
        </StackView>
      </StackView>
    </StackView>
  )
}

export default PatientDemographics
