import { PullMedicationsFromDoctorFirst } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { MEDICATION_FRAGMENT } from 'src/pages/PatientChartsPage/PatientVisits/fragments'

const MUTATION = gql`
  mutation PullMedicationsFromDoctorFirst($patientId: String!) {
    pullMedicationsFromDoctorFirst(patientId: $patientId) {
      id
      medications {
        ...MedicationFragment
      }
    }
  }
  ${MEDICATION_FRAGMENT}
`

const usePullMedicationsFromDoctorFirst = (patientId: string) => {
  return useMutation<PullMedicationsFromDoctorFirst>(MUTATION, {
    variables: { patientId },
  })
}

export default usePullMedicationsFromDoctorFirst
