import { paymentLineItemTypesDisplay } from 'common/data/paymentLineItemTypes'
import { formatMoneyInCents } from 'common/utils'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import compact from 'lodash/compact'
import { useParams } from 'react-router-dom'
import { GetPatientRefund } from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge/Badge'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  PAYMENT_STATUS,
  PAYMENT_TYPES,
} from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'
import { usePatientRefundQuery } from 'src/hooks/usePatientRefunds/usePatientRefunds'
import { sidepanelRoute } from 'src/lib/routes'

const Details = ({
  refund,
}: {
  refund: GetPatientRefund['develoCandidPatientRefund']
}) => {
  if (!refund) return
  return (
    <DataDisplayList
      title="Details"
      leftColumnWidth="lg"
      data={compact([
        {
          label: 'Amount',
          value: formatMoneyInCents(refund.amountCents),
        },
        {
          label: 'Category',
          value: (
            <Badge
              text={PAYMENT_TYPES['REFUND'].label}
              color={PAYMENT_TYPES['REFUND'].color}
              size="s"
            />
          ),
        },
        {
          label: 'Status',
          value: (
            <Badge
              text={PAYMENT_STATUS[refund.status].label}
              color={PAYMENT_STATUS[refund.status].color}
              size="s"
              showDot
            />
          ),
        },
        refund.candidPatientPayment
          ? {
              label: refund.candidPatientPayment.appointment
                ? 'Visit'
                : 'Charge',
              value: refund.chargeDisplay,
            }
          : undefined,
        refund.paymentTransaction.createdByUser
          ? {
              label: 'Processed by',
              value: (
                <StackView>
                  <Typography
                    textStyle="interface-default-s"
                    color="text-base-color-fg-muted"
                  >
                    {formatDisplayName(refund.paymentTransaction.createdByUser)}
                  </Typography>
                  <Typography
                    textStyle="interface-default-xs"
                    color="text-base-color-fg-subtle"
                  >
                    {refund.paymentTransaction.createdByUser.userType.name}
                  </Typography>
                </StackView>
              ),
            }
          : undefined,
        {
          label: 'Processed date and time',
          value: format(new Date(refund.createdAt), "MM/dd/yyyy 'at' h:mma"),
        },
      ])}
    />
  )
}

const SidepanelPatientRefundView = () => {
  const params = useParams()
  const location = useLocation()
  const { patientRefundId, patientId } = params
  const { refund, loading } = usePatientRefundQuery({
    develoCandidPatientRefundId: patientRefundId,
  })

  return (
    <SidepanelPage
      testId="patient-refund-view"
      header={refund?.candidPatientPayment?.chargeDisplay ?? 'Refund'}
      description={
        refund
          ? compact([
              format(new Date(refund.createdAt), 'PP'),
              refund.candidPatientPayment
                ? paymentLineItemTypesDisplay[
                    refund.candidPatientPayment.paymentLineItemType
                  ]
                : undefined,
            ]).join(' · ')
          : undefined
      }
      loading={loading}
    >
      {refund && (
        <StackView className="grow p-core-space-150" space={50}>
          <StackView direction="row" space={50}>
            <Button
              testId="transaction-receipt-button"
              text="Receipt"
              buttonStyle="secondary"
              onClick={() => {
                navigate(
                  sidepanelRoute(
                    {
                      route: `/patients/${patientId}/payments/receipt/${refund.paymentTransactionId}`,
                      width: 'medium',
                      overlay: true,
                    },
                    location,
                    params
                  )
                )
              }}
            />
          </StackView>

          <Details refund={refund} />
        </StackView>
      )}
    </SidepanelPage>
  )
}

export default SidepanelPatientRefundView
