import { navigate, useLocation, useParams } from '@redwoodjs/router'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { FavoriteOrderButton } from 'src/components/FavoriteOrderButton/FavoriteOrderButton'
import CreateOrderDropdown from 'src/components/molecules/CreateOrderDropdown/CreateOrderDropdown'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import { sidepanelRoute } from 'src/lib/routes'

import OrdersTable from './OrdersTable'
import { usePatientOrdersWithObservationsQuery } from './usePatientOrders'

const PatientOrders = () => {
  const params = useParams()
  const location = useLocation()
  const { id: patientId } = params
  const { pendedOrders, activeOrders, completedOrders, revokedOrders } =
    usePatientOrdersWithObservationsQuery(patientId)

  const openOrderSidepanelView = (e) =>
    navigate(
      sidepanelRoute(
        {
          route: `/patients/${patientId}/orders/${e.data.id}`,
        },
        location,
        params
      )
    )

  return (
    <StackView space={100} className="px-6 pb-8 pt-4" data-testid="order-row">
      <StackView
        direction="row"
        space={50}
        alignItems="center"
        justifyContent="between"
      >
        <Typography textStyle="title" fontWeight="semibold">
          Orders
        </Typography>
        <StackView
          direction="row"
          gap={50}
          justifyContent="end"
          fullWidth={false}
        >
          <FeatureFlagged flagName="FAVORITE_ORDERS">
            <FavoriteOrderButton patientId={patientId} />
          </FeatureFlagged>
          <CreateOrderDropdown route={`/patients/${patientId}/orders/`} />
        </StackView>
      </StackView>

      <StackView space={50}>
        <Typography textStyle="title" fontWeight="semibold">
          Pended
        </Typography>
        <OrdersTable
          testId="pended-orders-table"
          patientId={patientId}
          onRowClicked={openOrderSidepanelView}
          orders={pendedOrders}
        />
      </StackView>

      <StackView space={50}>
        <Typography textStyle="title" fontWeight="semibold">
          Active
        </Typography>
        <OrdersTable
          testId="active-orders-table"
          patientId={patientId}
          onRowClicked={openOrderSidepanelView}
          orders={activeOrders}
        />
      </StackView>

      <StackView space={50}>
        <Typography textStyle="title" fontWeight="semibold">
          Completed
        </Typography>
        <OrdersTable
          testId="completed-orders-table"
          patientId={patientId}
          onRowClicked={openOrderSidepanelView}
          orders={completedOrders}
          pagination={true}
        />
      </StackView>

      <StackView space={50} className="pb-4">
        <Typography textStyle="title" fontWeight="semibold">
          Revoked
        </Typography>
        <OrdersTable
          testId="revoked-orders-table"
          patientId={patientId}
          onRowClicked={openOrderSidepanelView}
          orders={revokedOrders}
          pagination={true}
        />
      </StackView>
    </StackView>
  )
}

export default PatientOrders
