import type { ChartingStatus } from 'types/graphql'

import { BadgeColor } from 'src/components/atoms/Badge/Badge'

import { nameValueArrayFromObject } from './utils'

export const chartingStatusDisplay: {
  [key in ChartingStatus]: string
} = {
  NA: 'N/A',
  COMPLETE: 'Complete',
  OPEN: 'Open',
}

export const chartingStatusColors: {
  [key in ChartingStatus]: BadgeColor
} = {
  NA: 'dark-gray',
  COMPLETE: 'green',
  OPEN: 'yellow',
}

export const chartingStatuses = nameValueArrayFromObject<ChartingStatus>(
  chartingStatusDisplay
)
