import { PatientDemographicsFragment } from 'types/graphql'

import { useParams } from '@redwoodjs/router'

import EthnicitySelectField from 'src/components/atoms/EthnicitySelectField/EthnicitySelectField'
import InputField from 'src/components/atoms/InputField'
import PreferredPronounsSelectField from 'src/components/atoms/PreferredPronounSelectField/PreferredPronounsSelectField'
import RaceSelectField from 'src/components/atoms/RaceSelectField/RaceSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import { ethnicityDisplay } from 'src/data/ethnicities'
import { preferredPronounDisplay } from 'src/data/preferredPronouns'
import { raceDisplay } from 'src/data/races'
import { UPDATE_PATIENT_CAREGIVER_ADDITIONAL_DEMOGRAPHICS } from 'src/pages/AppointmentCheckInPage/CheckInCaregiversTab/CaregiverAdditionalDemographicsVerificationCard'

const CaregiverAdditionalDemographicsCard: React.FC<{
  relationship: PatientDemographicsFragment['patientRelatedPersonRelationships'][0]
}> = ({ relationship }) => {
  const { id } = useParams()
  const { relatedPerson } = relationship

  const preSubmit = (data) => {
    const { preferredPronouns, race, ethnicity, socialSecurityNumber } = data

    return {
      variables: {
        patientId: id,
        relatedPersonId: relatedPerson.id,
        input: {
          preferredPronouns,
          race,
          ethnicity,
          socialSecurityNumber,
        },
      },
    }
  }

  return (
    <DataVerificationCard
      title="Additional demographics"
      defaultOpen
      updateMutation={UPDATE_PATIENT_CAREGIVER_ADDITIONAL_DEMOGRAPHICS}
      preSubmit={preSubmit}
      data={[
        {
          name: 'preferredPronouns',
          value: relatedPerson.preferredPronouns,
          displayValue:
            preferredPronounDisplay[relatedPerson.preferredPronouns],
          label: 'Preferred Pronouns',
          formInputComponent: PreferredPronounsSelectField,
        },
        {
          name: 'race',
          value: relatedPerson.race,
          displayValue: raceDisplay[relatedPerson.race],
          label: 'Race',
          formInputComponent: RaceSelectField,
        },
        {
          name: 'ethnicity',
          value: relatedPerson.ethnicity,
          displayValue: ethnicityDisplay[relatedPerson.ethnicity],
          label: 'Ethnicity',
          formInputComponent: EthnicitySelectField,
        },
        {
          name: 'socialSecurityNumber',
          value: relatedPerson.socialSecurityNumber,
          label: 'Social Security Number',
          formInputComponent: InputField,
        },
      ]}
    />
  )
}

export default CaregiverAdditionalDemographicsCard
