import { PayersQuery, PayersQueryVariables } from 'types/graphql'

import { RegisterOptions } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import { PayerSelectFieldBase } from './helpers/PayerSelectFieldBase'

const QUERY = gql`
  query PayersQuery {
    payers {
      id
      displayName
      candidPayerId
      isCommon
    }
  }
`

export const PayerSelectField = ({
  name,
  className,
  validation,
}: {
  name: string
  className?: string
  validation?: RegisterOptions
}) => {
  const { data } = useQuery<PayersQuery, PayersQueryVariables>(QUERY)

  const payers = data?.payers ?? []

  return (
    <PayerSelectFieldBase
      payers={payers}
      className={className}
      name={name}
      validation={validation}
    />
  )
}
