import { CalendarDaysIcon } from '@heroicons/react/24/solid'
import { isEmpty } from '@medplum/core'
import { familyMemberHistoryRelationshipTypeDisplay } from 'common/data/relationshipTypes'
import { FamilyMemberHistoryCondition } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

const formatConditionOccurrenceString = (
  condition: FamilyMemberHistoryCondition
) => {
  const conditionDisplay =
    condition.onsetYear && condition.onsetAgeInYears
      ? `${condition.onsetYear} at ${condition.onsetAgeInYears} y/o`
      : condition.onsetAgeInYears
        ? `${condition.onsetAgeInYears} y/o`
        : condition.onsetYear
          ? condition.onsetYear.toString()
          : ''

  return conditionDisplay
}

export const DiagnosisYearAndAgeColumn = {
  colId: 'diagnosisYearAndAge',
  headerName: 'Diagnosis year and/or age',
  cellRenderer: ({ data }) => {
    if (!data.conditions) return null

    return (
      <ul>
        {data.conditions.map((event) => {
          const formatted = formatConditionOccurrenceString(event)

          return (
            <li key={event.id}>
              <div className="relative pb-2">
                <div className="relative flex space-x-3">
                  {isEmpty(formatted) ? (
                    <Typography>-</Typography>
                  ) : (
                    <>
                      <div>
                        <span className="flex h-8 w-8 items-center justify-center rounded-full">
                          <CalendarDaysIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="flex min-w-0 flex-1 justify-between pt-1.5">
                        <Typography>{formatted}</Typography>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </li>
          )
        })}
      </ul>
    )
  },
}
export const Icd10CodeAndDescriptionColumn = {
  colId: 'icd10CodeAndDescription',
  headerName: 'ICD-10 code and description',
  cellRenderer: ({ data }) => {
    if (isEmpty(data?.conditions)) return null
    return (
      <StackView className="h-full" justifyContent="center">
        <Typography textStyle="description">
          {data.conditions[0].icd10Code}
        </Typography>
        <Typography
          textStyle="subtitle"
          size="m"
          className="whitespace-normal"
          fontWeight="semibold"
        >
          {data.conditions[0].icd10Description}
        </Typography>
      </StackView>
    )
  },
}

export const RelationshipTypeColumn = {
  colId: 'relationshipType',
  headerName: 'Relationship to patient',
  cellRenderer: ({ data }) => {
    return (
      <StackView className="h-full" justifyContent="center">
        {familyMemberHistoryRelationshipTypeDisplay[data.relationshipType]}
      </StackView>
    )
  },
}
