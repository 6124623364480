import Card from 'src/components/atoms/Card'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import BaseModal from 'src/components/molecules/Modal/BaseModal'

const LoadingModal = ({
  isOpen,
  title,
  description,
}: {
  isOpen: boolean
  title: string
  description?: string
}) => {
  return (
    <BaseModal isOpen={isOpen} setIsOpen={() => {}}>
      <Card
        contentClassName="p-core-space-200 m:p-core-space-150 w-screen sm:w-auto md:max-w-lg"
        className="rounded-b-none sm:rounded-base-border-radius-container-l"
      >
        <StackView justifyContent="center" space={100}>
          <LoadingSpinner size="l" color="brand" />
          <StackView justifyContent="center" space={50}>
            <Typography textStyle="title-m">{title}</Typography>
            {description && (
              <Typography textStyle="body-m" color="text-base-color-fg-muted">
                {description}
              </Typography>
            )}
          </StackView>
        </StackView>
      </Card>
    </BaseModal>
  )
}
export default LoadingModal
