import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import {
  FindPractitionerScheduleQuery,
  TenantLetterheadFragment,
} from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { EmrCurrentUser } from 'src/lib/auth'

import { LetterHead } from '../common'

import { AppointmentRow } from './AppointmentRow'
import { FilterBox } from './FilterBox'

export const PrintSchedule = ({
  forDate,
  appointments,
  tenant,
  currentUser,
  filters,
}: {
  forDate: Date
  appointments: FindPractitionerScheduleQuery['appointments']
  tenant: TenantLetterheadFragment
  currentUser: EmrCurrentUser
  filters: {
    practitionerNames: string[]
    locationNames: string[]
    appointmentStatusNames: string[]
  }
}) => {
  return (
    <StackView gap={100}>
      <LetterHead tenant={tenant} />
      <StackView gap={50}>
        <Typography textStyle="title-m">
          Schedule overview for {format(forDate, 'MMM d, yyyy')}
        </Typography>
        <Typography>
          Generated on {format(new Date(), 'MM/dd/yyyy')} by{' '}
          {formatDisplayName(currentUser)}
        </Typography>
      </StackView>
      <FilterBox filters={filters} />

      <div className="border-base-color-subtle border-t-core-border-width-40" />

      <StackView>
        {appointments.map((appointment, i) => {
          return (
            <AppointmentRow
              key={appointment.id}
              appointment={appointment}
              position={
                i === 0
                  ? 'first'
                  : i === appointments.length - 1
                    ? 'last'
                    : 'other'
              }
            />
          )
        })}
      </StackView>
    </StackView>
  )
}
