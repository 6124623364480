import omit from 'lodash/omit'
import pick from 'lodash/pick'
import {
  OrderCategory,
  OrderFragment,
  TargetTimeUnit,
  TargetTimePrefix,
} from 'types/graphql'

import { ProviderPreference } from './buildFormInputList'

const DEFAULT_TARGET_DATE = {
  value: 1,
  unit: 'WEEK' as TargetTimeUnit,
  prefix: 'AFTER' as TargetTimePrefix,
}

const buildDefaultProviderPreference = (
  order: OrderFragment
): ProviderPreference => {
  if (!order) return 'any'
  if (!order.performer && !order.existingContactPerformer?.contactId)
    return 'any'
  if (order.existingContactPerformer?.contactId) return 'existingContact'
  return 'custom'
}

const buildDefaultValues = ({
  order,
  category,
}: {
  order?: OrderFragment
  category: Exclude<OrderCategory, 'HG_LAB'>
}) => {
  let defaultValues
  if (order) {
    defaultValues = {
      orderInput:
        category === 'SCH'
          ? undefined
          : {
              ...pick(order, [
                'code',
                'freeTextCode',
                'reason',
                'performer',
                'expiresAt',
                'isUrgent',
                'comments',
              ]),
              providerPreference: buildDefaultProviderPreference(order),
              performer: !order.existingContactPerformer?.contactId
                ? order.performer
                : null,
              existingContactPerformer: order.existingContactPerformer,
              diagnosisCodes: order.diagnosisCodes?.map((code) => ({
                code,
              })),
            },
      schedulingOrderInput:
        category === 'SCH' && order.schedulingCase
          ? {
              ...pick(order.schedulingCase, [
                'chiefComplaints',
                'visitPractitionerId',
                'locationId',
              ]),
              appointmentDefinitionIds:
                order.schedulingCase.appointmentDefinitions?.map(
                  ({ id }) => id
                ),
              note: order.schedulingCase.baseCase.lastCaseNote?.text,
              targetDate: order.schedulingCase.targetDate
                ? omit(order.schedulingCase.targetDate, '__typename')
                : order.schedulingCase.targetTime &&
                    order.schedulingCase.targetTimeUnit
                  ? {
                      value: order.schedulingCase.targetTime,
                      unit: order.schedulingCase.targetTimeUnit,
                      prefix: 'AFTER',
                    }
                  : DEFAULT_TARGET_DATE,
            }
          : undefined,
    }
  } else {
    defaultValues = {
      orderInput:
        category === 'SCH'
          ? undefined
          : category === 'VAX'
            ? {
                isUrgent: false,
                diagnosisCodes: [
                  {
                    code: {
                      code: 'Z23',
                      description: 'Encounter for immunization',
                    },
                  },
                ],
              }
            : {
                code: undefined,
                isUrgent: false,
                providerPreference: 'any' as ProviderPreference,
                diagnosisCodes: [{ code: null }],
              },
      schedulingOrderInput:
        category === 'SCH'
          ? {
              targetDate: DEFAULT_TARGET_DATE,
            }
          : undefined,
    }
  }
  return defaultValues
}
export default buildDefaultValues
