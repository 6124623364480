import { useEffect, useState } from 'react'

import { isEmptyLexical } from 'common/lexical/lexical'
import compact from 'lodash/compact'
import { Route, Routes } from 'react-router-dom'
import { AppointmentChartingStatus, AppointmentStatus } from 'types/graphql'

import {
  navigate,
  routes,
  useLocation,
  useParams,
  Redirect,
} from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box'
import TabNav from 'src/components/molecules/TabNav/TabNav'
import { useAppointmentScheduleQuery } from 'src/components/organisms/ScheduleTable/useAppointmentSchedule'
import { CANCELLED_APPOINTMENT_STATUSES } from 'src/data/appointmentStatus'
import { sidepanelRoute } from 'src/lib/routes'

import { useLogPatientChartAccess } from '../PatientsPage/usePatientChartAccesses'

import PatientChartFinancials from './PatientChartFinancials/PatientChartFinancials'
import PatientDemographics, {
  demographicsNavConfig,
} from './PatientDemographics/PatientDemographics'
import PatientTab from './PatientDemographics/PatientTab/PatientTab'
import PatientDocuments from './PatientDocuments/PatientDocuments'
import PatientGrowthCharts from './PatientGrowthCharts/PatientGrowthCharts'
import PatientHeader from './PatientHeader/PatientHeader'
import PatientHistory from './PatientHistory/PatientHistory'
import { PatientHospitalVisit } from './PatientHospitalVisit/PatientHospitalVisit'
import PatientImmunizations from './PatientImmunizations/PatientImmunizations'
import PatientMedicationsCell from './PatientMedicationsCell'
import PatientOrders from './PatientOrders/PatientOrders'
import PatientResults from './PatientResults/PatientResults'
import PatientVisits from './PatientVisits'
import VisitFlowLayout, {
  visitFlowNavConfig,
} from './PatientVisits/VisitFlowLayout'
import { VisitNavigationGuard } from './PatientVisits/VisitNavigationGuard'

const GET_PATIENT = gql`
  query GetPatientPracticeCommentForPatientChartsPage($id: String!) {
    patient: patient(id: $id) {
      id
      practiceComment
    }
  }
`

const PatientChartsPage = () => {
  const location = useLocation()
  const params = useParams()
  const { id } = params
  const filters = {
    patientId: id,
    excludeStatuses: CANCELLED_APPOINTMENT_STATUSES as AppointmentStatus[],
    excludeChartingStatuses: ['COMPLETE'] as AppointmentChartingStatus[],
  }
  const { currentUser } = useEmrAuth()
  const [hasBeenInitialized, setHasBeenInitialized] = useState(false)

  const { data } = useAppointmentScheduleQuery(filters, { shouldPoll: false })

  const { data: patientData } = useQuery(GET_PATIENT, {
    variables: {
      id,
    },
  })

  // Only conditionally set visit link if there is 1 open visit
  let visitLink: string
  if (
    data?.appointments.length === 1 &&
    data?.appointments[0].chartingStatus === 'OPEN'
  ) {
    visitLink = `visits/${
      data.appointments[0].id
    }/${data.appointments[0].mostRecentVistFlowTab.toLowerCase()}`
  } else {
    visitLink = 'visits'
  }

  useEffect(() => {
    if (
      !hasBeenInitialized &&
      currentUser.userSettings.automaticallyOpenPracticeComment &&
      !isEmptyLexical(patientData?.patient?.practiceComment) &&
      !location.search.includes('sidepanelContext')
    ) {
      navigate(
        sidepanelRoute(
          { route: `/patients/${id}/practice-comment` },
          location,
          params
        )
      )
      setHasBeenInitialized(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientData])

  const rootPath = routes.patientChartsGlob

  useLogPatientChartAccess(id)

  return (
    <Box className="flex flex-1 flex-col overflow-hidden">
      <PatientHeader />
      <TabNav
        className="px-4"
        rootPath={routes.patientCharts({ id })}
        tabs={compact([
          {
            name: 'Visits',
            key: 'visits',
            to: rootPath({
              id,
              glob: visitLink,
            }),
          },
          {
            name: 'Demographics',
            key: 'demographics',
            to: rootPath({ id, glob: 'demographics/patient' }),
          },
          {
            name: 'Meds',
            key: 'medications',
            to: rootPath({ id, glob: 'medications' }),
          },
          {
            name: 'Orders',
            key: 'orders',
            to: rootPath({ id, glob: 'orders' }),
          },
          {
            name: 'History',
            key: 'history',
            to: rootPath({ id, glob: 'history' }),
          },
          {
            name: 'Results',
            key: 'results',
            to: rootPath({ id, glob: 'results' }),
          },
          {
            name: 'Immunizations',
            key: 'immunization',
            to: rootPath({ id, glob: 'immunization' }),
          },
          {
            name: 'Growth chart',
            key: 'growth-chart',
            to: rootPath({ id, glob: 'growth-chart' }),
          },
          {
            name: 'Docs',
            key: 'documents',
            to: rootPath({ id, glob: 'documents' }),
          },
          {
            name: 'Financials',
            key: 'financials',
            to: rootPath({ id, glob: 'financials' }),
          },
        ])}
      />
      <Box
        data-testid="patient-charts-tab-content"
        className="flex flex-1 overflow-y-auto overflow-x-hidden"
      >
        <Routes location={location}>
          <Route path={rootPath({ id, glob: 'visits' })}>
            <Route
              path={rootPath({ id, glob: 'visits/:appointmentId' })}
              element={<VisitFlowLayout />}
            >
              {visitFlowNavConfig.map((navItem) => (
                <Route
                  key={navItem.to}
                  path={rootPath({
                    id,
                    glob: `visits/:appointmentId/${navItem.to}`,
                  })}
                  element={
                    <VisitNavigationGuard>
                      {navItem.element}
                    </VisitNavigationGuard>
                  }
                />
              ))}
            </Route>
            <Route
              path={rootPath({ id, glob: 'visits/hospital/:hospitalVisitId' })}
              element={<PatientHospitalVisit />}
            />
            <Route index element={<PatientVisits />} />
          </Route>
          <Route
            path={rootPath({ id, glob: 'demographics' })}
            element={<PatientDemographics />}
          >
            {demographicsNavConfig(currentUser).map((navItem) => (
              <Route
                key={navItem.to}
                path={rootPath({
                  id,
                  glob: `demographics/${navItem.to}`,
                })}
                element={navItem.element}
              />
            ))}
            <Route index element={<PatientTab />} />
          </Route>
          <Route
            path={rootPath({ id, glob: 'medications' })}
            element={<PatientMedicationsCell patientId={id} />}
          />
          <Route
            path={rootPath({ id, glob: 'orders' })}
            element={<PatientOrders />}
          />
          <Route
            path={rootPath({ id, glob: 'history' })}
            element={<PatientHistory />}
          />
          <Route
            path={rootPath({ id, glob: 'results' })}
            element={<PatientResults />}
          />
          <Route
            path={rootPath({ id, glob: 'immunization' })}
            element={<PatientImmunizations />}
          />
          <Route
            path={rootPath({ id, glob: 'growth-chart' })}
            element={<PatientGrowthCharts />}
          />
          <Route
            path={rootPath({ id, glob: 'documents' })}
            element={<PatientDocuments />}
          />
          <Route
            path={rootPath({ id, glob: 'financials' })}
            element={<PatientChartFinancials />}
          />
          <Route
            path={rootPath({ id, glob: '*' })}
            element={
              <Redirect
                to={rootPath({ id, glob: 'visits' })}
                options={{ replace: true }}
              />
            }
          />
        </Routes>
      </Box>
    </Box>
  )
}

export default PatientChartsPage
