import { useParams } from 'react-router-dom'
import { GetAutomationRule, TextItemWithBulletPoints } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Badge from 'src/components/atoms/Badge'
import Divider from 'src/components/atoms/Divider'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'

import Automations from './components/Automations'

const GET_AUTOMATION_RULE = gql`
  query GetAutomationRule($id: AutomationRuleCode!) {
    automationRule(id: $id) {
      id
      title
      isEnabled
      triggerDescription {
        text
        bulletPoints
      }
      automations {
        __typename
        ... on ModifierAutomation {
          modifier
        }
        ... on DiagnosisAutomation {
          diagnosis {
            id
            code
            description
          }
        }
        ... on RemoveChargeItemAutomation {
          billingCode {
            id
            code
            description
          }
        }
        ... on ChargeItemAutomation {
          billingCode {
            id
            code
            description
          }
          diagnoses {
            id
            code
            description
          }
          modifiers
          countDescription
        }
      }
      group {
        id
        subSection {
          id
          title
          section {
            id
            title
          }
        }
      }
    }
  }
`
const useChargeCaptureRuleQuery = (ruleId) => {
  const { data, loading } = useQuery<GetAutomationRule>(GET_AUTOMATION_RULE, {
    variables: { id: ruleId },
  })

  return { rule: data?.automationRule, loading }
}

const TextWithBulletPoints = ({
  text,
  bulletPoints,
}: TextItemWithBulletPoints) => {
  return (
    <StackView space={25} className="py-4">
      <Typography textStyle="normal" color="text-base-color-fg-muted">
        {text}
      </Typography>
      {bulletPoints && (
        <StackView className="pl-4">
          {bulletPoints.map((bulletPoint) => (
            <Typography
              key={bulletPoint}
              textStyle="normal"
              color="text-base-color-fg-muted"
            >
              • {bulletPoint}
            </Typography>
          ))}
        </StackView>
      )}
    </StackView>
  )
}

const SidepanelChargeCaptureRuleView = () => {
  const { ruleId } = useParams()
  const { rule, loading } = useChargeCaptureRuleQuery(ruleId)

  return (
    <SidepanelPage
      testId="sidepanel-charge-capture-rule-view"
      header={
        <StackView space={25}>
          {rule?.isEnabled ? (
            <Badge showDot color="green" className="w-fit">
              Active
            </Badge>
          ) : (
            <Badge showDot color="dark-gray" className="w-fit">
              Inactive
            </Badge>
          )}
          <Typography textStyle="title-s">{rule?.title}</Typography>
        </StackView>
      }
      loading={loading}
    >
      <StackView className="p-core-space-150" divider>
        <DataDisplayList
          title="Details"
          data={[
            {
              // Should this be the section name? (Match the seleted tab? Or maybe the subsection within that tab?)
              label: 'Category',
              value: rule?.group?.subSection?.section?.title,
            },
            {
              label: 'Trigger',
              value: rule?.triggerDescription ? (
                <TextWithBulletPoints {...rule.triggerDescription} />
              ) : null,
            },
          ]}
        />
        <Divider />
        <Automations automations={rule?.automations} />
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelChargeCaptureRuleView
