import { useState } from 'react'

import { PatientDemographicsFragment } from 'types/graphql'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { AccordionItem } from 'src/components/molecules/Accordion/Accordion'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import { EditPharmacyModal } from 'src/components/molecules/EditPharmacyModal/EditPharmacyModal'
import { NoDefaultPharmacy } from 'src/components/molecules/NoDefaultPharmacy/NoDefaultPharmacy'
import { formatPhoneNumber } from 'src/lib/formatters'

const PatientPharmacyCard = ({
  patient,
}: {
  patient: PatientDemographicsFragment
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <AccordionItem
      bordered
      isOpen={isExpanded}
      setIsOpen={setIsExpanded}
      clickableHeader={false}
      title={
        <StackView
          direction="row"
          alignItems="center"
          justifyContent="between"
          className="h-12 pr-4"
          space={50}
        >
          <StackView
            alignItems="start"
            justifyContent="center"
            className="cursor-pointer"
            direction="col"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <Typography textStyle="title">Pharmacy</Typography>
          </StackView>
          <Button
            buttonStyle="secondary"
            onClick={() => {
              setIsExpanded(true)
              setModalIsOpen(true)
            }}
          >
            Edit
          </Button>
        </StackView>
      }
    >
      <Box className="border-l-lg rounded-b-lg border-x border-b">
        <StackView divider className="px-6 pb-6">
          {patient.defaultPharmacy ? (
            <DataDisplayList
              data={[
                {
                  label: 'Name',
                  value: patient.defaultPharmacy.name,
                },
                {
                  label: 'Address',
                  value: (
                    <StackView>
                      <p>{patient.defaultPharmacy.address1}</p>
                      <p>{patient.defaultPharmacy.address2}</p>
                      <p>
                        {patient.defaultPharmacy.city},{' '}
                        {patient.defaultPharmacy.state}{' '}
                        {patient.defaultPharmacy.postalCode}
                      </p>
                    </StackView>
                  ),
                },
                {
                  label: 'Phone',
                  value:
                    patient.defaultPharmacy.phone &&
                    formatPhoneNumber(patient.defaultPharmacy.phone),
                },
                {
                  label: 'Fax',
                  value:
                    patient.defaultPharmacy.fax &&
                    formatPhoneNumber(patient.defaultPharmacy.fax),
                },
              ]}
            />
          ) : (
            <NoDefaultPharmacy />
          )}
        </StackView>
      </Box>
      <EditPharmacyModal
        patientId={patient.id}
        isOpen={modalIsOpen}
        setIsOpen={setModalIsOpen}
      />
    </AccordionItem>
  )
}

export default PatientPharmacyCard
