import { useContext, PropsWithChildren, useMemo } from 'react'

import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { assertUnreachable } from 'common/utils'
import { formatDisplayName } from 'common/utils'
import {
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  startOfToday,
  subDays,
  subMonths,
} from 'date-fns'
import every from 'lodash/every'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import mapValues from 'lodash/mapValues'
import { AppointmentPatientCheckInQuery, OrderFragment } from 'types/graphql'

import { checkedInStatuses } from 'src/data/appointmentStatus'
import { coordinationOfBenefitTypeDisplay } from 'src/data/coordinationOfBenefitTypes'
import { insuranceCoverageStatusDisplay } from 'src/data/insuranceCoverageStatuses'
import { usePatientOrdersQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import { createNamedContext } from 'src/utils'

import {
  VerificationRequirement,
  TimebasedVerificationSchedule,
  AppointmentCheckInVerification,
  CheckInStep,
  CheckInStepSectionVerification,
  PracticeFormVerificationRequirementData,
  CHECK_IN_STEP_CONFIG,
  CheckInStepSectionConfig,
} from './types'
export * from './types'

export const REQUIREMENTS_REQUIRING_VERIFICATION: VerificationRequirement[] = [
  'soft',
  'hard',
]

const calculateDateVerificationRequirement = (
  schedule: TimebasedVerificationSchedule,
  lastVerifiedAt?: Date,
  skipVerificationIfNotPreviouslyVerified = false
): VerificationRequirement => {
  switch (schedule) {
    case 1:
      if (!lastVerifiedAt) {
        return skipVerificationIfNotPreviouslyVerified ? 'none' : 'hard'
      }
      if (isBefore(lastVerifiedAt, subMonths(startOfToday(), 6))) {
        return 'hard'
      } else if (isBefore(lastVerifiedAt, subMonths(startOfToday(), 3))) {
        return 'soft'
      } else if (isAfter(lastVerifiedAt, subDays(startOfToday(), 1))) {
        return 'verified'
      } else {
        return 'none'
      }
    case 2:
      if (!lastVerifiedAt) {
        return skipVerificationIfNotPreviouslyVerified ? 'none' : 'hard'
      }
      if (isBefore(lastVerifiedAt, subMonths(startOfToday(), 13))) {
        return 'hard'
      } else if (isBefore(lastVerifiedAt, subMonths(startOfToday(), 6))) {
        return 'soft'
      } else if (isAfter(lastVerifiedAt, subDays(startOfToday(), 1))) {
        return 'verified'
      } else {
        return 'none'
      }
    default:
      assertUnreachable(schedule)
  }
}

const calculatePracticeFormsVerificationRequirement = (
  formSummaries: PracticeFormVerificationRequirementData
): VerificationRequirement => {
  const unsignedPracticeForms = []
  for (const formSummary of formSummaries) {
    const {
      current: { signedPracticeForm, practiceFormTemplate },
    } = formSummary

    if (!signedPracticeForm) {
      unsignedPracticeForms.push(practiceFormTemplate.id)
    }
  }

  if (unsignedPracticeForms.length > 0) {
    return 'soft'
  } else {
    return 'none'
  }
}

const determineCheckInEligibility = (
  checkInVerification: AppointmentCheckInVerification
) => {
  const noHardRequirements = every(
    Object.values(checkInVerification),
    (step) => {
      return every(Object.values(step), (section) => {
        if (section.verificationRequirement) {
          return section.verificationRequirement !== 'hard'
        } else {
          return every(
            Object.values(section),
            (nestedSection: CheckInStepSectionVerification) => {
              return nestedSection.verificationRequirement !== 'hard'
            }
          )
        }
      })
    }
  )

  return noHardRequirements
}

const calculateSectionVerification = (
  section: CheckInStepSectionConfig,
  data: unknown,
  sectionName?: string
) => {
  const schedule = section.verificationSchedule
  const lastVerifiedAt =
    (schedule == 1 || schedule == 2) && get(data, section.dataPath)
      ? new Date(get(data, section.dataPath))
      : undefined

  return {
    section: sectionName ?? section.display,
    lastVerifiedAt,
    verificationRequirement:
      schedule === 'practice-forms'
        ? calculatePracticeFormsVerificationRequirement(
            get(data, section.dataPath).filter(
              (formSummary) =>
                isEmpty(section.dataFilter) ||
                section.dataFilter.includes(formSummary.type)
            )
          )
        : schedule == 1 || schedule == 2
          ? calculateDateVerificationRequirement(
              schedule,
              lastVerifiedAt,
              section.skipVerificationIfNotPreviouslyVerified
            )
          : assertUnreachable(schedule),
  }
}

const calculateCheckInVerification = (
  appointmentData: AppointmentPatientCheckInQuery['appointment']
): AppointmentCheckInVerification => {
  const checkInVerification = {
    PATIENT: mapValues(CHECK_IN_STEP_CONFIG['PATIENT'].sections, (section) =>
      calculateSectionVerification(section, appointmentData)
    ),
    CAREGIVER: {
      ...appointmentData.patient.patientRelatedPersonRelationships.reduce(
        (acc, relationship) => {
          acc[relationship.id] = mapValues(
            CHECK_IN_STEP_CONFIG['CAREGIVER'].sections,
            (section) =>
              !appointmentData.patient.caregiverOptedOutAt
                ? calculateSectionVerification(
                    section,
                    relationship,
                    `${section.display} - ${formatDisplayName(
                      relationship.relatedPerson
                    )} (${
                      relationshipTypeDisplay[relationship.relationshipType]
                    })`
                  )
                : {
                    section: 'ignore',
                    verificationRequirement: 'none',
                  }
          )

          return acc
        },
        {}
      ),
    },
    INSURANCE: {
      INSURANCE_OPT_OUT: calculateSectionVerification(
        CHECK_IN_STEP_CONFIG['INSURANCE'].sections['INSURANCE_OPT_OUT'],
        appointmentData
      ),
      ...appointmentData.patient.insuranceCoverages.reduce((acc, insurance) => {
        acc[insurance.id] = mapValues(
          CHECK_IN_STEP_CONFIG['INSURANCE'].sections['EXISTING_INSURANCES'],
          (section) =>
            !appointmentData.patient.insuranceOptedOutAt &&
            insurance.status === 'ACTIVE'
              ? calculateSectionVerification(
                  section,
                  insurance,
                  `${section.display} - ${
                    coordinationOfBenefitTypeDisplay[
                      insurance.coordinationOfBenefitsType
                    ]
                  } (${insuranceCoverageStatusDisplay[insurance.status]})`
                )
              : {
                  section: 'ignore',
                  verificationRequirement: 'none',
                }
        )

        return acc
      }, {}),
    },
    PRACTICE_FORMS: mapValues(
      CHECK_IN_STEP_CONFIG['PRACTICE_FORMS'].sections,
      (section) => calculateSectionVerification(section, appointmentData)
    ),
  }

  return checkInVerification
}

export const shouldAlertSchedulingOrder = (
  schedulingOrders: OrderFragment[]
) => {
  return schedulingOrders?.some(
    (schedulingOrder) => schedulingOrder.status === 'ACTIVE'
  )
}

const AppointmentCheckInContext = createNamedContext<{
  patientId: string
  appointmentData: AppointmentPatientCheckInQuery['appointment']
  canCompleteCheckIn: boolean
  isCheckedIn: boolean
  isCheckedOut: boolean
  schedulingOrders: OrderFragment[]
  getSectionsRequiringVerification: (
    step: CheckInStep
  ) => CheckInStepSectionVerification[]
  checkInVerification: AppointmentCheckInVerification
}>('AppointmentCheckIn')

export function useAppointmentCheckIn() {
  const context = useContext(AppointmentCheckInContext)

  return context
}

export function AppointmentCheckInProvider({
  patientId,
  appointmentData,
  children,
}: PropsWithChildren<{
  patientId: string
  appointmentData: AppointmentPatientCheckInQuery['appointment']
}>) {
  const checkInVerification = useMemo(
    () => calculateCheckInVerification(appointmentData),
    [appointmentData]
  )

  const canCompleteCheckIn = useMemo(
    () => determineCheckInEligibility(checkInVerification),
    [checkInVerification]
  )

  const { orders } = usePatientOrdersQuery(patientId)

  const schedulingOrders = orders?.filter(
    (order) =>
      order.category === 'SCH' &&
      order.status !== 'REVOKED' &&
      (isSameDay(parseISO(order.createdAt), parseISO(appointmentData.start)) ||
        isSameDay(
          parseISO(order.schedulingCase?.resolvedAt),
          parseISO(appointmentData.start)
        ))
  )

  const isCheckedIn = checkedInStatuses.includes(appointmentData.status)
  const isCheckedOut = appointmentData.status === 'CHECKED_OUT'

  const getSectionsRequiringVerification = (step: CheckInStep) => {
    return Object.values(checkInVerification[step]).reduce((acc, section) => {
      if (!section.verificationRequirement) {
        acc.push(
          ...Object.values(section).filter(
            (nestedSection: CheckInStepSectionVerification) =>
              REQUIREMENTS_REQUIRING_VERIFICATION.includes(
                nestedSection.verificationRequirement
              )
          )
        )
      } else if (
        REQUIREMENTS_REQUIRING_VERIFICATION.includes(
          (section as unknown as CheckInStepSectionVerification)
            .verificationRequirement
        )
      ) {
        acc.push(section)
      }

      return acc
    }, [])
  }

  return (
    <AppointmentCheckInContext.Provider
      value={{
        patientId,
        appointmentData,
        getSectionsRequiringVerification,
        canCompleteCheckIn,
        checkInVerification,
        isCheckedIn,
        isCheckedOut,
        schedulingOrders,
      }}
    >
      {children}
    </AppointmentCheckInContext.Provider>
  )
}

export default AppointmentCheckInContext
