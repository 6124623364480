import { PrinterIcon } from '@heroicons/react/24/solid'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import { sidepanelRoute } from 'src/lib/routes'

export const PrintScheduleButton = () => {
  const location = useLocation()
  const params = useParams()

  return (
    <Button
      icon={PrinterIcon}
      text="Print"
      buttonStyle="secondary"
      buttonSize="m"
      testId="print-schedule-btn"
      onClick={async () => {
        navigate(
          sidepanelRoute(
            {
              route: '/print-schedule',
              width: 'medium',
              overlay: true,
            },
            location,
            params
          )
        )
      }}
    />
  )
}
