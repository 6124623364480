import { formatMoneyInCents } from 'common/utils'

import { TypographyProps } from 'src/components/atoms/Typography'
import Typography from 'src/components/atoms/Typography/Typography'

export type BalanceDisplayProps = Omit<TypographyProps, 'color'> & {
  children: number
}

export const BalanceDisplay = ({
  children: value,
  ...rest
}: BalanceDisplayProps) => {
  return (
    <Typography
      color={
        value > 0
          ? 'text-base-color-fg-danger'
          : value < 0
            ? 'text-base-color-fg-success'
            : 'text-base-color-fg-default'
      }
      {...rest}
    >
      {formatMoneyInCents(value)}
    </Typography>
  )
}
