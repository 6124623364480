import { useCallback, useEffect, useRef, useState } from 'react'

import { Combobox } from '@headlessui/react'
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { format, parseISO } from 'date-fns'
import orderBy from 'lodash/orderBy'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { sidepanelRoute } from 'src/lib/routes'
import { useVitalsQuery } from 'src/pages/PatientChartsPage/PatientResults/Vitals/useVitals'

const EditVitalsDropdown = ({ disabled }: { disabled?: boolean }) => {
  const params = useParams()
  const location = useLocation()
  const { id: patientId } = params

  const { vitalsByDateAndCode } = useVitalsQuery(patientId)

  const [showEditableVitalsDropdown, setShowEditableVitalsDropdown] =
    useState(false)

  const editVitalsDropdownRef = useRef(null)

  const handleClickOutsideVitalsDropdown = useCallback(
    (event) => {
      if (
        editVitalsDropdownRef.current &&
        !editVitalsDropdownRef.current.contains(event.target)
      ) {
        setShowEditableVitalsDropdown(false)
      }
    },
    [setShowEditableVitalsDropdown]
  )
  const dateOptions = vitalsByDateAndCode?.reduce((options, curr) => {
    for (const date in curr.dataByDate)
      options[date] = (
        <Combobox.Option
          key={date}
          value={null}
          onClick={() => {
            navigate(
              sidepanelRoute(
                {
                  route: `/results/${patientId}/vitals/${date}/edit`,
                },
                location,
                params
              )
            )
            setShowEditableVitalsDropdown(false)
          }}
          className={({ active }) =>
            clsx(
              'relative',
              'py-2',
              'px-3',
              'cursor-default',
              'rounded',
              active ? 'bg-gray-300 text-gray-700' : 'bg-white text-gray-700'
            )
          }
        >
          <Typography className="-ml-1">
            {format(parseISO(date), 'MMM d, yyyy')}
          </Typography>
        </Combobox.Option>
      )
    return options
  }, {})

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideVitalsDropdown)
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideVitalsDropdown
      )
    }
  }, [handleClickOutsideVitalsDropdown])

  return (
    <Box testId="edit-vitals-date-dropdown">
      <Button
        text="Edit"
        icon={PencilIcon}
        buttonStyle="secondary"
        onClick={() =>
          setShowEditableVitalsDropdown(!showEditableVitalsDropdown)
        }
        testId="edit-vitals-button"
        disabled={disabled}
      />
      <Combobox ref={editVitalsDropdownRef} className="relative " as="div">
        <Combobox.Options static className=" shadow-lg">
          {showEditableVitalsDropdown ? (
            <div className="absolute z-10 mt-2 max-h-core-size-2000 w-core-size-800 overflow-auto rounded-core-border-radius-37 border border-base-color-bg-subtle">
              {orderBy(Object.values(dateOptions), ['key'], ['desc'])}
            </div>
          ) : null}
        </Combobox.Options>
      </Combobox>
    </Box>
  )
}

const VitalsHeader = () => {
  const params = useParams()
  const location = useLocation()
  const { id: patientId } = params

  const { vitalsLastUpdated } = useVitalsQuery(patientId)

  return (
    <StackView space={75}>
      <StackView direction="row" space={50} justifyContent="between">
        <StackView>
          <Typography textStyle="title-s" fontWeight="semibold">
            Vitals
          </Typography>
          {vitalsLastUpdated ? (
            <Typography
              textStyle="description"
              color="text-base-color-fg-muted"
            >
              Last updated {format(parseISO(vitalsLastUpdated), 'MM/dd/yyyy')}
            </Typography>
          ) : null}
        </StackView>
        <Box>
          <StackView space={50} direction="row">
            <EditVitalsDropdown disabled={!vitalsLastUpdated} />
            <Button
              text="Add"
              icon={PlusIcon}
              buttonStyle="secondary"
              onClick={() =>
                navigate(
                  sidepanelRoute(
                    {
                      route: `/results/${patientId}/vitals/add`,
                    },
                    location,
                    params
                  )
                )
              }
              testId="add-vitals-button"
            />
          </StackView>
        </Box>
      </StackView>
    </StackView>
  )
}

export default VitalsHeader
