import { useEffect, useState } from 'react'

import {
  AdjustmentsHorizontalIcon,
  PrinterIcon,
} from '@heroicons/react/24/solid'
import clsx from 'clsx'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Button from 'src/components/atoms/Button/Button'
import Divider from 'src/components/atoms/Divider/Divider'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import { classes as selectClasses } from 'src/components/atoms/SelectField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Toggle from 'src/components/molecules/Toggle/Toggle'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'
import { useSetUserSettingsMutation } from 'src/hooks/useUserSettings/useUserSettings'
import { sidepanelRoute } from 'src/lib/routes'
import { useEffectOnce } from 'src/utils'

import AllGrowthCharts from './AllGrowthCharts'
import { useGrowthChartQuery } from './useGrowthCharts'

export type GrowthChartDatasetType =
  | 'WHO_MALE'
  | 'WHO_FEMALE'
  | 'CDC_MALE'
  | 'CDC_FEMALE'

const PatientGrowthCharts = () => {
  const { getCurrentUser } = useEmrAuth()
  const params = useParams()
  const [setUserSetting] = useSetUserSettingsMutation()
  const { id: patientId } = params

  const location = useLocation()

  const [hideTables, setHideTables] = useState(false)
  const [zoomToToday, setZoomToToday] = useState(false)
  const [showCorrectedAge, setShowCorrectedAge] = useState(false)
  const [connectPoints, setConnectPoints] = useState(false)
  const [selectedDataset, setSelectedDataset] = useState(null)

  const {
    loading,
    transformedGrowthChartDatasets,
    patient,
    todayLineX,
    gestationalAgeAtBirthInWeeks,
    showGestationalAgeAtBirth,
    weeksPremature,
    queriedDataset,
  } = useGrowthChartQuery({
    patientId,
    growthChartDatasetType: selectedDataset,
  })

  useEffectOnce(() => {
    void getCurrentUser().then((user) => {
      setHideTables(user.userSettings.hideGrowthChartTables)
      setZoomToToday(user.userSettings.showGrowthChartZoomToToday)
      setConnectPoints(user.userSettings.showGrowthChartConnectedPoints)
    })
  })

  useEffect(() => {
    void getCurrentUser().then((user) => {
      if (showGestationalAgeAtBirth) {
        setShowCorrectedAge(user.userSettings.showGrowthChartCorrectedAge)
      }
    })
  }, [getCurrentUser, showGestationalAgeAtBirth])

  if (loading) {
    return (
      <StackView justifyContent="center">
        <LoadingSpinner />
      </StackView>
    )
  }

  return (
    <StackView
      space={100}
      className="px-core-space-150 pb-core-space-200 pt-core-space-100"
    >
      <StackView space={100} direction="row" justifyContent="between">
        <StackView space={100} direction="row" alignItems="center">
          <StackView space={50}>
            <Typography textStyle="title-s" className="flex-grow">
              Growth chart
            </Typography>

            {showGestationalAgeAtBirth && (
              <StackView direction="row" space={50}>
                <Typography
                  textStyle="interface-strong-s"
                  color="text-base-color-fg-muted"
                >
                  Gestational age at birth:
                </Typography>
                <Typography
                  textStyle="interface-default-s"
                  color="text-base-color-fg-muted"
                >
                  {gestationalAgeAtBirthInWeeks} weeks
                </Typography>

                <Typography textStyle="interface-default-xs">&bull;</Typography>

                <Typography
                  textStyle="interface-strong-s"
                  color="text-base-color-fg-muted"
                >
                  Prematurity:
                </Typography>
                <Typography
                  textStyle="interface-default-s"
                  color="text-base-color-fg-muted"
                >
                  {weeksPremature} weeks
                </Typography>
              </StackView>
            )}
          </StackView>

          <div className="w-core-size-2000">
            <select
              className={clsx(...selectClasses)}
              value={queriedDataset}
              onChange={(e) => setSelectedDataset(e.target.value)}
              data-testid="growth-chart-dataset-select"
            >
              {!queriedDataset && <option value={null}>N/A</option>}
              <option value="WHO_FEMALE">WHO 0-2, female</option>
              <option value="WHO_MALE">WHO 0-2, male</option>
              <option value="CDC_FEMALE">CDC 2-20, female</option>
              <option value="CDC_MALE">CDC 2-20, male</option>
            </select>
          </div>
          <div className="relative">
            <PopoverButton
              panelXPosition="right"
              panelWidth="w-core-size-2000"
              panelZIndex={100}
              buttonProps={{
                buttonStyle: 'secondary',
                icon: AdjustmentsHorizontalIcon,
                text: 'Display',
                testId: 'display-popover-button',
              }}
              renderPanel={() => (
                <StackView className="p-core-space-100" space={100}>
                  <Typography
                    textStyle="interface-strong-xs"
                    color="text-base-color-fg-muted"
                  >
                    Display options
                  </Typography>
                  {showGestationalAgeAtBirth && (
                    <Toggle
                      label="Corrected Age"
                      description="Plot corrected age for patients who were born premature."
                      onChange={() => {
                        void setUserSetting({
                          variables: {
                            input: {
                              showGrowthChartCorrectedAge: !showCorrectedAge,
                            },
                          },
                        })
                        setShowCorrectedAge(!showCorrectedAge)
                      }}
                      checked={showCorrectedAge}
                      testId="corrected-age-toggle"
                    />
                  )}
                  <Toggle
                    label="Connect points"
                    description="Visually link together all growth chart data points in a line plot."
                    onChange={() => {
                      void setUserSetting({
                        variables: {
                          input: {
                            showGrowthChartConnectedPoints: !connectPoints,
                          },
                        },
                      })
                      setConnectPoints(!connectPoints)
                    }}
                    checked={connectPoints}
                    testId="connect-points-toggle"
                  />
                  <Toggle
                    label="Zoom to today"
                    description="Zoom in up to the latest set of growth chart data points."
                    onChange={() => {
                      void setUserSetting({
                        variables: {
                          input: {
                            showGrowthChartZoomToToday: !zoomToToday,
                          },
                        },
                      })
                      setZoomToToday(!zoomToToday)
                    }}
                    checked={zoomToToday}
                    testId="zoom-to-today-toggle"
                  />
                  <Toggle
                    label="Hide tables"
                    description="Focus display on growth chart plots only with data tables all hidden."
                    onChange={() => {
                      void setUserSetting({
                        variables: {
                          input: {
                            hideGrowthChartTables: !hideTables,
                          },
                        },
                      })
                      setHideTables(!hideTables)
                    }}
                    checked={hideTables}
                    testId="hide-tables-toggle"
                  />
                </StackView>
              )}
            />
          </div>

          <Button
            text="Print"
            icon={PrinterIcon}
            buttonStyle="secondary"
            onClick={() => {
              void getCurrentUser().then((user) => {
                navigate(
                  sidepanelRoute(
                    {
                      route: `/print-growth-charts/${patientId}/${queriedDataset}`,
                      overlay: true,
                      width: 'medium',
                      zoomToToday: JSON.stringify(
                        user.userSettings.showGrowthChartZoomToToday
                      ),
                      connectPoints: JSON.stringify(
                        user.userSettings.showGrowthChartConnectedPoints
                      ),
                      showCorrectedAge: JSON.stringify(
                        user.userSettings.showGrowthChartCorrectedAge
                      ),
                    },
                    location,
                    params
                  )
                )
              })
            }}
          />
        </StackView>
      </StackView>

      <Divider />

      {!queriedDataset && (
        <Typography>Please select a dataset above.</Typography>
      )}

      {queriedDataset && patient && (
        <AllGrowthCharts
          patient={patient}
          transformedGrowthChartDatasets={transformedGrowthChartDatasets}
          hideTables={hideTables}
          showCorrectedAge={showCorrectedAge}
          zoomToToday={zoomToToday}
          todayLineX={todayLineX}
          connectPoints={connectPoints}
          queriedDataset={queriedDataset}
          growthChartHeight="65vh"
        />
      )}
    </StackView>
  )
}

export default PatientGrowthCharts
