import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { convert } from '@js-joda/core'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import {
  costPerBillingPeriodInCents,
  membershipPaymentFrequencyDisplay,
  patientMembershipStatus,
  patientMembershipStatusDisplay,
} from 'common/data/membershipPlans'
import { formatMoneyInCents } from 'common/utils'
import { format } from 'date-fns'
import { match } from 'ts-pattern'
import { PatientMembershipPlanFragment } from 'types/graphql'

import Badge, { BadgeColor } from 'src/components/atoms/Badge/Badge'
import Card from 'src/components/atoms/Card/Card'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { ConditionalWrapper } from 'src/components/ConditionalWrapper/ConditionalWrapper'

export const PatientMembershipPlanCard = ({
  plan,
  border = true,
}: {
  plan: PatientMembershipPlanFragment
  border?: boolean
}) => {
  const status = patientMembershipStatus({ plan })

  const startDate = jsDateToLocalDate(plan.startDate)
  const startDateDisplay = startDate.format(dateTimeFormatter('MM/dd/yyyy'))
  const endDate = plan.endDate ? jsDateToLocalDate(plan.endDate) : null
  const endDateDisplay = endDate?.format(dateTimeFormatter('MM/dd/yyyy'))

  const planCost = costPerBillingPeriodInCents({
    plan: plan.membershipPlan,
    frequency: plan.frequency,
  })

  return (
    <ConditionalWrapper
      shouldWrap={border}
      wrapper={(children) => <Card>{children}</Card>}
    >
      <StackView
        gap={50}
        className="p-core-space-75"
        testId="patient-membership-plan-card"
      >
        <StackView direction="row" justifyContent="between">
          <StackView>
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              Membership
            </Typography>
            <Typography textStyle="title-s">{`${plan.membershipPlan.name} (${
              membershipPaymentFrequencyDisplay[plan.frequency]
            })`}</Typography>

            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              Billed {formatMoneyInCents(planCost)} on{' '}
              {match(plan.frequency)
                .with(
                  'ANNUALLY',
                  () =>
                    `${format(
                      convert(startDate).toDate(),
                      'LLLL do'
                    )} each year.`
                )
                .with(
                  'QUARTERLY',
                  () =>
                    `${[
                      startDate,
                      startDate.plusMonths(3),
                      startDate.plusMonths(6),
                      startDate.plusMonths(9),
                    ]
                      .map((date) => {
                        return format(convert(date).toDate(), 'LLLL do')
                      })
                      .join(', ')} each year.`
                )
                .with(
                  'MONTHLY',
                  () =>
                    `on the ${format(
                      convert(startDate).toDate(),
                      'do'
                    )} of each month.`
                )
                .exhaustive()}
            </Typography>
          </StackView>

          <StackView fullWidth={false}>
            <Badge
              showDot
              text={patientMembershipStatusDisplay[status]}
              color={match(status)
                .returnType<BadgeColor>()
                .with('ACTIVE', () => 'green')
                .with('PENDING', () => 'blue')
                .with('CANCELLED', () => 'dark-gray')
                .exhaustive()}
            />
          </StackView>
        </StackView>

        <Divider />
        <StackView direction="row" justifyContent="between" alignItems="center">
          <StackView
            direction="row"
            fullWidth={false}
            alignItems="center"
            gap={25}
          >
            {plan.hasPaymentError ? (
              <ExclamationCircleIcon
                className="h-base-size-icon-xs w-base-size-icon-xs fill-danger"
                aria-label="Error with latest membership payment"
              />
            ) : null}
            <Typography textStyle="interface-default-xs">
              {formatMoneyInCents(plan.totalAmountPaidCents)} paid to date
            </Typography>
          </StackView>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {match(status)
              .with('ACTIVE', () => `Member since ${startDateDisplay}`)
              .with(
                'CANCELLED',
                () => `${startDateDisplay} to ${endDateDisplay}`
              )
              .with('PENDING', () => `Member starting on ${startDateDisplay}`)
              .exhaustive()}
          </Typography>
        </StackView>
      </StackView>
    </ConditionalWrapper>
  )
}
