import { useEffect, useRef } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { compact } from 'lodash'
import orderBy from 'lodash/orderBy'
import { ConditionClinicalStatus } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Table from 'src/components/molecules/Table/Table'
import { sidepanelRoute } from 'src/lib/routes'

import { GroupedCondition } from '../useConditions'

import {
  ConditionCodeDescriptionColumn,
  ConditionCommentColumn,
  ConditionOccurencesColumn,
  ConditionStatusColumn,
} from './config'

interface DiagnosesTableProps {
  patientId: string
  testId: string
  data: Record<string, GroupedCondition> | undefined
  filter: ConditionClinicalStatus[]
  editRoute: 'diagnoses' | 'zcodes'
  emptyMessage: string
}

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'py-2 cursor-pointer',
  resizable: true,
  autoHeight: true,
  minWidth: 180,
}

const DiagnosesTable = ({
  patientId,
  testId,
  filter,
  data,
  editRoute,
  emptyMessage,
}: DiagnosesTableProps) => {
  const { currentUser } = useEmrAuth()

  const gridRef = useRef<AgGridReact>()

  const params = useParams()
  const location = useLocation()

  const rowData = data
    ? orderBy(
        Object.values(data),
        ['lastClinicalStatus', 'lastOnsetAt'],
        ['asc', 'desc']
      )
    : undefined

  useEffect(() => gridRef.current.api?.onFilterChanged(), [filter])

  const allowDiagnosisComment = currentUser.featureFlags.includes(
    'DIAGNOSIS_AND_ZCODE_COMMENTS'
  )
  return (
    <Table<GroupedCondition>
      testId={testId}
      innerRef={gridRef}
      rowData={rowData}
      isExternalFilterPresent={() => filter.length > 0}
      doesExternalFilterPass={({ data }) => {
        return filter.includes(data.lastClinicalStatus)
      }}
      onRowClicked={(e) =>
        navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/${editRoute}/${e.data.code}`,
            },
            location,
            params
          )
        )
      }
      domLayout="autoHeight"
      rowHeight={50}
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={compact([
        ConditionCodeDescriptionColumn,
        ConditionOccurencesColumn,
        ConditionStatusColumn,
        allowDiagnosisComment && ConditionCommentColumn,
      ])}
      noRowsOverlayComponentParams={{ message: emptyMessage }}
    />
  )
}

export default DiagnosesTable
