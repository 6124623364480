import { useEffect, useRef, useState } from 'react'

import clsx from 'clsx'
import { format, isWithinInterval, parseISO } from 'date-fns'
import { GetPatientForImmunizations, Immunization } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { sidepanelRoute } from 'src/lib/routes'

import {
  immunizationRows,
  immunizationSchedules,
  getImmunizationColumns,
} from './ImmunizationRows'

type ImmunizationRow = {
  id: number
  immunization: string
  abbreviation?: string
  totalDosesDescription: string
  routine: boolean
  schedule: number
  cvxCodes: string[]
  OR?: number[]
  AND?: number[]
}

type ImmunizationSchedules = {
  id: number
  schedule: ImmunizationSchedule[]
}

type ScheduleType = 'RECOMMENDED' | 'CATCHUP'

type ColumnId =
  | 'birth'
  | '1w'
  | '1m'
  | '2m'
  | '4m'
  | '6m'
  | '9m'
  | '12m'
  | '15m'
  | '18m'
  | '24m'
  | '30m'
  | '3y'
  | '4y'
  | '5y'
  | '6y'
  | '7y'
  | '8y'
  | '9y'
  | '10y'
  | '11y'
  | '12y'
  | '13y'
  | '14y'
  | '15y'
  | '16y'
  | '17y'
  | '18y'
  | '19y'
  | '20y'
  | '21y'
  | '22y'
  | '23y'
  | '24y'
  | '25y'
  | '26y'

type ImmunizationSchedule = {
  name: string
  scheduleType: ScheduleType
  start: ColumnId
  end: ColumnId
  numberOfDoses: number
}

type RowProps = {
  className?: string
  zIndex?: number
  testId?: string
}
type CellProps = {
  className?: string
  width?: number
  height?: number
  padding?: number
  border?: number
  bg?: string
  justifyContent?: string
  alignItems?: string
  span?: number
  fullWidth?: boolean
  zIndex?: number
  onClick?: () => void
  testId?: string
}

type SubCellProps = {
  subColumn: SubColumn
  showImmunizationAnnotations: boolean
  testId?: string
}

type SubColumn = {
  administeredImmunizations: Immunization[]
  administeredImmunizationsByColumn: [Immunization[]]
  usesORImmunization: boolean
  usesANDImmunization: boolean
  isGrayedOut: boolean
  isOutlier: boolean
} & ImmunizationSchedule

const defaultCellPadding = 4
const defaultRowHeight = 50
const defaultHeaderRowHeight = 36
const defaultCellWidth = 65
const defaultCellBorder = 2
const defaultPinnedCellWidth = 300

const ImmunizationTable: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div
      className="relative flex w-min flex-col"
      data-testid="immunization-table"
    >
      {children}
    </div>
  )
}

const Row: React.FC<React.PropsWithChildren<RowProps>> = ({
  children,
  className,
  zIndex = 1,
  testId,
}) => {
  return (
    <div
      className={clsx('relative flex flex-row', className)}
      style={{ zIndex: zIndex }}
      data-testid={testId}
    >
      {children}
    </div>
  )
}

const Cell: React.FC<React.PropsWithChildren<CellProps>> = ({
  className,
  children,
  width = defaultCellWidth,
  height = defaultRowHeight,
  padding = defaultCellPadding,
  border = defaultCellBorder,
  bg = 'gray-100',
  justifyContent = 'center',
  alignItems = 'center',
  span = 1,
  fullWidth = false,
  zIndex,
  onClick = () => null,
  testId,
}) => {
  const classes = [
    'flex',
    `p-${padding}`,
    `h-${height}`,
    `bg-${bg}`,
    `justify-${justifyContent}`,
    `items-${alignItems}`,
    `border-${border}`,
    'border-white',
    'relative',
    className,
  ]
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      data-testid={testId}
      className={clsx(...classes)}
      style={{
        width: fullWidth ? '100%' : span > 1 ? span * width : width,
        height: height,
        zIndex: zIndex ? zIndex : 'unset',
      }}
      onClick={() => onClick()}
    >
      {children}
    </div>
  )
}

const SubCell: React.FC<SubCellProps> = ({
  subColumn,
  showImmunizationAnnotations,
  testId,
}) => {
  const location = useLocation()
  const params = useParams()
  let clickEvent = () => {}
  if (subColumn.administeredImmunizations.length) {
    const immunizationIds = subColumn.administeredImmunizations.map(
      (immunization) => immunization.id
    )
    clickEvent = () => {
      navigate(
        sidepanelRoute(
          {
            route: `/vaccine-dose-overview`,
            administeredImmunizationIds: JSON.stringify(immunizationIds),
          },
          location,
          params
        )
      )
    }
  }

  return (
    <Cell
      testId={testId}
      className={clsx(
        subColumn.administeredImmunizations.length && 'hover:cursor-pointer',
        'overflow-hidden'
      )}
      bg={
        subColumn.scheduleType === 'RECOMMENDED'
          ? 'yellow-100'
          : subColumn.scheduleType === 'CATCHUP'
            ? 'blue-100'
            : 'gray-100'
      }
      padding={0}
      fullWidth={true}
      onClick={() => clickEvent()}
    >
      <StackView>
        <StackView direction="row" alignItems="center">
          {subColumn.administeredImmunizationsByColumn?.map(
            (column, columnIndex) => (
              <StackView
                data-testid={`${testId}.${columnIndex}`}
                key={columnIndex}
                className={clsx([
                  column.length > 0 && 'bg-green-200',
                  'gap-px',
                ])}
                style={{ height: column.length > 0 && `${defaultRowHeight}px` }}
                alignItems="center"
                justifyContent="center"
              >
                {/* We need to display each immunization that takes place in this timeframe */}
                {column.map((immunization) => (
                  <Typography
                    key={immunization.id}
                    color="text-base-color-fg-success"
                    className="truncate text-center leading-3"
                    fontWeight="bold"
                    size="xs"
                  >
                    {format(parseISO(immunization.occurredAt), 'M/d/yy')}
                  </Typography>
                ))}
              </StackView>
            )
          )}
        </StackView>
        {/* If administeredImmunizations.length === 0, show the D1, D2 annotation  */}
        {subColumn.administeredImmunizations.length === 0 && (
          <StackView justifyContent="center">
            <Typography
              color="text-base-color-fg-attention"
              fontWeight="medium"
              className="truncate text-center"
              size="xs"
            >
              {showImmunizationAnnotations ? subColumn.name : ''}
            </Typography>
          </StackView>
        )}
      </StackView>
    </Cell>
  )
}

const PinnedCell: React.FC<React.PropsWithChildren<CellProps>> = ({
  bg = 'white',
  width = defaultPinnedCellWidth,
  height = defaultRowHeight,
  children,
  justifyContent = 'start',
  alignItems = 'center',
  className,
}) => {
  return (
    <Cell
      className={clsx(['sticky left-0 top-0'], className)}
      bg={bg}
      width={width}
      height={height}
      justifyContent={justifyContent}
      alignItems={alignItems}
      zIndex={60}
    >
      {children}
    </Cell>
  )
}

const OrPill = ({ row }) => {
  if (row.OR && row.id !== row.OR[row.OR.length - 1]) {
    return (
      <div
        className="absolute right-1 z-40 rounded-lg border border-gray-700 bg-white px-1 py-0"
        style={{
          bottom: row.AND
            ? `calc(-1rem - ${defaultRowHeight}px)` // This is VERY static for the case of Covid, which is our only AND relationship
            : '-1rem',
        }}
      >
        <Typography size="xs">or</Typography>
      </div>
    )
  }
  return null
}

const AndPill = ({ row }) => {
  if (row.AND && row.id !== row.AND[row.AND.length - 1]) {
    return (
      <div className="absolute -bottom-4 right-1 z-40 rounded-lg border border-gray-700 bg-white px-1 py-0">
        <Typography size="xs">and</Typography>
      </div>
    )
  }
  return null
}

const TodayLine = ({
  show,
  numberOfRows,
}: {
  show: boolean
  numberOfRows: number
}) => {
  if (!show) return null
  return (
    <div
      id="today-line"
      className="absolute -right-[2px] top-0 z-40 border-r-2 border-primary"
      style={{
        height: `${
          1 * defaultHeaderRowHeight + numberOfRows * defaultRowHeight - 4
        }px`,
      }}
    ></div>
  )
}

const getScheduleColumns = (
  patient: GetPatientForImmunizations['patient'],
  row: ImmunizationRow
) => {
  const schedule = immunizationSchedules.find(
    (s: ImmunizationSchedules) => s.id === row.schedule
  )
  const columns = getImmunizationColumns(patient.birthDate).map((c) => {
    return {
      ...c,
      schedule: { name: null, scheduleType: null },
      span: 1,
      subColumns: [],
      columnStart: null,
    }
  })
  const columnsToSplice = []
  // Only map cells if we have a schedule to follow, otherwise, we will just show gray cells
  if (schedule?.schedule) {
    // prevents running multiple times for subcolumns
    const groupedSchedule = new Set(schedule.schedule)
    groupedSchedule.forEach((s: ImmunizationSchedule) => {
      const column = columns.find((c) => s.start === c.colId)
      const columnStart = columns.findIndex((c) => s.start === c.colId)
      const columnEnd = columns.findIndex((c) => s.end === c.colId)
      const columnSpan = columnEnd - columnStart + 1
      // Find the subColumns within each cell
      const subColumns = schedule.schedule.filter(
        (s) => s.start === column.colId
      )

      // Find the administered immunizations within this timeframe
      const administeredImmunizations = patient.immunizations
        .filter((i) => row.cvxCodes.includes(i.code.code))
        .filter((i) => {
          return isWithinInterval(parseISO(i.occurredAt), {
            start: parseISO(columns[columnStart].intervalStart),
            end: parseISO(columns[columnEnd].intervalEnd),
          })
        })

      // Split the administered immunizations by the column that they take place in
      const columnsWithinSubColumn = columns.slice(columnStart, columnEnd + 1)
      const administeredImmunizationsByColumn = columnsWithinSubColumn.map(
        (column) => {
          return administeredImmunizations.filter((i) => {
            return isWithinInterval(parseISO(i.occurredAt), {
              start: parseISO(column.intervalStart),
              end: parseISO(column.intervalEnd),
            })
          })
        }
      )

      // We need to check to see if we have previously administered a vaccine in an OR series.
      // If we have, the cells should be grayed out for the row that has not been administerd.
      let usesORImmunization = false
      if (row.OR?.length) {
        // Get the row data for all of the other OR rows (not this current one)
        const orRows = immunizationRows.filter(
          (r) => r.id !== row.id && row.OR.includes(r.id)
        )
        // Get a list of all CVX codes between all OR rows
        const orCvxCodes = orRows.reduce((acc, r) => {
          acc.push(...r.cvxCodes)
          return acc
        }, [])

        // If the patient has a previous immunization from one of the above CVX codes, and we do not
        // have any of the current row codes, we can set usesORImmunization to true
        usesORImmunization =
          patient.immunizations.filter((i) => orCvxCodes.includes(i.code.code))
            .length > 0 &&
          patient.immunizations.filter((i) =>
            row.cvxCodes.includes(i.code.code)
          ).length === 0
      }

      let usesANDImmunization = false
      if (row.AND?.length) {
        // Get the row data for all of the other AND rows (not this current one)
        const andRows = immunizationRows.filter(
          (r) => r.id !== row.id && row.AND.includes(r.id)
        )

        // Get all AND IDs
        const andIds = andRows.map((r) => r.id)

        // Get all OR IDs
        const orIds = andRows.reduce((acc, r) => {
          if (r.OR) {
            acc.push(...r.OR)
          }
          return acc
        }, [])

        // Get the AND rows for any of our ORs
        const orRowsForAnds = immunizationRows.filter(
          (r) => !andIds.includes(r.id) && orIds.includes(r.id)
        )

        // Get a list of all CVX codes between all AND rows
        const cvxCodes = orRowsForAnds.reduce((acc, r) => {
          acc.push(...r.cvxCodes)
          return acc
        }, [])

        // If the patient has a previous immunization from one of the above CVX codes,
        // and we do not have any of the current row codes we can set usesANDImmunization to true
        usesANDImmunization =
          patient.immunizations.filter((i) => cvxCodes.includes(i.code.code))
            .length > 0 &&
          patient.immunizations.filter((i) =>
            row.cvxCodes.includes(i.code.code)
          ).length === 0
      }

      // We want to replace any cells indeces that are being overlapped with their new cell info
      columnsToSplice.push({
        column,
        subColumns: subColumns.map((subColumn) => {
          return {
            ...subColumn,
            administeredImmunizations,
            administeredImmunizationsByColumn,
            usesORImmunization,
            usesANDImmunization,
          }
        }),
        columnStart,
        columnEnd,
        columnSpan,
        schedule: s,
      })
    })
  }

  // We now need to splice out the columns that are being overlapped. We do this in reverse order
  // because we splice by index, which means the indeces will change after every loop
  columnsToSplice.reverse().forEach((column) => {
    columns.splice(column.columnStart, column.columnSpan, {
      ...column.column,
      subColumns: column.subColumns,
      schedule: column.schedule,
      span: column.columnSpan,
      columnStart: column.columnStart,
    })
  })

  // Display a date if an immunization was administered outside of suggested window
  const updatedColumns = columns
    .map((column) => {
      if (column.subColumns.length === 0) {
        const outlierImmunizations = patient.immunizations
          .filter((i) => row.cvxCodes.includes(i.code.code))
          .filter((i) => {
            return isWithinInterval(parseISO(i.occurredAt), {
              start: parseISO(column.intervalStart),
              end: parseISO(column.intervalEnd),
            })
          })

        // If we have any outliers, we need to show them
        if (outlierImmunizations.length) {
          return {
            ...column,
            subColumns: [
              {
                isOutlier: outlierImmunizations.length > 0,
                administeredImmunizations: outlierImmunizations,
                administeredImmunizationsByColumn: [outlierImmunizations],
              },
            ],
          }
        } else {
          // No outliers to display
          return column
        }
      } else {
        // Already has subColumns
        return column
      }
    })
    .map((column) => {
      return {
        ...column,
        subColumns: column.subColumns.map((subColumn) => {
          return {
            ...subColumn,
            isGrayedOut:
              subColumn.isOutlier ||
              subColumn.usesORImmunization ||
              subColumn.usesANDImmunization,
          }
        }),
      }
    })

  return updatedColumns
}

const ImmunizationRecordTable = ({
  patient,
  showNonRoutineVaccines,
  showImmunizationAnnotations,
  showOnePlusDoseVaccines,
  showNoDoseLinkedVaccines,
  showNoDoseVaccines,
}: {
  patient: GetPatientForImmunizations['patient']
  showNonRoutineVaccines: boolean
  showImmunizationAnnotations: boolean
  showOnePlusDoseVaccines: boolean
  showNoDoseLinkedVaccines: boolean
  showNoDoseVaccines: boolean
}) => {
  const [todayLineX, setTodayLineX] = useState(0)
  const [showTodayLine, setShowTodayLine] = useState(true)
  const immunizationTable = useRef(null)
  const columns = getImmunizationColumns(patient.birthDate)

  // Find the column that today is within
  const todayColumn = columns.find((c) => {
    return isWithinInterval(new Date(), {
      start: parseISO(c.intervalStart),
      end: parseISO(c.intervalEnd),
    })
  })

  // Get the index of the column that today is within
  const todayColumnIndex = columns.findIndex(
    (c) => c.colId === todayColumn?.colId
  )

  // Calculate various details about the today line, including:
  // The absolute X position
  // Whether or not it is displayed based on scroll position
  const calculateTodayLine = () => {
    if (todayColumnIndex !== -1) {
      // Get X position of today line
      setTodayLineX(
        defaultPinnedCellWidth + defaultCellWidth * (todayColumnIndex + 1)
      )

      // Hide or show today line based on scroll position
      calculateTodayLineVisibility()
    }
  }

  // This is the starting scroll position for when the table is initially loaded.
  const calculateInitialScroll = (numColumns: number) => {
    if (todayColumnIndex !== -1) {
      const columnToShow = todayColumnIndex + numColumns + 1 // 1 to account for the 0 index column width
      const clientWidth = immunizationTable.current.clientWidth

      immunizationTable.current.scrollLeft =
        defaultCellWidth * columnToShow - clientWidth + defaultPinnedCellWidth
    }
  }

  // Hide or show today line based on scroll position. This hides the today line if it goes over the pinned cells
  const calculateTodayLineVisibility = () => {
    const scrollLeft = immunizationTable.current.scrollLeft
    if (
      todayColumnIndex === -1 ||
      scrollLeft + defaultPinnedCellWidth > todayLineX
    ) {
      setShowTodayLine(false)
    } else {
      setShowTodayLine(true)
    }
  }

  // Get the rows that we want to display
  let rowsToShow = immunizationRows
    .map((row) => {
      return {
        ...row,
        columns: getScheduleColumns(patient, row),
        rowIsGrayedOut:
          getScheduleColumns(patient, row).filter(
            (c) =>
              c.subColumns.filter((sc) => sc.isGrayedOut === true).length > 0
          ).length > 0,
        rowIsAdministered:
          getScheduleColumns(patient, row).filter(
            (c) =>
              c.subColumns.filter(
                (sc) => sc.administeredImmunizations.length > 0
              ).length > 0
          ).length > 0,
      }
    })
    // Display or hide non-routine vaccines with or without documented doses.
    .filter((row) => row.routine || (!row.routine && showNonRoutineVaccines))

  // Display or hide vaccines that have one or more doses.
  if (!showOnePlusDoseVaccines) {
    rowsToShow = rowsToShow.filter((row) => {
      return !row.rowIsAdministered
    })
  }

  // Display or hide linked vaccines that have no documented dose.
  if (!showNoDoseLinkedVaccines) {
    rowsToShow = rowsToShow.filter((row) => {
      return !row.rowIsGrayedOut || row.rowIsAdministered
    })
  }

  // Display or hide any vaccine that has no documented dose.
  if (!showNoDoseVaccines) {
    rowsToShow = rowsToShow.filter((row) => {
      return row.rowIsAdministered
    })
  }

  // On initial render, calculate the default scroll position
  useEffect(() => {
    calculateInitialScroll(2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // When the number of rows to show changes, set the x position, height, and visibility of today line
  useEffect(() => {
    calculateTodayLine()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsToShow])

  // When scrolling, calculate if today line should be visible
  const handleScroll = () => {
    calculateTodayLineVisibility()
  }

  return (
    <div
      className="w-full overflow-auto"
      ref={immunizationTable}
      onScroll={handleScroll}
    >
      <ImmunizationTable>
        <Row
          className="sticky top-0"
          zIndex={50}
          testId="immunization-table-row-header"
        >
          <PinnedCell bg="gray-200" height={defaultHeaderRowHeight}>
            <Typography
              className="w-full text-center uppercase"
              color="text-base-color-fg-subtle"
              fontWeight="medium"
              tracking="tracking-wide"
            >
              Type
            </Typography>
          </PinnedCell>
          {columns.map((column, columnIndex) => (
            <Cell
              key={`column.${column.colId}`}
              testId={`immunization-header-cell-${column.colId}`}
              bg={
                todayColumnIndex === columnIndex && showTodayLine
                  ? 'primary'
                  : 'gray-200'
              }
              height={defaultHeaderRowHeight}
            >
              <Typography
                className="uppercase"
                color={
                  todayColumnIndex === columnIndex && showTodayLine
                    ? 'text-base-color-fg-emphasis'
                    : 'text-base-color-fg-subtle'
                }
                fontWeight="medium"
                tracking="tracking-wide"
              >
                {column.headerName}
              </Typography>

              {/* Today line overlay */}
              <TodayLine
                show={todayColumnIndex === columnIndex && showTodayLine}
                numberOfRows={rowsToShow.length}
              />
            </Cell>
          ))}
        </Row>

        {rowsToShow.map((row, index) => (
          <Row
            key={`row.${row.id}`}
            zIndex={40 - index}
            testId={`immunization-table-row-${index}`}
          >
            <PinnedCell
              bg={
                row.rowIsGrayedOut &&
                !row.rowIsAdministered &&
                (row.OR || row.AND)
                  ? 'gray-50'
                  : row.OR || row.AND
                    ? 'gray-100'
                    : 'white'
              }
            >
              <StackView
                className={
                  row.rowIsGrayedOut && !row.rowIsAdministered && 'opacity-50'
                }
              >
                <Typography fontWeight="medium">{row.immunization}</Typography>
                <StackView direction="row" space={50} alignItems="center">
                  {row.abbreviation && (
                    <>
                      <Typography>{row.abbreviation}</Typography>
                      <Typography textStyle="description">&bull;</Typography>
                    </>
                  )}
                  <Typography textStyle="description">
                    {row.totalDosesDescription}
                  </Typography>
                </StackView>
              </StackView>
              {showOnePlusDoseVaccines &&
                showNoDoseLinkedVaccines &&
                showNoDoseVaccines && (
                  <>
                    <OrPill row={row} />
                    <AndPill row={row} />
                  </>
                )}
            </PinnedCell>

            {row.columns.map((column) => (
              <Cell
                testId={`immunization-table-cell-${index}.${column.colId}`}
                key={`row.${row.id}.column.${column.colId}`}
                span={column.span}
                padding={0}
                border={column.subColumns.length ? 0 : 2}
                className={
                  row.rowIsGrayedOut && !row.rowIsAdministered && 'opacity-50'
                }
              >
                {column.subColumns.map((subColumn) => (
                  <SubCell
                    key={`row.${row.id}.column.${column.colId}.subcolumn${subColumn.name}`}
                    testId={`immunization-table-subcell-${index}.${column.colId}`}
                    subColumn={subColumn}
                    showImmunizationAnnotations={showImmunizationAnnotations}
                  ></SubCell>
                ))}
              </Cell>
            ))}
          </Row>
        ))}
      </ImmunizationTable>
    </div>
  )
}
export default ImmunizationRecordTable
