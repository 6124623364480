import { patientScoringSystems as DeveloPatientScoringSystems } from 'common/cdr/concepts/observations/patientScores/index'
import { patientScoringSystems } from 'common/data/patientScoringSystems'

import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'

import { RWSelectFieldProps } from '../SelectField'

export const PatientScoringSystemMultiRadioButtonField = (
  props: RWSelectFieldProps
) => {
  return (
    <MultiRadioButtonField
      {...props}
      radioPosition="right"
      radioStyle="card"
      defaultValue={props.defaultValue?.toString() ?? 'ACO_1_TO_6'}
      values={patientScoringSystems.map(({ name, value }) => ({
        label: name,
        description: DeveloPatientScoringSystems[value].description,
        value,
      }))}
    />
  )
}

export default PatientScoringSystemMultiRadioButtonField
