import {
  ComponentProps,
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react'

import clsx from 'clsx'

import { Label as RWLabel } from '@redwoodjs/forms'

export type LabelProps = ComponentProps<typeof RWLabel>
interface Props
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  > {
  htmlFor?: string
  text?: string
  children?: ReactNode
  className?: string
  cleanDefaultClasses?: boolean
}

const classes = [
  'block',
  'font-base-typography-interface-default-s-font-family',
  'text-base-typography-interface-default-s-font-size',
  'font-base-typography-interface-default-s-font-weight',
  'text-base-color-fg-muted',
]

const Label = ({
  htmlFor,
  text,
  children,
  cleanDefaultClasses,
  className,
  ...rest
}: Props): ReactElement => {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(!cleanDefaultClasses && classes, className)}
      {...rest}
    >
      {text || children}
    </label>
  )
}

export const FieldLabel = ({
  children,
  className,
  errorClassName,
  required,
  ...rest
}: LabelProps & { required?: boolean }) => {
  return (
    <RWLabel
      className={clsx(classes, className)}
      errorClassName={clsx(
        classes,
        errorClassName,
        'text-base-color-fg-danger'
      )}
      {...rest}
    >
      {children}
      {required && <span className="text-base-color-fg-muted">*</span>}
    </RWLabel>
  )
}

export default Label
