import {
  evaluate,
  extractValue,
  imperialToMetricMap,
  metricToImperialMap,
  unitDisplay,
  unitEnumToUCUMUnitMap,
} from 'common/unitConverter/unitConverter'
import { UnitInput } from 'types/graphql'

import Box from 'src/components/atoms/Box'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import { emptyPlaceholder } from 'src/lib/formatters'
import { PatientSectionV2 } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/PatientSectionV2'

import { AboRhBloodTypingSectionV2 } from './AboRhBloodTypingSectionV2'
import { VerticalDivider } from './BirthHistory'
import { CommentSection } from './CommentSection'
import { DeliverySectionV2 } from './DeliverySectionV2'
import { NaturalMotherHistorySectionV2 } from './NaturalMotherHistorySectionV2'
import { VALUE_DISPLAY_PRECISION } from './useBirthHistory'

const BirthHistoryV2 = ({
  patientId,
  testId,
}: {
  patientId: string
  testId: string
}) => {
  return (
    <Box
      horizontalPadding={100}
      verticalPadding={75}
      className={'rounded border border-gray-200 pb-core-space-100'}
      fullWidth
    >
      <StackView space={75} data-testid={testId}>
        <PatientSectionV2 patientId={patientId} />
        <Divider />
        <StackView direction="row" className="py-core-space-50">
          <Box className="w-1/2">
            <DeliverySectionV2 patientId={patientId} />
          </Box>
          <VerticalDivider />
          <StackView direction="row" className="w-2/3">
            <AboRhBloodTypingSectionV2 patientId={patientId} />
            <VerticalDivider />
            <NaturalMotherHistorySectionV2 patientId={patientId} />
          </StackView>
        </StackView>
        <FeatureFlagged flagName="BIRTH_HISTORY_COMMENT">
          <CommentSection patientId={patientId} />
        </FeatureFlagged>
      </StackView>
    </Box>
  )
}

export const RowDisplay = ({
  label,
  value,
  className,
}: {
  label: string
  value: string
  className?: string
}) => {
  return (
    <StackView
      direction="row"
      justifyContent="between"
      className={className ?? 'pt-core-space-75'}
    >
      <Typography
        color="text-base-color-fg-muted"
        textStyle="interface-default-s"
      >
        {label}
      </Typography>
      <Typography textStyle="interface-strong-s">
        {value ?? emptyPlaceholder}
      </Typography>
    </StackView>
  )
}

export const ColumnDisplay = ({
  label,
  value,
  className,
}: {
  label: string
  value: string
  className?: string
}) => {
  return (
    <StackView direction="col" className={className ?? 'pt-core-space-75'}>
      <Typography
        color="text-base-color-fg-muted"
        textStyle="interface-default-s"
      >
        {label}
      </Typography>
      <Typography
        textStyle="interface-strong-s"
        color="text-base-color-fg-muted"
      >
        {value ?? emptyPlaceholder}
      </Typography>
    </StackView>
  )
}

export const UnitValueDisplay = ({
  label,
  input,
  precision = VALUE_DISPLAY_PRECISION,
  displayAsInteger = false,
  direction = 'row',
}: {
  label: string
  input: UnitInput
  precision?: number
  displayAsInteger?: boolean
  direction?: 'row' | 'col'
}) => {
  if (!input) return null

  const { value, unit } = input

  const isConvertibleUnit =
    unit in metricToImperialMap || unit in imperialToMetricMap

  if (!isConvertibleUnit) {
    return (
      <StackView
        direction={direction}
        justifyContent={direction === 'row' ? 'between' : null}
      >
        <Typography color="text-base-color-fg-muted">{label}</Typography>
        <Typography textStyle="interface-strong-s">
          {value
            ? `${
                displayAsInteger && !isNaN(parseInt(value))
                  ? parseInt(value)
                  : Number(value).toFixed(precision)
              } ${unitDisplay[unitEnumToUCUMUnitMap[unit] ?? unit]}`
            : emptyPlaceholder}
        </Typography>
      </StackView>
    )
  }

  const valueInImperial = value
    ? extractValue(evaluate(value, unit, metricToImperialMap[unit] ?? unit))
    : null
  const valueInMetric = value
    ? extractValue(evaluate(value, unit, imperialToMetricMap[unit] ?? unit))
    : null

  return (
    <StackView direction="col">
      <Typography color="text-base-color-fg-muted">{label}</Typography>
      <StackView space={25} direction="row">
        <Typography textStyle="interface-strong-s">
          {valueInImperial
            ? `${Number(valueInImperial).toFixed(precision)} ${
                unitDisplay[metricToImperialMap[unit] ?? unit]
              }`
            : emptyPlaceholder}
        </Typography>
        <Typography
          textStyle="interface-default-xs"
          className="leading-snug"
          color="text-base-color-fg-subtle"
        >
          {valueInMetric
            ? `(${Number(valueInMetric).toFixed(precision)} ${
                unitDisplay[imperialToMetricMap[unit] ?? unit]
              })`
            : null}
        </Typography>
      </StackView>
    </StackView>
  )
}

export default BirthHistoryV2
