import { navigate, useLocation, useParams } from '@redwoodjs/router'

import { sidepanelRoute } from 'src/lib/routes'

const useBillingSidepanels = ({ encounterId }: { encounterId: string }) => {
  const location = useLocation()
  const params = useParams()
  const addVisitBillingCode = () => {
    navigate(
      sidepanelRoute(
        {
          route: `/encounters/${encounterId}/charges/new`,
          isVisitBillingCode: true,
        },
        location,
        params
      )
    )
  }

  const addAdditionalBillingCode = () => {
    navigate(
      sidepanelRoute(
        {
          route: `/encounters/${encounterId}/charges/new`,
          isVisitBillingCode: false,
        },
        location,
        params
      )
    )
  }

  const addAnyBillingCode = () => {
    navigate(
      sidepanelRoute(
        {
          route: `/encounters/${encounterId}/charges/new`,
          isVisitBillingCode: undefined,
        },
        location,
        params
      )
    )
  }

  const editBillingCode = (chargeItemId: string) => {
    navigate(
      sidepanelRoute(
        { route: `/charges/${chargeItemId}/edit` },
        location,
        params
      )
    )
  }
  return {
    addVisitBillingCode,
    addAdditionalBillingCode,
    addAnyBillingCode,
    editBillingCode,
  }
}

export default useBillingSidepanels
