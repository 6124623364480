import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { coordinationOfBenefitTypes } from 'src/data/coordinationOfBenefitTypes'

export const CoordinationOfBenefitsSelectField = (
  props: RWSelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={coordinationOfBenefitTypes.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default CoordinationOfBenefitsSelectField
