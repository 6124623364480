import { useEffect, useState } from 'react'

import { ChartBarIcon } from '@heroicons/react/24/solid'
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
} from '@mobiscroll/react'
import {
  addDays,
  endOfDay,
  isSaturday,
  isSunday,
  nextSaturday,
  previousSunday,
  startOfDay,
  subDays,
} from 'date-fns'
import isUndefined from 'lodash/isUndefined'

import { Form } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box/Box'
import DatePickerFilter from 'src/components/atoms/DatePickerFilter/DatePickerFilter'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import LocationFilter from 'src/components/molecules/LocationFilter/LocationFilter'
import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField'
import PractitionerFilter from 'src/components/molecules/PractitionerFilter/PractitionerFilter'
import SchedulePicker from 'src/components/molecules/SchedulePicker/SchedulePicker'
import ScheduleCalendar from 'src/components/organisms/ScheduleCalendar/ScheduleCalendar'
import {
  ChartingStatusColumn,
  ClaimStatusColumn,
  ConfirmationStatusColumn,
  HealthPlanColumn,
  PatientAgeColumn,
  PatientNameColumn,
  RoomNumberColumn,
  VisitCommentColumn,
  VisitPractitionerColumn,
  VisitStatusColumn,
  VisitTimeAndLocationColumn,
  VisitTypeAndChiefComplaintColumn,
} from 'src/components/organisms/ScheduleTable/config'
import ScheduleTable from 'src/components/organisms/ScheduleTable/ScheduleTable'
import {
  appointmentStatusFilterOptions,
  useLastSavedScheduleDateFilter,
  useScheduleFilter,
  useUpdateFilter,
} from 'src/hooks/useScheduleFilter/useScheduleFilter'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import { PrintScheduleButton } from './PrintScheduleButton'

export const PRACTITIONERS_AND_LOCATIONS = gql`
  query GetLocationsAndPractitioners {
    practitioners {
      id
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
    }
    locations {
      id
      name
      description
      availabilityTimes {
        id
        locationId
        configuration
        weekday
      }
    }
  }
`

const SchedulePage = () => {
  const { getCurrentUser } = useEmrAuth()
  const { visitDate, locationIds, practitionerIds, viewType, includeStatuses } =
    useScheduleFilter()
  const updateFilter = useUpdateFilter()
  const { data, loading } = useQuery(PRACTITIONERS_AND_LOCATIONS)
  const { isSidepanelOpen, sidepanelContext } = useSidepanel()
  const [updatedUser, setUpdatedUser] = useState(null)
  const lastSavedScheduleDateFilter = useLastSavedScheduleDateFilter()

  useEffect(() => {
    void getCurrentUser().then((user) => {
      setUpdatedUser(user)
    })
  }, [getCurrentUser])

  useEffect(() => {
    if (!visitDate) {
      updateFilter(
        'scheduleDateFilter',
        lastSavedScheduleDateFilter ?? new Date(),
        {
          saveSettings: false,
        }
      )
    }
  }, [updateFilter, visitDate, lastSavedScheduleDateFilter])

  useEffect(() => {
    if (updatedUser && isUndefined(includeStatuses)) {
      updateFilter(
        'scheduleAppointmentStatusFilter',
        updatedUser.userSettings.scheduleAppointmentStatusFilter,
        { saveSettings: true }
      )
    }
  }, [includeStatuses, updateFilter, updatedUser])

  const filteredPractitionerIds =
    practitionerIds?.length === 0
      ? data?.practitioners.map(({ id }) => id)
      : practitionerIds

  const filteredLocationIds =
    locationIds?.length === 0
      ? data?.locations.map(({ id }) => id)
      : locationIds

  const shouldDisplayWeeklySchedule =
    filteredPractitionerIds?.length == 1 && filteredLocationIds?.length == 1
  const scheduleStartDate = shouldDisplayWeeklySchedule
    ? isSunday(visitDate)
      ? startOfDay(visitDate)
      : startOfDay(previousSunday(visitDate))
    : subDays(startOfDay(visitDate), 1)

  const scheduleEndDate = shouldDisplayWeeklySchedule
    ? isSaturday(visitDate)
      ? endOfDay(visitDate)
      : endOfDay(nextSaturday(visitDate))
    : addDays(endOfDay(visitDate), 1)

  return (
    <StackView direction="row" className="h-full">
      <Box grow>
        <StackView className="h-full">
          <Box verticalPadding={100} horizontalPadding={75}>
            <StackView direction="row" justifyContent="between" wrap>
              <Typography textStyle="heading">Schedule</Typography>
              <Box data-testid="filter-bar">
                <StackView direction="row" gap={75} wrap>
                  {viewType == 'LIST' && (
                    <Form>
                      <MultiSelectDropdownField
                        testId="appointment-status-filter"
                        name="scheduleAppointmentStatusFilter"
                        icon={ChartBarIcon}
                        urlParamName="scheduleAppointmentStatusFilter"
                        emptyDisplayText="Status"
                        options={[...appointmentStatusFilterOptions]}
                        defaultValue={[]}
                        onToggleFilter={(values) =>
                          updateFilter(
                            'scheduleAppointmentStatusFilter',
                            values,
                            { saveSettings: true }
                          )
                        }
                      />
                    </Form>
                  )}
                  <DatePickerFilter
                    className="max-w-md"
                    value={visitDate}
                    disabled={
                      isSidepanelOpen &&
                      sidepanelContext.route === '/appointments/new'
                    }
                    onChange={(ev) => {
                      updateFilter('scheduleDateFilter', ev.value, {
                        saveSettings: true,
                      })
                    }}
                    renderCalendarHeader={() => (
                      <StackView
                        direction="row"
                        alignItems="center"
                        justifyContent="between"
                      >
                        <Box className="w-core-space-800">
                          <CalendarNav className="text-core-font-size-87" />
                        </Box>
                        <StackView
                          justifyContent="end"
                          direction="row"
                          fullWidth={false}
                          className="text-core-font-size-87"
                        >
                          <CalendarPrev />
                          <CalendarToday />
                          <CalendarNext />
                        </StackView>
                      </StackView>
                    )}
                  />
                  <PractitionerFilter
                    defaultDisplayText="Practitioners"
                    practitioners={data?.practitioners || []}
                  />
                  <Form>
                    <LocationFilter
                      defaultDisplayText="Locations"
                      name="locationFilter"
                      locations={data?.locations || []}
                    />
                  </Form>
                  <PrintScheduleButton />
                  <SchedulePicker />
                </StackView>
              </Box>
            </StackView>
          </Box>
          {/* When we click on schedule link. On the left side bar because we are
          not reloading we have visitDate as undefined for a small time. So we just check that it exists. */}
          {!loading && visitDate && (
            <Box
              horizontalPadding={75}
              verticalPadding={50}
              className="h-full overflow-hidden"
            >
              {viewType === 'CALENDAR' ? (
                <ScheduleCalendar
                  allLocations={data?.locations ?? []}
                  startDate={scheduleStartDate.toISOString()}
                  endDate={scheduleEndDate.toISOString()}
                  filteredPractitionerIds={filteredPractitionerIds}
                  filteredLocationIds={filteredLocationIds}
                />
              ) : (
                <StackView className="h-full">
                  <ScheduleTable
                    filters={{
                      locationIds,
                      practitionerIds,
                      startDate: startOfDay(visitDate).toISOString(),
                      endDate: endOfDay(visitDate).toISOString(),
                      includeStatuses,
                    }}
                    columnDefinitions={[
                      ConfirmationStatusColumn,
                      PatientNameColumn,
                      PatientAgeColumn,
                      HealthPlanColumn,
                      ...(practitionerIds?.length === 1
                        ? []
                        : [VisitPractitionerColumn]),
                      VisitTypeAndChiefComplaintColumn,
                      VisitTimeAndLocationColumn,
                      VisitCommentColumn,
                      VisitStatusColumn,
                      RoomNumberColumn,
                      ChartingStatusColumn,
                      ClaimStatusColumn,
                    ]}
                  />
                </StackView>
              )}
            </Box>
          )}
        </StackView>
      </Box>
    </StackView>
  )
}

export default SchedulePage
