import { PlusIcon } from '@heroicons/react/20/solid'

import { navigate, useLocation } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { sidepanelRoute } from 'src/lib/routes'

import VisitDiagnosesTable from '../PatientVisits/pages/OrdersDiagnosesPage/VisitDiagnosesTable'

import { useHospitalVisit } from './useHospitalVisit'

export const HospitalVisitDiagnosisSection = () => {
  const location = useLocation()
  const { data } = useHospitalVisit()

  if (!data?.hospitalVisit) return <LoadingSpinner />

  return (
    <StackView gap={100}>
      <SectionHeader
        title="Diagnoses"
        description="Only one visit diagnosis can be tagged as primary. Diagnoses added during the visit will be added to the patient's chart."
        actions={
          <Button
            data-testid="create-diagnosis-button"
            buttonStyle="secondary"
            icon={PlusIcon}
            text="Add"
            onClick={() =>
              navigate(
                sidepanelRoute(
                  {
                    route: `/encounters/${data?.hospitalVisit?.claim?.encounter?.id}/diagnoses/new`,
                  },
                  location
                )
              )
            }
          />
        }
      />
      <VisitDiagnosesTable
        encounterId={data?.hospitalVisit?.claim?.encounter?.id}
        primaryDiagnosis={
          data?.hospitalVisit?.claim?.encounter?.primaryDiagnosis
        }
        additionalDiagnoses={
          data?.hospitalVisit?.claim?.encounter?.additionalDiagnoses
        }
      />
    </StackView>
  )
}
