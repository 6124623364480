import { match } from 'ts-pattern'
import { Order } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import Typography from 'src/components/atoms/Typography'
import Modal from 'src/components/molecules/Modal'
import { orderIsPended } from 'src/data/orders'
import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'

export const PendedOrdersWarningModal = ({
  isOpen,
  onConfirm,
  onCancel,
  action,
}: {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  action: 'SIGN_VISIT' | 'SUBMIT_BILLING_CODES'
}) => {
  return (
    <Modal
      testId="visit-pended-orders-warning-modal"
      isOpen={isOpen}
      title="Pended orders"
      content={
        <Typography
          textStyle="body-s"
          color="text-base-color-fg-subtle"
          testId="visit-pended-orders-warning"
        >
          {match(action)
            .with(
              'SIGN_VISIT',
              () =>
                'There ore pended orders that are linked to this visit that should be canceled or signed prior to signing of the visit note.'
            )
            .with(
              'SUBMIT_BILLING_CODES',
              () =>
                'There are pended orders that are linked to this visit that should either be canceled, or signed and completed prior to submission of billing codes for this visit.'
            )
            .exhaustive()}
        </Typography>
      }
      modalStyle="warning"
      primaryButton={{
        text: match(action)
          .with('SIGN_VISIT', () => 'Sign note')
          .with('SUBMIT_BILLING_CODES', () => 'Submit billing codes')
          .exhaustive(),
        onClick: onConfirm,
      }}
      setIsOpen={(isOpen) => {
        if (isOpen) {
          return
        }

        onCancel()
      }}
    />
  )
}

export const usePendedOrdersConfirmation = ({
  patientId,
  appointmentId,
  orders,
}: {
  patientId: string
  appointmentId: string
  orders: Pick<Order, 'intent' | 'status'>[]
}) => {
  const [state, waitForConfirmation] = useConfirmation()

  const checkForPendedOrders = async () => {
    const hasPendedOrders = orders.some((order) => orderIsPended(order))

    if (!hasPendedOrders) {
      return { confirmed: true }
    }

    const result = await waitForConfirmation()

    if (!result.confirmed) {
      navigate(
        routes.patientChartsGlob({
          id: patientId,
          glob: `visits/${appointmentId}/orders`,
        })
      )
    }

    return result
  }

  return [state, checkForPendedOrders] as const
}
