import { patientStatuses } from 'common/data/patient'

import { SelectFieldProps } from 'src/components/atoms/SelectField'
import SelectField from 'src/components/atoms/SelectField/SelectField'
import { useFeatureFlagIsEnabled } from 'src/hooks/useFeatureFlagIsEnabled'

export const PatientStatusSelectField = (
  props: Omit<SelectFieldProps, 'options'>
) => {
  const collectionsEnabled = useFeatureFlagIsEnabled('COLLECTIONS_STATUS')
  const membershipEnabled = useFeatureFlagIsEnabled('PATIENT_MEMBER_STATUS')

  return (
    <SelectField
      {...props}
      options={patientStatuses
        .filter((status) =>
          !collectionsEnabled ? status.value !== 'COLLECTIONS' : true
        )
        .filter((status) =>
          !membershipEnabled ? status.value !== 'ACTIVE_MEMBER' : true
        )
        .map((status) => ({
          value: status.value,
          display: status.name,
        }))}
    />
  )
}
