import type { SelectPractitioners } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import { RWSelectFieldProps } from 'src/components/atoms/SelectField'
import PractitionerSelectField, {
  PractitionerSelectFieldProps,
} from 'src/components/Practitioner/PractitionerSelectField'

export const QUERY = gql`
  query SelectPractitioners {
    practitioners {
      id
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
    }
  }
`

export const Loading = (props: RWSelectFieldProps) => (
  <PractitionerSelectField practitioners={[]} {...props} />
)

export const Empty = (props: RWSelectFieldProps) => (
  <PractitionerSelectField practitioners={[]} {...props} />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  practitioners,
  ...rest
}: CellSuccessProps<SelectPractitioners> & PractitionerSelectFieldProps) => {
  return <PractitionerSelectField practitioners={practitioners} {...rest} />
}
