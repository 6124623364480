import { useEffect, useRef } from 'react'

import { text } from '@pdfme/schemas'
import { Designer } from '@pdfme/ui'
import { brushScriptMT } from 'common/pdf/fonts/brushScriptMT'
import { inter } from 'common/pdf/fonts/inter'
import { useParams } from 'react-router-dom'
import {
  FindPracticeFormQuery,
  FindPracticeFormQueryVariables,
  SetPracticeFormSchema,
  SetPracticeFormSchemaVariables,
} from 'types/graphql'

import { useIsBrowser } from '@redwoodjs/prerender/browserUtils'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import { QUERY as FIND_PRACTICE_FORM_QUERY } from 'src/components/PracticeFormCell'

const SET_SCHEMA = gql`
  mutation SetPracticeFormSchema($id: String!, $schema: JSON!) {
    setPracticeFormSchema(id: $id, schema: $schema) {
      id
      formSchemas
    }
  }
`

export const SidepanelPracticeFormConfigureFormSchema = () => {
  const { practiceFormId } = useParams()
  const containerRef = useRef<HTMLDivElement | null>()
  const designerRef = useRef<Designer | null>()

  const [setFormSchema, { loading: saving }] = useMutation<
    SetPracticeFormSchema,
    SetPracticeFormSchemaVariables
  >(SET_SCHEMA)

  const { data } = useQuery<
    FindPracticeFormQuery,
    FindPracticeFormQueryVariables
  >(FIND_PRACTICE_FORM_QUERY, {
    variables: {
      id: practiceFormId,
    },
  })

  const contentUrl = data?.practiceForm?.binary?.content?.url

  const browser = useIsBrowser()

  useEffect(() => {
    if (!browser) return
    if (!contentUrl) return
    if (!containerRef.current) return
    if (designerRef.current) return

    designerRef.current = new Designer({
      plugins: {
        text,
      },
      options: {
        font: {
          inter: {
            data: inter,
            fallback: true,
          },
          brushScriptMT: {
            data: brushScriptMT,
          },
        },
        requiredByDefault: true,
      },
      domContainer: containerRef.current,
      template: {
        basePdf: contentUrl,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        schemas: (data?.practiceForm?.formSchemas ?? []) as any,
      },
    })

    designerRef.current.onSaveTemplate((template) => {
      setFormSchema({
        variables: {
          id: practiceFormId,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          schema: template.schemas as any,
        },
        onCompleted: () => {
          toast.success('Saved practice form schema')
        },
      })
    })
  }, [contentUrl])

  return (
    <StackView className="h-full pb-core-space-600">
      <div ref={containerRef} />
      <StackView
        gap={50}
        direction="row"
        className="fixed bottom-core-space-50 right-core-space-50 z-50"
        fullWidth={false}
        justifyContent="end"
      >
        <Button
          onClick={() => {
            const page = designerRef.current.getPageCursor()

            const schemas = new Array(page).fill([])
            schemas[page] = [
              'signature',
              'signerFullName',
              'signerRelationshipToPatient',
              'signedDate',
            ].map((fieldName, i) => {
              return {
                ...text.propPanel.defaultSchema,
                position: {
                  x: 10 * i,
                  y: 10 * i,
                },
                required: true,
                name: fieldName,
                fontName: fieldName === 'signature' ? 'brushScriptMT' : 'inter',
                content: fieldName,
              }
            })
            designerRef.current.updateTemplate({
              ...designerRef.current.getTemplate(),
              schemas,
            })
          }}
          text="Add required fields"
          buttonStyle="secondary"
        />
        <Button
          onClick={() => designerRef.current.saveTemplate()}
          text="Save schema"
          loading={saving}
        />
      </StackView>
    </StackView>
  )
}
