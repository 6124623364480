import { Form } from '@redwoodjs/forms'

import Button from 'src/components/atoms/Button/Button'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import PatientLayout from 'src/layouts/PatientLayout/PatientLayout'

export const PatientPortalLoginPageLayout = ({
  title,
  description,
  tenantId,
  onSubmit,
  children,
  submitButton,
  extraDetails,
}: {
  title: string
  description: string
  tenantId: string
  onSubmit: (data) => void
  children: React.ReactNode
  submitButton: {
    text: string
    loading: boolean
  }
  extraDetails: React.ReactNode
}) => {
  return (
    <PatientLayout tenantId={tenantId}>
      <StackView>
        <StackView space={50} alignItems="center">
          <Typography textStyle="title-l" className="text-center">
            {title}
          </Typography>
          <Typography
            textStyle="interface-default-m"
            color="text-base-color-fg-muted"
            className="text-center"
          >
            {description}
          </Typography>
        </StackView>
        <Space space={150} />
        <Form onSubmit={onSubmit}>
          <StackView>
            {children}
            <Space space={200} />
            <Button
              type="submit"
              text={submitButton.text}
              buttonSize="l"
              loading={submitButton.loading}
              disabled={submitButton.loading}
            />
            <Space space={200} />
          </StackView>
        </Form>
        {extraDetails}
      </StackView>
    </PatientLayout>
  )
}
