import { ReactElement } from 'react'

import { brushScriptMT } from 'common/pdf/fonts/brushScriptMT'
import { inter } from 'common/pdf/fonts/inter'
import { interBold } from 'common/pdf/fonts/interBold'
import { interBoldItalic } from 'common/pdf/fonts/interBoldItalic'
import { interItalic } from 'common/pdf/fonts/interItalic'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'
import ReactDOMServer from 'react-dom/server'

import { isBrowser } from '@redwoodjs/prerender/browserUtils'

import { Footer } from './components/common'
import './index.css'
import { FooterMetadata, PatientMetadata } from './components/common/Footer'

const PDF_CONTENT_WIDTH_MM = 195.9
const PDF_CONTENT_WINDOW_WIDTH_PX = 800
const PDF_HTML2CANVAS_CLASS = 'generate-html2canvas-pdf'

export const isMac = isBrowser
  ? navigator.userAgent.toLowerCase().includes('mac')
  : false

const addFooter = async ({
  footerMetadata,
  doc,
  pageNumber,
  totalPages,
}: {
  footerMetadata: FooterMetadata
  doc: JsPDF
  pageNumber: number
  totalPages: number
}) => {
  return new Promise((resolve) => {
    const html = ReactDOMServer.renderToString(
      <Footer
        footerMetadata={footerMetadata}
        pageNumber={pageNumber}
        totalPages={totalPages}
      />
    )
    doc = doc.setPage(pageNumber)

    void doc.html(html, {
      callback: (doc) => {
        resolve(doc)
      },
      y: pageNumber * doc.internal.pageSize.getHeight() - 15,
      width: PDF_CONTENT_WIDTH_MM,
      windowWidth: PDF_CONTENT_WINDOW_WIDTH_PX,
      margin: [0, 10, 0, 10],
    })
  })
}

export const PDFWrapper = ({
  children,
  useHtml2Canvas = false,
}: {
  children: ReactElement
  useHtml2Canvas?: boolean
}) => {
  // Wrapping in a div with letterSpacing fixes issues where fonts render with weird white space.
  // Windows renders decimal pixels in webkit browsers with rounding, which which is why we
  // use separate values. These values are in no way scientific, but rather eyeballed.
  return (
    <div
      className={`pdf ${PDF_HTML2CANVAS_CLASS}`}
      style={{
        letterSpacing: isMac ? '0.01px' : '.6px',
        width: useHtml2Canvas && '215.9mm',
      }}
    >
      {children}
    </div>
  )
}

const getHtml2CanvasHtmlString = async (htmlString: string) => {
  const dom = new DOMParser().parseFromString(htmlString, 'text/html')

  document.body.appendChild(
    dom.getElementsByClassName(PDF_HTML2CANVAS_CLASS)[0]
  )

  const canvas = await html2canvas(
    document.getElementsByClassName(PDF_HTML2CANVAS_CLASS)[0] as HTMLElement
  )

  const canvasBase64 = canvas.toDataURL('image/png')

  htmlString = ReactDOMServer.renderToString(
    <img src={canvasBase64} alt="pdf" />
  )

  const canvases = document.getElementsByClassName(PDF_HTML2CANVAS_CLASS)
  if (canvases.length > 0) {
    for (const canvas of canvases) {
      canvas.remove()
    }
  }

  return htmlString
}

const generatePDF = async ({
  forPatient,
  component,
  callback,
  footer = true,
  useHtml2Canvas = false,
}: {
  forPatient?: PatientMetadata
  component: ReactElement | string
  callback: (doc: JsPDF) => void
  footer?: boolean
  useHtml2Canvas?: boolean
}) => {
  const doc = new JsPDF({
    orientation: 'portrait',
    format: 'letter', // 8.5 in x 11 in
    unit: 'mm', // 215.9 mm x 279.4 mm
  })

  doc.addFileToVFS('Inter-normal.ttf', inter)
  doc.addFont('Inter-normal.ttf', 'Inter', 'normal')

  doc.addFileToVFS('Inter-BoldItalic.ttf', interBoldItalic)
  doc.addFont('Inter-BoldItalic.ttf', 'Inter', 'bolditalic')

  doc.addFileToVFS('Inter-Italic.ttf', interItalic)
  doc.addFont('Inter-Italic.ttf', 'Inter', 'italic')

  doc.addFileToVFS('Inter-Bold.ttf', interBold)
  doc.addFont('Inter-Bold.ttf', 'Inter', 'bold')

  doc.addFileToVFS('Brush Script MT-normal.ttf', brushScriptMT)
  doc.addFont('Brush Script MT-normal.ttf', 'Brush Script MT', 'normal')

  let htmlString
  if (typeof component === 'string') {
    htmlString = component
  } else {
    const html = <PDFWrapper>{component}</PDFWrapper>
    htmlString = ReactDOMServer.renderToString(html)
  }

  if (useHtml2Canvas) {
    htmlString = await getHtml2CanvasHtmlString(htmlString)
  }

  void doc.html(htmlString, {
    callback: async function (doc) {
      if (footer) {
        const pages = doc.getNumberOfPages()
        for (let i = 1; i <= pages; i++) {
          await addFooter({
            footerMetadata: forPatient ? { patient: forPatient } : null,
            doc,
            pageNumber: i,
            totalPages: pages,
          })
        }
      }

      callback(doc)
    },
    autoPaging: 'text',
    width: PDF_CONTENT_WIDTH_MM, // target width of the content in the pdf in mm (subtract margin)
    windowWidth: PDF_CONTENT_WINDOW_WIDTH_PX, // window width in CSS pixels to scale the generated content
    margin: [10, 10, 20, 10],
  })
}

export const useGeneratePDF = () => generatePDF
