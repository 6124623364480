import { useRef, useState } from 'react'

import { useFloating, flip, offset, autoUpdate, size } from '@floating-ui/react'
import {
  AdjustmentsVerticalIcon,
  ArrowUturnDownIcon,
  CheckIcon,
  ChevronUpDownIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { isEmpty } from 'lodash'
import { useOnClickOutside } from 'usehooks-ts'

import { Controller, useFormContext } from '@redwoodjs/forms'

import NoSearchResultsIcon from 'src/components/illustrations/NoSearchResultsIcon'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'
import {
  StatusIndicator,
  StatusIndicatorColor,
} from 'src/components/StatusIndicator/StatusIndicator'

import Button from '../Button'
import Checkbox from '../Checkbox'
import { Input } from '../InputField'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import { RWSelectFieldProps } from '../SelectField'
import StackView from '../StackView'
import Typography, { TextStyle } from '../Typography/Typography'

type DropdownV2Size = 's' | 'm' | 'l'

type DropdownV2MenuItemProps = Omit<
  MenuItemProps,
  'isMultiSelect' | 'onClickMenuItem' | 'option' | 'selectedOptions'
>

type DropdownV2MenuProps = Omit<
  MenuProps,
  'isOpen' | 'onClearSelected' | 'options'
>

type DropdownV2MenuTriggerProps = Omit<
  MenuTriggerProps,
  'disabled' | 'onClickTrigger' | 'selectedOptions'
>

export type DropdownV2Props = Omit<RWSelectFieldProps, 'name'> & {
  disabled?: boolean
  hasError?: boolean
  isMultiSelect?: boolean
  menuItemProps?: DropdownV2MenuItemProps
  menuProps?: DropdownV2MenuProps
  menuTriggerProps?: DropdownV2MenuTriggerProps
  onSetValue?: (value: string | string[]) => void
  options: DropdownV2Option[]
  selectedValues: string | string[]
  setValue: (value: string | string[]) => void
  testId?: string
}

export type DropdownV2FieldProps = Omit<
  DropdownV2Props,
  'selectedValues' | 'setValue'
> & {
  name: string
}

export type DropdownV2Option = {
  disabled?: boolean
  icon?: React.FC<React.ComponentProps<'svg'>>
  label: string
  secondaryLabel?: string
  showMenuItemCheckbox?: boolean
  statusIndicatorColor?: StatusIndicatorColor
  value: string
}

type MenuTriggerProps = {
  disabled: boolean
  hasError?: boolean
  maxOptionsToDisplay?: number
  menuTriggerLeftIcon?: React.FC<React.ComponentProps<'svg'>>
  menuTriggerRightIcon?: React.FC<React.ComponentProps<'svg'>>
  onClickTrigger: () => void
  placeholder?: string
  selectedOptions: DropdownV2Option[]
  showMenuTriggerLeftIcon?: boolean
  showMenuTriggerRightIcon?: boolean
  size?: DropdownV2Size
  testId?: string
}

export type MenuProps = {
  emptySearchResultsDescription?: string
  emptySearchResultsMessage?: string
  floatSelectedToTop?: boolean
  isOpen: boolean
  menuClassName?: string
  menuFilters?: DropdownV2Option[]
  menuItemsLoading?: boolean
  menuSearchPlaceholder?: string
  onClearSelected?: () => void
  onClickMenuFilter?: (value: string) => void
  onUpdateSearchInput?: (value: string) => void
  options: DropdownV2Option[]
  selectedMenuFilterValues?: string[]
  showEmptySearchResultsMessage?: boolean
  showMenuClearSelected?: boolean
  showMenuSearch?: boolean
  testId?: string
}

type MenuItemProps = {
  isMultiSelect: boolean
  onClickMenuItem?: (value: string) => void
  option: DropdownV2Option
  selectedMenuItemOptionIcon?: React.FC<React.ComponentProps<'svg'>>
  selectedOptions: DropdownV2Option[]
  showMenuItemCheckbox?: boolean
  showMenuItemIcon?: boolean
  showMenuItemSelectedOptionIcon?: boolean
  showMenuItemStatusIndicator?: boolean
  testId?: string
}

const MenuTrigger = ({
  disabled,
  hasError,
  maxOptionsToDisplay = 3,
  menuTriggerLeftIcon: LeftIcon = ChevronUpDownIcon,
  menuTriggerRightIcon: RightIcon = ChevronUpDownIcon,
  onClickTrigger,
  placeholder = 'Select',
  selectedOptions,
  showMenuTriggerLeftIcon = false,
  showMenuTriggerRightIcon = true,
  size = 's',
  testId,
}: MenuTriggerProps) => {
  const classes = [
    'w-full',
    'cursor-pointer',
    'bg-comp-textfield-color-clean-enabled-bg',
    'border-base-border-width-selectable-s',
    'border-comp-textfield-color-clean-enabled-border',
    'rounded-base-border-radius-selectable-s',
    'shadow-base-box-shadow-selectable-s',

    'hover:bg-comp-textfield-color-clean-hover-bg',
    'hover:border-comp-textfield-color-clean-hover-border',
    'hover:shadow-base-box-shadow-selectable-s',

    'focus-within:!bg-comp-textfield-color-clean-focused-bg',
    'focus-within:!border-comp-textfield-color-clean-focused-border',
    'focus-within:!shadow-base-box-shadow-clean-focus-border',
    'focus:outline-none',
  ]

  const errorClasses = [
    'bg-comp-textfield-color-dirty-enabled-bg',
    'border-comp-textfield-color-dirty-enabled-border',

    'hover:bg-comp-textfield-color-dirty-hover-bg',
    'hover:border-comp-textfield-color-dirty-hover-border',

    'focus-within:!bg-comp-textfield-color-dirty-focused-bg',
    'focus-within:!border-comp-textfield-color-dirty-focused-border',
    'focus-within:!shadow-base-box-shadow-dirty-focus-border',
  ]

  const sizeClasses = {
    s: ['h-base-size-selectable-s', 'p-base-space-selectable-inset-s'],
    m: ['h-base-size-selectable-m', 'p-base-space-selectable-inset-m'],
    l: ['h-base-size-selectable-l', 'p-base-space-selectable-inset-m'],
  }

  const typographyClasses = [
    'truncate',
    'text-comp-textfield-color-clean-enabled-placeholder',
    'hover:text-comp-textfield-color-clean-hover-placeholder',
    'focus:text-comp-textfield-color-clean-focused-placeholder',
  ]

  const errorTypographyClasses = [
    'text-comp-textfield-color.dirty-enabled-placeholder',
    'hover:text-comp-textfield-color-dirty-hover-placeholder',
    'focus:text-comp-textfield-color-dirty-focused-placeholder',
  ]

  const selectedTypographyClasses = [
    'whitespace-nowrap',
    'text-comp-textfield-color-clean-enabled-content',
    'hover:text-comp-textfield-color-clean-hover-content',
    'focus:text-comp-textfield-color-clean-focused-content',
  ]

  const errorSelectedTypographyClasses = [
    'text-comp-textfield-color-dirty-enabled-content',
    'hover:text-comp-textfield-color-dirty-hover-content',
    'focus:text-comp-textfield-color-dirty-focused-content',
  ]

  const typographySizeClasses: Record<DropdownV2Size, TextStyle> = {
    s: 'interface-default-xs' as TextStyle,
    m: 'interface-default-s' as TextStyle,
    l: 'interface-default-m' as TextStyle,
  }

  const disabledClasses = [
    'pointer-events-none',
    'opacity-base-opacity-disabled',
  ]

  return (
    <StackView
      tabIndex={0}
      className={clsx(
        classes,
        sizeClasses[size],
        hasError && errorClasses,
        disabled && disabledClasses
      )}
      gap={50}
      direction="row"
      alignItems="center"
      justifyContent="between"
      onClick={() => !disabled && onClickTrigger()}
      testId={`${testId}-menu-trigger`}
    >
      {showMenuTriggerLeftIcon && (
        <LeftIcon className="h-base-size-icon-xs w-base-size-icon-xs" />
      )}
      <StackView
        alignItems="center"
        className="grow truncate"
        direction="row"
        gap={25}
      >
        {selectedOptions.length === 0 ? (
          <Typography
            className={clsx(
              typographyClasses,
              hasError && errorTypographyClasses
            )}
            textStyle={typographySizeClasses[size]}
          >
            {placeholder}
          </Typography>
        ) : selectedOptions.length === 1 ? (
          <>
            <Typography
              className={clsx(
                selectedTypographyClasses,
                !selectedOptions[0].secondaryLabel && 'truncate',
                hasError && errorSelectedTypographyClasses
              )}
              textStyle={typographySizeClasses[size]}
            >
              {selectedOptions[0].label}
            </Typography>
            {selectedOptions[0].secondaryLabel && (
              <Typography
                className={clsx(
                  typographyClasses,
                  hasError && errorTypographyClasses
                )}
                textStyle={typographySizeClasses[size]}
              >
                {selectedOptions[0].secondaryLabel}
              </Typography>
            )}
          </>
        ) : (
          <Typography
            className={clsx(
              selectedTypographyClasses,
              hasError && errorSelectedTypographyClasses
            )}
            textStyle={typographySizeClasses[size]}
          >
            {selectedOptions
              .map((o) => o.label)
              .slice(0, maxOptionsToDisplay)
              .join(', ')}
            {selectedOptions.length > maxOptionsToDisplay && '...'}
          </Typography>
        )}
      </StackView>
      {showMenuTriggerRightIcon && (
        <RightIcon className="h-base-size-icon-xs w-base-size-icon-xs" />
      )}
    </StackView>
  )
}

export const Menu = ({
  emptySearchResultsDescription = 'Try searching for something else',
  emptySearchResultsMessage = 'No results found',
  floatSelectedToTop = false,
  isMultiSelect,
  isOpen,
  menuClassName,
  menuFilters = [],
  menuItemsLoading = false,
  menuSearchPlaceholder = 'Search...',
  onClearSelected,
  onClickMenuItem,
  onClickMenuFilter,
  onUpdateSearchInput,
  options = [],
  selectedMenuItemOptionIcon,
  selectedMenuFilterValues = [],
  selectedOptions = [],
  showEmptySearchResultsMessage = false,
  showMenuClearSelected = false,
  showMenuItemCheckbox,
  showMenuItemIcon,
  showMenuItemSelectedOptionIcon,
  showMenuItemStatusIndicator,
  showMenuSearch = false,
  testId,
}: MenuProps & Omit<MenuItemProps, 'option'>) => {
  const [search, setSearch] = useState<string>('')

  const onChangeSearchInput = (e: { target: { value: string } }) => {
    const value = e.target.value
    setSearch(value)
    onUpdateSearchInput?.(value)
  }

  const menuClasses = [
    'w-full',
    'bg-white',
    'border-base-color-border-subtle',
    'border-base-border-width-container-s',
    'rounded-base-border-radius-container-l',
    'max-h-core-size-2000 overflow-y-auto',
    'last:border-b-0',
  ]

  const searchInputClasses = [
    'border-none',
    'text-base-color-fg-subtle',
    'p-base-space-selectable-inset-s',

    'text-base-typography-interface-default-s-font-size',
    'font-base-typography-interface-default-s-font-weight',
    'font-base-typography-interface-default-s-font-family',
    'leading-base-typography-interface-default-s-line-height',

    'focus:outline-none',
  ]

  const optionsSubset = floatSelectedToTop
    ? options.filter(
        (o) => !selectedOptions.map((so) => so.value).includes(o.value)
      )
    : options

  let filteredOptions: DropdownV2Option[]

  if (isEmpty(search)) {
    filteredOptions = optionsSubset
  } else {
    filteredOptions = optionsSubset.filter(
      (o) =>
        o.label.toLowerCase().includes(search.toLowerCase()) ||
        o.secondaryLabel?.toLowerCase().includes(search.toLowerCase())
    )
  }

  if (!isOpen) return
  return (
    <StackView
      className={clsx(menuClasses, menuClassName)}
      testId={`${testId}-menu`}
      divider
    >
      <>
        {showMenuSearch && isEmpty(menuFilters) ? (
          <StackView
            className="p-base-space-selectable-inset-xs"
            direction="row"
            fullWidth
            gap={50}
          >
            <input
              className={clsx(searchInputClasses)}
              onChange={onChangeSearchInput}
              placeholder={menuSearchPlaceholder}
              style={{ boxShadow: 'none' }}
              data-testid={`${testId}-menu-search-input`}
            />
          </StackView>
        ) : showMenuSearch || !isEmpty(menuFilters) ? (
          <StackView
            className="px-core-space-150 py-core-space-100"
            direction="row"
            fullWidth
            gap={50}
          >
            {showMenuSearch && (
              <div className="grow">
                <Input
                  iconRight={MagnifyingGlassIcon}
                  placeholder={menuSearchPlaceholder}
                  name="search"
                  onChange={onChangeSearchInput}
                />
              </div>
            )}
            {!isEmpty(menuFilters) && (
              <div className="relative">
                <PopoverButton
                  buttonProps={{
                    buttonStyle: 'secondary',
                    className: 'w-min',
                    icon: AdjustmentsVerticalIcon,
                    text: 'Filter',
                  }}
                  panelWidth="w-min"
                  renderPanel={() => (
                    <Menu
                      isMultiSelect
                      isOpen
                      onClickMenuItem={onClickMenuFilter}
                      options={menuFilters}
                      selectedOptions={menuFilters.filter((f) =>
                        selectedMenuFilterValues.includes(f.value)
                      )}
                      showMenuClearSelected={false}
                      showMenuItemCheckbox
                      showMenuItemSelectedOptionIcon={false}
                      showMenuSearch={false}
                    />
                  )}
                />
              </div>
            )}
          </StackView>
        ) : undefined}
      </>

      {floatSelectedToTop && selectedOptions.length > 0 && (
        <StackView
          className="p-base-space-selectable-inset-xs"
          testId={`${testId}-menu-float-to-top`}
        >
          {selectedOptions.map((option) => (
            <MenuItem
              isMultiSelect={isMultiSelect}
              key={option.value}
              onClickMenuItem={onClickMenuItem}
              option={option}
              selectedMenuItemOptionIcon={selectedMenuItemOptionIcon}
              selectedOptions={selectedOptions}
              showMenuItemCheckbox={showMenuItemCheckbox}
              showMenuItemIcon={showMenuItemIcon}
              showMenuItemSelectedOptionIcon={showMenuItemSelectedOptionIcon}
              showMenuItemStatusIndicator={showMenuItemStatusIndicator}
            />
          ))}
        </StackView>
      )}
      <StackView
        className="p-base-space-selectable-inset-xs"
        testId={`${testId}-menu-items`}
      >
        {menuItemsLoading ? (
          <div className="p-core-space-100">
            <LoadingSpinner />
          </div>
        ) : filteredOptions.length > 0 ? (
          <>
            {filteredOptions.map((option) => (
              <MenuItem
                isMultiSelect={isMultiSelect}
                key={option.value}
                onClickMenuItem={onClickMenuItem}
                option={option}
                selectedMenuItemOptionIcon={selectedMenuItemOptionIcon}
                selectedOptions={selectedOptions}
                showMenuItemCheckbox={showMenuItemCheckbox}
                showMenuItemIcon={showMenuItemIcon}
                showMenuItemSelectedOptionIcon={showMenuItemSelectedOptionIcon}
                showMenuItemStatusIndicator={showMenuItemStatusIndicator}
              />
            ))}
          </>
        ) : showEmptySearchResultsMessage ? (
          <StackView
            justifyContent="center"
            alignItems="center"
            space={150}
            className="p-base-space-container-inset-m"
          >
            <NoSearchResultsIcon />
            <StackView className="text-center" justifyContent="center">
              <Typography
                color="text-base-color-fg-muted"
                textStyle="interface-default-m"
              >
                {emptySearchResultsMessage}
              </Typography>
              <Typography
                color="text-base-color-fg-subtle"
                textStyle="interface-default-s"
              >
                {emptySearchResultsDescription}
              </Typography>
            </StackView>
          </StackView>
        ) : undefined}
      </StackView>
      {showMenuClearSelected && onClearSelected && (
        <StackView className="p-base-space-selectable-inset-xs" gap={25}>
          <Button
            buttonStyle="ghost"
            icon={ArrowUturnDownIcon}
            onClick={onClearSelected}
            text="Clear selected"
          />
        </StackView>
      )}
    </StackView>
  )
}

const MenuItem = ({
  onClickMenuItem = () => {},
  option,
  selectedMenuItemOptionIcon: SelectedMenuItemOptionIcon = CheckIcon,
  selectedOptions,
  showMenuItemCheckbox = false,
  showMenuItemIcon = false,
  showMenuItemSelectedOptionIcon = true,
  showMenuItemStatusIndicator = false,
}: MenuItemProps) => {
  const isSelectedOption = selectedOptions.some(
    (selectedOption) => selectedOption.value === option.value
  )

  const shouldShowCheckbox = (
    option: DropdownV2Option,
    defaultShowCheckbox: boolean
  ) => {
    // If option has its own checkbox preference, use that
    if ('showMenuItemCheckbox' in option) {
      return option.showMenuItemCheckbox
    }
    // Otherwise use the default checkbox setting
    return defaultShowCheckbox
  }

  const menuItemClasses = [
    'cursor-pointer',
    'p-base-space-selectable-inset-s',
    'rounded-base-border-radius-selectable-s',
    'hover:bg-base-color-bg-subtle',
    'hover:text-base-color-fg-default',
  ]

  const disabledMenuItemClasses = ['!cursor-default']

  return (
    <StackView
      className={clsx(
        menuItemClasses,
        option.disabled && disabledMenuItemClasses
      )}
      direction="row"
      alignItems="center"
      gap={50}
      onClick={option.disabled ? () => {} : () => onClickMenuItem(option.value)}
      testId={option.value}
    >
      {shouldShowCheckbox(option, showMenuItemCheckbox) && (
        <Checkbox
          name={`${option.value}-checkbox`}
          value={option.value}
          checked={isSelectedOption}
          disabled={option.disabled}
          className="w-core-space-100 !items-center"
        />
      )}
      {showMenuItemIcon && (
        <>
          {option.icon && (
            <option.icon className="h-base-size-icon-xs w-base-size-icon-xs" />
          )}
        </>
      )}
      {showMenuItemStatusIndicator && (
        <StatusIndicator color={option.statusIndicatorColor ?? 'purple'} />
      )}
      <StackView
        className="grow truncate"
        direction="row"
        gap={25}
        alignItems="center"
      >
        <Typography
          className={clsx([
            'whitespace-nowrap',
            !option.secondaryLabel && 'truncate',
          ])}
          color="text-base-color-fg-muted"
          textStyle="interface-default-s"
        >
          {option.label}
        </Typography>
        {option.secondaryLabel && (
          <Typography
            className="truncate"
            color="text-base-color-fg-subtle"
            textStyle="interface-default-s"
          >
            {option.secondaryLabel}
          </Typography>
        )}
      </StackView>

      {showMenuItemSelectedOptionIcon && isSelectedOption && (
        <SelectedMenuItemOptionIcon className="h-[15px] w-[15px] fill-base-color-fg-brand" />
      )}
    </StackView>
  )
}

export const DropdownV2 = ({
  disabled = false,
  hasError = false,
  isMultiSelect = true,
  menuItemProps,
  menuProps,
  menuTriggerProps,
  onSetValue,
  options = [],
  selectedValues = [],
  setValue,
  testId,
}: DropdownV2Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const ref = useRef(null)
  useOnClickOutside(ref, () => setIsOpen(false))

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    middleware: [
      flip(),
      offset(8),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            zIndex: 9999,
          })
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
  })

  const selectedOptions = Array.isArray(selectedValues)
    ? selectedValues
        ?.map((value) => options.find((o) => o.value === value))
        .filter(Boolean)
    : selectedValues
      ? [options.find((o) => o.value === selectedValues)]
      : []

  const classes = ['w-full']

  const onClickMenuItem = (value: string) => {
    const isSelectedOption = selectedValues?.includes(value)

    let values: string | string[]
    if (isMultiSelect && Array.isArray(selectedValues)) {
      if (!isSelectedOption) {
        values = [...selectedValues, value]
      } else {
        values = selectedValues.filter((v) => v !== value)
      }
    } else {
      values = value
      setIsOpen(false)
    }

    setValue(values)
    onSetValue?.(values)
  }

  const onClearSelected = () => {
    if (isMultiSelect) {
      setValue([])
    } else {
      setValue(null)
    }
  }

  return (
    <div data-testid={testId} ref={ref}>
      <StackView className={clsx(classes)}>
        <div ref={refs.setReference}>
          <MenuTrigger
            disabled={disabled}
            hasError={hasError}
            onClickTrigger={() => setIsOpen(!isOpen)}
            selectedOptions={selectedOptions}
            testId={testId}
            {...menuTriggerProps}
          />
        </div>

        <div ref={refs.setFloating} style={floatingStyles}>
          <Menu
            isMultiSelect={isMultiSelect}
            isOpen={isOpen && !disabled}
            onClearSelected={onClearSelected}
            onClickMenuItem={onClickMenuItem}
            options={options}
            selectedOptions={selectedOptions}
            testId={testId}
            {...menuProps}
            {...menuItemProps}
          />
        </div>
      </StackView>
    </div>
  )
}

export const DropdownV2Field = ({
  name,
  onSetValue,
  validation,
  testId,
  ...rest
}: DropdownV2FieldProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={name}
      rules={validation}
      render={({ field, formState }) => (
        <DropdownV2
          hasError={!!formState.errors[name]}
          onSetValue={onSetValue}
          selectedValues={field.value}
          setValue={(value) => {
            field.onChange(value)
          }}
          testId={testId ?? `${name}-dropdown`}
          {...rest}
        />
      )}
    />
  )
}
