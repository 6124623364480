import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { isEmpty, every, omit } from 'lodash'
import type {
  AppointmentPatientCheckInQuery,
  UpdatePatientResidenceInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import AddressFormInputList from 'src/components/Address/AddressFormInputList/AddressFormInputList'
import InheritedAddressField from 'src/components/Address/InheritedAddressField/InheritedAddressField'
import PatientResidesWithSelect from 'src/components/atoms/PatientResidesWithSelectField/PatientResidesWithSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import checkAge from 'src/lib/checkAge'
import { calculateAge, formatAddress } from 'src/lib/formatters'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext'
import { VERIFY_PATIENT_ATTRIBUTE_MUTATION } from 'src/providers/context/AppointmentCheckInContext/schema'

export const UPDATE_PATIENT_RESIDENCE = gql`
  mutation updatePatientResidenceMutation(
    $id: String!
    $input: UpdatePatientResidenceInput!
  ) {
    updatePatientResidence(id: $id, input: $input) {
      id
      residenceVerifiedAt
      contactInformation {
        id
        homeAddress {
          id
          line1
          line2
          city
          postalCode
          state
        }
        mailingAddress {
          id
          line1
          line2
          city
          postalCode
          state
        }
      }
      patientRelatedPersonRelationships {
        id
        doesResideWith
        relationshipType
        patientId
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
        }
      }
    }
  }
`

export const buildResidesWithIds = (
  relationships: AppointmentPatientCheckInQuery['appointment']['patient']['patientRelatedPersonRelationships']
) => {
  const residesWithIds = relationships.reduce(
    (acc, relationship) => {
      acc[relationship.relatedPerson.id] = relationship.doesResideWith
      return acc
    },
    {} as Record<string, boolean>
  )

  residesWithIds['livesAlone'] = every(
    Object.values(residesWithIds),
    (doesResideWith) => doesResideWith === false
  )

  return residesWithIds
}

export const buildResidesWithDisplay = (
  residesWithIds: Record<string, boolean>,
  relationships: AppointmentPatientCheckInQuery['appointment']['patient']['patientRelatedPersonRelationships']
) => {
  return residesWithIds['livesAlone']
    ? 'Does not live with caregiver'
    : relationships
        .filter((relationship) => residesWithIds[relationship.relatedPerson.id])
        .map(
          (relationship) =>
            `${formatDisplayName(relationship.relatedPerson)} (${
              relationshipTypeDisplay[relationship.relationshipType]
            })`
        )
        .join(', ')
}

interface ResidenceVerificationState
  extends Omit<UpdatePatientResidenceInput, 'residesWithIds'> {
  residesWithIds: Record<string, boolean>
}

export const PatientResidenceVerificationCard = () => {
  const [verifyPatientResidence] = useMutation(
    VERIFY_PATIENT_ATTRIBUTE_MUTATION
  )

  const {
    appointmentData: { patient },
    checkInVerification,
  } = useAppointmentCheckIn()

  const { verificationRequirement, lastVerifiedAt } =
    checkInVerification['PATIENT']['RESIDENCE']

  const onVerify = () => {
    verifyPatientResidence({
      variables: {
        patientId: patient.id,
        verificationType: 'RESIDENCE',
      },
    })
  }

  const preSubmitResidence = (data: ResidenceVerificationState) => {
    const { residesWithIds, ...rest } = data

    if (residesWithIds['livesAlone']) {
      if (rest.useHomeAddressAsMailingAddress && rest.homeAddress)
        delete rest.mailingAddress

      return {
        variables: {
          id: patient.id,
          input: {
            residesWithIds: ['livesAlone'],
            ...rest,
          },
        },
      }
    }

    const residesWithIdsArray = residesWithIds
      ? Object.keys(residesWithIds).filter((key) => residesWithIds[key])
      : []

    return {
      variables: {
        id: patient.id,
        input: {
          residesWithIds: residesWithIdsArray,
        },
      },
    }
  }
  const ageRestrictedAbilities = checkAge(calculateAge(patient.birthDate))
  const residesWithIds = buildResidesWithIds(
    patient.patientRelatedPersonRelationships
  )
  const currentlyResidesWithDisplay = buildResidesWithDisplay(
    residesWithIds,
    patient.patientRelatedPersonRelationships
  )

  const contactInformation =
    patient.contactInformation ??
    patient.patientRelatedPersonRelationships.find(
      (relationship) => relationship.doesResideWith
    )?.relatedPerson?.contactInformation

  const useHomeAddressAsMailingAddress =
    contactInformation?.mailingAddress?.id ===
    contactInformation?.homeAddress?.id

  const defaultValues = {
    residesWithIds,
    useHomeAddressAsMailingAddress,
    homeAddress: omit(contactInformation?.homeAddress, ['id', '__typename']),
    mailingAddress: omit(contactInformation?.mailingAddress, [
      'id',
      '__typename',
    ]),
  }

  const formMethods = useForm<ResidenceVerificationState>({
    defaultValues,
  })

  const residesWithIdsInput = formMethods.watch('residesWithIds')

  if (!verificationRequirement) {
    return null
  }

  return (
    <DataVerificationCard<
      ResidenceVerificationState,
      UpdatePatientResidenceInput
    >
      title="Residence"
      updateMutation={UPDATE_PATIENT_RESIDENCE}
      verificationOptions={{
        onVerify,
        verificationRequirement,
      }}
      preSubmit={preSubmitResidence}
      lastVerifiedAt={lastVerifiedAt}
      formMethods={formMethods}
      data={[
        {
          name: 'residesWithIds',
          label: 'Resides with',
          alignItems: 'start',
          required: true,
          inputProps: {
            name: 'residesWithIds',
            patientRelatedPersonRelationships:
              patient.patientRelatedPersonRelationships,
            validation: {
              validate: (result) => {
                if (
                  isEmpty(result) ||
                  every(Object.values(result), (residesWith) => !residesWith)
                ) {
                  return "Resides with is required. If the patient lives alone, please select 'Does not live with caregiver'"
                }

                if (
                  !isEmpty(result) &&
                  result['livesAlone'] &&
                  (patient.legalStatus === 'DEPENDENT' ||
                    !ageRestrictedAbilities.canLiveAlone)
                ) {
                  return 'Patients who are dependents should be living with one or more caregivers. Please select the caregiver(s) with whom the patient resides with.'
                }

                if (
                  !isEmpty(result) &&
                  result['livesAlone'] &&
                  !patient.contactInformation
                ) {
                  return 'Patients must have a contact information record to live alone, please add contact information for the patient to proceed.'
                }
              },
            },
          },
          value: residesWithIds,
          displayValue: currentlyResidesWithDisplay,
          formInputComponent: PatientResidesWithSelect,
        },
        {
          name: 'homeAddress',
          label: 'Home address',
          alignItems: 'start',
          inputProps: {
            name: 'homeAddress',
          },
          hideFormInput:
            isEmpty(residesWithIdsInput) ||
            !residesWithIdsInput['livesAlone'] ||
            !ageRestrictedAbilities.canLiveAlone,
          value: contactInformation?.homeAddress,
          displayValue: formatAddress(contactInformation?.homeAddress),
          formInputComponent: AddressFormInputList,
        },
        {
          name: 'mailingAddress',
          label: 'Mailing address',
          alignItems: 'start',
          inputProps: {
            name: 'mailingAddress',
            useInheritedAddressName: 'useHomeAddressAsMailingAddress',
            useInheritedAddressDescription:
              'Use home address as mailing address',
          },
          hideFormInput:
            isEmpty(residesWithIdsInput) ||
            !residesWithIdsInput['livesAlone'] ||
            !ageRestrictedAbilities.canLiveAlone,
          value: contactInformation?.mailingAddress,
          displayValue: useHomeAddressAsMailingAddress
            ? 'Same as home address'
            : formatAddress(contactInformation?.mailingAddress),
          formInputComponent: InheritedAddressField,
        },
      ]}
    />
  )
}

export default PatientResidenceVerificationCard
