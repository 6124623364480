import { Fragment, useState } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  CalendarIcon,
  HomeIcon,
  ListBulletIcon,
  XMarkIcon,
  CalendarDaysIcon,
} from '@heroicons/react/20/solid'
import { CreditCardIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { hasAccessToPortalFeature } from 'common/patientPortalAuth'
import { formatDisplayName } from 'common/utils'
import {
  GetUnreadMessageTaskCount,
  GetUnreadMessageTaskCountVariables,
} from 'types/graphql'

import { routes, NavLink } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

import { usePatientPortalAuth } from 'src/auth'
import Badge from 'src/components/atoms/Badge/Badge'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { formatPhoneNumber } from 'src/lib/formatters'
import { usePatientPortalContext } from 'src/providers/context/PatientPortalContext/PatientPortalContext'

import { SIDEPANEL_PORTAL_ID } from './utils'

const UNREAD_MESSAGE_QUERY = gql`
  query GetUnreadMessageTaskCount {
    unreadFamilyMessageTaskCount
  }
`

const Sidebar = ({
  closeButton,
  closeMenu,
  isMobileMenu,
}: {
  closeButton?: React.ReactNode
  closeMenu: () => void
  isMobileMenu: boolean
}) => {
  const { currentUser } = usePatientPortalAuth()
  const { tenant } = usePatientPortalContext()
  const tenantId = tenant.id

  const { data } = useQuery<
    GetUnreadMessageTaskCount,
    GetUnreadMessageTaskCountVariables
  >(UNREAD_MESSAGE_QUERY)

  const linkClasses = [
    'rounded-base-border-radius-selectable-s',
    'text-base-color-fg-default',
    'hover:text-base-color-fg-default',
  ]

  return (
    <StackView
      className="h-full px-core-space-100 py-core-space-150"
      justifyContent="between"
      testId="sidenav"
    >
      <StackView gap={100}>
        <StackView direction="row" justifyContent="between" alignItems="center">
          <img
            data-testid="practice-logo"
            className={
              isMobileMenu ? 'h-core-space-150 w-auto' : 'h-auto w-full'
            }
            src={`/img/logos/${tenantId}.png`}
            alt="Practice logo"
          />

          {closeButton}
        </StackView>
        <StackView>
          {[
            {
              id: 'home',
              label: 'Home',
              icon: HomeIcon,
              to: routes.patientPortalHome({
                tenantPortalKey: tenant.portalKey,
              }),
            },
            {
              id: 'medical-records',
              label: 'Records',
              icon: CalendarIcon,
              to: routes.patientPortalMedicalRecordsRoot({
                tenantPortalKey: tenant.portalKey,
              }),
              hide: !hasAccessToPortalFeature({
                user: currentUser,
                feature: 'MEDICAL_RECORDS',
              }),
            },
            {
              id: 'messages',
              label: 'Messages',
              icon: ListBulletIcon,
              to: routes.patientPortalMessageList({
                tenantPortalKey: tenant.portalKey,
              }),
              notification: data?.unreadFamilyMessageTaskCount ? (
                <Badge
                  text={data.unreadFamilyMessageTaskCount}
                  color="purple"
                />
              ) : null,
              hide: !hasAccessToPortalFeature({
                user: currentUser,
                feature: 'MESSAGES',
              }),
            },
            {
              id: 'payments',
              label: 'Payments',
              icon: CreditCardIcon,
              to: routes.patientPortalPayments({
                tenantPortalKey: tenant.portalKey,
              }),
              hide: !hasAccessToPortalFeature({
                user: currentUser,
                feature: 'BILLING',
              }),
            },
            {
              id: 'scheduleVisit',
              label: 'Schedule Visit',
              icon: CalendarDaysIcon,
              to: routes.portalPatientSelfSchedulingIntro({
                tenantPortalKey: tenant.portalKey,
              }),
              hide:
                !currentUser.featureFlags.includes(
                  'PATIENT_SELF_SCHEDULING_SETTINGS'
                ) ||
                !hasAccessToPortalFeature({
                  user: currentUser,
                  feature: 'SCHEDULE_VISIT',
                }),
            },
          ]
            .filter((item) => !item.hide)
            .map(({ id, label, icon: Icon, to, notification }) => {
              return (
                <NavLink
                  to={to}
                  key={id}
                  className={clsx(linkClasses)}
                  activeClassName={clsx([
                    ...linkClasses,
                    'bg-base-color-bg-subtle',
                  ])}
                  matchSubPaths={id === 'messages'}
                  onClick={() => closeMenu()}
                >
                  <StackView
                    direction="row"
                    justifyContent="between"
                    alignItems="center"
                  >
                    <StackView
                      direction="row"
                      alignItems="center"
                      className="p-core-space-50"
                      gap={50}
                    >
                      <Icon className="h-base-size-icon-xs w-base-size-icon-xs" />
                      <Typography
                        textStyle="interface-strong-s"
                        color="text-base-color-fg-muted"
                      >
                        {label}
                      </Typography>
                    </StackView>
                    {notification}
                  </StackView>
                </NavLink>
              )
            })}
        </StackView>
      </StackView>

      <StackView gap={100} alignItems="start">
        <StackView className="border-b-core-border-width-10 border-t-core-border-width-10 border-base-color-bg-subtle py-core-space-100">
          <Typography
            textStyle="interface-strong-xs"
            color="text-base-color-fg-muted"
          >
            {formatDisplayName(currentUser)}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {formatPhoneNumber(currentUser.mobileNumber)}
          </Typography>
        </StackView>

        <img
          className="h-core-space-150 w-auto"
          src="/img/logo-grayscale.svg"
          alt="Develo"
        />
      </StackView>
    </StackView>
  )
}

export const PatientPortalLayout = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { tenant } = usePatientPortalContext()
  const tenantId = tenant.id
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const closeMobileMenu = () => setMobileMenuOpen(false)

  return (
    <div className="flex h-full">
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />

      {/* Sidebar */}
      <section className="hidden w-core-space-1100 border-r-core-border-width-10 border-base-color-bg-subtle md:block">
        <Sidebar closeMenu={closeMobileMenu} isMobileMenu={false} />
      </section>

      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20 md:hidden"
          onClose={setMobileMenuOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative flex w-fit flex-1 flex-col bg-white">
                <Sidebar
                  closeButton={
                    <Button
                      buttonStyle="ghost"
                      onClick={() => setMobileMenuOpen(false)}
                      icon={XMarkIcon}
                      buttonSize="l"
                    />
                  }
                  closeMenu={closeMobileMenu}
                  isMobileMenu
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Content area */}
      <div className="flex flex-1 flex-col overflow-hidden">
        <header className="w-full md:hidden">
          <StackView
            direction="row"
            className="px-core-space-100 py-core-space-75"
            justifyContent="between"
            alignItems="center"
          >
            <img
              data-testid="practice-logo"
              className="h-core-space-150 w-auto"
              src={`/img/logos/${tenantId}.png`}
              alt="Practice logo"
            />

            <Button
              buttonStyle="ghost"
              icon={Bars3Icon}
              onClick={() => setMobileMenuOpen(true)}
            />
          </StackView>
        </header>

        {/* Main content */}
        <main className="flex flex-1 items-stretch justify-center overflow-y-auto">
          {children}
        </main>
      </div>

      <div id={SIDEPANEL_PORTAL_ID} />
    </div>
  )
}
