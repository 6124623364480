import { isValidElement } from 'react'

import {
  imperialToMetricMap,
  unitDisplay,
  Unit,
  imperialToMetricDisplayMap,
  metricToImperialMap,
  extractValue,
  evaluate,
} from 'common/unitConverter/unitConverter'
import { isObject } from 'lodash'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { MapEntry } from 'src/data/utils'
import { emptyPlaceholder, formatDateDisplay } from 'src/lib/formatters'

interface ColumnDisplayProps {
  label: string
  entry?: MapEntry
  component?: JSX.Element
  displayUnit?: Unit
  displayUnits?: Unit[]
  precision?: number
  suffix?: string
  metricDisplay?: boolean
  splitDisplay?: boolean
  useUnitDisplay?: boolean
}

export const ColumnDisplay = ({
  label,
  entry,
  component,
  precision = 2,
  metricDisplay = false,
  useUnitDisplay = false,
}: ColumnDisplayProps) => {
  if (isValidElement(component)) {
    return (
      <StackView direction="col">
        <Typography color="text-base-color-fg-muted">{label}</Typography>
        {component}
      </StackView>
    )
  }

  if (!entry?.display && !entry?.value)
    return (
      <StackView direction="col">
        <Typography color="text-base-color-fg-muted">{label}</Typography>
        <StackView space={25} direction="row">
          <Typography size="xl">{emptyPlaceholder}</Typography>
        </StackView>
      </StackView>
    )

  let valueDisplay = ''
  let isMetricOrImperialDisplayUnit = false
  let units = []
  if (entry.display) {
    units = Object.keys(entry.display)
    isMetricOrImperialDisplayUnit =
      units.some((unit) => unit in imperialToMetricMap) ||
      units.some((unit) => unit in metricToImperialMap)
    if (isObject(entry.display)) {
      valueDisplay = units
        .map((unit) => {
          return `${
            isMetricOrImperialDisplayUnit
              ? Number(entry.display[unit]).toFixed(precision)
              : entry.display[unit] // to handle
          }${' '}${useUnitDisplay ? unitDisplay[unit] : unit}`
        })
        .join(' ')
    } else {
      valueDisplay = entry.display
    }
  } else if (!isObject(entry.value)) {
    const formattedDate = formatDateDisplay(entry.value)
    if (formattedDate) {
      valueDisplay = formattedDate
    } else {
      valueDisplay = entry.value
    }
  }

  const buildMetricValueDisplay = () => {
    if (
      !units?.length ||
      (!imperialToMetricMap[units[0]] && !imperialToMetricDisplayMap[units[0]])
    ) {
      return ''
    }

    if (
      !imperialToMetricDisplayMap[units[0]] ||
      imperialToMetricMap[units[0]] === imperialToMetricDisplayMap[units[0]]
    ) {
      return `(${Number(entry.value).toFixed(precision)} ${
        imperialToMetricMap[units[0]]
      })`
    }

    return `(${Number(
      extractValue(
        evaluate(
          entry.value.toString(),
          imperialToMetricMap[units[0]],
          imperialToMetricDisplayMap[units[0]]
        )
      )
    ).toFixed(precision)} ${imperialToMetricDisplayMap[units[0]]})`
  }

  return (
    <StackView direction="col">
      <Typography color="text-base-color-fg-muted">{label}</Typography>
      <StackView space={25} direction="row" alignItems="center">
        <Typography size="xl">{valueDisplay}</Typography>
        {metricDisplay && isMetricOrImperialDisplayUnit && (
          <Typography size="s" color="text-base-color-fg-muted">
            {buildMetricValueDisplay()}
          </Typography>
        )}
      </StackView>
    </StackView>
  )
}
