import { useRef, useMemo } from 'react'

import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { EMPTY_OBSERVATION_VALUE_PLACEHOLDER } from 'common/cdr/concepts/observations/index'
import { format, parseISO } from 'date-fns'
import groupBy from 'lodash/groupBy'
import { FindPatientLabs } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'

import { useLabsQuery } from './useLabs'

type LabTableRow = {
  name: string
  unit: string
  dataByDate: {
    [key: string]: string
  }
}

const defaultColDef: ColDef = {
  minWidth: 200,
}

const nameColDef: ColDef = {
  colId: 'name',
  pinned: 'left',
  cellStyle: { display: 'inline-block' },
  cellClass: 'bg-gray-100',
  minWidth: 280,
  maxWidth: 400,
  autoHeight: true,
  cellRenderer: ({ data }: ICellRendererParams<LabTableRow>) => {
    return (
      <StackView className="whitespace-pre-line py-2" justifyContent="center">
        <StackView
          direction="row"
          justifyContent="center"
          alignItems="center"
          className="flex-grow"
          space={100}
        >
          <Typography fontWeight="semibold" className="flex-1">
            {data.name}
          </Typography>
          <Typography textStyle="description">{data.unit}</Typography>
        </StackView>
      </StackView>
    )
  },
}

const LabCellRenderer = (params: ICellRendererParams<LabTableRow>) => {
  const key = params.colDef.colId

  return (
    <StackView justifyContent="center">
      <Typography>{params.data.dataByDate[key] || '-'}</Typography>
    </StackView>
  )
}

const transformLabs = (labs: FindPatientLabs['patient']['labs']) => {
  return Object.values(
    labs.reduce((acc, lab) => {
      if (lab.value.value === EMPTY_OBSERVATION_VALUE_PLACEHOLDER) return acc
      const name = lab.name
      if (!acc[name]) {
        acc[name] = {
          name: lab.name,
          unit:
            lab.value.unit ||
            lab.components?.map((c) => c.value.unit)?.join(' / '),
          dataByDate: {},
        }
      }

      const date = format(parseISO(lab.effectiveAt), 'yyyy-MM-dd')
      acc[name].dataByDate[date] = lab.value.unit
        ? lab.value.value
        : lab.value.display

      return acc
    }, {}) as { [key: string]: LabTableRow }
  ).sort((a, b) => a.name.localeCompare(b.name))
}

const buildColDefs = (labs: FindPatientLabs['patient']['labs']) => {
  const columnDataKeys = Object.keys(
    groupBy(labs, (lab) => format(parseISO(lab.effectiveAt), 'yyyy-MM-dd'))
  )
    .sort((a, b) => {
      return a.localeCompare(b)
    })
    .reverse()

  return [
    nameColDef,
    ...columnDataKeys.map((key) => {
      return {
        colId: key,
        headerName: format(parseISO(key), 'MMM dd, yyyy'),
        cellRenderer: LabCellRenderer,
      }
    }),
  ]
}

const LabsTable = ({ patientId }: { patientId: string }) => {
  const gridRef = useRef<AgGridReact>()
  const { labs } = useLabsQuery(patientId)

  const { labsByDateAndCode, colDefs } = useMemo(() => {
    if (!labs) return {}

    return {
      labsByDateAndCode: transformLabs(labs),
      colDefs: buildColDefs(labs),
    }
  }, [labs])

  return (
    <Table
      testId="labs-table"
      innerRef={gridRef}
      rowData={labsByDateAndCode}
      domLayout="autoHeight"
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={colDefs}
      animateRows={true}
      noRowsOverlayComponentParams={{ message: 'No labs recorded' }}
      pagination={false}
    />
  )
}

export default LabsTable
