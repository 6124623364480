import { Redirect, routes, useLocation } from '@redwoodjs/router'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'

import { useVisit, useVisitQuery } from './useVisit'
import { visitFlowNavConfig } from './VisitFlowLayout'

export const VisitNavigationGuard = ({ children }) => {
  const location = useLocation()
  const { patientId, appointmentId } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)

  if (loading) return <LoadingSpinner />

  const pathParts = location.pathname.split('/')
  const visitFlowStep = pathParts[pathParts.length - 1]
  const matchingVisitFlowNavItem = visitFlowNavConfig.find(
    (item) => item.to === visitFlowStep
  )

  if (
    !matchingVisitFlowNavItem?.encounterModes?.includes(visit.encounterMode)
  ) {
    const firstEnabledVisitFlowNavItem = visitFlowNavConfig.find((item) =>
      item.encounterModes.includes(visit.encounterMode)
    )
    return (
      <Redirect
        to={routes.patientChartsGlob({
          id: patientId,
          glob: `visits/${appointmentId}/${firstEnabledVisitFlowNavItem.to}`,
        })}
        options={{
          replace: true,
        }}
      />
    )
  }

  return children
}
