import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { countries } from 'src/data/countries'

export const CountrySelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      defaultValue="USA"
      options={countries.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default CountrySelectField
