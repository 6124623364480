import { useCallback, useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import {
  RelatedPerson,
  SearchCaregivers,
  SearchCaregiversVariables,
} from 'types/graphql'
import { useDebounceCallback } from 'usehooks-ts'

import { RegisterOptions, useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import RenderRelatedPersonSearchResults from 'src/components/molecules/Search/RenderRelatedPersonSearchResults'
import { formatDateDisplay } from 'src/lib/formatters'

export interface ExistingCaregiverSearchProps {
  onSearchResultClickCallback?: (
    id: string,
    relatedPerson: Partial<RelatedPerson>
  ) => void
  onSearchButtonClickCallback?: (patientIds: string[]) => void
  onClearSelection?: () => void
  name?: string
  disabled?: boolean
  placeholder?: string
  validation?: RegisterOptions
  defaultPatientId?: string
  showIcon?: boolean
  testId?: string
}

const MIN_QUERY_LENGTH = 2

export const SEARCH_CAREGIVERS_QUERY = gql`
  query SearchCaregivers($input: RelatedPersonSearchInput!) {
    searchCaregivers(input: $input) {
      id
      givenName
      familyName
      birthDate
      contactInformation {
        id
        homeAddress {
          id
          line1
          line2
          city
          postalCode
          state
          country
        }
        mailingAddress {
          id
          line1
          line2
          city
          postalCode
          state
          country
        }
      }
    }
  }
`

const ExistingCaregiverSearch = ({
  onSearchResultClickCallback,
  onSearchButtonClickCallback,
  name = 'ExistingCaregiverSearchInput',
  disabled,
  placeholder,
  onClearSelection,
  showIcon = true,
  testId,
  ...rest
}: ExistingCaregiverSearchProps) => {
  const [selectedCaregiver, onSelectCaregiver] = useState()
  const { trigger, setValue, register, unregister } = useFormContext()

  const [query, setQuery] = useState('')

  const [searchCaregivers, { data, loading }] = useLazyQuery<
    SearchCaregivers,
    SearchCaregiversVariables
  >(SEARCH_CAREGIVERS_QUERY)

  const searchResults =
    !loading && query.length >= MIN_QUERY_LENGTH
      ? (data?.searchCaregivers ?? [])
      : []

  const searchCallback = useCallback(
    (text: string) => {
      if (text.length < MIN_QUERY_LENGTH) return

      void searchCaregivers({
        variables: {
          input: {
            searchQuery: text,
          },
        },
      })
    },
    [searchCaregivers]
  )
  const debouncedSearch = useDebounceCallback(searchCallback, 300)

  const onsearchCaregiversType = (event) => {
    const searchQuery = event.target.value
    setQuery(searchQuery)
    trigger(name)
    void debouncedSearch(searchQuery)
  }

  const displayName = `${name}DisplayText`
  const valueName = `${name}Id`

  useEffect(() => {
    register(displayName)
    register(valueName)
    return () => {
      unregister(valueName)
      unregister(displayName)
    }
  }, [displayName, register, unregister, valueName])

  const onSearchResultClick = useCallback(
    (value, relatedPerson) => {
      onSelectCaregiver(relatedPerson)
      setValue(name, relatedPerson)
      setValue(valueName, relatedPerson.id)
      setValue(
        displayName,
        `${formatDisplayName(relatedPerson)} - ${formatDateDisplay(
          relatedPerson.birthDate
        )}`
      )
      if (onSearchResultClickCallback) {
        onSearchResultClickCallback(value, relatedPerson)
      }
    },
    [
      onSearchResultClickCallback,
      onSelectCaregiver,
      displayName,
      name,
      valueName,
      setValue,
    ]
  )
  const clearSearch = () => {
    setValue(valueName, undefined)
    setValue(name, undefined)
    onSelectCaregiver(undefined)
    setValue(displayName, '')
    setQuery('')
  }

  const hasXIcon = query.length > 0 && !disabled

  return (
    <Box className="relative">
      <StackView direction="row" space={50}>
        <StackView direction="col">
          <InputField
            autoComplete="off"
            data-testid={testId ?? 'search-existing-caregiver-input'}
            name={displayName}
            id={name}
            placeholder={
              typeof placeholder === 'string'
                ? placeholder
                : 'Search for an existing caregiver'
            }
            onChange={(e) => {
              onsearchCaregiversType(e)
            }}
            onClickIconRight={() => {
              if (hasXIcon) {
                clearSearch()
                if (onClearSelection) {
                  onClearSelection()
                }
              }
            }}
            iconLeft={MagnifyingGlassIcon}
            iconRight={!showIcon ? undefined : hasXIcon ? XMarkIcon : undefined}
            validation={{
              required: rest?.validation?.required
                ? 'An existing caregiver must be selected'
                : false,
              pattern: {
                message:
                  'Search by name, DOB (mm/dd/yyyy), or phone number (1234567890)',
                value: /([a-zA-Z0-9._-]+)/g,
              },
            }}
          />
          {!selectedCaregiver && !loading && searchResults && (
            <RenderRelatedPersonSearchResults
              name={displayName}
              isSearchActive={query.length >= MIN_QUERY_LENGTH}
              data-testid="search-existing-caregiver-results"
              searchResults={searchResults}
              onSearchResultClick={onSearchResultClick}
            />
          )}
        </StackView>
        {onSearchButtonClickCallback && (
          <Button
            disabled={loading || !searchResults.length}
            onClick={() => {
              clearSearch()
              onSearchButtonClickCallback(
                searchResults.map((relatedPerson) => relatedPerson.id)
              )
            }}
          >
            Search
          </Button>
        )}
      </StackView>
    </Box>
  )
}

export default ExistingCaregiverSearch
