import { observationConcepts } from 'common/cdr/concepts/observations/index'

import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography, {
  TextStyle,
  TypographyColor,
} from 'src/components/atoms/Typography'

const ListItem = ({
  value,
  textStyle = 'normal',
  color,
  shouldShow = true,
}: {
  value: string
  textStyle?: TextStyle
  color?: TypographyColor
  shouldShow?: boolean
}) => {
  return shouldShow === true && value ? (
    <li className=" list-none pl-4 -indent-3.5 before:pr-1 before:content-['\2012']">
      <Typography color={color} textStyle={textStyle}>
        {value}
      </Typography>
    </li>
  ) : null
}

const emptyPlaceholder = '\u2014'

export const ContraceptiveSummary = ({ contraceptives }): JSX.Element => {
  if (!contraceptives) return null

  const hasBarrierAnswers = Object.keys(contraceptives?.barrier).some(
    (key) => contraceptives?.barrier[key] === true
  )
  const hasNonBarrierAnswers = Object.keys(contraceptives?.nonBarrier).some(
    (key) => contraceptives?.nonBarrier[key] === true
  )

  if (!hasBarrierAnswers && !hasNonBarrierAnswers) {
    return <Typography>{emptyPlaceholder}</Typography>
  }

  return (
    <StackView space={50} className="pt-1">
      {hasBarrierAnswers && (
        <ul>
          <Typography textStyle="subtitle">Barrier</Typography>
          <ul>
            <ListItem
              value={
                observationConcepts['CONTRACEPTIVE_BARRIER_CERVICAL_CAP']
                  .display
              }
              shouldShow={contraceptives?.barrier?.cervicalCap === true}
            />
            <ListItem
              value={
                observationConcepts['CONTRACEPTIVE_BARRIER_DIAPHRAGM'].display
              }
              shouldShow={contraceptives?.barrier?.diaphragm === true}
            />

            <ListItem
              value={
                observationConcepts['CONTRACEPTIVE_BARRIER_FEMALE_CONDOM']
                  .display
              }
              shouldShow={contraceptives?.barrier?.femaleCondom === true}
            />
            <ListItem
              value={
                observationConcepts['CONTRACEPTIVE_BARRIER_MALE_CONDOM'].display
              }
              shouldShow={contraceptives?.barrier?.maleCondom === true}
            />
            <ListItem
              value={
                observationConcepts['CONTRACEPTIVE_BARRIER_SPERMICIDE'].display
              }
              shouldShow={contraceptives?.barrier?.spermicide === true}
            />
            <ListItem
              value={
                observationConcepts['CONTRACEPTIVE_BARRIER_SPONGE'].display
              }
              shouldShow={contraceptives?.barrier?.sponge === true}
            />
            <ListItem
              value={
                observationConcepts['CONTRACEPTIVE_BARRIER_VAGINAL_GEL'].display
              }
              shouldShow={contraceptives?.barrier?.vaginalGel === true}
            />
          </ul>
          <Space />
        </ul>
      )}

      {hasNonBarrierAnswers && (
        <>
          <ul>
            <Typography textStyle="subtitle">Non-Barrier</Typography>
            <ul>
              <ListItem
                value={
                  observationConcepts[
                    'CONTRACEPTIVE_NONBARRIER_FEMALE_STERILIZATION'
                  ].display
                }
                shouldShow={
                  contraceptives?.nonBarrier?.femaleSterilization === true
                }
              />
              <ListItem
                value={
                  observationConcepts[
                    'CONTRACEPTIVE_NONBARRIER_MALE_STERILIZATION'
                  ].display
                }
                shouldShow={
                  contraceptives?.nonBarrier?.maleSterilization === true
                }
              />
              <ListItem
                value={
                  observationConcepts['CONTRACEPTIVE_NONBARRIER_IMPLANT']
                    .display
                }
                shouldShow={contraceptives?.nonBarrier?.implant === true}
              />
              <ListItem
                value={
                  observationConcepts['CONTRACEPTIVE_NONBARRIER_INJECTION']
                    .display
                }
                shouldShow={contraceptives?.nonBarrier?.injection === true}
              />
              <ListItem
                value={
                  observationConcepts['CONTRACEPTIVE_NONBARRIER_INTRAUTERINE']
                    .display
                }
                shouldShow={contraceptives?.nonBarrier?.intrauterine === true}
              />
              <ListItem
                value={
                  observationConcepts['CONTRACEPTIVE_NONBARRIER_ORAL_PILL']
                    .display
                }
                shouldShow={contraceptives?.nonBarrier?.oralPill === true}
              />
              <ListItem
                value={
                  observationConcepts['CONTRACEPTIVE_NONBARRIER_TRANSDERMAL']
                    .display
                }
                shouldShow={contraceptives?.nonBarrier?.transdermal === true}
              />
              <ListItem
                value={
                  observationConcepts['CONTRACEPTIVE_NONBARRIER_VAGINAL_RING']
                    .display
                }
                shouldShow={contraceptives?.nonBarrier?.vaginalRing === true}
              />
            </ul>
          </ul>
        </>
      )}
    </StackView>
  )
}
