import { isEmpty } from 'lodash'

import StackView from 'src/components/atoms/StackView'
import { VerticalDivider } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/BirthHistory'
import { useBirthHistoryQuery } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'
import { ColumnDisplay } from 'src/pages/PatientChartsPage/PatientHistory/ColumnDisplay'

export const PatientSection = ({ patientId }: { patientId: string }) => {
  const { birthHistory } = useBirthHistoryQuery(patientId)
  if (isEmpty(birthHistory)) return null

  const {
    gestationalAgeAtBirth,
    birthWeight,
    birthHeadCircumference,
    birthLength,
    dischargeWeight,
    dischargeDate,
    lastTotalSerumBilirubin,
    totalSerumBilirubinDate,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = birthHistory as any

  return (
    <StackView
      testId="birth-history-patient-section"
      direction="row"
      space={75}
    >
      <StackView direction="col" className="w-1/4" space={75}>
        <ColumnDisplay
          label="Gestational age at birth"
          entry={gestationalAgeAtBirth}
          metricDisplay
          useUnitDisplay
        />
        <ColumnDisplay
          label="Discharge weight"
          entry={dischargeWeight}
          metricDisplay
          useUnitDisplay
        />
      </StackView>
      <VerticalDivider />
      <StackView direction="col" className="w-1/4" space={75}>
        <ColumnDisplay
          label="Birth weight"
          entry={birthWeight}
          metricDisplay
          useUnitDisplay
        />
        <ColumnDisplay
          label="Discharge date"
          entry={dischargeDate}
          metricDisplay
          useUnitDisplay
        />
      </StackView>
      <VerticalDivider />
      <StackView direction="col" className="w-1/4" space={75}>
        <ColumnDisplay label="Birth length" entry={birthLength} metricDisplay />
        <ColumnDisplay
          label="Last total serum bilirubin"
          entry={lastTotalSerumBilirubin}
          metricDisplay
          useUnitDisplay
        />
      </StackView>
      <VerticalDivider />
      <StackView direction="col" className="w-1/4" space={75}>
        <ColumnDisplay
          label="Birth head circumference"
          entry={birthHeadCircumference}
          metricDisplay
        />
        <ColumnDisplay
          label="Total serum bilirubin date"
          entry={totalSerumBilirubinDate}
          metricDisplay
        />
      </StackView>
    </StackView>
  )
}
