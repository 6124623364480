import { useState } from 'react'

import { capitalize } from '@medplum/core'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { membershipPaymentFrequencyDisplay } from 'common/data/membershipPlans'
import { formatMoneyInCents } from 'common/utils'
import { compareDesc } from 'date-fns'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import {
  PatientMembershipPlanCharges,
  PatientMembershipPlanChargesVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Badge from 'src/components/atoms/Badge/Badge'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import EmptyDataContent from 'src/components/molecules/EmptyDataContent/EmptyDataContent'
import { ListItemCell } from 'src/components/molecules/ListRowItem/ListItemCell'
import { ListRowItem } from 'src/components/molecules/ListRowItem/ListRowItem'
import { PaginationControls } from 'src/components/molecules/PaginationControls/PaginationControls'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { PAYMENT_STATUS } from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'

const pageSize = 5

const QUERY = gql`
  query PatientMembershipPlanCharges($patientId: String!) {
    patient(id: $patientId) {
      id
      membershipPlanCharges {
        id
        patientPaymentIntentCharges {
          id
          paymentAmountCents
          patientPaymentIntent {
            id
            status
            createdAt
            tilledPaymentMethod {
              id
              brand
              holderName
              last4
              expirationMonth
              expirationYear
            }
          }
        }
        recurringChargeRequest {
          id
          patientMembershipPlan {
            id
            membershipPlan {
              id
              name
              annualAmountCents
              quarterlyAmountCents
              monthlyAmountCents
            }
            frequency
          }
        }
      }
    }
  }
`

export const MembershipPaymentHistorySection = () => {
  const { patientId } = useParams()
  const [page, setPage] = useState(0)

  const { data } = useQuery<
    PatientMembershipPlanCharges,
    PatientMembershipPlanChargesVariables
  >(QUERY, {
    variables: {
      patientId,
    },
  })

  const charges = data?.patient?.membershipPlanCharges ?? []
  const payments = charges.flatMap((charge) => {
    const paymentIntentCharges = charge.patientPaymentIntentCharges ?? []

    return paymentIntentCharges.map((paymentIntentCharge) => ({
      ...paymentIntentCharge,
      recurringChargeRequest: charge.recurringChargeRequest,
    }))
  })
  const visiblePayments =
    payments
      .sort((a, b) =>
        compareDesc(
          new Date(a.patientPaymentIntent.createdAt),
          new Date(b.patientPaymentIntent.createdAt)
        )
      )
      .slice(page * pageSize, page * pageSize + pageSize) ?? []

  return (
    <StackView gap={100}>
      <SectionHeader size="s" title="Payment history" />
      {match(payments)
        .with([], () => <EmptyDataContent message="No payments yet" />)
        .otherwise(() => {
          return (
            <StackView gap={25}>
              {visiblePayments.map((payment) => {
                const plan =
                  payment.recurringChargeRequest.patientMembershipPlan

                const paymentMethod =
                  payment.patientPaymentIntent.tilledPaymentMethod

                return (
                  <Card
                    key={payment.id}
                    testId="membership-plan-payment-list-item"
                  >
                    <ListRowItem>
                      <StackView gap={25}>
                        <Typography
                          textStyle="interface-strong-s"
                          color="text-base-color-fg-muted"
                          testId="payment-date"
                        >
                          {jsDateToLocalDate(
                            payment.patientPaymentIntent.createdAt
                          ).format(dateTimeFormatter('MM/dd/yyyy'))}
                        </Typography>

                        <StackView>
                          <Typography
                            textStyle="interface-default-xs"
                            color="text-base-color-fg-subtle"
                          >
                            {capitalize(paymentMethod.brand)} ending in{' '}
                            {paymentMethod.last4}
                          </Typography>
                          <Typography
                            textStyle="interface-default-xs"
                            color="text-base-color-fg-subtle"
                          >
                            {plan.membershipPlan.name} (
                            {membershipPaymentFrequencyDisplay[plan.frequency]})
                          </Typography>
                        </StackView>
                      </StackView>

                      <ListItemCell>
                        <Typography>
                          {formatMoneyInCents(payment.paymentAmountCents)}
                        </Typography>
                        <Badge
                          color={
                            PAYMENT_STATUS[payment.patientPaymentIntent.status]
                              .color
                          }
                          text={
                            PAYMENT_STATUS[payment.patientPaymentIntent.status]
                              .label
                          }
                          size="s"
                          showDot
                        />
                      </ListItemCell>
                    </ListRowItem>
                  </Card>
                )
              })}
              <PaginationControls
                page={page}
                onClickPaginationButton={setPage}
                totalPages={Math.ceil(payments.length / pageSize)}
              />
            </StackView>
          )
        })}
    </StackView>
  )
}
