import { PlusIcon } from '@heroicons/react/20/solid'
import {
  ListFavoriteOrdersForVisit,
  ListFavoriteOrdersForVisitVariables,
} from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'
import { sidepanelRoute } from 'src/lib/routes'

const QUERY = gql`
  query ListFavoriteOrdersForVisit {
    favoriteOrders {
      id
    }
  }
`

export const FavoriteOrderButton = ({
  patientId,
  encounterId,
}: {
  patientId: string
  encounterId?: string
}) => {
  const location = useLocation()
  const params = useParams()

  const { data } = useQuery<
    ListFavoriteOrdersForVisit,
    ListFavoriteOrdersForVisitVariables
  >(QUERY)

  if (!data?.favoriteOrders?.length) return null

  return (
    <Button
      testId="favorite-orders-btn"
      text="Add favorite"
      icon={PlusIcon}
      buttonStyle="secondary"
      onClick={() => {
        navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/favorite-orders/create`,
              encounterId: encounterId,
            },
            location,
            params
          )
        )
      }}
    />
  )
}
