import {
  GetEmrPaymentTransaction,
  GetEmrPaymentTransactionVariables,
  GetPatientPortalPaymentTransaction,
  GetPatientPortalPaymentTransactionVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { TENANT_LETTERHEAD_FRAGMENT } from 'src/pdf/fragments'

export const CANDID_PATIENT_PAYMENT_FRAGMENT = gql`
  fragment CandidPatientPaymentFragment on CandidPayment {
    id
    chargeDisplay
    amountCents
    paidAt
    note
    patient {
      id
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
    }
    appointment {
      id
      start
      chiefComplaints
      appointmentDefinitions {
        id
        name
      }
    }
    directPatientChargeEncounter {
      id
      directPatientChargeBillingCode {
        id
        code
        description
      }
      createdAt
    }
    paymentLineItemType
    paymentMethodType
  }
`

const PAYMENT_TRANSACTION_FRAGMENT = gql`
  fragment PaymentTransactionFragment on PaymentTransaction {
    id
    createdAt
    amountCents
    candidPatientPayments {
      ...CandidPatientPaymentFragment
    }
    paymentPlanPayments {
      id
      chargeDisplay
      amountCents
      patient {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
      }
    }
    candidPatientRefunds {
      id
      amountCents
      patient {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
      }
      candidPatientPayment {
        ...CandidPatientPaymentFragment
      }
    }
    paymentPlanRefunds {
      id
      amountCents
      patient {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
      }
    }
    tenant {
      ...TenantLetterheadFragment
    }
  }
  ${TENANT_LETTERHEAD_FRAGMENT}
  ${CANDID_PATIENT_PAYMENT_FRAGMENT}
`

const GET_PATIENT_PORTAL_PAYMENT_TRANSACTION = gql`
  query GetPatientPortalPaymentTransaction($id: String!) {
    patientPortalPaymentTransaction(id: $id) {
      ...PaymentTransactionFragment
    }
  }
  ${PAYMENT_TRANSACTION_FRAGMENT}
`

const GET_EMR_PAYMENT_TRANSACTION = gql`
  query GetEmrPaymentTransaction($id: String!) {
    paymentTransaction(id: $id) {
      ...PaymentTransactionFragment
    }
  }
  ${PAYMENT_TRANSACTION_FRAGMENT}
`

export const usePatientPortalPaymentTransactionQuery = ({
  paymentTransactionId,
}: {
  paymentTransactionId: string
}) => {
  const { data, ...rest } = useQuery<
    GetPatientPortalPaymentTransaction,
    GetPatientPortalPaymentTransactionVariables
  >(GET_PATIENT_PORTAL_PAYMENT_TRANSACTION, {
    variables: {
      id: paymentTransactionId,
    },
    skip: !paymentTransactionId,
  })

  return {
    paymentTransaction: data?.patientPortalPaymentTransaction,
    ...rest,
  }
}

export const useEmrPaymentTransactionQuery = ({
  paymentTransactionId,
}: {
  paymentTransactionId: string
}) => {
  const { data, ...rest } = useQuery<
    GetEmrPaymentTransaction,
    GetEmrPaymentTransactionVariables
  >(GET_EMR_PAYMENT_TRANSACTION, {
    variables: {
      id: paymentTransactionId,
    },
    skip: !paymentTransactionId,
  })

  return {
    paymentTransaction: data?.paymentTransaction,
    ...rest,
  }
}
