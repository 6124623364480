import { formatDisplayName } from 'common/utils'
import isEmpty from 'lodash/isEmpty'
import {
  PatientDemographicsFragment,
  UpdateInsuranceCoverageInput,
} from 'types/graphql'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import { PayerSelectField } from 'src/components/molecules/PayerSelectField/PayerSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import { formatDateDisplay, formatDateFieldValue } from 'src/lib/formatters'
import { UPDATE_INSURANCE_STATUS_MUTATION } from 'src/providers/context/AppointmentCheckInContext/schema'
import { base64EncodeFile } from 'src/utils'

type VerificationFormState = UpdateInsuranceCoverageInput & {
  birthDate: string
  memberName: string
}

const InsuranceMemberCard: React.FC<{
  insurance: PatientDemographicsFragment['insuranceCoverages'][0]
  patient: PatientDemographicsFragment
}> = ({ insurance, patient }) => {
  const preSubmit = async (data: VerificationFormState) => {
    const {
      birthDate: _birthDate,
      memberName: _memberName,
      insuranceCardBinary: insuranceCardFile,
      ...input
    } = data

    return {
      variables: {
        id: insurance.id,
        input: {
          insuranceCardBinary: isEmpty(insuranceCardFile)
            ? undefined
            : {
                content: await base64EncodeFile(insuranceCardFile[0]),
                contentType: insuranceCardFile[0].type,
              },
          ...input,
        },
      },
    }
  }

  return (
    <DataVerificationCard<VerificationFormState, UpdateInsuranceCoverageInput>
      editButtonTestId="edit-insurance-member-information"
      title="Member information"
      updateMutation={UPDATE_INSURANCE_STATUS_MUTATION}
      preSubmit={preSubmit}
      defaultOpen
      data={[
        {
          name: 'memberName',
          label: 'Member name',
          required: true,
          message:
            'Please confirm that the member name matches the patient name that is kept on file. If it does not, please go back and update the patient name.',
          inputProps: {
            disabled: true,
          },
          value: formatDisplayName(patient),
          formInputComponent: InputField,
        },
        {
          name: 'birthDate',
          label: 'Member date of birth',
          message:
            'Please confirm that the member date of birth matches the patient date of birth that is kept on file. If it does not, please go back and update the patient date of birth.',
          required: true,
          inputProps: {
            disabled: true,
          },
          value: patient.birthDate,
          displayValue: formatDateDisplay(patient.birthDate),
          formInputComponent: DatePickerField,
        },
        {
          name: 'planMemberIdentifier',
          label: 'Member ID',
          required: true,
          value: insurance.planMemberIdentifier,
          formInputComponent: InputField,
        },
        {
          name: 'payerId',
          label: 'Insurance provider',
          required: true,
          value: insurance.payer.id,
          displayValue: insurance.payer.displayName,
          formInputComponent: PayerSelectField,
        },
        {
          name: 'planGroupIdentifier',
          label: 'Group ID',
          value: insurance.planGroupIdentifier,
          formInputComponent: InputField,
        },
        {
          name: 'startDate',
          label: 'Coverage start date',
          value: insurance.startDate,
          displayValue: formatDateDisplay(insurance.startDate),
          inputProps: {
            validation: {
              setValueAs: (value: Date) => formatDateFieldValue(value),
            },
          },
          formInputComponent: DatePickerField,
        },
        {
          name: 'endDate',
          label: 'Coverage end date',
          value: insurance.endDate,
          displayValue: formatDateDisplay(insurance.endDate),
          inputProps: {
            validation: {
              setValueAs: (value: Date) => formatDateFieldValue(value),
            },
          },
          formInputComponent: DatePickerField,
        },
        {
          name: 'insuranceCardBinary',
          label: 'Insurance card copy',
          value: undefined,
          displayValue: insurance.binary ? (
            <StackView>
              <FileDisplay
                url={insurance.binary.url}
                type={insurance.binary?.contentType}
              />
            </StackView>
          ) : null,
          formInputComponent: FileInput,
        },
      ]}
    />
  )
}

export default InsuranceMemberCard
