import { useEffect, useState } from 'react'

import { DocumentNode } from '@apollo/client'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import { get, RegisterOptions, useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

export interface QueryBackendSearchInputFieldProps {
  onSearchResultClickCallback?: (id: string, option) => void
  name?: string
  disabled?: boolean
  placeholder?: string
  validation?: RegisterOptions
  query: DocumentNode
  queryKey: string
  displayOptionFn: (obj: object) => string
  onClickXMark?: () => void
}

const RenderSearchResults = ({
  onSearchResultClick,
  searchResults,
  isSearchActive,
  name,
  displayOptionFn,
}) => {
  const { formState } = useFormContext()
  const { errors } = formState

  const hasError = !!get(errors, name)
  if (hasError) {
    return null
  }
  return (
    <Box
      data-testid="search-results"
      className={clsx(
        'absolute top-10 z-10 mt-1 h-fit max-h-40 w-full overflow-auto rounded-md bg-white shadow-md'
      )}
    >
      <StackView direction="col">
        {isSearchActive && searchResults.length === 0 && (
          <StackView
            direction="row"
            data-testid="search-option-result"
            className="pointer-events-none py-2"
            onClick={onSearchResultClick}
          >
            <Typography
              className="pointer-events-none pl-3"
              textStyle="subtitle"
              color="text-base-color-fg-default"
            >
              Not found
            </Typography>
          </StackView>
        )}
        {searchResults?.map((option) => {
          return (
            <Box key={option.id || option.code}>
              <StackView
                id={option.id}
                direction="row"
                data-testid="search-option-result"
                className="z-20 py-2 hover:bg-gray-300"
                onClick={() => onSearchResultClick(option.id, option)}
              >
                <Typography
                  className="pointer-events-none pl-3"
                  textStyle="subtitle"
                >
                  {displayOptionFn(option)}
                </Typography>
              </StackView>
            </Box>
          )
        })}
      </StackView>
    </Box>
  )
}

const QueryBackedSearchInputField = ({
  onSearchResultClickCallback,
  name,
  disabled,
  placeholder = 'Start typing to search',
  query: QUERY,
  displayOptionFn,
  queryKey,
  onClickXMark,
  validation,
}: QueryBackendSearchInputFieldProps) => {
  const [typing, setIsTyping] = useState(false)
  const [debounceTimer, setDebounceTimer] = useState(undefined)
  const [selectedOption, onSelectOption] = useState(undefined)
  const [enhancedSearchInput, setEnhancedSearchInput] = useState({
    searchQuery: '',
  })
  const { trigger, setValue, unregister } = useFormContext()
  const displayName = `${name}DisplayText`
  const valueName = `${name}Id`

  useEffect(() => {
    return () => {
      if (validation?.shouldUnregister) {
        unregister(name)
        unregister(displayName)
        unregister(valueName)
      }
    }
  }, [displayName, name, unregister, validation?.shouldUnregister, valueName])

  const onEnhancedSearchType = (event) => {
    if (debounceTimer) {
      clearInterval(debounceTimer)
    }
    setEnhancedSearchInput({ searchQuery: event.target.value })
    trigger(name)
    setDebounceTimer(
      setTimeout(() => {
        setIsTyping(false)
      }, 400)
    )
  }

  const onSearchResultClick = (value, option) => {
    onSelectOption(option)
    setValue(name, option)
    setValue(valueName, option.id)
    setValue(displayName, displayOptionFn(option))
    if (onSearchResultClickCallback) {
      onSearchResultClickCallback(value, option)
    }
  }
  const { data: enhancedSearchResponse } = useQuery(QUERY, {
    skip:
      enhancedSearchInput.searchQuery.length < 2 || !!selectedOption || typing,
    variables: { input: enhancedSearchInput },
  })

  const classes = [
    'block w-full text-sm text-gray-900 pr-7',
    'rounded-md shadow-sm border-gray-300',
    'focus:border-primary focus:ring-primary',
    disabled && 'bg-gray-50 pointer-events-none',
  ]

  const errorClasses = [
    'border-red-300 focus:border-danger',
    'focus:outline-none focus:ring-danger',
    'text-red-900 placeholder-red-300',
  ]
  const hasXIcon = typing || enhancedSearchInput.searchQuery.length > 0

  return (
    <Box className="relative min-h-10">
      <StackView direction="row">
        <StackView direction="col">
          <InputField
            autoComplete="off"
            data-testid="enhanced-search-input"
            name={displayName}
            id={name}
            readOnly={!!selectedOption}
            placeholder={placeholder}
            onChange={(e) => {
              setIsTyping(true)
              onEnhancedSearchType(e)
            }}
            onClickIconRight={() => {
              if (hasXIcon) {
                setValue(valueName, undefined)
                setValue(name, undefined)
                onSelectOption(undefined)
                setValue(displayName, '')
                setEnhancedSearchInput({ searchQuery: '' })
                if (onClickXMark) {
                  onClickXMark()
                }
              }
            }}
            iconRight={hasXIcon ? XMarkIcon : MagnifyingGlassIcon}
            className={clsx(classes)}
            errorClassName={clsx(errorClasses)}
            validation={{
              required: validation?.required
                ? 'An option must be selected'
                : false,
            }}
          />

          {!selectedOption &&
            enhancedSearchResponse &&
            enhancedSearchResponse[queryKey] && (
              <RenderSearchResults
                displayOptionFn={displayOptionFn}
                name={displayName}
                isSearchActive={enhancedSearchInput.searchQuery.length >= 2}
                searchResults={enhancedSearchResponse[queryKey]}
                onSearchResultClick={onSearchResultClick}
              />
            )}
        </StackView>
      </StackView>
    </Box>
  )
}

export default QueryBackedSearchInputField
