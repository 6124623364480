import { PlusIcon } from '@heroicons/react/20/solid'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'

import BillingTable from '../PatientVisits/pages/BillingPage/sections/BillingTable'
import useBillingSidepanels from '../PatientVisits/pages/BillingPage/sections/useBillingSidepanels'

import { HospitalVisitClaimSubsection } from './HospitalVisitClaimSubsection'
import { useHospitalVisit } from './useHospitalVisit'

export const HospitalVisitBillingSection = () => {
  const { data } = useHospitalVisit()
  const { addAnyBillingCode } = useBillingSidepanels({
    encounterId: data?.hospitalVisit?.claim?.encounter?.id,
  })

  if (!data?.hospitalVisit) return

  const visit = data.hospitalVisit
  const { claim } = visit

  return (
    <StackView gap={150}>
      <SectionHeader
        title="Billing"
        description="Bill for your hospital visit."
      />
      <HospitalVisitClaimSubsection />
      <StackView gap={100}>
        <SectionHeader
          size="s"
          title="Billing"
          description="Bill for your hospital visit."
          actions={
            <Button
              testId="hospital-visit-add-billing-codes-btn"
              buttonStyle="secondary"
              icon={PlusIcon}
              text="Add"
              onClick={addAnyBillingCode}
            />
          }
        />
        <BillingTable
          testId="hospital-visit-billing-table"
          encounterId={claim.encounter.id}
          chargeItems={claim.encounter.chargeItems ?? []}
          primaryDiagnosis={claim.encounter.primaryDiagnosis?.diagnosisCode}
          isEditable
        />
      </StackView>
    </StackView>
  )
}
