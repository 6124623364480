import { navigate, useLocation } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

const VISIT_TEMPLATE_SCHEDULING_ORDER_FRAGMENT = gql`
  fragment VisitTemplateSchedulingOrderFragment on VisitTemplateSchedulingOrder {
    id
    visitTemplateOrderId
    visitPractitionerId
    visitPractitionerType
    visitPractitioner {
      id
      namePrefix
      givenName
      familyName
      nameSuffix
    }
    locationId
    locationType
    location {
      id
      name
    }
    targetTime
    targetTimeUnit
    targetDate {
      id
      value
      valueEnd
      unit
      unitEnd
      prefix
      date
      dateEnd
      rangeType
    }
    chiefComplaints
    appointmentDefinitions {
      id
      duration
      code
    }
  }
`

export const VISIT_TEMPLATE_ORDER_FRAGMENT = gql`
  fragment VisitTemplateOrderFragment on VisitTemplateOrder {
    id
    orderCategory
    code
    cptCode
    cptCodeDescription
    visitTemplateSchedulingOrders {
      ...VisitTemplateSchedulingOrderFragment
    }
  }
  ${VISIT_TEMPLATE_SCHEDULING_ORDER_FRAGMENT}
`

export const VISIT_TEMPLATE_DIAGNOSIS_FRAGMENT = gql`
  fragment VisitTemplateDiagnosisFragment on VisitTemplateDiagnosis {
    id
    isPrimaryDiagnosis
    diagnosisCode {
      id
      code
      description
    }
    visitTemplateOrderDiagnoses {
      id
      visitTemplateOrderId
      visitTemplateDiagnosisId
    }
  }
  ${VISIT_TEMPLATE_ORDER_FRAGMENT}
`

export const VISIT_TEMPLATE_FRAGMENT = gql`
  fragment VisitTemplateFragment on VisitTemplate {
    id
    name
    isPracticeTemplate
    isWellChildTemplate
    periodicityStage
    updatedAt
    createdById
    attestation
    historyPresentIllness
    developmentalSurveillance
    planOfCare
    assessment
    physicalExam
    reviewOfSystems
    visitSummary
    patientEducationAdditionalNotes
    patientEducationAdolescentConfidential
    documentationAdolescentConfidential
    patientEducationDocuments {
      id
      title
    }
    visitTemplateOrders {
      ...VisitTemplateOrderFragment
    }
    visitTemplateDiagnoses {
      ...VisitTemplateDiagnosisFragment
    }
  }
  ${VISIT_TEMPLATE_ORDER_FRAGMENT}
  ${VISIT_TEMPLATE_DIAGNOSIS_FRAGMENT}
`

const VISIT_TEMPLATES_QUERY = gql`
  query GetVisitTemplates {
    practiceVisitTemplates {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const VISIT_TEMPLATE_QUERY = gql`
  query GetVisitTemplate($id: String!) {
    visitTemplate(id: $id) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const DELETE_TEMPLATE_MUTATION = gql`
  mutation DeleteVisitTemplate($id: String!) {
    deleteVisitTemplate(id: $id) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const UPSERT_TEMPLATE_MUTATION = gql`
  mutation UpsertVisitTemplate(
    $id: String!
    $input: UpsertVisitTemplateInput!
  ) {
    upsertVisitTemplate(id: $id, input: $input) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const UPDATE_TEMPLATE_ROS_MUTATION = gql`
  mutation UpdateVisitTemplateRos($id: String!, $reviewOfSystems: JSON!) {
    updateVisitTemplateRos(id: $id, reviewOfSystems: $reviewOfSystems) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const DELETE_TEMPLATE_ORDER_MUTATION = gql`
  mutation DeleteVisitTemplateOrder($id: String!) {
    deleteVisitTemplateOrder(id: $id) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const DELETE_TEMPLATE_DIAGNOSIS_MUTATION = gql`
  mutation DeleteVisitTemplateDiagnosis($id: String!) {
    deleteVisitTemplateDiagnosis(id: $id) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const SET_PRIMARY_TEMPLATE_DIAGNOSIS_MUTATION = gql`
  mutation SetPrimaryVisitTemplateDiagnosis($id: String!) {
    setPrimaryVisitTemplateDiagnosis(id: $id) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const CREATE_TEMPLATE_ORDER_MUTATION = gql`
  mutation CreateVisitTemplateOrder($input: CreateVisitTemplateOrderInput!) {
    createVisitTemplateOrder(input: $input) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

const CREATE_TEMPLATE_DIAGNOSIS_MUTATION = gql`
  mutation CreateVisitTemplateDiagnosis(
    $input: CreateVisitTemplateDiagnosisInput!
  ) {
    createVisitTemplateDiagnosis(input: $input) {
      ...VisitTemplateFragment
    }
  }
  ${VISIT_TEMPLATE_FRAGMENT}
`

export const useVisitTemplatesQuery = () => {
  const { data, ...rest } = useQuery(VISIT_TEMPLATES_QUERY)

  return {
    practiceTemplates: data?.practiceVisitTemplates,
    ...rest,
  }
}

export const useVisitTemplateQuery = (id: string) => {
  const { data, ...rest } = useQuery(VISIT_TEMPLATE_QUERY, {
    variables: { id },
    skip: id === 'new',
  })

  return {
    template: data?.visitTemplate,
    ...rest,
  }
}

export const useDeleteVisitTemplateMutation = (id: string) => {
  return useMutation(DELETE_TEMPLATE_MUTATION, {
    variables: {
      id,
    },
    refetchQueries: ['GetVisitTemplates'],
  })
}

export const useUpsertVisitTemplateMutation = () => {
  const location = useLocation()
  const page = location.pathname.split('/')[3]
  return useMutation(UPSERT_TEMPLATE_MUTATION, {
    onCompleted: (data) => {
      toast.success('Visit template updated')
      navigate(`/visit-template/${data.upsertVisitTemplate.id}/${page}`)
    },
  })
}

export const useUpdateVisitTemplateRosMutation = () => {
  return useMutation(UPDATE_TEMPLATE_ROS_MUTATION)
}

export const useDeleteVisitTemplateOrderMutation = (id: string) => {
  return useMutation(DELETE_TEMPLATE_ORDER_MUTATION, {
    variables: {
      id,
    },
  })
}

export const useDeleteVisitTemplateDiagnosisMutation = (id: string) => {
  return useMutation(DELETE_TEMPLATE_DIAGNOSIS_MUTATION, {
    variables: {
      id,
    },
  })
}

export const useSetPrimaryVisitTemplateDiagnosisMutation = (id: string) => {
  return useMutation(SET_PRIMARY_TEMPLATE_DIAGNOSIS_MUTATION, {
    variables: {
      id,
    },
  })
}

export const useCreateVisitTemplateOrderMutation = () => {
  return useMutation(CREATE_TEMPLATE_ORDER_MUTATION)
}

export const useCreateVisitTemplateDiagnosisMutation = () => {
  return useMutation(CREATE_TEMPLATE_DIAGNOSIS_MUTATION)
}
