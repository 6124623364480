import { useState, useEffect } from 'react'

import { GetPatientImmunizationsToPrint } from 'types/graphql'

import { useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { ON_DEMAND_IMMUNIZATIONS_FRAGMENT } from 'src/hooks/useOnDemandMedicalRecordsQuery/useOnDemandMedicalRecordsQuery'
import PrintPatientImmunizationsDocument from 'src/pdf/components/PrintPatientImmunizationsDocument'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'
import { isCI, isDev } from 'src/utils'

const QUERY = gql`
  query GetPatientImmunizationsToPrint($id: String!) {
    patient(id: $id) {
      ...OnDemandImmunizationFragment
    }
  }
  ${ON_DEMAND_IMMUNIZATIONS_FRAGMENT}
`

const SidepanelPrintImmunizations = () => {
  const params = useParams()
  const patientId = params.id

  const { data } = useQuery<GetPatientImmunizationsToPrint>(QUERY, {
    variables: {
      id: patientId,
    },
  })

  const [isGenerating, setIsGenerating] = useState(false)
  const generatePDF = useGeneratePDF()
  const [generated, setGenerated] = useState<URL>()

  useEffect(() => {
    if (data) {
      setIsGenerating(true)
      void generatePDF({
        forPatient: data.patient,
        component: <PrintPatientImmunizationsDocument patient={data.patient} />,
        callback: (doc) => {
          setIsGenerating(false)
          setGenerated(doc.output('bloburl'))
        },
      })
    }
  }, [generatePDF, data])

  if (!data) return null

  return (
    <SidepanelPage header="Print immunizations">
      <StackView className="h-full pt-4" space={50}>
        {isGenerating ? (
          <LoadingSpinner />
        ) : !isGenerating && params.testing && (isDev || isCI) ? (
          // This should only display when we are testing the page on dev
          <PrintPatientImmunizationsDocument
            patient={data.patient}
            testId="print-immunizations"
          />
        ) : (
          <FileDisplay
            type="application/pdf"
            url={generated}
            showDownloadLink={false}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPrintImmunizations
