import { useState } from 'react'

import { paymentLineItemTypesDisplay } from 'common/data/paymentLineItemTypes'
import { assertUnreachable, formatMoneyInCents } from 'common/utils'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import compact from 'lodash/compact'
import { useParams } from 'react-router-dom'
import { FetchPatientPayment } from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge/Badge'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import Modal from 'src/components/molecules/Modal'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import Tabs from 'src/components/molecules/Tabs/Tabs'
import { PAYMENT_TYPES } from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'
import PatientPaymentEventsTable from 'src/components/PatientFinancials/components/PatientPaymentEventsTable'
import {
  usePatientPaymentQuery,
  useRefundCandidPatientPaymentMutation,
} from 'src/hooks/usePatientPayments/usePatientPayments'
import { sidepanelRoute } from 'src/lib/routes'

const ProcessedBy = ({
  paymentEvent,
}: {
  paymentEvent: FetchPatientPayment['patientPayment']['createdCandidPatientPaymentEvent']
}) => {
  if (!paymentEvent.occurredByUserType) return null
  switch (paymentEvent.occurredByUserType) {
    case 'USER':
      return (
        <StackView>
          <Typography
            textStyle="body-s"
            color="text-base-color-fg-muted"
            className="!mb-0"
          >
            {formatDisplayName(paymentEvent.occurredBy)}
          </Typography>
          <Typography
            color="text-base-color-fg-subtle"
            textStyle="interface-default-xs"
          >
            {paymentEvent.occurredBy?.userType.name}
          </Typography>
        </StackView>
      )
    case 'TILLED':
      return 'Paid by patient or caregiver via web link or portal'
    default:
      assertUnreachable(paymentEvent.occurredByUserType)
  }
}

const DetailsTab = ({
  payment,
}: {
  payment: FetchPatientPayment['patientPayment']
}) => {
  const paymentType =
    payment?.amountCents > 0
      ? 'PAYMENT'
      : payment?.amountCents === 0
        ? 'NET_ZERO'
        : 'REFUND'

  if (!payment) return null
  return (
    <DataDisplayList
      title="Details"
      leftColumnWidth="lg"
      data={compact([
        {
          label: 'Amount',
          value: formatMoneyInCents(payment.amountCents),
        },
        {
          label: 'Paid on',
          value: format(new Date(payment.paidAt), 'MM/dd/yyyy'),
        },
        {
          label: 'Payment method',
          value: payment.note,
        },
        {
          label: 'Type',
          value: (
            <Badge
              testId="payment-type-badge"
              color={PAYMENT_TYPES[paymentType].color}
              text={PAYMENT_TYPES[paymentType].label}
            />
          ),
        },
        {
          label: payment.appointment ? 'Visit' : 'Charge',
          value: payment.chargeDisplay,
        },
        {
          label: 'Line item',
          value: paymentLineItemTypesDisplay[payment.paymentLineItemType],
        },
        payment.comments && {
          label: 'Comments',
          value: payment.comments,
        },
        payment.createdCandidPatientPaymentEvent && {
          label: 'Processed by',
          value: (
            <ProcessedBy
              paymentEvent={payment.createdCandidPatientPaymentEvent}
            />
          ),
        },
        payment.createdCandidPatientPaymentEvent && {
          label: 'Recorded date and time',
          value: format(
            new Date(payment.createdCandidPatientPaymentEvent.occurredAt),
            "MM/dd/yyyy 'at' h:mma"
          ),
        },
      ])}
    />
  )
}

const HistoryTab = ({
  payment,
}: {
  payment: FetchPatientPayment['patientPayment']
}) => {
  return (
    <StackView className="py-core-space-75" space={100}>
      <Typography textStyle="title-xs">History</Typography>
      <PatientPaymentEventsTable payment={payment} />
    </StackView>
  )
}

const SidepanelPatientPaymentView = () => {
  const params = useParams()
  const location = useLocation()
  const { patientPaymentId, patientId } = params
  const { payment, loading } = usePatientPaymentQuery(patientPaymentId)
  const [refundCandidPatientPayment] = useRefundCandidPatientPaymentMutation()
  const [
    refundingExternalCandidPatientPaymentId,
    setRefundingExternalCandidPatientPaymentId,
  ] = useState<string>(null)

  return (
    <SidepanelPage
      testId="patient-payment-view"
      header={payment?.chargeDisplay ?? 'Patient Payment'}
      description={
        payment
          ? compact([
              format(new Date(payment.paidAt), 'PP'),
              paymentLineItemTypesDisplay[payment.paymentLineItemType],
            ]).join(' · ')
          : undefined
      }
      loading={loading}
    >
      <StackView className="grow p-core-space-150" space={50}>
        <StackView direction="row" space={50}>
          {isEmpty(payment?.candidPatientRefunds) && (
            <Button
              testId="transaction-edit-button"
              text="Edit"
              buttonStyle="secondary"
              onClick={() => {
                navigate(
                  sidepanelRoute(
                    {
                      route: `/patients/${patientId}/payments/${patientPaymentId}/edit`,
                    },
                    location,
                    params
                  )
                )
              }}
            />
          )}
          <Button
            testId="transaction-receipt-button"
            text="Receipt"
            buttonStyle="secondary"
            onClick={() => {
              navigate(
                sidepanelRoute(
                  {
                    route: `/patients/${patientId}/payments/receipt/${payment.paymentTransactionId}`,
                    width: 'medium',
                    overlay: true,
                  },
                  location,
                  params
                )
              )
            }}
          />

          {payment?.paymentTransactionId &&
            isEmpty(payment?.candidPatientRefunds) && (
              <Button
                text="Refund"
                buttonStyle="secondary"
                onClick={() => {
                  setRefundingExternalCandidPatientPaymentId(payment.id)
                }}
              />
            )}
        </StackView>

        <Tabs
          growTabs
          tabs={[
            {
              name: 'Details',
              content: <DetailsTab payment={payment} />,
            },
            {
              name: 'History',
              content: <HistoryTab payment={payment} />,
            },
          ]}
        />
      </StackView>

      <Modal
        title="Refund payment"
        modalStyle="warning"
        isOpen={!!refundingExternalCandidPatientPaymentId}
        primaryButton={{
          text: 'Refund payment',
          onClick: () => {
            void refundCandidPatientPayment({
              variables: {
                input: {
                  externalCandidPatientPaymentId:
                    refundingExternalCandidPatientPaymentId,
                },
              },
            })
          },
        }}
        content="Are you sure you would like to refund this payment? This cannot be undone."
        setIsOpen={(isOpen) =>
          !isOpen && setRefundingExternalCandidPatientPaymentId(null)
        }
        onClose={() => setRefundingExternalCandidPatientPaymentId(null)}
        hideIcon
      />
    </SidepanelPage>
  )
}

export default SidepanelPatientPaymentView
