import { PlaceOfService } from 'types/graphql'

import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { placeOfServiceOptions, placeOfServices } from 'src/data/placeOfService'

export type FilteredPlaceOfServiceSelectFieldProps = RWSelectFieldProps & {
  optionFilter: ({
    name,
    value,
  }: {
    name: string
    value: PlaceOfService
  }) => boolean
}

export const FilteredPlaceOfServiceSelectField = (
  props: FilteredPlaceOfServiceSelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      options={placeOfServiceOptions
        .filter(({ value }) => placeOfServices[value].isPriority)
        .filter((option) => props.optionFilter(option))
        .map(({ name, value }) => ({
          value,
          display: name,
        }))}
    />
  )
}

export default FilteredPlaceOfServiceSelectField
