import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { usePatientOrdersQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import SidepanelNotAvailable from 'src/pages/Sidepanel/SidepanelNotAvailable/SidepanelNotAvailable'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

export const REFUSE_IMMUNIZATION = gql`
  mutation RefuseImmunization($input: AdministerImmunizationInput!) {
    administerImmunization(input: $input) {
      id
    }
  }
`

const SidepanelVisitVaccineAdministrationRefuse = () => {
  const [administerImmunization, { loading: administeringImmunization }] =
    useMutation(REFUSE_IMMUNIZATION, {
      onCompleted: () => {
        toast.success('Vaccine administration saved')
      },
      refetchQueries: ['FindPatientOrders'],
    })

  const { closeSidePanel } = useSidepanel()
  const { patientId, isPatientChart } = useIsPatientChart()
  const { orderId, encounterId } = useParams()
  const { orders, loading } = usePatientOrdersQuery(patientId)
  const order = orders?.find((order) => order.id === orderId)

  const formMethods = useForm({
    defaultValues: {
      verbalRefusalByPatient: false,
      verbalRefusalByCaregiver: false,
      refusalFormCompleted: false,
    },
  })

  const verbalRefusalByPatient = formMethods.watch('verbalRefusalByPatient')
  const verbalRefusalByCaregiver = formMethods.watch('verbalRefusalByCaregiver')
  const refusalFormCompleted = formMethods.watch('refusalFormCompleted')

  if (!isPatientChart || !order) {
    return <SidepanelNotAvailable />
  }

  const onSubmit = (data) => {
    const input = {
      ...data,
      encounterId,
      orderId,
      patientId,
    }
    void administerImmunization({
      variables: { input: input },
      onCompleted: closeSidePanel,
    })
  }

  return (
    <>
      <SidepanelPage
        testId="sidepanel-vaccine-administration"
        header={order.codeDisplay}
        description={order.name}
        loading={loading}
      >
        <SidepanelForm
          footerProps={{
            submitText: 'Save',
            cancelText: 'Discard changes',
            submitting: administeringImmunization,
          }}
          onSubmit={onSubmit}
          formMethods={formMethods}
        >
          <FormInputList
            title="Vaccine refusal"
            divider={false}
            items={[
              {
                name: 'verbalRefusalByPatient',
                label: 'Verbal vaccine refusal by the patient',
                hideLabel: true,
                formInputComponent: CheckboxField,
                inputProps: {
                  label: 'Verbal vaccine refusal by the patient',
                  validation: {
                    validate: (value) => {
                      return !(
                        verbalRefusalByCaregiver ||
                        refusalFormCompleted ||
                        value
                      )
                        ? 'At least one option must be selected'
                        : null
                    },
                  },
                },
                direction: 'col',
              },
              {
                name: 'verbalRefusalByCaregiver',
                label: 'Verbal vaccine refusal by the caregiver',
                hideLabel: true,
                formInputComponent: CheckboxField,
                inputProps: {
                  label: 'Verbal vaccine refusal by the caregiver',
                  validation: {
                    validate: (value) => {
                      return !(
                        verbalRefusalByPatient ||
                        refusalFormCompleted ||
                        value
                      )
                        ? 'At least one option must be selected'
                        : null
                    },
                  },
                },
                direction: 'col',
              },
              {
                name: 'refusalFormCompleted',
                label: 'Refusal to vaccinate form completed',
                hideLabel: true,
                formInputComponent: CheckboxField,
                inputProps: {
                  label: 'Refusal to vaccinate form completed',
                  validation: {
                    validate: (value) => {
                      return !(
                        verbalRefusalByPatient ||
                        verbalRefusalByCaregiver ||
                        value
                      )
                        ? 'At least one option must be selected'
                        : null
                    },
                  },
                },
                direction: 'col',
              },
            ]}
          />
        </SidepanelForm>
      </SidepanelPage>
    </>
  )
}

export default SidepanelVisitVaccineAdministrationRefuse
