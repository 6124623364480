import { ArrowTopRightOnSquareIcon, BellIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import { useParams } from 'react-router-dom'

import {
  navigate,
  routes,
  useLocation,
  useParams as useRWParams,
} from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { BalanceDisplay } from 'src/components/molecules/BalanceDisplay/BalanceDisplay'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { StatelessTabs } from 'src/components/molecules/Tabs'
import { sidepanelRoute } from 'src/lib/routes'
import { useSendPaymentReminders } from 'src/pages/PracticeFinancialsPage/useSendPaymentReminders'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { openNewTab } from 'src/utils'

import { DetailsTab } from './DetailsTab'
import { HistoryTab } from './HistoryTab'
import {
  SidepanelPatientFinancialsProvider,
  useSidepanelPatientFinancialsContext,
} from './SidepanelPatientFinancialsContext'
import { StatementTab } from './StatementTab'

const tabs = [
  {
    key: 'details',
    name: 'Details',
    content: <DetailsTab />,
  },
  {
    key: 'statement',
    name: 'Statement',
    content: <StatementTab />,
  },
  {
    key: 'history',
    name: 'History',
    content: <HistoryTab />,
  },
]

const SidepanelPatientFinancialsHelper = () => {
  const { patient } = useSidepanelPatientFinancialsContext()
  const { patientId, ...params } = useParams()
  const { sidepanelContext } = useSidepanel()
  const location = useLocation()
  const rwParams = useRWParams()

  const selectedTab = params?.tab ?? 'details'

  const [sendPaymentReminders, { loading }] = useSendPaymentReminders()

  const primaryGuarantor = patient.primaryGuarantorId
    ? patient.patientRelatedPersonRelationships.find(
        (relationship) =>
          relationship.relatedPerson.id === patient.primaryGuarantorId
      ).relatedPerson
    : patient

  return (
    <SidepanelPage
      header={
        <StackView gap={100}>
          <StackView gap={25}>
            <Typography textStyle="title-s">
              {formatDisplayName(patient)}
            </Typography>

            <StackView direction="row" alignItems="center" gap={25}>
              <Typography
                textStyle="interface-strong-xs"
                color="text-base-color-fg-muted"
              >
                Total family balance:
              </Typography>

              <BalanceDisplay textStyle="interface-strong-xs">
                {patient.totalOutstandingBalanceCents}
              </BalanceDisplay>
            </StackView>
          </StackView>
          <StackView gap={50}>
            <StackView direction="row" gap={50}>
              <Button
                icon={BellIcon}
                text="Send reminder"
                loading={loading}
                disabled={
                  loading || primaryGuarantor.portalUserStatus !== 'ACTIVE'
                }
                onClick={() => {
                  void sendPaymentReminders({
                    variables: {
                      guarantors: [
                        patient.primaryGuarantorId
                          ? {
                              guarantorType: 'RELATED_PERSON',
                              id: patient.primaryGuarantorId,
                            }
                          : {
                              guarantorType: 'PATIENT',
                              id: patient.id,
                            },
                      ],
                    },
                    onCompleted: (result) => {
                      if (
                        result.sendFamilyBalanceReminders.unsentMessages?.length
                      ) {
                        const unsentMessage =
                          result.sendFamilyBalanceReminders.unsentMessages[0]
                        toast.error(
                          `Failed to send payment reminder. Reason: ${unsentMessage.reason}`
                        )
                      } else {
                        toast.success('Sent payment reminder')
                      }
                    },
                  })
                }}
              />
              <Button
                text="View chart"
                icon={ArrowTopRightOnSquareIcon}
                buttonStyle="secondary"
                onClick={() =>
                  openNewTab(
                    routes.patientCharts({
                      id: patient.id,
                    })
                  )
                }
              />
            </StackView>

            {primaryGuarantor.portalUserStatus !== 'ACTIVE' ? (
              <Typography
                textStyle="interface-default-xs"
                color="text-base-color-fg-subtle"
              >
                The primary guarantor must have an active family portal account
                to send payment reminders.
              </Typography>
            ) : null}
          </StackView>
        </StackView>
      }
    >
      <StackView className="h-full">
        <StatelessTabs
          contained
          className="px-core-space-150"
          selectedTabIndex={tabs.findIndex((tab) => tab.key === selectedTab)}
          onTabChange={(index) => {
            const key = tabs[index]?.key

            navigate(
              sidepanelRoute(
                {
                  ...sidepanelContext,
                  route: `/patient/${patientId}/financials/${key}`,
                },
                location,
                rwParams
              )
            )
          }}
          tabs={tabs}
        />
      </StackView>
    </SidepanelPage>
  )
}

const SidepanelPatientFinancials = () => (
  <SidepanelPatientFinancialsProvider>
    <SidepanelPatientFinancialsHelper />
  </SidepanelPatientFinancialsProvider>
)

export default SidepanelPatientFinancials
