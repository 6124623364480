import { useRef } from 'react'

import { XMarkIcon } from '@heroicons/react/20/solid'
import {
  ArrowUturnLeftIcon,
  CheckCircleIcon,
  CheckIcon,
  NoSymbolIcon,
  PencilIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { assertUnreachable } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { match } from 'ts-pattern'
import { OrderFragment, VaccineManagementServiceProvider } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import AreaLoader from 'src/components/atoms/AreaLoader/AreaLoader'
import Badge from 'src/components/atoms/Badge'
import Button from 'src/components/atoms/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'
import { sidepanelRoute } from 'src/lib/routes'
import {
  usePatientOrdersQuery,
  isRelevantForEncounter,
} from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import {
  useVisit,
  useVisitQuery,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { isDeveloVaccineInventoryEnabled } from 'src/utils/vaccineManagementServiceProvider'

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'start',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  resizable: false,
}

const VaccineOrderStatusCheckmark = ({ order }: { order: OrderFragment }) => {
  switch (order.status) {
    case 'COMPLETED':
      return (
        <StackView space={25} fullWidth={false}>
          <CheckCircleIcon
            className="h-8 w-8 fill-success"
            data-testid="completed-checkmark"
          />
        </StackView>
      )
    case 'REVOKED':
      return (
        <StackView space={25} fullWidth={false}>
          <XCircleIcon
            className="h-8 w-8 fill-danger"
            data-testid="not-done-checkmark"
          />
        </StackView>
      )
    case 'ACTIVE':
      return (
        <StackView space={25} fullWidth={false}>
          <CheckCircleIcon
            className="h-8 w-8 fill-gray-300"
            data-testid="default-checkmark"
          />
        </StackView>
      )
    default:
      assertUnreachable(order.status)
  }
}

const VaccineOrderDetails = ({ order }: { order: OrderFragment }) => {
  return (
    <StackView space={25} className="truncate">
      <StackView direction="row" space={50} alignItems="center">
        <Typography
          className="truncate"
          color="text-base-color-fg-default"
          fontWeight="semibold"
          size="m"
        >
          {order.codeDisplay}
        </Typography>
        {order.intent !== 'ORDER' && (
          <Badge color="yellow" className="capitalize">
            Pended
          </Badge>
        )}
      </StackView>
      <StackView direction="row" space={50}>
        <Typography textStyle="description" fontWeight="medium">
          {order.name}
        </Typography>
        <Typography textStyle="description" fontWeight="medium">
          {format(parseISO(order.createdAt), 'MMM d, yyyy')}
        </Typography>
      </StackView>
    </StackView>
  )
}

const VaccineOrderActionButton = ({
  order,
  vaccineManagementServiceProvider,
}: {
  order: OrderFragment
  vaccineManagementServiceProvider: VaccineManagementServiceProvider
}) => {
  const { appointmentId } = useVisit()
  const location = useLocation()
  const { visit } = useVisitQuery(appointmentId)

  const actions = match(vaccineManagementServiceProvider)
    .with('DEVELO', 'VAXCARE_PRIVATE', () => {
      return match(order.status)
        .with('ACTIVE', () => [
          {
            buttonText: 'Administer',
            buttonIcon: CheckIcon,
            route: 'edit',
          },
        ])
        .with('COMPLETED', () => [
          {
            buttonText: 'Edit',
            buttonIcon: PencilIcon,
            route: 'edit',
          },
          {
            buttonText: 'Undo',
            buttonIcon: ArrowUturnLeftIcon,
            route: 'undo',
          },
        ])
        .with('REVOKED', () => [])
        .exhaustive()
    })
    .with('VAXCARE_FULL', 'CANID', () => {
      return match(order.status)
        .with('ACTIVE', () => [
          {
            buttonText: 'Refuse',
            buttonIcon: XMarkIcon,
            route: 'refuse',
          },
        ])
        .with('REVOKED', 'COMPLETED', () => [])
        .exhaustive()
    })
    .exhaustive()

  if (actions.length === 0) return null

  return (
    <StackView space={25} fullWidth={false} direction="row">
      {actions.map((action) => (
        <Button
          key={`${order.id}.${action.route}`}
          buttonStyle="secondary"
          testId={`vaccine-admin-${action.route}-button`}
          innerRef={(ref) => {
            if (!ref) return
            ref.onclick = (e) => {
              e.stopPropagation()
              navigate(
                sidepanelRoute(
                  {
                    route: `/encounters/${visit.encounter.id}/location/${visit.location.id}/vaccine-admin/${order.id}/${action.route}`,
                  },
                  location
                )
              )
            }
          }}
          icon={action.buttonIcon}
          tooltip={action.buttonText}
          tooltipPosition="left"
        />
      ))}
    </StackView>
  )
}

const NoRowsOverlay = () => {
  return (
    <StackView justifyContent="center" alignItems="center">
      <NoSymbolIcon className="h-8 w-8 text-gray-400" />
      <Typography textStyle="subtitle" fontWeight="bold">
        No pended or active vaccine orders
      </Typography>
      <Typography>
        If vaccines need to be administered, place new vaccine orders first.
      </Typography>
    </StackView>
  )
}

const isRelevantVaccineOrder = (encounterId: string, order: OrderFragment) => {
  return (
    order.category === 'VAX' &&
    (isRelevantForEncounter(encounterId, order) ||
      // Only show REVOKED if there is an immunization along with it.
      // This means that the immunization was refused
      (order.encounterId === encounterId &&
        (order.status !== 'REVOKED' ||
          (order.status === 'REVOKED' && order.immunization))))
  )
}

const columnDefinitions = [
  {
    colId: 'order',
    cellRenderer: ({ data, context }) => (
      <StackView direction="row" alignItems="center" space={50}>
        <VaccineOrderStatusCheckmark order={data} />
        <VaccineOrderDetails order={data} />
        <VaccineOrderActionButton
          order={data}
          vaccineManagementServiceProvider={
            context.vaccineManagementServiceProvider
          }
        />
      </StackView>
    ),
  },
]

const VaccineAdministrationOrderTable = () => {
  const gridRef = useRef<AgGridReact>()
  const { id: patientId } = useParams()
  const location = useLocation()
  const { currentUser } = useEmrAuth()

  const { appointmentId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)
  const { nonCancelledOrders, loading } = usePatientOrdersQuery(patientId)

  if (loading) return <LoadingSpinner />

  const vaccineOrders =
    nonCancelledOrders?.filter((order) =>
      isRelevantVaccineOrder(visit?.encounter?.id, order)
    ) ?? []

  return (
    <Table
      className={vaccineOrders?.length === 0 && 'develo-ag-no-rows-h-32'}
      testId="vaccine-order-table"
      innerRef={gridRef}
      rowData={vaccineOrders}
      domLayout="autoHeight"
      rowHeight={72}
      defaultColDef={defaultColDef}
      columnDefs={columnDefinitions}
      animateRows={true}
      loadingOverlayComponent={AreaLoader}
      noRowsOverlayComponent={NoRowsOverlay}
      pagination={false}
      onRowClicked={(e) => {
        if (isDeveloVaccineInventoryEnabled(currentUser)) {
          navigate(
            sidepanelRoute(
              {
                route: `/encounters/${visit.encounter.id}/location/${
                  visit.location.id
                }/vaccine-admin/${e.data.id}/${
                  e.data.status === 'ACTIVE' ? 'edit' : ''
                }`,
              },
              location
            )
          )
        }
      }}
      hideHeader
      context={{
        vaccineManagementServiceProvider:
          currentUser.vaccineManagementServiceProvider,
      }}
    />
  )
}

export default VaccineAdministrationOrderTable
