import { isEmpty } from 'lodash'
import type {
  FindContactOrganizationQuery,
  FindContactOrganizationQueryVariables,
  UpdateContactOrganizationInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import InheritedAddressField from 'src/components/Address/InheritedAddressField/InheritedAddressField'
import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import ContactOrganizationTypeSelectField from 'src/components/atoms/ContactOrganizationTypeSelectField/ContactOrganizationTypeSelectField'
import InputField from 'src/components/atoms/InputField/InputField'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { CONTACT_FRAGMENT } from 'src/pages/ContactsPage/ContactOrganizationsCell'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import getDirtyFields from 'src/utils/getDirtyFields'

export const QUERY = gql`
  query FindContactOrganizationQuery($id: String!) {
    contactOrganization(id: $id) {
      id
      name
      type
      hipaaCovered
      primaryContact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`

const UPDATE_CONTACT_MUTATION = gql`
  mutation UpdateContactOrganization(
    $id: String!
    $input: UpdateContactOrganizationInput!
  ) {
    updateContactOrganization(id: $id, input: $input) {
      id
      name
      type
      hipaaCovered
      contactCount
      primaryContact {
        ...ContactFragment
      }
    }
  }
  ${CONTACT_FRAGMENT}
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindContactOrganizationQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  contactOrganization,
}: CellSuccessProps<
  FindContactOrganizationQuery,
  FindContactOrganizationQueryVariables
>) => {
  const { closeSidePanel } = useSidepanel()
  const [updateContact, { loading: updatingContact, error }] = useMutation(
    UPDATE_CONTACT_MUTATION,
    {
      onCompleted: () => {
        toast.success('Contact organization updated')
        closeSidePanel()
      },
    }
  )
  const formMethods = useForm<
    UpdateContactOrganizationInput & { hasNoAddress: boolean }
  >({
    shouldUnregister: true,
    defaultValues: {
      hasNoAddress: !contactOrganization.primaryContact?.address,
      ...contactOrganization,
    },
  })

  const {
    formState: { dirtyFields },
  } = formMethods

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Save',
        disabled: isEmpty(dirtyFields),
        submitting: updatingContact,
      }}
      formMethods={formMethods}
      error={error}
      onSubmit={async (value) => {
        const dirtyValues: UpdateContactOrganizationInput & {
          hasNoAddress?: boolean
        } = getDirtyFields(dirtyFields, value)
        const { hasNoAddress, ...toSubmit } = dirtyValues
        if (hasNoAddress) {
          toSubmit.primaryContact = toSubmit.primaryContact ?? {}
          toSubmit.primaryContact.address = null
        }
        updateContact({
          variables: { id: contactOrganization.id, input: toSubmit },
        })
      }}
    >
      <FormInputList
        items={[
          {
            name: 'name',
            label: 'Organization name',
            required: true,
            formInputComponent: InputField,
          },
          {
            name: 'type',
            label: 'Organization type',
            required: true,
            formInputComponent: ContactOrganizationTypeSelectField,
          },
          {
            name: 'hipaaCovered',
            label: 'Is this contact a HIPAA covered entity?',
            formInputComponent: BooleanSelectField,
            required: true,
            inputProps: {
              trueDisplay: 'Yes',
              falseDisplay: 'No',
            },
          },
          {
            name: 'primaryContact.address',
            label: 'Address',
            formInputComponent: InheritedAddressField,
            inputProps: {
              useInheritedAddressName: 'hasNoAddress',
              useInheritedAddressDescription: 'skip address',
            },
          },
          {
            name: 'primaryContact.faxNumber',
            label: 'Fax number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'primaryContact.emailAddress',
            label: 'Email address',
            formInputComponent: InputField,
          },
          {
            name: 'primaryContact.mobileNumber',
            label: 'Mobile number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'primaryContact.homeNumber',
            label: 'Home number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'primaryContact.workNumber',
            label: 'Work number',
            formInputComponent: PhoneInputField,
          },
        ]}
      />
    </SidepanelForm>
  )
}
