import { acoScores } from 'common/data/acoScores'

import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'

export const AcoScoreSelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      testId="aco-score-select-field"
      {...props}
      emptyAs={null}
      includeEmptyOption={false}
      defaultValue={props.defaultValue ?? 'ACO_ONE'}
      options={acoScores.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default AcoScoreSelectField
