import { useParams } from '@redwoodjs/router'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import PatientFinancials from 'src/components/PatientFinancials/PatientFinancials'

const PatientChartFinancials = () => {
  const params = useParams()
  const { id: patientId } = params

  return (
    <StackView
      space={75}
      className="px-6 pb-8 pt-4"
      justifyContent="center"
      direction="row"
    >
      <Box className="w-core-size-web-breakpoint-m">
        <PatientFinancials patientId={patientId} title="Financials" />
      </Box>
    </StackView>
  )
}

export default PatientChartFinancials
