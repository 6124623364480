import { useLazyQuery } from '@apollo/client'
import omit from 'lodash/omit'
import { useParams } from 'react-router-dom'
import {
  GetClaimForFormDefaultValues,
  GetClaimForFormDefaultValuesVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import BillingProviderSelectField from 'src/components/atoms/BillingProviderSelectField/BillingProviderSelectField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import PlaceOfServiceSelectField from 'src/components/atoms/PlaceOfServiceSelectField/PlaceOfServiceSelectField'
import PractitionerClinicalDetailSelectField from 'src/components/atoms/PractitionerClinicalDetailSelectField/PractitionerClinicalDetailSelectField'
import { HospitalLocationSelect } from 'src/components/HopsitalLocationSelect/HospitalLocationSelect'
import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const QUERY = gql`
  query GetClaimForFormDefaultValues($id: String!) {
    claim(id: $id) {
      id
      renderingProviderId
      supervisingProviderId
      billingProviderId
      placeOfService
      hospitalServiceFacilityId
      hospitalAdmissionDate: admissionDate
      hospitalDischargeDate: dischargeDate
    }
  }
`

const SET_CLAIM_DETAILS = gql`
  mutation SetClaimDetails($input: SetClaimDetailsInput!) {
    setClaimDetails(input: $input) {
      id
      renderingProviderId
      renderingProvider {
        npi
        practitioner {
          id
          givenName
          familyName
        }
      }
      supervisingProviderId
      supervisingProvider {
        npi
        practitioner {
          id
          givenName
          familyName
        }
      }
      billingProvider {
        id
        name
      }
      placeOfService
      hospitalServiceFacilityId
      hospitalServiceFacility {
        id
        name
        address {
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
      admissionDate
      dischargeDate
    }
  }
`

const SidepanelClaimDetailsEdit = () => {
  const { claimId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const [getFormDefaultData] = useLazyQuery<
    GetClaimForFormDefaultValues,
    GetClaimForFormDefaultValuesVariables
  >(QUERY)
  const [setClaimDetails, { loading }] = useMutation(SET_CLAIM_DETAILS)

  const formMethods = useForm({
    defaultValues: async () => {
      const result = await getFormDefaultData({ variables: { id: claimId } })

      return omit(result.data.claim, 'id', '__typename')
    },
  })

  return (
    <SidepanelPage
      header="Edit billing summary"
      description="Review and edit claim-level details. This section includes optional fields that can be completed where necessary."
    >
      <SidepanelForm
        onSubmit={(data) => {
          setClaimDetails({
            variables: {
              input: {
                claimId,
                ...data,
              },
            },
            onCompleted: () => {
              toast.success('Claim details updated')
              closeSidePanel()
            },
          })
        }}
        formMethods={formMethods}
        footerProps={{
          submitText: 'Save',
          submitting: loading,
        }}
      >
        {formMethods.formState.isLoading ? (
          <LoadingSpinner />
        ) : (
          <FormInputList
            divider={false}
            items={[
              {
                label: 'Rendering provider',
                name: 'renderingProviderId',
                required: true,
                direction: 'col',
                formInputComponent: PractitionerClinicalDetailSelectField,
                inputProps: {
                  excludeSchedulingOnlyPractitioners: true,
                },
              },
              {
                label: 'Supervising provider',
                name: 'supervisingProviderId',
                required: false,
                direction: 'col',
                formInputComponent: PractitionerClinicalDetailSelectField,
                inputProps: {
                  excludeSchedulingOnlyPractitioners: true,
                },
              },
              {
                label: 'Billing provider',
                name: 'billingProviderId',
                required: true,
                direction: 'col',
                formInputComponent: BillingProviderSelectField,
              },
              {
                label: 'Service facility location',
                name: 'hospitalServiceFacilityId',
                required: true,
                direction: 'col',
                formInputComponent: HospitalLocationSelect,
              },
              {
                label: 'Place of service',
                name: 'placeOfService',
                required: true,
                direction: 'col',
                formInputComponent: PlaceOfServiceSelectField,
              },
              {
                label: 'Hospital admission date',
                name: 'hospitalAdmissionDate',
                required: true,
                direction: 'col',
                formInputComponent: DatePickerFieldThatWorks,
              },
              {
                label: 'Hospital discharge date',
                name: 'hospitalDischargeDate',
                required: true,
                direction: 'col',
                formInputComponent: DatePickerFieldThatWorks,
              },
            ]}
          />
        )}
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelClaimDetailsEdit
