export const ADDRESS_FRAGMENT = gql`
  fragment AddressFragment on Address {
    id
    line1
    line2
    city
    state
    postalCode
    country
  }
`
