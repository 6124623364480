import { BirthHistoryFragment } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { WysiwygDisplay } from 'src/components/atoms/WysiwygDisplay/WysiwygDisplay'

import { useBirthHistoryQuery } from './useBirthHistory'

export const CommentSection = ({ patientId }: { patientId: string }) => {
  const { birthHistory } = useBirthHistoryQuery(patientId)

  return (
    <StackView
      gap={75}
      className="rounded border p-core-space-50"
      testId="birth-history-comment-section"
    >
      <Typography color="text-base-color-fg-default" textStyle="title-xs">
        Comment
      </Typography>

      <WysiwygDisplay text={(birthHistory as BirthHistoryFragment)?.comment} />
    </StackView>
  )
}
