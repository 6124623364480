import { useState, useMemo } from 'react'

import { NoSymbolIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import { PatientDemographicsFragment } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Tabs from 'src/components/molecules/Tabs/Tabs'
import { REMOVE_PATIENT_CAREGIVER_MUTATION } from 'src/pages/AppointmentCheckInPage/CheckInCaregiversTab/CaregiversVerificationCreation'
import NewCaregiverForm from 'src/pages/AppointmentCheckInPage/CheckInCaregiversTab/NewCaregiverForm'

import ExistingCaregiverSection from './ExistingCaregiverSection'

export const CaregiversTabs = ({
  patient,
}: {
  patient: PatientDemographicsFragment
}) => {
  const [removePatientCaregiver] = useMutation(
    REMOVE_PATIENT_CAREGIVER_MUTATION
  )

  const [newCaregivers, setNewCaregivers] = useState([])

  const addNewCaregiver = () => {
    setNewCaregivers((prev) => [...prev, {}])
  }

  const removeCaregiver = (index: number) => {
    setNewCaregivers((prev) => prev.filter((_, i) => i !== index))
  }

  const caregiverTabs = useMemo(() => {
    const removeExistingCaregiver = (caregiverId: string) => {
      removePatientCaregiver({
        variables: { patientId: patient.id, relatedPersonId: caregiverId },
      })
    }

    const newCaregiverTabs = newCaregivers.map((caregiver, index) => {
      const name = caregiver.familyName
        ? formatDisplayName(caregiver)
        : `New caregiver ${index + 1}`

      return {
        key: `new-${index}`,
        name,
        content: (
          <NewCaregiverForm
            patient={patient}
            displayName={name}
            onRemove={() => removeCaregiver(index)}
            onComplete={() => removeCaregiver(index)}
          />
        ),
      }
    })

    const existingCaregiverTabs = patient.patientRelatedPersonRelationships.map(
      (relationship) => {
        return {
          key: relationship.id,
          name: formatDisplayName(relationship.relatedPerson),

          content: (
            <ExistingCaregiverSection
              patient={patient}
              relationship={relationship}
              onRemove={() =>
                removeExistingCaregiver(relationship.relatedPerson.id)
              }
            />
          ),
        }
      }
    )

    return [...existingCaregiverTabs, ...newCaregiverTabs]
  }, [newCaregivers, patient, removePatientCaregiver])

  if (caregiverTabs.length == 0) {
    return (
      <StackView justifyContent="center" alignItems="center" className="h-56">
        <NoSymbolIcon className="h-8 w-8 text-gray-400" />
        <Space space={50} />
        <Typography textStyle="subtitle">No caregivers</Typography>
        <Typography color="text-base-color-fg-muted" className="text-center">
          Try contacting the patient and/or caregivers to enter this
          information.
        </Typography>
        <Space space={100} />
        <Button onClick={addNewCaregiver}>Add caregiver</Button>
      </StackView>
    )
  }

  return (
    <Tabs tabs={caregiverTabs} style="button">
      <StackView
        direction="row"
        justifyContent="end"
        fullWidth={false}
        className="grow"
      >
        <Button onClick={addNewCaregiver}>Add Caregiver</Button>
      </StackView>
    </Tabs>
  )
}

export default CaregiversTabs
