import { BellIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { GetAllLocations, GetAllLocationsVariables } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'
import { useScheduleFilter } from 'src/hooks/useScheduleFilter/useScheduleFilter'
import { sidepanelRoute } from 'src/lib/routes'

import { useSupervisingPractitionerAssignmentSidepanelData } from './useSupervisingPractitionerAssignmentSidepanelData'

const LOCATIONS_QUERY = gql`
  query GetAllLocations {
    locations {
      id
      name
    }
  }
`

export const OpenSupervisingPractitionerAssignmentSidepanelButton = () => {
  const location = useLocation()
  const params = useParams()
  const { data: locationsData } = useQuery<
    GetAllLocations,
    GetAllLocationsVariables
  >(LOCATIONS_QUERY)
  const { visitDate, locationIds } = useScheduleFilter()

  const visitDateString = format(visitDate, 'yyyy-MM-dd')

  const allLocations = locationsData?.locations ?? []

  const selectedLocations = allLocations.filter((location) =>
    locationIds?.includes(location.id)
  )
  const defaultLocationId = locationsData?.locations?.[0]?.id
  const selectedLocationId = selectedLocations?.[0]?.id
  const locationToNavigateTo = selectedLocationId ?? defaultLocationId

  const { data } = useSupervisingPractitionerAssignmentSidepanelData(
    {
      date: visitDateString,
      locationId: selectedLocationId,
    },
    { skip: !selectedLocationId || locationIds?.length !== 1 }
  )

  if (!locationToNavigateTo) return null

  const buttonText =
    data?.supervisingPractitionerAssignments?.length === 1
      ? formatDisplayName(
          data.supervisingPractitionerAssignments[0]
            .supervisingPractitionerClinicalDetail.practitioner
        )
      : data?.supervisingPractitionerAssignments?.length
        ? `${data.supervisingPractitionerAssignments.length} supervising providers`
        : 'Supervising provider'

  return (
    <Button
      buttonStyle="secondary"
      icon={BellIcon}
      text={buttonText}
      testId="open-supervising-practitioners-form-btn"
      onClick={() =>
        navigate(
          sidepanelRoute(
            {
              route: `/supervising-practitioner-assignment/${locationToNavigateTo}/${visitDateString}`,
              width: 'medium',
            },
            location,
            params
          )
        )
      }
    />
  )
}
