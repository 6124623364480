import { useParams } from '@redwoodjs/router'

import StackView from 'src/components/atoms/StackView/StackView'
import checkAge from 'src/lib/checkAge'
import { calculateAge } from 'src/lib/formatters'

import { usePatientDemographicsQuery } from '../usePatient'

import PatientAdditionalDemographicsCard from './PatientAdditionalDemographicsCard'
import PatientContactInformationCard from './PatientContactInformationCard'
import PatientCoreDemographicsCard from './PatientCoreDemographicsCard'
import PatientPharmacyCard from './PatientPharmacyCard'
import PatientPracticeDetailsCard from './PatientPracticeDetailsCard'
import PatientResidenceCard from './PatientResidenceCard'

const PatientTab = () => {
  const { id } = useParams()
  const { patient } = usePatientDemographicsQuery(id)

  if (!patient) return null

  const ageRestrictedAbilities = checkAge(calculateAge(patient.birthDate))

  return (
    <StackView space={75} className="max-w-2xl">
      <PatientPracticeDetailsCard patient={patient} />
      <PatientCoreDemographicsCard patient={patient} />
      {ageRestrictedAbilities.canHavePersonalContactInformation && (
        <PatientContactInformationCard patient={patient} />
      )}
      <PatientPharmacyCard patient={patient} />
      <PatientAdditionalDemographicsCard patient={patient} />
      <PatientResidenceCard patient={patient} />
    </StackView>
  )
}

export default PatientTab
