import { FindPatientSexualHistory, UpsertSexualHistory } from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import {
  FrontendConfiguration,
  deepOmit,
  getExistingFormValues,
  getExistingValuesForFrontend,
  useGetFrontendMappings,
} from 'src/data/utils'

import { OBSERVATION_FRAGMENT } from '../../PatientVisits/fragments'

const SEXUAL_HISTORY_FRAGMENT = gql`
  fragment SexualHistoryFragment on SexualHistory {
    id
    status {
      ...ObservationFragment
    }
    sexualIntercourse {
      anal {
        ...ObservationFragment
      }
      oral {
        ...ObservationFragment
      }
      vaginal {
        ...ObservationFragment
      }
    }
    sexualOrientation {
      ...ObservationFragment
    }
    genderIdentity {
      ...ObservationFragment
    }
    partners {
      total {
        ...ObservationFragment
      }
      inLastThreeMonths {
        ...ObservationFragment
      }
      inLastTwelveMonths {
        ...ObservationFragment
      }
    }
    contraceptives {
      barrier {
        cervicalCap {
          ...ObservationFragment
        }
        femaleCondom {
          ...ObservationFragment
        }
        maleCondom {
          ...ObservationFragment
        }
        diaphragm {
          ...ObservationFragment
        }
        spermicide {
          ...ObservationFragment
        }
        sponge {
          ...ObservationFragment
        }
        vaginalGel {
          ...ObservationFragment
        }
      }
      nonBarrier {
        maleSterilization {
          ...ObservationFragment
        }
        femaleSterilization {
          ...ObservationFragment
        }
        implant {
          ...ObservationFragment
        }
        injection {
          ...ObservationFragment
        }
        intrauterine {
          ...ObservationFragment
        }
        oralPill {
          ...ObservationFragment
        }
        transdermal {
          ...ObservationFragment
        }
        vaginalRing {
          ...ObservationFragment
        }
      }
    }
  }
  ${OBSERVATION_FRAGMENT}
`

const SEXUAL_HISTORY_QUERY = gql`
  query FindPatientSexualHistory($id: String!) {
    patient(id: $id) {
      id
      sexualHistory {
        ...SexualHistoryFragment
      }
    }
  }
  ${SEXUAL_HISTORY_FRAGMENT}
`

const UPSERT_SEXUAL_HISTORY_MUTATION = gql`
  mutation UpsertSexualHistory($input: UpsertSexualHistoryInput!) {
    upsertSexualHistory(input: $input) {
      id
      sexualHistory {
        ...SexualHistoryFragment
      }
    }
  }
  ${SEXUAL_HISTORY_FRAGMENT}
`

// algorithm:
// recursively traverse sexualHistory results,
// mapping observations to their enum values as described by codeToEnumMap

const getSexualHistoryForFrontend = (
  sexualHistory,
  codeToEnumMap,
  enumToDisplayMap,
  config?: FrontendConfiguration
) => {
  return getExistingValuesForFrontend({
    obj: sexualHistory,
    codeToEnumMap,
    enumToDisplayMap,
    config,
  })
}

export const useSexualHistoryQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientSexualHistory>(
    SEXUAL_HISTORY_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const sexualHistory = data?.patient?.sexualHistory
  const { codeToEnumMap, enumToDisplayMap } = useGetFrontendMappings(
    patientId,
    sexualHistory?.__typename
  )

  if (!codeToEnumMap || !enumToDisplayMap) return {}

  const sexualHistoryForFrontend = deepOmit(
    getSexualHistoryForFrontend(
      sexualHistory,
      codeToEnumMap,
      enumToDisplayMap,
      {
        unitInputs: {
          birthWeight: { metricUnit: 'g', displayUnits: ['lbs', 'oz'] },
        },
        checkboxInputs: [
          'oral',
          'vaginal',
          'anal',
          'cervicalCap',
          'diaphragm',
          'femaleCondom',
          'maleCondom',
          'spermicide',
          'sponge',
          'vaginalGel',
          'femaleSterilization',
          'maleSterilization',
          'implant',
          'injection',
          'intrauterine',
          'oralPill',
          'transdermal',
          'vaginalRing',
        ],
      }
    ),
    ['id', '__typename']
    // TODO: type this
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) as any

  const sexualHistoryFormValues = getExistingFormValues(
    {},
    sexualHistoryForFrontend
  )

  return {
    sexualHistory: sexualHistoryForFrontend,
    sexualHistoryFormValues,
    loading,
    error,
  }
}

export const useUpsertSexualHistory = () => {
  return useMutation<UpsertSexualHistory>(UPSERT_SEXUAL_HISTORY_MUTATION, {
    onCompleted: () => {
      toast.success('Sexual history saved')
    },
  })
}
