import Box from 'src/components/atoms/Box'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView'
import { AboRhBloodTypingSection } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/AboRhBloodTypingSection'
import { DeliverySection } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/DeliverySection'
import { NaturalMotherHistorySection } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/NaturalMotherHistorySection'
import { PatientSection } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/PatientSection'

export const VerticalDivider = () => (
  <div className="mx-4 w-[1px] bg-gray-100"></div>
)

const BirthHistory = ({
  patientId,
  testId,
}: {
  patientId: string
  testId: string
}) => {
  return (
    <Box
      horizontalPadding={100}
      verticalPadding={75}
      className={'rounded border border-gray-200 pb-core-space-100'}
    >
      <StackView space={75} className="pb-core-space-100" data-testid={testId}>
        <PatientSection patientId={patientId} />
        <Divider />
        <StackView direction="row">
          <DeliverySection patientId={patientId} />
          <VerticalDivider />
          <StackView direction="row" className="w-4/5">
            <AboRhBloodTypingSection patientId={patientId} />
            <VerticalDivider />
            <NaturalMotherHistorySection patientId={patientId} />
          </StackView>
        </StackView>
      </StackView>
    </Box>
  )
}

export default BirthHistory
