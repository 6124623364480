import { useParams } from 'react-router-dom'
import { GetHospitalVisit, GetHospitalVisitVariables } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import {
  ENCOUNTER_BILLING_FRAGMENT,
  ENCOUNTER_DIAGNOSES_FRAGMENT,
} from '../PatientVisits/fragments'

const QUERY = gql`
  query GetHospitalVisit($id: String!) {
    hospitalVisit(id: $id) {
      id
      claim {
        id
        admissionDate
        dischargeDate
        renderingProviderId
        renderingProvider {
          npi
          practitioner {
            id
            givenName
            familyName
          }
        }
        supervisingProviderId
        supervisingProvider {
          npi
          practitioner {
            id
            givenName
            familyName
          }
        }
        billingProviderId
        billingProvider {
          id
          name
        }
        placeOfService
        hospitalServiceFacility {
          id
          name
          address {
            line1
            line2
            city
            state
            country
            postalCode
          }
        }
        encounter {
          id
          ...EncounterDiagnosesFragment
          ...EncounterBillingFragment
          documents {
            id
            type {
              display
            }
            subType {
              display
            }
            title
            createdAt
          }
        }
      }
    }
  }
  ${ENCOUNTER_BILLING_FRAGMENT}
  ${ENCOUNTER_DIAGNOSES_FRAGMENT}
`
export const useHospitalVisit = () => {
  const { hospitalVisitId } = useParams()

  return useQuery<GetHospitalVisit, GetHospitalVisitVariables>(QUERY, {
    variables: {
      id: hospitalVisitId,
    },
  })
}
