import React, { useState } from 'react'

import { format } from 'date-fns'
import { DocumentNode } from 'graphql'

import {
  Form,
  useForm,
  FormError,
  FieldValues,
  DefaultValues,
} from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import Button, { Submit } from 'src/components/atoms/Button/Button'
import { DataDisplayableValue } from 'src/components/atoms/DataDisplayValue'
import DataVerificationButton from 'src/components/atoms/DataVerificationButton/DataVerificationButton'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { AccordionItem } from 'src/components/molecules/Accordion/Accordion'
import Alert from 'src/components/molecules/Alert/Alert'
import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import FormInputList, {
  Item,
} from 'src/components/molecules/FormInputList/FormInputList'
import { TooltipProps } from 'src/components/molecules/Tooltip/Tooltip'
import {
  REQUIREMENTS_REQUIRING_VERIFICATION,
  VerificationRequirement,
} from 'src/providers/context/AppointmentCheckInContext/AppointmentCheckInContext'

export interface Data extends Item {
  value: unknown
  displayValue?: DataDisplayableValue
  displayTooltipProps?: Omit<TooltipProps, 'children'>
  hideFormInput?: boolean
}

type SubmitVariables<T extends FieldValues> = {
  [key: string]: unknown
  input: Partial<T>
}

type VerificationOptions = {
  onVerify: () => void
  verificationRequirement: VerificationRequirement
}

export type DataVerificationCardProps<
  T extends FieldValues,
  U extends FieldValues,
> = {
  title: string
  data: Data[]
  updateMutation: DocumentNode
  preSubmit: (
    data: T
  ) =>
    | { variables: SubmitVariables<U> }
    | Promise<{ variables: SubmitVariables<U> }>
  verificationOptions?: VerificationOptions
  defaultOpen?: boolean
  defaultEditing?: boolean
  formMethods?: ReturnType<typeof useForm<T>>
  editButtonTestId?: string
} & (
  | {
      lastVerifiedAt?: Date
      lastUpdatedAt?: never
    }
  | {
      lastUpdatedAt?: Date
      lastVerifiedAt?: never
    }
)

export const VerificationAlert = ({
  verificationRequirement,
}: {
  verificationRequirement: VerificationRequirement
}) => {
  switch (verificationRequirement) {
    case 'soft':
      return (
        <Box horizontalPadding={100} verticalPadding={50}>
          <Alert
            title="Please verify and/or update the below information as part of check-in"
            style="warning"
          />
        </Box>
      )
    case 'hard':
      return (
        <Box horizontalPadding={100} verticalPadding={50}>
          <Alert
            title="Please verify and/or update the below information before proceeding forward with check-in"
            style="danger"
          />
        </Box>
      )
    default:
      return null
  }
}

const DataVerificationCard = <
  T extends FieldValues,
  U extends FieldValues = T,
>({
  title,
  updateMutation,
  verificationOptions,
  preSubmit,
  lastVerifiedAt,
  lastUpdatedAt,
  defaultOpen,
  defaultEditing,
  data,
  formMethods: propFormMethods,
  editButtonTestId,
}: DataVerificationCardProps<T, U>) => {
  const [isExpanded, setIsExpanded] = useState(
    defaultOpen ||
      REQUIREMENTS_REQUIRING_VERIFICATION.includes(
        verificationOptions?.verificationRequirement
      )
  )
  const [isEditing, setIsEditing] = useState(defaultEditing || false)

  const [saveUpdates, { loading: savingUpdates, error }] = useMutation(
    updateMutation,
    {
      onCompleted: () => {
        setIsEditing(false)
      },
    }
  )

  const hookFormMethods = useForm<T>({
    defaultValues: data.reduce((acc, { name, value }) => {
      acc[name] = value
      return acc
    }, {}) as DefaultValues<T>,
  })

  const formMethods = propFormMethods ?? hookFormMethods

  const onSubmit = async (data: T) => {
    const { variables } = await preSubmit(data)
    void saveUpdates({ variables })
  }

  return (
    <AccordionItem
      bordered
      isOpen={isExpanded}
      setIsOpen={setIsExpanded}
      clickableHeader={false}
      title={
        <StackView
          direction="row"
          alignItems="center"
          justifyContent="between"
          className="h-12 pr-4"
          space={50}
        >
          <StackView
            alignItems="start"
            justifyContent="center"
            className="cursor-pointer"
            direction="col"
            onClick={() => setIsExpanded((prev) => !prev)}
          >
            <Typography textStyle="title">{title}</Typography>
            {lastVerifiedAt ? (
              <Typography color="text-base-color-fg-muted">
                Last verified on {format(lastVerifiedAt, 'MM/dd/yyyy')}
              </Typography>
            ) : lastUpdatedAt ? (
              <Typography color="text-base-color-fg-muted">
                Last updated on {format(lastUpdatedAt, 'MM/dd/yyyy')}
              </Typography>
            ) : null}
          </StackView>
          {!isEditing && (
            <>
              {verificationOptions && (
                <DataVerificationButton
                  verificationRequirement={
                    verificationOptions.verificationRequirement
                  }
                  onClick={verificationOptions.onVerify}
                />
              )}
              <Button
                testId={editButtonTestId}
                buttonStyle="secondary"
                onClick={() => {
                  setIsExpanded(true)
                  setIsEditing(true)
                }}
              >
                Edit
              </Button>
            </>
          )}
          <Divider />
        </StackView>
      }
    >
      <Box className="border-l-lg rounded-b-lg border-x border-b">
        {verificationOptions && (
          <VerificationAlert
            verificationRequirement={
              verificationOptions.verificationRequirement
            }
          />
        )}
        {isEditing ? (
          <Form<T> formMethods={formMethods} onSubmit={onSubmit} error={error}>
            <StackView divider className="px-6 pb-6">
              <FormError
                error={error}
                wrapperClassName="rw-form-error-wrapper"
                titleClassName="rw-form-error-title"
                listClassName="rw-form-error-list"
              />

              <FormInputList
                items={data.map(({ hideFormInput, ...data }) => {
                  return {
                    ...data,
                    hide: hideFormInput,
                  }
                })}
              />
            </StackView>
            <StackView
              direction="row"
              alignItems="center"
              justifyContent="end"
              className="bg-gray-50 p-6"
              space={50}
            >
              <Button
                type="button"
                buttonStyle="secondary"
                onClick={() => setIsEditing(false)}
              >
                Cancel
              </Button>
              <Submit disabled={savingUpdates} loading={savingUpdates}>
                Save
              </Submit>
            </StackView>
          </Form>
        ) : (
          <StackView divider className="px-6 pb-6">
            <DataDisplayList
              data={data.map(
                ({
                  label,
                  value,
                  displayValue,
                  displayTooltipProps,
                  hide,
                }) => ({
                  label,
                  value: displayValue ?? (value as DataDisplayableValue),
                  tooltipProps: displayTooltipProps,
                  key: label,
                  hide,
                })
              )}
            />
          </StackView>
        )}
      </Box>
    </AccordionItem>
  )
}

export default DataVerificationCard
