import { observationConcepts } from 'common/cdr/concepts/observations/index'
import { objectFlip } from 'common/data/utils'
import {
  Unit,
  UpsertIntakeVitalsSummaryInput,
  UpsertPatientVitalsInput,
} from 'types/graphql'

import { RegisterOptions, useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import { Option } from 'src/components/atoms/Select/Select'
import FieldError from 'src/components/FieldError/FieldError'
import { InputFieldWithSelectableUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'

export const vitalLabels: Record<string, string> = {
  bodyHeight: 'Height',
  bodyWeight: 'Weight',
  headCircumference: 'Head circumference',
  bodyTemperature: 'Temperature',
  bloodPressureSystolic: 'Systolic blood pressure',
  bloodPressureDiastolic: 'Diastolic blood pressure',
  bloodPressure: 'Blood pressure',
  heartRate: 'Pulse',
  respiratoryRate: 'Respiratory rate',
  pulseOximetry: 'Pulse oximetry',
  fractionOfInspiredOxygen: 'Fraction of inspired oxygen (FiO2)',
}

export const vitalObservationDisplay: Record<string, string> = {
  bodyHeight: observationConcepts['BODY_HEIGHT'].display,
  bodyWeight: observationConcepts['BODY_WEIGHT'].display,
  headCircumference: observationConcepts['HEAD_CIRCUMFERENCE'].display,
  bodyTemperature: observationConcepts['BODY_TEMPERATURE'].display,
  bloodPressureSystolic: observationConcepts['BLOOD_PRESSURE_SYSTOLIC'].display,
  bloodPressureDiastolic:
    observationConcepts['BLOOD_PRESSURE_DIASTOLIC'].display,
  bloodPressure: observationConcepts['BLOOD_PRESSURE'].display,
  heartRate: observationConcepts['PULSE'].display,
  respiratoryRate: observationConcepts['RESPIRATORY_RATE'].display,
  pulseOximetry: observationConcepts['PULSE_OXIMETRY'].display,
  fractionOfInspiredOxygen:
    observationConcepts['FRACTION_OF_INSPIRED_OXYGEN'].display,
}

export const vitalObservationDisplayToField = objectFlip(
  vitalObservationDisplay
)

const vitalsValidation: Record<
  | Exclude<
      keyof UpsertIntakeVitalsSummaryInput,
      | 'patientId'
      | 'bloodPressure'
      | 'bodyWeight'
      | 'bodyMassIndex'
      | 'effectiveDateTime'
    >
  | Exclude<
      keyof UpsertPatientVitalsInput,
      | 'patientId'
      | 'bloodPressure'
      | 'bodyWeight'
      | 'bodyMassIndex'
      | 'effectiveDateTime'
    >
  | 'bloodPressureSystolic'
  | 'bloodPressureDiastolic'
  | 'bodyWeightWholeUnit'
  | 'bodyWeightPartialUnit',
  {
    [key in Unit]?: {
      min: { value: number; errorMessage: string }
      max: { value: number; errorMessage: string }
    }
  }
> = {
  bodyHeight: {
    in: {
      min: {
        value: 1,
        errorMessage: `${vitalLabels['bodyHeight']} is too low`,
      },
      max: {
        value: 120,
        errorMessage: `${vitalLabels['bodyHeight']} is too high`,
      },
    },
    cm: {
      min: {
        value: 2.5,
        errorMessage: `${vitalLabels['bodyHeight']} is too low`,
      },
      max: {
        value: 300,
        errorMessage: `${vitalLabels['bodyHeight']} is too high`,
      },
    },
  },
  bodyWeightWholeUnit: {
    lbs: {
      min: {
        value: 0.5,
        errorMessage: `${vitalLabels['bodyWeight']} is too low`,
      },
      max: {
        value: 500,
        errorMessage: `${vitalLabels['bodyWeight']} is too high`,
      },
    },
    kg: {
      min: {
        value: 0.2,
        errorMessage: `${vitalLabels['bodyWeight']} is too low`,
      },
      max: {
        value: 230,
        errorMessage: `${vitalLabels['bodyWeight']} is too high`,
      },
    },
  },
  bodyWeightPartialUnit: {
    oz: {
      min: {
        value: 0,
        errorMessage: `${vitalLabels['bodyWeight']} is too low`,
      },
      max: {
        value: 16,
        errorMessage: `${vitalLabels['bodyWeight']} is too high`,
      },
    },
    g: {
      min: {
        value: 0,
        errorMessage: `${vitalLabels['bodyWeight']} is too low`,
      },
      max: {
        value: 1000,
        errorMessage: `${vitalLabels['bodyWeight']} is too high`,
      },
    },
  },
  headCircumference: {
    in: {
      min: {
        value: 1,
        errorMessage: `${vitalLabels['headCircumference']} is too low`,
      },
      max: {
        value: 30,
        errorMessage: `${vitalLabels['headCircumference']} is too high`,
      },
    },
    cm: {
      min: {
        value: 2.5,
        errorMessage: `${vitalLabels['headCircumference']} is too low`,
      },
      max: {
        value: 75,
        errorMessage: `${vitalLabels['headCircumference']} is too high`,
      },
    },
  },
  bodyTemperature: {
    degF: {
      min: {
        value: 70,
        errorMessage: `${vitalLabels['bodyTemperature']} is too low`,
      },
      max: {
        value: 120,
        errorMessage: `${vitalLabels['bodyTemperature']} is too high`,
      },
    },
    Cel: {
      min: {
        value: 20,
        errorMessage: `${vitalLabels['bodyTemperature']} is too low`,
      },
      max: {
        value: 50,
        errorMessage: `${vitalLabels['bodyTemperature']} is too high`,
      },
    },
  },
  bloodPressureSystolic: {
    mmHg: {
      min: {
        value: 50,
        errorMessage: `${vitalLabels['bloodPressureSystolic']} is too low`,
      },
      max: {
        value: 300,
        errorMessage: `${vitalLabels['bloodPressureSystolic']} is too high`,
      },
    },
  },
  bloodPressureDiastolic: {
    mmHg: {
      min: {
        value: 10,
        errorMessage: `${vitalLabels['bloodPressureDiastolic']} is too low`,
      },
      max: {
        value: 200,
        errorMessage: `${vitalLabels['bloodPressureDiastolic']} is too high`,
      },
    },
  },
  heartRate: {
    BEATS_PER_MINUTE: {
      min: {
        value: 10,
        errorMessage: `${vitalLabels['heartRate']} is too low`,
      },
      max: {
        value: 250,
        errorMessage: `${vitalLabels['heartRate']} is too high`,
      },
    },
  },
  respiratoryRate: {
    BREATHS_PER_MINUTE: {
      min: {
        value: 5,
        errorMessage: `${vitalLabels['respiratoryRate']} is too low`,
      },
      max: {
        value: 100,
        errorMessage: `${vitalLabels['respiratoryRate']} is too high`,
      },
    },
  },
  pulseOximetry: {
    PERCENT: {
      min: {
        value: 70,
        errorMessage: `${vitalLabels['pulseOximetry']} is too low`,
      },
      max: {
        value: 100,
        errorMessage: `${vitalLabels['pulseOximetry']} is too high`,
      },
    },
  },
  fractionOfInspiredOxygen: {
    PERCENT: {
      min: {
        value: 70,
        errorMessage: `${vitalLabels['fractionOfInspiredOxygen']} is too low`,
      },
      max: {
        value: 100,
        errorMessage: `${vitalLabels['fractionOfInspiredOxygen']} is too high`,
      },
    },
  },
}

export const validateVitalFn = (vitalName: string, vitalUnit: Unit) => {
  return (value) => {
    const validation = vitalsValidation[vitalName]?.[vitalUnit]
    if (!validation) return false

    const valueAsNumber = Number(value)

    if (valueAsNumber < 0) return validation.min.errorMessage

    return valueAsNumber < validation.min.value
      ? validation.min.errorMessage
      : valueAsNumber > validation.max.value
        ? validation.max.errorMessage
        : true
  }
}

export const VitalInput = ({
  name,
  fieldErrorName = `${name}.value`,
  fieldErrorLabel = vitalLabels[name],
  unitOptions,
  validation,
  ...props
}: {
  name: string
  fieldErrorName?: string
  fieldErrorLabel?: string
  unitOptions: Option[]
  validation?: RegisterOptions
}) => {
  const { register, watch } = useFormContext()

  const formState = watch(name)

  register(name, {
    ...validation,
  })

  return (
    <Box fullWidth>
      <InputFieldWithSelectableUnit
        {...props}
        name={name}
        unitOptions={unitOptions}
        validation={{
          validate:
            formState?.value && formState?.unit
              ? validateVitalFn(name, formState?.unit)
              : undefined,
          required: validation?.required && validation.required,
          shouldUnregister: true,
        }}
        setValueAsString
      />
      <FieldError name={fieldErrorName} label={fieldErrorLabel} />
    </Box>
  )
}
