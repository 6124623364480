import { useEffect, useMemo, useState } from 'react'

import {
  arrow,
  autoPlacement,
  autoUpdate,
  computePosition,
  offset,
} from '@floating-ui/react-dom-interactions'
import { convert, LocalTime, nativeJs } from '@js-joda/core'
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  MbscCalendarColor,
  MbscCalendarEvent,
  MbscEventCreatedEvent,
  MbscRecurrenceRule,
} from '@mobiscroll/react'
import clsx from 'clsx'
import {
  startInLocationTimezone,
  endInLocationTimezone,
} from 'common/data/appointments'
import { appointmentTypeDisplay } from 'common/data/appointmentTypes'
import { clampInterval, mergeIntervals } from 'common/data/interval'
import { formatDisplayName } from 'common/utils'
import {
  addMinutes,
  format,
  parse,
  parseISO,
  subMinutes,
  startOfDay,
  endOfDay,
  isToday,
  isThisWeek,
} from 'date-fns'
import { isEqual } from 'lodash'
import { isEmpty as isEmptyLodash, isUndefined } from 'lodash'
import {
  FindPractitionersAndAppointments,
  type AvailabilityTime,
  type Location,
  type WeekdayKey,
  FindPractitionersAndAppointmentsVariables,
  AppointmentType,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import { PractitionerAppointmentTypeChip } from 'src/components/PractitionerAppointmentTypeChip/PractitionerAppointmentTypeChip'
import {
  isAppointmentCheckedIn,
  isAppointmentCheckedOut,
} from 'src/data/appointmentStatus'
import { appointmentTypeToColor } from 'src/data/appointmentTypes'
import {
  EM_AVAILABILITY,
  WELL_CHILD_AVAILABILITY,
} from 'src/data/availabilityTypes'
import { weekdayDisplay } from 'src/data/weekdays'
import { useFeatureFlagIsEnabled } from 'src/hooks/useFeatureFlagIsEnabled'
import {
  useScheduleFilter,
  useUpdateFilter,
} from 'src/hooks/useScheduleFilter/useScheduleFilter'
import { OpenSupervisingPractitionerAssignmentSidepanelButton } from 'src/pages/Sidepanel/SidepanelSupervisingPractitionerAssignment/OpenSupervisingPractitionerAssignmentSidepanelButton'
import { useAppointmentManager } from 'src/providers/context/AppointmentsManagementContext'
import { isGrayClicked } from 'src/utils/recurrenceRulesHelpers'

import { Configuration } from '../../AvailabilityException/AvailabilityExceptionForm/AvailabilityExceptionForm'
import { Hours } from '../../AvailabilityException/PractitionerAvailabilityExceptionForm/PractitionerAvailabilityExceptionForm'

import ScheduleEventTooltip, {
  ScheduleEventTooltipState,
} from './ScheduleEventTooltip'

const calendarEventDateToLocalTime = (date: string | Date): LocalTime => {
  if (date instanceof Date) {
    return LocalTime.of(date.getHours(), date.getMinutes())
  }

  return LocalTime.parse(date)
}

// Used to hide practitioners who aren't working for the entire day
const shouldRenderResource = ({
  resourceId,
  allInvalids,
  visitDate,
  selectedPractitionerIds,
}: {
  resourceId: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allInvalids: any[]
  visitDate: Date
  selectedPractitionerIds: string[] | undefined
}): boolean => {
  if (selectedPractitionerIds?.length) return true

  const weekday = visitDate
    .toLocaleString('en', { weekday: 'short' })
    .slice(0, 2)
    .toUpperCase()

  const invalidsForResource = allInvalids
    .filter((invalid) => {
      return (
        invalid.resource === resourceId &&
        invalid.recurring.weekDays === weekday
      )
    })
    .map(({ start, end, ...rest }) => ({
      ...rest,
      start: calendarEventDateToLocalTime(start),
      end: calendarEventDateToLocalTime(end),
    }))
    .sort((a, b) => {
      return a.start.compareTo(b.start)
    })

  const combinedInvalids = invalidsForResource.reduce((acc, interval) => {
    if (!acc.length) return [interval]

    const lastInterval = acc[acc.length - 1]
    const otherIntervals = acc.slice(0, -1)

    const mergedIntervals = mergeIntervals(lastInterval, interval)

    return [...otherIntervals, ...mergedIntervals]
  }, [])

  if (combinedInvalids.length !== 1) return true

  const [combinedInvalid] = combinedInvalids

  const combinedInvalidIsAllDay =
    (combinedInvalid.start.equals(LocalTime.of(0, 0)) ||
      combinedInvalid.start.equals(LocalTime.of(2, 0))) &&
    combinedInvalid.end.equals(LocalTime.of(23, 59))

  return !combinedInvalidIsAllDay
}

export const QUERY = gql`
  query FindPractitionersAndAppointments(
    $filters: AppointmentFiltersInput
    $availabilityExceptionFilter: AvailabilityExceptionsFilter
    $dates: IntervalDateTimeInput
    $holidayFilters: HolidaysQueryInput
    $practitionerIds: [String]
  ) {
    holidays(filter: $holidayFilters) {
      id
      name
      isObserved
      date
    }
    appointmentDefinitions {
      duration
      id
      code
      name
    }
    availabilityExceptions(filter: $availabilityExceptionFilter) {
      id
      configuration
      date
      locationId
      practitionerId
    }
    practitioners(practitionerIds: $practitionerIds) {
      id
      givenName
      familyName
      availabilityTimes {
        id
        weekday
        locationId
        practitionerId
        configuration
      }
      availabilityExceptions(dates: $dates) {
        id
        configuration
        date
        locationId
        practitionerId
      }
    }
    appointments(filters: $filters) {
      id
      patientSelfScheduledAt
      start
      end
      locationId
      practitioner {
        id
      }
      status
      confirmedAt
      statusUpdatedAt
      chiefComplaints
      chartingStatus
      visitComment
      isPatientRegistered
      patientId
      location {
        id
        name
        timezone
      }
      appointmentDefinitions {
        id
        type
        duration
        code
        name
      }
      patient {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
        preferredName
        birthDate
        sexAtBirth
        active
        contactInformation {
          id
          mobileNumber
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
        primaryGuardian {
          id
          contactInformation {
            id
            homeAddress {
              id
              line1
              line2
              city
              state
              postalCode
            }
          }
        }
        patientRelatedPersonRelationships {
          id
          doesResideWith
          relationshipType
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
            contactInformation {
              id
              mobileNumber
            }
          }
        }
      }
      patientRegistrationIntent {
        id
        givenName
        familyName
        phoneNumber
        birthDate
        sexAtBirth
        email
      }
    }
  }
`

const GET_PRACTITIONER_PREFERRED_APPOINTMENT_TYPES = gql`
  query GetPractitionerPreferredAppointmentTypes(
    $input: GetPractitionerPreferredAppointmentTypeDatesInput
  ) {
    practitionerPreferredAppointmentTypeDates(input: $input) {
      id
      date
      practitionerId
      appointmentType
    }
  }
`

const generateHolidaysInvalidTimes = (
  holidays,
  practitionerIds,
  locationIds
) => {
  // add holidays to result
  return holidays.reduce((acc, holiday) => {
    practitionerIds.forEach((practitionerId) => {
      locationIds.forEach((locationId) => {
        acc.push({
          start: startOfDay(parseISO(holiday.date)),
          end: endOfDay(parseISO(holiday.date)),
          resource: `${practitionerId}-${locationId}`,
          cssClass: 'practice-closed',
          text: holiday.name,
          recurring: {
            repeat: 'yearly',
            weekDays: format(parseISO(holiday.date), 'EEE')
              .toUpperCase()
              .slice(0, 2),
          },
        })
      })
    })
    return acc
  }, [])
}

const generateLocationsInvalidTimes = (
  locations,
  locationsAvailabilityExceptions,
  practitionerIds,
  holidays
) => {
  const response = locations.reduce(
    (acc, { availabilityTimes }, index) => {
      // each location
      availabilityTimes.forEach(({ weekday, locationId, configuration }) => {
        const {
          visitHours: { start, end },
          closed,
        } = configuration as unknown as Configuration

        if (closed) {
          // we cross each location against all the practitioners. We are rendering invalid times
          // not valid ones
          // if is closed just create a full day block
          practitionerIds.forEach((practitionerId) => {
            acc.invalidTimes.push({
              text: 'Practice closed',
              cssClass: 'practice-closed',
              start: '00:00',
              end: '23:59',
              recurring: { repeat: 'weekly', weekDays: weekday.slice(0, 2) },
              resource: `${practitionerId}-${locationId}`,
            })
          })
        } else {
          // We need to calculate the min start time and max end time for
          // the locations being filtered
          const localStart = LocalTime.parse(start)
          const localEnd = LocalTime.parse(end)
          if (isUndefined(acc.minTime) || localStart.isBefore(acc.minTime)) {
            acc.minTime = localStart
          }
          if (isUndefined(acc.maxTime) || localEnd.isAfter(acc.maxTime)) {
            acc.maxTime = localEnd
          }

          practitionerIds.forEach((practitionerId) => {
            // Mobiscroll doesn't render recurring events across daylights savings correctly in some cases. Having the start of the invalid be at
            // 00:00 and the end be at the start of the clinic availability causes the invalid to be one hour longer than it should be.
            // Having the invalid start at 02:00 fixes the problem
            acc.invalidTimes.push({
              start: '02:00',
              text: 'Practice closed',
              cssClass: 'practice-closed',
              end: start,
              recurring: { repeat: 'weekly', weekDays: weekday.slice(0, 2) },
              resource: `${practitionerId}-${locationId}`,
            })
            // Between this 2 invalid times exist a valid time for that location / practitioners
            acc.invalidTimes.push({
              start: end,
              end: '23:59',
              text: 'Practice closed',
              cssClass: 'practice-closed',
              recurring: { repeat: 'weekly', weekDays: weekday.slice(0, 2) },
              resource: `${practitionerId}-${locationId}`,
            })
          })
        }
      })

      if (index === locations.length - 1) {
        let minTime: LocalTime = acc.minTime
        let maxTime: LocalTime = acc.maxTime
        const defaultMinTime = LocalTime.of(6, 0)
        const defaultMaxTime = LocalTime.of(12, 0)
        if (!minTime) {
          minTime = maxTime ? maxTime.minusHours(3) : defaultMinTime
        }
        if (!maxTime) {
          maxTime = minTime ? minTime.plusHours(3) : defaultMaxTime
        }
        acc.minTime = minTime
        acc.maxTime = maxTime
      }
      return acc
    },
    {
      invalidTimes: [],
      minTime: undefined,
      maxTime: undefined,
    }
  )

  const holidayInvalidTimes = generateHolidaysInvalidTimes(
    holidays,
    practitionerIds,
    locations.map(({ id }) => id)
  )

  response.invalidTimes = [...response.invalidTimes, ...holidayInvalidTimes]
  //  We need to remove invalid times that match the location exception weekday
  // so we just filter and remove the invalid times that match the date
  // with the recurring rule and the resources for that location
  locationsAvailabilityExceptions.forEach((locationException) => {
    const exceptionWeekday = format(parseISO(locationException.date), 'EEE')
      .toUpperCase()
      .slice(0, 2)
    response.invalidTimes = response.invalidTimes.filter(
      ({
        resource,
        recurring,
      }: {
        resource: string
        recurring: Partial<MbscRecurrenceRule>
      }) => {
        if (
          recurring &&
          recurring.weekDays === exceptionWeekday &&
          resource.endsWith(locationException.locationId)
        ) {
          return false
        }
        return true
      }
    )
    const configuration =
      locationException.configuration as unknown as Configuration
    practitionerIds.forEach((practitionerId) => {
      if (configuration.closed) {
        response.invalidTimes.push({
          start: '00:00',
          end: '23:59',
          resource: `${practitionerId}-${locationException.locationId}`,
          cssClass: 'practice-closed',
          text: 'Practice override',
          recurring: {
            repeat: 'weekly',
            weekDays: exceptionWeekday,
          },
        })
      } else {
        // when is open we need to recalculate the min and max time to include exceptions from locations.
        const newPossibleMin = LocalTime.parse(configuration['start'])
        const newPossibleMax = LocalTime.parse(configuration['end'])

        if (newPossibleMin.isBefore(response.minTime)) {
          response.minTime = newPossibleMin
        }
        if (newPossibleMax.isAfter(response.maxTime)) {
          response.maxTime = newPossibleMax
        }
        response.invalidTimes.push({
          start: '00:00',
          end: configuration['start'],
          resource: `${practitionerId}-${locationException.locationId}`,
          cssClass: 'practice-closed',
          text: 'Practice override',
          recurring: {
            repeat: 'weekly',
            weekDays: exceptionWeekday,
          },
        })
        response.invalidTimes.push({
          start: configuration['end'],
          end: '23:59',
          resource: `${practitionerId}-${locationException.locationId}`,
          cssClass: 'practice-closed',
          text: 'Practice override',
          recurring: {
            repeat: 'weekly',
            weekDays: exceptionWeekday,
          },
        })
      }
    })
  })

  return {
    ...response,
    minTime: response.minTime.toString(),
    maxTime: response.maxTime.toString(),
  }
}

// This functions allows to get the invalid times that match a current day
// for a determined practitioner location
// If the result has length=1 it means the location is closed. But it has length 2 it means
// that is open. This because a closed location is only composed by 1 invalid time (00:00 - 23:59)
// meanwhile a open location is composed by 2 times: [{00:00 - startTime},{endTime - 23:59}]
const getLocationAvailableTimeOnWeekDay = (locationTimeConstraints, at) => {
  return locationTimeConstraints.invalidTimes.filter((locationInvalidTime) => {
    if (
      locationInvalidTime.recurring &&
      locationInvalidTime.recurring.weekDays === at.weekday.slice(0, 2) &&
      locationInvalidTime.resource === `${at.practitionerId}-${at.locationId}`
    ) {
      return true
    }
    return false
  })
}

const generatePractitionersInvalidTimesWhenPracticeOpen = (
  locationAvailableTime,
  allAvailabilityTimes,
  acc,
  at,
  isOverride = false
) => {
  const visitHoursEndTimeString = locationAvailableTime[1].start
  if (typeof visitHoursEndTimeString !== 'string') {
    return
  }

  const visitHoursStartTime = LocalTime.parse(locationAvailableTime[0].end)
  const visitHoursEndTime = LocalTime.parse(locationAvailableTime[1].start)

  const practitionerConfig: Hours[] = isOverride
    ? at.configuration
    : allAvailabilityTimes[`${at.weekday}-${at.locationId}`].configuration

  // if practitioner has this day configured
  const weekDays = at.weekday.slice(0, 2)
  const resource = `${at.practitionerId}-${at.locationId}`
  if (practitionerConfig && practitionerConfig.length) {
    let mockStart: LocalTime = visitHoursStartTime
    practitionerConfig.forEach(
      (
        {
          start: startString,
          end: endString,
          availabilityType,
          appointmentType,
        },
        index
      ) => {
        const configuredStart = LocalTime.parse(startString)
        const configuredEnd = LocalTime.parse(endString)
        if (
          configuredStart.isBefore(visitHoursStartTime) &&
          configuredEnd.isBefore(visitHoursStartTime)
        ) {
          return
        }
        if (
          configuredStart.isAfter(visitHoursEndTime) &&
          configuredEnd.isAfter(visitHoursEndTime)
        ) {
          return
        }

        const clampedStartEnd = clampInterval({
          interval: {
            start: configuredStart,
            end: configuredEnd,
          },
          min: visitHoursStartTime,
          max: visitHoursEndTime,
        })
        const start = clampedStartEnd.start.toString()
        const end = clampedStartEnd.end.toString()

        acc.invalidTimes.push({
          start: mockStart.toString(),
          end: start,
          resource,
          cssClass: 'practitioner-unavailable',
          text: isOverride
            ? 'Practitioner override'
            : 'Practitioner unavailable',
          recurring: {
            repeat: 'weekly',
            weekDays: weekDays,
          },
        })
        if (availabilityType === 'office-time') {
          acc.invalidTimes.push({
            start,
            end,
            resource,
            title: `Office Time ${isOverride ? 'override' : ''}`,
            type: 'office-time',
            cssClass: 'practitioner-unavailable',
            recurring: {
              repeat: 'weekly',
              weekDays: weekDays,
            },
          })
        } else if (availabilityType === WELL_CHILD_AVAILABILITY) {
          acc.wellChildAvailability.push({
            start,
            end,
            title: `Well child availability ${isOverride ? 'override' : ''}`,
            cssClass: 'well-child-availability',
            recurring: {
              repeat: 'weekly',
              weekDays: weekDays,
            },
            resource,
          })
        } else if (availabilityType === EM_AVAILABILITY) {
          acc.emAvailability.push({
            start,
            end,
            title: `E&M availability ${isOverride ? 'override' : ''}`,
            cssClass: 'em-availability',
            recurring: {
              repeat: 'weekly',
              weekDays: weekDays,
            },
            resource,
          })
        } else if (appointmentType) {
          acc.availability.push({
            start,
            end,
            title: [
              appointmentTypeDisplay[appointmentType],
              isOverride ? 'override' : null,
            ]
              .filter(Boolean)
              .join(' '),
            cssClass: [
              appointmentType.toLowerCase().replaceAll('_', '-'),
              'availability',
            ].join('-'),
            recurring: {
              repeat: 'weekly',
              weekDays: weekDays,
            },
            resource,
          })
        }

        mockStart = configuredEnd
        if (
          index === practitionerConfig.length - 1 &&
          mockStart.isBefore(visitHoursEndTime)
        ) {
          acc.invalidTimes.push({
            start: mockStart.toString(),
            end: visitHoursEndTimeString,
            recurring: {
              repeat: 'weekly',
              weekDays,
            },
            resource,
            cssClass: 'practitioner-unavailable',
            text: isOverride
              ? 'Practitioner override'
              : 'Practitioner unavailable',
          })
        }
      }
    )
  } else {
    // if practitioner does not have this day configured
    // not configured day = pratitioner not working
    acc.invalidTimes.push({
      start: visitHoursStartTime.toString(),
      end: visitHoursEndTime.toString(),
      resource: resource,
      cssClass: 'practitioner-unavailable',
      text: isOverride ? 'Practitioner override' : 'Practitioner unavailable',
      recurring: {
        repeat: 'weekly',
        weekDays: at.weekday.slice(0, 2),
      },
    })
  }
}

// This function takes the time constraint location data and practitioners data to include the
// practitioner available time and exceptions.
// Its pretty similar to the other function but it handles other few rules since a practitioner availabiity or exception
// is composed by multiple blocks of time.  [{start, end, availabiliyType(officeTime, visitAvailability,notAvailable)}]
const generatePractitionersInvalidTimes = (
  practitioners,
  locationIds,
  locationTimeConstraints
) => {
  const result = practitioners.reduce(
    (
      acc,
      {
        availabilityTimes,
        id: practitionerId,
        availabilityExceptions: practitionerAvailabilityExceptions,
      }
    ) => {
      const allAvailabilityTimes = availabilityTimes
        .filter(({ locationId }) => locationIds.includes(locationId))
        .reduce(
          (acc, curr) => {
            const day: WeekdayKey = curr.weekday
            const dayLocationKey = `${day}-${curr.locationId}`
            acc[dayLocationKey] = {
              ...curr,
              practitionerId,
              locationId: curr.locationId,
            }
            return acc
          },
          //initial reduce value
          Object.keys(weekdayDisplay).reduce((acc, key) => {
            locationIds.forEach((locationId) => {
              const dayLocationKey = `${key}-${locationId}`
              acc[dayLocationKey] = {
                weekday: key,
                practitionerId,
                locationId,
              }
            }, {})
            return acc
          }, {})
        )
      Object.values(allAvailabilityTimes).forEach(
        (at: AvailabilityTypeWithConfig) => {
          const locationAvailableTime = getLocationAvailableTimeOnWeekDay(
            locationTimeConstraints,
            at
          )
          const isOpen = locationAvailableTime.length === 2
          if (isOpen) {
            generatePractitionersInvalidTimesWhenPracticeOpen(
              locationAvailableTime,
              allAvailabilityTimes,
              acc,
              at
            )
          }
        }
      )
      // Add practitioner exceptions
      practitionerAvailabilityExceptions.forEach((exception) => {
        const resourceId = `${exception.practitionerId}-${exception.locationId}`

        const weekday = format(parseISO(exception.date), 'EEE').toUpperCase()
        // remove real availability and office times from the weekday that matches the
        // override
        acc.invalidTimes = acc.invalidTimes.filter((invalidObj) => {
          if (
            invalidObj.recurring &&
            invalidObj.recurring.weekDays === weekday.slice(0, 2) &&
            invalidObj.resource === resourceId
          ) {
            return false
          }
          return true
        })
        acc.wellChildAvailability = acc.wellChildAvailability.filter(
          (availability) => {
            if (
              availability.recurring &&
              availability.recurring.weekDays === weekday.slice(0, 2) &&
              availability.resource === resourceId
            ) {
              return false
            }

            return true
          }
        )
        acc.emAvailability = acc.emAvailability.filter((availability) => {
          if (
            availability.recurring &&
            availability.recurring.weekDays === weekday.slice(0, 2) &&
            availability.resource === resourceId
          ) {
            return false
          }

          return true
        })
        acc.availability = acc.availability.filter((availability) => {
          if (
            availability.recurring &&
            availability.recurring.weekDays === weekday.slice(0, 2) &&
            availability.resource === resourceId
          ) {
            return false
          }

          return true
        })
        const at = {
          configuration: exception.configuration,
          locationId: exception.locationId,
          practitionerId: exception.practitionerId,
          weekday: weekday as WeekdayKey,
        }
        const locationAvailableTime = getLocationAvailableTimeOnWeekDay(
          locationTimeConstraints,
          at
        )
        const isOpen = locationAvailableTime.length === 2
        if (isOpen) {
          generatePractitionersInvalidTimesWhenPracticeOpen(
            locationAvailableTime,
            allAvailabilityTimes,
            acc,
            at,
            true
          )
        }
      })

      return acc
    },
    {
      invalidTimes: [],
      wellChildAvailability: [],
      emAvailability: [],
      availability: [],
    }
  )
  return {
    invalidTimes: [
      ...locationTimeConstraints.invalidTimes,
      ...result.invalidTimes,
    ],
    wellChildAvailability: result.wellChildAvailability,
    emAvailability: result.emAvailability,
    availability: result.availability,
    minTime: locationTimeConstraints.minTime,
    maxTime: locationTimeConstraints.maxTime,
  }
}
export interface AvailabilityTimePractitionerConfig {
  start: string
  end: string
  availabilityType:
    | 'visit-availability'
    | 'office-time'
    | 'well-child-availability'
    | 'em-availability'
  appointmentType?: AppointmentType
  isPortalSelfSchedulable?: boolean
}

interface WithConfig {
  configuration: AvailabilityTimePractitionerConfig[]
}

export type AvailabilityTypeWithConfig = Omit<
  AvailabilityTime,
  'configuration'
> &
  WithConfig

interface Props {
  startDate: string
  updateTemplateWithFilter?: boolean
  endDate: string
  allLocations: Location[]
  filteredPractitionerIds: string[]
  singleResourceDisplay?: boolean
  filteredLocationIds: string[]
  onEventCreationCallback?: () => void
  disableEventCreation?: boolean
}

const nextAndPrevPropsAreEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps)
}

const ScheduleCalendar = React.memo(
  ({
    onEventCreationCallback,
    startDate,
    updateTemplateWithFilter,
    allLocations,
    singleResourceDisplay = false,
    endDate,
    filteredPractitionerIds: practitionerIds,
    filteredLocationIds: locationIds = [],
    disableEventCreation,
  }: Props) => {
    const { data, loading, error } = useQuery<
      FindPractitionersAndAppointments,
      FindPractitionersAndAppointmentsVariables
    >(QUERY, {
      variables: {
        practitionerIds: practitionerIds,
        filters: {
          startDate,
          endDate,
          practitionerIds,
          locationIds,
          excludeStatuses: ['CANCELLED', 'NO_SHOW'],
        },
        availabilityExceptionFilter: {
          startDate,
          endDate,
        },
        dates: {
          start: startDate,
          end: endDate,
        },
        holidayFilters: {
          interval: {
            start: format(parseISO(startDate), 'yyyy-MM-dd'),
            end: format(parseISO(endDate), 'yyyy-MM-dd'),
          },
          activeOnly: true,
        },
      },
    })

    const preferredAppointmentTypesEnabled = useFeatureFlagIsEnabled(
      'PRACTITIONER_PREFERRED_APPOINTMENT_TYPES'
    )

    const getPractitionerPreferredAppointmentTypesQuery = useQuery(
      GET_PRACTITIONER_PREFERRED_APPOINTMENT_TYPES,
      {
        variables: {
          input: {
            startDate: format(parseISO(startDate), 'yyyy-MM-dd'),
            endDate: format(parseISO(endDate), 'yyyy-MM-dd'),
            practitionerIds,
          },
        },
        skip: !preferredAppointmentTypesEnabled,
      }
    )

    const practitionerPreferredAppointmentTypes =
      getPractitionerPreferredAppointmentTypesQuery?.data
        ?.practitionerPreferredAppointmentTypeDates ?? []

    const {
      holidays,
      appointmentDefinitions,
      availabilityExceptions: locationAvailabilityExceptions,
      practitioners,
      appointments,
    } = loading || error
      ? {
          holidays: [],
          appointmentDefinitions: [],
          availabilityExceptions: [],
          practitioners: [],
          appointments: [],
        }
      : data
    const {
      setNewAppointmentFormValue,
      templateEvent,
      initiateAppointmentCreation,
      setAppointmentDefinitionDurations,
      selectAppointment,
      setInstance,
    } = useAppointmentManager()
    const updateFilter = useUpdateFilter()
    const { visitDate, practitionerIds: selectedPractitionerIds } =
      useScheduleFilter()
    const singleResourceDisplayed =
      singleResourceDisplay === true
        ? false
        : practitionerIds?.length === 1 && locationIds?.length === 1

    const [invalids, setInvalids] = useState([])
    const [minMaxTimes, setMinMaxTimes] = useState<{
      minTime?: string
      maxTime?: string
    }>({})
    const [tooltipData, setTooltipData] = useState<ScheduleEventTooltipState>()
    const [wellChildAvailability, setWellChildAvailability] = useState([])
    const [emAvailability, setEMAvailability] = useState([])
    const [availability, setAvailability] = useState([])
    const allLocationsObj = useMemo(() => {
      return allLocations.reduce(
        (acc, location: Location): { [locationId: string]: string } => {
          acc[location.id] = location.name as string
          return acc
        },
        {}
      )
    }, [allLocations])

    useEffect(() => {
      if (loading) return
      setAppointmentDefinitionDurations(
        appointmentDefinitions.reduce(
          (acc, appointmentDefinition) => {
            acc[appointmentDefinition.id] = appointmentDefinition.duration
            return acc
          },
          {} as Record<string, number>
        )
      )
    }, [appointmentDefinitions, setAppointmentDefinitionDurations, loading])

    useEffect(() => {
      if (loading || !practitionerIds) return

      // generate invalid times for practitioners (gray blocks)
      const {
        invalidTimes,
        wellChildAvailability,
        emAvailability,
        availability,
        minTime,
        maxTime,
      } = generatePractitionersInvalidTimes(
        practitioners.filter(
          ({ id }) =>
            practitionerIds.includes(id) || practitionerIds.length === 0
        ),
        locationIds.length === 0
          ? allLocations.map(({ id }) => id)
          : locationIds,
        generateLocationsInvalidTimes(
          allLocations.filter(
            ({ id }) => locationIds.includes(id) || locationIds.length === 0
          ),
          locationAvailabilityExceptions.filter(({ locationId }) =>
            locationIds.includes(locationId)
          ),
          practitioners.map(({ id }) => id),
          holidays
        )
      )
      setInvalids(invalidTimes)
      setMinMaxTimes({ minTime, maxTime })
      setWellChildAvailability(wellChildAvailability)
      setEMAvailability(emAvailability)
      setAvailability(availability)
    }, [
      practitioners,
      locationIds,
      allLocations,
      locationAvailabilityExceptions,
      holidays,
      practitionerIds,
      loading,
    ])

    const appointmentEvents: MbscCalendarEvent[] =
      appointments?.map((appointment) => {
        const appointmentStart = convert(
          startInLocationTimezone(appointment).toLocalDateTime()
        ).toDate()
        const appointmentEnd = convert(
          endInLocationTimezone(appointment).toLocalDateTime()
        ).toDate()

        const eventObj: MbscCalendarEvent = {
          title: [
            formatDisplayName(
              appointment.patient ?? appointment.patientRegistrationIntent,
              { showPreferredName: true }
            ),
            appointment.patientRegistrationIntent && '(NP)',
            isAppointmentCheckedIn(appointment.status)
              ? '(Checked in)'
              : isAppointmentCheckedOut(appointment.status)
                ? '(Checked out)'
                : undefined,
          ]
            .filter(Boolean)
            .join(' '),
          id: appointment.id,
          start: appointmentStart,
          end: appointmentEnd,
          color: appointmentTypeToColor(
            appointment.appointmentDefinitions[0].type
          ),
          cssClass: appointment.patientSelfScheduledAt
            ? 'appointment-event-patient-self-scheduled'
            : 'appointment-event',
          resource: `${appointment.practitioner.id}-${appointment.locationId}`,
          appointmentObject: appointment,
        }
        return eventObj
      }) ?? []

    const allResources =
      practitioners?.reduce((acc, practitioner) => {
        const { id, givenName, familyName } = practitioner || {}
        const practitionerName = `${givenName} ${familyName}`
        const locationsArray = isEmptyLodash(locationIds)
          ? allLocations.map(({ id }) => id)
          : locationIds
        let name = ''

        const defaultedPractitionerIds = practitionerIds ?? []

        const singleResource =
          defaultedPractitionerIds.length === 1 && locationIds.length === 1
        locationsArray.forEach((locationId) => {
          if (singleResource) {
            name = `${practitionerName} - ${allLocationsObj[locationId]}`
          } else if (defaultedPractitionerIds.length === 1) {
            name = allLocationsObj[locationId]
          } else if (locationIds.length === 1) {
            name = practitionerName
          } else {
            name = `${practitionerName} - ${allLocationsObj[locationId]}`
          }

          const resourceId = `${id}-${locationId}`
          const appointmentsCountForDay = appointmentEvents?.filter(
            ({ resource, appointmentObject }) =>
              resource === resourceId &&
              isEqual(
                startOfDay(visitDate),
                startOfDay(parseISO(appointmentObject.start))
              )
          )?.length

          const willRenderResource = shouldRenderResource({
            resourceId,
            visitDate,
            allInvalids: invalids,
            selectedPractitionerIds,
          })

          acc.push({
            id: resourceId,
            name: `${name}${
              singleResource || !appointmentsCountForDay
                ? ''
                : ` (${appointmentsCountForDay})`
            }`,
            practitionerId: id,
            shouldRender: willRenderResource,
          })
        })
        return acc
      }, []) ?? []

    const resources = allResources.every((resource) => !resource.shouldRender)
      ? allResources
      : allResources.filter((resource) => resource.shouldRender)

    const sortedResources = resources.sort((a, b) => {
      const practitionerA = a.name
      const practitionerB = b.name
      if (practitionerA < practitionerB) {
        return -1
      }
      if (practitionerA > practitionerB) {
        return 1
      }
      return 0
    })

    const onEventCreate = (
      args: MbscEventCreatedEvent,
      inst: Eventcalendar
    ) => {
      if (disableEventCreation || loading) return false

      const { event, action } = args
      const shour = (event.start as Date).getHours()
      const sminutes = (event.start as Date).getMinutes()
      const sfullYear = (event.start as Date).getFullYear()
      const smonth = (event.start as Date).getMonth()
      const sdate = (event.start as Date).getDate()
      const ehour = (event.end as Date).getHours()
      const eminutes = (event.end as Date).getMinutes()
      const efullYear = (event.end as Date).getFullYear()
      const emonth = (event.end as Date).getMonth()
      const edate = (event.end as Date).getDate()
      const startDateTime = parse(
        `${sfullYear}-${smonth + 1}-${sdate} ${shour}:${sminutes}`,
        'yyyy-MM-dd HH:mm',
        new Date()
      )
      const endDateTime = parse(
        `${efullYear}-${emonth + 1}-${edate} ${ehour}:${eminutes}`,
        'yyyy-MM-dd HH:mm',
        new Date()
      )
      const [grayClicked, { min: minTimePossible }] = isGrayClicked(
        inst,
        {
          ...event,
          start: startDateTime,
          end: endDateTime,
        },
        true
      )
      if (grayClicked) {
        return false
      }

      event.start = minTimePossible
      event.end = addMinutes(minTimePossible, 5)

      if (action === 'click') {
        // if there is a template event, update the associated form
        if (templateEvent) {
          const [practitionerId, locationId] = (event.resource as string).split(
            '-'
          )
          setNewAppointmentFormValue('practitioner', practitionerId)
          setNewAppointmentFormValue('location', locationId)
          setNewAppointmentFormValue('date', event.start as Date)
          setNewAppointmentFormValue(
            'timeStart',
            format(event.start as Date, 'h:mm a')
          )
          return false
        }
        event.color = '#E5E7EB'
        event.id = 'creating-appointment'
        initiateAppointmentCreation(event)
        if (onEventCreationCallback) {
          onEventCreationCallback()
        }
        return false
      }

      return false
    }

    const updatePosition = (event) => {
      const eventElement = document.querySelector(`[data-id=${event.id}]`)
      const floating = document.getElementById('floating')
      const arrowEl = document.getElementById('arrow')

      if (!eventElement || !floating || !arrowEl) return

      void computePosition(eventElement, floating, {
        middleware: [
          offset(10),
          arrow({ element: arrowEl }),
          autoPlacement({
            padding: 10,
            allowedPlacements: ['bottom', 'top', 'left', 'right'],
          }),
        ],
      }).then(({ x, y, middlewareData, placement }) => {
        Object.assign(floating.style, {
          top: `${y}px`,
          left: `${x}px`,
        })
        const isBottomOrTop = ['bottom', 'top'].includes(placement)
        const isBottom = placement === 'bottom'
        const isRight = placement === 'right'
        const { x: arrowX } = middlewareData.arrow ?? {}
        const left = `${arrowX}px`
        const bottomTopClasses = {
          top: isBottom ? '-8px' : `${floating.clientHeight}px`,
          left,
          transform: !isBottom ? 'rotateX(180deg)' : '',
        }
        const leftRightClasses = {
          top: `${floating.clientHeight / 2}px`,
          left: isRight ? '-12px' : `${floating.clientWidth - 4}px`,
          transform: `rotateZ(${isRight ? '270' : '-270'}deg)`,
        }
        Object.assign(
          arrowEl.style,
          isBottomOrTop ? bottomTopClasses : leftRightClasses
        )
      })
    }

    const autoUpdater = (event) => {
      if (event) {
        const eventElement = document.querySelector(`[data-id=${event.id}]`)
        const floating = document.getElementById('floating')
        if (eventElement && floating) {
          autoUpdate(eventElement, floating, () => updatePosition(event), {
            ancestorScroll: false,
            layoutShift: false,
          })
        }
      }
    }

    const showTooltip = (event) => {
      setTooltipData(event)
    }

    const hideTooltip = () => {
      setTooltipData(undefined)
    }

    autoUpdater(tooltipData)

    const viewType = singleResourceDisplayed ? 'week' : 'day'

    // Convert to string so it can be used in useMemo dependency array.  A Date will change every render
    const visitDateString = visitDate.toDateString()
    // useMemo here so this only changes if the selected date changes.
    // Otherwise interacting with the calendar will cause it to scroll to the current time
    const selectedDate = useMemo(() => {
      const date = new Date(visitDateString)

      const shouldScrollToCurrentTime =
        (viewType === 'week' && isThisWeek(date)) ||
        (viewType === 'day' && isToday(date))

      return shouldScrollToCurrentTime ? new Date() : date
    }, [visitDateString, viewType])

    const scheduleColors: MbscCalendarColor[] = [
      ...wellChildAvailability,
      ...emAvailability,
      ...availability,
    ]

    return (
      <>
        <Card className="front-desk-calendar h-full" contentClassName="h-full">
          <Eventcalendar
            ref={setInstance}
            onEventCreate={onEventCreate}
            onEventClick={({ event }) => {
              if (templateEvent) {
                return false
              }
              selectAppointment(event)
              hideTooltip()
            }}
            dateFormatLong="DDDD MMMM DD, YYYY"
            showEventTooltip={false}
            clickToCreate="single"
            colors={scheduleColors}
            invalid={invalids}
            onEventHoverIn={({ event }) => {
              if (templateEvent) {
                return false
              }
              showTooltip(event)
            }}
            onEventHoverOut={() => {
              hideTooltip()
            }}
            onSelectedDateChange={({ date }) => {
              if (templateEvent) {
                setNewAppointmentFormValue('date', date as Date)
                if (updateTemplateWithFilter) {
                  updateFilter('scheduleDateFilter', date as Date, {
                    saveSettings: true,
                  })
                }
              } else {
                updateFilter('scheduleDateFilter', date as Date, {
                  saveSettings: true,
                })
              }
            }}
            selectedDate={selectedDate}
            resources={sortedResources}
            renderHeader={() => (
              <StackView
                justifyContent="between"
                direction="row"
                testId="schedule-calendar-header"
              >
                <Box className="w-core-space-1500">
                  <CalendarNav />
                </Box>
                <FeatureFlagged flagName="SUPERVISING_PRACTITIONER_ASSIGNMENT">
                  <Box className="pl-core-space-400">
                    <OpenSupervisingPractitionerAssignmentSidepanelButton />
                  </Box>
                </FeatureFlagged>
                <StackView
                  justifyContent="end"
                  direction="row"
                  fullWidth={false}
                  className="w-core-space-1500"
                >
                  <CalendarPrev />
                  <CalendarToday />
                  <CalendarNext />
                </StackView>
              </StackView>
            )}
            renderDay={({ date, selected }) => {
              if (loading) return

              const practitionerId = practitionerIds[0]
              const zonedDateTime = nativeJs(date)
              const dateString = zonedDateTime.toLocalDate().toString()
              const appointmentTypeDate =
                practitionerPreferredAppointmentTypes.find(
                  (preferredAppointmentType) =>
                    preferredAppointmentType.practitionerId ===
                      practitionerId &&
                    preferredAppointmentType.date === dateString
                )

              const appointmentType =
                appointmentTypeDate?.appointmentType ?? 'UNASSIGNED'

              return (
                <StackView>
                  <Typography
                    className="uppercase"
                    textStyle="interface-default-xs"
                    color="text-base-color-fg-subtle"
                  >
                    {format(date, 'EEE')}
                  </Typography>
                  <StackView alignItems="center" space={25}>
                    <Box
                      className={clsx(
                        'flex h-core-space-250 w-core-space-250 items-center justify-center rounded-full',
                        selected ? 'bg-core-color-blue-30' : undefined
                      )}
                    >
                      <Typography
                        textStyle="interface-strong-xl"
                        className={
                          selected ? 'text-core-color-neutral-100' : undefined
                        }
                      >
                        {format(date, 'd')}
                      </Typography>
                    </Box>
                    <PractitionerAppointmentTypeChip
                      practitionerId={practitionerId}
                      date={dateString}
                      appointmentType={appointmentType}
                    />
                  </StackView>
                </StackView>
              )
            }}
            renderResource={(resource) => {
              if (loading) return

              const date = nativeJs(visitDate).toLocalDate().toString()
              const appointmentTypeDate =
                practitionerPreferredAppointmentTypes.find(
                  (preferredAppointmentType) =>
                    preferredAppointmentType.practitionerId ===
                      resource.practitionerId &&
                    preferredAppointmentType.date === date
                )

              const appointmentType =
                appointmentTypeDate?.appointmentType ?? 'UNASSIGNED'
              return (
                <StackView alignItems="center" space={50}>
                  <Typography textStyle="interface-strong-m">
                    {resource.name}
                  </Typography>
                  {viewType === 'day' ? (
                    <PractitionerAppointmentTypeChip
                      practitionerId={resource.practitionerId}
                      date={date}
                      appointmentType={appointmentType}
                    />
                  ) : null}
                </StackView>
              )
            }}
            data={[
              ...appointmentEvents,
              ...(templateEvent ? [templateEvent] : []),
            ]}
            view={{
              schedule: {
                type: viewType,
                startTime: minMaxTimes?.minTime
                  ? format(
                      subMinutes(
                        parse(minMaxTimes?.minTime, 'HH:mm', new Date()),
                        30
                      ),
                      'HH:mm'
                    )
                  : undefined,
                endTime: minMaxTimes?.maxTime
                  ? format(
                      addMinutes(
                        parse(minMaxTimes?.maxTime, 'HH:mm', new Date()),
                        30
                      ),
                      'HH:mm'
                    )
                  : undefined,
                size: singleResourceDisplayed ? undefined : 1,
                timeCellStep: 15,
                timeLabelStep: 30,
              },
            }}
            theme="material"
            themeVariant="light"
          />
        </Card>
        <ScheduleEventTooltip tooltipData={tooltipData} />
      </>
    )
  },
  nextAndPrevPropsAreEqual
)

export default ScheduleCalendar
