import { NoSymbolIcon } from '@heroicons/react/24/outline'

import Space from 'src/components/atoms/Space'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

export const NoDefaultPharmacy = () => {
  const ePrescribeProviderName = 'DoctorFirst'
  return (
    <StackView justifyContent="center" alignItems="center" className="h-56">
      <NoSymbolIcon className="h-8 w-8 text-gray-400" />
      <Space space={50} />
      <Typography textStyle="subtitle">No pharmacy set</Typography>
      <Typography color="text-base-color-fg-muted" className="text-center">
        Click Edit to set the pharmacy using {ePrescribeProviderName}
      </Typography>
    </StackView>
  )
}
