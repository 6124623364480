import Button from 'src/components/atoms/Button/Button'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'

import BillingSectionHeader from './BillingSectionHeader'
import BillingTable from './BillingTable'
import useBillingSidepanels from './useBillingSidepanels'

const VisitBillingSection = ({
  encounterId,
  chargeItems,
  primaryDiagnosis,
  isEditable,
}) => {
  const { addVisitBillingCode } = useBillingSidepanels({ encounterId })

  const sortedChargeItems = chargeItems.sort((a, b) => {
    if (a.isPrimary) {
      return -1
    }
    if (b.isPrimary) {
      return 1
    }
    return 0
  })

  return (
    <StackView space={100}>
      <BillingSectionHeader
        title="Visit type billing codes"
        subtitle="Add, remove, and edit the visit type billing code(s) associated with the visit type(s) for the visit. Codes covered in this section include well child 99381-99385 & 99391-99395, core E&M 99202-99205, 99212-99215 & 99417, and telephone E&M 99441-99443. With the exception of the primary diagnosis code, linkages of existing visit diagnosis codes to billing codes in this section can be modified."
      >
        {isEditable && (
          <Button
            buttonStyle="secondary"
            text="+ Add billing code"
            onClick={addVisitBillingCode}
          />
        )}
      </BillingSectionHeader>
      <Divider />
      <BillingTable
        encounterId={encounterId}
        testId="visit-billing-table"
        chargeItems={sortedChargeItems}
        primaryDiagnosis={primaryDiagnosis}
        isEditable={isEditable}
      />
    </StackView>
  )
}

export default VisitBillingSection
