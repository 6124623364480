import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'
import StackView from 'src/components/atoms/StackView'
import BaseModal, {
  BaseModalProps,
} from 'src/components/molecules/Modal/BaseModal'
import useRefetchPatientPharmacy from 'src/hooks/useRefetchPatientPharmacy/useRefetchPatientPharmacy'
import PatientMedicationsCell from 'src/pages/PatientChartsPage/PatientMedicationsCell'

interface EditPharmacyModalProps
  extends Pick<BaseModalProps, 'isOpen' | 'setIsOpen'> {
  patientId: string
}

export const EditPharmacyModal: React.FC<EditPharmacyModalProps> = ({
  patientId,
  isOpen,
  setIsOpen,
}) => {
  const refetchPatientPharmacy = useRefetchPatientPharmacy(patientId)
  const onClose = async () => {
    await refetchPatientPharmacy()
    setIsOpen(false)
  }
  return (
    <BaseModal isOpen={isOpen} setIsOpen={setIsOpen} onClose={onClose}>
      <Card
        className="flex h-[90vh] w-[90vw] flex-col p-5"
        contentClassName="w-full flex-grow"
        title={'DoctorFirst'}
        footer={
          <StackView direction="row" space={100} justifyContent="end">
            <Button type="button" buttonStyle="secondary" onClick={onClose}>
              Close
            </Button>
          </StackView>
        }
      >
        <PatientMedicationsCell patientId={patientId} variant="CHART" />
      </Card>
    </BaseModal>
  )
}
