import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

const NotAvailable = () => {
  return (
    <StackView
      className="px-core-space-150 py-core-space-300 text-center"
      gap={50}
    >
      <Typography textStyle="interface-strong-m">
        Whoops, this is not available
      </Typography>
      <Typography textStyle="interface-default-s">
        It looks like you are trying to access content that is not available.
      </Typography>
    </StackView>
  )
}

export default NotAvailable
