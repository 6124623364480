import { useEffect, useState } from 'react'

import { parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { match, P } from 'ts-pattern'
import {
  GetImmunizationToAdminister,
  GetLocationsForVaccineAdmin,
  GetTenantForVaccineAdmin,
  PatientEligibilityForVFC,
  VaccineFundingSource,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import { DropdownField } from 'src/components/atoms/Dropdown'
import VaccineInventorySelectField, {
  VaccineInventorySelection,
} from 'src/components/atoms/VaccineInventorySelectField/VaccineInventorySelectField'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { InputFieldWithSelectableUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import Modal from 'src/components/molecules/Modal/Modal'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { routeOptions } from 'src/data/vaccineRouteOptions'
import { siteOptions } from 'src/data/vaccineSiteOptions'
import { vfcOptions } from 'src/data/VFCOptions'
import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { usePatientOrdersQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

import { InventoryDetails } from './SidepanelVisitVaccineAdministrationView'
import { useGetImmunizationToAdminister } from './useGetImmunizationToAdminister'
import { usePatientVfcEligibility } from './usePatientVfcEligibility'

const GET_LOCATIONS = gql`
  query GetLocationsForVaccineAdmin {
    locations {
      id
      name
    }
  }
`

const GET_TENANT = gql`
  query GetTenantForVaccineAdmin {
    tenant {
      id
      practitioners {
        id
        givenName
        familyName
        medplumId
      }
    }
  }
`

export const ADMINISTER_IMMUNIZATION = gql`
  mutation AdministerImmunization($input: AdministerImmunizationInput!) {
    administerImmunization(input: $input) {
      id
    }
  }
`

const unitOptions = ['ml', 'oz', 'mg']

export const VaccineRefusalCheckboxes = ({
  immunizationData,
  disabled = false,
  showCheckboxesOverride = false,
}) => {
  return (
    <>
      {(showCheckboxesOverride ||
        !immunizationData?.immunization.ndcDetails?.ndcSale) && (
        <FormInputList
          title="Vaccine refusal"
          divider={false}
          items={[
            {
              name: 'verbalRefusalByPatient',
              label: 'Verbal vaccine refusal by the patient',
              hideLabel: true,
              formInputComponent: CheckboxField,
              inputProps: {
                label: 'Verbal vaccine refusal by the patient',
                disabled: disabled,
              },
              direction: 'col',
            },
            {
              name: 'verbalRefusalByCaregiver',
              label: 'Verbal vaccine refusal by the caregiver',
              hideLabel: true,
              formInputComponent: CheckboxField,
              inputProps: {
                label: 'Verbal vaccine refusal by the caregiver',
                disabled: disabled,
              },
              direction: 'col',
            },
            {
              name: 'refusalFormCompleted',
              label: 'Refusal to vaccinate form completed',
              hideLabel: true,
              formInputComponent: CheckboxField,
              inputProps: {
                label: 'Refusal to vaccinate form completed',
                disabled: disabled,
              },
              direction: 'col',
            },
          ]}
        />
      )}
    </>
  )
}

const SidepanelVisitVaccineAdministrationEdit = () => {
  const { data: locationData, loading: locationLoading } =
    useQuery<GetLocationsForVaccineAdmin>(GET_LOCATIONS)
  const { data: tenantData, loading: tenantLoading } =
    useQuery<GetTenantForVaccineAdmin>(GET_TENANT)

  const [administerImmunization, { loading: administeringImmunization }] =
    useMutation(ADMINISTER_IMMUNIZATION, {
      onCompleted: () => {
        toast.success('Vaccine administration saved')
      },
      refetchQueries: ['FindPatientOrders'],
    })

  const [confirmationState, waitForConfirmation] = useConfirmation()

  const { closeSidePanel } = useSidepanel()
  const { patientId, isPatientChart } = useIsPatientChart()
  const { orderId, encounterId, locationId } = useParams()
  const { orders } = usePatientOrdersQuery(patientId)
  const { data: patientVfcEligibilityData } =
    usePatientVfcEligibility(patientId)
  const order = orders?.find((order) => order.id === orderId)

  const immunizationId = order?.supportingInfo?.[0]?.split('/')[1]

  const { data: immunizationData, loading: immunizationLoading } =
    useGetImmunizationToAdminister(immunizationId)

  const { currentUser } = useEmrAuth()

  const [inventory, setInventory] = useState<
    VaccineInventorySelection | GetImmunizationToAdminister['immunization']
  >(null)

  const formMethods = useForm({
    defaultValues: {
      verbalRefusalByPatient: false,
      verbalRefusalByCaregiver: false,
      refusalFormCompleted: false,
      patientEligibilityForVFC: null,
      vaccineInventoryId: null,
      previousVaccineInventoryId: null,
      verbalVaccineConsentObtained: 'yes',
      visProvided: 'yes',
      receivedCounseling: 'yes',
      patientWasHealthy: 'yes',
      vaccineConsentFormCompleted: false,
      performerId: currentUser.medplumId,
      clinicLocationId: locationId,
      occurredAt: new Date(),
      doseQuantity: {
        value: null,
        unit: 'ml',
      },
      route: null,
      site: null,
    },
  })

  const previousVaccineInventoryId = formMethods.watch(
    'previousVaccineInventoryId'
  )
  const selectedVfcEligibility = formMethods.watch('patientEligibilityForVFC')

  // Update form values when inventory is chosen, only if we are not editing an existing immunization
  useEffect(() => {
    if (!immunizationData) {
      // if there isn't an immunizationData, then this isn't an immunization type
      const inventorySelection = inventory as VaccineInventorySelection
      formMethods.setValue(
        'doseQuantity.value',
        inventorySelection?.ndcDetails.defaultDoseAmount
      )
      formMethods.setValue(
        'doseQuantity.unit',
        inventorySelection?.ndcDetails.defaultDoseUnit
      )
      const defaultRouteCode = routeOptions.find(
        (r) => r.display === inventorySelection?.ndcDetails.defaultRoute
      )?.code
      formMethods.setValue('route', defaultRouteCode)
    }
  }, [formMethods, inventory, immunizationData])

  // Set form values when editing immunization
  useEffect(() => {
    if (immunizationData) {
      const { immunization } = immunizationData
      const values = {
        verbalRefusalByPatient: immunization.verbalRefusalByPatient,
        verbalRefusalByCaregiver: immunization.verbalRefusalByCaregiver,
        refusalFormCompleted: immunization.refusalFormCompleted,
        patientEligibilityForVFC: immunization.patientEligibilityForVFC,
        verbalVaccineConsentObtained: immunization.verbalVaccineConsentObtained
          ? 'yes'
          : 'no',
        visProvided: immunization.visProvided ? 'yes' : 'no',
        receivedCounseling: immunization.receivedCounseling ? 'yes' : 'no',
        patientWasHealthy: immunization.patientWasHealthy ? 'yes' : 'no',
        vaccineConsentFormCompleted: immunization.vaccineConsentFormCompleted,
        performerId: immunization.performerReference?.split('/')[1],
        clinicLocationId: immunization.locationId,
        occurredAt: parseISO(immunization.occurredAt),
        doseQuantity: {
          value: immunization.doseQuantity?.value,
          unit: immunization.doseQuantity?.unit,
        },
        route: immunization.route,
        site: immunization.site,
        vaccineInventoryId: !immunization.vaccineInventoryLocationId
          ? 'deletedInventory'
          : immunization.vaccineInventoryLocationId,
        previousVaccineInventoryId: !immunization.vaccineInventoryLocationId
          ? 'deletedInventory'
          : immunization.vaccineInventoryLocationId,
      }

      formMethods.reset(values)
      setInventory(immunization)
    }
  }, [immunizationData, formMethods, locationId])

  useEffect(() => {
    if (
      patientVfcEligibilityData?.patient?.vfcEligibility &&
      !immunizationData
    ) {
      formMethods.setValue(
        'patientEligibilityForVFC',
        patientVfcEligibilityData.patient.vfcEligibility
      )
    }
  }, [patientVfcEligibilityData, formMethods, immunizationData])

  // If refused vaccine, hide all other fields
  const verbalRefusalByPatient = formMethods.watch('verbalRefusalByPatient')
  const verbalRefusalByCaregiver = formMethods.watch('verbalRefusalByCaregiver')
  const refusalFormCompleted = formMethods.watch('refusalFormCompleted')

  const refusedVaccination =
    verbalRefusalByPatient || verbalRefusalByCaregiver || refusalFormCompleted

  if (!isPatientChart || !order) {
    return <SidepanelNotAvailable />
  }

  const onSubmit = (data) => {
    const input = {
      ...data,
      verbalVaccineConsentObtained:
        data.verbalVaccineConsentObtained === 'yes' ? true : false,
      visProvided: data.visProvided === 'yes' ? true : false,
      receivedCounseling: data.receivedCounseling === 'yes' ? true : false,
      patientWasHealthy: data.patientWasHealthy === 'yes' ? true : false,
      encounterId,
      orderId,
      patientId,
    }
    void administerImmunization({
      variables: { input: input },
      onCompleted: closeSidePanel,
    })
  }

  return (
    <>
      <SidepanelPage
        testId="sidepanel-vaccine-administration"
        header={order.codeDisplay}
        description={order.name}
        loading={locationLoading || tenantLoading || immunizationLoading}
      >
        <SidepanelForm
          footerProps={{
            submitText: 'Save',
            cancelText: 'Discard changes',
            submitting: administeringImmunization,
          }}
          onSubmit={onSubmit}
          formMethods={formMethods}
        >
          <VaccineRefusalCheckboxes immunizationData={immunizationData} />

          {!refusedVaccination && (
            <>
              <FormInputList
                title="Vaccination core details"
                divider={false}
                items={[
                  {
                    name: 'patientEligibilityForVFC',
                    label:
                      'Patient eligibility for Vaccines for Children (VFC)',
                    formInputComponent: DropdownField,
                    required: !refusedVaccination,
                    alert:
                      !immunizationData &&
                      patientVfcEligibilityData?.patient?.vfcEligibility &&
                      patientVfcEligibilityData.patient.vfcEligibility ===
                        selectedVfcEligibility
                        ? {
                            title:
                              'VFC eligibility auto-filled per primary health plan details.',
                            style: 'info',
                          }
                        : undefined,
                    inputProps: {
                      placeholder: 'Select One',
                      options: [
                        {
                          value: null,
                          name: 'Select One',
                          disabled: true,
                        },
                        ...vfcOptions,
                      ],
                      beforeChange: async (selectedValue) => {
                        if (
                          !currentUser.featureFlags.includes(
                            'VFC_MISMATCH_CONFIRMATION'
                          )
                        ) {
                          return
                        }
                        const selectedOption = vfcOptions.find(
                          (option) => option.value === selectedValue
                        )
                        const selectedADifferentOption =
                          selectedOption &&
                          selectedOption.value !== selectedVfcEligibility
                        if (
                          selectedADifferentOption &&
                          shouldAskForConfirmation({
                            fundingSource: inventory?.fundingSource,
                            patientEligibilityForVFC:
                              selectedOption.value as PatientEligibilityForVFC,
                          })
                        ) {
                          const { confirmed } = await waitForConfirmation()
                          return { preventSelection: !confirmed }
                        }
                      },
                      validation: {
                        shouldUnregister: true,
                      },
                      className: 'w-96 truncate',
                      itemsContainerClassName: '!w-96',
                    } as Partial<React.ComponentProps<typeof DropdownField>>,
                    direction: 'col',
                  },
                  {
                    name: 'vaccineInventoryId',
                    label: 'Vaccine inventory',
                    required: !refusedVaccination,
                    formInputComponent: VaccineInventorySelectField,
                    inputProps: {
                      cvxCode: order.code,
                      locationId: locationId,
                      onSelection: (selection) => setInventory(selection),
                      beforeChange: async (
                        selectedValue: VaccineInventorySelection
                      ) => {
                        if (
                          !currentUser.featureFlags.includes(
                            'VFC_MISMATCH_CONFIRMATION'
                          )
                        ) {
                          return
                        }
                        const selectedADifferentOption =
                          selectedValue && selectedValue.id !== inventory?.id
                        if (
                          selectedADifferentOption &&
                          shouldAskForConfirmation({
                            fundingSource: selectedValue.fundingSource,
                            patientEligibilityForVFC: selectedVfcEligibility,
                          })
                        ) {
                          const { confirmed } = await waitForConfirmation()
                          return { preventSelection: !confirmed }
                        }
                      },
                      hideExpired: true,
                      validation: {
                        shouldUnregister: true,
                      },
                      recreateDeletedInventory:
                        !!immunizationData &&
                        previousVaccineInventoryId === 'deletedInventory',
                      deletedInventory: {
                        id: 'deletedInventory',
                        location: {
                          id: immunizationData?.immunization.locationId,
                        },
                        lotNumber: immunizationData?.immunization.lotNumber,
                        expirationDate:
                          immunizationData?.immunization.expirationDate,
                        fundingSource:
                          immunizationData?.immunization.fundingSource,
                        currentDoses: 0,
                        status: 'DELETED',
                        ndcDetails: immunizationData?.immunization.ndcDetails,
                      },
                    },
                    direction: 'col',
                  },
                ]}
              />

              <InventoryDetails inventory={inventory} />

              <FormInputList
                title="Pre-vaccination checks"
                divider={false}
                leftColumnWidth="lg"
                items={[
                  {
                    name: 'verbalVaccineConsentObtained',
                    label: 'Verbal vaccine consent was obtained',
                    formInputComponent: MultiRadioButtonField,
                    required: !refusedVaccination,
                    inputProps: {
                      values: [
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ],
                      direction: 'row',
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                  },
                  {
                    name: 'visProvided',
                    label:
                      'CDC vaccine information statement (VIS) was offered and/or provided',
                    formInputComponent: MultiRadioButtonField,
                    required: !refusedVaccination,
                    inputProps: {
                      values: [
                        {
                          label: inventory?.cvxDetails?.visDate
                            ? `Yes (Publication date: ${inventory.cvxDetails.visDate})`
                            : 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ],
                      direction: 'row',
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                  },
                  {
                    name: 'receivedCounseling',
                    label:
                      'Patient and/or caregiver received counseling from a physician or qualified healthcare professional',
                    formInputComponent: MultiRadioButtonField,
                    required: !refusedVaccination,
                    inputProps: {
                      values: [
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ],
                      direction: 'row',
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                  },
                  {
                    name: 'patientWasHealthy',
                    label:
                      'Patient was healthy and not sick with moderate or severe illness at time of vaccine administration',
                    formInputComponent: MultiRadioButtonField,
                    required: !refusedVaccination,
                    inputProps: {
                      values: [
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ],
                      direction: 'row',
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                  },
                  {
                    name: 'vaccineConsentFormCompleted',
                    label: '',
                    formInputComponent: CheckboxField,
                    inputProps: {
                      label: 'Vaccine consent form completed',
                    },
                    direction: 'col',
                  },
                ]}
              />

              <FormInputList
                title="Vaccine administration details"
                className="pb-24"
                divider={false}
                items={[
                  {
                    name: 'performerId',
                    label: 'Practice staff',
                    formInputComponent: DropdownField,
                    required: !refusedVaccination,
                    inputProps: {
                      options: tenantData?.tenant.practitioners
                        .filter((p) => p.medplumId)
                        .map((practitioner) => {
                          return {
                            name: `${practitioner.givenName} ${practitioner.familyName}`,
                            value: practitioner.medplumId,
                          }
                        }),
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                    direction: 'col',
                  },
                  {
                    name: 'clinicLocationId',
                    label: 'Clinic location',
                    formInputComponent: DropdownField,
                    required: !refusedVaccination,
                    inputProps: {
                      options: locationData?.locations.map((location) => {
                        return {
                          name: location.name,
                          value: location.id,
                        }
                      }),
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                    direction: 'col',
                  },
                  {
                    name: 'occurredAt',
                    label: 'Date and time',
                    formInputComponent: DatePickerField,
                    required: !refusedVaccination,
                    inputProps: {
                      controls: ['datetime'],
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                    direction: 'col',
                  },
                  {
                    name: 'doseQuantity',
                    label: 'Dose amount',
                    formInputComponent: InputFieldWithSelectableUnit,
                    required: !refusedVaccination,
                    inputProps: {
                      type: 'number',
                      step: '.01',
                      unitOptions: unitOptions.map((route) => {
                        return {
                          name: route,
                          value: route,
                        }
                      }),
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                    direction: 'col',
                  },
                  {
                    name: 'route',
                    label: 'Route',
                    formInputComponent: DropdownField,
                    required: !refusedVaccination,
                    inputProps: {
                      options: routeOptions.map((route) => {
                        return {
                          name: route.display,
                          value: route.code,
                        }
                      }),
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                    direction: 'col',
                  },
                  {
                    name: 'site',
                    label: 'Site',
                    formInputComponent: DropdownField,
                    required: !refusedVaccination,
                    inputProps: {
                      options: siteOptions.map((site) => {
                        return {
                          name: site.display,
                          value: site.code,
                        }
                      }),
                      validation: {
                        shouldUnregister: true,
                      },
                    },
                    direction: 'col',
                  },
                ]}
              />
            </>
          )}
        </SidepanelForm>
      </SidepanelPage>
      <Modal
        isOpen={confirmationState.isConfirming}
        title="VFC eligibility and vaccine inventory mismatch"
        content="There is mismatch between patient eligibility for VFC and the selected vaccine inventory. Where needed, please correct the mismatch."
        modalStyle="warning"
        primaryButton={{
          text: 'Continue',
          onClick: confirmationState.confirm,
        }}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            return
          }

          confirmationState.cancel()
        }}
      />
    </>
  )
}

const shouldAskForConfirmation = ({
  fundingSource,
  patientEligibilityForVFC,
}: {
  fundingSource?: VaccineFundingSource
  patientEligibilityForVFC?: PatientEligibilityForVFC
}) => {
  return match(fundingSource)
    .with(P.nullish, () => false)
    .with(
      'PUBLIC_NON_VFC',
      'PUBLIC_VFC',
      'PUBLIC_OTHER',
      () => patientEligibilityForVFC === 'V01'
    )
    .with('PRIVATE_FUNDS', () =>
      match(patientEligibilityForVFC)
        .with(P.nullish, () => false)
        .with(
          'V02',
          'V03',
          'V04',
          'V05',
          'V07',
          'V22',
          'V23',
          'V24',
          'V25',
          () => true
        )
        .with('V01', 'V00', () => false)
        .exhaustive()
    )
    .exhaustive()
}

export default SidepanelVisitVaccineAdministrationEdit
