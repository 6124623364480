import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  CheckIcon,
} from '@heroicons/react/20/solid'

import { VerificationRequirement } from 'src/providers/context/AppointmentCheckInContext/AppointmentCheckInContext'

import Button from '../Button/Button'

const getButtonContent = (verificationRequirement: VerificationRequirement) => {
  switch (verificationRequirement) {
    case 'hard':
      return {
        icon: <ExclamationCircleIcon className="mr-2 h-5 w-5 text-danger" />,
        text: 'Verify',
      }
    case 'soft':
      return {
        icon: <ExclamationTriangleIcon className="mr-2 h-5 w-5 text-warning" />,
        text: 'Verify',
      }
    case 'verified':
      return {
        icon: <CheckIcon className="mr-2 h-5 w-5 text-success" />,
        text: 'Verified',
      }
    case 'none':
      return {
        icon: null,
        text: 'Verify',
      }
  }
}

const DataVerificationButton = ({
  verificationRequirement,
  onClick,
}: {
  verificationRequirement: VerificationRequirement
  onClick: () => void
}) => {
  const { icon, text } = getButtonContent(verificationRequirement)

  return (
    <Button
      buttonStyle="secondary"
      onClick={onClick}
      data-testid="data-verification-button"
    >
      {icon} {text}
    </Button>
  )
}

export default DataVerificationButton
