import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { pick, isEmpty } from 'lodash'
import type {
  FindPatientEducationDocumentQuery,
  FindPatientEducationDocumentQueryVariables,
  PatientEducationDocumentType,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField/InputField'
import PatientEducationDocumentTypeSelectField from 'src/components/atoms/PatientEducationDocumentTypeSelectField/PatientEducationDocumentTypeSelectField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { QUERY as FIND_PATIENT_EDUCATION_DOCUMENTS_QUERY } from 'src/components/PatientEducationDocumentCell'
import { patientEducationDocumentTypeDisplay } from 'src/data/patientEducationDocumentTypes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

import SidepanelForm from '../molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from '../molecules/SidepanelPage/SidepanelPage'

export const QUERY = FIND_PATIENT_EDUCATION_DOCUMENTS_QUERY

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPatientEducationDocumentQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

const UPDATE_PATIENT_EDUCATION_DOCUMENT_MUTATION = gql`
  mutation UpdatePatientEducationDocument(
    $id: String!
    $input: UpdatePatientEducationDocumentInput!
  ) {
    updatePatientEducationDocument(id: $id, input: $input) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

type FormData = {
  type?: PatientEducationDocumentType
  title?: string
  /**
   * A list of files (max length 1) or undefined. Useful for the user to upload a single file.
   *
   * This matches the type used by `FileInput`
   */
  files?: FileList
}

export const Success = ({
  patientEducationDocument,
}: CellSuccessProps<
  FindPatientEducationDocumentQuery,
  FindPatientEducationDocumentQueryVariables
>) => {
  const { closeSidePanel } = useSidepanel()
  const [
    updatePatientEducationDocument,
    { loading: updatingPatientEducationDocument },
  ] = useMutation(UPDATE_PATIENT_EDUCATION_DOCUMENT_MUTATION, {
    onCompleted: () => {
      toast.success(`Patient education document updated`)
      closeSidePanel()
    },
    refetchQueries: ['PatientEducationDocumentsQuery'],
  })
  const formMethods = useForm<FormData>({
    defaultValues: pick(patientEducationDocument, 'type', 'title'),
    shouldUnregister: true,
  })
  const {
    formState: { dirtyFields },
  } = formMethods

  const hasDirtyFields = !isEmpty(dirtyFields)

  const onSubmit = async (value: FormData) => {
    const dirtyValues: FormData = Object.fromEntries(
      Object.entries(value).filter(([key, _]) => dirtyFields[key])
    )
    const { files, ...input } = dirtyValues
    if (files !== undefined && files.length === 1) {
      input['binary'] = {
        content: await base64EncodeFile(files[0]),
        contentType: files[0].type,
      }
    }
    updatePatientEducationDocument({
      variables: { input, id: patientEducationDocument.id },
    })
  }

  return (
    <SidepanelPage
      testId="patient-education-document-edit"
      header={`Patient education: ${
        patientEducationDocumentTypeDisplay[patientEducationDocument.type]
      }`}
      subHeader={patientEducationDocument.title}
      description={`Last modified on ${format(
        new Date(patientEducationDocument.updatedAt),
        'PP'
      )} · Last modified by ${formatDisplayName(
        patientEducationDocument.updatedBy
      )}`}
    >
      <SidepanelForm
        footerProps={{
          disabled: !hasDirtyFields,
          submitText: 'Save',
          submitting: updatingPatientEducationDocument,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'type',
              label: 'Document sub-type',
              formInputComponent: PatientEducationDocumentTypeSelectField,
            },
            {
              name: 'title',
              label: 'Document title',
              formInputComponent: InputField,
              required: true,
            },

            {
              name: 'files',
              label: 'Document upload',
              formInputComponent: FileInput,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}
