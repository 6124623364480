import { AppointmentPatientCheckInQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import EthnicitySelectField from 'src/components/atoms/EthnicitySelectField/EthnicitySelectField'
import InputField from 'src/components/atoms/InputField'
import PreferredPronounsSelectField from 'src/components/atoms/PreferredPronounSelectField/PreferredPronounsSelectField'
import RaceSelectField from 'src/components/atoms/RaceSelectField/RaceSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import { ethnicityDisplay } from 'src/data/ethnicities'
import { preferredPronounDisplay } from 'src/data/preferredPronouns'
import { raceDisplay } from 'src/data/races'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext'

const VERIFY_PATIENT_CAREGIVER_ADDITIONAL_DEMOGRAPHICS = gql`
  mutation VerifyPatientCaregiverAdditionalDemographicsMutation(
    $patientId: String!
    $relatedPersonId: String!
  ) {
    verifyPatientCaregiverAdditionalDemographics(
      id: $patientId
      relatedPersonId: $relatedPersonId
    ) {
      id
      patientRelatedPersonRelationships {
        id
        relatedPerson {
          id
          additionalDemographicsVerifiedAt
        }
      }
    }
  }
`

export const UPDATE_PATIENT_CAREGIVER_ADDITIONAL_DEMOGRAPHICS = gql`
  mutation UpdatePatientCaregiverAdditionalDemographics(
    $patientId: String!
    $relatedPersonId: String!
    $input: UpdatePatientCaregiverAdditionalDemographicsInput!
  ) {
    updatePatientCaregiverAdditionalDemographics(
      id: $patientId
      relatedPersonId: $relatedPersonId
      input: $input
    ) {
      id
      patientRelatedPersonRelationships {
        id
        verifiedAt
        relatedPerson {
          id
          preferredPronouns
          race
          ethnicity
          socialSecurityNumber
        }
      }
    }
  }
`

const CaregiverAdditionalDemographicsVerificationCard: React.FC<{
  relationship: AppointmentPatientCheckInQuery['appointment']['patient']['patientRelatedPersonRelationships'][0]
}> = ({ relationship }) => {
  const { relatedPerson } = relationship

  const {
    checkInVerification,
    appointmentData: { patient },
  } = useAppointmentCheckIn()

  const [verifyPatientCaregiverAdditionalDemographics] = useMutation(
    VERIFY_PATIENT_CAREGIVER_ADDITIONAL_DEMOGRAPHICS
  )

  const { verificationRequirement, lastVerifiedAt } =
    checkInVerification['CAREGIVER'][relationship.id]['ADDITIONAL_DEMOGRAPHICS']

  const onVerify = () => {
    verifyPatientCaregiverAdditionalDemographics({
      variables: {
        patientId: patient.id,
        relatedPersonId: relatedPerson.id,
      },
    })
  }

  const preSubmit = (data) => {
    const { preferredPronouns, race, ethnicity, socialSecurityNumber } = data

    return {
      variables: {
        patientId: patient.id,
        relatedPersonId: relatedPerson.id,
        input: {
          preferredPronouns,
          race,
          ethnicity,
          socialSecurityNumber,
        },
      },
    }
  }

  return (
    <DataVerificationCard
      title="Additional demographics"
      lastVerifiedAt={lastVerifiedAt}
      defaultOpen
      updateMutation={UPDATE_PATIENT_CAREGIVER_ADDITIONAL_DEMOGRAPHICS}
      preSubmit={preSubmit}
      verificationOptions={{
        onVerify: onVerify,
        verificationRequirement,
      }}
      data={[
        {
          name: 'preferredPronouns',
          value: relatedPerson.preferredPronouns,
          displayValue:
            preferredPronounDisplay[relatedPerson.preferredPronouns],
          label: 'Preferred Pronouns',
          formInputComponent: PreferredPronounsSelectField,
        },
        {
          name: 'race',
          value: relatedPerson.race,
          displayValue: raceDisplay[relatedPerson.race],
          label: 'Race',
          formInputComponent: RaceSelectField,
        },
        {
          name: 'ethnicity',
          value: relatedPerson.ethnicity,
          displayValue: ethnicityDisplay[relatedPerson.ethnicity],
          label: 'Ethnicity',
          formInputComponent: EthnicitySelectField,
        },
        {
          name: 'socialSecurityNumber',
          value: relatedPerson.socialSecurityNumber,
          label: 'Social Security Number',
          formInputComponent: InputField,
        },
      ]}
    />
  )
}

export default CaregiverAdditionalDemographicsVerificationCard
