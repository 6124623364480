import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { insuranceTimeQualifiers } from 'src/data/insuranceTimeQualifiers'

export const InsuranceTimeQualifierSelectField = (
  props: RWSelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={insuranceTimeQualifiers.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default InsuranceTimeQualifierSelectField
