import { useEffect, useState } from 'react'

import { assertUnreachable } from 'common/utils'
import queryString from 'query-string'

import { LoginAttributes } from '@redwoodjs/auth-dbauth-web'
import { Form } from '@redwoodjs/forms'
import { navigate, routes, useLocation } from '@redwoodjs/router'
import { MetaTags } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import { Submit } from 'src/components/atoms/Button/Button'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'

const LoginPage = () => {
  const { currentUser, logIn } = useEmrAuth()
  const [isLoading, setIsLoading] = useState(false)

  const { search } = useLocation()
  const query = queryString.parse(search)
  let redirectTo = query.redirectTo as string // we don't expect multiple redirectTo query parameters
  while (redirectTo?.startsWith('/login?redirectTo=')) {
    // sometimes we redirect multiple times
    redirectTo = redirectTo.replace('/login?redirectTo=', '')
    redirectTo = decodeURIComponent(redirectTo)
  }

  useEffect(() => {
    switch (currentUser?.status) {
      case 'ACTIVE':
        if (redirectTo) {
          navigate(redirectTo)
        } else {
          navigate(routes.home())
        }
        break
      case 'CLAIMED':
        navigate(routes.onboarding())
        break
      case 'ARCHIVED':
      case 'INACTIVE':
      case 'INVITED':
        throw new Error(
          `Should not be able to log in if user is ${currentUser.status}`
        )
      case undefined:
        break
      default:
        assertUnreachable(currentUser.status)
    }
    // disabing eslint because not using exhaustive dependencies so that this only triggers once (when current user changes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const onSubmit = async (data: LoginAttributes) => {
    setIsLoading(true)
    const response = await logIn({ ...data })

    if (response.message) {
      toast(response.message)
    } else if (response.error) {
      toast.error(response.error)
    } else {
      toast.success('Welcome back!')
    }
    setIsLoading(false)
  }

  return (
    <>
      <MetaTags title="Login" description="Login to your Develo account" />

      <Form onSubmit={onSubmit}>
        <FormInputList
          items={[
            {
              name: 'username',
              label: 'Username',
              formInputComponent: InputField,
              required: true,
              direction: 'col',
              inputProps: {
                testId: 'username-field',
              },
            },
            {
              name: 'password',
              label: 'Password',
              formInputComponent: InputField,
              required: true,
              direction: 'col',
              inputProps: {
                type: 'password',
                testId: 'password-field',
              },
            },
          ]}
          divider={false}
        />

        <StackView justifyContent="center">
          <Submit loading={isLoading}>Login</Submit>
        </StackView>
      </Form>
    </>
  )
}

export default LoginPage
