import {
  PractitionerPreferredAppointmentType,
  UpsertPreferredAppointmentType,
  UpsertPreferredAppointmentTypeVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import { useFeatureFlagIsEnabled } from 'src/hooks/useFeatureFlagIsEnabled'

import Button from '../atoms/Button/Button'
import Card from '../atoms/Card/Card'
import StackView from '../atoms/StackView/StackView'
import { PopoverChip } from '../PopoverChip/PopoverChip'
import {
  StatusIndicator,
  StatusIndicatorColor,
} from '../StatusIndicator/StatusIndicator'

export interface PractitionerAppointmentTypeChipProps {
  date: string
  practitionerId: string
  appointmentType: PractitionerPreferredAppointmentType
}

const UPSERT_PREFERRED_APPOINTMENT_TYPE = gql`
  mutation UpsertPreferredAppointmentType(
    $input: UpsertPractitionerPreferredAppointmentTypeInput!
  ) {
    upsertPractitionerPreferredAppointmentType(input: $input) {
      id
      date
      practitionerId
      appointmentType
    }
  }
`

const appointmentTypeToStatusColor: {
  [key in PractitionerPreferredAppointmentType]: StatusIndicatorColor
} = {
  WELL_CHILD: 'green',
  EM: 'red',
  SCHOOL: 'yellow',
  UNASSIGNED: 'light-gray',
}

const appointmentTypeToText: {
  [key in PractitionerPreferredAppointmentType]: string
} = {
  WELL_CHILD: 'Well child',
  EM: 'E&M sick',
  SCHOOL: 'School',
  UNASSIGNED: 'Unassigned',
}

const options: PractitionerPreferredAppointmentType[] = [
  'WELL_CHILD',
  'EM',
  'SCHOOL',
  'UNASSIGNED',
]

export const PractitionerAppointmentTypeChip = ({
  practitionerId,
  date,
  appointmentType,
}: PractitionerAppointmentTypeChipProps) => {
  const [upsertPreferredAppointmentType] = useMutation<
    UpsertPreferredAppointmentType,
    UpsertPreferredAppointmentTypeVariables
  >(UPSERT_PREFERRED_APPOINTMENT_TYPE, {
    refetchQueries: ['GetPractitionerPreferredAppointmentTypes'],
  })

  const preferredAppointmentTypesEnabled = useFeatureFlagIsEnabled(
    'PRACTITIONER_PREFERRED_APPOINTMENT_TYPES'
  )

  if (!preferredAppointmentTypesEnabled) return null

  return (
    <PopoverChip
      chipProps={{
        leftContent: {
          type: 'status',
          color: appointmentTypeToStatusColor[appointmentType],
        },
        text: appointmentTypeToText[appointmentType],
        testId: 'practitioner-appointment-type-chip',
      }}
      renderPanel={({ close }) => (
        <Card testId="practitioner-appointment-type-menu">
          <StackView className="p-core-space-50">
            {options.map((option) => (
              <StackView
                key={option}
                direction="row"
                alignItems="center"
                space={25}
              >
                <StatusIndicator color={appointmentTypeToStatusColor[option]} />
                <Button
                  buttonStyle="ghost"
                  text={appointmentTypeToText[option]}
                  onClick={() => {
                    upsertPreferredAppointmentType({
                      variables: {
                        input: {
                          practitionerId,
                          date,
                          appointmentType: option,
                        },
                      },
                    })
                    close()
                  }}
                />
              </StackView>
            ))}
          </StackView>
        </Card>
      )}
    />
  )
}
