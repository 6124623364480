import { useMemo, useRef } from 'react'

import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { format, parseISO } from 'date-fns'
import groupBy from 'lodash/groupBy'
import { FindPatientScreens, Interpretation, Order } from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'
import { LocationContextType } from '@redwoodjs/router/location'

import Badge from 'src/components/atoms/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'
import { referenceToId } from 'src/components/Order/CompleteQuestionnaireOrderSidepanelForm/CompleteQuestionnaireOrderSidepanelForm'
import {
  interpretationDisplay,
  interpretationToColor,
} from 'src/data/interpretations'
import { sidepanelRoute } from 'src/lib/routes'
import { getOrderResultsRoute } from 'src/pages/PatientChartsPage/PatientVisits/pages/IntakePage/ScreeningsTable'

import { useScreensQuery } from './useScreens'

type ScreenTableRow = {
  name: string
  dataByDate: {
    [key: string]: {
      value: string
      interpretation?: Interpretation
      order?: Order
      route?: string
    }
  }
}

const defaultColDef: ColDef = {
  minWidth: 200,
}

const nameColDef: ColDef = {
  colId: 'name',
  pinned: 'left',
  cellStyle: { display: 'inline-block' },
  cellClass: 'bg-gray-100',
  minWidth: 280,
  maxWidth: 400,
  autoHeight: true,
  cellRenderer: ({ data }: ICellRendererParams<ScreenTableRow>) => (
    <StackView className="whitespace-pre-line py-2" justifyContent="center">
      <Typography className="flex-1" fontWeight="semibold">
        {data.name}
      </Typography>
    </StackView>
  ),
}

const ScreenCellRenderer = (params: ICellRendererParams<ScreenTableRow>) => {
  const key = params.colDef.colId

  if (!params.data.dataByDate[key])
    return (
      <StackView className="h-full" justifyContent="center">
        <Typography>-</Typography>
      </StackView>
    )

  const { value, interpretation, route } = params.data.dataByDate[key]

  return (
    <StackView
      className={clsx(['h-full', route && 'cursor-pointer'])}
      direction="row"
      space={50}
      alignItems="center"
    >
      <>
        {/* Only show value if it is not the same as interpretation badge */}
        {interpretationDisplay[interpretation] !== value && (
          <Typography>{value}</Typography>
        )}

        {interpretation && (
          <div>
            <Badge color={interpretationToColor[interpretation] ?? 'dark-gray'}>
              {interpretationDisplay[interpretation]}
            </Badge>
          </div>
        )}
      </>
    </StackView>
  )
}

const transformScreens = (
  screens: FindPatientScreens['patient']['screens']
) => {
  return Object.values(
    screens.reduce((acc, screen) => {
      const name = screen.name
      if (!acc[name]) {
        acc[name] = {
          name: screen.name,
          dataByDate: {},
        }
      }

      const date = format(parseISO(screen.effectiveAt), 'yyyy-MM-dd')

      const order = screen.order
      const encounterReference =
        screen.encounterReference ?? order?.encounterReference
      const encounterId = encounterReference
        ? referenceToId(encounterReference, 'Encounter')
        : undefined

      const route =
        order && encounterId
          ? getOrderResultsRoute({
              order,
              encounterId,
            })
          : undefined

      acc[name].dataByDate[date] = {
        value: screen.value.display,
        interpretation: screen.interpretation?.display,
        order,
        route,
      }
      return acc
    }, {}) as { [key: string]: ScreenTableRow }
  ).sort((a, b) => a.name.localeCompare(b.name))
}

const buildColDefs = (
  screens: FindPatientScreens['patient']['screens'],
  location: LocationContextType
) => {
  const columnDataKeys = Object.keys(
    groupBy(screens, (screen) =>
      format(parseISO(screen.effectiveAt), 'yyyy-MM-dd')
    )
  )
    .sort((a, b) => {
      return a.localeCompare(b)
    })
    .reverse()

  return [
    nameColDef,
    ...columnDataKeys.map((key) => {
      return {
        colId: key,
        headerName: format(parseISO(key), 'MMM dd, yyyy'),
        cellRenderer: ScreenCellRenderer,
        onCellClicked: (e) => {
          {
            if (e.data.dataByDate[key].route) {
              navigate(
                sidepanelRoute(
                  {
                    route: e.data.dataByDate[key].route,
                  },
                  location
                )
              )
            }
          }
        },
      }
    }),
  ]
}

const ScreensTable = ({ patientId }: { patientId: string }) => {
  const gridRef = useRef<AgGridReact>()
  const { screens } = useScreensQuery(patientId)
  const location = useLocation()

  const { screensByDateAndCode, colDefs } = useMemo(() => {
    if (!screens) return {}

    return {
      screensByDateAndCode: transformScreens(screens),
      colDefs: buildColDefs(screens, location),
    }
  }, [screens, location])

  return (
    <Table
      testId="screens-table"
      innerRef={gridRef}
      rowData={screensByDateAndCode}
      domLayout="autoHeight"
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={colDefs}
      animateRows={true}
      noRowsOverlayComponentParams={{ message: 'No screens recorded' }}
      pagination={false}
    />
  )
}

export default ScreensTable
