import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

const SEND_FAXES_MUTATION = gql`
  mutation SendFaxes($input: SendFaxesInput!) {
    sendFaxes(input: $input) {
      id
    }
  }
`

const useSendFax = ({ afterCompleted }: { afterCompleted?: () => void }) => {
  return useMutation(SEND_FAXES_MUTATION, {
    onCompleted: () => {
      toast.success('Fax queued for sending')
      if (afterCompleted) {
        afterCompleted()
      }
    },
    refetchQueries: [
      'MyOutboundFaxesQuery',
      'GetOpenOrders',
      'PaginatedOutboundFaxesQuery',
    ],
  })
}

export default useSendFax
