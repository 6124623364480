import { useEffect, useMemo } from 'react'

import { isEmpty, omit } from 'lodash'
import {
  ObservationDefinition,
  OrderWithObservationsFragment,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import Divider from 'src/components/atoms/Divider'
import { FieldLabel } from 'src/components/atoms/Label'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import {
  OverallFindingInput,
  OrderRefusal,
} from 'src/components/Order/CompleteScreeningOrderSidepanelForm/CompleteScreeningOrderSidepanelForm'
import { VisionScreeningTable } from 'src/components/Order/CompleteVisionScreeningOrderSidepanelForm/VisionScreeningTable'
import { useCompleteVisionScreeningOrderMutation } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const visionScreeningResultQuestionCodeToField: Record<string, string> = {
  '386708005': 'leftEye',
  '397534005': 'leftEyeCorrected',
  '386709002': 'rightEye',
  '397535006': 'rightEyeCorrected',
  '363983007': 'bothEyes',
  '397536007': 'bothEyesCorrected',
}

const visionScreeningResultFieldToQuestionCode: Record<string, string> = {
  leftEye: '386708005',
  leftEyeCorrected: '397534005',
  rightEye: '386709002',
  rightEyeCorrected: '397535006',
  bothEyes: '363983007',
  bothEyesCorrected: '397536007',
}

const defaultVisionScreeningResults = {
  leftEye: {
    questionCode: visionScreeningResultFieldToQuestionCode.leftEye,
    answerCode: null,
  },
  leftEyeCorrected: {
    questionCode: visionScreeningResultFieldToQuestionCode.leftEyeCorrected,
    answerCode: null,
  },
  rightEye: {
    questionCode: visionScreeningResultFieldToQuestionCode.rightEye,
    answerCode: null,
  },
  rightEyeCorrected: {
    questionCode: visionScreeningResultFieldToQuestionCode.rightEyeCorrected,
    answerCode: null,
  },
  bothEyes: {
    questionCode: visionScreeningResultFieldToQuestionCode.bothEyes,
    answerCode: null,
  },
  bothEyesCorrected: {
    questionCode: visionScreeningResultFieldToQuestionCode.bothEyesCorrected,
    answerCode: null,
  },
}

const VisionScreeningMethodInput = ({
  observationDefinitions,
}: {
  observationDefinitions: ObservationDefinition[]
}) => {
  const methodDefinition = observationDefinitions?.find(
    (obs) => obs.questionCode === 'VISUAL_SCREENING_CHART_OR_DEVICE'
  )

  if (isEmpty(methodDefinition)) return null

  const visionMethodOptions = methodDefinition.validCodedValueSet?.map(
    (option) => {
      return {
        value: option.code,
        name: option.display,
      }
    }
  )

  return (
    <>
      <FieldLabel name="methods">
        <Typography textStyle="subtitle">{methodDefinition.label}</Typography>
      </FieldLabel>

      <StackView space={25}>
        {visionMethodOptions.map((option) => (
          <CheckboxField
            key={option.value}
            name={`methods`}
            label={option.name}
            value={option.value}
          />
        ))}
      </StackView>
    </>
  )
}

const ScreeningFindings = ({
  order,
}: {
  order: OrderWithObservationsFragment
}) => {
  if (isEmpty(order) || isEmpty(order.observationDefinitions)) return null

  return (
    <StackView space={75}>
      <Typography textStyle="title" fontWeight="semibold">
        Findings
      </Typography>
      <Divider />
      <StackView space={75}>
        <OverallFindingInput
          observationDefinition={order.observationDefinitions[0]}
          disabled={order.doNotPerform}
        />
        <Divider />
        <VisionScreeningTable
          observationDefinitions={order.observationDefinitions}
        />
        <Divider />
        <VisionScreeningMethodInput
          observationDefinitions={order.observationDefinitions}
        />
      </StackView>
    </StackView>
  )
}

const ScreeningNotes = ({ order }) => {
  return (
    <StackView space={50}>
      <FieldLabel name="notes">
        <Typography textStyle="subtitle">Notes</Typography>
      </FieldLabel>
      <TextAreaField disabled={order.doNotPerform} name="notes" rows={5} />
    </StackView>
  )
}

export const CompleteVisionScreeningOrderSidepanelForm = ({
  patientId,
  encounterId,
  order,
}: {
  patientId: string
  encounterId: string
  order: OrderWithObservationsFragment
}) => {
  const [completeVisionScreeningOrder, { loading: completingOrder }] =
    useCompleteVisionScreeningOrderMutation()

  const { closeSidePanel } = useSidepanel()

  const visionScreeningResults = useMemo(() => {
    const observations = order.observations
    const screeningObservation = observations?.[0]
    if (
      isEmpty(screeningObservation) ||
      isEmpty(screeningObservation.components)
    ) {
      return {}
    }

    const screeningResult = {}
    for (const component of screeningObservation.components) {
      const questionCode = component.code.code
      const answerCode = component.value.value

      if (visionScreeningResultQuestionCodeToField[questionCode]) {
        screeningResult[
          visionScreeningResultQuestionCodeToField[questionCode]
        ] = {
          questionCode,
          answerCode,
        }
      }
    }

    return { ...defaultVisionScreeningResults, ...screeningResult }
  }, [order.observations])

  const defaultFormValues = useMemo(() => {
    const methods = {}
    order.observations?.[0]?.methods?.forEach((method) => {
      methods[method.code] = true
    })

    return {
      notes: order.observations?.[0]?.notes || '',
      overallFinding: order.code
        ? {
            questionCode: order.code,
            answerCode:
              order.observations?.find(
                (observation) => observation.coding[0].code === order.code
              )?.value.value || null,
          }
        : undefined,
      findings: !isEmpty(visionScreeningResults)
        ? visionScreeningResults
        : defaultVisionScreeningResults,
      methods,

      refusalReasons: order.refusalReasons || {
        refusalByPatient: false,
        refusalByCaregiver: false,
        uncooperativePatient: false,
        contraindicatedForPatient: false,
        underCareOfAnotherProvider: false,
      },
    }
  }, [
    order.code,
    order.observations,
    visionScreeningResults,
    order.refusalReasons,
  ])

  const formMethods = useForm({
    defaultValues: defaultFormValues,
  })

  const refusalReasons = formMethods.watch('refusalReasons')

  const doNotPerform = Object.values(omit(refusalReasons, '__typename')).some(
    Boolean
  )

  useEffect(() => {
    formMethods.reset(defaultFormValues)
  }, [defaultFormValues, formMethods])

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Save',
        cancelText: order.doNotPerform ? 'Close' : 'Discard changes',
        submitting: completingOrder,
        disabled: completingOrder || order.doNotPerform,
      }}
      formMethods={formMethods}
      onSubmit={async (input) => {
        const methods = Object.keys(input.methods).filter(
          (key) => input.methods[key]
        )
        await completeVisionScreeningOrder({
          variables: {
            input: {
              ...input,
              methods,
              orderId: order.id,
              patientId,
              encounterId,
              refusalReasons: omit(input.refusalReasons, '__typename'),
            },
          },
          onCompleted: () => {
            toast.success('Vision screening results updated')
            closeSidePanel()
          },
        })
      }}
    >
      <StackView space={75} className="pb-4">
        <OrderRefusal order={order} description="Screening refusal" />
        {!doNotPerform && (
          <>
            <ScreeningFindings order={order} />
            <ScreeningNotes order={order} />
          </>
        )}
      </StackView>
    </SidepanelForm>
  )
}

export default CompleteVisionScreeningOrderSidepanelForm
