import { ReactElement, useEffect, useState } from 'react'

import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import {
  get,
  RegisterOptions,
  useFormContext,
  useWatch,
} from '@redwoodjs/forms'

import Typography from '../Typography'

export interface ComboboxOption {
  value: string
  name: string
  disabled?: boolean
  description?: string | ReactElement
  synonyms?: string[]
  heading?: boolean
  key?: string
}

interface Props {
  options: ComboboxOption[]
  className?: string
  onChange?: (selectedId: string) => void
  name?: string
  value?: string
  icon?: React.FunctionComponent<React.ComponentProps<'svg'>>
  hasError?: boolean
  onClickIcon?: () => void
  required?: boolean
  emptyOptionsMessage?: string
  allowAddingNewOptions?: boolean
  onOptionsUpdated?: (options: ComboboxOption[]) => void
  filterCallback?: (query: string) => ComboboxOption[]
  cannotEditExistingValues?: boolean
  forceDisplayPlaceholder?: boolean
  showSearchIcon?: boolean
  disabled?: boolean
  limit?: number
}

export const ComboboxInput = ({
  options,
  value,
  icon = ChevronUpDownIcon,
  onChange,
  className = '',
  emptyOptionsMessage,
  onClickIcon,
  name,
  filterCallback,
  hasError = false,
  required = false,
  allowAddingNewOptions = false,
  onOptionsUpdated,
  cannotEditExistingValues = false,
  forceDisplayPlaceholder = false,
  showSearchIcon = false,
  disabled = false,
  limit = 50,
}: Props) => {
  const [query, setQuery] = useState('')
  const [filteredOptions, setFilteredOptions] = useState([])
  const Icon = icon

  // Callback for when a new option is added by user input
  const newOptionAdded = (val) => {
    if (val && !options.find((x) => x.value === val)) {
      if (onOptionsUpdated) {
        onOptionsUpdated([...options, { value: val, name: val }])
      }
    }
  }

  // Whenever query or options are updated we set the filteredOptions without rerendering the whole component
  useEffect(() => {
    setFilteredOptions(() =>
      (query === ''
        ? options
        : filterCallback
          ? filterCallback(query)
          : options.filter((option) => {
              if (option.heading) return true

              return query
                .split(/\s+/)
                .every(
                  (queryTerm) =>
                    option.name
                      .toLowerCase()
                      .includes(queryTerm.toLowerCase()) ||
                    (option.synonyms &&
                      option.synonyms.some((synonym) =>
                        synonym.toLowerCase().includes(queryTerm)
                      ))
                )
            })
      ).slice(0, limit)
    )
  }, [query, options, filterCallback, limit])

  const errorClasses = [
    'border-red-300 focus:border-danger',
    'focus:outline-none focus:ring-danger',
    'text-red-900 placeholder-red-300',
  ]

  const description = options.find((o) => o.value === value)?.description

  return (
    <Combobox
      as="div"
      defaultValue={value}
      onChange={(val) => {
        if (allowAddingNewOptions) {
          newOptionAdded(val)
        }
        if (onChange) {
          onChange(val)
        }
      }}
      id={name}
      value={value}
      disabled={disabled}
    >
      <div className={clsx('relative')}>
        <Combobox.Input
          className={clsx(
            'block',
            'w-full',
            'rounded-md',
            'shadow-sm',
            'focus:border-primary',
            'border-gray-300',
            'focus:ring-primary',
            'md:text-sm',
            'text-md',
            'pr-8',
            showSearchIcon ? 'pl-8' : '',
            description ? 'h-12 pt-0' : '',
            ...(hasError ? errorClasses : []),
            className
          )}
          autoComplete="off"
          placeholder={
            forceDisplayPlaceholder || options?.length === 0
              ? emptyOptionsMessage
              : undefined
          }
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(optionId?: string) => {
            return options.find((option) => option.value === optionId)?.name
          }}
          data-testid="combobox-input"
          required={required}
          onFocus={(event) => {
            if (cannotEditExistingValues) {
              event.target.disabled = true
            }
          }}
        />
        {description && (
          <>
            {typeof description == 'string' ? (
              <Typography
                color="text-base-color-fg-muted"
                className="absolute bottom-0.5 left-3 w-full truncate pr-12"
              >
                {description}
              </Typography>
            ) : (
              <div className="absolute left-3 top-7 h-5 w-full truncate pr-12 text-gray-500">
                {description}
              </div>
            )}
          </>
        )}
        {showSearchIcon && (
          <div className="h-full items-center">
            <MagnifyingGlassIcon
              className="absolute inset-y-1/4 left-2 flex h-5 w-5 items-center text-gray-400"
              aria-hidden="true"
            />
          </div>
        )}

        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          data-testid="combobox-dropdown"
          onClick={(e) => {
            if (onClickIcon) {
              e.preventDefault()
              onClickIcon()
            }
          }}
        >
          <Icon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>
        {(filteredOptions.length > 0 ||
          (allowAddingNewOptions && query.length > 0)) && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full divide-y overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {/* Allow creating new options if query does not match existing value */}
            {allowAddingNewOptions &&
              query.length > 0 &&
              !filteredOptions.find((o) => o.value === query) && (
                <Combobox.Option
                  value={query}
                  className={({ active }) =>
                    clsx(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-primary text-white' : 'text-gray-900'
                    )
                  }
                >
                  Create {query}
                </Combobox.Option>
              )}
            {filteredOptions.map((option) => (
              <Combobox.Option
                key={option.key ?? option.value}
                value={option.value}
                className={({ active }) =>
                  clsx(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-primary text-white' : 'text-gray-900',
                    (option.disabled || option.heading) && 'bg-gray-100'
                  )
                }
                disabled={option.disabled || option.heading}
                data-testid="combobox-options"
              >
                {({ active, selected, disabled }) => (
                  <>
                    <span
                      className={clsx(
                        'block',
                        selected && 'font-semibold',
                        disabled && 'font-medium text-gray-400'
                      )}
                      title={option.name}
                    >
                      {option.name}
                    </span>

                    {selected && (
                      <span
                        className={clsx(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}

                    {option.description && (
                      <>
                        {typeof option.description == 'string' ? (
                          <span
                            className={clsx(
                              active ? 'text-white' : 'text-gray-500'
                            )}
                          >
                            {option.description}
                          </span>
                        ) : (
                          <div
                            className={clsx(
                              active ? 'text-white' : 'text-gray-500'
                            )}
                          >
                            {option.description}
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

export interface ComboboxFieldProps extends Props {
  name?: string
  validation?: RegisterOptions
}

export const ComboboxField = ({
  name,
  validation,
  onOptionsUpdated,
  ...rest
}: ComboboxFieldProps) => {
  const { formState, register, setValue, unregister } = useFormContext()

  register(name, validation)
  const value = useWatch({
    name,
  })
  useEffect(() => {
    return () => validation?.shouldUnregister && unregister(name)
  }, [name, unregister, validation?.shouldUnregister])

  const hasError = !!get(formState.errors, name)

  return (
    <ComboboxInput
      name={name}
      hasError={hasError}
      value={value}
      onChange={(val) => {
        setValue(name, val)
      }}
      onOptionsUpdated={(options) => onOptionsUpdated(options)}
      {...rest}
    />
  )
}
