import type { SelectActivePractitioners } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import ActivePractitionerSelectField, {
  ActivePractitionerSelectFieldProps,
} from 'src/components/ActivePractitionerSelectCell/ActivePractitionerSelectField'

import { RWSelectFieldProps } from '../atoms/SelectField'

export const QUERY = gql`
  query SelectActivePractitioners {
    activePractitioners {
      id
      medplumId
      givenName
      familyName
    }
  }
`

export const Loading = (props: RWSelectFieldProps) => (
  <ActivePractitionerSelectField activePractitioners={[]} {...props} />
)

export const Empty = (props: RWSelectFieldProps) => (
  <ActivePractitionerSelectField activePractitioners={[]} {...props} />
)

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-base-color-fg-danger">Error: {error?.message}</div>
)

export const Success = ({
  activePractitioners,
  ...rest
}: CellSuccessProps<SelectActivePractitioners> &
  ActivePractitionerSelectFieldProps) => {
  return (
    <ActivePractitionerSelectField
      activePractitioners={activePractitioners}
      {...rest}
    />
  )
}
