import { PatientChargesSection } from 'src/components/PatientFinancials/components/PatientChargesSection'

import { useVisit } from '../../useVisit'

const VisitChargesPage = () => {
  const { patientId, appointmentId } = useVisit()

  return (
    <PatientChargesSection
      patientId={patientId}
      appointmentId={appointmentId}
      subtitle="Add direct patient charges for the current visit. Charges will not be submitted to the patient's health plan."
    />
  )
}

export default VisitChargesPage
