import { useState } from 'react'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
} from '@heroicons/react/24/solid'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { PlaceOfService } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import BillingProviderSelectField from 'src/components/atoms/BillingProviderSelectField/BillingProviderSelectField'
import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import ChevronLink from 'src/components/atoms/ChevronLink/ChevronLink'
import PlaceOfServiceSelectField from 'src/components/atoms/PlaceOfServiceSelectField/PlaceOfServiceSelectField'
import PractitionerClinicalDetailSelectField from 'src/components/atoms/PractitionerClinicalDetailSelectField/PractitionerClinicalDetailSelectField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import LocationSelectCell from 'src/components/Location/LocationSelectCell'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import { DateRangeField } from 'src/components/molecules/DatePicker/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { placeOfServiceDisplay } from 'src/data/placeOfService'
import { AddressJoinOption, formatAddress } from 'src/lib/formatters'

import BillingSectionHeader from './BillingSectionHeader'

export interface BillingSummaryFormProps {
  claim?: {
    id: string
    renderingProviderId: string
    supervisingProviderId: string | undefined
    placeOfService: PlaceOfService
    serviceFacilityId: string
    billingProviderId: string
    admissionDate?: string
    dischargeDate?: string
    submittedAt?: string
  }
  currentFormInputValues?: {
    renderingProviderId: string
    supervisingProviderId?: string
    placeOfService: PlaceOfService
    serviceFacilityId?: string
    billingProviderId: string
    admissionDate?: string
    dischargeDate?: string
  }
}

const BillingSummarySection = ({
  claim,
  currentFormInputValues: input,
}: BillingSummaryFormProps) => {
  // Display values from the claim if it exists, otherwise from the form
  const renderingProviderId =
    claim?.renderingProviderId ?? input?.renderingProviderId
  const supervisingProviderId =
    claim?.supervisingProviderId ?? input?.supervisingProviderId
  const placeOfService = claim?.placeOfService ?? input?.placeOfService
  const serviceFacilityId = claim?.serviceFacilityId ?? input?.serviceFacilityId
  const billingProviderId = claim?.billingProviderId ?? input?.billingProviderId
  const admissionDate = claim?.admissionDate ?? input?.admissionDate
  const dischargeDate = claim?.dischargeDate ?? input?.dischargeDate

  const isEditable = !claim
  const [isEditing, setIsEditing] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <StackView space={100}>
      <BillingSectionHeader
        title="Summary"
        subtitle="Review and edit claim-level details. This section includes optional fields that can be completed where necessary."
      >
        {isEditable && (
          <EditToggleButton
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            setIsExpanded={setIsExpanded}
          />
        )}
        <ChevronLink
          className="h-5 w-5 flex-shrink-0 text-gray-800"
          aria-hidden="true"
          icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </BillingSectionHeader>
      {isEditing ? (
        <BillingSummaryForm />
      ) : isExpanded ? (
        <BillingSummaryDisplay
          renderingProviderId={renderingProviderId}
          supervisingProviderId={supervisingProviderId}
          placeOfService={placeOfService}
          serviceFacilityId={serviceFacilityId}
          billingProviderId={billingProviderId}
          admissionDate={admissionDate}
          dischargeDate={dischargeDate}
        />
      ) : null}
    </StackView>
  )
}

const EditToggleButton = ({
  isEditing,
  setIsEditing,
  setIsExpanded,
}: {
  isEditing: boolean
  setIsEditing: (isEditing: boolean) => void
  setIsExpanded: (isExpanded: boolean) => void
}) => (
  <Button
    testId="edit-toggle-button"
    icon={PencilIcon}
    buttonStyle="secondary"
    text={isEditing ? 'Stop editing' : 'Edit'}
    onClick={() => {
      setIsEditing(!isEditing)
      setIsExpanded(true)
    }}
  />
)

interface BillingSummaryDisplayProps {
  renderingProviderId: string
  supervisingProviderId: string
  placeOfService: PlaceOfService
  serviceFacilityId: string
  billingProviderId: string
  admissionDate?: string
  dischargeDate?: string
}

const BILLING_SUMMARY_QUERY = gql`
  query BillingSummaryQuery(
    $renderingProviderId: String!
    $serviceFacilityId: String!
    $billingProviderId: String!
  ) {
    practitionerClinicalDetail(id: $renderingProviderId) {
      id
      npi
      practitioner {
        id
        givenName
        familyName
      }
    }
    location(id: $serviceFacilityId) {
      id
      name
      address {
        id
        line1
        line2
        city
        state
        postalCode
      }
    }
    billingProvider(id: $billingProviderId) {
      id
      name
      npi
    }
  }
`

const SUPERVISING_PROVIDER_QUERY = gql`
  query SupervisingProviderDetails($supervisingProviderId: String!) {
    practitionerClinicalDetail(id: $supervisingProviderId) {
      id
      npi
      practitioner {
        id
        givenName
        familyName
      }
    }
  }
`

const BillingSummaryDisplay = ({
  renderingProviderId,
  supervisingProviderId,
  placeOfService,
  serviceFacilityId,
  billingProviderId,
  admissionDate,
  dischargeDate,
}: BillingSummaryDisplayProps) => {
  const { data, error, loading } = useQuery(BILLING_SUMMARY_QUERY, {
    variables: {
      renderingProviderId,
      serviceFacilityId,
      billingProviderId,
    },
    skip: !renderingProviderId || !serviceFacilityId || !billingProviderId,
  })
  const supervisingProviderQuery = useQuery(SUPERVISING_PROVIDER_QUERY, {
    variables: {
      supervisingProviderId,
    },
    skip: !supervisingProviderId,
  })

  if (!renderingProviderId || !serviceFacilityId || !billingProviderId) {
    return (
      <div>
        Billing not configured to submit claims, please contact Develo support
      </div>
    )
  }

  if (loading || (supervisingProviderId && supervisingProviderQuery.loading))
    return <div>Loading...</div>
  if (error) return <div>{error.message}</div>

  const supervisingProvider =
    supervisingProviderQuery.data?.practitionerClinicalDetail

  return (
    <Card className="p-4">
      <DataDisplayList
        data={[
          {
            label: 'Rendering provider',
            value: `${formatDisplayName(
              data.practitionerClinicalDetail.practitioner
            )}  ·  ${data.practitionerClinicalDetail.npi}`,
          },
          {
            label: 'Supervising provider',
            value: supervisingProvider
              ? `${formatDisplayName(supervisingProvider.practitioner)}  ·  ${
                  supervisingProvider.npi
                }`
              : '-',
          },
          {
            label: 'Billing provider',
            value: data.billingProvider.name,
          },
          {
            label: 'Service facility location',
            value: (
              <StackView>
                <Typography>{data.location.name}</Typography>
                <Typography>
                  {formatAddress(
                    data.location.address,
                    AddressJoinOption.COMMA
                  )}
                </Typography>
              </StackView>
            ),
          },
          {
            label: 'Place of service',
            value: placeOfServiceDisplay[placeOfService],
          },
          {
            label: 'Hospitalization dates',
            value:
              admissionDate && dischargeDate
                ? format(parseISO(admissionDate), 'MMM d, yyyy') +
                  ' to ' +
                  format(parseISO(dischargeDate), 'MMM d, yyyy')
                : '',
            hide: placeOfService !== 'INPATIENT_HOSPITAL',
          },
        ]}
      />
    </Card>
  )
}

const BillingSummaryForm = () => {
  const { watch } = useFormContext()
  const placeOfService = watch('input.placeOfService')

  return (
    <Card>
      <FormInputList
        className="p-4"
        items={[
          {
            label: 'Rendering provider',
            name: 'input.renderingProviderId',
            required: true,
            formInputComponent: PractitionerClinicalDetailSelectField,
            inputProps: {
              excludeSchedulingOnlyPractitioners: true,
            },
          },
          {
            label: 'Supervising provider',
            name: 'input.supervisingProviderId',
            required: false,
            formInputComponent: PractitionerClinicalDetailSelectField,
            inputProps: {
              excludeSchedulingOnlyPractitioners: true,
            },
          },
          {
            label: 'Billing provider',
            name: 'input.billingProviderId',
            required: true,
            formInputComponent: BillingProviderSelectField,
          },
          {
            label: 'Service facility location',
            name: 'input.serviceFacilityId',
            required: true,
            formInputComponent: LocationSelectCell,
          },
          {
            label: 'Place of service',
            name: 'input.placeOfService',
            required: true,
            formInputComponent: PlaceOfServiceSelectField,
          },
          {
            label: 'Hospitalization dates',
            required: placeOfService === 'INPATIENT_HOSPITAL',
            hide: placeOfService !== 'INPATIENT_HOSPITAL',
            name: 'input.hospitalizationDates',
            formInputComponent: DateRangeField,
            inputProps: {
              nameStart: 'input.admissionDate',
              nameEnd: 'input.dischargeDate',
            },
          },
        ]}
      />
    </Card>
  )
}

export default BillingSummarySection
