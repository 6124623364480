import LoadingModal from 'src/components/molecules/LoadingModal/LoadingModal'

const SubmitClaimLoadingModal = ({ loading }: { loading: boolean }) => {
  if (!loading) {
    return null
  } else {
    return (
      <LoadingModal
        isOpen={true}
        title="Claim submission in progress"
        description="We're currently submitting your claim, this may take a moment. Don't refresh or close your window until the claim has been submitted."
      />
    )
  }
}

export default SubmitClaimLoadingModal
