import type { GetContactOrganizationsDropdown } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'

export const QUERY = gql`
  query GetContactOrganizationsDropdown {
    contactOrganizations {
      id
      name
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<GetContactOrganizationsDropdown>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  contactOrganizations,
  ...selectFieldProps
}: CellSuccessProps<GetContactOrganizationsDropdown> & RWSelectFieldProps) => {
  return (
    <SelectField
      {...selectFieldProps}
      emptyAs={null}
      options={contactOrganizations.map((org) => ({
        value: org.id,
        display: org.name,
      }))}
    />
  )
}
