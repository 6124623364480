import {
  PatientDemographicsFragment,
  UpdateInsuranceCoverageInput,
} from 'types/graphql'

import HealthPlanTypeSelectField from 'src/components/atoms/HealthPlanTypeSelectField/HealthPlanTypeSelectField'
import InputField from 'src/components/atoms/InputField'
import PayerAddressSelectField from 'src/components/atoms/PayerAddressSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import { healthPlanTypeDisplay } from 'src/data/healthPlanTypes'
import { formatAddress } from 'src/lib/formatters'
import { UPDATE_INSURANCE_STATUS_MUTATION } from 'src/providers/context/AppointmentCheckInContext/schema'

const InsurancePlanCard: React.FC<{
  insurance: PatientDemographicsFragment['insuranceCoverages'][0]
  patient: PatientDemographicsFragment
}> = ({ insurance }) => {
  const preSubmit = (data: UpdateInsuranceCoverageInput) => {
    return { variables: { id: insurance.id, input: data } }
  }

  return (
    <DataVerificationCard<UpdateInsuranceCoverageInput>
      title="General plan information"
      updateMutation={UPDATE_INSURANCE_STATUS_MUTATION}
      preSubmit={preSubmit}
      defaultOpen
      data={[
        {
          name: 'planName',
          label: 'Plan name',
          value: insurance.planName,
          formInputComponent: InputField,
        },
        {
          name: 'planType',
          label: 'Health plan type',
          value: insurance.planType,
          displayValue: healthPlanTypeDisplay[insurance.planType],
          formInputComponent: HealthPlanTypeSelectField,
        },
        {
          name: 'payerAddressId',
          label: 'Mailing address',
          value: insurance.payerAddressId,
          displayValue: insurance.payerAddress
            ? `${insurance.payerAddress.purpose} | ${formatAddress(
                insurance.payerAddress
              )}`
            : 'Please enter a mailing address',
          formInputComponent: PayerAddressSelectField,
          inputProps: {
            id: insurance.payerId,
          },
        },
        {
          name: 'planWebsite',
          label: 'Plan website',
          value: insurance.planWebsite,
          formInputComponent: InputField,
        },
      ]}
    />
  )
}

export default InsurancePlanCard
