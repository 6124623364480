import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import SwapButton from 'src/components/molecules/SwapButton/SwapButton'

const BILLING_CODE_GROUP_QUERY = gql`
  query BillingCodeGroupQuery($id: String!) {
    billingCodeGroup(id: $id) {
      id
      billingCodes {
        id
        code
        description
      }
    }
  }
`

const SWAP_BILLING_CODE_MUTATION = gql`
  mutation SwapBillingCodeMutation(
    $chargeItemId: String!
    $billingCodeId: String!
  ) {
    updateChargeItem(
      chargeItemId: $chargeItemId
      input: { billingCodeId: $billingCodeId }
    ) {
      id
      billingCode {
        id
      }
    }
  }
`

interface BillingCodeSwapButtonProps {
  billingGroupId: string
  billingCodeId: string
  chargeItemId: string
}

const BillingCodeSwapButton: React.FC<BillingCodeSwapButtonProps> = ({
  billingGroupId,
  billingCodeId,
  chargeItemId,
}) => {
  const [swapBillingCodeMutation] = useMutation(SWAP_BILLING_CODE_MUTATION, {
    onCompleted: () => {
      toast.success('Billing code updated')
    },
  })
  const swap = (option) => {
    swapBillingCodeMutation({
      variables: {
        chargeItemId: chargeItemId,
        billingCodeId: option.id,
      },
    })
  }
  const { data, error } = useQuery(BILLING_CODE_GROUP_QUERY, {
    variables: {
      id: billingGroupId,
    },
  })

  if (!data || error) {
    return null
  }

  return (
    <SwapButton
      options={data.billingCodeGroup.billingCodes}
      selectedOptionId={billingCodeId}
      swap={swap}
    />
  )
}

export default BillingCodeSwapButton
