import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'

import { navigate, useLocation } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { questionnaireCodes } from 'src/components/Order/CompleteQuestionnaireOrderSidepanelForm/Questionnaire'
import { sidepanelRoute } from 'src/lib/routes'
import { ChiefComplaintSection } from 'src/pages/PatientChartsPage/PatientVisits/pages/IntakePage/ChiefComplaintSection'

import {
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from '../../useVisit'

import { CaregiversPresentSection } from './CaregiversSection'
import InHouseTestsTable from './InHouseTestsTable'
import { PatientVitalsDisplay } from './PatientVitalsDisplay'
import { PracticeStaffInvolvedSection } from './PracticeStaffInvolvedSection'
import ScreeningsTable from './ScreeningsTable'

const IntakePage = () => {
  const { patientId, appointmentId } = useVisit()
  const location = useLocation()
  const { visit } = useVisitQuery(appointmentId)

  useUpdateVisitFlowTabForAppointment('intake')

  if (!visit) return null

  return (
    <StackView space={100} className="max-w-3xl pt-4">
      <StackView space={50}>
        <Typography textStyle="heading" fontWeight="medium">
          Intake, tests and screenings
        </Typography>

        <Typography textStyle="body" color="text-base-color-fg-muted">
          Document results for vitals. For in-house test and screening results,
          document and link results to each corresponding order.
        </Typography>
      </StackView>

      <CaregiversPresentSection visit={visit} />

      <ChiefComplaintSection visit={visit} />

      <PracticeStaffInvolvedSection visit={visit} />

      <PatientVitalsDisplay />

      <StackView space={50}>
        <StackView direction="row" justifyContent="between" alignItems="center">
          <Typography textStyle="heading" fontWeight="medium">
            In-house tests
          </Typography>
          <Button
            className="w-min whitespace-nowrap hover:text-gray-700"
            buttonStyle="link"
            onClick={() => {
              navigate(`/patient-charts/${patientId}/visits/${visit.id}/orders`)
            }}
            text="View orders"
            icon={ArrowTopRightOnSquareIcon}
          />
        </StackView>

        <InHouseTestsTable
          patientId={patientId}
          encounterId={visit?.encounter?.id}
          onRowClicked={(e) => {
            return navigate(
              sidepanelRoute(
                {
                  route: `/patients/${patientId}/orders/${e.data.id}`,
                },
                location
              )
            )
          }}
        />
      </StackView>

      <StackView space={50}>
        <Typography textStyle="heading" fontWeight="medium">
          Screenings
        </Typography>
        <ScreeningsTable
          patientId={patientId}
          encounterId={visit?.encounter?.id}
          onRowClicked={(e) => {
            const overallResultExists = e.data?.observations?.length > 0
            const mode = overallResultExists ? 'edit' : 'view'
            return navigate(
              sidepanelRoute(
                {
                  route: questionnaireCodes.includes(e.data.code)
                    ? `/encounters/${visit?.encounter?.id}/questionnaires/${e.data.id}/${mode}`
                    : `/patients/${patientId}/orders/${e.data.id}`,
                },
                location
              )
            )
          }}
        />
      </StackView>
    </StackView>
  )
}

export const NotRecordedIllustration = () => (
  <svg
    width="161"
    height="120"
    viewBox="0 0 161 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="82.25" cy="52" r="52" fill="#EAECF0" />
    <circle cx="27.25" cy="11" r="5" fill="#F2F4F7" />
    <circle cx="24.25" cy="109" r="7" fill="#F2F4F7" />
    <circle cx="151.25" cy="35" r="7" fill="#F2F4F7" />
    <circle cx="140.25" cy="8" r="4" fill="#F2F4F7" />
    <g filter="url(#filter0_dd_8013_3709)">
      <path
        d="M47.9766 79.11L81.2151 63.6107C82.8839 62.8325 83.6059 60.8488 82.8277 59.1799L63.1984 17.0848L49.9063 12.2468L22.7111 24.9281C21.0423 25.7063 20.3203 27.69 21.0985 29.3588L43.5459 77.4974C44.324 79.1662 46.3077 79.8882 47.9766 79.11Z"
        fill="url(#paint0_linear_8013_3709)"
      />
      <path
        d="M49.9067 12.2471L63.1989 17.085L54.1338 21.3121L49.9067 12.2471Z"
        fill="#EAECF0"
      />
    </g>
    <g filter="url(#filter1_dd_8013_3709)">
      <path
        d="M63.8662 67.7831H100.541C102.382 67.7831 103.875 66.2904 103.875 64.4491V18.0022L93.8727 8H63.8662C62.0248 8 60.5321 9.49271 60.5321 11.3341V64.4491C60.5321 66.2904 62.0248 67.7831 63.8662 67.7831Z"
        fill="url(#paint1_linear_8013_3709)"
      />
      <path d="M93.8726 8L103.875 18.0022H93.8726V8Z" fill="#EAECF0" />
    </g>
    <g filter="url(#filter2_dd_8013_3709)">
      <path
        d="M82.7245 63.5909L115.963 79.0903C117.632 79.8685 119.616 79.1465 120.394 77.4777L140.023 35.3825L135.185 22.0903L107.99 9.40903C106.321 8.63085 104.337 9.35286 103.559 11.0217L81.1119 59.1602C80.3337 60.8291 81.0557 62.8128 82.7245 63.5909Z"
        fill="url(#paint2_linear_8013_3709)"
      />
      <path
        d="M135.186 22.0903L140.023 35.3825L130.958 31.1554L135.186 22.0903Z"
        fill="#EAECF0"
      />
    </g>
    <g filter="url(#filter3_b_8013_3709)">
      <rect
        x="60.25"
        y="74"
        width="44"
        height="44"
        rx="22"
        fill="#344054"
        fillOpacity="0.4"
      />
      <path
        d="M91.7806 104.469L87.0865 99.7762C88.4471 98.1428 89.1255 96.0478 88.9807 93.9269C88.8359 91.806 87.879 89.8226 86.3091 88.3893C84.7392 86.956 82.6771 86.1831 80.5519 86.2314C78.4266 86.2797 76.4018 87.1455 74.8986 88.6486C73.3955 90.1518 72.5297 92.1766 72.4814 94.3019C72.4331 96.4271 73.206 98.4892 74.6393 100.059C76.0726 101.629 78.056 102.586 80.1769 102.731C82.2978 102.875 84.3928 102.197 86.0262 100.837L90.7193 105.531C90.789 105.6 90.8718 105.656 90.9628 105.693C91.0538 105.731 91.1514 105.75 91.25 105.75C91.3485 105.75 91.4461 105.731 91.5371 105.693C91.6282 105.656 91.7109 105.6 91.7806 105.531C91.8503 105.461 91.9056 105.378 91.9433 105.287C91.981 105.196 92.0004 105.099 92.0004 105C92.0004 104.901 91.981 104.804 91.9433 104.713C91.9056 104.622 91.8503 104.539 91.7806 104.469ZM74 94.5C74 93.1649 74.3959 91.8599 75.1375 90.7499C75.8792 89.6398 76.9335 88.7747 78.1669 88.2638C79.4003 87.7529 80.7575 87.6192 82.0668 87.8797C83.3762 88.1401 84.5789 88.783 85.5229 89.727C86.4669 90.671 87.1098 91.8737 87.3703 93.1831C87.6307 94.4925 87.497 95.8497 86.9862 97.0831C86.4753 98.3165 85.6101 99.3707 84.5001 100.112C83.39 100.854 82.085 101.25 80.75 101.25C78.9604 101.248 77.2446 100.536 75.9792 99.2707C74.7138 98.0053 74.002 96.2896 74 94.5Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_8013_3709"
        x="-0.310547"
        y="8.01978"
        width="104.547"
        height="112.499"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_8013_3709"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8013_3709"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_8013_3709"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_8013_3709"
          result="effect2_dropShadow_8013_3709"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_8013_3709"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dd_8013_3709"
        x="40.5321"
        y="8"
        width="83.3428"
        height="99.7832"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_8013_3709"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8013_3709"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_8013_3709"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_8013_3709"
          result="effect2_dropShadow_8013_3709"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_8013_3709"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_dd_8013_3709"
        x="59.7029"
        y="8"
        width="104.547"
        height="112.499"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_8013_3709"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_8013_3709"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_8013_3709"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_8013_3709"
          result="effect2_dropShadow_8013_3709"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_8013_3709"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_8013_3709"
        x="52.25"
        y="66"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_8013_3709"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_8013_3709"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_8013_3709"
        x1="46.0238"
        y1="79.2398"
        x2="19.5353"
        y2="31.4525"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset="1" stopColor="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_8013_3709"
        x1="62.0415"
        y1="67.0755"
        x2="58.2306"
        y2="12.571"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset="1" stopColor="#F9FAFB" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_8013_3709"
        x1="81.3699"
        y1="62.1785"
        x2="100.951"
        y2="11.17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E4E7EC" />
        <stop offset="1" stopColor="#F9FAFB" />
      </linearGradient>
    </defs>
  </svg>
)
export default IntakePage
