import { XCircleIcon } from '@heroicons/react/24/solid'
import { InsuranceOptOutStatus } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import { Chip } from 'src/components/Chip/Chip'
import { sidepanelRoute } from 'src/lib/routes'

const INSURANCE_OPT_OUT_DESCRIPTIONS: {
  [key in InsuranceOptOutStatus]: string
} = {
  INSURED_SELF_PAY: 'Insured opt-out',
  UNINSURED_SELF_PAY: 'Uninsured',
  INSURANCE_PENDING: 'Pending',
  OTHER: 'Other',
}

const PatientNoInsuranceChip = ({
  insuranceOptOutStatus = 'UNINSURED_SELF_PAY',
  patientId,
}: {
  insuranceOptOutStatus: InsuranceOptOutStatus
  patientId: string
}) => {
  const params = useParams()
  const location = useLocation()
  if (!insuranceOptOutStatus) insuranceOptOutStatus = 'UNINSURED_SELF_PAY'
  return (
    <Chip
      text={INSURANCE_OPT_OUT_DESCRIPTIONS[insuranceOptOutStatus]}
      leftContent={{
        type: 'icon',
        icon: XCircleIcon,
      }}
      onClick={() => {
        navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/insurance-coverages/no-insurance`,
            },
            location,
            params
          )
        )
      }}
    />
  )
}
export default PatientNoInsuranceChip
