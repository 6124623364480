import { useState } from 'react'

import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { ListMembershipsForPatient } from 'types/graphql'

import StackView from 'src/components/atoms/StackView/StackView'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { StatelessTabs } from 'src/components/molecules/Tabs'
import { usePatientMemberships } from 'src/hooks/usePatientMemberships/usePatientMemberships'

import { CurrentPatientMembershipPlanTab } from './CurrentPatientMembershipPlanTab'
import { PastPatientMembershipPlansTab } from './PastPatientMembershipPlansTab'

const SidepanelPatientMembershipsHelper = ({
  data,
}: {
  data: ListMembershipsForPatient
}) => {
  const activePlan = data.patient?.activeMembershipPlan
  const pastPlans = data.patient?.pastMembershipPlans ?? []

  const [tab, setTab] = useState<'current' | 'past'>(
    activePlan ? 'current' : 'past'
  )

  const showTabs = activePlan && pastPlans.length

  return (
    <SidepanelPage
      header="Patient membership"
      description="View details about the membership for the patient."
    >
      <StackView className="px-core-space-100">
        {showTabs ? (
          <StatelessTabs
            hideContent
            growTabs
            selectedTabIndex={match(tab)
              .with('current', () => 0)
              .with('past', () => 1)
              .exhaustive()}
            tabs={[
              {
                key: 'current',
                name: 'Current',
              },
              {
                key: 'past',
                name: 'Past',
              },
            ]}
            onTabChange={(index) =>
              setTab(
                match(index)
                  .with(1, () => 'past' as const)
                  .otherwise(() => 'current' as const)
              )
            }
          />
        ) : null}

        {match(tab)
          .with('current', () => {
            return <CurrentPatientMembershipPlanTab />
          })
          .with('past', () => <PastPatientMembershipPlansTab />)
          .exhaustive()}
      </StackView>
    </SidepanelPage>
  )
}

const SidepanelPatientMemberships = () => {
  const { patientId } = useParams()
  const { data } = usePatientMemberships({ patientId })

  if (!data) return

  return <SidepanelPatientMembershipsHelper data={data} />
}

export default SidepanelPatientMemberships
