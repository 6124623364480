import { useState } from 'react'

import { DocumentIcon } from '@heroicons/react/24/outline'
import {
  ArrowUturnLeftIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import Checkbox from 'src/components/atoms/Checkbox/Checkbox'
import { Input } from 'src/components/atoms/InputField/InputField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'
import {
  useApplyVisitTemplateMutation,
  useClearVisitTemplatesMutation,
  useVisitQuery,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useVisitTemplatesQuery } from 'src/pages/VisitTemplatePage/useVisitTemplates'

import Modal from '../Modal'

const TemplateSelectButton = ({ disabled = false }: { disabled?: boolean }) => {
  const { appointmentId: visitId } = useParams()
  const { visit } = useVisitQuery(visitId)
  const { practiceTemplates, loading } = useVisitTemplatesQuery()
  const [applyVisitTemplate] = useApplyVisitTemplateMutation()
  const [clearVisitTemplates, { loading: clearingTemplates }] =
    useClearVisitTemplatesMutation()
  const [query, setQuery] = useState<string>('')
  const [clearing, setClearing] = useState<boolean>(false)
  return (
    <div className="relative">
      <PopoverButton
        panelWidth="w-64"
        panelXPosition="right"
        panelZIndex={50}
        buttonProps={{
          testId: 'template-select-button',
          buttonStyle: 'secondary',
          buttonSize: 'm',
          text:
            visit?.visitTemplates.length > 1
              ? 'Templates'
              : visit?.visitTemplates.length === 1
                ? visit?.visitTemplates[0].name
                : 'Template',
          icon: DocumentIcon,
          disabled: disabled,
          justifyContent: 'start',
          truncate: true,
          maxTextWidth: 'max-w-[10rem]',
        }}
        renderPanel={({ close }) => (
          <StackView space={50} divider>
            <Box className="px-2 pt-2">
              <Input
                name="search-box"
                onChange={(e) => setQuery(e.target.value)}
                iconLeft={MagnifyingGlassIcon}
                placeholder="Search templates..."
              />
            </Box>

            <Box className="max-h-64 overflow-auto p-2">
              {loading ? (
                <LoadingSpinner />
              ) : (
                <>
                  {practiceTemplates?.length > 0 && (
                    <StackView space={50}>
                      <Typography
                        fontWeight="semibold"
                        color="text-base-color-fg-muted"
                      >
                        Practice templates
                      </Typography>
                      {practiceTemplates
                        ?.filter((template) =>
                          template.name
                            .toLowerCase()
                            .includes(query.toLowerCase())
                        )
                        .map((template) => {
                          const isSelected =
                            visit?.visitTemplates.filter(
                              (vt) => vt.id === template.id
                            ).length > 0

                          return (
                            <Checkbox
                              id={`template-${template.id}`}
                              name={template.name}
                              key={template.id}
                              label={template.name}
                              checked={isSelected}
                              disabled={isSelected}
                              onChange={() => {
                                applyVisitTemplate({
                                  variables: {
                                    visitId,
                                    visitTemplateId: template.id,
                                  },
                                })
                                close()
                                setQuery('')
                              }}
                            />
                          )
                        })}
                    </StackView>
                  )}
                </>
              )}
            </Box>

            {visit?.visitTemplates.length > 0 && (
              <Button
                className="!rounded-none"
                buttonStyle="secondary"
                icon={ArrowUturnLeftIcon}
                text="Clear applied template(s)"
                loading={clearingTemplates}
                onClick={() => {
                  setClearing(true)
                  close()
                  setQuery('')
                }}
              />
            )}
          </StackView>
        )}
        unmount={false}
      />

      <Modal
        title="Clear template?"
        modalStyle="warning"
        isOpen={clearing}
        content="Are you sure you want to clear all the template(s) that have been applied? This will remove all template and free-text content entered in the patient education, documentation, and attestation sections."
        primaryButton={{
          text: 'Continue',
          onClick: () => {
            clearVisitTemplates({
              variables: {
                visitId,
              },
            })
          },
        }}
        setIsOpen={(isOpen) => !isOpen && setClearing(false)}
        onClose={() => setClearing(false)}
        hideIcon
      />
    </div>
  )
}

export default TemplateSelectButton
