import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { patientCaregiverInformationDocumentTypes } from 'src/data/patientCaregiverInformationDocumentTypes'

export const PatientCaregiverInformationDocumentTypeSelectField = (
  props: RWSelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={patientCaregiverInformationDocumentTypes.map(
        ({ name, value }) => ({
          value,
          display: name,
        })
      )}
    />
  )
}

export default PatientCaregiverInformationDocumentTypeSelectField
