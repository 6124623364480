import { useEffect, useState } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { GetPatientActiveInsuranceCoverages } from 'types/graphql'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge'
import Button from 'src/components/atoms/Button'
import { AnchorLink } from 'src/components/atoms/Link/Link'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { StatelessTabs } from 'src/components/molecules/Tabs'
import {
  EligibilityIcon,
  PlanTypeIcon,
} from 'src/components/PatientInsuranceChip/PatientInsuranceChip'
import {
  insuranceCoverageStatusBadgeColors,
  insuranceCoverageStatusDisplay,
} from 'src/data/insuranceCoverageStatuses'
import { useActiveInsuranceCoveragesQuery } from 'src/hooks/useInsuranceCoverages/useInsuranceCoverages'
import { formatAddress } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import {
  COORDINATION_OF_BENEFITS_DISPLAY,
  HEALTH_PLAN_STATUS_DISPLAY,
  HEALTH_PLAN_TYPE_DISPLAY,
  sortInsuranceCoverages,
} from 'src/utils/insuranceCoverages'

const InsuranceCoverageTabContent = ({
  insuranceCoverage,
}: {
  insuranceCoverage: GetPatientActiveInsuranceCoverages['patient']['activeInsuranceCoverages'][number]
}) => {
  if (!insuranceCoverage) return

  return (
    <StackView space={100}>
      <DataDisplayList
        title="Insurance status"
        subtitle={
          insuranceCoverage.statusVerifiedAt
            ? `Last verified on ${format(
                new Date(insuranceCoverage.statusVerifiedAt),
                'PP'
              )}`
            : undefined
        }
        leftColumnWidth="lg"
        data={[
          {
            label: 'Status',
            value: (
              <Badge
                text={insuranceCoverageStatusDisplay[insuranceCoverage.status]}
                color={
                  insuranceCoverageStatusBadgeColors[insuranceCoverage.status]
                }
              />
            ),
          },
          {
            label: 'Type',
            value:
              COORDINATION_OF_BENEFITS_DISPLAY[
                insuranceCoverage.coordinationOfBenefitsType
              ],
          },
        ]}
      />

      <DataDisplayList
        title="Insurance card"
        subtitle={
          insuranceCoverage.beneficiaryVerifiedAt
            ? `Last verified on ${format(
                new Date(insuranceCoverage.beneficiaryVerifiedAt),
                'PP'
              )}`
            : undefined
        }
        leftColumnWidth="lg"
        data={[
          {
            label: 'Insurance card copy',
            value: insuranceCoverage.binary?.url && (
              <StackView>
                <AnchorLink
                  to={insuranceCoverage.binary.url}
                  text="Image"
                  target="_blank"
                />
              </StackView>
            ),
          },
        ]}
      />

      <DataDisplayList
        title="Member information"
        subtitle={
          insuranceCoverage.beneficiaryVerifiedAt
            ? `Last verified on ${format(
                new Date(insuranceCoverage.beneficiaryVerifiedAt),
                'PP'
              )}`
            : undefined
        }
        leftColumnWidth="lg"
        data={[
          {
            label: 'Member name',
            value: formatDisplayName(insuranceCoverage.beneficiary),
          },
          {
            label: 'Member date of birth',
            value: jsDateToLocalDate(
              insuranceCoverage.beneficiary.birthDate
            ).format(dateTimeFormatter('MMM d, yyyy')),
          },
          {
            label: 'Member ID',
            value: insuranceCoverage.planMemberIdentifier,
          },
          {
            label: 'Insurance provider',
            value: insuranceCoverage.payer.displayName,
          },
        ]}
      />

      <DataDisplayList
        title="Policyholder information"
        subtitle={
          insuranceCoverage.subscriberVerifiedAt
            ? `Last verified on ${format(
                new Date(insuranceCoverage.subscriberVerifiedAt),
                'PP'
              )}`
            : undefined
        }
        leftColumnWidth="lg"
        data={[
          {
            label: 'Policyholder full name',
            value: insuranceCoverage.beneficiaryIsSubscriber
              ? formatDisplayName(insuranceCoverage.beneficiary)
              : formatDisplayName(insuranceCoverage.subscriber),
          },
          {
            label: 'Policyholder date of birth',
            value: insuranceCoverage.beneficiaryIsSubscriber
              ? jsDateToLocalDate(
                  insuranceCoverage.beneficiary.birthDate
                ).format(dateTimeFormatter('MMM d, yyyy'))
              : jsDateToLocalDate(
                  insuranceCoverage.subscriber.birthDate
                ).format(dateTimeFormatter('MMM d, yyyy')),
          },
          {
            label: 'Relationship to patient',
            value: insuranceCoverage.beneficiaryIsSubscriber
              ? 'Self'
              : relationshipTypeDisplay[
                  insuranceCoverage.subscriber
                    .patientRelatedPersonRelationships[0].relationshipType
                ],
          },
        ]}
      />

      <DataDisplayList
        title="General plan information"
        subtitle={
          insuranceCoverage.planInformationVerifiedAt
            ? `Last verified on ${format(
                new Date(insuranceCoverage.planInformationVerifiedAt),
                'MMM d, yyyy'
              )}`
            : undefined
        }
        leftColumnWidth="lg"
        data={[
          {
            label: 'Health plan type',
            value: HEALTH_PLAN_TYPE_DISPLAY[insuranceCoverage.planType],
          },
          {
            label: 'Claims mailing address',
            value: insuranceCoverage.payerAddress
              ? `${insuranceCoverage.payerAddress.purpose} | ${formatAddress(
                  insuranceCoverage.payerAddress
                )}`
              : undefined,
          },
          {
            label: 'Health plan website',
            value: (
              <AnchorLink
                to={insuranceCoverage.planWebsite}
                text={insuranceCoverage.planWebsite}
                target="_blank"
              />
            ),
          },
        ]}
      />
    </StackView>
  )
}

const SidepanelPatientInsuranceCoveragesView = () => {
  const params = useParams()
  const location = useLocation()
  const { insuranceCoverageId: defaultInsuranceCoverageId, patientId } = params
  const { patient, activeInsuranceCoverages } =
    useActiveInsuranceCoveragesQuery(patientId)
  const [selectedInsuranceCoverage, setSelectedInsuranceCoverage] =
    useState<
      GetPatientActiveInsuranceCoverages['patient']['activeInsuranceCoverages'][number]
    >(null)

  const sortedInsuranceCoverages = sortInsuranceCoverages(
    activeInsuranceCoverages
  )

  const onTabChange = (index: number) => {
    setSelectedInsuranceCoverage(sortedInsuranceCoverages[index])
  }

  useEffect(() => {
    if (defaultInsuranceCoverageId && activeInsuranceCoverages.length > 0) {
      const insuranceCoverage = activeInsuranceCoverages.find(
        (coverage) => coverage.id === defaultInsuranceCoverageId
      )
      setSelectedInsuranceCoverage(insuranceCoverage)
    }
  }, [defaultInsuranceCoverageId, activeInsuranceCoverages])

  useEffect(() => {
    if (patient && activeInsuranceCoverages.length === 0) {
      navigate(
        sidepanelRoute(
          {
            route: `/patients/${patient.id}/insurance-coverages/no-insurance`,
          },
          location,
          params
        )
      )
    }
  }, [patient, activeInsuranceCoverages, params, location])

  if (!selectedInsuranceCoverage) {
    return <LoadingSpinner />
  }

  return (
    <SidepanelPage
      testId="patient-insurance-coverages-view"
      header={
        selectedInsuranceCoverage.planName ??
        selectedInsuranceCoverage.payer.displayName
      }
      metaContent={
        <StackView space={100}>
          <StackView direction="row" alignItems="center" space={25}>
            <EligibilityIcon
              status={
                selectedInsuranceCoverage.mostRecentEligibility?.status ??
                'UNKNOWN'
              }
            />
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              {
                HEALTH_PLAN_STATUS_DISPLAY[
                  selectedInsuranceCoverage.mostRecentEligibility?.status ??
                    'UNKNOWN'
                ]
              }
            </Typography>

            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              ·
            </Typography>

            <PlanTypeIcon planType={selectedInsuranceCoverage.planType} />
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              {
                HEALTH_PLAN_TYPE_DISPLAY[
                  selectedInsuranceCoverage.planType ?? 'UNKNOWN'
                ]
              }
            </Typography>
          </StackView>
          <div className="w-fit">
            <Button
              text="View"
              buttonStyle="secondary"
              iconPlacement="left"
              icon={ArrowTopRightOnSquareIcon}
              onClick={() => {
                navigate(
                  routes.patientChartsGlob({
                    id: patientId,
                    glob: 'demographics/insurance',
                  })
                )
              }}
            />
          </div>
        </StackView>
      }
    >
      <StackView className="grow p-core-space-150" space={50}>
        {sortedInsuranceCoverages.length > 1 ? (
          <StatelessTabs
            selectedTabIndex={sortedInsuranceCoverages.findIndex(
              (coverage) => coverage.id === selectedInsuranceCoverage.id
            )}
            tabs={sortedInsuranceCoverages.map((coverage) => {
              return {
                name: COORDINATION_OF_BENEFITS_DISPLAY[
                  coverage.coordinationOfBenefitsType
                ],
                content: (
                  <InsuranceCoverageTabContent insuranceCoverage={coverage} />
                ),
              }
            })}
            onTabChange={(index) => onTabChange(index)}
            growTabs
          />
        ) : (
          <InsuranceCoverageTabContent
            insuranceCoverage={selectedInsuranceCoverage}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientInsuranceCoveragesView
