import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import { NavLink, routes, useLocation, navigate } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { isActiveRoute } from 'src/lib/routes'

import ApplicationLayout from '../ApplicationLayout/ApplicationLayout'

interface SecondaryLayoutProps {
  navigationConfig: GroupedNavConfig[]
  children?: React.ReactNode
}

export type GroupedNavConfig = {
  name: string
  icon: React.FunctionComponent<React.ComponentProps<'svg'>>
  items: GroupedNavConfigItem[]
}

type GroupedNavConfigItem = {
  name: string
  to: string
  onClick?: () => void
}

interface SidebarProps {
  className?: string
  navigationConfig: GroupedNavConfig[]
}

const Sidebar = ({ navigationConfig, className }: SidebarProps) => {
  return (
    <div
      data-testid="sidebar"
      className={clsx('w-56 overflow-y-auto border-r bg-white', className)}
    >
      <div className="flex w-full flex-col py-6">
        <div className="flex flex-shrink-0 justify-between px-4">
          <Button
            data-testid="nav-back-button"
            buttonStyle="ghost"
            className="rounded-full px-0.5 py-0.5 text-gray-500"
            onClick={() => navigate(routes.home())}
          >
            <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" />
            <Typography size="m">Settings</Typography>
          </Button>
        </div>
        <div className={clsx('mt-6 w-full flex-1 space-y-1 px-4')}>
          {navigationConfig.map((item) => (
            <NavItemGroup key={item.name} {...item} />
          ))}
        </div>
      </div>
    </div>
  )
}

const NavItemGroup = (props: GroupedNavConfig) => {
  return (
    <>
      <StackView
        direction="row"
        space={50}
        alignItems="center"
        className="py-2"
      >
        <props.icon
          aria-hidden="true"
          className={clsx(
            'h-6 w-6 flex-none text-gray-400 group-hover:text-white'
          )}
        />
        <Typography size="xs" color="text-base-color-fg-muted">
          {props.name}
        </Typography>
      </StackView>
      {props.items.map((item) => (
        <NavItem key={item.name} {...item} />
      ))}
    </>
  )
}

const NavItem = ({ ...item }: GroupedNavConfigItem) => {
  const { pathname } = useLocation()
  const isActive = isActiveRoute(pathname, item.to)

  const linkClasses = [
    'group',
    'flex',
    'items-center',
    'gap-1',
    'rounded-md',
    'p-3',
    'text-xs',
    'font-medium',
    isActive
      ? 'bg-gray-100 text-gray-900'
      : 'text-gray-600 hover:bg-gray-500 hover:text-white',
  ]

  return (
    <>
      <NavLink
        key={item.name}
        className={clsx(linkClasses)}
        activeClassName={clsx(linkClasses)}
        data-testid={item.name}
        to={item.to}
        onClick={item.onClick}
      >
        <span>{item.name}</span>
      </NavLink>
    </>
  )
}

const MobileMenu = (props: { navigationConfig: GroupedNavConfig[] }) => {
  return (
    <>
      {props.navigationConfig.map((item) => (
        <MobileNavItemGroup key={item.name} {...item} />
      ))}
    </>
  )
}

const MobileNavItemGroup = (props: GroupedNavConfig) => {
  return (
    <>
      <StackView
        direction="row"
        space={50}
        alignItems="center"
        className="py-2"
      >
        <props.icon
          aria-hidden="true"
          className={clsx(
            'h-6 w-6 flex-none text-gray-400 group-hover:text-white'
          )}
        />
        <Typography
          size="s"
          color="text-base-color-fg-muted"
          fontWeight="medium"
        >
          {props.name}
        </Typography>
      </StackView>
      {props.items.map((item) => (
        <MobileNavItem key={item.name} {...item} />
      ))}
    </>
  )
}

const MobileNavItem = (item: GroupedNavConfigItem) => {
  const { pathname } = useLocation()
  const isActive = isActiveRoute(pathname, item.to)

  const linkClasses = [
    'group',
    'flex',
    'items-center',
    'rounded-md',
    'px-3',
    'py-2',
    'text-sm',
    'font-medium',
    isActive
      ? 'bg-gray-100 text-gray-900'
      : 'text-gray-600 hover:bg-gray-500 hover:text-white',
  ]

  return (
    <NavLink
      key={item.name}
      className={clsx(linkClasses)}
      activeClassName={clsx(linkClasses)}
      to={item.to}
      onClick={item.onClick}
    >
      <span className="mt-2">{item.name}</span>
    </NavLink>
  )
}

export default function SecondaryLayout({
  navigationConfig,
  children,
}: SecondaryLayoutProps) {
  return (
    <ApplicationLayout
      sidebar={
        <Sidebar
          navigationConfig={navigationConfig}
          className="hidden md:block"
        />
      }
      mobileMenu={<MobileMenu navigationConfig={navigationConfig} />}
    >
      {children}
    </ApplicationLayout>
  )
}
