import { UpsertPatientScoreInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

const UPSERT_PATIENT_SCORE_MUTATION = gql`
  mutation UpsertPatientScore($input: UpsertPatientScoreInput!) {
    upsertPatientScore(input: $input) {
      id
    }
  }
`
export const useUpsertPatientScore = () => {
  return useMutation<UpsertPatientScoreInput>(UPSERT_PATIENT_SCORE_MUTATION, {
    onCompleted: () => {
      toast.success('Patient score saved')
    },
  })
}
