import { useState } from 'react'

import { NoSymbolIcon } from '@heroicons/react/20/solid'
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import { format } from 'date-fns'
import { compact } from 'lodash'
import { InsuranceEligibilityFragment } from 'types/graphql'

import Button from 'src/components/atoms/Button/Button'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import DataDisplayCard from 'src/components/molecules/DataDisplayCard'
import { coverageLevelDisplay } from 'src/data/insuranceCoverageLevel'
import { inNetworkIndicatorDisplay } from 'src/data/insuranceInNetworkIndicators'
import { insuranceCoveragePlanStatusDisplay } from 'src/data/insuranceStatuses'
import { insuranceTimeQualifiersDisplay } from 'src/data/insuranceTimeQualifiers'
import { useCheckEligibility } from 'src/hooks/useCheckEligibility/useCheckEligibility'

import CreateInsuranceEligibility from './CreateInsuranceEligibility'

interface InsuranceEligibilityProps {
  insuranceId?: string
  patientRegistrationIntentId?: string
  insuranceEligibility: InsuranceEligibilityFragment
  /** Whether to disable editing (either by automated eligibility check or manual entry) */
  disableEdit?: boolean
  /** The appointment ID, if this is being used in the context of an appointment */
  appointmentId?: string
}

const NoInsuranceEligibility = () => (
  <StackView justifyContent="center" alignItems="center">
    <NoSymbolIcon className="h-8 w-8 text-gray-400" />
    <Space space={50} />
    <Typography textStyle="subtitle">
      No insurance eligibility information found
    </Typography>
  </StackView>
)

const InsuranceEligibility: React.FC<InsuranceEligibilityProps> = ({
  insuranceId,
  patientRegistrationIntentId,
  insuranceEligibility,
  disableEdit = false,
  appointmentId,
}) => {
  const [isEditing, setIsEditing] = useState(false)

  const [checkEligibility, { loading }] = useCheckEligibility(
    appointmentId
      ? { appointmentId }
      : insuranceId
        ? { insuranceCoverageId: insuranceId }
        : { patientRegistrationIntentId }
  )

  return (
    <StackView space={100}>
      <StackView space={50}>
        <StackView
          direction="row"
          alignItems="center"
          justifyContent="between"
          className="pt-core-space-100"
          space={50}
        >
          <Typography textStyle="title">
            Eligibility and Benefits Determination
          </Typography>
          {!disableEdit && (
            <Button
              className="h-10 min-w-fit"
              onClick={() => checkEligibility()}
              loading={loading}
            >
              Run eligibility check
            </Button>
          )}
        </StackView>
        <Typography textStyle="body" color="text-base-color-fg-muted">
          {disableEdit
            ? 'This section contains details about the state of the insurance coverage.'
            : 'Run an electronic eligibility and benefits determination check to obtain information on the patient’s eligibility for coverage and benefit details under the above health plan.'}
        </Typography>
      </StackView>
      {isEditing ? (
        <CreateInsuranceEligibility
          insuranceId={insuranceId}
          patientRegistrationIntentId={patientRegistrationIntentId}
          appointmentId={appointmentId}
          onCancel={() => setIsEditing(false)}
          onComplete={() => setIsEditing(false)}
        />
      ) : (
        <DisplayInsuranceEligibility
          insuranceEligibility={insuranceEligibility}
        />
      )}
      {!disableEdit && !isEditing && (
        <Button buttonStyle="secondary" onClick={() => setIsEditing(true)}>
          Enter new eligibility manually
        </Button>
      )}
    </StackView>
  )
}

export default InsuranceEligibility

const DisplayInsuranceEligibility = ({ insuranceEligibility }) =>
  !insuranceEligibility ? (
    <NoInsuranceEligibility />
  ) : (
    <>
      <DataDisplayCard
        title="Eligibility check result"
        description={`Last verified on ${format(
          new Date(insuranceEligibility.checkedAt),
          'PP'
        )}`}
        data={[
          {
            label: 'Overall status',
            value: (
              <StackView direction="row" space={50} alignItems="center">
                <Typography>
                  {
                    insuranceCoveragePlanStatusDisplay[
                      insuranceEligibility.status
                    ]
                  }
                </Typography>
                {
                  {
                    UNKNOWN: (
                      <QuestionMarkCircleIcon className="h-6 w-6 flex-shrink-0 text-warning" />
                    ),
                    INACTIVE: (
                      <XCircleIcon className="h-6 w-6 flex-shrink-0 text-danger" />
                    ),
                    ACTIVE_COVERAGE: (
                      <CheckCircleIcon className="h-6 w-6 flex-shrink-0 text-success" />
                    ),
                  }[insuranceEligibility.status]
                }
              </StackView>
            ),
          },
        ]}
      />
      {insuranceEligibility.insuranceCoverageEligibilityCategories.map(
        (insuranceEligibilityCategory) => (
          <InsuranceEligibilityCategory
            key={insuranceEligibilityCategory.id}
            insuranceEligibilityCategory={insuranceEligibilityCategory}
          />
        )
      )}
    </>
  )

interface InsuranceEligibilityCategoryProps {
  insuranceEligibilityCategory: InsuranceEligibilityFragment['insuranceCoverageEligibilityCategories'][0]
}

const InsuranceEligibilityCategory: React.FC<
  InsuranceEligibilityCategoryProps
> = ({ insuranceEligibilityCategory }) => {
  const titleElements = compact([
    coverageLevelDisplay[insuranceEligibilityCategory.coverageLevel],
    inNetworkIndicatorDisplay[insuranceEligibilityCategory.inNetworkIndicator],
  ])
  const title = titleElements.length > 0 ? titleElements.join(', ') : undefined

  return (
    <DataDisplayCard
      title={title}
      data={[
        {
          label: 'Status',
          value: (
            <StackView direction="row" space={50} alignItems="center">
              <Typography>
                {
                  insuranceCoveragePlanStatusDisplay[
                    insuranceEligibilityCategory.planStatus
                  ]
                }
              </Typography>
              {
                {
                  UNKNOWN: (
                    <QuestionMarkCircleIcon className="h-6 w-6 flex-shrink-0 text-warning" />
                  ),
                  INACTIVE: (
                    <XCircleIcon className="h-6 w-6 flex-shrink-0 text-danger" />
                  ),
                  ACTIVE_COVERAGE: (
                    <CheckCircleIcon className="h-6 w-6 flex-shrink-0 text-success" />
                  ),
                }[insuranceEligibilityCategory.planStatus]
              }
            </StackView>
          ),
        },
        {
          label: 'Co-pay: $ amount per visit',
          value: insuranceEligibilityCategory.copay,
          hide: insuranceEligibilityCategory.copay === null,
        },
        {
          label: 'Co-insurance: % share of billed amount',
          value: insuranceEligibilityCategory.coinsurance,
          hide: insuranceEligibilityCategory.coinsurance === null,
        },
        {
          label: `Deductible: total $ per ${insuranceTimeQualifiersDisplay[
            insuranceEligibilityCategory.deductibleTimeQualifierCode
          ]?.toLowerCase()}`,
          value: insuranceEligibilityCategory.deductibleTotal,
          hide: insuranceEligibilityCategory.deductibleTotal === null,
        },
        {
          label: 'Deductible: $ amount remaining',
          value: insuranceEligibilityCategory.deductibleRemaining,
          hide: insuranceEligibilityCategory.deductibleRemaining === null,
        },
        {
          label: `Out of pocket max: $ max per ${insuranceTimeQualifiersDisplay[
            insuranceEligibilityCategory.deductibleTimeQualifierCode
          ]?.toLowerCase()}`,
          value: insuranceEligibilityCategory.outOfPocketMax,
          hide: insuranceEligibilityCategory.outOfPocketMax === null,
        },
      ]}
    />
  )
}
