import {
  PatientDocumentType,
  DocumentCaseResolutionType,
  DocumentType,
} from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'

import Divider from 'src/components/atoms/Divider'
import DocumentTypeSelectField from 'src/components/atoms/DocumentTypeSelectField'
import StackView from 'src/components/atoms/StackView'
import CreateExternalRequest from 'src/components/CreateExternalRequest'
import CreatePatientEducationDocument from 'src/components/CreatePatientEducationDocument'
import CreatePatientForm from 'src/components/CreatePatientForm'
import CreatePracticeAdminDocument from 'src/components/CreatePracticeAdminDocument'
import CreatePracticeForm from 'src/components/CreatePracticeForm/CreatePracticeForm'
import CreatePracticeLetter from 'src/components/CreatePracticeLetter'
import CreateScreeningTool from 'src/components/CreateScreeningTool'
import FormInputList from 'src/components/molecules/FormInputList'
import SidepanelButtonFooter from 'src/components/molecules/SidepanelButtonFooter'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { patientDocumentTypeDisplay } from 'src/data/patientDocumentTypes'
import { CreatePatientDocument } from 'src/pages/Sidepanel/SidepanelPatientDocumentUpload'

const documentCreateConfig: {
  [key in DocumentCaseResolutionType]: {
    formComponent: React.FC<{
      type: PatientDocumentType | DocumentType
    }>
  }
} = {
  PRACTICE_ADMIN: {
    formComponent: CreatePracticeAdminDocument,
  },
  PATIENT_FORM: {
    formComponent: CreatePatientForm,
  },
  EXTERNAL_REQUEST: {
    formComponent: CreateExternalRequest,
  },
  SCREENING_TOOL: {
    formComponent: CreateScreeningTool,
  },
  PRACTICE_LETTER: {
    formComponent: CreatePracticeLetter,
  },
  PRACTICE_FORM: {
    formComponent: CreatePracticeForm,
  },
  PATIENT_EDUCATION: {
    formComponent: CreatePatientEducationDocument,
  },
  CLINICAL_RESULT: {
    formComponent: CreatePatientDocument,
  },
  PATIENT_DOCUMENT: {
    formComponent: CreatePatientDocument,
  },
}

export const CreateFormForDocumentType: React.FC<{
  documentType?: DocumentType | PatientDocumentType
}> = ({ documentType }) => {
  if (!documentType) {
    return (
      <SidepanelForm
        footerElement={
          <SidepanelButtonFooter
            disabled
            submitText="Upload document"
            submitting={false}
          />
        }
      />
    )
  }

  const isPatientDocument = !!patientDocumentTypeDisplay[documentType]
  const resolutionType =
    documentType === 'CLINICAL_RESULT'
      ? 'CLINICAL_RESULT'
      : isPatientDocument
        ? 'PATIENT_DOCUMENT'
        : (documentType as DocumentCaseResolutionType)
  const { formComponent: FormComponent } = documentCreateConfig[resolutionType]

  return <FormComponent type={documentType} />
}

const CreateDocument = () => {
  const formMethods = useForm<{ documentType: DocumentType }>()
  const documentType = formMethods.watch('documentType')

  return (
    <StackView className="flex h-full">
      <Form className="flex-0 px-6" formMethods={formMethods}>
        <FormInputList
          title="Document Details"
          items={[
            {
              name: 'documentType',
              label: 'Document type',
              formInputComponent: DocumentTypeSelectField,
              inputProps: {
                patient: false,
                clinic: true,
              },
              required: true,
            },
          ]}
        />
        <Divider />
      </Form>
      <CreateFormForDocumentType documentType={documentType} />
    </StackView>
  )
}

export default CreateDocument
