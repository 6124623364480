import { useState } from 'react'

import {
  AdjustmentsHorizontalIcon,
  PencilIcon,
  PlusIcon,
  PrinterIcon,
} from '@heroicons/react/24/solid'
import { GetPatient } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Button from 'src/components/atoms/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Toggle from 'src/components/molecules/Toggle/Toggle'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'
import { useSetUserSettingsMutation } from 'src/hooks/useUserSettings/useUserSettings'
import { sidepanelRoute } from 'src/lib/routes'
import { useEffectOnce } from 'src/utils'

import ImmunizationRecordTable from './ImmunizationRecordTable/ImmunizationRecordTable'

const GET_PATIENT = gql`
  query GetPatientForImmunizations($id: String!) {
    patient(id: $id) {
      id
      givenName
      familyName
      birthDate
      immunizations {
        id
        code {
          code
          system
          display
        }
        occurredAt
      }
    }
  }
`

const Legend = () => {
  return (
    <StackView direction="row" space={75} alignItems="center" fullWidth={false}>
      <StackView direction="row" space={25}>
        <div className="h-5 w-5 rounded-sm border bg-yellow-100"></div>
        <Typography
          size="s"
          color="text-base-color-fg-muted"
          className="whitespace-nowrap"
        >
          Schedule
        </Typography>
      </StackView>

      <StackView direction="row" space={25}>
        <div className="h-5 w-5 rounded-sm border bg-blue-100"></div>
        <Typography
          size="s"
          color="text-base-color-fg-muted"
          className="whitespace-nowrap"
        >
          Catch-up
        </Typography>
      </StackView>

      <StackView direction="row" space={25}>
        <div className="h-5 w-5 rounded-sm border bg-green-200"></div>
        <Typography
          size="s"
          color="text-base-color-fg-muted"
          className="whitespace-nowrap"
        >
          Given
        </Typography>
      </StackView>
    </StackView>
  )
}

const PatientImmunizations = () => {
  const { getCurrentUser } = useEmrAuth()
  const { id: patientId } = useParams()
  const location = useLocation()
  const { data, loading } = useQuery<GetPatient>(GET_PATIENT, {
    variables: {
      id: patientId,
    },
  })

  const [setImmunizationUserSetting] = useSetUserSettingsMutation()

  const [showNonRoutineVaccines, setShowNonRoutineVaccines] = useState(false)
  const [showImmunizationAnnotations, setShowImmunizationAnnotations] =
    useState(true)

  const [showOnePlusDoseVaccines, setShowOnePlusDoseVaccines] = useState(true)
  const [showNoDoseLinkedVaccines, setShowNoDoseLinkedVaccines] = useState(true)
  const [showNoDoseVaccines, setShowNoDoseVaccines] = useState(true)

  // On page load, get the most up to date current user settings.
  useEffectOnce(() => {
    void getCurrentUser().then((user) => {
      setShowImmunizationAnnotations(
        user.userSettings.showImmunizationAnnotations
      )
      setShowNonRoutineVaccines(user.userSettings.showNonRoutineVaccines)
      setShowOnePlusDoseVaccines(user.userSettings.showOnePlusDoseVaccines)
      setShowNoDoseLinkedVaccines(user.userSettings.showNoDoseLinkedVaccines)
      setShowNoDoseVaccines(user.userSettings.showNoDoseVaccines)
    })
  })

  if (loading) {
    return (
      <StackView justifyContent="center">
        <LoadingSpinner />
      </StackView>
    )
  }

  return (
    <StackView space={75} className="p-3">
      <StackView direction="row" justifyContent="between" alignItems="center">
        <StackView
          space={100}
          direction="row"
          alignItems="center"
          fullWidth={false}
          className="py-2"
          wrap
        >
          <Typography textStyle="title">
            Immunization record and schedule
          </Typography>
          <Legend />
        </StackView>

        <StackView
          direction="row"
          space={50}
          justifyContent="end"
          fullWidth={false}
        >
          <Button
            testId="print-immunizations-button"
            className="justify-self-end"
            icon={PrinterIcon}
            buttonStyle="secondary"
            text="Print"
            onClick={() => {
              navigate(
                sidepanelRoute(
                  {
                    route: `/print-immunizations/${patientId}`,
                    overlay: true,
                    width: 'medium',
                  },
                  location
                )
              )
            }}
          />
          <div className="relative">
            <PopoverButton
              panelXPosition="right"
              panelWidth="w-core-size-2000"
              panelZIndex={100}
              buttonProps={{
                buttonStyle: 'secondary',
                icon: AdjustmentsHorizontalIcon,
                text: 'Display',
                testId: 'display-popover-button',
              }}
              renderPanel={() => (
                <StackView divider>
                  <StackView className="p-4" space={50}>
                    <Typography
                      textStyle="interface-strong-xs"
                      color="text-base-color-fg-muted"
                    >
                      Vaccine properties
                    </Typography>

                    <Toggle
                      label="Show 1+ dose vaccines"
                      description="Display or hide vaccines that have one or more doses."
                      checked={showOnePlusDoseVaccines}
                      testId="show-one-plus-dose-vaccines"
                      onChange={async () => {
                        void setImmunizationUserSetting({
                          variables: {
                            input: {
                              showOnePlusDoseVaccines: !showOnePlusDoseVaccines,
                            },
                          },
                        })
                        setShowOnePlusDoseVaccines(!showOnePlusDoseVaccines)
                      }}
                    />

                    <Toggle
                      label="Show no dose linked vaccines"
                      description="Display or hide linked vaccines that have no documented dose."
                      checked={showNoDoseLinkedVaccines}
                      testId="show-no-dose-linked-vaccines"
                      onChange={async () => {
                        void setImmunizationUserSetting({
                          variables: {
                            input: {
                              showNoDoseLinkedVaccines:
                                !showNoDoseLinkedVaccines,
                            },
                          },
                        })
                        setShowNoDoseLinkedVaccines(!showNoDoseLinkedVaccines)
                      }}
                    />

                    <Toggle
                      label="Show no dose vaccines"
                      description="Display or hide any vaccine that has no documented dose."
                      checked={showNoDoseVaccines}
                      testId="show-no-dose-vaccines"
                      onChange={async () => {
                        void setImmunizationUserSetting({
                          variables: {
                            input: {
                              showNoDoseVaccines: !showNoDoseVaccines,
                            },
                          },
                        })
                        setShowNoDoseVaccines(!showNoDoseVaccines)
                      }}
                    />
                  </StackView>

                  <StackView className="p-4" space={50}>
                    <Typography
                      textStyle="interface-strong-xs"
                      color="text-base-color-fg-muted"
                    >
                      View options
                    </Typography>

                    <Toggle
                      label="Show CDC labeling"
                      description="Display or hide CDC labeling including cell fill color and annotations."
                      checked={showImmunizationAnnotations}
                      testId="show-immunization-annotations"
                      onChange={async () => {
                        void setImmunizationUserSetting({
                          variables: {
                            input: {
                              showImmunizationAnnotations:
                                !showImmunizationAnnotations,
                            },
                          },
                        })
                        setShowImmunizationAnnotations(
                          !showImmunizationAnnotations
                        )
                      }}
                    />

                    <Toggle
                      label="Show non-routine vaccines"
                      description="Display or hide non-routine vaccines with or without documented doses."
                      checked={showNonRoutineVaccines}
                      testId="show-non-routine-vaccines"
                      onChange={async () => {
                        void setImmunizationUserSetting({
                          variables: {
                            input: {
                              showNonRoutineVaccines: !showNonRoutineVaccines,
                            },
                          },
                        })
                        setShowNonRoutineVaccines(!showNonRoutineVaccines)
                      }}
                    />
                  </StackView>
                </StackView>
              )}
            />
          </div>

          <Button
            testId="edit-immunization-history-button"
            className="justify-self-end"
            buttonStyle="secondary"
            icon={PencilIcon}
            text="Edit"
            onClick={() => {
              navigate(
                sidepanelRoute(
                  {
                    route: `/edit-immunization-history/${patientId}`,
                  },
                  location
                )
              )
            }}
          />
          <Button
            testId="add-immunization-history-button"
            className="justify-self-end"
            icon={PlusIcon}
            text="Add"
            onClick={() => {
              navigate(
                sidepanelRoute(
                  {
                    route: `/add-immunization-history/${patientId}`,
                  },
                  location
                )
              )
            }}
          />
        </StackView>
      </StackView>
      <ImmunizationRecordTable
        patient={data.patient}
        showNonRoutineVaccines={showNonRoutineVaccines}
        showImmunizationAnnotations={showImmunizationAnnotations}
        showOnePlusDoseVaccines={showOnePlusDoseVaccines}
        showNoDoseLinkedVaccines={showNoDoseLinkedVaccines}
        showNoDoseVaccines={showNoDoseVaccines}
      />
    </StackView>
  )
}

export default PatientImmunizations
