import { useEffect, useState } from 'react'

import { FindPatientVisit, FindUsersInvolvedWithVisit } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { UserDropdownV2Field } from 'src/components/User/UserDropdownV2Field/UserDropdownV2Field'

import { useUpdateVisitUsersInvolvedMutation } from '../../useVisit'

const QUERY = gql`
  query FindUsersInvolvedWithVisit($id: String!) {
    appointment(id: $id) {
      id
      usersInvolved {
        id
        givenName
        familyName
      }
    }
  }
`

export const PracticeStaffInvolvedSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const [defaultsInit, setDefaultsInit] = useState<boolean>(false)
  const { data } = useQuery(QUERY, { variables: { id: visit.id } })

  const formMethods = useForm({
    defaultValues: {
      usersInvolvedIds: data?.appointment?.usersInvolved.map(
        (
          u: FindUsersInvolvedWithVisit['appointment']['usersInvolved'][number]
        ) => u.id
      ),
    },
  })

  useEffect(() => {
    if (data?.appointment && !defaultsInit) {
      setDefaultsInit(true)
      formMethods.reset({
        usersInvolvedIds: data?.appointment?.usersInvolved.map(
          (
            u: FindUsersInvolvedWithVisit['appointment']['usersInvolved'][number]
          ) => u.id
        ),
      })
    }
  }, [data?.appointment, defaultsInit, formMethods])

  const [updateVisitUsersInvolved] = useUpdateVisitUsersInvolvedMutation()

  const saveUpdates = () => {
    const userIds = formMethods.getValues('usersInvolvedIds')?.filter(Boolean)
    void updateVisitUsersInvolved({
      variables: {
        appointmentId: visit.id,
        userIds,
      },
      onCompleted: () => {
        toast.success('Practice staff involved updated')
      },
    })
  }

  return (
    <Form formMethods={formMethods}>
      <StackView gap={50}>
        <Typography textStyle="title" fontWeight="semibold">
          Practice staff involved
        </Typography>
        <UserDropdownV2Field
          excludedStatuses={['INACTIVE', 'ARCHIVED']}
          name="usersInvolvedIds"
          onSetValue={saveUpdates}
          testId="practice-staff-involved"
          userTypes={['OA', 'RN', 'RNT', 'MA', 'MAT']}
        />
      </StackView>
    </Form>
  )
}
