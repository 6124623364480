import { useState, useRef, useEffect, useCallback } from 'react'

import { Combobox } from '@headlessui/react'
import { UserCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import { formatDisplayName } from 'common/utils'
import isUndefined from 'lodash/isUndefined'

import { useEmrAuth } from 'src/auth'
import Checkbox from 'src/components/atoms/Checkbox/Checkbox'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import {
  useScheduleFilter,
  useUpdateFilter,
} from 'src/hooks/useScheduleFilter/useScheduleFilter'

const baseFilterClasses = [
  'relative',
  'inline-flex',
  'items-center',
  'text-sm',
  'text-gray-700',
  'font-medium',
  'bg-white',
  'hover:bg-gray-300',
  'border',
  'border-gray-300',
  'focus:z-10',
  'focus:ring-0',
  'focus:outline-none',
  'cursor-pointer',
]

const PractitionerFilter = ({ practitioners, defaultDisplayText }) => {
  const { getCurrentUser } = useEmrAuth()
  const filterRef = useRef(null)
  const [updatedUser, setUpdatedUser] = useState(null)
  const [open, setOpen] = useState(false)
  const { practitionerIds: selectedPractitionerIds } = useScheduleFilter()
  const updateFilter = useUpdateFilter()

  useEffect(() => {
    getCurrentUser().then((user) => {
      setUpdatedUser(user)
    })
  }, [getCurrentUser])

  useEffect(() => {
    if (updatedUser && isUndefined(selectedPractitionerIds)) {
      updateFilter(
        'schedulePractitionerFilter',
        updatedUser.userSettings.schedulePractitionerFilter,
        {
          saveSettings: true,
        }
      )
    }
  }, [updatedUser, selectedPractitionerIds, updateFilter])

  const handleClickOutsideFilter = useCallback((event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideFilter)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFilter)
    }
  }, [handleClickOutsideFilter])

  const clearFilter = () => {
    updateFilter('schedulePractitionerFilter', [], { saveSettings: true })
  }

  const toggleFilter = ({ id: practitionerId }) => {
    updateFilter(
      'schedulePractitionerFilter',
      selectedPractitionerIds.includes(practitionerId)
        ? selectedPractitionerIds.filter((id) => id != practitionerId)
        : selectedPractitionerIds.concat([practitionerId]),
      {
        saveSettings: true,
      }
    )
  }

  const renderFilterText = () => {
    const selectedPractitioners = practitioners.filter((practitioner) =>
      selectedPractitionerIds.includes(practitioner.id)
    )

    const defaultText = `${defaultDisplayText[0].toLowerCase()}${defaultDisplayText.slice(
      1
    )}`

    if (!selectedPractitioners?.length) {
      return defaultText
    }
    if (selectedPractitioners.length === 1) {
      return formatDisplayName(selectedPractitioners[0])
    } else {
      return `${selectedPractitioners.length} ${defaultText}`
    }
  }

  if (!selectedPractitionerIds) return null

  return (
    <Combobox as="div" data-testid="practitioner-filter">
      <div className={clsx('relative')} ref={filterRef}>
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            onClick={() => {
              setOpen(true)
            }}
            type="button"
            className={clsx(
              ...baseFilterClasses,
              'px-3',
              'py-2',
              'h-base-size-selectable-m',
              'rounded-l-md'
            )}
          >
            <UserCircleIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <Typography noWrap>{renderFilterText()}</Typography>
          </button>
          <button
            type="button"
            onClick={clearFilter}
            data-testid="clear-filters"
            className={clsx(
              ...baseFilterClasses,
              'px-2',
              'py-2',
              '-ml-px',
              'h-base-size-selectable-m',
              'rounded-r-md'
            )}
          >
            <XMarkIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </button>
        </span>
        {open && (
          <Combobox.Options
            static
            className={clsx(
              'absolute',
              'bg-white',
              'right-0',
              'z-10',
              'py-1',
              'mt-2',
              'w-56',
              'overflow-auto',
              'rounded-md',
              'shadow-lg'
            )}
          >
            {practitioners.map((practitioner) => {
              const { id: practitionerId, givenName, familyName } = practitioner
              return (
                <Combobox.Option
                  key={practitionerId}
                  value={practitionerId}
                  onClick={() => toggleFilter(practitioner)}
                  className={({ active }) =>
                    clsx(
                      'relative',
                      'py-2',
                      'px-3',
                      'cursor-default',
                      active ? 'bg-gray-300 text-gray-700' : 'text-gray-700'
                    )
                  }
                  data-testid="combobox-practitionerOptions"
                >
                  <StackView direction="row" alignItems="center">
                    <Checkbox
                      id={`cb-${practitionerId}`}
                      name={`cb-${practitionerId}`}
                      checked={selectedPractitionerIds.includes(
                        practitioner.id
                      )}
                      value={practitionerId}
                    />
                    <Typography className="-ml-1">{`${givenName} ${familyName}`}</Typography>
                  </StackView>
                </Combobox.Option>
              )
            })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

export default PractitionerFilter
