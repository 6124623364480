import { AppointmentPatientCheckInQuery } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import AddressFormInputList from 'src/components/Address/AddressFormInputList/AddressFormInputList'
import InheritedAddressField from 'src/components/Address/InheritedAddressField/InheritedAddressField'
import InputField from 'src/components/atoms/InputField'
import LanguageSelectField from 'src/components/atoms/LanguageSelectField/LanguageSelectField'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import { languageDisplay } from 'src/data/languages'
import { deepOmit } from 'src/data/utils'
import { formatAddress, formatPhoneNumber } from 'src/lib/formatters'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext'

const VERIFY_PATIENT_CAREGIVER_CONTACT_INFORMATION = gql`
  mutation VerifyPatientCaregiverContactInformationMutation(
    $patientId: String!
    $relatedPersonId: String!
  ) {
    verifyPatientCaregiverContactInformation(
      id: $patientId
      relatedPersonId: $relatedPersonId
    ) {
      id
      patientRelatedPersonRelationships {
        id
        relatedPerson {
          id
          contactInformation {
            id
            verifiedAt
          }
        }
      }
    }
  }
`

export const UPDATE_PATIENT_CAREGIVER_CONTACT_INFORMATION = gql`
  mutation UpdatePatientCaregiverContactInformation(
    $patientId: String!
    $relatedPersonId: String!
    $input: UpdatePatientCaregiverContactInformationInput!
  ) {
    updatePatientCaregiverContactInformation(
      id: $patientId
      relatedPersonId: $relatedPersonId
      input: $input
    ) {
      id
      patientRelatedPersonRelationships {
        id
        relatedPerson {
          id
          contactInformation {
            id
            mobileNumber
            emailAddress
            homeNumber
            workNumber
            faxNumber
            primaryLanguage
            secondaryLanguage
            homeAddress {
              id
              line1
              line2
              city
              state
              country
              postalCode
            }
            mailingAddress {
              id
              line1
              line2
              city
              state
              country
              postalCode
            }
          }
        }
      }
    }
  }
`

const CaregiverContactInformationVerificationCard: React.FC<{
  relationship: AppointmentPatientCheckInQuery['appointment']['patient']['patientRelatedPersonRelationships'][0]
}> = ({ relationship }) => {
  const { relatedPerson } = relationship

  const {
    checkInVerification,
    appointmentData: { patient },
  } = useAppointmentCheckIn()

  const [verifyPatientCaregiverContactInformation] = useMutation(
    VERIFY_PATIENT_CAREGIVER_CONTACT_INFORMATION
  )

  const { verificationRequirement, lastVerifiedAt } =
    checkInVerification['CAREGIVER'][relationship.id]['CONTACT_INFORMATION']

  const onVerify = () => {
    verifyPatientCaregiverContactInformation({
      variables: {
        patientId: patient.id,
        relatedPersonId: relatedPerson.id,
      },
    })
  }

  const preSubmit = (data) => {
    const { useHomeAddressAsMailingAddress } = data
    const caregiverContactInformationInput = { ...data }

    if (useHomeAddressAsMailingAddress) {
      delete caregiverContactInformationInput.mailingAddress
    }

    return {
      variables: {
        patientId: patient.id,
        relatedPersonId: relatedPerson.id,
        input: caregiverContactInformationInput,
      },
    }
  }

  const useHomeAddressAsMailingAddress =
    relatedPerson.contactInformation.mailingAddress.id ===
    relatedPerson.contactInformation.homeAddress.id

  const formMethods = useForm({
    defaultValues: {
      mobileNumber: relatedPerson.contactInformation.mobileNumber,
      homeNumber: relatedPerson.contactInformation.homeNumber,
      workNumber: relatedPerson.contactInformation.workNumber,
      emailAddress: relatedPerson.contactInformation.emailAddress,
      faxNumber: relatedPerson.contactInformation.faxNumber,
      homeAddress: deepOmit(relatedPerson.contactInformation.homeAddress, [
        '__typename',
        'id',
      ]),
      mailingAddress: deepOmit(
        relatedPerson.contactInformation.mailingAddress,
        ['__typename', 'id']
      ),
      useHomeAddressAsMailingAddress,
    },
  })

  return (
    <DataVerificationCard
      title="Contact information"
      formMethods={formMethods}
      lastVerifiedAt={lastVerifiedAt}
      defaultOpen
      updateMutation={UPDATE_PATIENT_CAREGIVER_CONTACT_INFORMATION}
      preSubmit={preSubmit}
      verificationOptions={{
        onVerify: onVerify,
        verificationRequirement,
      }}
      data={[
        {
          name: 'mobileNumber',
          value: formatPhoneNumber(
            relatedPerson.contactInformation.mobileNumber
          ),
          label: 'Mobile number',
          required: true,
          formInputComponent: PhoneInputField,
        },
        {
          name: 'homeNumber',
          value: formatPhoneNumber(relatedPerson.contactInformation.homeNumber),
          label: 'Home number',
          formInputComponent: PhoneInputField,
        },
        {
          name: 'workNumber',
          value: formatPhoneNumber(relatedPerson.contactInformation.workNumber),
          label: 'Work number',
          formInputComponent: PhoneInputField,
        },
        {
          name: 'emailAddress',
          value: relatedPerson.contactInformation.emailAddress,
          label: 'Email address',
          inputProps: {
            type: 'email',
          },
          formInputComponent: InputField,
        },
        {
          name: 'faxNumber',
          value: formatPhoneNumber(relatedPerson.contactInformation.faxNumber),
          label: 'Fax number',
          formInputComponent: PhoneInputField,
        },
        {
          name: 'primaryLanguage',
          value: relatedPerson.contactInformation.primaryLanguage,
          displayValue:
            languageDisplay[relatedPerson.contactInformation.primaryLanguage],
          label: 'Primary language',
          required: true,
          inputProps: {
            defaultValue: 'EN_US',
          },
          formInputComponent: LanguageSelectField,
        },
        {
          name: 'secondaryLanguage',
          value: relatedPerson.contactInformation.secondaryLanguage,
          displayValue:
            languageDisplay[relatedPerson.contactInformation.secondaryLanguage],
          label: 'Secondary language',
          formInputComponent: LanguageSelectField,
        },
        {
          name: 'homeAddress',
          value: relatedPerson.contactInformation.homeAddress,
          displayValue: formatAddress(
            relatedPerson.contactInformation.homeAddress
          ),
          label: 'Home address',
          alignItems: 'start',
          inputProps: {
            name: 'homeAddress',
          },
          formInputComponent: AddressFormInputList,
        },
        {
          name: 'mailingAddress',
          value: relatedPerson.contactInformation.mailingAddress,
          displayValue: useHomeAddressAsMailingAddress
            ? 'Same as home address'
            : formatAddress(relatedPerson.contactInformation.mailingAddress),
          label: 'Mailing address',
          alignItems: 'start',
          inputProps: {
            name: 'mailingAddress',
            useInheritedAddressName: 'useHomeAddressAsMailingAddress',
            useInheritedAddressDescription:
              'Use home address as mailing address',
          },
          formInputComponent: InheritedAddressField,
        },
      ]}
    />
  )
}

export default CaregiverContactInformationVerificationCard
