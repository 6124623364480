import { BoltIcon } from '@heroicons/react/20/solid'
import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import { format, parseISO } from 'date-fns'
import { ConditionClinicalStatus } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField'
import TextareaAutoSaveSection from 'src/components/molecules/TextareaAutoSaveSection/TextareaAutoSaveSection'
import { conditionStatuses } from 'src/data/conditionStatuses'
import { sidepanelRoute } from 'src/lib/routes'
import BirthHistory from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/BirthHistory'
import MenstrualHistory from 'src/pages/PatientChartsPage/PatientHistory/MenstrualHistory/MenstrualHistory'
import SexualHistory from 'src/pages/PatientChartsPage/PatientHistory/SexualHistory/SexualHistory'
import {
  SocialHistoryCategoryColumn,
  SocialHistoryValueColumn,
  SocialHistoryNoRowsOverlay,
} from 'src/pages/PatientChartsPage/PatientHistory/SocialHistory/config'
import SocialHistoryTable from 'src/pages/PatientChartsPage/PatientHistory/SocialHistory/SocialHistoryTable'
import { useSocialHistoryQuery } from 'src/pages/PatientChartsPage/PatientHistory/SocialHistory/useSocialHistory'

import {
  usePatientPastMedicalHistoryMutation,
  usePatientPastMedicalHistoryQuery,
} from '../PatientDemographics/usePatient'

import AllergiesTable from './Allergies/AllergiesTable'
import NoKnownAllergies from './Allergies/NoKnownAllergies'
import { useAllergiesQuery } from './Allergies/useAllergies'
import BirthHistoryV2 from './BirthHistory/BirthHistoryV2'
import DiagnosesTable from './Diagnoses/DiagnosesTable/DiagnosesTable'
import { useConditionsQuery } from './Diagnoses/useConditions'
import {
  DiagnosisYearAndAgeColumn,
  Icd10CodeAndDescriptionColumn,
  RelationshipTypeColumn,
} from './FamilyHistory/config'
import FamilyHistoryTable from './FamilyHistory/FamilyHistoryTable'
import { useFamilyHistoryQuery } from './FamilyHistory/useFamilyHistory'
import { useMenstrualHistoryQuery } from './MenstrualHistory/useMenstrualHistory'
import PatientScoresTable, {
  EmptyPatientScores,
} from './PatientScores/PatientScoresTable'
import { usePatientScoresQuery } from './PatientScores/usePatientScores'

const PatientScoresSection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()

  const { patientScoresLastUpdated, patientScores } =
    usePatientScoresQuery(patientId)

  const hasPatientScores = patientScores?.length

  return (
    <StackView space={50}>
      <StackView direction="row" justifyContent="between">
        <StackView direction="row" space={50} alignItems="center">
          <Typography textStyle="title" fontWeight="semibold">
            Patient scores
          </Typography>
          {patientScoresLastUpdated ? (
            <Typography
              textStyle="description"
              color="text-base-color-fg-muted"
            >
              Last updated{' '}
              {format(parseISO(patientScoresLastUpdated), 'MM/dd/yyyy')}
            </Typography>
          ) : null}
        </StackView>
        <Button
          testId="add-patient-scores-button"
          icon={PlusIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                {
                  route: `/patients/${patientId}/scores/new`,
                },
                location,
                params
              )
            )
          }
          text="Add"
        />
      </StackView>
      {!hasPatientScores ? (
        <EmptyPatientScores />
      ) : (
        <PatientScoresTable patientId={patientId} />
      )}
    </StackView>
  )
}

const AllergySection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()

  const { allergiesLastUpdated, hasNoKnownAllergies } =
    useAllergiesQuery(patientId)

  return (
    <StackView space={50}>
      <StackView direction="row" justifyContent="between">
        <StackView direction="row" space={50} alignItems="center">
          <Typography textStyle="title" fontWeight="semibold">
            Allergies
          </Typography>
          {allergiesLastUpdated ? (
            <Typography
              textStyle="description"
              color="text-base-color-fg-muted"
            >
              Last updated{' '}
              {format(parseISO(allergiesLastUpdated), 'MM/dd/yyyy')}
            </Typography>
          ) : null}
        </StackView>
        <Button
          testId="edit-allergies-button"
          icon={PencilIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                { route: `/patients/${patientId}/allergies/new` },
                location,
                params
              )
            )
          }
          text="Edit"
        />
      </StackView>
      {hasNoKnownAllergies ? (
        <NoKnownAllergies />
      ) : (
        <AllergiesTable patientId={patientId} />
      )}
    </StackView>
  )
}

const PastMedicalAndSurgicalHistorySection = ({
  patientId,
}: {
  patientId: string
}) => {
  const { patient, loading } = usePatientPastMedicalHistoryQuery(patientId)
  const [updatePastMedicalHistory] = usePatientPastMedicalHistoryMutation()

  if (!patient) return null
  return (
    <TextareaAutoSaveSection
      testId="past-medical-history"
      title="Past medical & surgical history"
      subtitle={
        patient?.pastMedicalHistoryLastUpdatedAt
          ? `Last updated ${format(
              parseISO(patient.pastMedicalHistoryLastUpdatedAt),
              'MM/dd/yyyy'
            )}`
          : undefined
      }
      isRichText={true}
      defaultValue={patient?.pastMedicalHistory}
      onChange={(value: string) =>
        updatePastMedicalHistory({
          variables: { input: { patientId, value } },
        })
      }
      loading={loading}
      macroPhraseSection="PAST_MEDICAL_HISTORY"
    />
  )
}

const DiagnosesSection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()
  const { conditionsLastUpdated, conditionsGroupedByCode } = useConditionsQuery(
    patientId,
    'DIAGNOSIS'
  )
  const formMethods = useForm<{
    diagnosesFilter: ConditionClinicalStatus[]
  }>({
    defaultValues: { diagnosesFilter: ['active', 'inactive'] },
  })

  const filter = formMethods.watch('diagnosesFilter')
  return (
    <StackView space={50}>
      <StackView direction="row" space={50} alignItems="center">
        <StackView fullWidth={false}>
          <Typography textStyle="title" fontWeight="semibold">
            Diagnoses
          </Typography>
          {conditionsLastUpdated ? (
            <Typography
              textStyle="description"
              color="text-base-color-fg-muted"
              noWrap
            >
              Last updated{' '}
              {format(parseISO(conditionsLastUpdated), 'MM/dd/yyyy')}
            </Typography>
          ) : null}
        </StackView>
        <StackView direction="row" justifyContent="end" space={50}>
          <Form formMethods={formMethods}>
            <MultiSelectDropdownField
              name="diagnosesFilter"
              testId="dropdown-diagnosesFilter"
              emptyDisplayText="Status"
              multiSelectDisplayText="statuses"
              icon={BoltIcon}
              options={conditionStatuses}
            />
          </Form>
          <Button
            testId="edit-diagnoses-button"
            icon={PencilIcon}
            onClick={() =>
              navigate(
                sidepanelRoute(
                  { route: `/patients/${patientId}/diagnoses/new` },
                  location,
                  params
                )
              )
            }
            text="Edit"
          />
        </StackView>
      </StackView>
      <DiagnosesTable
        patientId={patientId}
        testId="diagnoses-table"
        editRoute="diagnoses"
        filter={filter}
        data={conditionsGroupedByCode}
        emptyMessage="No diagnoses found"
      />
    </StackView>
  )
}

const ZCodesSection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()

  const { conditionsGroupedByCode, conditionsLastUpdated } = useConditionsQuery(
    patientId,
    'Z_CODE'
  )

  const formMethods = useForm<{ zCodesFilter: ConditionClinicalStatus[] }>({
    defaultValues: { zCodesFilter: ['active', 'inactive'] },
  })
  const filter = formMethods.watch('zCodesFilter')

  return (
    <StackView space={50}>
      <StackView direction="row" justifyContent="between" space={50}>
        <StackView direction="row" space={50} alignItems="center">
          <Typography textStyle="title" fontWeight="semibold">
            Z codes
          </Typography>
          {conditionsLastUpdated ? (
            <Typography
              textStyle="description"
              color="text-base-color-fg-muted"
              noWrap
            >
              Last updated{' '}
              {format(parseISO(conditionsLastUpdated), 'MM/dd/yyyy')}
            </Typography>
          ) : null}
        </StackView>

        <Form formMethods={formMethods}>
          <MultiSelectDropdownField
            name="zCodesFilter"
            testId="dropdown-zCodesFilter"
            emptyDisplayText="Status"
            multiSelectDisplayText="statuses"
            icon={BoltIcon}
            options={conditionStatuses}
          />
        </Form>
        <Button
          testId="edit-zCodes-button"
          icon={PencilIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                { route: `/patients/${patientId}/zcodes/new` },
                location,
                params
              )
            )
          }
          text="Edit"
        />
      </StackView>
      <DiagnosesTable
        patientId={patientId}
        testId="zCodes-table"
        editRoute="zcodes"
        filter={filter}
        data={conditionsGroupedByCode}
        emptyMessage="No Z codes found"
      />
    </StackView>
  )
}

const BirthHistorySection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()

  return (
    <StackView space={50}>
      <StackView
        direction="row"
        space={50}
        alignItems="center"
        justifyContent="between"
      >
        <StackView fullWidth={false}>
          <Typography textStyle="title" fontWeight="semibold">
            Birth history
          </Typography>
        </StackView>
        <Button
          testId="edit-birth-history-button"
          icon={PencilIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                { route: `/patients/${patientId}/birthHistory/edit` },
                location,
                params
              )
            )
          }
          text="Edit"
        />
      </StackView>

      <FeatureFlagged
        flagName="BIRTH_HISTORY_UNIT_INPUTS"
        childrenIfFlagNotActive={
          <BirthHistory patientId={patientId} testId="patient-birth-history" />
        }
      >
        <BirthHistoryV2
          patientId={patientId}
          testId="patient-birth-history-V2"
        />
      </FeatureFlagged>
    </StackView>
  )
}

const SexualHistorySection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()

  return (
    <StackView space={50}>
      <StackView>
        <StackView
          direction="row"
          space={50}
          alignItems="center"
          justifyContent="between"
        >
          <Typography textStyle="title" fontWeight="semibold">
            Sexual history
          </Typography>
          <Button
            testId="edit-sexual-history-button"
            icon={PencilIcon}
            onClick={() =>
              navigate(
                sidepanelRoute(
                  { route: `/patients/${patientId}/sexualHistory/edit` },
                  location,
                  params
                )
              )
            }
            text="Edit"
          />
        </StackView>
      </StackView>
      <SexualHistory patientId={patientId} testId="patient-sexual-history" />
    </StackView>
  )
}

const MenstrualHistorySection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()
  const { hasMenstrualHistory } = useMenstrualHistoryQuery(patientId)
  if (!hasMenstrualHistory) return null
  return (
    <StackView space={50}>
      <StackView>
        <StackView
          direction="row"
          space={50}
          alignItems="center"
          justifyContent="between"
        >
          <Typography textStyle="title" fontWeight="semibold">
            Menstrual History
          </Typography>
          <Button
            testId="edit-menstrual-history-button"
            icon={PencilIcon}
            onClick={() =>
              navigate(
                sidepanelRoute(
                  { route: `/patients/${patientId}/menstrualHistory/edit` },
                  location,
                  params
                )
              )
            }
            text="Edit"
          />
        </StackView>
      </StackView>
      <MenstrualHistory
        patientId={patientId}
        testId="patient-menstrual-history"
      />
    </StackView>
  )
}

const SocialHistorySection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()

  const { fieldToSocialHistoryObservations } = useSocialHistoryQuery(patientId)

  return (
    <StackView space={50} className="pb-4">
      <StackView>
        <StackView
          direction="row"
          space={50}
          alignItems="center"
          justifyContent="between"
        >
          <Typography textStyle="title" fontWeight="semibold">
            Social history
          </Typography>
          <Button
            testId="edit-social-history-button"
            icon={PencilIcon}
            onClick={() =>
              navigate(
                sidepanelRoute(
                  { route: `/patients/${patientId}/socialHistory/edit` },
                  location,
                  params
                )
              )
            }
            text="Edit"
          />
        </StackView>
      </StackView>
      <SocialHistoryTable
        testId="patient-social-history"
        rowData={
          fieldToSocialHistoryObservations
            ? Object.values(fieldToSocialHistoryObservations)
            : []
        }
        columnDefs={[SocialHistoryCategoryColumn, SocialHistoryValueColumn]}
        noRowsOverlayComponent={SocialHistoryNoRowsOverlay}
      />
    </StackView>
  )
}

const FamilyHistorySection = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()

  const { familyMemberHistories } = useFamilyHistoryQuery(patientId)

  return (
    <StackView space={50}>
      <StackView>
        <StackView
          direction="row"
          space={50}
          alignItems="center"
          justifyContent="between"
        >
          <Typography textStyle="title" fontWeight="semibold">
            Family history
          </Typography>
          <Button
            testId="create-family-history-button"
            icon={PencilIcon}
            onClick={() =>
              navigate(
                sidepanelRoute(
                  { route: `/patients/${patientId}/familyHistory/create` },
                  location,
                  params
                )
              )
            }
            text="Create"
          />
        </StackView>
      </StackView>
      <FamilyHistoryTable
        testId="patient-family-history"
        rowData={familyMemberHistories}
        columnDefs={[
          Icd10CodeAndDescriptionColumn,
          DiagnosisYearAndAgeColumn,
          RelationshipTypeColumn,
        ]}
      />
    </StackView>
  )
}

const PatientHistory = () => {
  const { id: patientId } = useParams()

  return (
    <StackView space={200} className="px-6 pt-4">
      <AllergySection patientId={patientId} />
      <PastMedicalAndSurgicalHistorySection patientId={patientId} />
      <DiagnosesSection patientId={patientId} />
      <ZCodesSection patientId={patientId} />
      <PatientScoresSection patientId={patientId} />
      <BirthHistorySection patientId={patientId} />
      <FamilyHistorySection patientId={patientId} />
      <SexualHistorySection patientId={patientId} />
      <MenstrualHistorySection patientId={patientId} />
      <SocialHistorySection patientId={patientId} />
    </StackView>
  )
}

export default PatientHistory
