import clsx from 'clsx'
import { displayAppointmentTimeRange } from 'common/data/appointments'
import { appointmentTypeDisplay } from 'common/data/appointmentTypes'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { formatDisplayName } from 'common/utils'
import {
  FindPractitionerScheduleQuery,
  InsuranceCoveragePlanStatus,
} from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { appointmentStatusConfiguration } from 'src/data/appointmentStatus'
import { insuranceOptOutStatusDisplay } from 'src/data/insuranceOptOutStatuses'
import { sexAbbreviation } from 'src/data/sexes'
import { formatDateDisplay, formatAge } from 'src/lib/formatters'

import { SubHeading } from '../common'

export const AppointmentRow = ({
  appointment,
  position,
}: {
  appointment: FindPractitionerScheduleQuery['appointments'][number]
  position: 'first' | 'last' | 'other'
}) => {
  const patient = appointment.patient ?? appointment.patientRegistrationIntent

  const bottomRowData: { key: string; primary: string; secondary: string }[] = [
    {
      key: 'time',
      primary: displayAppointmentTimeRange(appointment),
      secondary: appointmentStatusConfiguration[appointment.status].display,
    },
    {
      key: 'practitioner',
      primary: formatDisplayName(appointment.practitioner),
      secondary: appointment.location.name,
    },
    {
      key: 'visitType',
      primary: appointment.appointmentDefinitions
        .map(({ type }) => appointmentTypeDisplay[type])
        .join(', '),

      secondary: appointment.chiefComplaints
        .map((complaint) => chiefComplaintDisplay[complaint])
        .join(', '),
    },
  ]

  const insuranceCoverages = appointment.patient?.activeInsuranceCoverages ?? []

  return (
    <StackView
      key={appointment.id}
      gap={50}
      className={clsx(
        'border-base-color-subtle border-x-core-border-width-10 border-t-core-border-width-10 p-core-space-100',
        position === 'first' && 'rounded-t-core-border-radius-25',
        position === 'last' &&
          'rounded-b-core-border-radius-25 border-b-core-border-width-10'
      )}
    >
      <SubHeading>{formatDisplayName(patient)}</SubHeading>

      <StackView direction="row" gap={50}>
        {bulletSeparate(
          [
            <Typography key="sexAtBirth" color="text-base-color-fg-muted">
              {sexAbbreviation[patient.sexAtBirth]}
            </Typography>,

            <Typography
              key="age"
              color="text-base-color-fg-muted"
              className="shrink-0"
            >
              {formatAge(patient.birthDate)} (
              {formatDateDisplay(patient.birthDate)})
            </Typography>,

            appointment.patient?.insuranceOptOutStatus ? (
              <Typography
                key="insuranceOptOutStatus"
                color="text-base-color-fg-muted"
              >
                {
                  insuranceOptOutStatusDisplay[
                    appointment.patient.insuranceOptOutStatus
                  ]
                }
              </Typography>
            ) : null,

            ...insuranceCoverages.map((coverage) => (
              <StackView key={coverage.id} direction="row" gap={25}>
                <InsuranceStatusIcon
                  insuranceCoverageStatus={
                    coverage.mostRecentEligibility?.status
                  }
                />
                <Typography color="text-base-color-fg-muted">
                  {coverage.payer.displayName}
                </Typography>
              </StackView>
            )),
          ].filter(Boolean)
        )}
      </StackView>

      <StackView direction="row">
        {bottomRowData.map((data) => (
          <StackView key={data.key}>
            <Typography>{data.primary}</Typography>
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
            >
              {data.secondary}
            </Typography>
          </StackView>
        ))}
      </StackView>
    </StackView>
  )
}

const InsuranceStatusIcon = ({
  insuranceCoverageStatus,
}: {
  insuranceCoverageStatus: InsuranceCoveragePlanStatus
}) => (
  <img
    className="mt-2 h-3 w-auto"
    src={
      insuranceCoverageStatus === 'ACTIVE_COVERAGE'
        ? '/img/check-circle-icon-green.png'
        : insuranceCoverageStatus === 'INACTIVE'
          ? '/img/error-circle.png'
          : '/img/warning-triangle.png'
    }
    alt="insurance-status"
  />
)

const bulletSeparate = (items: React.ReactNode[]): React.ReactNode =>
  items.reduce((acc, el, i) => {
    if (i === 0) return el

    return (
      <>
        {acc}
        <Typography color="text-base-color-fg-muted">&bull;</Typography>
        {el}
      </>
    )
  })
