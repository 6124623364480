import { familyMemberHistoryRelationshipTypes } from 'common/data/relationshipTypes'
import { useParams } from 'react-router-dom'
import { CreateFamilyMemberHistoryInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import RelationshipTypeSelectField from 'src/components/atoms/RelationshipTypeSelectField/RelationshipTypeSelectField'
import FamilyMemberHistoryOccurrenceInputField from 'src/components/molecules/FamilyMemberHistoryOccurrenceInputField/FamilyMemberHistoryOccurrenceInputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import GenericFormFieldArray from 'src/components/molecules/GenericFormFieldArray/GenericFormFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import DiagnosisSearchField from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import { useCreateFamilyHistory } from 'src/pages/PatientChartsPage/PatientHistory/FamilyHistory/useFamilyHistory'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const familyHistoryRelationshipTypeOptions =
  familyMemberHistoryRelationshipTypes.map(({ name, value }) => ({
    name,
    value,
    display: name,
  }))

const SidepanelFamilyHistoryCreate = () => {
  const { patientId } = useParams()

  const { closeSidePanel } = useSidepanel()

  const [createFamilyMemberHistory, { loading: upserting }] =
    useCreateFamilyHistory()

  const onSubmit = async (data) => {
    const input: CreateFamilyMemberHistoryInput = {
      patientId,
      icd10Code: data.icd10Code?.code,
      relationshipType: data?.relationshipType,
      occurrences: data.occurrences,
    }

    createFamilyMemberHistory({
      variables: { input },
      onCompleted: () => {
        toast.success('Family history created')
        closeSidePanel()
      },
    })
  }

  const formMethods = useForm<CreateFamilyMemberHistoryInput>({
    defaultValues: {
      occurrences: [
        {
          onsetYear: null,
          onsetAgeInYears: null,
        },
      ],
    },
  })

  return (
    <SidepanelPage
      testId={'patient-family-history-create'}
      header="Create family history"
    >
      <SidepanelForm
        footerProps={{
          submitting: upserting,
          submitText: 'Create',
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'icd10Code',
              label: 'Family history code',
              required: true,
              direction: 'col',
              formInputComponent: DiagnosisSearchField,
              inputProps: {
                patientId,
                defaultFilters: [
                  'USER_FAVORITE',
                  'PATIENT_ACTIVE',
                  'FAMILY_HISTORY',
                ],
                searchSet: 'ALL',
                defaultSearching: true,
              },
            },
            {
              name: 'relationshipType',
              label: 'Relationship to patient',
              required: true,
              inputProps: {
                filterOptions: familyHistoryRelationshipTypeOptions,
              },
              formInputComponent: RelationshipTypeSelectField,
            },
            {
              name: 'occurrences',
              label: 'Past occurrence(s)',
              subtitle: `Click on 'Add occurrence' to add multiple occurrences
                of the same condition`,
              direction: 'col',
              formInputComponent: GenericFormFieldArray,
              inputProps: {
                formInputComponent: FamilyMemberHistoryOccurrenceInputField,
                addButtonLabel: 'Add occurrence',
                addNewDefault: {
                  onsetAgeInYears: null,
                  onsetYear: null,
                },
                showItemErrors: false,
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelFamilyHistoryCreate
