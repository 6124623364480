import { box14ClaimQualifiers } from 'common/data/claimQualifiers'

import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'

export const Box14QualifierSelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={box14ClaimQualifiers.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default Box14QualifierSelectField
