import { useEffect } from 'react'

import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { compact } from 'lodash'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import {
  SendAppointmentTaskReminder,
  SendAppointmentTaskReminderInput,
  SendAppointmentTaskReminderVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { navigate, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import FormInputList from 'src/components/molecules/FormInputList'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { SEND_TASK_REMINDER } from 'src/components/PatientVisitTasksSection/PatientVisitTasksSection'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { formatPhoneNumber } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { useVisitQuery } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'

const SidepanelSendAppointmentTasksReminder = () => {
  const params = useParams()
  const location = useLocation()

  const { appointmentId } = params

  const { visit } = useVisitQuery(appointmentId)

  const [
    sendTaskReminder,
    { loading: sendingTaskReminder, error: sendTaskReminderError },
  ] = useMutation<
    SendAppointmentTaskReminder,
    SendAppointmentTaskReminderVariables
  >(SEND_TASK_REMINDER)

  const patient = visit?.patient

  const patientContact = patient?.contactInformation && {
    value: ['patient', patient.id].join(':'),
    label: `${formatDisplayName(patient)}: ${formatPhoneNumber(
      patient.contactInformation.mobileNumber
    )}`,
    description: 'Patient',
  }

  const patientRegistrationIntentContact = visit?.patientRegistrationIntent && {
    value: [
      'patientRegistrationIntent',
      visit.patientRegistrationIntent.id,
    ].join(':'),
    label: `${formatDisplayName(
      visit.patientRegistrationIntent
    )}: ${formatPhoneNumber(visit.patientRegistrationIntent.phoneNumber)}`,
    description: 'Patient',
  }

  const relatedPersonContacts =
    patient?.patientRelatedPersonRelationships &&
    patient.patientRelatedPersonRelationships.map((relationship) => ({
      value: ['relatedPerson', relationship.relatedPerson.id].join(':'),
      label: `${formatDisplayName(
        relationship.relatedPerson
      )}: ${formatPhoneNumber(
        relationship.relatedPerson.contactInformation.mobileNumber
      )}`,
      description: [
        relationship.guardianshipType &&
          `${guardianshipTypeDisplay[relationship.guardianshipType]} ${
            relationship.guardianshipType !== 'NON_GUARDIAN' ? 'Guardian' : ''
          }`,
        relationship.relationshipType &&
          relationshipTypeDisplay[relationship.relationshipType],
        relationship.isGuarantor ? 'Guarantor' : '',
      ]
        .filter(Boolean)
        .join(' \u2022 '),
    }))

  const appointmentReminderContacts = compact([
    ...(relatedPersonContacts ?? []),
    !patientContact && patientRegistrationIntentContact,
    patientContact,
  ])

  const onSubmit = (data) => {
    const [type, id] = data.recipient.split(':')

    const input: SendAppointmentTaskReminderInput = match(type)
      .with('patient', () => ({ patientId: id }))
      .with('patientRegistrationIntent', () => ({
        patientRegistrationIntentId: id,
      }))
      .with('relatedPerson', () => ({ relatedPersonId: id }))
      .otherwise(() => ({}) as SendAppointmentTaskReminderInput)

    void sendTaskReminder({
      variables: {
        appointmentId,
        input,
      },
      onCompleted: () => {
        toast.success('Sent task to patient or caregiver')
        navigate(
          sidepanelRoute(
            {
              route: `/appointments/${appointmentId}/visit`,
            },
            location,
            params
          )
        )
      },
    })
  }

  const formMethods = useForm({
    defaultValues: {
      recipient: appointmentReminderContacts?.[0]?.value,
    },
  })

  useEffect(() => {
    if (
      appointmentReminderContacts.length &&
      !formMethods.getValues('recipient')
    ) {
      formMethods.setValue('recipient', appointmentReminderContacts[0].value)
    }
  }, [appointmentReminderContacts, formMethods])

  return (
    <SidepanelPage
      header="Send task(s) link"
      description="Select the recipient who should receive the text message-based web link."
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Send',
          submitting: sendingTaskReminder,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
        error={sendTaskReminderError}
      >
        <StackView space={25}>
          <StackView space={150} className="pt-core-space-150">
            <Typography textStyle="title-xs">Details</Typography>
            <StackView space={25}>
              <Typography textStyle="interface-default-s">Recipient</Typography>
              <Typography textStyle="interface-default-xs">
                Select the patient or one of their caregivers who should receive
                the text message-based web link for the visit.
              </Typography>
            </StackView>
          </StackView>
          <FormInputList
            divider={false}
            className="py-core-space-0"
            items={compact([
              appointmentReminderContacts.length && {
                name: 'recipient',
                label: 'Recipient',
                hideLabel: true,
                required: true,
                formInputComponent: MultiRadioButtonField,
                inputProps: {
                  radioStyle: 'card',
                  radioPosition: 'right',
                  values: appointmentReminderContacts,
                },
                direction: 'col',
              },
            ])}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelSendAppointmentTasksReminder
