import { useState } from 'react'

import { formatDollarsToCents } from 'common/utils'
import { match } from 'ts-pattern'
import {
  CreateMembershipPlan,
  CreateMembershipPlanVariables,
} from 'types/graphql'

import { useForm, useFormContext } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Box from 'src/components/atoms/Box/Box'
import Card from 'src/components/atoms/Card/Card'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { MoneyInputField } from 'src/components/molecules/MoneyInputField/MoneyInputField'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import Toggle from 'src/components/molecules/Toggle/Toggle'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const CREATE_MEMBERSHIP = gql`
  mutation CreateMembershipPlan($input: CreateMembershipPlanInput!) {
    createMembershipPlan(input: $input) {
      id
      name
      annualAmountCents
      quarterlyAmountCents
      monthlyAmountCents
    }
  }
`

const SidepanelMembershipCreate = () => {
  const { closeSidePanel } = useSidepanel()
  const formMethods = useForm()

  const [createMembershipPlan] = useMutation<
    CreateMembershipPlan,
    CreateMembershipPlanVariables
  >(CREATE_MEMBERSHIP)

  return (
    <SidepanelPage
      header="Add membership tier"
      description="Configure details for a new membership tier."
    >
      <SidepanelForm
        formMethods={formMethods}
        footerProps={{
          submitting: formMethods.formState.isSubmitting,
          submitText: 'Save',
        }}
        divider={false}
        onSubmit={async (data) => {
          await createMembershipPlan({
            variables: {
              input: {
                name: data.title,
                annualAmountCents: formatDollarsToCents(data.annualDollars),
                quarterlyAmountCents: formatDollarsToCents(
                  data.quarterlyDollars
                ),
                monthlyAmountCents: formatDollarsToCents(data.monthlyDollars),
              },
            },
            refetchQueries: ['ListMembershipPlans'],
            onCompleted: () => {
              closeSidePanel()
              toast.success('Membership tier created')
            },
          })
        }}
      >
        <StackView className="py-core-space-150" gap={50}>
          <SectionHeader title="Tier details" size="s" />
          <FormInputList
            items={[
              {
                name: 'title',
                label: 'Title',
                direction: 'col',
                required: true,
                formInputComponent: InputField,
              },
            ]}
          />

          <StackView gap={25}>
            <Typography textStyle="interface-default-s">
              Payment frequency*
            </Typography>
            <Typography
              color="text-base-color-fg-subtle"
              textStyle="interface-default-xs"
            >
              Choose at least one frequency and add the charge amount for that
              frequency.
            </Typography>
          </StackView>
          <MembershipFeeCard type="monthly" />
          <MembershipFeeCard type="quarterly" />
          <MembershipFeeCard type="annually" />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

const MembershipFeeCard = ({
  type,
}: {
  type: 'annually' | 'quarterly' | 'monthly'
}) => {
  const formMethods = useFormContext()

  const name = match(type)
    .with('annually', () => 'annualDollars')
    .with('quarterly', () => 'quarterlyDollars')
    .with('monthly', () => 'monthlyDollars')
    .exhaustive()
  const title = match(type)
    .with('annually', () => 'Annual payments')
    .with('quarterly', () => 'Quarterly payments')
    .with('monthly', () => 'Monthly payments')
    .exhaustive()
  const description = match(type)
    .with('annually', () => 'One payment every year.')
    .with('quarterly', () => 'Four equal payments each year.')
    .with('monthly', () => 'Twelve equal payments each year.')
    .exhaustive()

  const [checked, setChecked] = useState(false)

  const handleChecked = (isChecked: boolean) => {
    if (!isChecked) {
      formMethods.resetField(name)
    }

    setChecked(isChecked)
  }

  return (
    <Card
      className="px-core-space-100 py-core-space-75"
      testId={`${type}-payment-frequency`}
    >
      <StackView gap={50}>
        <StackView direction="row" justifyContent="between" alignItems="center">
          <SectionHeader size="xs" title={title} description={description} />
          <Toggle checked={checked} onChange={handleChecked} />
        </StackView>

        <Box className={!checked ? 'hidden' : undefined}>
          <MoneyInputField
            name={name}
            rightText={match(type)
              .with('annually', () => 'per year')
              .with('quarterly', () => 'per quarter')
              .with('monthly', () => 'per month')
              .exhaustive()}
            validation={{
              validate: (_value, fieldValues) => {
                if (
                  !fieldValues.annualDollars &&
                  !fieldValues.quarterlyDollars &&
                  !fieldValues.monthlyDollars
                ) {
                  return 'At least one payment frequency must be set'
                }
              },
            }}
          />
        </Box>

        <FieldError
          name={name}
          label={'This field'}
          defaultErrorMessage={`This field has an error`}
        />
      </StackView>
    </Card>
  )
}

export default SidepanelMembershipCreate
