import { useEffect } from 'react'

import isEmpty from 'lodash/isEmpty'
import { CreateInsuranceCoverageInput, RelationshipType } from 'types/graphql'

import { useForm, FormError, Form } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Box from 'src/components/atoms/Box'
import Button, { Submit } from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card/Card'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import CoordinationOfBenefitsSelectField from 'src/components/atoms/CoordinationOfBenefitsSelectField/CoordinationOfBenefitsSelectField'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import HealthPlanTypeSelectField from 'src/components/atoms/HealthPlanTypeSelectField/HealthPlanTypeSelectField'
import InputField from 'src/components/atoms/InputField'
import InsuranceCoverageStatusSelectField from 'src/components/atoms/InsuranceCoverageStatusSelectField/InsuranceCoverageStatusSelectField'
import PayerAddressSelectField from 'src/components/atoms/PayerAddressSelectField'
import RelationshipTypeSelectField from 'src/components/atoms/RelationshipTypeSelectField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { PayerSelectField } from 'src/components/molecules/PayerSelectField/PayerSelectField'
import RelatedPersonSelectCell from 'src/components/RelatedPerson/RelatedPersonSelectCell'
import { formatDateFieldValue } from 'src/lib/formatters'
import { base64EncodeFile } from 'src/utils'

const CREATE_INSURANCE_COVERAGE_MUTATION = gql`
  mutation CreateInsuranceCoverageMutation(
    $input: CreateInsuranceCoverageInput!
  ) {
    createInsuranceCoverage(input: $input) {
      id
    }
  }
`

interface CreateInsuranceFormInput extends CreateInsuranceCoverageInput {
  memberName: string
  memberBirthDate: string
  subscriber: {
    id: string
    birthDate: string
    relationshipType: RelationshipType
  }
}

const CreateInsuranceForm = ({
  patientId,
  displayName,
  memberName,
  memberBirthDate,
  memberCaregiverOptedOutAt,
  relationships,
  onComplete,
  onCancel,
}) => {
  const [
    createInsuranceCoverage,
    { loading: creatingInsuranceCoverage, error },
  ] = useMutation(CREATE_INSURANCE_COVERAGE_MUTATION, {
    onCompleted: () => {
      toast.success('InsuranceCoverage created')
      onComplete()
    },
    refetchQueries: [
      'AppointmentPatientCheckInQuery',
      'FindPatientDemographicsQuery',
    ],
  })

  const formMethods = useForm<CreateInsuranceFormInput>({
    shouldUnregister: true,
    defaultValues: {
      memberName,
      memberBirthDate,
      beneficiaryIsSubscriber: false,
    },
  })

  const { setValue, watch } = formMethods

  const beneficiaryIsSubscriber = watch('beneficiaryIsSubscriber')
  const subscriber = watch('subscriber')
  const payerId = watch('payerId')

  useEffect(() => {
    const relationship = relationships.find(
      (relationship) => relationship.relatedPerson.id === subscriber?.id
    )

    if (relationship) {
      setValue('subscriber.birthDate', relationship.relatedPerson.birthDate)
      setValue('subscriber.relationshipType', relationship.relationshipType)
    }
  }, [relationships, setValue, subscriber?.id])

  const onSubmit = async (data: CreateInsuranceFormInput) => {
    const {
      insuranceCardBinary: insuranceCardFile,
      memberBirthDate: _birthDate,
      memberName: _memberName,
      subscriber,
      ...rest
    } = data

    const input = {
      beneficiaryId: patientId,
      subscriberId: subscriber?.id,
      insuranceCardBinary: isEmpty(insuranceCardFile)
        ? undefined
        : {
            content: await base64EncodeFile(insuranceCardFile[0]),
            contentType: insuranceCardFile[0].type,
          },
      ...rest,
    }

    createInsuranceCoverage({ variables: { input } })
  }

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <StackView space={125}>
        <Typography textStyle="heading">{displayName}</Typography>
        <FormError
          error={error}
          wrapperClassName="rw-form-error-wrapper"
          titleClassName="rw-form-error-title"
          listClassName="rw-form-error-list"
        />
        <Card title="Insurance status">
          <Box horizontalPadding={100}>
            <FormInputList
              items={[
                {
                  name: 'status',
                  label: 'Status',
                  required: true,
                  formInputComponent: InsuranceCoverageStatusSelectField,
                },
                {
                  name: 'coordinationOfBenefitsType',
                  label: 'Type',
                  required: true,
                  formInputComponent: CoordinationOfBenefitsSelectField,
                },
              ]}
            />
          </Box>
        </Card>

        <Typography textStyle="title">Member and Policyholder</Typography>

        <Card title="Member information">
          <Box horizontalPadding={100}>
            <FormInputList
              items={[
                {
                  name: 'memberName',
                  label: 'Member name',
                  required: true,
                  message:
                    'Please confirm that the member name matches the patient name that is kept on file. If it does not, please go back and update the patient name.',
                  inputProps: {
                    disabled: true,
                  },
                  formInputComponent: InputField,
                },
                {
                  name: 'memberBirthDate',
                  label: 'Member date of birth',
                  message:
                    'Please confirm that the member date of birth matches the patient date of birth that is kept on file. If it does not, please go back and update the patient date of birth.',
                  required: true,
                  inputProps: {
                    disabled: true,
                  },
                  formInputComponent: DatePickerField,
                },
                {
                  name: 'planMemberIdentifier',
                  label: 'Member ID',
                  required: true,
                  formInputComponent: InputField,
                },
                {
                  name: 'payerId',
                  label: 'Insurance provider',
                  required: true,
                  formInputComponent: PayerSelectField,
                },
                {
                  name: 'planGroupIdentifier',
                  label: 'Group ID',
                  formInputComponent: InputField,
                },
                {
                  name: 'startDate',
                  label: 'Coverage start date',
                  inputProps: {
                    validation: {
                      setValueAs: (value: Date) => formatDateFieldValue(value),
                    },
                  },
                  formInputComponent: DatePickerField,
                },
                {
                  name: 'endDate',
                  label: 'Coverage end date',
                  inputProps: {
                    validation: {
                      setValueAs: (value: Date) => formatDateFieldValue(value),
                    },
                  },
                  formInputComponent: DatePickerField,
                },
              ]}
            />
          </Box>
        </Card>

        <Card title="Plan information">
          <Box horizontalPadding={100}>
            <FormInputList
              items={[
                {
                  name: 'planName',
                  label: 'Plan name',
                  formInputComponent: InputField,
                },
                {
                  name: 'planType',
                  label: 'Health plan type',
                  formInputComponent: HealthPlanTypeSelectField,
                },
                {
                  name: 'payerAddressId',
                  label: 'Mailing address',
                  // required: true,
                  formInputComponent: PayerAddressSelectField,
                  inputProps: {
                    id: payerId,
                  },
                },
                {
                  name: 'planWebsite',
                  label: 'Plan website',
                  formInputComponent: InputField,
                },
              ]}
            />
          </Box>
        </Card>

        <Card title="Policy holder information">
          <Box horizontalPadding={100}>
            <FormInputList
              items={[
                {
                  name: 'beneficiaryIsSubscriber',
                  label: 'Patient is policy holder',
                  inputProps: {
                    validation: {
                      validate: (beneficiaryIsSubscriber) =>
                        !beneficiaryIsSubscriber && memberCaregiverOptedOutAt
                          ? 'Patient needs a caregiver to select a policy holder. If no caregivers, the patient must either be the policy holder or opt out of insurance coverage.'
                          : true,
                    },
                  },
                  formInputComponent: CheckboxField,
                },
                {
                  name: 'subscriber.id',
                  label: 'Policyholder',
                  required: true,
                  hide: beneficiaryIsSubscriber,
                  inputProps: {
                    patientId,
                  },
                  formInputComponent: RelatedPersonSelectCell,
                },
                {
                  name: 'subscriber.birthDate',
                  label: 'Policyholder date of birth',
                  message:
                    'Please confirm that the policyholder date of birth matches the caregiver date of birth that is kept on file. If it does not, please go back and update the caregiver date of birth.',
                  required: true,
                  hide: beneficiaryIsSubscriber || !subscriber?.id,
                  inputProps: {
                    disabled: true,
                  },
                  formInputComponent: DatePickerField,
                },
                {
                  name: 'subscriber.relationshipType',
                  label: 'Relationship to patient',
                  required: true,
                  hide: beneficiaryIsSubscriber || !subscriber?.id,
                  inputProps: {
                    disabled: true,
                  },
                  formInputComponent: RelationshipTypeSelectField,
                },
              ]}
            />
          </Box>
        </Card>

        <Card title="Health insurance card">
          <Box horizontalPadding={100}>
            <FormInputList
              items={[
                {
                  name: 'insuranceCardBinary',
                  label: 'Insurance card copy',
                  formInputComponent: FileInput,
                },
              ]}
            />
          </Box>
        </Card>

        <StackView
          direction="row"
          justifyContent="end"
          className="py-4"
          space={50}
        >
          <Button buttonStyle="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Submit loading={creatingInsuranceCoverage}>Submit</Submit>
        </StackView>
      </StackView>
    </Form>
  )
}

export default CreateInsuranceForm
