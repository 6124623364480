import { useEffect, useState } from 'react'

import { isEmpty } from 'lodash'
import { useParams } from 'react-router-dom'
import { UpsertBirthHistoryInputWithUnitInputs } from 'types/graphql'

import { RegisterOptions, useForm, useFormContext } from '@redwoodjs/forms'

import { useEmrAuth } from 'src/auth'
import BloodTypeSelectField from 'src/components/atoms/BloodTypeSelectField/BloodTypeSelectField'
import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import DeliveryMethodSelectField from 'src/components/atoms/DeliveryMethodSelectField/DeliveryMethodSelectField'
import InputField from 'src/components/atoms/InputField'
import LabTestResultSelectField from 'src/components/atoms/LabTestResultSelectField/LabTestResultSelectField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import NewbornHearingScreeningResultSelectField from 'src/components/atoms/NewbornHearingScreeningResultSelectField/NewbornHearingScreeningResultSelectField'
import PresenceIndicatorSelectField from 'src/components/atoms/PresenceIndicatorSelectField/PresenceIndicatorSelectField'
import { Option } from 'src/components/atoms/Select/Select'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import FieldError from 'src/components/FieldError/FieldError'
import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import {
  getUnitOptions,
  InputFieldWithSelectableUnit,
} from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { BodyWeightInput } from 'src/components/VisitIntakeVital/BodyWeightInput'
import {
  compactObject,
  deepOmit,
  getNumericInputValidationFn,
  replaceEmptyObjectEntriesWithNull,
} from 'src/data/utils'
import {
  useBirthHistoryQuery,
  useUpsertBirthHistory,
} from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const SidepanelBirthHistoryEditWithUnitInputs = () => {
  const { patientId } = useParams()
  const { closeSidePanel } = useSidepanel()
  const { birthHistoryFormValues, hasRequiredBirthMeasurements } =
    useBirthHistoryQuery(patientId)

  const [existingFormValuesLoaded, setExistingFormValuesLoaded] =
    useState(false)

  const [upsertBirthHistory, { loading: upserting }] = useUpsertBirthHistory()

  const onSubmit = async (data: UpsertBirthHistoryInputWithUnitInputs) => {
    const input = deepOmit(
      {
        patientId,
        ...compactObject(replaceEmptyObjectEntriesWithNull(data)),
      },
      ['display', 'valueType']
    )

    void upsertBirthHistory({
      variables: { input },
      onCompleted: closeSidePanel,
    })
  }

  const formMethods = useForm<UpsertBirthHistoryInputWithUnitInputs>({
    defaultValues: birthHistoryFormValues,
  })

  useEffect(() => {
    if (existingFormValuesLoaded) return
    if (isEmpty(birthHistoryFormValues)) return

    if (!existingFormValuesLoaded) {
      formMethods.reset(birthHistoryFormValues)
      setExistingFormValuesLoaded(true)
    }
  }, [birthHistoryFormValues, formMethods, existingFormValuesLoaded])

  if (!existingFormValuesLoaded)
    return (
      <Box className="flex h-screen items-center justify-center">
        <LoadingSpinner size="m" />
      </Box>
    )
  return (
    <SidepanelPage
      testId="patient-birth-history-edit"
      header="Edit birth history"
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Update',
          submitting: upserting,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <StackView space={25}>
          <Typography textStyle="subtitle">General</Typography>
          <GeneralBirthHistoryInputs
            hasRequiredBirthMeasurements={hasRequiredBirthMeasurements}
          />
        </StackView>
        <StackView space={25}>
          <Typography textStyle="subtitle">ABO and Rh Blood Typing</Typography>
          <PatientBloodTypingInputs />
        </StackView>
        <StackView space={50}>
          <Typography textStyle="subtitle">Natural Mother</Typography>
          <NaturalMotherHistoryInputs />
          <Box verticalPadding={75}>
            <PrenatalLabInputs />
          </Box>
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export const BirthHistoryUnitInput = ({
  name,
  fieldErrorName = `${name}.value`,
  fieldErrorLabel = name,
  unitOptions,
  validation,
  ...props
}: {
  name: string
  fieldErrorName?: string
  fieldErrorLabel?: string
  unitOptions: Option[]
  validation?: RegisterOptions
}) => {
  const { register } = useFormContext()

  register(name, {
    ...validation,
  })

  return (
    <Box fullWidth>
      <InputFieldWithSelectableUnit
        {...props}
        name={name}
        unitOptions={unitOptions}
        validation={{
          required: validation?.required && validation.required,
          shouldUnregister: true,
        }}
        setValueAsString
      />
      <FieldError name={fieldErrorName} label={fieldErrorLabel} />
    </Box>
  )
}

const GeneralBirthHistoryInputs = ({
  hasRequiredBirthMeasurements,
}: {
  hasRequiredBirthMeasurements: boolean
}) => {
  return (
    <FormInputList
      items={[
        {
          name: 'gestationalAgeAtBirth',
          label: 'Gestational age at birth',
          direction: 'col',
          formInputComponent: BirthHistoryUnitInput,
          inputProps: {
            unitOptions: getUnitOptions(['wk']),
            validation: getNumericInputValidationFn(),
            description:
              'Enter the GAB in weeks to support corrected age growth chart plotting in cases where the patient is born premature',
          },
          required: hasRequiredBirthMeasurements ? true : false,
        },
        {
          name: 'birthWeight',
          label: 'Birth weight',
          direction: 'col',
          formInputComponent: BodyWeightInput,
          inputProps: {
            allowPartialInput: true,
            unitOptions: getUnitOptions(['lbs', 'g']),
          },
        },
        {
          name: 'dischargeWeight',
          label: 'Discharge weight',
          direction: 'col',
          formInputComponent: BodyWeightInput,
          inputProps: {
            allowPartialInput: true,
            unitOptions: getUnitOptions(['lbs', 'g']),
          },
        },
        {
          name: 'birthLength',
          label: 'Birth length',
          direction: 'col',
          formInputComponent: BirthHistoryUnitInput,
          inputProps: {
            unitOptions: getUnitOptions(['in', 'cm']),
          },
        },
        {
          name: 'birthHeadCircumference',
          label: 'Birth head circumference',
          direction: 'col',
          formInputComponent: BirthHistoryUnitInput,
          inputProps: {
            unitOptions: getUnitOptions(['in', 'cm']),
          },
        },
        {
          name: 'deliveryMethod',
          label: 'Delivery Method',
          direction: 'col',
          formInputComponent: DeliveryMethodSelectField,
        },
        {
          name: 'birthFacility',
          label: 'Birth facility',
          direction: 'col',
          formInputComponent: InputField,
          inputProps: {
            validation: {
              validate: (value: string) => {
                if (value && !isNaN(Number(value))) {
                  return 'Please enter at least one alphabetic character.'
                }
              },
            },
          },
        },
        {
          name: 'dischargeDate',
          label: 'Discharge date',
          direction: 'col',
          formInputComponent: DatePickerFieldThatWorks,
        },
        {
          name: 'newbornHearingScreeningResult',
          label: 'Hearing screening result',
          direction: 'col',
          formInputComponent: NewbornHearingScreeningResultSelectField,
        },

        {
          name: 'lastTotalSerumBilirubin',
          label: 'Last total serum bilirubin',
          direction: 'col',
          formInputComponent: BirthHistoryUnitInput,
          inputProps: {
            unitOptions: getUnitOptions(['mg/dL']),
          },
        },
        {
          name: 'totalSerumBilirubinDate',
          label: 'Total serum bilirubin date',
          direction: 'col',
          formInputComponent: DatePickerFieldThatWorks,
        },
      ]}
    />
  )
}

const NaturalMotherHistoryInputs = () => {
  return (
    <FormInputList
      items={[
        {
          name: 'naturalMotherHistory.ageAtPatientBirth',
          label: "Mother's age at birth",
          direction: 'col',
          formInputComponent: BirthHistoryUnitInput,
          inputProps: {
            unitOptions: getUnitOptions(['a']),
          },
        },
        {
          name: 'naturalMotherHistory.hadRoutinePrenatalCare',
          label: 'Routine prenatal care',
          direction: 'col',
          formInputComponent: PresenceIndicatorSelectField,
        },
        {
          name: 'naturalMotherHistory.hadPregnancyComplications',
          label: 'Pregnancy complications',
          direction: 'col',
          formInputComponent: PresenceIndicatorSelectField,
        },
      ]}
    />
  )
}
const PatientBloodTypingInputs = () => {
  return (
    <FormInputList
      items={[
        {
          name: 'bloodType',
          label: 'Patient blood type',
          direction: 'col',
          formInputComponent: BloodTypeSelectField,
        },
        {
          name: 'naturalMotherHistory.bloodType',
          label: 'Natural mother blood type',
          direction: 'col',
          formInputComponent: BloodTypeSelectField,
        },
        {
          name: 'naturalMotherHistory.directAntiglobulinTestResult',
          label: 'Direct antiglobulin test (DAT)',
          direction: 'col',
          formInputComponent: LabTestResultSelectField,
        },
      ]}
    />
  )
}

const PrenatalLabInputs = () => {
  const formMethods = useFormContext()
  const { currentUser } = useEmrAuth()

  return (
    <>
      <StackView direction="row" justifyContent="between">
        <Typography textStyle="subtitle">Prenatal Labs</Typography>
        <Button
          buttonStyle="link"
          onClick={() => {
            formMethods.setValue('naturalMotherHistory.prenatalLabResults', {
              streptococcusGroupB: 'NEGATIVE',
              hiv: 'NEGATIVE',
              chlamydia: 'NEGATIVE',
              gonorrhoeae: 'NEGATIVE',
              syphilis: 'NEGATIVE',
              rubella: 'NEGATIVE',
            })
          }}
        >
          Set all to negative
        </Button>
      </StackView>
      <FormInputList
        items={[
          {
            name: 'naturalMotherHistory.prenatalLabResults.streptococcusGroupB',
            label: 'Group B strep',
            direction: 'col' as const,
            formInputComponent: LabTestResultSelectField,
          },
          {
            name: 'naturalMotherHistory.prenatalLabResults.hiv',
            label: 'Human immunodeficiency virus (HIV)',
            direction: 'col' as const,
            formInputComponent: LabTestResultSelectField,
          },
          {
            name: 'naturalMotherHistory.prenatalLabResults.chlamydia',
            label: 'Chlamydia trachomatis',
            direction: 'col' as const,
            formInputComponent: LabTestResultSelectField,
          },
          {
            name: 'naturalMotherHistory.prenatalLabResults.gonorrhoeae',
            label: 'Neisseria gonorrhoeae',
            direction: 'col' as const,
            formInputComponent: LabTestResultSelectField,
          },
          {
            name: 'naturalMotherHistory.prenatalLabResults.syphilis',
            label: 'Treponema pallidum (syphilis)',
            direction: 'col' as const,
            formInputComponent: LabTestResultSelectField,
          },
          {
            name: 'naturalMotherHistory.prenatalLabResults.rubella',
            label: 'Rubella',
            direction: 'col' as const,
            formInputComponent: LabTestResultSelectField,
          },
          currentUser.featureFlags.includes('BIRTH_HISTORY_COMMENT')
            ? {
                name: 'comment',
                label: 'Comment',
                direction: 'col' as const,
                formInputComponent: WysiwygField,
                inputProps: {
                  defaultValue: formMethods.getValues('comment'),
                  testId: 'birth-history-comment-input',
                },
              }
            : null,
        ].filter(Boolean)}
      />
    </>
  )
}
export default SidepanelBirthHistoryEditWithUnitInputs
