import { SignOrders, SignOrdersVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'

const SIGN_ORDERS = gql`
  mutation SignOrders($orderIds: [String!]!, $encounterId: String) {
    signOrders(orderIds: $orderIds, encounterId: $encounterId) {
      id
      intent
    }
  }
`

const SIGN_ORDERS_V2 = gql`
  mutation SignOrdersV2($orderIds: [String!]!, $encounterId: String) {
    signOrdersV2(orderIds: $orderIds, encounterId: $encounterId) {
      id
      intent
    }
  }
`

export const useSignOrders = (orderIds: string[], encounterId: string) => {
  const { currentUser } = useEmrAuth()

  const v2Enabled = currentUser.featureFlags.includes('SIGN_ORDERS_V2')

  return useMutation<SignOrders, SignOrdersVariables>(
    v2Enabled ? SIGN_ORDERS_V2 : SIGN_ORDERS,
    {
      variables: { orderIds, encounterId },
      refetchQueries: v2Enabled
        ? undefined
        : ['FindPatientOrdersWithObservations'],
      onCompleted: v2Enabled
        ? () => {
            toast.success(
              orderIds.length === 1 ? 'Order signed' : 'Orders signed'
            )
          }
        : undefined,
    }
  )
}
