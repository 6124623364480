import { formatDisplayName } from 'common/utils'

import { get, useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { formatDateDisplay } from 'src/lib/formatters'

const RenderRelatedPersonSearchResults = ({
  onSearchResultClick,
  searchResults,
  isSearchActive,
  name,
}: {
  onSearchResultClick
  isSearchActive: boolean
  name: string
  searchResults: {
    id: string
    birthDate?: string
    namePrefix?: string
    givenName: string
    middleName?: string
    familyName: string
    nameSuffix?: string
    credentials?: string
  }[]
}) => {
  const { formState } = useFormContext()
  const { errors } = formState

  const hasError = !!get(errors, name)
  if (hasError) {
    return null
  }
  return (
    <Box
      data-testid="search-existing-caregiver-results"
      className="absolute right-0 top-10 z-10 mt-1 h-fit max-h-40 w-fit min-w-full overflow-auto rounded-md bg-white shadow-md"
    >
      <StackView direction="col">
        {isSearchActive && searchResults.length === 0 && (
          <StackView
            direction="row"
            data-testid="search-existing-caregiver-result"
            className="pointer-events-none py-2"
            onClick={onSearchResultClick}
          >
            <Typography
              className="pointer-events-none pl-3"
              textStyle="subtitle"
              color="text-base-color-fg-default"
            >
              No caregivers found
            </Typography>
          </StackView>
        )}
        {searchResults.length > 0 && (
          <>
            {searchResults?.map((relatedPerson) => {
              return (
                <Button
                  key={relatedPerson.id}
                  buttonStyle="ghost"
                  onClick={() =>
                    onSearchResultClick(relatedPerson.id, relatedPerson)
                  }
                  className="p-2 hover:bg-gray-300"
                >
                  <StackView
                    id={relatedPerson.id}
                    direction="row"
                    data-testid="search-existing-caregiver-result"
                    space={50}
                  >
                    <Typography
                      className="pointer-events-none"
                      textStyle="subtitle"
                      noWrap
                    >
                      {formatDisplayName(relatedPerson)}
                    </Typography>
                    <Typography
                      className="pointer-events-none"
                      textStyle="subtitle"
                      color="text-base-color-fg-muted"
                    >
                      {formatDateDisplay(relatedPerson.birthDate)}
                    </Typography>
                  </StackView>
                </Button>
              )
            })}
          </>
        )}
      </StackView>
    </Box>
  )
}

export default RenderRelatedPersonSearchResults
