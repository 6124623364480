import { P, match } from 'ts-pattern'
import { AppointmentType } from 'types/graphql'
import colors from 'web/tokens/color.json'

export const appointmentTypeToColor = (
  appointmentType: AppointmentType
): string =>
  match(appointmentType)
    .with('WELL_CHILD', () => colors['base-color-fg-brand'])
    .with('TELEMEDICINE_WC', () => colors['core-color-rose-30'])
    .with('EM', () => colors['core-color-yellow-40'])
    .with('EM_FOLLOW_UP', () => colors['core-color-yellow-40'])
    .with('TELEMEDICINE_EM', () => colors['core-color-rose-30'])
    .with('TELEMEDICINE_OTHER', () => colors['core-color-rose-30'])
    .with('CONSULTATION', () => colors['core-color-blue-40'])
    .with('NURSE', () => colors['core-color-teal-40'])
    .with('LAB', () => colors['core-color-orange-30'])
    .with('PHONE', () => colors['core-color-neutral-10'])
    .with('PROCEDURE', () => colors['core-color-cyan-20'])
    .with('FORM', () => colors['core-color-red-20'])
    .with('ADOLESCENT', () => colors['core-color-blue-0'])
    .with(P.nullish, () => undefined)
    .exhaustive()
