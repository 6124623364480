import { relationshipTypes } from 'common/data/relationshipTypes'
import { RelationshipType } from 'types/graphql'

import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'

type RelationshipTypeSelectFieldProps = RWSelectFieldProps & {
  filterOptions?: RelationshipTypeOption[]
}

interface RelationshipTypeOption {
  name: string
  value: RelationshipType
  display: string
}

export const RelationshipTypeSelectField = (
  props: RelationshipTypeSelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      className="disabled:bg-slate-50"
      emptyAs={null}
      options={
        props.filterOptions
          ? props.filterOptions
          : relationshipTypes.map(({ name, value }) => ({
              value,
              display: name,
            }))
      }
    />
  )
}

export default RelationshipTypeSelectField
