import { useRef, useEffect } from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import difference from 'lodash/difference'
import { PayerQuery } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { formatAddress } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

import { DropdownField, DropdownFieldProps } from '../Dropdown'

export type PayerAddressSelectFieldProps = DropdownFieldProps & {
  id?: string
}

const QUERY = gql`
  query PayerQuery($id: String!) {
    payer(id: $id) {
      id
      addresses {
        id
        purpose
        line1
        line2
        city
        country
        district
        postalCode
        state
        zipPlusFour
      }
    }
  }
`

const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const PayerAddressSelectField = ({
  id,
  name,
  ...props
}: PayerAddressSelectFieldProps) => {
  const params = useParams()
  const location = useLocation()
  const { data } = useQuery<PayerQuery>(QUERY, {
    variables: { id },
    skip: !id,
  })
  const { setValue } = useFormContext()

  const noDataLoaded = !id || !data?.payer?.addresses
  const prevAddresses = usePrevious(data?.payer?.addresses ?? [])

  const options = noDataLoaded
    ? []
    : data.payer.addresses.map((address) => ({
        value: address.id,
        name: `${address.purpose} | ${formatAddress(address)}`,
      }))

  useEffect(() => {
    if (!noDataLoaded && !!prevAddresses?.length) {
      const diff = difference(data.payer.addresses, prevAddresses)
      if (diff.length == 1) {
        setValue(name, diff[0].id)
      }
    }
  }, [name, data?.payer?.addresses, prevAddresses, setValue, noDataLoaded])

  return (
    <DropdownField
      {...props}
      name={name}
      options={options}
      actionOption={{
        value: 'create-new',
        name: 'Add new mailing address',
        icon: {
          value: <PlusIcon className="h-5 w-5" />,
          class: '',
        },
        onSelect: () => {
          navigate(
            sidepanelRoute(
              {
                route: `/create-payer-address/${id}`,
              },
              location,
              params
            )
          )
        },
      }}
    />
  )
}

export default PayerAddressSelectField
