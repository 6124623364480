import { formatDisplayName } from 'common/utils'
import { PatientDemographicsFragment } from 'types/graphql'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

import CaregiverAdditionalDemographicsCard from './CaregiverAdditionalDemographicsCard'
import CaregiverContactInformationCard from './CaregiverContactInformationCard'
import CaregiverCoreDemographicsCard from './CaregiverCoreDemographicsCard'
import CaregiverRelationshipCard from './CaregiverRelationshipCard'

const ExistingCaregiverSection: React.FC<{
  patient: PatientDemographicsFragment
  relationship: PatientDemographicsFragment['patientRelatedPersonRelationships'][0]
  onRemove: () => void
}> = ({ patient, relationship, onRemove }) => {
  return (
    <>
      <StackView direction="row" justifyContent="between" className="py-4">
        <Typography textStyle="heading">
          {formatDisplayName(relationship.relatedPerson)}
        </Typography>
        <Button
          buttonStyle="secondary"
          onClick={onRemove}
          disabled={
            relationship.doesResideWith ||
            relationship.relatedPerson.id === patient.primaryGuardianId ||
            relationship.isGuarantor ||
            relationship.relatedPerson.id === patient.primaryGuarantorId
          }
        >
          Remove
        </Button>
      </StackView>
      <StackView space={75}>
        <CaregiverRelationshipCard relationship={relationship} />

        <Typography textStyle="title" className="py-4">
          Contact
        </Typography>
        <CaregiverContactInformationCard relationship={relationship} />

        <Typography textStyle="title" className="py-4">
          Demographics
        </Typography>
        <CaregiverCoreDemographicsCard relationship={relationship} />
        <CaregiverAdditionalDemographicsCard relationship={relationship} />
      </StackView>
    </>
  )
}

export default ExistingCaregiverSection
