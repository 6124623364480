import {
  DependencyList,
  EffectCallback,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import debounce from 'lodash/debounce'

/**
 * Context object accepts a displayName string property.
 * React DevTools uses this string to determine what to display for the context.
 *
 * reference: https://reactjs.org/docs/context.html#contextdisplayname
 */
export const createNamedContext = <T>(name: string, defaultValue?: T) => {
  const NamedContext = createContext<T | undefined>(defaultValue)
  NamedContext.displayName = name
  return NamedContext
}

export const base64EncodeFile = (file: Blob) => {
  return new Promise<string>((resolve, reject) => {
    const fr = new FileReader()
    fr.onerror = reject
    fr.onload = () => {
      const base64String = window.btoa(fr.result as string)
      resolve(base64String)
    }
    fr.readAsBinaryString(file)
  })
}

export const convertImageSrcToBase64 = (src: string): Promise<string> => {
  return new Promise((resolve) => {
    const img = new Image()
    img.crossOrigin = 'Anonymous'
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.height = img.naturalHeight
      canvas.width = img.naturalWidth
      ctx.drawImage(img, 0, 0)
      const dataURL = canvas.toDataURL()
      resolve(dataURL)
    }

    img.src = src
  })
}

export type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object
    ? Subset<K[attr]>
    : K[attr] extends object | null
      ? Subset<K[attr]> | null
      : K[attr] extends object | null | undefined
        ? Subset<K[attr]> | null | undefined
        : K[attr]
}

export type ValueOf<T> = T[keyof T]

export const useDebouncedCallback = <T extends () => void>(
  callback: T,
  delay: number
) => {
  const ref = useRef<T>()

  ref.current = callback

  const doCallbackWithDebounce = useMemo(() => {
    const callback = () => ref.current?.()
    return debounce(callback, delay)
  }, [delay])

  return doCallbackWithDebounce
}

const Store = new Map<string, unknown>()
export const usePersistedState = <S>(
  id: string,
  initialState: S | (() => S)
) => {
  const stateFromStore = Store.has(id) ? (Store.get(id) as S) : undefined
  const [state, dispatch] = useState<S>(stateFromStore ?? initialState)
  useEffect(() => {
    Store.set(id, state)
  }, [state, id])
  return [state, dispatch] as const
}

export const stringToHash = (str: string): number =>
  str.split('').reduce((hash, char) => {
    return char.charCodeAt(0) + (hash << 6) + (hash << 16) - hash
  }, 0)

export const openNewTab = (url) => window.open(url, '_blank')?.focus()

/**
 * Custom hook for determining if the component is rendering for the first time.
 * @returns {boolean} A boolean value indicating whether the component is rendering for the first time.
 * @example
 * const isFirstRender = useIsFirstRender();
 * // Use isFirstRender to conditionally execute code only on the initial render.
 */
export function useIsFirstRender(): boolean {
  const isFirst = useRef(true)

  if (isFirst.current) {
    isFirst.current = false

    return true
  }

  return isFirst.current
}

/**
 * A hook that runs an effect only once (at mount).
 * @param {EffectCallback} effect - The effect to run.
 * @example
 * useEffectOnce(() => {
 *   console.log('Hello World');
 * });
 */
export const useEffectOnce = (effect: EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, [])
}

/**
 * Custom hook that runs an effect only on updates (not on the initial render).
 * @param {EffectCallback} effect - The function to run as the effect.
 * @param {DependencyList} [deps] - An optional array of dependencies for the effect.
 * @example
 * // Usage of useUpdateEffect hook
 * useUpdateEffect(() => {
 *   // Effect code to run on updates
 *   console.log('Component updated!');
 * }, [dependency1, dependency2]);
 */
export const useUpdateEffect = (
  effect: EffectCallback,
  deps?: DependencyList
) => {
  const isFirst = useIsFirstRender()

  useEffect(() => {
    if (!isFirst) {
      return effect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}

export const isDev = process.env.NODE_ENV === 'development'

export const isCI = process.env.CI === 'true'
