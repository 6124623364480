import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'

interface BillingSectionHeaderProps {
  title: string
  subtitle: string
}

const BillingSectionHeader: React.FC<
  React.PropsWithChildren<BillingSectionHeaderProps>
> = ({ title, subtitle, children }) => (
  <StackView
    direction="row"
    justifyContent="between"
    alignItems="center"
    space={100}
    className="px-4"
  >
    <StackView space={50}>
      <Typography textStyle="title" fontWeight="semibold">
        {title}
      </Typography>
      <Typography textStyle="body" color="text-base-color-fg-muted">
        {subtitle}
      </Typography>
    </StackView>
    {children}
  </StackView>
)

export default BillingSectionHeader
