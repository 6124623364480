import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { pick, isEmpty } from 'lodash'
import type {
  FindPatientFormQuery,
  FindPatientFormQueryVariables,
  PatientFormType,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField/InputField'
import PatientFormTypeSelectField from 'src/components/atoms/PatientFormTypeSelectField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { QUERY as FIND_PATIENT_FORMS_QUERY } from 'src/components/PatientFormCell'
import { patientFormTypeDisplay } from 'src/data/patientFormTypes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

import SidepanelForm from '../molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from '../molecules/SidepanelPage/SidepanelPage'

export const QUERY = FIND_PATIENT_FORMS_QUERY

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPatientFormQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

const UPDATE_PATIENT_FORM_MUTATION = gql`
  mutation UpdatePatientForm($id: String!, $input: UpdatePatientFormInput!) {
    updatePatientForm(id: $id, input: $input) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

type FormData = {
  type?: PatientFormType
  title?: string
  /**
   * A list of files (max length 1) or undefined. Useful for the user to upload a single file.
   *
   * This matches the type used by `FileInput`
   */
  files?: FileList
}

export const Success = ({
  patientForm,
}: CellSuccessProps<FindPatientFormQuery, FindPatientFormQueryVariables>) => {
  const { closeSidePanel } = useSidepanel()
  const [updatePatientForm, { loading: updatingPatientForm }] = useMutation(
    UPDATE_PATIENT_FORM_MUTATION,
    {
      onCompleted: () => {
        toast.success(`Patient form updated`)
        closeSidePanel()
      },
      refetchQueries: ['PatientFormsQuery'],
    }
  )
  const formMethods = useForm<FormData>({
    defaultValues: pick(patientForm, 'type', 'title'),
    shouldUnregister: true,
  })
  const {
    formState: { dirtyFields },
  } = formMethods
  const type = formMethods.watch('type')

  const hasDirtyFields = !isEmpty(dirtyFields)

  const onSubmit = async (value: FormData) => {
    const dirtyValues: FormData = Object.fromEntries(
      Object.entries(value).filter(([key, _]) => dirtyFields[key])
    )
    const { files, ...input } = dirtyValues
    if (files !== undefined && files.length === 1) {
      input['binary'] = {
        content: await base64EncodeFile(files[0]),
        contentType: files[0].type,
      }
    }

    updatePatientForm({
      variables: { input, id: patientForm.id },
    })
  }

  return (
    <SidepanelPage
      testId="patient-form-edit"
      header={`Patient form: ${patientFormTypeDisplay[patientForm.type]}`}
      subHeader={patientForm.title}
      description={`Last modified on ${format(
        new Date(patientForm.updatedAt),
        'PP'
      )} · Last modified by ${formatDisplayName(patientForm.updatedBy)}`}
    >
      <SidepanelForm
        footerProps={{
          disabled: !hasDirtyFields,
          submitText: 'Save',
          submitting: updatingPatientForm,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'type',
              label: 'Document sub-type',
              formInputComponent: PatientFormTypeSelectField,
            },
            {
              name: 'title',
              label: 'Document title',
              formInputComponent: InputField,
              required: type === 'OTHER',
            },

            {
              name: 'files',
              label: 'Document upload',
              formInputComponent: FileInput,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}
