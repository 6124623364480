import Button from 'src/components/atoms/Button/Button'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'

import BillingSectionHeader from './BillingSectionHeader'
import BillingTable from './BillingTable'
import useBillingSidepanels from './useBillingSidepanels'

const AdditionalBillingSection = ({
  encounterId,
  chargeItems,
  primaryDiagnosis,
  isEditable,
}) => {
  const { addAdditionalBillingCode } = useBillingSidepanels({ encounterId })

  return (
    <StackView space={100}>
      <BillingSectionHeader
        title="Additional billing codes"
        subtitle="Add, remove, and edit additional billing code(s) for the visit that were not brought in directly by completed orders, and are not a visit type billing code for the visit. Units and modifiers for each billing code can be adjusted, and linkages to existing visit diagnosis codes can be set up and modified."
      >
        {isEditable && (
          <Button
            buttonStyle="secondary"
            text="+ Add billing code"
            onClick={addAdditionalBillingCode}
          />
        )}
      </BillingSectionHeader>
      <Divider />
      <BillingTable
        encounterId={encounterId}
        testId="additional-billing-table"
        chargeItems={chargeItems}
        primaryDiagnosis={primaryDiagnosis}
        isEditable={isEditable}
      />
    </StackView>
  )
}

export default AdditionalBillingSection
