import { isEmpty } from 'lodash'

import StackView from 'src/components/atoms/StackView'
import { interpretationDisplay } from 'src/data/interpretations'
import { emptyPlaceholder } from 'src/lib/formatters'
import { useBirthHistoryQuery } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'
import { ColumnDisplay } from 'src/pages/PatientChartsPage/PatientHistory/ColumnDisplay'

export const DeliverySection = ({ patientId }: { patientId: string }) => {
  const { birthHistory } = useBirthHistoryQuery(patientId)

  if (isEmpty(birthHistory)) return null
  const { birthFacility, newbornHearingScreeningResult, deliveryMethod } =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    birthHistory as any

  return (
    <StackView
      testId="birth-history-delivery-section"
      className="w-1/5"
      space={75}
    >
      <ColumnDisplay label="Delivery method" entry={deliveryMethod} />
      <ColumnDisplay label="Birth facility" entry={birthFacility} />
      <ColumnDisplay
        label="Hearing screen result"
        entry={{
          display:
            interpretationDisplay[newbornHearingScreeningResult?.value] ??
            emptyPlaceholder,
        }}
      />
    </StackView>
  )
}
