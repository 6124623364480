import type { UpdatePatientDemographicsInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import EthnicitySelectField from 'src/components/atoms/EthnicitySelectField/EthnicitySelectField'
import InputField from 'src/components/atoms/InputField'
import PreferredPronounsSelectField from 'src/components/atoms/PreferredPronounSelectField/PreferredPronounsSelectField'
import RaceSelectField from 'src/components/atoms/RaceSelectField/RaceSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import { ethnicityDisplay } from 'src/data/ethnicities'
import { preferredPronounDisplay } from 'src/data/preferredPronouns'
import { raceDisplay } from 'src/data/races'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext/AppointmentCheckInContext'
import { VERIFY_PATIENT_ATTRIBUTE_MUTATION } from 'src/providers/context/AppointmentCheckInContext/schema'

export const UPDATE_PATIENT_ADDITIONAL_DEMOGRAPHICS = gql`
  mutation updatePatientAdditionalDemographicsMutation(
    $id: String!
    $input: UpdatePatientDemographicsInput!
  ) {
    updatePatientDemographics(id: $id, input: $input) {
      id
      updatedAt
      additionalDemographicsVerifiedAt
      preferredPronouns
      race
      ethnicity
      socialSecurityNumber
    }
  }
`

export const PatientAdditionalDemographicsVerificationCard = () => {
  const [verifyPatientAdditionalDemographics] = useMutation(
    VERIFY_PATIENT_ATTRIBUTE_MUTATION
  )

  const {
    appointmentData: { patient },
    checkInVerification,
  } = useAppointmentCheckIn()

  const { verificationRequirement, lastVerifiedAt } =
    checkInVerification['PATIENT']['ADDITIONAL_DEMOGRAPHICS']

  const onVerify = () => {
    verifyPatientAdditionalDemographics({
      variables: {
        patientId: patient.id,
        verificationType: 'ADDITIONAL_DEMOGRAPHICS',
      },
    })
  }

  const preSubmitAdditionalDemographics = (
    data: UpdatePatientDemographicsInput
  ) => {
    const input = {
      ...data,
    }

    return { variables: { id: patient.id, input } }
  }

  return (
    <DataVerificationCard<UpdatePatientDemographicsInput>
      title="Additional demographics"
      updateMutation={UPDATE_PATIENT_ADDITIONAL_DEMOGRAPHICS}
      verificationOptions={{
        onVerify,
        verificationRequirement,
      }}
      preSubmit={preSubmitAdditionalDemographics}
      lastVerifiedAt={lastVerifiedAt}
      data={[
        {
          name: 'preferredPronouns',
          label: 'Preferred pronouns',
          value: patient.preferredPronouns,
          displayValue: preferredPronounDisplay[patient.preferredPronouns],
          formInputComponent: PreferredPronounsSelectField,
        },
        {
          name: 'race',
          label: 'Race',
          value: patient.race,
          displayValue: raceDisplay[patient.race],
          formInputComponent: RaceSelectField,
        },
        {
          name: 'ethnicity',
          label: 'Ethnicity',
          value: patient.ethnicity,
          displayValue: ethnicityDisplay[patient.ethnicity],
          formInputComponent: EthnicitySelectField,
        },
        {
          name: 'socialSecurityNumber',
          label: 'Social security number',
          value: patient.socialSecurityNumber,
          displayValue: patient.socialSecurityNumber,
          formInputComponent: InputField,
        },
      ]}
    />
  )
}

export default PatientAdditionalDemographicsVerificationCard
