import {
  UpdatePatientMembershipPlanPaymentMethod,
  UpdatePatientMembershipPlanPaymentMethodVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
const UPDATE_PAYMENT_METHOD = gql`
  mutation UpdatePatientMembershipPlanPaymentMethod(
    $input: UpdateMembershipPlanPaymentMethodInput!
  ) {
    updatePatientMembershipPlanPaymentMethod(input: $input) {
      id
      tilledPaymentMethodId
      tilledPaymentMethod {
        id
        brand
        holderName
        last4
        expirationMonth
        expirationYear
      }
    }
  }
`

export const useUpdatePatientMembershipPlanPaymentMethod = () =>
  useMutation<
    UpdatePatientMembershipPlanPaymentMethod,
    UpdatePatientMembershipPlanPaymentMethodVariables
  >(UPDATE_PAYMENT_METHOD)
