import {
  BellAlertIcon,
  CheckCircleIcon,
  ClockIcon,
} from '@heroicons/react/24/solid'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import clsx from 'clsx'
import { displayAppointmentTimeRange } from 'common/data/appointments'
import { appointmentTypeDisplay } from 'common/data/appointmentTypes'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { differenceInMinutes, format, parseISO } from 'date-fns'
import { isEmpty, sortBy } from 'lodash'
import { match } from 'ts-pattern'
import {
  FindPastEncountersQuery,
  FindPractitionerScheduleQuery,
  RecentlyViewedPatientsQuery,
  FoundPatientDetails,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Badge from 'src/components/atoms/Badge/Badge'
import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { PatientStatusBadge } from 'src/components/molecules/PatientStatusBadge/PatientStatusBadge'
import { PatientMembershipCheckmark } from 'src/components/PatientMembershipCheckmark/PatientMembershipCheckmark'
import { useStartVisitWarningContext } from 'src/components/StartVisitWarningModals/StartVisitWarningModals'
import {
  appointmentStatusConfiguration,
  isAppointmentCancelled,
} from 'src/data/appointmentStatus'
import {
  chartingStatusColors,
  chartingStatusDisplay,
} from 'src/data/chartingStatus'
import { insuranceOptOutStatusDisplay } from 'src/data/insuranceOptOutStatuses'
import { sexDisplay } from 'src/data/sexes'
import {
  formatAge,
  formatDateDisplay,
  formatContactInformationPhoneNumbers,
} from 'src/lib/formatters'
import { useEffectOnce } from 'src/utils'
import {
  InsuranceCoverageLineItem,
  sortInsuranceCoverages,
} from 'src/utils/insuranceCoverages'

import { useStartVisit } from './useAppointmentSchedule'

export const ConfirmationStatusColumn = {
  colId: 'confirmationStatus',
  headerName: '',
  maxWidth: 30,
  cellStyle: { justifyContent: 'center' },
  cellRenderer: ({ data }) => {
    return (
      <StackView
        className="h-full gap-1"
        justifyContent="center"
        alignItems="end"
      >
        {data.confirmedAt ? (
          <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-success" />
        ) : (
          <CheckCircleIcon className="h-5 w-5 flex-shrink-0 text-gray-300" />
        )}
      </StackView>
    )
  },
}

export const PatientNameColumn: ColDef = {
  colId: 'patientName',
  headerName: 'Full name & sex',
  wrapText: false,
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    const patient = data.isPatientRegistered
      ? data.patient
      : data.patientRegistrationIntent

    return (
      <StackView
        className="h-full"
        justifyContent="center"
        data-testid="name-cell"
      >
        <StackView direction="row" gap={50} alignItems="center">
          <Typography className="whitespace-normal">
            {formatDisplayName(patient, { showPreferredName: true })}
          </Typography>

          {data.patient ? (
            <Box className="flex-0">
              <PatientMembershipCheckmark
                patient={data.patient}
                className="w-base-size-icon h-base-size-icon-m"
              />
            </Box>
          ) : null}
        </StackView>
        <StackView direction="row" gap={25} className="flex-wrap">
          <Typography size="xs" color="text-base-color-fg-muted">
            {sexDisplay[patient.sexAtBirth]}
          </Typography>
          {data.isFirstAppointmentForPatient && (
            <>
              <Typography
                size="xs"
                color="text-base-color-fg-muted"
                fontWeight="medium"
              >
                •
              </Typography>
              <Typography
                size="xs"
                color="text-base-color-fg-brand"
                fontWeight="medium"
              >
                New patient
              </Typography>
            </>
          )}
        </StackView>
      </StackView>
    )
  },
}

export const PatientAgeColumn = {
  colId: 'age',
  headerName: 'Age & DOB',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    const patient = data.isPatientRegistered
      ? data.patient
      : data.patientRegistrationIntent

    return (
      <StackView className="h-full" justifyContent="center">
        <Typography>{formatAge(patient.birthDate)}</Typography>
        <Typography size="xs" color="text-base-color-fg-muted">
          {formatDateDisplay(patient.birthDate)}
        </Typography>
      </StackView>
    )
  },
}

export const VisitTypeAndChiefComplaintColumn = {
  colId: 'visitTypeAndChiefComplaint',
  headerName: 'Visit type & chief complaint',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    return (
      <StackView>
        <Typography>
          {data.appointmentDefinitions
            .map(({ type }) => appointmentTypeDisplay[type])
            .join(', ')}
        </Typography>
        <Typography>{`${data.chiefComplaints
          .map((complaint) => chiefComplaintDisplay[complaint])
          .join(', ')}`}</Typography>
      </StackView>
    )
  },
}

export const VisitDateAndTimeColumn = {
  colId: 'dateTime',
  headerName: 'Date & time',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    return (
      <StackView>
        <Typography>{format(parseISO(data.start), 'MM/dd/yyyy')}</Typography>
        <Typography
          size="xs"
          color="text-base-color-fg-muted"
        >{`${displayAppointmentTimeRange(data)}`}</Typography>
      </StackView>
    )
  },
}

export const VisitTimeAndLocationColumn = {
  colId: 'visitTimeAndLocation',
  headerName: 'Visit time & location',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    return (
      <StackView className="h-full" justifyContent="center">
        <Typography
          className={clsx(
            isAppointmentCancelled(data.status) && 'line-through',
            'whitespace-normal'
          )}
        >
          {`${displayAppointmentTimeRange(data)}`}
        </Typography>
        <Typography
          size="xs"
          color="text-base-color-fg-muted"
          className={clsx(data.status === 'CANCELLED' && 'line-through')}
        >
          {data.location.name}
        </Typography>
      </StackView>
    )
  },
}

export const VisitLocationColumn = {
  colId: 'location',
  headerName: 'Location',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    return <Typography>{data.location.name}</Typography>
  },
}

const VisitStatusRenderer = ({
  data,
}: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
  const [, updateState] = React.useState<object>()
  const forceUpdate = React.useCallback(() => updateState({}), [])
  useEffectOnce(() => {
    const t = setInterval(() => {
      forceUpdate()
    }, 60 * 1000)
    return () => {
      if (t) {
        clearInterval(t)
      }
    }
  })
  const shouldShowTimeAlert = [
    'ARRIVED',
    'CHECKED_IN',
    'WAITING_FOR_RN',
    'WAITING_FOR_MA',
    'WAITING_FOR_LABS',
    'WAITING_FOR_PRACTITIONER',
    'WAITING_FOR_VACCINATION',
    'POST_IMMUNIZATION_WAIT',
    'SEEN',
    'READY_TO_BE_CHECKED_OUT',
  ].includes(data.status)
  const isCheckedOut = data.status === 'CHECKED_OUT'
  const minutes = differenceInMinutes(
    new Date(),
    new Date(data.statusUpdatedAt)
  )
  return (
    <StackView
      justifyContent="center"
      alignItems="start"
      className="h-full"
      space={25}
    >
      <Badge
        size="s"
        text={appointmentStatusConfiguration[data.status].display}
        color={appointmentStatusConfiguration[data.status].badgeColor}
      />
      {isCheckedOut && (
        <Badge
          icon={ClockIcon}
          text={format(new Date(data.statusUpdatedAt), 'HH:mm')}
        />
      )}
      {shouldShowTimeAlert && (
        <Badge
          color={minutes >= 15 ? 'red' : 'dark-gray'}
          icon={BellAlertIcon}
          text={`${minutes} min`}
        />
      )}
    </StackView>
  )
}

export const VisitStatusColumn = {
  colId: 'visitStatus',
  headerName: 'Visit status',
  cellRenderer: VisitStatusRenderer,
}

export const VisitPractitionerColumn = {
  colId: 'visitPractitioner',
  headerName: 'Visit practitioner',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    return <Typography>{formatDisplayName(data.practitioner)}</Typography>
  },
}

export const HealthPlanColumn: ColDef = {
  colId: 'healthPlan',
  headerName: 'Health plan',
  cellClass: 'truncate',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    if (data.patient?.insuranceOptOutStatus) {
      return (
        <Typography className="whitespace-normal">
          {insuranceOptOutStatusDisplay[data.patient.insuranceOptOutStatus]}
        </Typography>
      )
    }

    // find coverageInfo depending on if patient is registered yet
    const coverageInfo = data.patient
      ? sortInsuranceCoverages(data.patient.activeInsuranceCoverages)
      : [data.patientRegistrationIntent].filter(
          (registrationIntent) => registrationIntent?.payer
        )

    return (
      <StackView space={50} className="w-full">
        {coverageInfo.map((c) => (
          <InsuranceCoverageLineItem
            key={c.id}
            coverage={c}
            alignItems="center"
          />
        ))}
      </StackView>
    )
  },
}

export const ChartingStatusColumn = {
  colId: 'chartingStatus',
  headerName: 'Note status',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    if (!data.chartingStatus || data.chartingStatus === 'NA') return null

    return (
      <StackView className="h-full" justifyContent="center" alignItems="start">
        <Badge
          text={chartingStatusDisplay[data.chartingStatus]}
          color={chartingStatusColors[data.chartingStatus]}
        />
      </StackView>
    )
  },
}

export const ClaimStatusColumn = {
  colId: 'claimStatus',
  headerName: 'Claim status',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    return match(data.encounterMode)
      .with('SIMPLIFIED', 'DIRECT_PRIMARY_CARE', () => (
        <StackView
          className="h-full"
          justifyContent="center"
          alignItems="start"
        >
          <Badge text="N/A" color="dark-gray" testId="claim-status-badge" />
        </StackView>
      ))
      .with('STANDARD', () => {
        if (data.claim) {
          return (
            <StackView
              className="h-full"
              justifyContent="center"
              alignItems="start"
            >
              <Badge
                text="Submitted"
                color="green"
                testId="claim-status-badge"
              />
            </StackView>
          )
        }

        if (
          data.chartingStatus === 'OPEN' ||
          data.chartingStatus === 'COMPLETE'
        ) {
          return (
            <StackView
              className="h-full"
              justifyContent="center"
              alignItems="start"
            >
              <Badge text="Open" color="yellow" testId="claim-status-badge" />
            </StackView>
          )
        }

        return null
      })
      .exhaustive()
  },
}

export const VisitCommentColumn = {
  colId: 'visitComment',
  headerName: 'Visit comment',
  cellClass: 'cursor-pointer',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    return (
      <Typography className="whitespace-pre-wrap">
        {data.visitComment}
      </Typography>
    )
  },
}

export const RoomNumberColumn: ColDef = {
  colId: 'roomNumber',
  headerName: 'RM',
  maxWidth: 100,
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    return <Typography>{data.room?.name || '-'}</Typography>
  },
}

export const VisitActionColumn = {
  colId: 'action',
  headerName: 'Action',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPractitionerScheduleQuery['appointments'][0]>) => {
    // eslint can't tell that this is a component, and doesn't like using hooks outside of them
    const { checkForStartVisitWarnings, isCheckingForWarnings } =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useStartVisitWarningContext()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [startVisit, { loading: startingVisit }] = useStartVisit()

    if (data.chartingStatus === 'NA') {
      return (
        <Button
          text="Start visit"
          buttonStyle="secondary"
          loading={startingVisit || isCheckingForWarnings}
          disabled={startingVisit || isCheckingForWarnings}
          innerRef={(ref) => {
            if (!ref) return

            ref.onclick = async (e) => {
              e.stopPropagation()

              const { confirmed } = await checkForStartVisitWarnings({
                appointmentId: data.id,
              })

              if (!confirmed) return

              await startVisit({
                variables: { id: data.id },
                onCompleted: () => {
                  navigate(
                    routes.patientChartsGlob({
                      id: data.patient.id,
                      glob: `visits/${
                        data.id
                      }/${data.mostRecentVistFlowTab.toLowerCase()}`,
                    })
                  )
                },
              })
            }
          }}
        />
      )
    }

    return (
      <Button
        text="Enter visit"
        innerRef={(ref) => {
          if (!ref) return

          ref.onclick = async (e) => {
            e.stopPropagation()
            navigate(
              routes.patientChartsGlob({
                id: data.patient.id,
                glob: `visits/${
                  data.id
                }/${data.mostRecentVistFlowTab.toLowerCase()}`,
              })
            )
          }
        }}
      />
    )
  },
}

export const EncounterDateAndTimeColumn = {
  colId: 'dateTime',
  headerName: 'Date & time',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPastEncountersQuery['appointments'][0]>) => {
    return (
      <StackView>
        <Typography>{format(parseISO(data.start), 'MM/dd/yyyy')}</Typography>
        <Typography
          size="xs"
          color="text-base-color-fg-muted"
        >{`${displayAppointmentTimeRange(data)}`}</Typography>
      </StackView>
    )
  },
}

export const EncounterPractitionerColumn = {
  colId: 'visitPractitioner',
  headerName: 'Visit practitioner',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPastEncountersQuery['appointments'][0]>) => {
    return <Typography>{formatDisplayName(data.practitioner)}</Typography>
  },
}

export const EncounterVisitTypeAndChiefComplaintColumn = {
  colId: 'visitTypeAndChiefComplaint',
  headerName: 'Visit type & CC',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPastEncountersQuery['appointments'][0]>) => {
    return (
      <StackView>
        <Typography>
          {data.appointmentDefinitions
            .map(({ type }) => appointmentTypeDisplay[type])
            .join(', ')}
        </Typography>
        <Typography
          size="xs"
          color="text-base-color-fg-muted"
        >{`${data.chiefComplaints
          .map((complaint) => chiefComplaintDisplay[complaint])
          .join(', ')}`}</Typography>
      </StackView>
    )
  },
}

export const EncounterPrimaryDiagnosisColumn = {
  colId: 'primaryVisitDiagnosis',
  headerName: 'Primary visit diagnosis',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPastEncountersQuery['appointments'][0]>) => {
    if (!data.encounter || !data.encounter.primaryDiagnosis) {
      return null
    }

    return (
      <Typography>
        {`${data.encounter.primaryDiagnosis.code.code} - ${data.encounter.primaryDiagnosis.code.display}`}
      </Typography>
    )
  },
}

export const EncounterAdditionalDiagnosisColumn = {
  colId: 'additionalVisitDiagnosis',
  headerName: 'Additional visit diagnosis',
  cellRenderer: ({
    data,
  }: ICellRendererParams<FindPastEncountersQuery['appointments'][0]>) => {
    if (!data.encounter || isEmpty(data.encounter.additionalDiagnoses)) {
      return null
    }

    return (
      <StackView>
        {data.encounter.additionalDiagnoses.map((diagnosis) => {
          return (
            <Typography
              key={diagnosis.code.code}
            >{`${diagnosis.code.code} - ${diagnosis.code.display}`}</Typography>
          )
        })}
      </StackView>
    )
  },
}

const AddendButton = ({ data }) => {
  const { hasRole } = useEmrAuth()

  if (!hasRole('PRACTITIONER')) return null
  return (
    <Button
      buttonStyle="secondary"
      text="Addend"
      innerRef={(ref) => {
        if (!ref) return

        ref.onclick = async (e) => {
          e.stopPropagation()
          navigate(
            routes.patientChartsGlob({
              id: data.patient.id,
              glob: `visits/${data.id}/intake`,
            })
          )
        }
      }}
    />
  )
}

const ReviewBillingButton = ({ data }) => {
  const { hasRole } = useEmrAuth()

  if (!hasRole('CHARGE_CAPTURE')) return null
  return (
    <Button
      buttonStyle={data.claim ? 'secondary' : 'primary'}
      text={data.claim ? 'View billing' : 'Finish billing'}
      innerRef={(ref) => {
        if (!ref) return

        ref.onclick = async (e) => {
          e.stopPropagation()
          navigate(
            routes.patientChartsGlob({
              id: data.patient.id,
              glob: `visits/${data.id}/billing`,
            })
          )
        }
      }}
    />
  )
}

export const EncounterActionColumn = {
  colId: 'action',
  headerName: 'Action',
  cellRenderer: ({ data }) => {
    return (
      <StackView direction="row" gap={50}>
        <AddendButton data={data} />
        <ReviewBillingButton data={data} />
      </StackView>
    )
  },
}

export const PatientChartIndexNameColumn = {
  colId: 'patientName',
  headerName: 'Patient name',
  cellRenderer: ({
    data,
  }: ICellRendererParams<
    | FoundPatientDetails['patients'][0]
    | RecentlyViewedPatientsQuery['recentlyViewedPatients'][0]
  >) => {
    return (
      <StackView>
        <Typography>{formatDisplayName(data)}</Typography>
        <Typography color="text-base-color-fg-muted" size="xs">
          {sexDisplay[data.sexAtBirth]}
        </Typography>
      </StackView>
    )
  },
}

export const PatientChartIndexAgeColumn = {
  colId: 'age',
  headerName: 'Age',
  cellRenderer: ({
    data,
  }: ICellRendererParams<
    | FoundPatientDetails['patients'][0]
    | RecentlyViewedPatientsQuery['recentlyViewedPatients'][0]
  >) => {
    return (
      <StackView className="h-full" justifyContent="center">
        <Typography>{formatAge(data.birthDate)}</Typography>
        <Typography size="xs" color="text-base-color-fg-muted">
          {formatDateDisplay(data.birthDate)}
        </Typography>
      </StackView>
    )
  },
}

export const PatientChartIndexGuardiansColumn = {
  colId: 'guardians',
  headerName: 'Guardian(s)',
  minWidth: 325,
  cellRenderer: ({
    data,
  }: ICellRendererParams<
    | FoundPatientDetails['patients'][0]
    | RecentlyViewedPatientsQuery['recentlyViewedPatients'][0]
  >) => {
    const guardians = data.patientRelatedPersonRelationships.filter(
      (relationship) => {
        return relationship.guardianshipType !== 'NON_GUARDIAN'
      }
    )

    return (
      <StackView space={75}>
        {sortBy(guardians, ['isGuarantor']) // false, false, false, true
          .reverse() // true, false, false, false
          .map((relationship) => {
            return (
              <StackView key={relationship.id}>
                <Typography>
                  {`${formatDisplayName(relationship.relatedPerson)} (${
                    relationshipTypeDisplay[relationship.relationshipType]
                  }${relationship.isGuarantor ? ' | Guarantor' : ''})`}
                </Typography>
                <Typography size="xs" color="text-base-color-fg-muted">
                  {formatContactInformationPhoneNumbers(
                    relationship.relatedPerson.contactInformation
                  )}
                </Typography>
                <Typography size="xs" color="text-base-color-fg-muted">
                  E {relationship.relatedPerson.contactInformation.emailAddress}
                </Typography>
              </StackView>
            )
          })}
      </StackView>
    )
  },
}

export const PatientChartIndexPhoneNumberColumn = {
  colId: 'phoneNumbers',
  headerName: 'Phone number(s)',
  cellRenderer: ({
    data,
  }: ICellRendererParams<
    | FoundPatientDetails['patients'][0]
    | RecentlyViewedPatientsQuery['recentlyViewedPatients'][0]
  >) => {
    return data.contactInformation ? (
      <StackView>
        <StackView direction="row">
          <Typography>
            {formatContactInformationPhoneNumbers(data.contactInformation)}
          </Typography>
        </StackView>
      </StackView>
    ) : (
      <Typography>N/A</Typography>
    )
  },
}

export const PatientChartIndexEmailAddressColumn = {
  colId: 'emailAddress',
  headerName: 'Email address',
  minWidth: 220,
  cellRenderer: ({
    data,
  }: ICellRendererParams<
    | FoundPatientDetails['patients'][0]
    | RecentlyViewedPatientsQuery['recentlyViewedPatients'][0]
  >) => {
    const patientEmailAddress = data.contactInformation?.emailAddress
    return <Typography>{patientEmailAddress ?? 'N/A'}</Typography>
  },
}

export const PatientChartIndexMRNColumn = {
  colId: 'mrn',
  headerName: 'MRN',
  cellRenderer: ({
    data,
  }: ICellRendererParams<
    | FoundPatientDetails['patients'][0]
    | RecentlyViewedPatientsQuery['recentlyViewedPatients'][0]
  >) => {
    return <Typography>{data.mrn} </Typography>
  },
}

export const PatientChartIndexLastVisitColumn = {
  colId: 'lastVisit',
  headerName: 'Last visit',
  cellRenderer: ({ data }) => {
    const date = data.mostRecentAppointment?.start
    return (
      <StackView className="h-full" justifyContent="center">
        <Typography>
          {date ? format(new Date(date), 'MMM d, yyyy') : ''}
        </Typography>
      </StackView>
    )
  },
}

export const PatientChartIndexStatusColumn = {
  colId: 'status',
  headerName: 'Status',
  cellRenderer: ({ data }) => {
    return <PatientStatusBadge patient={data} showActive />
  },
}
