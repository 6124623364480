import {
  ResendPatientPortalLoginCode,
  ResendPatientPortalLoginCodeVariables,
} from 'types/graphql'
import { useCountdown } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { usePatientPortalAuth } from 'src/auth'
import Button from 'src/components/atoms/Button/Button'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { formatPhoneNumber } from 'src/lib/formatters'
import { usePatientPortalContext } from 'src/providers/context/PatientPortalContext/PatientPortalContext'
import { useEffectOnce } from 'src/utils'

import { PatientPortalLoginPageLayout } from './PatientPortalLoginPageLayout'

const REQUEST_CODE = gql`
  mutation ResendPatientPortalLoginCode(
    $input: RequestPatientPortalLoginCodeInput!
  ) {
    requestPatientPortalLoginCode(input: $input) {
      ok
    }
  }
`

export const PatientPortalLoginCodeStep = ({
  mobileNumber,
}: {
  mobileNumber: string
}) => {
  const { tenant } = usePatientPortalContext()
  const tenantId = tenant.id

  const { logIn, loading } = usePatientPortalAuth()

  const [canRequestNewCodeInSeconds, controls] = useCountdown({
    countStart: 30,
    countStop: 0,
    intervalMs: 1000,
    isIncrement: false,
  })

  useEffectOnce(() => {
    controls.startCountdown()
  })

  const [requestLoginCode, { loading: isRequestingCode }] = useMutation<
    ResendPatientPortalLoginCode,
    ResendPatientPortalLoginCodeVariables
  >(REQUEST_CODE)

  const resendCode = () =>
    requestLoginCode({
      variables: {
        input: {
          tenantId,
          mobileNumber,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        controls.resetCountdown()
        controls.startCountdown()

        toast.success(`A new code was sent to ${maskedPhoneNumber}`)
      },
    })

  const maskedPhoneNumber = `(···) ··· - ${mobileNumber.slice(-4)}`

  return (
    <PatientPortalLoginPageLayout
      title="Enter the code we sent"
      description={`We sent a one-time login code to ${maskedPhoneNumber} that will expire in 15 minutes.`}
      tenantId={tenantId}
      onSubmit={async (data) => {
        const result = await logIn({
          username: [mobileNumber, tenantId].join(':'),
          password: data.code,
        })

        if (result.error) {
          toast.error(
            'Code is incorrect or has expired. Please try again or request a new code.'
          )
        }
      }}
      submitButton={{
        text: 'Log in',
        loading: loading,
      }}
      extraDetails={
        <StackView alignItems="center" gap={25}>
          <StackView
            direction="row"
            justifyContent="center"
            alignItems="center"
            space={50}
            wrap
          >
            <Typography color="text-base-color-fg-subtle">
              {"Didn't receive a code or it expired?"}
            </Typography>
            <Button
              disabled={canRequestNewCodeInSeconds > 0}
              buttonStyle="link"
              text="Resend code"
              className="inline !px-0"
              loading={isRequestingCode}
              onClick={resendCode}
            />
            <Typography color="text-base-color-fg-subtle">
              {`or call our practice at ${formatPhoneNumber(
                tenant.primaryPhoneNumber
              )}.`}
            </Typography>
          </StackView>
          {canRequestNewCodeInSeconds ? (
            <Typography
              textStyle="interface-default-xs"
              color="text-base-color-fg-subtle"
              className="text-center"
            >
              You can request another code in {canRequestNewCodeInSeconds}{' '}
              seconds
            </Typography>
          ) : null}
        </StackView>
      }
    >
      <FormInputList
        className="py-0"
        divider={false}
        items={[
          {
            name: 'code',
            label: 'Enter your code',
            required: true,
            direction: 'col',
            formInputComponent: InputField,
            inputProps: {
              size: 'l',
              minLength: 6,
              maxLength: 6,
            },
          },
        ]}
      />
    </PatientPortalLoginPageLayout>
  )
}
