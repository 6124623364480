import type { UpdateContactInformationInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import InputField from 'src/components/atoms/InputField/InputField'
import LanguageSelectField from 'src/components/atoms/LanguageSelectField/LanguageSelectField'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import { languageDisplay } from 'src/data/languages'
import { formatPhoneNumber } from 'src/lib/formatters'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext/AppointmentCheckInContext'

export const UPDATE_PATIENT_CONTACT_INFORMATION = gql`
  mutation UpdatePatientContactInformationMutation(
    $id: String!
    $input: UpdateContactInformationInput!
  ) {
    updatePatientContactInformation(id: $id, input: $input) {
      id
      contactInformation {
        id
        mobileNumber
        homeNumber
        emailAddress
        faxNumber
        primaryLanguage
        secondaryLanguage
      }
    }
  }
`

const VERIFY_PATIENT_CONTACT_INFORMATION = gql`
  mutation VerifyPatientContactInformationMutation($id: String!) {
    verifyPatientContactInformation(id: $id) {
      id
      contactInformation {
        verifiedAt
      }
    }
  }
`

const PatientContactInformationVerificationCard = () => {
  const [verifyPatientContactInformation] = useMutation(
    VERIFY_PATIENT_CONTACT_INFORMATION
  )

  const {
    appointmentData: { patient },
    checkInVerification,
  } = useAppointmentCheckIn()

  const { verificationRequirement, lastVerifiedAt } =
    checkInVerification['PATIENT']['CONTACT_INFORMATION']

  const onVerify = () => {
    verifyPatientContactInformation({
      variables: {
        id: patient.id,
      },
    })
  }

  const preSubmitPatientContactInformation = (
    data: UpdateContactInformationInput
  ) => {
    const input = { ...data }
    return {
      variables: {
        id: patient.id,
        input,
      },
    }
  }

  return (
    <DataVerificationCard<UpdateContactInformationInput>
      title="Contact information"
      updateMutation={UPDATE_PATIENT_CONTACT_INFORMATION}
      verificationOptions={{
        onVerify,
        verificationRequirement,
      }}
      preSubmit={preSubmitPatientContactInformation}
      lastVerifiedAt={lastVerifiedAt}
      data={[
        {
          name: 'mobileNumber',
          label: 'Mobile number',
          required: true,
          value: patient.contactInformation?.mobileNumber,
          displayValue: formatPhoneNumber(
            patient.contactInformation?.mobileNumber
          ),
          formInputComponent: PhoneInputField,
        },
        {
          name: 'homeNumber',
          label: 'Home number',
          value: patient.contactInformation?.homeNumber,
          displayValue: formatPhoneNumber(
            patient.contactInformation?.homeNumber
          ),
          formInputComponent: PhoneInputField,
        },
        {
          name: 'emailAddress',
          label: 'Email address',
          value: patient.contactInformation?.emailAddress,
          displayValue: patient.contactInformation?.emailAddress,
          formInputComponent: InputField,
        },
        {
          name: 'faxNumber',
          label: 'Fax number',
          value: patient.contactInformation?.faxNumber,
          displayValue: formatPhoneNumber(
            patient.contactInformation?.faxNumber
          ),
          formInputComponent: PhoneInputField,
        },
        {
          name: 'primaryLanguage',
          label: 'Primary language',
          required: true,
          hide: !patient.contactInformation,
          value: patient.contactInformation?.primaryLanguage,
          inputProps: {
            defaultValue: 'EN_US',
          },
          displayValue:
            languageDisplay[patient.contactInformation?.primaryLanguage],
          formInputComponent: LanguageSelectField,
        },
        {
          name: 'secondaryLanguage',
          label: 'Secondary language',
          value: patient.contactInformation?.secondaryLanguage,
          hide: !patient.contactInformation,
          displayValue:
            languageDisplay[patient.contactInformation?.secondaryLanguage],
          formInputComponent: LanguageSelectField,
        },
      ]}
    />
  )
}

export default PatientContactInformationVerificationCard
