import clsx from 'clsx'
import { compact, truncate } from 'lodash'
import {
  GetPatient,
  HealthPlanType,
  InsuranceCoveragePlanStatus,
} from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import StackView from 'src/components/atoms/StackView'
import { Chip } from 'src/components/Chip/Chip'
import { Tooltip } from 'src/components/molecules/Tooltip/Tooltip'
import { sidepanelRoute } from 'src/lib/routes'
import {
  COORDINATION_OF_BENEFITS_DISPLAY,
  HEALTH_PLAN_STATUS_COLORS,
  HEALTH_PLAN_STATUS_DISPLAY,
  HEALTH_PLAN_STATUS_ICONS,
  HEALTH_PLAN_TYPE_DISPLAY,
  HEALTH_PLAN_TYPE_ICONS,
} from 'src/utils/insuranceCoverages'

export const EligibilityIcon = (args: {
  status: InsuranceCoveragePlanStatus
}) => {
  const status = args?.status ?? 'UNKNOWN'
  const Icon = HEALTH_PLAN_STATUS_ICONS[status]
  return (
    <Icon
      className={clsx([
        'h-base-size-icon-xs w-base-size-icon-xs',
        HEALTH_PLAN_STATUS_COLORS[status],
      ])}
    />
  )
}

export const PlanTypeIcon = (args: { planType: HealthPlanType }) => {
  const planType = args?.planType ?? 'UNKNOWN'
  const Icon = HEALTH_PLAN_TYPE_ICONS[planType]
  return (
    <Icon
      className={clsx([
        'h-base-size-icon-xs w-base-size-icon-xs',
        'fill-base-color-fg-muted',
      ])}
    />
  )
}

const PatientInsuranceChip = ({
  insuranceCoverage,
  patientId,
  showPlanName = true,
}: {
  insuranceCoverage: GetPatient['patient']['activeInsuranceCoverages'][number]
  patientId: string
  showPlanName?: boolean
}) => {
  const params = useParams()
  const location = useLocation()
  return (
    <Tooltip
      testId="patient-insurance-chip-tooltip"
      title={compact([
        COORDINATION_OF_BENEFITS_DISPLAY[
          insuranceCoverage.coordinationOfBenefitsType
        ],
        HEALTH_PLAN_TYPE_DISPLAY[insuranceCoverage.planType ?? 'UNKNOWN'],
      ]).join(' · ')}
      text={compact([
        HEALTH_PLAN_STATUS_DISPLAY[
          insuranceCoverage.mostRecentEligibility?.status ?? 'UNKNOWN'
        ],
        insuranceCoverage.payer.displayName,
        insuranceCoverage.planName,
      ])}
    >
      <Chip
        text={
          showPlanName
            ? truncate(insuranceCoverage.payer.displayName, { length: 15 })
            : undefined
        }
        leftContent={{
          type: 'element',
          element: (
            <StackView direction="row" space={25} fullWidth={false}>
              <EligibilityIcon
                status={insuranceCoverage.mostRecentEligibility?.status}
              />
              <PlanTypeIcon planType={insuranceCoverage.planType} />
            </StackView>
          ),
        }}
        onClick={() => {
          navigate(
            sidepanelRoute(
              {
                route: `/patients/${patientId}/insurance-coverages/${insuranceCoverage.id}`,
              },
              location,
              params
            )
          )
        }}
      />
    </Tooltip>
  )
}
export default PatientInsuranceChip
