import { formatDisplayName } from 'common/utils'

import { get, useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { PatientMembershipCheckmark } from 'src/components/PatientMembershipCheckmark/PatientMembershipCheckmark'
import { sexDisplay } from 'src/data/sexes'
import { formatDateDisplay } from 'src/lib/formatters'

import { PatientStatusBadge } from '../PatientStatusBadge/PatientStatusBadge'

const RenderPatientSearchResults = ({
  onSearchResultClick,
  searchResults: patients,
  isSearchActive,
  name,
}) => {
  const { formState } = useFormContext()
  const { errors } = formState

  const hasError = !!get(errors, name)
  if (hasError) {
    return null
  }
  return (
    <Box
      data-testid="search-patient-results"
      className="absolute right-0 top-10 z-10 mt-1 h-fit max-h-40 w-fit min-w-full overflow-auto rounded-md bg-white shadow-md"
    >
      <StackView direction="col">
        {isSearchActive && patients.length === 0 && (
          <StackView
            direction="row"
            data-testid="search-patient-result"
            className="pointer-events-none py-2"
            onClick={onSearchResultClick}
          >
            <Typography
              className="pointer-events-none pl-3"
              textStyle="subtitle"
              color="text-base-color-fg-default"
            >
              No patients found
            </Typography>
          </StackView>
        )}
        {patients.length > 0 && (
          <>
            {patients?.map((patient) => {
              return (
                <Button
                  key={patient.id}
                  buttonStyle="ghost"
                  onClick={() => onSearchResultClick(patient.id, patient)}
                  className="p-2 hover:bg-gray-300"
                >
                  <StackView
                    id={patient.id}
                    direction="row"
                    data-testid="search-patient-result"
                    space={50}
                  >
                    <Typography
                      className="pointer-events-none"
                      textStyle="subtitle"
                      noWrap
                    >
                      {formatDisplayName(patient)}
                    </Typography>
                    <Typography
                      className="pointer-events-none"
                      textStyle="subtitle"
                      color="text-base-color-fg-muted"
                    >
                      {sexDisplay[patient.sexAtBirth].charAt(0)}
                    </Typography>
                    <Typography
                      className="pointer-events-none"
                      textStyle="subtitle"
                      color="text-base-color-fg-muted"
                    >
                      {formatDateDisplay(patient.birthDate)}
                    </Typography>
                    <Typography
                      className="pointer-events-none"
                      textStyle="subtitle"
                      color="text-base-color-fg-muted"
                    >
                      {patient.mrn}
                    </Typography>

                    <PatientMembershipCheckmark patient={patient} />

                    <PatientStatusBadge patient={patient} />
                  </StackView>
                </Button>
              )
            })}
          </>
        )}
      </StackView>
    </Box>
  )
}

export default RenderPatientSearchResults
