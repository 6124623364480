import { partition } from 'lodash'
import { FindPatientVisit } from 'types/graphql'

import { usePatientOrdersQuery } from '../PatientOrders/usePatientOrders'

import { useVisit, useVisitQuery } from './useVisit'

type ChargeItem =
  FindPatientVisit['appointment']['encounter']['claim']['chargeItems'][number]

type ChargeItemOrder = Exclude<
  ChargeItem['service'],
  { id?: string; __typename?: 'NoLinkedService' }
>

type ChargeItemWithOrder = ChargeItem & {
  service: ChargeItemOrder
}

export const useVisitBillingState = () => {
  const { appointmentId, patientId } = useVisit()
  const { visit, loading: visitIsLoading } = useVisitQuery(appointmentId)
  const {
    pendedOrders,
    activeOrders,
    loading: ordersAreLoading,
  } = usePatientOrdersQuery(patientId)

  if (visitIsLoading || ordersAreLoading) return { data: {}, loading: true }

  if (!visit)
    return {
      data: {},
      loading: false,
    }
  if (!visit.encounter) {
    throw new Error('Encounter not found')
  }

  const primaryDiagnosis = visit.encounter.primaryDiagnosis?.diagnosisCode

  let potentiallyBillableOrders = []
  if (!ordersAreLoading) {
    // potentiallyBillableOrders for this encounter (excludes orders that aren't part of the encounter)
    potentiallyBillableOrders = [...pendedOrders, ...activeOrders].filter(
      (o) => o.isPotentiallyBillable && o.encounterId === visit.encounter.id
    )
  }

  const chargeItems = visit.encounter.claim?.submittedAt
    ? visit.encounter.claim.chargeItems
    : visit.encounter.chargeItems

  const nonDoNotBillChargeItems = chargeItems.filter(
    (chargeItem) => !chargeItem.doNotBill
  )
  const [chargeItemsForVisit, remainder] = partition(
    nonDoNotBillChargeItems,
    'billingCode.isVisitCode'
  )
  const [additionalChargeItems, orderChargeItemsIncludingRevoked] = partition(
    remainder,
    ['service.__typename', 'NoLinkedService']
  )

  const orderChargeItems = orderChargeItemsIncludingRevoked.filter(
    (o): o is ChargeItemWithOrder => {
      if (o.service.__typename === 'NoLinkedService') {
        // this helps the type checker. We really should have filtered these out already.
        throw new Error('Unexpected service type')
      }
      return o.service.order.status !== 'REVOKED'
    }
  )

  const claimContainsSupportingInfo = !!(
    visit?.encounter?.claim &&
    visit.encounter.claim.billingProviderId &&
    visit.encounter.claim.serviceFacilityId &&
    visit.encounter.claim.placeOfService &&
    visit.encounter.claim.renderingProviderId
  )

  const ordersAccountedFor = orderChargeItems.map(
    (chargeItem) => chargeItem.service.order.id
  )
  const unaccountedForOrders = potentiallyBillableOrders.filter(
    (order) => !ordersAccountedFor.includes(order.id)
  )
  const [orderBillableChargeItems, orderNonBillableChargeItems] = partition(
    orderChargeItems,
    'service.order.isInBillableState'
  )

  const isEditable = !visit.encounter.claim?.submittedAt

  const showOrderBillingWarning =
    (isEditable && orderNonBillableChargeItems?.length > 0) ||
    unaccountedForOrders?.length > 0

  return {
    data: {
      visit,
      primaryDiagnosis,
      orderChargeItems,
      chargeItemsForVisit,
      additionalChargeItems,
      potentiallyBillableOrders,
      claimContainsSupportingInfo,
      orderBillableChargeItems,
      orderNonBillableChargeItems,
      showOrderBillingWarning,
      ordersAccountedFor,
      unaccountedForOrders,
      isEditable,
    },
    loading: false,
  }
}
