import {
  PencilSquareIcon,
  PlusIcon,
  Squares2X2Icon,
} from '@heroicons/react/24/solid'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import { UpdateAppointmentDocumentation } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { navigate, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Accordion from 'src/components/molecules/Accordion/Accordion'
import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField'
import {
  CompleteOrganSystem,
  OrganSystem,
  completeOrganSystemDisplay,
  organSystems,
  organSystemsNameValueArray,
} from 'src/data/organSystems'
import { sidepanelRoute } from 'src/lib/routes'
import { useUpdateEffect } from 'src/utils'

import {
  useVisitQuery,
  useVisit,
  useUpdateAppointmentDocumentationMutation,
} from '../../useVisit'

import PeriodicityDefaultsDropdown, {
  SET_APPOINTMENT_PERIODICITY_DEFAULT_MUTATION,
} from './PeriodicityDefaultsDropdown'

export type ReviewOfSystemFinding = {
  reports: string[]
  denies: string[]
}

type FormState = {
  selectedSystems: readonly OrganSystem[]
} & Record<OrganSystem, ReviewOfSystemFinding>

export const ReviewOfSystemsFindingDisplay: React.FC<{
  system: CompleteOrganSystem
  finding: ReviewOfSystemFinding
  editLink: string
  canEdit?: boolean
}> = ({ system, finding, editLink, canEdit = true }) => {
  const location = useLocation()

  const anyFindings =
    finding?.reports?.length > 0 || finding?.denies?.length > 0

  return (
    <StackView
      className="border-b border-gray-200 last:border-b-0"
      direction="row"
      alignItems="center"
      data-testid={`review-of-system-${system}`}
    >
      <StackView className="px-3 py-1.5" space={25}>
        <StackView direction="row" alignItems="center" space={25}>
          <Typography
            textStyle="title-xs"
            color={
              anyFindings
                ? 'text-base-color-fg-default'
                : 'text-base-color-fg-muted'
            }
          >
            {completeOrganSystemDisplay[system]}
          </Typography>
        </StackView>
        <StackView direction="row" justifyContent="between" space={50}>
          <Box className="w-1/2 leading-3" data-testid="ros-reports">
            {anyFindings && (
              <>
                <Typography
                  className="whitespace-pre-wrap"
                  fontWeight="bold"
                  color="text-base-color-fg-danger"
                >
                  Reports:{' '}
                </Typography>
                <Typography
                  className="whitespace-pre-wrap"
                  color="text-base-color-fg-danger"
                >
                  {finding?.reports?.join(', ')}
                </Typography>
              </>
            )}
          </Box>
          <Box className="w-1/2 leading-3" data-testid="ros-denies">
            {anyFindings && (
              <>
                <Typography
                  className="whitespace-pre-wrap"
                  fontWeight="bold"
                  color="text-base-color-fg-success"
                >
                  Denies:{' '}
                </Typography>
                <Typography
                  className="whitespace-pre-wrap"
                  color="text-base-color-fg-success"
                >
                  {finding?.denies?.join(', ')}
                </Typography>
              </>
            )}
          </Box>
        </StackView>
      </StackView>
      {canEdit && (
        <Box className="px-3 py-1.5">
          <Button
            className="h-8 !text-gray-500"
            buttonStyle="secondary"
            icon={anyFindings ? PencilSquareIcon : PlusIcon}
            text={anyFindings ? 'Edit' : 'Add findings'}
            onClick={() => {
              navigate(
                sidepanelRoute(
                  {
                    route: editLink,
                  },
                  location
                )
              )
            }}
          />
        </Box>
      )}
    </StackView>
  )
}

const ReviewOfSystemsSection: React.FC<{ canEdit?: boolean }> = ({
  canEdit = true,
}) => {
  const { appointmentId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()
  const [setAppointmentPeriodicityDefault] = useMutation(
    SET_APPOINTMENT_PERIODICITY_DEFAULT_MUTATION,
    {
      onCompleted: () => {
        toast.success('Documentation updated')
      },
    }
  )

  const ros = visit?.reviewOfSystems ?? {}

  const formMethods = useForm<FormState>({
    defaultValues: ros
      ? {
          ...(ros as object),
          selectedSystems: Object.keys(ros) as OrganSystem[],
        }
      : {},
  })

  const { selectedSystems, ...rest } = formMethods.watch()

  useUpdateEffect(() => {
    if (!isEqual(ros, rest) && !!ros) {
      formMethods.reset({
        selectedSystems: Object.keys(ros) as OrganSystem[],
        ...(ros as object),
      })
    }
  }, [formMethods, ros])

  const onSubmit = ({
    formState: inputs,
    incomingSelectedSystems,
  }: {
    formState: FormState
    incomingSelectedSystems: OrganSystem[]
  }) => {
    const reviewOfSystemFindings = incomingSelectedSystems.reduce(
      (acc, system) => {
        acc[system] = inputs[system] || {
          reports: [],
          denies: [],
        }
        return acc
      },
      {} as Record<OrganSystem, ReviewOfSystemFinding>
    )

    updateAppointmentDocumentation({
      variables: {
        input: {
          visitId: visit.id,
          documentationType: 'REVIEW_OF_SYSTEMS',
          value: JSON.stringify(reviewOfSystemFindings),
        },
      },
      onCompleted: (data: UpdateAppointmentDocumentation) => {
        const ros = data.updateAppointmentDocumentation.reviewOfSystems
        formMethods.reset({
          ...(ros as object),
          selectedSystems: incomingSelectedSystems,
        })
      },
    })
  }

  const values = formMethods.getValues()
  const sortedSystems = sortBy(selectedSystems, (system) =>
    organSystems.indexOf(system)
  )

  return (
    <Form formMethods={formMethods}>
      <Box data-testid="review-of-systems">
        <Accordion>
          <Accordion.AccordionItem
            title={
              <StackView>
                <Typography
                  textStyle="title"
                  className="leading-7"
                  fontWeight="semibold"
                >
                  Review of systems
                </Typography>
                <Typography textStyle="body" color="text-base-color-fg-muted">
                  Select organ system(s) of interest prior to making a
                  periodicity selection to add in age-appropriate defaults.
                  Periodicity defaults will overwrite any existing review of
                  system (ROS) entries. If a template is applied, existing ROS
                  entries where present for a given organ system will be
                  preserved.
                </Typography>
              </StackView>
            }
            headerClassName="!px-0"
            defaultOpen
          >
            <StackView space={75}>
              <StackView
                direction="row"
                space={50}
                alignItems="center"
                justifyContent="end"
                fullWidth={false}
              >
                <PeriodicityDefaultsDropdown
                  onChange={(value) => {
                    if (sortedSystems.length === 0) {
                      toast.error(
                        'Organ systems need to be selected first before periodicity defaults can be loaded.'
                      )
                    } else {
                      setAppointmentPeriodicityDefault({
                        variables: {
                          appointmentId,
                          periodicityStage: value,
                          documentationType: 'REVIEW_OF_SYSTEMS',
                          systems: sortedSystems,
                        },
                      })
                    }
                  }}
                />
                <MultiSelectDropdownField
                  name="selectedSystems"
                  icon={Squares2X2Icon}
                  emptyDisplayText="Systems"
                  options={organSystemsNameValueArray}
                  defaultValue={[]}
                  disabled={!canEdit}
                  onToggleFilter={(keys) =>
                    canEdit &&
                    onSubmit({
                      formState: values,
                      incomingSelectedSystems: keys as OrganSystem[],
                    })
                  }
                  showSelectAll
                  hideClearMultiSelect
                />
              </StackView>
              <StackView className="rounded-md border border-gray-200">
                {sortedSystems.map((system: OrganSystem) => (
                  <ReviewOfSystemsFindingDisplay
                    key={system}
                    editLink={`/encounters/${visit?.encounter?.id}/ROS/${system}/edit`}
                    system={system}
                    finding={ros?.[system]}
                    canEdit={canEdit}
                  />
                ))}
              </StackView>
            </StackView>
          </Accordion.AccordionItem>
        </Accordion>
      </Box>
    </Form>
  )
}

export default ReviewOfSystemsSection
