import { useParams } from 'react-router-dom'

import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { PatientMembershipPlanCard } from 'src/components/PatientMembershipPlanCard/PatientMembershipPlanCard'
import { usePatientMemberships } from 'src/hooks/usePatientMemberships/usePatientMemberships'

import { MembershipPaymentHistorySection } from './MembershipPaymentHistorySection'

export const PastPatientMembershipPlansTab = () => {
  const { patientId } = useParams()
  const { data } = usePatientMemberships({ patientId })

  if (!data) return null

  const activePlan = data?.patient?.activeMembershipPlan
  const pastPlans = data?.patient?.pastMembershipPlans ?? []

  return (
    <StackView gap={150} className="py-core-space-150">
      <StackView gap={100}>
        <SectionHeader size="s" title="Past memberships" />
        {pastPlans.map((plan) => (
          <PatientMembershipPlanCard key={plan.id} plan={plan} />
        ))}
      </StackView>

      {!activePlan ? (
        <>
          <Divider />
          <MembershipPaymentHistorySection />
        </>
      ) : null}
    </StackView>
  )
}
