import { namePrefixDisplay } from 'common/data/namePrefixes'
import { nameSuffixDisplay } from 'common/data/nameSuffixes'
import { parseISO } from 'date-fns'
import { PatientDemographicsFragment } from 'types/graphql'

import { useParams } from '@redwoodjs/router'

import InputField from 'src/components/atoms/InputField'
import MaritalStatusSelectField from 'src/components/atoms/MaritalStatusSelectField/MaritalStatusSelectField'
import NamePrefixSelectField from 'src/components/atoms/NamePrefixSelectField/NamePrefixSelectField'
import NameSuffixSelectField from 'src/components/atoms/NameSuffixSelectField/NameSuffixSelectField'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import { maritalStatusDisplay } from 'src/data/maritalStatuses'
import { formatDateDisplay, formatDateFieldValue } from 'src/lib/formatters'
import { UPDATE_PATIENT_CAREGIVER_CORE_DEMOGRAPHICS } from 'src/pages/AppointmentCheckInPage/CheckInCaregiversTab/CaregiverCoreDemographicsVerificationCard'

const CaregiverCoreDemographicsCard: React.FC<{
  relationship: PatientDemographicsFragment['patientRelatedPersonRelationships'][0]
}> = ({ relationship }) => {
  const { id } = useParams()
  const { relatedPerson } = relationship

  const preSubmit = (data) => {
    const {
      namePrefix,
      givenName,
      middleName,
      familyName,
      nameSuffix,
      preferredName,
      birthDate,
      maritalStatus,
      occupation,
      employer,
    } = data

    return {
      variables: {
        patientId: id,
        relatedPersonId: relatedPerson.id,
        input: {
          namePrefix,
          givenName,
          middleName,
          familyName,
          nameSuffix,
          preferredName,
          birthDate,
          maritalStatus,
          occupation,
          employer,
        },
      },
    }
  }

  return (
    <DataVerificationCard
      title="Core demographics"
      defaultOpen
      updateMutation={UPDATE_PATIENT_CAREGIVER_CORE_DEMOGRAPHICS}
      preSubmit={preSubmit}
      data={[
        {
          name: 'namePrefix',
          value: relatedPerson.namePrefix,
          displayValue: namePrefixDisplay[relatedPerson.namePrefix],
          label: 'Prefix',

          formInputComponent: NamePrefixSelectField,
        },
        {
          name: 'givenName',
          value: relatedPerson.givenName,
          label: 'First Name',
          required: true,
          formInputComponent: InputField,
        },
        {
          name: 'middleName',
          value: relatedPerson.middleName,
          label: 'Middle Name',
          formInputComponent: InputField,
        },
        {
          name: 'familyName',
          value: relatedPerson.familyName,
          label: 'Family Name',
          required: true,
          formInputComponent: InputField,
        },
        {
          name: 'nameSuffix',
          value: relatedPerson.nameSuffix,
          displayValue: nameSuffixDisplay[relatedPerson.nameSuffix],
          label: 'Suffix',
          formInputComponent: NameSuffixSelectField,
        },
        {
          name: 'preferredName',
          value: relatedPerson.preferredName,
          label: 'Preferred Name',
          formInputComponent: InputField,
        },
        {
          name: 'birthDate',
          value: relatedPerson.birthDate,
          displayValue: formatDateDisplay(relatedPerson.birthDate),
          label: 'Date of birth',
          required: true,
          inputProps: {
            emptyAs: null,
            validation: {
              setValueAs: (value) => formatDateFieldValue(value),
              validate: (value) => {
                return parseISO(value) > new Date()
                  ? 'Date of birth must be in the past'
                  : null
              },
            },
          },
          formInputComponent: DatePickerField,
        },
        {
          name: 'maritalStatus',
          value: relatedPerson.maritalStatus,
          displayValue: maritalStatusDisplay[relatedPerson.maritalStatus],
          label: 'Marital Status',
          formInputComponent: MaritalStatusSelectField,
        },
        {
          name: 'occupation',
          value: relatedPerson.occupation,
          label: 'Occupation',
          formInputComponent: InputField,
        },
        {
          name: 'employer',
          value: relatedPerson.employer,
          label: 'Current employer',
          formInputComponent: InputField,
        },
      ]}
    />
  )
}

export default CaregiverCoreDemographicsCard
