import { patientMembershipStatus } from 'common/data/membershipPlans'
import { match } from 'ts-pattern'

import PrimaryCheckmarkIcon from '../PrimaryCheckmarkIcon/PrimaryCheckmarkIcon'

export const PatientMembershipCheckmark = ({
  patient,
  className,
}: {
  patient: {
    activeMembershipPlan?: {
      id: string
      startDate: string
      endDate?: string
    }
  }
  className?: string
}) => {
  if (!patient.activeMembershipPlan) return null

  const status = patientMembershipStatus({ plan: patient.activeMembershipPlan })

  return match(status)
    .with('ACTIVE', () => (
      <PrimaryCheckmarkIcon
        className={className}
        testId="patient-membership-checkmark"
      />
    ))
    .with('CANCELLED', 'PENDING', () => null)
    .exhaustive()
}
