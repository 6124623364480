import { useMemo } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { formatPhoneNumber } from 'react-phone-number-input'
import { FindDocumentCaseDetails } from 'types/graphql'

import { useFormContext } from '@redwoodjs/forms'
import { routes } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import { FieldLabel } from 'src/components/atoms/Label'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Alert from 'src/components/molecules/Alert/Alert'
import FormInputList from 'src/components/molecules/FormInputList'
import { TypeaheadField } from 'src/components/molecules/Typeahead'
import { useContactsQueryAsTypeaheadOptions } from 'src/hooks/useContactsQuery/useContactsQuery'
import { openNewTab, useEffectOnce } from 'src/utils'

export const EfaxWhenResolvingTaskInput = ({
  name,
  originalSenderContact,
  originalSenderFaxNumber,
  defaultSelectedContactIds,
}: {
  name: string
  originalSenderContact?: FindDocumentCaseDetails['documentCase']['inboundFax']['from']
  originalSenderFaxNumber?: string
  defaultSelectedContactIds?: string[]
}) => {
  const { contactOptions } = useContactsQueryAsTypeaheadOptions()

  const efaxToOriginalSenderOption = {
    label: `E-fax to original sender (${originalSenderContact?.name})`,
    supportingLabel: formatPhoneNumber(originalSenderContact?.faxNumber),
    value: originalSenderContact?.id,
    disabled: !originalSenderContact?.faxNumber,
    headerKey: originalSenderContact?.id,
    headerLabel: 'Original sender',
  }

  const formMethods = useFormContext()
  const checkboxName = `shouldEfax`

  const shouldEfax = formMethods.watch(checkboxName)

  const defaultFaxRecipientContactIds = useMemo(
    () =>
      defaultSelectedContactIds?.length
        ? defaultSelectedContactIds
        : originalSenderContact
          ? [originalSenderContact.id]
          : [],
    [defaultSelectedContactIds, originalSenderContact]
  )

  useEffectOnce(() => {
    formMethods.setValue(name, defaultFaxRecipientContactIds)
  })

  return (
    <StackView space={50}>
      <StackView space={25}>
        <Typography textStyle="title-xs">E-fax when resolving task</Typography>
        <Typography textStyle="body-s">
          Select the recipients for the fax that is sent upon task resolution.
        </Typography>
      </StackView>
      <StackView className="pb-0">
        <StackView direction="row" alignItems="start">
          <CheckboxField name={checkboxName} />
          <StackView space={25}>
            <FieldLabel name={checkboxName}>
              E-fax this document after resolving task
            </FieldLabel>
          </StackView>
        </StackView>
        {shouldEfax && (
          <>
            <FormInputList
              items={[
                {
                  name: 'faxRecipientContactIds',
                  label: 'Recipient',
                  formInputComponent: TypeaheadField,
                  inputProps: {
                    options: originalSenderContact
                      ? [efaxToOriginalSenderOption, ...(contactOptions ?? [])]
                      : contactOptions,
                    includeCheckbox: true,
                    placeholder: 'Search for contacts',
                  },
                  direction: 'col',
                  required: true,
                },
              ]}
            />
            {originalSenderFaxNumber && !originalSenderContact ? (
              <Alert
                style="info"
                title="Can't find the contact you're looking for?"
                description={`The original sender of this document ${formatPhoneNumber(
                  originalSenderFaxNumber
                )} is not in your contacts. Create a contact for them first if you want to fax back to the same number.`}
                action={
                  <Button
                    className="w-min whitespace-nowrap"
                    buttonStyle="ghost"
                    buttonSize="s"
                    icon={ArrowTopRightOnSquareIcon}
                    onClick={() => openNewTab(routes.contacts())}
                  />
                }
              />
            ) : (
              <Typography textStyle="body-xs" color="text-base-color-fg-subtle">
                If there isn&apos;t a fax number linked to a recipient, you must
                add them before sending an e-fax. If they are a patient or
                caregiver please add them in their chart, otherwise add the
                recipient to your contacts.
              </Typography>
            )}
          </>
        )}
      </StackView>
    </StackView>
  )
}
