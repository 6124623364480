import { useEffect, useState } from 'react'

import { PencilIcon } from '@heroicons/react/24/solid'
import { convertParagraphToLexical } from 'common/lexical/lexical'
import { format, parseISO } from 'date-fns'

import { useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import Divider from 'src/components/atoms/Divider'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { lexicalToHTML } from 'src/components/atoms/WysiwygField/utils/utils'
import TextareaAutoSaveSection from 'src/components/molecules/TextareaAutoSaveSection/TextareaAutoSaveSection'
import PrintVisitNoteDocument from 'src/pdf/components/PrintVisitNoteDocument'
import { isCI, isDev } from 'src/utils'

import {
  BillingOrdersWarningModal,
  useBillingOrdersWarningConfirmation,
} from '../../BillingOrdersWarningModal'
import {
  PendedOrdersWarningModal,
  usePendedOrdersConfirmation,
} from '../../PendedOrdersWarningModal'
import {
  containsAdolescentConfidentialInformation,
  generateHTMLStringsForVisit,
  useSignVisitV2,
} from '../../useSignVisit'
import {
  useUpdateAppointmentDocumentationMutation,
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from '../../useVisit'

import { PrintVisitDocumentsDropdown } from './PrintVisitDocumentsDropdown'

const AttestationSection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [attestations, setAttestations] = useState([])
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()

  useEffect(() => {
    async function updateAttestations() {
      const tempArray = []
      for (const x in visit?.appointmentSignatures) {
        if (visit.appointmentSignatures[x].attestation) {
          const html = await lexicalToHTML(
            convertParagraphToLexical(
              visit.appointmentSignatures[x].attestation
            )
          )
          tempArray.push({ ...visit.appointmentSignatures[x], html })
        }
      }
      setAttestations(tempArray)
    }
    void updateAttestations()
  }, [visit])

  return (
    <StackView space={75}>
      <TextareaAutoSaveSection
        testId="attestation"
        title="Attestation"
        description="Add an optional attestation above your signature within the visit note."
        isRichText={true}
        macroPhraseSection="ATTESTATION"
        defaultValue={visit?.attestation}
        onChange={(value: string) =>
          updateAppointmentDocumentation({
            variables: {
              input: {
                visitId: visit.id,
                value: value,
                documentationType: 'ATTESTATION',
              },
            },
          })
        }
        loading={loading}
        disabled={
          visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')
        }
      />

      {attestations.length > 0 && (
        <Box border rounded padding={75}>
          <StackView space={75}>
            {attestations.map((signature) => (
              <StackView direction="row" key={signature.id} space={100}>
                <Box className="w-1/5">
                  <Typography>
                    {format(parseISO(signature.updatedAt), 'MMMM d, yyyy')}
                  </Typography>
                </Box>
                <Box className="w-4/5">
                  <Typography
                    dangerouslySetInnerHTML={{
                      __html: signature.html,
                    }}
                  />
                </Box>
              </StackView>
            ))}
          </StackView>
        </Box>
      )}
    </StackView>
  )
}

const ReviewPage = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId } = useVisit()
  const { visit, currentPractitioner } = useVisitQuery(appointmentId)

  useUpdateVisitFlowTabForAppointment('review')

  const [generatedHtmlStrings, setGeneratedHtmlStrings] = useState(null)

  const params = useParams()

  const [signVisitV2, { loading: signingVisitV2 }] = useSignVisitV2()

  const [
    billingWarningConfirmationState,
    waitForBillingWarningConfirmation,
    billingStateIsLoading,
  ] = useBillingOrdersWarningConfirmation({
    patientId: params.id,
    appointmentId: visit?.id,
  })
  const [pendedOrdersConfirmationState, waitForPendedOrdersConfirmation] =
    usePendedOrdersConfirmation({
      patientId: params.id,
      appointmentId: visit?.id,
      orders: visit?.encounter?.orders ?? [],
    })

  useEffect(() => {
    async function generateHtmlStrings() {
      const htmlStrings = await generateHTMLStringsForVisit(visit)
      setGeneratedHtmlStrings(htmlStrings)
    }
    void generateHtmlStrings()
  }, [visit])

  if (!visit) return null

  const hasAdolescentConfidentialInfo =
    containsAdolescentConfidentialInformation(visit)

  return (
    <>
      <StackView space={100} className="max-w-3xl">
        <StackView
          space={50}
          direction="row"
          className="sticky top-0 z-10 border-b bg-white py-4"
        >
          <Typography textStyle="heading" className="grow">
            Visit review
          </Typography>
          <PrintVisitDocumentsDropdown
            visitId={visit?.id}
            hasAdolescentConfidentialInfo={hasAdolescentConfidentialInfo}
          />
          {visit.chartingStatus !== 'COMPLETE' && hasRole('PRACTITIONER') && (
            <Button
              testId="sign-visit-button"
              text="Sign"
              icon={PencilIcon}
              disabled={billingStateIsLoading}
              loading={signingVisitV2}
              onClick={async () => {
                const { confirmed: billingWarningConfirmed } =
                  await waitForBillingWarningConfirmation()
                if (!billingWarningConfirmed) return

                const { confirmed: pendedOrdersWarningConfirmed } =
                  await waitForPendedOrdersConfirmation()
                if (!pendedOrdersWarningConfirmed) return

                return signVisitV2({
                  visit,
                  currentPractitioner,
                })
              }}
            />
          )}
        </StackView>

        <AttestationSection />

        <Divider />

        {generatedHtmlStrings ? (
          <PrintVisitNoteDocument
            visit={visit}
            currentPractitioner={currentPractitioner}
            testId="print-visit-note"
            htmlStrings={generatedHtmlStrings}
            hideHeader
            hideVisitDetails={params.testing && (isDev || isCI) ? false : true}
            hideFooter
          />
        ) : (
          <LoadingSpinner />
        )}
      </StackView>

      <BillingOrdersWarningModal
        isOpen={billingWarningConfirmationState.isConfirming}
        onConfirm={billingWarningConfirmationState.confirm}
        onCancel={billingWarningConfirmationState.cancel}
        action="SIGN_VISIT"
      />

      <PendedOrdersWarningModal
        isOpen={pendedOrdersConfirmationState.isConfirming}
        onConfirm={pendedOrdersConfirmationState.confirm}
        onCancel={pendedOrdersConfirmationState.cancel}
        action="SIGN_VISIT"
      />
    </>
  )
}

export default ReviewPage
