import type { CreatePracticeFormInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import PracticeFormTypeSelectField from 'src/components/atoms/PracticeFormTypeSelectField/PracticeFormTypeSelectField'
import FormInputList, {
  Item,
} from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

const CREATE_PRACTICE_FORM_MUTATION = gql`
  mutation CreatePracticeForm($input: CreatePracticeFormInput!) {
    createPracticeForm(input: $input) {
      id
    }
  }
`

export const practiceFormInputListItems: (opts?: {
  type?: string
  patientId?: string
  isDocumentCase?: boolean
}) => Item[] = () => [
  {
    name: 'type',
    label: 'Document sub-type',
    required: true,
    formInputComponent: PracticeFormTypeSelectField,
  },
  {
    name: 'isRequired',
    label: 'Check-in requirement',
    required: true,
    inputProps: {
      trueDisplay: 'Required',
      falseDisplay: 'Optional',
    },
    formInputComponent: BooleanSelectField,
  },
]

const CreatePracticeForm = () => {
  const { closeSidePanel } = useSidepanel()
  const [createPracticeForm, { loading: creatingPracticeForm }] = useMutation(
    CREATE_PRACTICE_FORM_MUTATION,
    {
      onCompleted: () => {
        toast.success('Practice form created')
        closeSidePanel()
      },
      refetchQueries: ['PracticeFormsQuery'],
    }
  )
  const formMethods = useForm<CreatePracticeFormInput>({
    defaultValues: { isRequired: false },
  })

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Upload document',
        submitting: creatingPracticeForm,
      }}
      formMethods={formMethods}
      onSubmit={async (value) => {
        const { binary: binaryInput, ...input } = value

        input['binary'] = {
          content: await base64EncodeFile(binaryInput[0]),
          contentType: binaryInput[0].type,
        }
        createPracticeForm({ variables: { input } })
      }}
    >
      <FormInputList
        items={[
          ...practiceFormInputListItems(),
          {
            name: 'binary',
            label: 'Document upload',
            required: true,
            formInputComponent: FileInput,
          },
        ]}
      />
    </SidepanelForm>
  )
}

export default CreatePracticeForm
