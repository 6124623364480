import { PencilIcon, LinkIcon } from '@heroicons/react/24/solid'
import { formatDisplayName } from 'common/utils'
import type { FindUserById } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList/DataDisplayList'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { HOST_URL } from 'src/lib/constants'
import {
  checkboxInputTag,
  formatAddress,
  formatDateDisplay,
  formatEnum,
} from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

const REGENERATE_RESET_TOKEN_MUTATION = gql`
  mutation RegenerateResetToken($id: String!) {
    regenerateResetToken(id: $id) {
      id
      resetToken
      resetTokenExpiresAt
    }
  }
`

interface Props {
  user: NonNullable<FindUserById['user']>
}

const User = ({ user }: Props) => {
  const { hasRole } = useEmrAuth()
  const isAdmin = hasRole('ADMIN')

  const location = useLocation()
  const params = useParams()

  const [regenerateResetToken] = useMutation(REGENERATE_RESET_TOKEN_MUTATION, {
    onCompleted: (res) => {
      const inviteLink = `${HOST_URL}/reset-password?resetToken=${res.regenerateResetToken.resetToken}`
      void navigator.clipboard.writeText(inviteLink)
      toast.success('An invite link was copied to your clipboard.')
    },
  })

  return (
    <SidepanelPage header={formatDisplayName(user)}>
      <StackView className="h-full p-8" space={25} data-testid="user-display">
        <StackView direction="row" space={75}>
          {isAdmin && (
            <>
              <Button
                buttonStyle="secondary"
                icon={PencilIcon}
                text="Edit"
                onClick={() =>
                  navigate(
                    sidepanelRoute(
                      {
                        route: `/users/${user.id}/edit`,
                      },
                      location,
                      params
                    )
                  )
                }
              />

              {user.status === 'INVITED' && (
                <Button
                  buttonStyle="secondary"
                  icon={LinkIcon}
                  text="Invite Link"
                  onClick={() =>
                    regenerateResetToken({ variables: { id: user.id } })
                  }
                />
              )}
            </>
          )}
        </StackView>

        <DataDisplayList
          testId="staff-member-details"
          data={[
            {
              label: 'First name',
              value: user.givenName,
            },
            {
              label: 'Last Name',
              value: user.familyName,
            },
            {
              label: 'Email',
              value: user.email,
            },
            {
              label: 'Date of Birth',
              value: user.practitioner
                ? formatDateDisplay(user.practitioner.birthDate)
                : '-',
              hide: !isAdmin,
            },
            {
              label: 'Credentials / title',
              value: user.credentialsLabel ? user.credentialsLabel : '-',
              hide: !isAdmin,
            },
            {
              label: 'Gender',
              value: user.practitioner
                ? formatEnum(user.practitioner.gender)
                : '-',
              hide: !isAdmin,
            },
            {
              label: 'Mailing Address',
              value: user.practitioner?.contactInformation
                ? formatAddress(
                    user.practitioner.contactInformation.mailingAddress
                  )
                : '-',
              hide: !isAdmin,
            },
            {
              label:
                !user.practitioner?.contactInformation ||
                user.practitioner.contactInformation.mailingAddress.id ===
                  user.practitioner.contactInformation.homeAddress.id
                  ? 'Home Address matches Mailing Address'
                  : 'Home Address',
              value:
                !user.practitioner?.contactInformation ||
                user.practitioner.contactInformation.mailingAddress.id ===
                  user.practitioner.contactInformation.homeAddress.id ? (
                  checkboxInputTag(true)
                ) : (
                  <Typography>
                    {formatAddress(
                      user.practitioner?.contactInformation.homeAddress
                    )}
                  </Typography>
                ),
              hide: !isAdmin,
            },
            {
              label: 'User Type',
              value: user.userType.name,
            },
            {
              label: 'Is Admin',
              value: checkboxInputTag(user.userType.roles.includes('ADMIN')),
            },
            {
              label: 'Visit Practitioner',
              value: checkboxInputTag(
                user.userType.roles.includes('PRACTITIONER')
              ),
            },
            {
              label: 'Billing Practitioner',
              value: checkboxInputTag(
                user.userType.roles.includes('CHARGE_CAPTURE')
              ),
            },
            {
              label: 'Primary Location',
              value: user.primaryLocation.name,
            },
            {
              label: 'Locations',
              value: user.locations.map((loc) => loc.name).join(', '),
            },
          ]}
        />
      </StackView>
    </SidepanelPage>
  )
}

export default User
