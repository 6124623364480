import { ColDef } from 'ag-grid-community'
import { parseISO } from 'date-fns'
import sort from 'lodash/sortBy'
import { Condition } from 'types/graphql'

import Badge from 'src/components/atoms/Badge/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { useGetLexicalHtml } from 'src/components/atoms/WysiwygField/hooks/useGetLexicalHtml'
import {
  conditionStatusDisplay,
  conditionStatusBadgeColor,
} from 'src/data/conditionStatuses'

import { GroupedCondition } from '../useConditions'

const formatConditionOccurrenceString = (condition: Condition) => {
  return `${parseISO(condition.onsetAt).toLocaleDateString()}${
    condition.abatedAt
      ? ` - ${parseISO(condition.abatedAt).toLocaleDateString()}`
      : ''
  }`
}

export const ConditionStatusColumn: ColDef<GroupedCondition> = {
  colId: 'status',
  headerName: 'Status',
  minWidth: 120,
  maxWidth: 120,
  cellRenderer: ({ data }) => {
    return (
      <StackView justifyContent="center" fullWidth={false}>
        <Badge
          size="l"
          text={conditionStatusDisplay[data.lastClinicalStatus]}
          color={conditionStatusBadgeColor[data.lastClinicalStatus]}
        />
      </StackView>
    )
  },
}

export const ConditionOccurencesColumn: ColDef<GroupedCondition> = {
  colId: 'occurrences',
  headerName: 'Occurrence(s)',
  minWidth: 150,
  maxWidth: 250,
  cellRenderer: ({ data }: { data: GroupedCondition }) => {
    if (!data.conditions) return null

    const sortedOccurences = sort(data.conditions, 'onsetAt').reverse()

    return (
      <StackView justifyContent="center" fullWidth={false}>
        <Typography
          color="text-base-color-fg-muted"
          textStyle="interface-default-s"
        >
          {formatConditionOccurrenceString(sortedOccurences[0])}
        </Typography>
        {sortedOccurences.length > 1 && (
          <Typography
            color="text-base-color-fg-subtle"
            textStyle="interface-default-xs"
          >
            {sortedOccurences.length - 1} other occurrence(s)
          </Typography>
        )}
      </StackView>
    )
  },
}

export const ConditionCodeDescriptionColumn: ColDef<GroupedCondition> = {
  colId: 'name',
  headerName: 'Name',
  flex: 1,
  cellRenderer: ({ data }) => {
    return (
      <StackView
        className="h-full py-base-space-page-inset-s"
        justifyContent="center"
      >
        <Typography
          textStyle="interface-strong-s"
          className="whitespace-normal"
        >
          {data.display}
        </Typography>
        <Typography
          textStyle="interface-default-xs"
          color="text-base-color-fg-subtle"
        >
          {data.code}
        </Typography>
      </StackView>
    )
  },
}

const ConditionCommentLexicalHtml = ({
  data,
}: {
  data: GroupedCondition
}): React.JSX.Element => {
  const html = useGetLexicalHtml(data?.comment)
  return html
}

export const ConditionCommentColumn: ColDef<GroupedCondition> = {
  colId: 'comment',
  headerName: 'Comment',
  flex: 1,
  cellRenderer: ({ data }: { data: GroupedCondition }) => {
    return <ConditionCommentLexicalHtml data={data} />
  },
}
