import { useEffect, useState } from 'react'

import {
  GetPatientBillingStatement,
  GetPatientBillingStatementVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import { PATIENT_DETAILED_BILLING_STATEMENT_FRAGMENT } from 'src/hooks/usePatientBillingStatement/usePatientBillingStatement'
import PrintPatientDetailedBillingStatement from 'src/pdf/components/PrintPatientDetailedBillingStatement'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

import { useSidepanelPatientFinancialsContext } from './SidepanelPatientFinancialsContext'

const QUERY = gql`
  query GetPatientBillingStatement($patientId: String!) {
    patient(id: $patientId) {
      id
      billingStatement {
        ...BillingStatementFragment
      }
    }
  }
  ${PATIENT_DETAILED_BILLING_STATEMENT_FRAGMENT}
`

export const StatementTab = () => {
  const { patient } = useSidepanelPatientFinancialsContext()

  const { data, loading } = useQuery<
    GetPatientBillingStatement,
    GetPatientBillingStatementVariables
  >(QUERY, {
    variables: {
      patientId: patient.id,
    },
  })

  const [isGenerating, setIsGenerating] = useState(false)
  const [generated, setGenerated] = useState<URL>()
  const generatePDF = useGeneratePDF()

  useEffect(() => {
    if (generated) return
    if (isGenerating) return
    if (!data?.patient?.billingStatement) return

    setIsGenerating(true)

    void generatePDF({
      component: (
        <PrintPatientDetailedBillingStatement
          billingStatement={data.patient.billingStatement}
        />
      ),
      callback: (doc) => {
        setIsGenerating(false)
        setGenerated(doc.output('bloburl'))
      },
    })
  }, [data, generated, isGenerating, generatePDF])

  return (
    <StackView className="h-full p-core-space-150">
      {loading || isGenerating ? (
        <LoadingSpinner />
      ) : generated ? (
        <FileDisplay
          type="application/pdf"
          url={generated}
          showDownloadLink={false}
        />
      ) : (
        'This patient does not have a billing statement to display.'
      )}
    </StackView>
  )
}
