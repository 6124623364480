import {
  AppointmentPatientCheckInQuery,
  FindPatientDemographicsQuery,
} from 'types/graphql'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { SiblingInfoCard } from 'src/components/organisms/PatientSiblings/SiblingInfoCard'

export const PatientSiblings = ({
  patient,
  fullWidth = false,
}: {
  patient:
    | FindPatientDemographicsQuery['patient']
    | AppointmentPatientCheckInQuery['appointment']['patient']
  fullWidth?: boolean
}) => {
  if (!patient || !patient.siblings?.length) return null

  return (
    <StackView space={75} className={fullWidth ? '' : 'max-w-2xl'}>
      <Typography textStyle="heading">Siblings</Typography>
      {patient.siblings.map((sibling) => {
        const siblingCaregiverIds =
          sibling.patientRelatedPersonRelationships.map(
            (relationship) => relationship.relatedPersonId
          )

        return (
          <SiblingInfoCard
            key={sibling.id}
            sibling={sibling}
            sharedRelationships={patient.patientRelatedPersonRelationships.filter(
              (relationship) =>
                siblingCaregiverIds.includes(relationship.relatedPersonId)
            )}
          />
        )
      })}
    </StackView>
  )
}
