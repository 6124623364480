import { UpdatePatientInsuranceOptOutInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'

import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import InsuranceOptOutStatusSelectField from 'src/components/atoms/InsuranceOptOutStatusSelectField/InsuranceOptOutStatusSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import { insuranceOptOutStatusDisplay } from 'src/data/insuranceOptOutStatuses'
import {
  CheckInStepSectionVerification,
  useAppointmentCheckIn,
} from 'src/providers/context/AppointmentCheckInContext/AppointmentCheckInContext'
import { VERIFY_PATIENT_ATTRIBUTE_MUTATION } from 'src/providers/context/AppointmentCheckInContext/schema'

export const UPDATE_INSURANCE_OPT_OUT_MUTATION = gql`
  mutation UpdateInsuranceOptOut(
    $id: String!
    $input: UpdatePatientInsuranceOptOutInput!
  ) {
    updatePatientInsuranceOptOut(id: $id, input: $input) {
      id
      insuranceOptedOutAt
      insuranceOptOutStatus
      insuranceCoverages {
        id
        status
      }
    }
  }
`

const InsuranceOptOutVerificationCard = () => {
  const [verifyInsuranceOptOut] = useMutation(VERIFY_PATIENT_ATTRIBUTE_MUTATION)

  const {
    appointmentData: { patient },
    checkInVerification,
  } = useAppointmentCheckIn()

  const { verificationRequirement, lastVerifiedAt } = checkInVerification[
    'INSURANCE'
  ]['INSURANCE_OPT_OUT'] as CheckInStepSectionVerification

  const onVerify = () => {
    verifyInsuranceOptOut({
      variables: {
        patientId: patient.id,
        verificationType: 'INSURANCE_OPT_OUT',
      },
    })
  }

  const preSubmit = (data: UpdatePatientInsuranceOptOutInput) => {
    const { ...input } = data
    return { variables: { id: patient.id, input } }
  }

  const formMethods = useForm<UpdatePatientInsuranceOptOutInput>({
    defaultValues: {
      insuranceOptOut: !!patient.insuranceOptedOutAt,
      insuranceOptOutStatus: patient.insuranceOptOutStatus,
    },
  })

  const isPatientOptingOut = formMethods.watch('insuranceOptOut')

  return (
    <DataVerificationCard<UpdatePatientInsuranceOptOutInput>
      title="Insurance opt-out"
      updateMutation={UPDATE_INSURANCE_OPT_OUT_MUTATION}
      verificationOptions={{
        onVerify,
        verificationRequirement,
      }}
      preSubmit={preSubmit}
      lastVerifiedAt={lastVerifiedAt}
      formMethods={formMethods}
      defaultOpen={!!patient.insuranceOptedOutAt}
      data={[
        {
          name: 'insuranceOptOut',
          label: 'Is patient opting-out?',
          required: true,
          inputProps: {
            includeEmptyOption: false,
            trueDisplay: 'Yes',
            falseDisplay: 'No',
          },
          value: patient.insuranceOptedOutAt ? 'Yes' : 'No',
          formInputComponent: BooleanSelectField,
        },
        {
          name: 'insuranceOptOutStatus',
          label: 'Insurance opt-out reason',
          required: true,
          hideFormInput: !isPatientOptingOut,
          hide: !patient.insuranceOptedOutAt,
          displayValue:
            insuranceOptOutStatusDisplay[patient.insuranceOptOutStatus],
          value: patient.insuranceOptOutStatus,
          formInputComponent: InsuranceOptOutStatusSelectField,
        },
      ]}
    />
  )
}

export default InsuranceOptOutVerificationCard
