import { Address } from 'types/graphql'

import Card from 'src/components/atoms/Card'
import Link from 'src/components/atoms/Link'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { AddressJoinOption, formatAddress } from 'src/lib/formatters'

const qrCodeSize = 100

export const AddressAndPortalLinkSection = ({
  name,
  mailingAddress,
  portal,
}: {
  name: string
  mailingAddress: Pick<
    Address,
    'id' | 'line1' | 'line2' | 'city' | 'state' | 'postalCode' | 'country'
  >
  portal?: {
    url: string
    qrCodeDataUrl: string
  }
}) => {
  return (
    <StackView direction="row" gap={75}>
      <Card className="flex-1 basis-1/2 p-core-space-75">
        <StackView key={mailingAddress.id} justifyContent="between" gap={25}>
          <Typography textStyle="title-s">{name}</Typography>

          <Typography className="whitespace-pre-wrap">
            {formatAddress(mailingAddress, AddressJoinOption.NEWLINE)}
          </Typography>
        </StackView>
      </Card>

      {portal ? (
        <Card className="basis-1/2 bg-base-color-bg-subtle p-core-space-75">
          <StackView direction="row" gap={100}>
            <StackView justifyContent="between">
              <StackView gap={25}>
                <Typography textStyle="title-s">
                  Pay through the family portal
                </Typography>
                <Typography>
                  Scan the QR code or use the URL below to make an online
                  payment.
                </Typography>
              </StackView>

              <Link to={portal.url} text={portal.url} size="s" />
            </StackView>
            <img
              src={portal.qrCodeDataUrl}
              height={qrCodeSize}
              width={qrCodeSize}
              alt="Family portal QR code"
            />
          </StackView>
        </Card>
      ) : null}
    </StackView>
  )
}
