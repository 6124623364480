import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { languages } from 'src/data/languages'

export const LanguageSelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={languages.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default LanguageSelectField
