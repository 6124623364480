import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Alert from 'src/components/molecules/Alert/Alert'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { usePatientOrdersQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

import { VaccineRefusalCheckboxes } from './SidepanelVisitVaccineAdministrationEdit'
import { useGetImmunizationToAdminister } from './useGetImmunizationToAdminister'

const UNDO_ADMINISTER_IMMUNIZATION = gql`
  mutation UndoAdministerImmunization(
    $input: UndoAdministerImmunizationInput!
  ) {
    undoAdministerImmunization(input: $input) {
      id
    }
  }
`

const SidepanelVisitVaccineAdministrationUndo = () => {
  const [administerImmunization, { loading: administeringImmunization }] =
    useMutation(UNDO_ADMINISTER_IMMUNIZATION, {
      onCompleted: () => {
        toast.success('Vaccine administration undone')
      },
      refetchQueries: ['FindPatientOrders'],
    })

  const { closeSidePanel } = useSidepanel()
  const { patientId, isPatientChart } = useIsPatientChart()
  const { orderId, encounterId, locationId } = useParams()
  const { orders } = usePatientOrdersQuery(patientId)
  const order = orders?.find((order) => order.id === orderId)

  const immunizationId = order?.supportingInfo?.[0]?.split('/')[1]

  const { data: immunizationData, loading: immunizationLoading } =
    useGetImmunizationToAdminister(immunizationId)

  const formMethods = useForm({
    defaultValues: {
      verbalRefusalByPatient: false,
      verbalRefusalByCaregiver: false,
      refusalFormCompleted: false,
    },
  })

  // Set form values
  useEffect(() => {
    if (immunizationData) {
      const { immunization } = immunizationData
      const values = {
        verbalRefusalByPatient: immunization.verbalRefusalByPatient,
        verbalRefusalByCaregiver: immunization.verbalRefusalByCaregiver,
        refusalFormCompleted: immunization.refusalFormCompleted,
      }

      formMethods.reset(values)
    }
  }, [immunizationData, formMethods, locationId])

  if (!isPatientChart || !order) {
    return <SidepanelNotAvailable />
  }

  const onSubmit = (data) => {
    const input = {
      ...data,
      encounterId,
      orderId,
      patientId,
    }
    administerImmunization({
      variables: { input: input },
      onCompleted: closeSidePanel,
    })
  }

  return (
    <SidepanelPage
      testId="sidepanel-vaccine-administration-undo"
      header="Undo vaccine administration"
      description={`Are you sure you want to undo the documented ${order.codeDisplay} vaccine administration?`}
      loading={immunizationLoading}
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Undo administration',
          submitting: administeringImmunization,
          submitButtonStyle: 'danger',
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <div className="my-4">
          <Alert
            title="Please read before you proceed"
            description="This undo action reverses the earlier-documented vaccine administration that was entered in error, and will accordingly adjust your practice's vaccine inventory count. The undo action also requires selection of one or more vaccine refusal reasons. Of note, this action once taken cannot be subsequently undone, and automatically results in cancellation of an underlying pended vaccine order, or revocation of an underlying signed vaccine order."
            style="danger"
          />
        </div>

        <VaccineRefusalCheckboxes
          immunizationData={immunizationData}
          showCheckboxesOverride={true}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelVisitVaccineAdministrationUndo
