import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'

import { navigate } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'
import Alert from 'src/components/molecules/Alert/Alert'

import { useVisit } from '../../../useVisit'
import { useVisitBillingState } from '../../../useVisitBillingState'

import BillingSectionHeader from './BillingSectionHeader'
import BillingTable from './BillingTable'

const OrderBillingSection = () => {
  const { patientId, appointmentId } = useVisit()
  const {
    data: {
      visit,
      primaryDiagnosis,
      unaccountedForOrders,
      orderBillableChargeItems: billableChargeItems,
      orderNonBillableChargeItems: nonBillableChargeItems,
      showOrderBillingWarning,
      isEditable,
    },
    loading,
  } = useVisitBillingState()

  if (loading) return null

  return (
    <StackView space={100}>
      <BillingSectionHeader
        title="Order-linked billing codes"
        subtitle="Orders that have been signed and completed will appear here with their associated billing code. These billing codes can be removed and unlinked from their corresponding order, and units and modifiers for billing codes can also be adjusted. Linkages of existing visit diagnosis codes to billing codes in this section can be modified."
      >
        <Button
          icon={ArrowTopRightOnSquareIcon}
          buttonStyle="link"
          text="View orders and diagnoses"
          onClick={() => {
            navigate(
              `/patient-charts/${patientId}/visits/${appointmentId}/orders`
            )
          }}
        />
      </BillingSectionHeader>
      <Divider />
      {showOrderBillingWarning && (
        <Alert
          title="Billing code(s) linked to the below order(s) were not pulled in since these orders were not signed and/or completed:"
          description={[
            ...(nonBillableChargeItems ?? []).map(
              (chargeItem) =>
                `• ${chargeItem.service.order.name} ${
                  chargeItem.service.order.codeDisplay
                } (CPT: ${chargeItem.billingCode.code}${
                  chargeItem.service.order?.diagnosisCodes?.length
                    ? `, DX: ${chargeItem.service.order.diagnosisCodes
                        .map(({ code }) => code)
                        .join(' • ')}`
                    : ''
                })`
            ),
            ...(unaccountedForOrders ?? []).map(
              (o) => `• ${o.name} ${o.codeDisplay}`
            ),
          ].join('\n')}
          data-testid="non-billable-orders-alert"
        />
      )}

      <BillingTable
        encounterId={visit.encounter.id}
        testId="order-billing-table"
        chargeItems={billableChargeItems}
        primaryDiagnosis={primaryDiagnosis}
        includeOrderColumn
        isEditable={isEditable}
      />
    </StackView>
  )
}

export default OrderBillingSection
