import { ReactNode } from 'react'

import Typography from 'src/components/atoms/Typography'

export const SubHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      textStyle="interface-strong-xs"
      tracking="tracking-widest"
      color="text-base-color-fg-subtle"
      className="block uppercase"
    >
      {children}
    </Typography>
  )
}
