import { useRef } from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { patientScoringSystems } from 'common/cdr/concepts/observations/patientScores/index'
import {
  patientScoreTypeToScoringSystem,
  patientScoreTypeToScoringSystemDiplay,
} from 'common/data/patientScoringSystems'
import { scoreDisplay } from 'common/data/scores'
import { format, parseISO } from 'date-fns'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'
import { sidepanelRoute } from 'src/lib/routes'

import { NotRecordedIllustration } from '../../PatientVisits/pages/IntakePage/IntakePage'

import { usePatientScoresQuery } from './usePatientScores'

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    padding: '16px',
  },
  cellClass: 'cursor-pointer',
  resizable: true,
  minWidth: 180,
  wrapText: true,
  autoHeight: true,
}

const columnDefs: ColDef[] = [
  {
    colId: 'score',
    headerName: 'Score',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center">
          <Typography textStyle="interface-strong-s">
            {scoreDisplay[data.score]}
          </Typography>
        </StackView>
      )
    },
    maxWidth: 108,
  },
  {
    colId: 'scoringSystem',
    headerName: 'Scoring System',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center">
          <Typography color="text-base-color-fg-muted">
            {patientScoreTypeToScoringSystemDiplay[data.__typename]}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
            className="overflow-hidden overflow-ellipsis"
          >
            {
              patientScoringSystems[
                patientScoreTypeToScoringSystem[data.__typename]
              ].description
            }
          </Typography>
        </StackView>
      )
    },
    cellStyle: { wordBreak: 'normal' },
  },
  {
    colId: 'comments',
    headerName: 'Comments',
    cellRenderer: ({ data }) => {
      return (
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
          className="overflow-hidden overflow-ellipsis"
        >
          {data.comments ?? 'N/A'}
        </Typography>
      )
    },
  },
  {
    colId: 'lastUpdated',
    headerName: 'Last updated',
    cellRenderer: ({ data }) => {
      return (
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
        >
          {format(parseISO(data.updatedAt), 'MM/dd/yyyy')}
        </Typography>
      )
    },
  },
]

export const EmptyPatientScores = () => {
  const params = useParams()
  const location = useLocation()
  const { id: patientId } = useParams()

  return (
    <StackView space={100} fullWidth>
      <StackView space={50} justifyContent="center" alignItems="center">
        <NotRecordedIllustration />
        <Typography textStyle="title-xs">No patient scores found</Typography>
        <Typography textStyle="body-s" color="text-base-color-fg-muted">
          There are no patient scores for this patient
        </Typography>
        <Button
          buttonStyle="secondary"
          text="Add"
          icon={PlusIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                { route: `/patients/${patientId}/scores/new` },
                location,
                params
              )
            )
          }
        />
      </StackView>
    </StackView>
  )
}

const PatientScoresTable = ({ patientId }: { patientId: string }) => {
  const gridRef = useRef<AgGridReact>()

  const { patientScores, loading } = usePatientScoresQuery(patientId)

  const params = useParams()
  const location = useLocation()

  if (loading) return <LoadingSpinner />
  return (
    <Table
      testId="patient-scores-table"
      innerRef={gridRef}
      rowData={patientScores}
      onRowClicked={(e) =>
        navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/scores/${
                patientScoreTypeToScoringSystem[e.data.__typename]
              }/view`,
            },
            location,
            params
          )
        )
      }
      domLayout="autoHeight"
      rowHeight={50}
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      noRowsOverlayComponent={EmptyPatientScores}
    />
  )
}

export default PatientScoresTable
