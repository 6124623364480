import { appointmentTypeDisplay } from 'common/data/appointmentTypes'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { formatMoneyInCents } from 'common/utils'
import { format } from 'date-fns'
import { capitalize, compact } from 'lodash'
import { PatientBillingEncounters } from 'types/graphql'

import {
  DropdownField,
  DropdownFieldProps,
} from 'src/components/atoms/Dropdown'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import { usePatientBillingEncountersQuery } from 'src/hooks/usePatientPayments/usePatientPayments'

export type BillingEncounterType =
  | 'APPOINTMENT_ENCOUNTER'
  | 'DIRECT_PATIENT_CHARGE_ENCOUNTER'
  | 'UNSUBMITTED_CLAIM'

export type BillingEncounterSelectFieldProps = DropdownFieldProps & {
  name: string
  patientId: string
  filteredEncounters?: PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters']
  filteredAppointments?: PatientBillingEncounters['patient']['appointments']
}

export const getEncounter = ({
  encounters,
  lineItemId,
}: {
  encounters: PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters']
  lineItemId: string
}):
  | PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters'][number]
  | undefined =>
  encounters?.find((encounter) => lineItemId?.includes(encounter.id))

export const getEncounterType = (
  encounter: PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters'][number]
): BillingEncounterType => {
  return encounter?.appointment
    ? 'APPOINTMENT_ENCOUNTER'
    : encounter?.directPatientChargeEncounter
      ? 'DIRECT_PATIENT_CHARGE_ENCOUNTER'
      : 'UNSUBMITTED_CLAIM'
}

const getBillingEncounterOptionName = (
  encounter: PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters'][number]
) => {
  return getEncounterType(encounter) === 'APPOINTMENT_ENCOUNTER'
    ? getAppointmentOptionName(encounter.appointment)
    : getEncounterType(encounter) === 'DIRECT_PATIENT_CHARGE_ENCOUNTER'
      ? getDirectPatientChargeOptionName(encounter.directPatientChargeEncounter)
      : encounter.id
}

const getBillingEncounterOptionBalance = (
  encounter: PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters'][number]
) => {
  return formatMoneyInCents(
    encounter.claims[0].remainingPatientResponsibilityCents ?? 0
  )
}

const getAppointmentOptionName = (
  appointment: PatientBillingEncounters['patient']['appointments'][number]
) => {
  return compact([
    getAppointmentDate(appointment) instanceof Date &&
      format(getAppointmentDate(appointment), 'MM/dd/yyyy'),
    `${
      appointmentTypeDisplay[appointment.appointmentDefinitions[0].type]
    }: ${capitalize(
      appointment.chiefComplaints
        .map((complaint) => chiefComplaintDisplay[complaint])
        .join(', ')
    )}`,
  ]).join(' · ')
}

const getDirectPatientChargeOptionName = (
  directPatientChargeEncounter: PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters'][number]['directPatientChargeEncounter']
) => {
  return compact([
    getDirectPatientChargeDate(directPatientChargeEncounter) instanceof Date &&
      format(
        getDirectPatientChargeDate(directPatientChargeEncounter),
        'MM/dd/yyyy'
      ),
    capitalize(
      directPatientChargeEncounter.directPatientChargeBillingCode.description
    ),
  ]).join(' · ')
}

const getAppointmentDate = (
  appointment: PatientBillingEncounters['patient']['appointments'][number]
) => new Date(appointment.start)

const getDirectPatientChargeDate = (
  directPatientChargeEncounter: PatientBillingEncounters['patient']['candidPatientRollup']['candidEncounters'][number]['directPatientChargeEncounter']
) => new Date(directPatientChargeEncounter.createdAt)

const BillingEncounterSelectField = ({
  name,
  patientId,
  filteredEncounters,
  filteredAppointments,
  ...rest
}: BillingEncounterSelectFieldProps) => {
  const { billingEncounters, appointmentsWithoutBillingEncounters, loading } =
    usePatientBillingEncountersQuery({
      patientId,
      shouldSkipQuery: !!filteredEncounters,
    })

  const billingEncountersToUse = filteredEncounters ?? billingEncounters
  const appointmentsToUse =
    filteredAppointments ?? appointmentsWithoutBillingEncounters

  const options = []

  billingEncountersToUse?.forEach((encounter) =>
    options.push({
      name: [
        getBillingEncounterOptionName(encounter),
        getBillingEncounterOptionBalance(encounter),
      ].join(' · '),
      value: encounter.id,
      date:
        getEncounterType(encounter) === 'APPOINTMENT_ENCOUNTER'
          ? getAppointmentDate(encounter.appointment)
          : getEncounterType(encounter) === 'DIRECT_PATIENT_CHARGE_ENCOUNTER'
            ? getDirectPatientChargeDate(encounter.directPatientChargeEncounter)
            : null,
    })
  )

  appointmentsToUse?.forEach((appointment) =>
    options.push({
      name: getAppointmentOptionName(appointment),
      value: appointment.id,
      date: getAppointmentDate(appointment),
    })
  )

  options.sort((a, b) => b.date - a.date)

  if (loading) return <LoadingSpinner />

  return <DropdownField name={name} options={options} {...rest} />
}

export default BillingEncounterSelectField
