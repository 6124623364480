import { isEmpty } from 'lodash'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { bloodTypeDisplay } from 'src/data/bloodTypes'
import { labTestResultDisplay } from 'src/data/labTestResults'
import { useBirthHistoryQuery } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'

import { RowDisplay } from './BirthHistoryV2'

export const AboRhBloodTypingSectionV2 = ({
  patientId,
}: {
  patientId: string
}) => {
  const { birthHistoryFormValues } = useBirthHistoryQuery(patientId)

  if (isEmpty(birthHistoryFormValues)) return null

  const { bloodType, naturalMotherHistory } = birthHistoryFormValues

  return (
    <StackView space={75} testId="birth-history-bloodtyping-section" divider>
      <Typography color="text-base-color-fg-default" textStyle="title-xs">
        ABO and Rh blood typing
      </Typography>
      <RowDisplay
        label="Patient blood type"
        value={bloodTypeDisplay[bloodType]}
      />
      <RowDisplay
        label="Natural mother blood type"
        value={bloodTypeDisplay[naturalMotherHistory?.bloodType]}
      />
      <RowDisplay
        label="Direct antiglobulin test (DAT)"
        value={
          labTestResultDisplay[
            naturalMotherHistory?.directAntiglobulinTestResult
          ]
        }
      />
    </StackView>
  )
}
