import { useApolloClient } from '@apollo/client'
import { LocalDate } from '@js-joda/core'
import { match } from 'ts-pattern'
import {
  PatientItemizedBillingHistory,
  PatientItemizedBillingHistoryVariables,
  PatientPayments,
  PatientPaymentsVariables,
  PatientPortalPatientDetails,
  PatientPortalPatientDetailsVariables,
  PatientPortalPatientItemizedBillingHistory,
  PatientPortalPatientItemizedBillingHistoryVariables,
  PatientPortalPaymentsAndRefunds,
  PatientPortalPaymentsAndRefundsVariables,
} from 'types/graphql'

import {
  PATIENT_ITEMIZED_BILLING_HISTORY_QUERY,
  PORTAL_PATIENT_ITEMIZED_BILLING_HISTORY_QUERY,
} from 'src/hooks/usePatientBillingStatement/usePatientBillingStatement'
import { PATIENT_PAYMENTS_QUERY } from 'src/hooks/usePatientPayments/usePatientPayments'
import { GET_PATIENT_PORTAL_PAYMENTS_AND_REFUNDS } from 'src/hooks/usePatientPortalPayments/usePatientPortalPayments'

import PrintPatientDetailedPaymentHistory from './components/PrintPatientDetailedPaymentHistory'
import PrintPatientItemizedBillingHistory from './components/PrintPatientItemizedBillingHistory'
import {
  PATIENT_DETAILS_FRAGMENT,
  TENANT_LETTERHEAD_FRAGMENT,
} from './fragments'
import { useGeneratePDF } from './useGeneratePDF'

type PatientDocumentInput = {
  type:
    | 'BILLING_HISTORY'
    | 'PAYMENT_HISTORY'
    | 'PORTAL_BILLING_HISTORY'
    | 'PORTAL_PAYMENT_HISTORY'
  startDate: LocalDate
  endDate: LocalDate
}

export const GET_PATIENT_PORTAL_PATIENT_DETAILS = gql`
  query PatientPortalPatientDetails($patientId: String!) {
    patientPortalPatient(patientId: $patientId) {
      id
      ...PatientDetailsFragment
      tenant {
        ...TenantLetterheadFragment
      }
      contactInformation {
        id
        mailingAddress {
          id
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
      primaryGuarantor {
        id
        givenName
        familyName
        contactInformation {
          id
          mailingAddress {
            id
            line1
            line2
            city
            state
            postalCode
            country
          }
        }
      }
    }
  }
  ${PATIENT_DETAILS_FRAGMENT}
  ${TENANT_LETTERHEAD_FRAGMENT}
`

export const useGeneratePatientDocument = () => {
  const generatePdf = useGeneratePDF()

  const client = useApolloClient()

  return async ({
    patientId,
    input,
  }: {
    patientId: string
    input: PatientDocumentInput
  }): Promise<URL> => {
    const component = await match(input)
      .with({ type: 'PAYMENT_HISTORY' }, async (args) => {
        const { data } = await client.query<
          PatientPayments,
          PatientPaymentsVariables
        >({
          query: PATIENT_PAYMENTS_QUERY,
          variables: {
            id: patientId,
          },
        })

        return (
          <PrintPatientDetailedPaymentHistory
            patient={data.patient}
            patientPayments={data.patientPayments}
            develoCandidPatientRefunds={data.develoCandidPatientRefunds}
            paymentPlanPaymentsForPatient={data.paymentPlanPaymentsForPatient}
            paymentPlanRefundsForPatient={data.paymentPlanRefundsForPatient}
            startDate={args.startDate}
            endDate={args.endDate}
            generatedAt={new Date()}
            tenant={data.patient.tenant}
          />
        )
      })
      .with({ type: 'PORTAL_PAYMENT_HISTORY' }, async (args) => {
        const { data: paymentData } = await client.query<
          PatientPortalPaymentsAndRefunds,
          PatientPortalPaymentsAndRefundsVariables
        >({
          query: GET_PATIENT_PORTAL_PAYMENTS_AND_REFUNDS,
          variables: {
            patientIds: [patientId],
          },
        })

        const { data: patientData } = await client.query<
          PatientPortalPatientDetails,
          PatientPortalPatientDetailsVariables
        >({
          query: GET_PATIENT_PORTAL_PATIENT_DETAILS,
          variables: {
            patientId,
          },
        })

        return (
          <PrintPatientDetailedPaymentHistory
            patient={patientData.patientPortalPatient}
            patientPayments={
              paymentData.patientPortalPaymentHistory.patientPayments
            }
            develoCandidPatientRefunds={
              paymentData.patientPortalPaymentHistory.develoCandidPatientRefunds
            }
            paymentPlanPaymentsForPatient={
              paymentData.patientPortalPaymentHistory
                .paymentPlanPaymentsForPatient
            }
            paymentPlanRefundsForPatient={
              paymentData.patientPortalPaymentHistory
                .paymentPlanRefundsForPatient
            }
            startDate={args.startDate}
            endDate={args.endDate}
            generatedAt={new Date()}
            tenant={patientData.patientPortalPatient.tenant}
          />
        )
      })
      .with({ type: 'BILLING_HISTORY' }, async (args) => {
        const { data } = await client.query<
          PatientItemizedBillingHistory,
          PatientItemizedBillingHistoryVariables
        >({
          query: PATIENT_ITEMIZED_BILLING_HISTORY_QUERY,
          variables: {
            patientId,
            filters: {
              startDate: args.startDate.toString(),
              endDate: args.endDate.toString(),
            },
          },
        })

        return (
          <PrintPatientItemizedBillingHistory
            billingStatement={data.billingStatement}
            endDate={args.endDate}
            generatedAt={new Date()}
            patient={data.patient}
            startDate={args.startDate}
          />
        )
      })
      .with({ type: 'PORTAL_BILLING_HISTORY' }, async (args) => {
        const { data } = await client.query<
          PatientPortalPatientItemizedBillingHistory,
          PatientPortalPatientItemizedBillingHistoryVariables
        >({
          query: PORTAL_PATIENT_ITEMIZED_BILLING_HISTORY_QUERY,
          variables: {
            patientId,
            filters: {
              startDate: args.startDate.toString(),
              endDate: args.endDate.toString(),
            },
          },
        })

        return (
          <PrintPatientItemizedBillingHistory
            billingStatement={data.patientPortalBillingHistory}
            endDate={args.endDate}
            generatedAt={new Date()}
            patient={data.patientPortalPatient}
            startDate={args.startDate}
          />
        )
      })
      .exhaustive()

    return new Promise((resolve) => {
      void generatePdf({
        component,
        callback: (doc) => {
          resolve(doc.output('bloburl'))
        },
      })
    })
  }
}
