import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import SwapButton from 'src/components/molecules/SwapButton/SwapButton'
import { VISIT_CONDITION_FRAGMENT } from 'src/pages/PatientChartsPage/PatientVisits/fragments'

const DIAGNOSIS_CODE_GROUP_QUERY = gql`
  query DiagnosisCodeGroupQuery($id: String!) {
    diagnosisCodeGroup(id: $id) {
      id
      diagnosisCodes {
        id
        code
        description
      }
    }
  }
`

const SWAP_DIAGNOSIS_CODE_MUTATION = gql`
  mutation SwapDiagnosisCodeMutation(
    $encounterId: String!
    $conditionId: String!
    $diagnosisCodeId: String!
    $isPrimary: Boolean!
  ) {
    updateVisitDiagnosis(
      encounterId: $encounterId
      conditionId: $conditionId
      diagnosisCodeId: $diagnosisCodeId
      isPrimary: $isPrimary
    ) {
      id
      primaryDiagnosis {
        ...VisitConditionFragment
      }
    }
  }
  ${VISIT_CONDITION_FRAGMENT}
`

interface DiagnosisCodeSwapButtonProps {
  encounterId: string
  conditionId: string
  diagnosisCodeId: string
  diagnosisCodeGroupId: string
}

const DiagnosisCodeSwapButton: React.FC<DiagnosisCodeSwapButtonProps> = ({
  encounterId,
  conditionId,
  diagnosisCodeId,
  diagnosisCodeGroupId,
}) => {
  const [swapDiagnosisCodeMutation] = useMutation(
    SWAP_DIAGNOSIS_CODE_MUTATION,
    {
      onCompleted: () => {
        toast.success('Diagnosis code updated')
      },
    }
  )
  const swap = (option) => {
    swapDiagnosisCodeMutation({
      variables: {
        encounterId,
        conditionId,
        diagnosisCodeId: option.id,
        isPrimary: true,
      },
    })
  }
  const { data, loading, error } = useQuery(DIAGNOSIS_CODE_GROUP_QUERY, {
    variables: {
      id: diagnosisCodeGroupId,
    },
  })
  if (loading || error) {
    return null
  }

  return (
    <SwapButton
      options={data.diagnosisCodeGroup.diagnosisCodes}
      selectedOptionId={diagnosisCodeId}
      swap={swap}
    />
  )
}

export default DiagnosisCodeSwapButton
