import groupBy from 'lodash/groupBy'
import { OrderCategory } from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Dropdown, { DropdownItem } from 'src/components/atoms/Dropdown'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { sidepanelRoute } from 'src/lib/routes'

const internalOrderCategoryOptions = groupBy(
  Object.values(orderCategoryConfig),
  'type'
)

const CreateOrderDropdown = ({
  buttonText = 'Create order',
  route,
  hideExternal = false,
  hideInternal = false,
}: {
  buttonText?: string
  route: string
  hideExternal?: boolean
  hideInternal?: boolean
}) => {
  const location = useLocation()
  const { currentUser } = useEmrAuth()

  const onSelect = (orderType: OrderCategory) => {
    const sidepanelWidth = orderType === 'HG_LAB' ? 'medium' : undefined
    return navigate(
      sidepanelRoute(
        {
          route: `${route}${orderType}/create`,
          width: sidepanelWidth,
        },
        location,
        // params doesn't reliably update after navigating to a new route, but location.search does
        Object.fromEntries(new URLSearchParams(location.search).entries())
      )
    )
  }

  return (
    <Dropdown
      text={buttonText}
      name="create-order"
      className="!bg-primary !text-white"
    >
      <StackView direction="row" divider className="w-72">
        {!hideInternal && (
          <StackView>
            {internalOrderCategoryOptions.internal.map((category) => (
              <DropdownItem
                key={category.display}
                value={category.category}
                onSelect={onSelect}
              >
                <Typography noWrap>{category.display}</Typography>
              </DropdownItem>
            ))}
          </StackView>
        )}
        {!hideExternal && (
          <StackView>
            {internalOrderCategoryOptions.external
              .filter(
                (category) =>
                  category.category !== 'HG_LAB' ||
                  currentUser.featureFlags.includes('HEALTH_GORILLA')
              )
              .map((category) => (
                <DropdownItem
                  key={category.display}
                  value={category.category}
                  onSelect={onSelect}
                >
                  <Typography noWrap>{category.display}</Typography>
                </DropdownItem>
              ))}
          </StackView>
        )}
      </StackView>
    </Dropdown>
  )
}

export default CreateOrderDropdown
