import { ComponentProps, ReactElement } from 'react'

import clsx from 'clsx'

import { SelectField as RWSelectField } from '@redwoodjs/forms'

export type RWSelectFieldProps = ComponentProps<typeof RWSelectField>

interface Option {
  value: string | undefined
  display: string
}

export interface SelectFieldProps extends RWSelectFieldProps {
  includeEmptyOption?: boolean
  emptyOptionValue?: string
  emptyOptionDisplay?: string
  options?: Option[]
  testId?: string
}

export const EMPTY_OPTION: Option = {
  value: '',
  display: '',
}

export const classes = [
  'block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-gray-900',
  'text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm',
]

const errorClasses = [
  'border-red-300 focus:border-danger',
  'focus:outline-none focus:ring-danger',
  'text-red-900 placeholder-red-300',
]

const EmptyOption = ({
  value = EMPTY_OPTION.value,
  display = EMPTY_OPTION.display,
}: Option) => (
  <option value={value} key={value}>
    {display}
  </option>
)

export default function SelectField({
  options,
  className,
  errorClassName,
  includeEmptyOption = true,
  emptyOptionValue,
  emptyOptionDisplay,
  testId,
  ...rest
}: SelectFieldProps): ReactElement {
  return (
    <RWSelectField
      className={clsx(...classes, className)}
      errorClassName={clsx(...errorClasses, ...classes, errorClassName)}
      data-testid={testId}
      {...rest}
    >
      {options && (
        <>
          {includeEmptyOption && (
            <EmptyOption
              value={emptyOptionValue}
              display={emptyOptionDisplay}
            />
          )}
          {options.map((option) => (
            <option value={option.value} key={option.value}>
              {option.display}
            </option>
          ))}
        </>
      )}
    </RWSelectField>
  )
}
