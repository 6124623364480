import type { CreateUserInput, CreateUserMutation } from 'types/graphql'

import { navigate, routes, useParams, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import NewUserForm from 'src/components/User/NewUserForm/NewUserForm'
import { sidepanelRoute } from 'src/lib/routes'

const CREATE_USER_MUTATION = gql`
  mutation CreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`

const NewUser = () => {
  const location = useLocation()
  const params = useParams()

  const [createUser, { loading, error }] = useMutation<CreateUserMutation>(
    CREATE_USER_MUTATION,
    {
      onCompleted: (data) => {
        toast.success('User created')
        if (data?.createUser?.id) {
          navigate(
            sidepanelRoute(
              { route: `/users/${data.createUser.id}` },
              location,
              params
            )
          )
        } else {
          navigate(routes.staff())
        }
      },
      refetchQueries: ['FindUsers'],
    }
  )

  const onSave = (input: CreateUserInput) => {
    void createUser({ variables: { input } })
  }

  return <NewUserForm onSave={onSave} loading={loading} error={error} />
}

export default NewUser
