import { useEffect } from 'react'

import { compact } from 'lodash'
import { useParams } from 'react-router-dom'
import { DiagnosisCode, DiagnosisCodeFilter } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import { CheckboxField } from 'src/components/atoms/Checkbox'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import DiagnosisSearchField from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { useConditionsQuery } from 'src/pages/PatientChartsPage/PatientHistory/Diagnoses/useConditions'
import { useAddVisitDiagnosisMutation } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useGetVisitDiagnoses } from 'src/pages/Sidepanel/SidepanelVisitDiagnosisCreate/useGetVisitDiagnoses'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type FormState = {
  isPrimary: boolean
  code: DiagnosisCode
  codeSearchFilter: DiagnosisCodeFilter[]
  codeSearchInput: string
  comment: string
}

const SidepanelVisitDiagnosisCreate = () => {
  const { currentUser } = useEmrAuth()

  const { encounterId } = useParams()
  const { patientId } = useIsPatientChart()
  const { closeSidePanel } = useSidepanel()

  const { primaryDiagnosis, additionalDiagnoses } =
    useGetVisitDiagnoses(encounterId)

  const { conditionsGroupedByCode } = useConditionsQuery(patientId)

  const formMethods = useForm<FormState>({
    defaultValues: {
      isPrimary:
        !primaryDiagnosis && !additionalDiagnoses?.length ? true : false,
      comment: '',
    },
  })

  const selectedDiagnosisCode = formMethods.watch('code')

  const [addVisitDiagnosis, { loading: addingVisitDiagnosis }] =
    useAddVisitDiagnosisMutation()

  const onSubmit = (data: FormState) => {
    void addVisitDiagnosis({
      variables: {
        encounterId,
        diagnosisCodeId: data.code.id,
        isPrimary: data.isPrimary,
        comment: data.comment,
      },
      onCompleted: () => {
        toast.success('Visit diagnosis added')
        closeSidePanel()
      },
      refetchQueries: ['FindPatientOrders'],
    })
  }

  useEffect(() => {
    if (primaryDiagnosis || additionalDiagnoses?.length) {
      formMethods.setValue('isPrimary', false)
    }
  }, [primaryDiagnosis, additionalDiagnoses, formMethods])

  const allowDiagnosisComment = currentUser.featureFlags.includes(
    'DIAGNOSIS_AND_ZCODE_COMMENTS'
  )

  return (
    <SidepanelPage header="Add visit diagnosis">
      <SidepanelForm
        key={selectedDiagnosisCode?.code}
        footerProps={{
          submitText: 'Add diagnosis',
          submitting: addingVisitDiagnosis,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <FormInputList
          items={compact([
            {
              name: 'code',
              label: 'Diagnosis code',
              required: true,
              direction: 'col',
              formInputComponent: DiagnosisSearchField,
              inputProps: {
                patientId,
                encounterId,
                searchSet: 'ALL',
                defaultSearching: !selectedDiagnosisCode,
              },
            },
            {
              name: 'isPrimary',
              label: '',
              hideLabel: true,
              direction: 'col',
              formInputComponent: CheckboxField,
              inputProps: {
                label:
                  'This is the primary diagnosis. If there is an existing primary diagnosis, it will be replaced.',
              },
            },
            allowDiagnosisComment && {
              name: 'comment',
              label: 'Comment',
              direction: 'col',
              formInputComponent: WysiwygField,
              inputProps: {
                testId: 'create-visit-diagnosis-comment',
                defaultValue:
                  conditionsGroupedByCode[selectedDiagnosisCode?.code]?.comment,
              },
            },
          ])}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelVisitDiagnosisCreate
