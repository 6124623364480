import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { contactOrganizationTypes } from 'src/data/contactOrganizationTypes'

export const ContactOrganizationTypeSelectField = (
  props: RWSelectFieldProps
) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={contactOrganizationTypes.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default ContactOrganizationTypeSelectField
