import type { CreatePatientEducationDocumentInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField'
import PatientEducationDocumentTypeSelectField from 'src/components/atoms/PatientEducationDocumentTypeSelectField/PatientEducationDocumentTypeSelectField'
import FormInputList, {
  Item,
} from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

const CREATE_PATIENT_EDUCATION_DOCUMENT_MUTATION = gql`
  mutation CreatePatientEducationDocument(
    $input: CreatePatientEducationDocumentInput!
  ) {
    createPatientEducationDocument(input: $input) {
      id
    }
  }
`

export const patientEducationInputListItems: (opts?: {
  type?: string
  patientId?: string
  isDocumentCase?: boolean
}) => Item[] = () => [
  {
    name: 'type',
    label: 'Document sub-type',
    required: true,
    formInputComponent: PatientEducationDocumentTypeSelectField,
  },
  {
    name: 'title',
    label: 'Document title',
    formInputComponent: InputField,
    required: true,
  },
]

const CreatePatientEducationDocument = () => {
  const { closeSidePanel } = useSidepanel()
  const [
    createPatientEducationDocument,
    { loading: creatingPatientEducationDocument },
  ] = useMutation(CREATE_PATIENT_EDUCATION_DOCUMENT_MUTATION, {
    onCompleted: () => {
      toast.success('Patient education document created')
      closeSidePanel()
    },
    refetchQueries: ['PatientEducationDocumentsQuery'],
  })
  const formMethods = useForm<CreatePatientEducationDocumentInput>()

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Upload document',
        submitting: creatingPatientEducationDocument,
      }}
      formMethods={formMethods}
      onSubmit={async (value) => {
        const { binary: binaryInput, ...input } = value

        input['binary'] = {
          content: await base64EncodeFile(binaryInput[0]),
          contentType: binaryInput[0].type,
        }
        createPatientEducationDocument({ variables: { input } })
      }}
    >
      <FormInputList
        items={[
          ...patientEducationInputListItems(),
          {
            name: 'binary',
            label: 'Document upload',
            required: true,
            formInputComponent: FileInput,
          },
        ]}
      />
    </SidepanelForm>
  )
}

export default CreatePatientEducationDocument
