import { formatDisplayName } from 'common/utils'
import {
  ListUserDropdownV2,
  ListUserDropdownV2Variables,
  UserStatus,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { DropdownV2Field } from 'src/components/atoms/DropdownV2/DropdownV2'

const QUERY = gql`
  query ListUserDropdownV2 {
    users {
      id
      givenName
      familyName
      userType {
        id
        name
        userCategory
      }
      status
    }
  }
`

export const UserDropdownV2Field = ({
  excludedStatuses = [],
  isMultiSelect = true,
  name,
  onSetValue,
  showMenuItemCheckbox = true,
  showMenuItemSelectedOptionIcon = false,
  testId,
  userTypes = [],
}: {
  excludedStatuses?: UserStatus[]
  isMultiSelect?: boolean
  name: string
  onSetValue: (value: string[] | string) => void
  showMenuItemCheckbox?: boolean
  showMenuItemSelectedOptionIcon?: boolean
  testId?: string
  userTypes?: string[]
}) => {
  const { data } = useQuery<ListUserDropdownV2, ListUserDropdownV2Variables>(
    QUERY
  )

  let users: ListUserDropdownV2['users'] = data?.users ?? []

  if (userTypes.length > 0) {
    users = users.filter((user: ListUserDropdownV2['users'][number]) =>
      userTypes.includes(user.userType?.name)
    )
  }

  if (excludedStatuses.length > 0) {
    users = users.filter(
      (user: ListUserDropdownV2['users'][number]) =>
        !excludedStatuses.includes(user.status)
    )
  }

  const options =
    users.map((user: ListUserDropdownV2['users'][number]) => ({
      value: user.id,
      label: formatDisplayName(user),
    })) ?? []

  return (
    <DropdownV2Field
      isMultiSelect={isMultiSelect}
      menuItemProps={{
        showMenuItemCheckbox,
        showMenuItemSelectedOptionIcon,
      }}
      name={name}
      onSetValue={onSetValue}
      options={options}
      testId={testId}
    />
  )
}
