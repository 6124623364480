import { ArrowUpTrayIcon, PlusIcon } from '@heroicons/react/24/solid'
import {
  CreatableDocumentSubType,
  CreatableDocumentType,
  creatableDocumentTypes,
} from 'common/cdr/concepts/documents'
import capitalize from 'lodash/capitalize'

import { useForm, Form } from '@redwoodjs/forms'
import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import InputField from 'src/components/atoms/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'
import { sidepanelRoute } from 'src/lib/routes'

import DocumentsTable from './DocumentsTable'

const CreateDocumentButton = ({
  type,
  subType,
  closePanel,
}: {
  type: CreatableDocumentType
  subType?: CreatableDocumentSubType
  closePanel: () => void
}) => {
  const location = useLocation()
  const params = useParams()

  return (
    <Button
      justifyContent="start"
      buttonStyle="ghost"
      icon={PlusIcon}
      text={
        subType
          ? capitalize(`${creatableDocumentTypes[type].subTypes[subType]}`)
          : capitalize(creatableDocumentTypes[type].display)
      }
      onClick={() => {
        navigate(
          sidepanelRoute(
            {
              route: `/documents/patient-document/create/`,
              type,
              subType,
            },
            location,
            params
          )
        )
        closePanel()
      }}
    />
  )
}

const PatientDocuments = () => {
  const params = useParams()
  const location = useLocation()
  const { id: patientId } = params
  const formMethods = useForm()

  const searchInput = formMethods.watch('search')

  return (
    <StackView space={75} className="px-6 pb-8 pt-4">
      <StackView>
        <StackView direction="row" space={50} alignItems="center">
          <StackView direction="row" space={100} alignItems="center">
            <Typography textStyle="title" fontWeight="semibold">
              Documents
            </Typography>
            <Form formMethods={formMethods} className="w-80">
              <InputField
                name="search"
                placeholder="Search to filter documents"
              />
            </Form>
          </StackView>

          <Button
            icon={ArrowUpTrayIcon}
            onClick={() =>
              navigate(
                sidepanelRoute(
                  {
                    route: `/documents/patient-document/upload`,
                  },
                  location,
                  params
                )
              )
            }
            text="Upload"
          />

          <div className="relative">
            <PopoverButton
              panelXPosition="right"
              panelWidth="w-fit"
              buttonProps={{
                buttonStyle: 'secondary',
                icon: PlusIcon,
                text: 'Create',
              }}
              renderPanel={({ close }) => (
                <StackView className="p-core-space-25">
                  {Object.keys(creatableDocumentTypes).map(
                    (type: CreatableDocumentType) => (
                      <>
                        {Object.keys(creatableDocumentTypes[type].subTypes)
                          .length > 0 ? (
                          <>
                            {Object.keys(
                              creatableDocumentTypes[type].subTypes
                            ).map((subType: CreatableDocumentSubType) => (
                              <CreateDocumentButton
                                key={subType ? `${type}.${subType}` : type}
                                type={type}
                                subType={subType}
                                closePanel={close}
                              />
                            ))}
                          </>
                        ) : (
                          <CreateDocumentButton
                            key={type}
                            type={type}
                            closePanel={close}
                          />
                        )}
                      </>
                    )
                  )}
                </StackView>
              )}
            />
          </div>
        </StackView>
      </StackView>
      <DocumentsTable patientId={patientId} searchInput={searchInput} />
    </StackView>
  )
}

export default PatientDocuments
