import { useMemo } from 'react'

import maxBy from 'lodash/maxBy'
import { FindPatientScreens } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { ORDER_WITH_OBSERVATIONS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

const PATIENT_SCREENS_QUERY = gql`
  query FindPatientScreens($id: String!) {
    patient(id: $id) {
      id
      screens {
        id
        name
        value
        effectiveAt
        updatedAt
        encounterReference
        orderReference
        order {
          ...OrderWithObservationsFragment
        }
        code {
          system
          code
          display
        }
        interpretation {
          system
          code
          display
        }
      }
    }
  }
  ${ORDER_WITH_OBSERVATIONS_FRAGMENT}
`

export const useScreensQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientScreens>(
    PATIENT_SCREENS_QUERY,
    {
      variables: { id: patientId },
    }
  )

  const screensLastUpdated = useMemo(() => {
    return data?.patient?.screens?.length > 0
      ? maxBy(data.patient.screens, (a) => a.updatedAt).updatedAt
      : null
  }, [data])

  return {
    screens: data?.patient?.screens,
    screensLastUpdated,
    loading,
    error,
  }
}
