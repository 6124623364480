import { ArchiveFax, ArchiveFaxVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

const ARCHIVE_FAX = gql`
  mutation ArchiveFax($id: String!) {
    archiveFax(id: $id) {
      id
      status
      archivedAt
    }
  }
`
export const useArchiveFax = (faxId: string) => {
  return useMutation<ArchiveFax, ArchiveFaxVariables>(ARCHIVE_FAX, {
    variables: { id: faxId },
    refetchQueries: ['PaginatedOutboundFaxesQuery'],
    onCompleted: () => {
      toast.success('Archived outbound fax')
    },
  })
}
