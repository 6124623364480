import '@js-joda/timezone'
import { useState } from 'react'

import { PencilIcon } from '@heroicons/react/24/solid'
import { DateTimeFormatter, ZonedDateTime, convert } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'
import {
  appointmentDateInLocationTimezone,
  displayAppointmentTimeRange,
} from 'common/data/appointments'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { dateTimeFormatter } from 'common/data/date'
import { formatDisplayName } from 'common/utils'
import { formatPhoneNumber } from 'react-phone-number-input'
import { Route, Routes, useParams } from 'react-router-dom'
import {
  AppointmentChartingStatus,
  AppointmentStatus,
  FindAppointmentDetails,
  UpdateVisitDetails,
} from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { Link, navigate, routes, useLocation } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import AppointmentAuditRecordsCell from 'src/components/AppointmentAuditRecordsCell'
import {
  AppointmentBookingsWarningProvider,
  useAppointmentBookingWarningsContext,
} from 'src/components/AppointmentBookingWarningModals/AppointmentBookingWarningModals'
import AppointmentCaregiverDetailsCell from 'src/components/AppointmentCaregiverDetailsCell'
import AppointmentDefinitionComboBoxCell from 'src/components/AppointmentDefinition/AppointmentDefinitionComboBoxCell'
import AppointmentInsuranceDetailsCell from 'src/components/AppointmentInsuranceDetailsCell'
import AppointmentPatientDetailsCell from 'src/components/AppointmentPatientDetailsCell'
import Box from 'src/components/atoms/Box/Box'
import Button, { Submit } from 'src/components/atoms/Button/Button'
import ChiefComplaintSelectField from 'src/components/atoms/ChiefComplaintSelectField/ChiefComplaintSelectField'
import StackView from 'src/components/atoms/StackView/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import AppointmentInformationHeaderCell, {
  QUERY,
} from 'src/components/molecules/AppointmentInformationHeaderCell'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import TabNav from 'src/components/molecules/TabNav/TabNav'
import { AppointmentNotificationHistoryTable } from 'src/components/organisms/AppointmentNotificationHistoryTable'
import { useStartVisit } from 'src/components/organisms/ScheduleTable/useAppointmentSchedule'
import { PatientVisitTasksSection } from 'src/components/PatientVisitTasksSection/PatientVisitTasksSection'
import PractitionerDropdownCell from 'src/components/Practitioner/PractitionerDropdownCell'
import {
  StartVisitWarningProvider,
  useStartVisitWarningContext,
} from 'src/components/StartVisitWarningModals/StartVisitWarningModals'
import {
  VisitCheckInWarningProvider,
  useVisitCheckInWarningContext,
} from 'src/components/VisitCheckInWarningModals/VisitCheckInWarningModals'
import {
  checkedInStatuses,
  isAppointmentCancelled,
} from 'src/data/appointmentStatus'
import { formatDateDisplay } from 'src/lib/formatters'
import { useVisitQuery } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'

const UPDATE_VISIT_DETAILS_MUTATION = gql`
  mutation UpdateVisitDetails(
    $visitId: String!
    $input: UpdateVisitDetailsInput!
  ) {
    updateVisitDetails(visitId: $visitId, input: $input) {
      id
      chiefComplaints
      start
      end
      location {
        id
        name
        timezone
      }
      practitioner {
        id
        namePrefix
        nameSuffix
        givenName
        middleName
        familyName
      }
      appointmentDefinitions {
        id
        name
        code
        type
      }
      visitComment
      patientFacingTasks {
        __typename
        ... on PatientCheckInTask {
          id
          isComplete
          completedAt
          lastSentAt
          lastSentToNumber
        }
        ... on PatientRegistrationTask {
          id
          isComplete
          completedAt
          lastSentAt
          lastSentToNumber
        }
        ... on PatientScreeningTask {
          id
          isComplete
          questionnaireName
          questionnaireCode
          questionnaireIntendedFor
          completedAt
          lastSentAt
          lastSentToNumber
        }
      }
    }
  }
`

const AppointmentVisitDetails = ({
  appointment,
}: {
  appointment: UpdateVisitDetails['updateVisitDetails']
}) => {
  const {
    start,
    visitComment,
    chiefComplaints,
    practitioner,
    location,
    appointmentDefinitions,
  } = appointment

  const { checkForAppointmentBookingWarnings } =
    useAppointmentBookingWarningsContext()

  const formMethods = useForm({
    defaultValues: {
      visitDate: formatDateDisplay(start),
      visitTime: displayAppointmentTimeRange(appointment),
      appointmentDefinitionIds: appointmentDefinitions.map(({ id }) => id),
      chiefComplaints: chiefComplaints.filter(Boolean) ?? [],
      practitionerId: practitioner.id,
      visitLocation: location.name,
      visitComment: visitComment,
    },
  })

  const [isEditingAppointmentDetails, setIsEditingAppointmentDetails] =
    useState(false)

  const [updateVisitDetails, { loading: updatingVisitDetails }] = useMutation(
    UPDATE_VISIT_DETAILS_MUTATION
  )

  return (
    <StackView className="h-full" gap={200}>
      {appointment.patientFacingTasks?.length ? (
        <PatientVisitTasksSection appointment={appointment} />
      ) : null}
      <Form
        className="h-full"
        formMethods={formMethods}
        onSubmit={async (values) => {
          const {
            appointmentDefinitionIds,
            chiefComplaints,
            practitionerId,
            visitComment,
          } = values

          const appointmentStart = ZonedDateTime.parse(appointment.start)

          const date = convert(appointmentStart.toLocalDate()).toDate()

          const startTime = appointmentStart
            .toLocalTime()
            .format(DateTimeFormatter.ofPattern('h:mm a').withLocale(Locale.US))

          const { confirmed } = await checkForAppointmentBookingWarnings({
            date,
            startTime,
            visitTypes: appointmentDefinitionIds,
            locationId: appointment.location.id,
            practitionerId,
          })

          if (!confirmed) return

          void updateVisitDetails({
            variables: {
              visitId: appointment.id,
              input: {
                appointmentDefinitionIds,
                chiefComplaints: chiefComplaints.filter(Boolean),
                practitionerId,
                visitComment,
              },
            },
            onCompleted: () => {
              setIsEditingAppointmentDetails(false)
            },
          })
        }}
      >
        <StackView
          direction="row"
          justifyContent="between"
          className="pt-core-space-25"
          space={25}
        >
          <StackView className="flex-grow">
            <Typography textStyle="title-xs">Visit details</Typography>
            <Typography textStyle="body-xs">
              {!isEditingAppointmentDetails
                ? 'This section includes details regarding the upcoming visit. Visit type(s), chief complaint(s), visit practitioner, and visit comments can be updated if needed.'
                : 'Edit visit details where needed before saving.'}
            </Typography>
          </StackView>
          <StackView
            direction="row"
            space={50}
            justifyContent="end"
            fullWidth={false}
          >
            {!isEditingAppointmentDetails ? (
              <Button
                onClick={() => setIsEditingAppointmentDetails(true)}
                buttonStyle="secondary"
                icon={PencilIcon}
                text="Edit"
                disabled={updatingVisitDetails}
                testId="edit-visit-details-btn"
              />
            ) : (
              <>
                <Button
                  onClick={() => setIsEditingAppointmentDetails(false)}
                  buttonStyle="secondary"
                  text="Cancel"
                  disabled={updatingVisitDetails}
                />
                <Submit
                  buttonStyle="primary"
                  text="Save"
                  disabled={updatingVisitDetails}
                  loading={updatingVisitDetails}
                />
              </>
            )}
          </StackView>
        </StackView>

        {isEditingAppointmentDetails ? (
          <FormInputList
            items={[
              {
                name: 'appointmentDefinitionIds',
                label: 'Visit type',
                required: true,
                formInputComponent: AppointmentDefinitionComboBoxCell,
                direction: 'col',
              },
              {
                name: 'chiefComplaints',
                label: 'Chief complaints',
                formInputComponent: ChiefComplaintSelectField,
                inputProps: {
                  appointmentDefinitionId: formMethods.getValues(
                    'appointmentDefinitionIds'
                  )?.[0],
                },
                direction: 'col',
              },
              {
                name: 'practitionerId',
                label: 'Visit practitioner',
                required: true,
                formInputComponent: PractitionerDropdownCell,
                direction: 'col',
              },
              {
                name: 'visitComment',
                label: 'Visit comments',
                formInputComponent: TextAreaField,
                inputProps: {
                  rows: 6,
                },
                direction: 'col',
              },
            ]}
          />
        ) : (
          <DataDisplayList
            data={[
              {
                label: 'Visit date',
                value: appointmentDateInLocationTimezone(appointment).format(
                  dateTimeFormatter('MM/dd/yyyy')
                ),
              },
              {
                label: 'Visit time',
                value: displayAppointmentTimeRange(appointment),
              },
              {
                label: 'Visit type',
                value: appointmentDefinitions
                  .map(({ name }) => name)
                  .join(', '),
              },
              {
                label: 'Chief complaints',
                value: chiefComplaints
                  .map((complaint) => chiefComplaintDisplay[complaint])
                  .join(', '),
              },
              {
                label: 'Visit practitioner',
                value: formatDisplayName(practitioner),
              },
              {
                label: 'Visit location',
                value: location.name,
              },
              {
                label: 'Visit comments',
                value: visitComment,
              },
            ]}
          />
        )}
      </Form>
    </StackView>
  )
}

const EstablishedPatientContent = ({
  appointment,
}: {
  appointment: FindAppointmentDetails['appointment']
}) => {
  const { appointmentId } = useParams()
  const rootPath = `/appointments/${appointmentId}`

  return (
    <>
      <TabNav
        rootPath={rootPath}
        sidepanel
        tabs={[
          {
            name: 'Visit',
            key: 'visit',
            to: `${rootPath}/visit`,
          },
          {
            name: 'Patient',
            key: 'patient',
            to: `${rootPath}/patient`,
          },
          {
            name: 'Caregiver',
            key: 'caregiver',
            to: `${rootPath}/caregivers`,
          },
          {
            name: 'Insurance',
            key: 'insurance',
            to: `${rootPath}/insurance`,
          },
          {
            name: 'History',
            key: 'history',
            to: `${rootPath}/history`,
          },
        ]}
      />
      <Box className="-mx h-full overflow-y-auto">
        <Routes>
          <Route
            index
            path="visit"
            element={<AppointmentVisitDetails appointment={appointment} />}
          />
          <Route
            path="patient"
            element={
              <AppointmentPatientDetailsCell appointmentId={appointmentId} />
            }
          />
          <Route
            path="caregivers"
            element={
              <AppointmentCaregiverDetailsCell appointmentId={appointmentId} />
            }
          />
          <Route
            path="insurance"
            element={
              <AppointmentInsuranceDetailsCell appointmentId={appointmentId} />
            }
          />
          <Route
            path="history"
            element={
              <StackView space={125}>
                <AppointmentAuditRecordsCell appointmentId={appointmentId} />
                <StackView space={50}>
                  <SectionHeader title="Visit reminder history" />
                  <AppointmentNotificationHistoryTable
                    appointmentId={appointmentId}
                  />
                </StackView>
              </StackView>
            }
          />
        </Routes>
      </Box>
    </>
  )
}

const NewPatientContent = ({
  appointment,
}: {
  appointment: FindAppointmentDetails['appointment']
}) => {
  return (
    <StackView space={100} className="overflow-y-auto border-t">
      <DataDisplayList
        title="Contact details"
        subtitle="This section includes contact information details."
        data={[
          {
            label: 'Phone number',
            value: formatPhoneNumber(
              appointment.patientRegistrationIntent.phoneNumber
            ),
          },
          {
            label: 'Email address',
            value: appointment.patientRegistrationIntent.email,
          },
        ]}
      />
      <AppointmentVisitDetails appointment={appointment} />
    </StackView>
  )
}

export const AppointmentPatientActions = ({
  id: appointmentId,
  patientId,
  status,
  chartingStatus,
}: {
  id: string
  patientId: string
  status: AppointmentStatus
  chartingStatus: AppointmentChartingStatus
}) => {
  const { hasRole } = useEmrAuth()
  const { search } = useLocation()
  const { visit, loading } = useVisitQuery(appointmentId)
  const { checkForStartVisitWarnings, isCheckingForWarnings } =
    useStartVisitWarningContext()
  const { checkForVisitCheckInWarnings } = useVisitCheckInWarningContext()
  const [startVisit, { loading: startingVisit }] = useStartVisit()
  const hasPatientArrived = [...checkedInStatuses, 'ARRIVED'].includes(status)

  if (loading) return null

  return (
    <StackView
      alignItems="center"
      direction="row"
      justifyContent="start"
      space={50}
    >
      {hasPatientArrived && (
        <Button
          text={chartingStatus === 'NA' ? 'Start visit' : 'Enter visit'}
          buttonStyle="primary"
          loading={startingVisit || isCheckingForWarnings}
          disabled={startingVisit || isCheckingForWarnings}
          onClick={async () => {
            if (chartingStatus === 'NA') {
              const { confirmed } = await checkForStartVisitWarnings({
                appointmentId,
              })
              if (!confirmed) return

              void startVisit({
                variables: { id: appointmentId },
                onCompleted: (data) => {
                  navigate(
                    routes.patientChartsGlob({
                      id: patientId,
                      glob: visit
                        ? `visits/${appointmentId}/${data.startVisit.mostRecentVistFlowTab.toLowerCase()}`
                        : 'visits',
                    })
                  )
                },
                onError: () => {
                  toast.error('Failed to start visit, please try again.')
                },
              })
            } else {
              navigate(
                routes.patientChartsGlob({
                  id: patientId,
                  glob: visit
                    ? `visits/${appointmentId}/${visit.mostRecentVistFlowTab.toLowerCase()}`
                    : 'visits',
                })
              )
            }
          }}
        />
      )}
      {!isAppointmentCancelled(status) && (
        <Button
          data-testid="appointment-check-in-btn"
          disabled={status === 'CANCELLED'}
          text={
            checkedInStatuses.includes(status)
              ? 'Check-out'
              : status === 'CHECKED_OUT'
                ? 'Check-out details'
                : 'Check-in'
          }
          buttonStyle="secondary"
          onClick={async () => {
            const { confirmed } = await checkForVisitCheckInWarnings({
              appointmentId: visit.id,
            })
            if (!confirmed) return

            const params = new URLSearchParams(search)
            params.delete('sidepanelContext')

            navigate(
              routes.appointmentCheckIn({
                id: appointmentId,
                scheduleContext: params.toString(),
              })
            )
          }}
        />
      )}
      <Link
        to={routes.patientChartsGlob({
          id: patientId,
          glob: 'visits',
        })}
        target="_blank"
      >
        <Button buttonStyle="secondary" text="View chart" />
      </Link>

      {chartingStatus === 'COMPLETE' && hasRole('PRACTITIONER') && (
        <Link
          to={routes.patientChartsGlob({
            id: patientId,
            glob: `visits/${appointmentId}/intake`,
          })}
          target="_blank"
        >
          <Button buttonStyle="secondary" text="Addend" />
        </Link>
      )}
    </StackView>
  )
}

export const PatientRegistrationActions = ({
  appointmentId,
  status,
}: {
  appointmentId: string
  status: AppointmentStatus
}) => {
  const { search } = useLocation()

  return (
    <StackView
      alignItems="center"
      direction="row"
      justifyContent="start"
      space={75}
    >
      <Button
        data-testid="appointment-register-patient-btn"
        text="Register"
        disabled={isAppointmentCancelled(status)}
        onClick={() => {
          const params = new URLSearchParams(search)
          params.delete('sidepanelContext')

          navigate(
            routes.patientRegistration({
              appointmentId,
              scheduleContext: params.toString(),
            })
          )
        }}
      />
    </StackView>
  )
}

const SidepanelAppointment = () => {
  const { appointmentId } = useParams()
  const { data, loading, error } = useQuery(QUERY, {
    variables: {
      id: appointmentId,
    },
  })

  if (loading || error || !data.appointment) return null

  const { isPatientRegistered } = data.appointment
  return (
    <VisitCheckInWarningProvider>
      <StartVisitWarningProvider>
        <AppointmentBookingsWarningProvider>
          <Box className="h-full max-w-3xl overflow-hidden p-4">
            <StackView space={100} className="h-full">
              <AppointmentInformationHeaderCell id={appointmentId} />
              <StackView direction="row" justifyContent="between" gap={50} wrap>
                <StackView gap={50} direction="row" wrap fullWidth={false}>
                  {isPatientRegistered ? (
                    <AppointmentPatientActions {...data.appointment} />
                  ) : (
                    <PatientRegistrationActions
                      appointmentId={data.appointment.id}
                      status={data.appointment.status}
                    />
                  )}
                </StackView>
              </StackView>
              {isPatientRegistered ? (
                <EstablishedPatientContent appointment={data.appointment} />
              ) : (
                <NewPatientContent appointment={data.appointment} />
              )}
            </StackView>
          </Box>
        </AppointmentBookingsWarningProvider>
      </StartVisitWarningProvider>
    </VisitCheckInWarningProvider>
  )
}

export default SidepanelAppointment
