import { useState } from 'react'

import { formatMoneyInCents } from 'common/utils'
import {
  add,
  format,
  isAfter,
  isBefore,
  parseISO,
  startOfToday,
} from 'date-fns'
import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView'
import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import TilledCardDetails, {
  useTilledCardDetailsRef,
} from 'src/components/TilledCardDetails/TilledCardDetails'
import {
  useCreatePatientPaymentPlan,
  usePatientPaymentPlanOptionsQuery,
} from 'src/hooks/usePatientPaymentPlans/usePatientPaymentPlans'
import { paymentRefetchQueries } from 'src/hooks/usePatientPayments/usePatientPayments'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import SidepanelForbidden from '../SidepanelForbidden/SidepanelForbidden'

const SidepanelPatientPaymentPlanCreate = () => {
  const { currentUser } = useEmrAuth()
  const { patientId } = useParams()

  const { closeSidePanel } = useSidepanel()

  const { patientPaymentPlanOptions } =
    usePatientPaymentPlanOptionsQuery(patientId)

  const { createPatientPaymentPlan, loading: creatingPatientPaymentPlan } =
    useCreatePatientPaymentPlan()

  const [creatingPaymentMethod, setCreatingPaymentMethod] =
    useState<boolean>(false)

  const tilledCardDetailsRef = useTilledCardDetailsRef()

  const formMethods = useForm({
    defaultValues: {
      cardHolder: '',
      zip: '',
      startDate: new Date(),
    },
  })

  const onSubmit = async (data) => {
    setCreatingPaymentMethod(true)
    const paymentMethod =
      await tilledCardDetailsRef?.current?.createPaymentMethod({
        cardHolder: data.cardHolder,
        zip: data.zip,
      })
    setCreatingPaymentMethod(false)
    if (!paymentMethod.ok) return

    await createPatientPaymentPlan({
      variables: {
        input: {
          patientId,
          paymentMethodId: paymentMethod.id,
          termMonths: parseInt(data.terms),
          termAmountCents: patientPaymentPlanOptions?.termOptions.find(
            (term) => term.months === parseInt(data.terms)
          )?.amountCents,
          startDate: data.startDate,
        },
      },
      refetchQueries: paymentRefetchQueries,
      onCompleted: () => {
        toast.success('Payment plan created successfully')
      },
    })

    closeSidePanel()
  }

  if (!currentUser.featureFlags.includes('PAYMENT_PLANS')) {
    return <SidepanelForbidden />
  }

  const thirtyDaysFromNow = add(new Date(), { days: 30 })

  return (
    <SidepanelPage
      header="Set up a payment plan"
      description="Split outstanding payments equally over a duration that works for the patient."
    >
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        footerProps={{
          disabled: !formMethods.formState.isValid,
          submitting: creatingPatientPaymentPlan || creatingPaymentMethod,
          submitText: 'Confirm and pay',
        }}
      >
        <StackView className="py-core-space-150" space={100}>
          <FormInputList
            title="Details"
            subtitle="Select the term and enter card details for the payment plan. Once you confirm these details the payment plan will begin today."
            divider={false}
            items={[
              {
                name: 'terms',
                label: 'Term (Months)',
                subtitle: 'Choose one from the available options.',
                required: true,
                direction: 'col',
                formInputComponent: MultiRadioButtonField,
                inputProps: {
                  values:
                    patientPaymentPlanOptions?.termOptions.map((term) => ({
                      value: term.months.toString(),
                      label: `${term.months} months`,
                      description: `${formatMoneyInCents(
                        term.amountCents
                      )} per month`,
                    })) ?? [],
                  radioStyle: 'card',
                  radioPosition: 'right',
                },
              },
              {
                name: 'startDate',
                label: 'Start date',
                required: true,
                direction: 'col',
                formInputComponent: DatePickerFieldThatWorks,
                inputProps: {
                  validation: {
                    validate: (value) => {
                      if (
                        isBefore(parseISO(value), startOfToday()) ||
                        isAfter(parseISO(value), thirtyDaysFromNow)
                      )
                        return `Enter a valid date between ${format(
                          new Date(),
                          'P'
                        )} and ${format(thirtyDaysFromNow, 'P')}.`
                    },
                  },
                },
                message: `The latest start date for this payment plan is ${format(
                  thirtyDaysFromNow,
                  'P'
                )}. Payment plans must have a start date that falls within 30 days of today’s date.`,
              },
              {
                name: 'cardHolder',
                label: 'Cardholder full name',
                required: true,
                direction: 'col',
                formInputComponent: InputField,
              },
            ]}
          />

          <TilledCardDetails
            tilledCardDetailsRef={tilledCardDetailsRef}
            tilledAccountId={patientPaymentPlanOptions?.tilledAccountId}
          />

          <FormInputList
            items={[
              {
                name: 'zip',
                label: 'Billing zip code',
                required: true,
                direction: 'col',
                formInputComponent: InputField,
              },
            ]}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPatientPaymentPlanCreate
