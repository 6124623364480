import { ReactElement, useRef } from 'react'

import {
  FloatingPortal,
  autoUpdate,
  computePosition,
} from '@floating-ui/react-dom-interactions'
import { Menu } from '@headlessui/react'

import Button from 'src/components/atoms/Button/Button'

interface MenuButtonProps<T extends { id: string }> {
  options: T[]
  renderOption: ({
    option,
    active,
    disabled,
    close,
  }: {
    option: T
    active: boolean
    disabled: boolean
    close: () => void
  }) => ReactElement
  buttonProps: Omit<React.ComponentProps<typeof Button>, 'children' | 'onClick'>
}

function MenuButton<T extends { id: string }>({
  options,
  renderOption,
  buttonProps,
}: MenuButtonProps<T>) {
  const buttonRef = useRef(null)
  const menuRef = useRef(null)

  const showMenu = () => {
    autoUpdate(buttonRef.current, menuRef.current, () => {
      if (!buttonRef.current || !menuRef.current) return

      void computePosition(buttonRef.current, menuRef.current, {
        placement: 'bottom-end',
      }).then(({ x, y }) => {
        if (menuRef.current) {
          Object.assign(menuRef.current.style, {
            left: `${x}px`,
            top: `${y}px`,
          })
        }
      })
    })
  }

  return (
    <Menu>
      <Menu.Button>
        <div ref={buttonRef}>
          <Button {...buttonProps} onClick={showMenu} />
        </div>
      </Menu.Button>
      <FloatingPortal>
        <div ref={menuRef} className="fixed z-10 mt-2 w-96">
          <Menu.Items className="divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            {options.map((option) => (
              <Menu.Item
                as="div"
                key={option.id}
                className="cursor-pointer first:rounded-t-md last:rounded-b-md hover:bg-green-50"
              >
                {({ ...renderProps }) =>
                  renderOption({ option, ...renderProps })
                }
              </Menu.Item>
            ))}
          </Menu.Items>
        </div>
      </FloatingPortal>
    </Menu>
  )
}

export default MenuButton
