import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { userPoolsToDisplay } from 'common/data/userPools'
import { formatDisplayName } from 'common/utils'
import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import Divider from 'src/components/atoms/Divider/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown/Dropdown'
import Label from 'src/components/atoms/Label/Label'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import DataRow from 'src/components/molecules/DataRow/DataRow'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import NotificationSuccess from 'src/components/molecules/Notification/Notification'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { codeGeneration } from 'src/pages/CasesPage/utils/codeGeneration'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

export const CREATE_CASE_REVIEW_STATUS_UDPATE_MUTATION = gql`
  mutation CreateResultsReviewCaseStatusUpdate(
    $input: CreateCaseStatusUpdateInput!
  ) {
    createCaseStatusUpdate(input: $input) {
      id
      baseCaseId
      text
      baseCase {
        id
        updatedAt
        dueAt
        resultsReviewCase {
          id
        }
      }
    }
  }
`

const READ_PATIENT = gql`
  query ReadPatientResultsReview($id: String!, $resultsReviewCaseId: String!) {
    resultsReviewCase(id: $resultsReviewCaseId) {
      id
      baseCaseId
      code
    }
    diagnosticReportReviewCase(id: $resultsReviewCaseId) {
      id
      baseCaseId
      code
    }
    patient(id: $id) {
      id
      givenName
      familyName
      contactInformation {
        id
        mobileNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      patientRelatedPersonRelationships {
        id
        doesResideWith
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          contactInformation {
            id
            mobileNumber
            emailAddress
          }
        }
      }
    }
  }
`

const CREATE_CASE_ROUTED_EVENT = gql`
  mutation CreateCaseRoutedEventResultsReview(
    $input: CreateCaseRoutedEventInput!
  ) {
    createCaseRoutedEvent(input: $input) {
      id
      routedToPoolId
      routedToUser {
        id
        givenName
        familyName
      }
      routedToTaskUserPool {
        id
        displayName
        users {
          id
          givenName
          familyName
          userType {
            id
            name
          }
        }
      }
      createdBy {
        id
        givenName
        familyName
      }
      baseCase {
        id
        assignedUserId
        assignedTaskUserPoolId
        assignedTaskUserPool {
          id
          displayName
          users {
            id
            givenName
            familyName
            userType {
              id
              name
            }
          }
        }
        assignedUser {
          id
          familyName
          givenName
        }
      }
    }
  }
`

interface FormProps {
  voiceMessageWithPatient: boolean
  voiceMessageWithCaregiver: boolean
  caregiver?: string
  routedType?: 'normal-results' | 'abnormal-results'
}

const SidepanelResultsReviewCaseStatusUpdate = () => {
  const { patientId } = useParams()
  const paramsRW = useParamsRW()
  const [createCaseRoutedEvent, { loading: creatingCaseRoutedEvent }] =
    useMutation(CREATE_CASE_ROUTED_EVENT)

  const { glob } = paramsRW
  const resultsReviewCaseId = glob?.split('/')[1]
  const { data, loading } = useQuery(READ_PATIENT, {
    variables: {
      id: patientId,
      resultsReviewCaseId,
    },
    fetchPolicy: 'cache-first',
  })
  const code = data?.resultsReviewCase
    ? codeGeneration(data.resultsReviewCase)
    : '...'
  const [createCaseStatusUpdate, { loading: creating }] = useMutation(
    CREATE_CASE_REVIEW_STATUS_UDPATE_MUTATION
  )
  const { closeSidePanel } = useSidepanel()
  const formMethods = useForm<FormProps>({
    defaultValues: {
      voiceMessageWithPatient: false,
      voiceMessageWithCaregiver: false,
    },
  })
  if (loading) {
    return <LoadingSpinner />
  }
  const baseCaseId = data.resultsReviewCase
    ? data.resultsReviewCase.baseCaseId
    : data.diagnosticReportReviewCase.baseCaseId

  const options = (data?.patient?.patientRelatedPersonRelationships || []).map(
    ({ relatedPerson, relationshipType }) => {
      return {
        key: relatedPerson.id,
        id: relatedPerson.id,
        value: `${formatDisplayName(relatedPerson)} - ${
          relationshipTypeDisplay[relationshipType]
        }`,
        name: `${formatDisplayName(relatedPerson)} - ${
          relationshipTypeDisplay[relationshipType]
        }`,
      }
    }
  )
  const [voiceMessageWithCaregiver, voiceMessageWithPatient, routedType] =
    formMethods.watch([
      'voiceMessageWithCaregiver',
      'voiceMessageWithPatient',
      'routedType',
    ])
  const onSubmit = async (formData: FormProps) => {
    const promises = []
    if (formData.voiceMessageWithPatient) {
      promises.push(
        new Promise((res) => {
          createCaseStatusUpdate({
            variables: {
              input: {
                type: 'RESULTS_REVIEW',
                baseCaseId,
                text: 'Results reviewed, left voicemail with patient',
                icon: 'USER_CIRCLE',
              },
            },
            onCompleted: () => res(true),
            refetchQueries: [
              'GetActivityItems',
              'GetNotResolvedCasesCountByType',
            ],
          })
        })
      )
    }
    if (formData.voiceMessageWithCaregiver) {
      promises.push(
        new Promise((res) => {
          createCaseStatusUpdate({
            variables: {
              input: {
                type: 'RESULTS_REVIEW',
                baseCaseId,
                icon: 'USERS',
                text: `Results reviewed, left voicemail with caregiver: ${formData.caregiver}`,
              },
            },
            onCompleted: () => res(true),
            refetchQueries: [
              'GetActivityItems',
              'GetNotResolvedCasesCountByType',
            ],
          })
        })
      )
    }
    await Promise.all(promises)
    if (formData.routedType) {
      await new Promise((resolve) => {
        createCaseStatusUpdate({
          variables: {
            input: {
              type: 'RESULTS_REVIEW',
              baseCaseId,
              text: `Results reviewed; route to practice staff to discuss ${
                formData.routedType === 'abnormal-results' ? 'AB' : ''
              }NORMAL results with patient and/or caregiver(s)`,
              icon:
                formData.routedType === 'abnormal-results'
                  ? 'ARROW_TRENDING_DOWN'
                  : 'ARROW_TRENDING_UP',
            },
          },
          refetchQueries: [
            'GetActivityItems',
            'GetNotResolvedCasesCountByType',
          ],
          onCompleted: () => resolve(true),
        })
      })
      createCaseRoutedEvent({
        variables: {
          input: {
            baseCaseId,
            routedToPoolId: 'STAFF',
          },
        },
        refetchQueries: ['GetActivityItems', 'GetNotResolvedCasesCountByType'],
        onCompleted: () => {
          toast.custom(
            () => {
              return (
                <NotificationSuccess
                  title={`Task ${code} routed to ${userPoolsToDisplay['STAFF']}`}
                  onClose={() => {
                    toast.dismiss(`toast-routed-to-${code}-STAFF`)
                  }}
                />
              )
            },
            {
              duration: 4000,
              position: 'top-right',
              id: `toast-routed-to-${code}-STAFF`,
            }
          )
        },
      })
    }
    closeSidePanel()
  }
  return (
    <SidepanelPage
      header={`Status update: ${code}`}
      description="Check the option(s) that apply to your status update."
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Submit update',
          disabled:
            creating ||
            (!voiceMessageWithCaregiver &&
              !voiceMessageWithPatient &&
              !routedType),
          submitting: creatingCaseRoutedEvent,
        }}
        autoComplete="off"
        title="Status update"
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <StackView space={25}>
          <Space space={75} />
          <Typography textStyle="title" fontWeight="medium">
            Interim status options
          </Typography>
          <Space space={75} />
          <Divider />
          <DataRow
            label={
              <StackView space={50}>
                <Label>Check the option(s) that apply to the task:</Label>
              </StackView>
            }
            value={
              <StackView space={75}>
                <CheckboxField
                  name="voiceMessageWithPatient"
                  label="Results reviewed; left voicemail with patient"
                />
                <CheckboxField
                  name="voiceMessageWithCaregiver"
                  label="Results reviewed; left voicemail with caregiver"
                />
                {voiceMessageWithCaregiver && (
                  <>
                    <DropdownField
                      options={options}
                      name="caregiver"
                      validation={{
                        required: voiceMessageWithCaregiver,
                        shouldUnregister: true,
                      }}
                    />
                    <FieldError
                      name="caregiver"
                      defaultErrorMessage="This field is required"
                    />
                  </>
                )}
              </StackView>
            }
          />
          <Divider />
          <FormInputList
            items={[
              {
                name: 'routedType',
                label:
                  'If routing to practice staff, select the option that applies to the task:',
                formInputComponent: MultiRadioButtonField,
                inputProps: {
                  labelClassName: 'font-normal',
                  values: [
                    {
                      label:
                        'Results reviewed; route to practice staff to discuss NORMAL results with patient and/or caregiver(s)',
                      value: 'normal-results',
                    },
                    {
                      label:
                        'Results reviewed; route to practice staff to discuss ABNORMAL results with patient and/or caregiver(s)',
                      value: 'abnormal-results',
                    },
                  ],
                },
              },
            ]}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelResultsReviewCaseStatusUpdate
