import { getPatientStatus, patientStatusDisplay } from 'common/data/patient'
import { formatDisplayName } from 'common/utils'
import { PatientDemographicsFragment, UpdatePatientInput } from 'types/graphql'

import InputField from 'src/components/atoms/InputField'
import { useGetLexicalHtml } from 'src/components/atoms/WysiwygField/hooks/useGetLexicalHtml'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import { PatientStatusSelectField } from 'src/components/molecules/PatientStatusSelectField/PatientStatusSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import PractitionerSelectCell from 'src/components/Practitioner/PractitionerSelectCell'

import { UPDATE_PATIENT_DEMOGRAPHICS_MUTATION } from '../usePatient'

type UpdatePracticeDetailsFormState = UpdatePatientInput & { mrn?: string }

const PatientPracticeDetailsCard = ({
  patient,
}: {
  patient: PatientDemographicsFragment
}) => {
  const { id, mrn, primaryProvider, practiceComment } = patient
  const practiceCommentHtml = useGetLexicalHtml(practiceComment)

  const preSubmit = ({
    mrn: _mrn,
    ...input
  }: UpdatePracticeDetailsFormState) => {
    return { variables: { id, input } }
  }

  const patientStatus = getPatientStatus(patient)

  return (
    <DataVerificationCard<UpdatePracticeDetailsFormState>
      title="Practice details"
      updateMutation={UPDATE_PATIENT_DEMOGRAPHICS_MUTATION}
      defaultOpen
      preSubmit={preSubmit}
      editButtonTestId="edit-patient-practice-details"
      data={[
        {
          name: 'mrn',
          label: 'MRN',
          value: mrn,
          required: true,
          inputProps: { disabled: true },
          formInputComponent: InputField,
        },
        {
          name: 'status',
          label: 'Patient status',
          value: patientStatus,
          required: true,
          displayValue: patientStatusDisplay[patientStatus],
          formInputComponent: PatientStatusSelectField,
        },
        {
          name: 'primaryProviderId',
          label: 'Primary practitioner',
          value: primaryProvider?.id ?? 'Unassigned',
          required: true,
          displayValue: primaryProvider
            ? formatDisplayName(primaryProvider)
            : 'Unassigned',
          inputProps: {
            includeUnassigned: true,
          },
          formInputComponent: PractitionerSelectCell,
        },
        {
          name: 'practiceComment',
          label: 'Practice comment',
          value: practiceCommentHtml,
          formInputComponent: WysiwygField,
          inputProps: {
            defaultValue: practiceComment,
          },
        },
      ]}
    />
  )
}

export default PatientPracticeDetailsCard
