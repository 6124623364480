import { isEmpty } from 'lodash'

import StackView from 'src/components/atoms/StackView'
import { deliveryMethodDisplay } from 'src/data/deliveryMethods'
import { interpretationDisplay } from 'src/data/interpretations'
import { useBirthHistoryQuery } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'

import { ColumnDisplay } from './BirthHistoryV2'

export const DeliverySectionV2 = ({ patientId }: { patientId: string }) => {
  const { birthHistoryFormValues } = useBirthHistoryQuery(patientId)

  if (isEmpty(birthHistoryFormValues)) return null
  const { birthFacility, newbornHearingScreeningResult, deliveryMethod } =
    birthHistoryFormValues

  return (
    <StackView space={75} testId="birth-history-delivery-section" divider>
      <ColumnDisplay
        label="Delivery Method"
        value={deliveryMethodDisplay[deliveryMethod]}
        className="pt-core-space-0"
      />
      <ColumnDisplay label="Birth facility" value={birthFacility} />
      <ColumnDisplay
        label="Hearing screen result"
        value={interpretationDisplay[newbornHearingScreeningResult]}
      />
    </StackView>
  )
}
