import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'

const NoKnownAllergies = () => {
  return (
    <StackView
      data-testid="no-known-allergies"
      className="border-gray rounded-lg border p-4"
      space={75}
    >
      <Typography textStyle="title" fontWeight="medium">
        No known allergies
      </Typography>
    </StackView>
  )
}

export default NoKnownAllergies
