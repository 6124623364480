import {
  PatientDemographicsFragment,
  UpdatePatientInsuranceOptOutInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import InsuranceOptOutStatusSelectField from 'src/components/atoms/InsuranceOptOutStatusSelectField/InsuranceOptOutStatusSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import { insuranceOptOutStatusDisplay } from 'src/data/insuranceOptOutStatuses'
import { UPDATE_INSURANCE_OPT_OUT_MUTATION } from 'src/pages/AppointmentCheckInPage/CheckInInsuranceTab/InsuranceOptOutVerificationCard'

const InsuranceOptOutCard = ({
  patient,
}: {
  patient: PatientDemographicsFragment
}) => {
  const preSubmit = (data: UpdatePatientInsuranceOptOutInput) => {
    const { ...input } = data
    return { variables: { id: patient.id, input } }
  }

  const formMethods = useForm<UpdatePatientInsuranceOptOutInput>({
    defaultValues: {
      insuranceOptOut: !!patient.insuranceOptedOutAt,
      insuranceOptOutStatus: patient.insuranceOptOutStatus,
    },
  })

  const isPatientOptingOut = formMethods.watch('insuranceOptOut')

  return (
    <DataVerificationCard<UpdatePatientInsuranceOptOutInput>
      title="Insurance opt-out"
      updateMutation={UPDATE_INSURANCE_OPT_OUT_MUTATION}
      preSubmit={preSubmit}
      formMethods={formMethods}
      defaultOpen={!!patient.insuranceOptedOutAt}
      data={[
        {
          name: 'insuranceOptOut',
          label: 'Is patient opting-out?',
          required: true,
          inputProps: {
            includeEmptyOption: false,
            trueDisplay: 'Yes',
            falseDisplay: 'No',
          },
          value: patient.insuranceOptedOutAt ? 'Yes' : 'No',
          formInputComponent: BooleanSelectField,
        },
        {
          name: 'insuranceOptOutStatus',
          label: 'Insurance opt-out reason',
          required: true,
          hideFormInput: !isPatientOptingOut,
          hide: !patient.insuranceOptedOutAt,
          displayValue:
            insuranceOptOutStatusDisplay[patient.insuranceOptOutStatus],
          value: patient.insuranceOptOutStatus,
          formInputComponent: InsuranceOptOutStatusSelectField,
        },
      ]}
    />
  )
}

export default InsuranceOptOutCard
