import { format, parseISO } from 'date-fns'
import compact from 'lodash/compact'
import { FindOrderToPrint, OrderFragment, Address } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { orderStatusDisplay } from 'src/data/orderStatuses'
import { formatDateDisplay } from 'src/lib/formatters'

import {
  LabeledValue,
  LetterHead,
  PatientAndGuardians,
  Section,
  Signature,
  SubSection,
} from './common'

const buildAddressDisplay = (address: Partial<Address>): string => {
  if (!address) return ''
  const lines = [
    compact([address.line1, address.line2]).join('\n'),
    compact([
      address.city,
      address.district,
      address.state,
      address.postalCode,
    ]).join(', '),
    address.country,
  ]
  return compact(lines).join('\n')
}

const Diagnoses = ({
  diagnosisCodes,
}: {
  diagnosisCodes: OrderFragment['diagnosisCodes']
}) => {
  if (!diagnosisCodes?.length) return null

  return (
    <StackView direction="row" wrap className="pt-2">
      {diagnosisCodes.map((diagnosis) => (
        <StackView className="w-1/2" space={25} key={diagnosis.code}>
          <Typography size="l">{diagnosis.code}</Typography>
          <Typography>{diagnosis.description}</Typography>
        </StackView>
      ))}
    </StackView>
  )
}

const OrderWithReferralDetails = ({ order }: { order: OrderFragment }) => {
  return (
    <StackView direction="row" space={100}>
      <StackView className="w-1/2">
        <LabeledValue label="Reason" value={order.reason} />
        <LabeledValue
          label="Provider"
          value={
            order.performer
              ? order.performer
              : order.existingContactPerformer
                ? order.existingContactPerformer.display
                : 'Any provider'
          }
        />
        {order.existingContactPerformer && (
          <>
            <LabeledValue
              label="Organization"
              value={order.existingContactPerformer.organization}
            />
            <LabeledValue
              label="Address"
              value={buildAddressDisplay(
                order.existingContactPerformer.address
              )}
            />
            {order.existingContactPerformer.faxNumber && (
              <LabeledValue
                label="Fax number"
                value={order.existingContactPerformer.faxNumber}
              />
            )}
            {order.existingContactPerformer.workNumber && (
              <LabeledValue
                label="Phone number"
                value={order.existingContactPerformer.workNumber}
              />
            )}
            {order.existingContactPerformer.emailAddress && (
              <LabeledValue
                label="Email address"
                value={order.existingContactPerformer.emailAddress}
              />
            )}
          </>
        )}

        <LabeledValue
          label="Date/Time"
          value={format(parseISO(order.createdAt), 'MM/dd/yyyy hh:mm a')}
        />
        <LabeledValue label="Number" value={order.name} />
      </StackView>
      <StackView className="w-1/2">
        <LabeledValue label="Status" value={orderStatusDisplay[order.status]} />
        <LabeledValue
          label="Expiry"
          value={order.expiresAt ? formatDateDisplay(order.expiresAt) : 'N/A'}
        />
        <LabeledValue
          label="Urgency"
          value={order.isUrgent ? 'Urgent' : 'Not urgent'}
        />
        <LabeledValue label="Comment" value={order.comments} />
      </StackView>
    </StackView>
  )
}

const BasicOrderDetails = ({ order }: { order: OrderFragment }) => {
  return (
    <StackView direction="row" space={100}>
      <StackView className="w-1/2">
        <LabeledValue
          label="Date/Time"
          value={format(parseISO(order.createdAt), 'MM/dd/yyyy hh:mm a')}
        />
        <LabeledValue label="Number" value={order.name} />
        <LabeledValue label="Status" value={orderStatusDisplay[order.status]} />
        <LabeledValue
          label="Expiry"
          value={order.expiresAt ? formatDateDisplay(order.expiresAt) : 'N/A'}
        />
      </StackView>
      <StackView className="w-1/2">
        <LabeledValue
          label="Urgency"
          value={order.isUrgent ? 'Urgent' : 'Not urgent'}
        />
        <LabeledValue label="Comment" value={order.comments} />
      </StackView>
    </StackView>
  )
}

const OrderDetailsSubSection = ({ order }: { order: OrderFragment }) => {
  return (
    <SubSection header={orderCategoryConfig[order.category].display}>
      <StackView space={50}>
        <Typography size="l">{order.codeDisplay}</Typography>
        {['RAD', 'REF'].includes(order.category) ? (
          <OrderWithReferralDetails order={order} />
        ) : (
          <BasicOrderDetails order={order} />
        )}
      </StackView>
      {order.diagnosisCodes && (
        <Diagnoses diagnosisCodes={order.diagnosisCodes} />
      )}
    </SubSection>
  )
}

const PrintOrderDocument = ({
  order,
}: {
  order: FindOrderToPrint['order']
}) => {
  return (
    <StackView space={100} className="h-full">
      <LetterHead tenant={order.requester.tenant} />
      <Section>
        <PatientAndGuardians patient={order.patient} />
        <OrderDetailsSubSection order={order} />
      </Section>
      <Signature signedAt={order.createdAt} practitioner={order.requester} />
    </StackView>
  )
}

export default PrintOrderDocument
