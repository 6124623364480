import { PlusIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { useParams } from 'react-router-dom'
import {
  GuardianshipType,
  LinkPatientsToCaregiverMutation,
  LinkPatientsToCaregiverMutationVariables,
  RelationshipType,
} from 'types/graphql'

import { useFieldArray, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import Button from 'src/components/atoms/Button/Button'
import GuardianshipTypeSelectField from 'src/components/atoms/GuardianshipTypeSelectField/GuardianshipTypeSelectField'
import { RelationshipTypeSelectField } from 'src/components/atoms/RelationshipTypeSelectField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import EnhancedPatientSearch from 'src/components/Patient/EnhancedPatientSearch/EnhancedPatientSearch'
import { refetchQueries } from 'src/pages/FamilyPortalAdminPage/FamilyPortalAdminPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const LINK_PATIENTS = gql`
  mutation LinkPatientsToCaregiverMutation(
    $input: LinkPatientsToCaregiverInput!
  ) {
    linkPatientsToCaregiver(input: $input) {
      id
    }
  }
`

const SidepanelCaregiverLinkPatients = () => {
  const { relatedPersonId } = useParams()
  const { closeSidePanel } = useSidepanel()

  const formMethods = useForm<{
    relationships: {
      patient?: { id: string }
      guardianshipStatus?: string
      guarantorStatus?: boolean
      relationshipType?: string
    }[]
  }>({
    defaultValues: {
      relationships: [{}],
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: 'relationships',
    control: formMethods.control,
    rules: {
      minLength: 1,
    },
  })

  const [linkPatientsToCaregiver, { loading }] = useMutation<
    LinkPatientsToCaregiverMutation,
    LinkPatientsToCaregiverMutationVariables
  >(LINK_PATIENTS)

  return (
    <SidepanelPage
      header="Add patient(s)"
      description="Link additional patient(s) to this caregiver. This will create a patient-specific link of the caregiver to the patient."
    >
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={(data) => {
          void linkPatientsToCaregiver({
            variables: {
              input: {
                relatedPersonId,
                relationships: data.relationships.map((relationship) => {
                  return {
                    patientId: relationship.patient.id,
                    relationshipType:
                      relationship.relationshipType as RelationshipType,
                    guardianshipType:
                      relationship.guardianshipStatus as GuardianshipType,
                    isGuarantor: relationship.guarantorStatus,
                  }
                }),
              },
            },
            onCompleted: () => {
              closeSidePanel()
              toast.success('Patients linked successfully')
            },
            refetchQueries,
          })
        }}
        footerProps={{
          submitText: 'Save',
          cancelText: 'Cancel',
          submitting: loading || formMethods.formState.isSubmitting,
        }}
      >
        <StackView gap={150} className="py-core-space-100">
          <StackView divider>
            {fields.map((field, index) => {
              return (
                <StackView key={field.id} className="py-core-space-100">
                  <StackView
                    direction="row"
                    justifyContent="between"
                    alignItems="center"
                  >
                    <Typography textStyle="title-xs">
                      Patient details
                    </Typography>
                    {index !== 0 ? (
                      <Button
                        onClick={() => remove(index)}
                        text="Remove"
                        buttonStyle="secondary"
                        icon={XMarkIcon}
                      />
                    ) : null}
                  </StackView>
                  <FormInputList
                    divider={false}
                    items={[
                      {
                        name: `relationships.${index}.patient`,
                        label: 'Patient(s)',
                        direction: 'col',
                        required: true,
                        formInputComponent: EnhancedPatientSearch,
                      },
                      {
                        name: `relationships.${index}.guardianshipStatus`,
                        label: 'Guardianship status',
                        direction: 'col',
                        required: true,
                        formInputComponent: GuardianshipTypeSelectField,
                      },
                      {
                        name: `relationships.${index}.guarantorStatus`,
                        label: 'Guarantor status',
                        direction: 'col',
                        required: true,
                        formInputComponent: BooleanSelectField,
                        inputProps: {
                          trueDisplay: 'Yes',
                          falseDisplay: 'No',
                        },
                      },
                      {
                        name: `relationships.${index}.relationshipType`,
                        label: 'Relationship to patient',
                        direction: 'col',
                        required: true,
                        formInputComponent: RelationshipTypeSelectField,
                      },
                    ]}
                  />
                </StackView>
              )
            })}
          </StackView>
        </StackView>

        <StackView>
          <Button
            icon={PlusIcon}
            text="Add patient"
            buttonStyle="secondary"
            onClick={() => append({})}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelCaregiverLinkPatients
