// import { useLocation, useParams } from '@redwoodjs/router'

import { Form, useForm } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import MultiSelectComboBoxFieldArray from 'src/components/molecules/MultiSelectComboBoxFieldArray/MultiSelectComboBoxFieldArray'
import TextareaAutoSaveSection from 'src/components/molecules/TextareaAutoSaveSection/TextareaAutoSaveSection'
import { calculateAge } from 'src/lib/formatters'

import {
  useUpdateAppointmentDocumentationMutation,
  useUpdateVisitFlowTabForAppointment,
  useUpdateVisitPatientEducationDocumentsMutation,
  useVisit,
  useVisitQuery,
} from '../../useVisit'

const GET_PATIENT_EDUCATION_DOCUMENTS = gql`
  query PatientEducationDocumentsForVisitQuery {
    patientEducationDocuments {
      id
      title
    }
  }
`

const DocumentSelectSection = () => {
  const { hasRole } = useEmrAuth()
  const [updateVisitPatientEducation] =
    useUpdateVisitPatientEducationDocumentsMutation()

  const { appointmentId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)

  const formMethods = useForm({
    defaultValues: {
      patientEducationDocuments: visit.patientEducationDocuments.map(
        (x) => x.id
      ),
    },
  })

  const selectedPatientEducationDocuments = formMethods.watch(
    'patientEducationDocuments'
  )

  const { data, loading: loadingPatientEducationDocuments } = useQuery(
    GET_PATIENT_EDUCATION_DOCUMENTS
  )

  const saveUpdates = () => {
    updateVisitPatientEducation({
      variables: {
        visitId: visit.id,
        documentIds: selectedPatientEducationDocuments,
      },
    })
  }

  if (loadingPatientEducationDocuments) return null

  return (
    <Form formMethods={formMethods}>
      <FormInputList
        items={[
          {
            name: `patientEducationDocuments`,
            label: 'Documents',
            formInputComponent: MultiSelectComboBoxFieldArray,
            direction: 'col',
            inputProps: {
              testId: 'patient-education-documents',
              options: data.patientEducationDocuments.map((doc) => {
                return {
                  name: doc.title,
                  value: doc.id,
                }
              }),
              addSelectionText: 'Add additional documents',
              addSelectionIsButton: true,
              disableAddSelectionButton:
                selectedPatientEducationDocuments[
                  selectedPatientEducationDocuments.length - 1
                ] === '',
              minimumValueLength: 0,
              emptyOptionsMessage: 'Search for document',
              forceDisplayPlaceholder: true,
              showSearchIcon: true,
              onChange: () => saveUpdates(),
              onRemoveSelectedOption: () => saveUpdates(),
              disabled:
                visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER'),
            },
          },
        ]}
      />
    </Form>
  )
}

const AdditionalNotesSection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()

  return (
    <TextareaAutoSaveSection
      testId="patient-education-additional-notes"
      title="Additional notes"
      titleStyle="label"
      isRichText={true}
      macroPhraseSection="PATIENT_EDUCATION"
      defaultValue={visit?.patientEducationAdditionalNotes}
      onChange={(value: string) =>
        updateAppointmentDocumentation({
          variables: {
            input: {
              visitId: visit.id,
              value: value,
              documentationType: 'PATIENT_EDUCATION_ADDITIONAL_NOTES',
            },
          },
        })
      }
      loading={loading}
      disabled={visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')}
    />
  )
}

const AdolescentConfidentialSection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()

  if (calculateAge(visit.patient.birthDate).years < 10) return null
  return (
    <TextareaAutoSaveSection
      testId="patient-education-adolescent-confidential"
      title="Adolescent confidential"
      description="Add optional adolescent confidential patient education comments pertaining to this current visit. Adolescent confidential patient education comments will only be available within the visit note and after-visit summary for the adolescent patient, and will not be shared with any parents and/or caregivers involved with the patient's care."
      titleStyle="label"
      isRichText={true}
      macroPhraseSection="PATIENT_EDUCATION"
      defaultValue={visit?.patientEducationAdolescentConfidential}
      onChange={(value: string) =>
        updateAppointmentDocumentation({
          variables: {
            input: {
              visitId: visit.id,
              value: value,
              documentationType: 'PATIENT_EDUCATION_ADOLESCENT_CONFIDENTIAL',
            },
          },
        })
      }
      loading={loading}
      disabled={visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')}
    />
  )
}

const PatientEducationPage = () => {
  const { appointmentId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)

  useUpdateVisitFlowTabForAppointment('education')

  if (!visit) return null

  return (
    <StackView space={100} className="max-w-3xl pt-4">
      <StackView space={50} direction="row">
        <Typography textStyle="heading" className="grow">
          Patient education
        </Typography>
      </StackView>

      <DocumentSelectSection />

      <AdditionalNotesSection />
      <AdolescentConfidentialSection />
    </StackView>
  )
}

export default PatientEducationPage
