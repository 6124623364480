import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { allergySeverities } from 'src/data/allergySeverities'

export const AllergySeveritySelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={allergySeverities.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default AllergySeveritySelectField
