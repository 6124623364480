import {
  CheckCircleIcon,
  PencilIcon,
  PlusIcon,
} from '@heroicons/react/20/solid'
import { format } from 'date-fns'

import { navigate, useLocation } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { sidepanelRoute } from 'src/lib/routes'

import { useHospitalVisit } from './useHospitalVisit'

export const HospitalVisitDocumentationSection = () => {
  const { data } = useHospitalVisit()
  const location = useLocation()

  if (!data?.hospitalVisit) return <LoadingSpinner />

  const claim = data.hospitalVisit.claim

  const documents = claim.encounter.documents ?? []

  return (
    <StackView gap={100}>
      <SectionHeader
        title="Documentation"
        description="Upload hospital visit documentation exported from your hospital's EMR system."
        actions={
          <Button
            testId="hospital-visit-add-document-btn"
            buttonStyle="secondary"
            icon={PlusIcon}
            text="Add"
            onClick={() =>
              navigate(
                sidepanelRoute(
                  {
                    route: `/documents/patient-document/upload`,
                    encounterId: claim.encounter.id,
                  },
                  location
                )
              )
            }
          />
        }
      />
      <StackView gap={50}>
        {documents.map((document) => {
          return (
            <Card
              testId="hospital-visit-document-list-item"
              key={document.id}
              className="px-core-space-100 py-core-space-75"
            >
              <StackView
                direction="row"
                justifyContent="between"
                alignItems="center"
              >
                <StackView direction="row" gap={50} alignItems="center">
                  <CheckCircleIcon className="h-base-size-icon-s w-base-size-icon-s text-base-color-fg-success" />

                  <StackView gap={25}>
                    <Typography
                      textStyle="interface-strong-s"
                      color="text-base-color-fg-muted"
                    >
                      {[
                        [document.type.display, document.subType?.display]
                          .filter(Boolean)
                          .join(': '),
                        document.title ? `(${document.title})` : undefined,
                      ]
                        .filter(Boolean)
                        .join(' ')}
                    </Typography>
                    <Typography
                      textStyle="interface-default-xs"
                      color="text-base-color-fg-subtle"
                    >
                      Created{' '}
                      {format(
                        new Date(document.createdAt),
                        "MM/dd/yyyy 'at' hh:mm aa"
                      )}
                    </Typography>
                  </StackView>
                </StackView>

                <Button
                  aria-label="Edit document"
                  buttonStyle="ghost"
                  icon={PencilIcon}
                  onClick={() => {
                    navigate(
                      sidepanelRoute(
                        {
                          route: `/documents/patient-document/${document.id}/edit`,
                        },
                        location
                      )
                    )
                  }}
                />
              </StackView>
            </Card>
          )
        })}
      </StackView>
    </StackView>
  )
}
