import { AppointmentPatientCheckInQuery, PracticeFormType } from 'types/graphql'

export const timebasedVerificationSchedule = [1, 2] as const
export type TimebasedVerificationSchedule =
  (typeof timebasedVerificationSchedule)[number]
export type VerificationSchedule =
  | TimebasedVerificationSchedule
  | 'practice-forms'

export type VerificationRequirement =
  | 'none'
  | 'verified'
  | 'verifiedByPatient'
  | 'soft'
  | 'hard'

export const CHECK_IN_STEP_CONFIG = {
  PATIENT: {
    display: 'Patient',
    sections: {
      CORE_DEMOGRAPHICS: {
        display: 'Core demographics',
        verificationSchedule: 2,
        dataPath: 'patient.coreDemographicsVerifiedAt',
      },
      PHARMACY: {
        display: 'Pharmacy',
        verificationSchedule: 2,
        dataPath: 'patient.pharmacyVerifiedAt',
      },
      ADDITIONAL_DEMOGRAPHICS: {
        display: 'Additional demographics',
        verificationSchedule: 2,
        dataPath: 'patient.additionalDemographicsVerifiedAt',
      },
      CONTACT_INFORMATION: {
        display: 'Contact information',
        verificationSchedule: 1,
        dataPath: 'patient.contactInformation.verifiedAt',
        skipVerificationIfNotPreviouslyVerified: true,
      },
      RESIDENCE: {
        display: 'Residence',
        verificationSchedule: 1,
        dataPath: 'patient.residenceVerifiedAt',
      },
    },
  },
  CAREGIVER: {
    display: 'Caregiver',
    sections: {
      RELATIONSHIP: {
        display: 'Patient caregiver relationship',
        verificationSchedule: 2,
        dataPath: 'verifiedAt',
      },
      CONTACT_INFORMATION: {
        display: 'Caregiver contact information',
        verificationSchedule: 1,
        dataPath: 'relatedPerson.contactInformation.verifiedAt',
      },
      CORE_DEMOGRAPHICS: {
        display: 'Caregiver core demographics',
        verificationSchedule: 2,
        dataPath: 'relatedPerson.coreDemographicsVerifiedAt',
      },
      ADDITIONAL_DEMOGRAPHICS: {
        display: 'Caregiver additional demographics',
        verificationSchedule: 2,
        dataPath: 'relatedPerson.additionalDemographicsVerifiedAt',
      },
    },
  },
  INSURANCE: {
    display: 'Insurance',
    sections: {
      INSURANCE_OPT_OUT: {
        display: 'Insurance Opt-out',
        verificationSchedule: 2,
        dataPath: 'patient.insuranceOptOutVerifiedAt',
      },
      EXISTING_INSURANCES: {
        INSURANCE_STATUS: {
          display: 'Insurance status',
          verificationSchedule: 1,
          dataPath: 'statusVerifiedAt',
        },
        MEMBER_INFORMATION: {
          display: 'Member information',
          verificationSchedule: 2,
          dataPath: 'beneficiaryVerifiedAt',
        },
        POLICYHOLDER_INFORMATION: {
          display: 'Policyholder information',
          verificationSchedule: 2,
          dataPath: 'subscriberVerifiedAt',
        },
        GENERAL_PLAN_INFORMATION: {
          display: 'General plan information',
          verificationSchedule: 2,
          dataPath: 'planInformationVerifiedAt',
        },
      },
    },
  },
  PRACTICE_FORMS: {
    display: 'Practice forms',
    sections: {
      MANDATORY_PRACTICE_POLICY: {
        display: 'Mandatory practice policy forms',
        verificationSchedule: 'practice-forms',
        dataPath: 'patient.requiredPracticeFormSummaries',
        dataFilter: [
          'CONDUCT_POLICY',
          'FINANCIAL_POLICY',
          'FOSTER_CARE_REGISTRATION',
          'HIPAA_AND_PHI_POLICY',
          'CONSENT_TO_TREATMENT',
          'AUTHORIZATION_TO_RELEASE_RECORDS_TO_THIRD_PARTY',
        ] as PracticeFormType[],
      },
      MANDATORY_NEW_PATIENT: {
        display: 'Mandatory new patient forms',
        verificationSchedule: 'practice-forms',
        dataPath: 'patient.requiredPracticeFormSummaries',
        dataFilter: [
          'DEMOGRAPHICS',
          'INITIAL_HISTORY',
          'INSURANCE_VERIFICATION',
          'RECORDS_RELEASE_REQUEST',
        ] as PracticeFormType[],
      },
    },
  },
} as const
export type CheckInStep = keyof typeof CHECK_IN_STEP_CONFIG
export type CheckInStepConfig = typeof CHECK_IN_STEP_CONFIG
export type CheckInStepSectionConfig = {
  display: string
  verificationSchedule: VerificationSchedule
  dataPath: string
  skipVerificationIfNotPreviouslyVerified?: boolean
  dataFilter?: readonly string[]
}

export type PracticeFormVerificationRequirementData =
  | AppointmentPatientCheckInQuery['appointment']['patient']['requiredPracticeFormSummaries']
  | AppointmentPatientCheckInQuery['appointment']['patient']['optionalPracticeFormSummaries']
export type VerificationRequirementData =
  | Date
  | PracticeFormVerificationRequirementData

export type CheckInStepSectionVerification = {
  section: string
  verificationRequirement: VerificationRequirement
  lastVerifiedAt?: Date
}

type NonNestedCheckInSteps = Exclude<CheckInStep, 'CAREGIVER' | 'INSURANCE'>

export type CheckInStepVerification<T extends CheckInStep> =
  T extends NonNestedCheckInSteps
    ? {
        [key in keyof CheckInStepConfig[T]['sections']]: CheckInStepSectionVerification
      }
    : T extends 'CAREGIVER'
      ? {
          [key: string]: Record<
            keyof CheckInStepConfig[T]['sections'],
            CheckInStepSectionVerification
          >
        }
      : T extends 'INSURANCE'
        ? {
            [key: string]:
              | Record<
                  keyof CheckInStepConfig[T]['sections']['EXISTING_INSURANCES'],
                  CheckInStepSectionVerification
                >
              | CheckInStepSectionVerification
          }
        : never

export type AppointmentCheckInVerification = {
  [key in CheckInStep]: CheckInStepVerification<key>
}
