import { CreateTaskUserPool, CreateTaskUserPoolVariables } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { TaskUserPoolSidepanelForm } from 'src/components/organisms/TaskUserPoolSidepanelForm/TaskUserPoolSidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const CREATE_TASK_USER_POOL = gql`
  mutation CreateTaskUserPool($input: CreateTaskUserPoolInput!) {
    createTaskUserPool(input: $input) {
      id
    }
  }
`

const SidepanelTaskUserPoolCreate = () => {
  const { closeSidePanel } = useSidepanel()

  const [createTaskUserPool, { loading: submitting }] = useMutation<
    CreateTaskUserPool,
    CreateTaskUserPoolVariables
  >(CREATE_TASK_USER_POOL, {
    refetchQueries: ['GetTaskUserPools'],
    onCompleted: () => {
      toast.success('User pool created successfully')
      closeSidePanel()
    },
  })

  return (
    <SidepanelPage
      header="Create user pool"
      description="Set up a custom user pool for your practice."
    >
      <TaskUserPoolSidepanelForm
        onSubmit={(data) =>
          createTaskUserPool({
            variables: {
              input: data,
            },
          })
        }
        submitting={submitting}
      />
    </SidepanelPage>
  )
}

export default SidepanelTaskUserPoolCreate
