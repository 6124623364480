import type {
  PatientDemographicsFragment,
  UpdatePatientInput,
} from 'types/graphql'

import InputField from 'src/components/atoms/InputField/InputField'
import LanguageSelectField from 'src/components/atoms/LanguageSelectField/LanguageSelectField'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import { languageDisplay } from 'src/data/languages'
import { formatPhoneNumber } from 'src/lib/formatters'
import { UPDATE_PATIENT_CONTACT_INFORMATION } from 'src/pages/AppointmentCheckInPage/CheckInPatientTab/PatientContactInformationVerificationCard'

const PatientContactInformationCard = ({
  patient,
}: {
  patient: PatientDemographicsFragment
}) => {
  const preSubmit = (input: UpdatePatientInput) => {
    return { variables: { id: patient.id, input } }
  }

  return (
    <DataVerificationCard<UpdatePatientInput>
      title="Contact information"
      updateMutation={UPDATE_PATIENT_CONTACT_INFORMATION}
      defaultOpen
      preSubmit={preSubmit}
      data={[
        {
          name: 'mobileNumber',
          label: 'Mobile number',
          required: true,
          value: patient.contactInformation?.mobileNumber,
          displayValue: formatPhoneNumber(
            patient.contactInformation?.mobileNumber
          ),
          formInputComponent: PhoneInputField,
        },
        {
          name: 'homeNumber',
          label: 'Home number',
          value: patient.contactInformation?.homeNumber,
          displayValue: formatPhoneNumber(
            patient.contactInformation?.homeNumber
          ),
          formInputComponent: PhoneInputField,
        },
        {
          name: 'emailAddress',
          label: 'Email address',
          value: patient.contactInformation?.emailAddress,
          displayValue: patient.contactInformation?.emailAddress,
          formInputComponent: InputField,
        },
        {
          name: 'faxNumber',
          label: 'Fax number',
          value: patient.contactInformation?.faxNumber,
          displayValue: formatPhoneNumber(
            patient.contactInformation?.faxNumber
          ),
          formInputComponent: PhoneInputField,
        },
        {
          name: 'primaryLanguage',
          label: 'Primary language',
          required: true,
          hide: !patient.contactInformation,
          value: patient.contactInformation?.primaryLanguage,
          displayValue:
            languageDisplay[patient.contactInformation?.primaryLanguage],
          inputProps: {
            defaultValue: 'EN_US',
          },
          formInputComponent: LanguageSelectField,
        },
        {
          name: 'secondaryLanguage',
          label: 'Secondary language',
          value: patient.contactInformation?.secondaryLanguage,
          hide: !patient.contactInformation,
          displayValue:
            languageDisplay[patient.contactInformation?.secondaryLanguage],
          formInputComponent: LanguageSelectField,
        },
      ]}
    />
  )
}

export default PatientContactInformationCard
