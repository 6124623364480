import {
  relationshipTypeDisplay,
  relationshipTypes,
} from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { FindPatientVisit } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import { DropdownV2Field } from 'src/components/atoms/DropdownV2/DropdownV2'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import MultiSelectComboBoxFieldArray from 'src/components/molecules/MultiSelectComboBoxFieldArray/MultiSelectComboBoxFieldArray'
import {
  useDeleteVisitCaregiverPresentMutation,
  useUpsertVisitCaregiverPresentMutation,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useUpdateEffect } from 'src/utils'

export const CaregiversPresentSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const { currentUser } = useEmrAuth()
  const shouldUseV2 = currentUser.featureFlags.includes(
    'CAREGIVERS_PRESENT_DROPDOWN_V2'
  )
  const currentCaregiversPresent = visit.caregiversPresent?.map(
    (appointmentCaregiver) => {
      return (
        appointmentCaregiver.patientRelatedPersonRelationship?.id ||
        appointmentCaregiver.id
      )
    }
  )

  const formMethods = useForm({
    defaultValues: {
      caregiversPresent: currentCaregiversPresent,
    },
  })

  const caregiverOptions = [
    {
      name: 'Existing caregivers',
      value: 'existing-caregivers',
      disabled: true,
    },

    ...visit.patient.patientRelatedPersonRelationships
      .map((relationship) => {
        return {
          name: `${formatDisplayName(relationship.relatedPerson)} (${
            relationshipTypeDisplay[relationship.relationshipType]
          })`,
          value: relationship.id,
          disabled: false,
        }
      })

      .sort((a, b) => a.name.localeCompare(b.name)),

    { name: 'Other', value: 'other', disabled: true },

    ...relationshipTypes.map((relationship) => {
      const value = shouldUseV2
        ? (visit.caregiversPresent.find(
            (caregiver) => caregiver.relationshipType === relationship.value
          )?.id ?? relationship.value)
        : relationship.value
      return {
        ...relationship,
        value,
        disabled: false,
      }
    }),

    ...(!shouldUseV2
      ? [
          {
            name: 'Current caregivers',
            value: 'current-caregivers',
            disabled: true,
          },
        ]
      : []),

    ...(!shouldUseV2
      ? visit.caregiversPresent.map((appointmentCaregiver) => {
          const nameDisplay =
            appointmentCaregiver.patientRelatedPersonRelationship
              ? `${formatDisplayName(
                  appointmentCaregiver.patientRelatedPersonRelationship
                    .relatedPerson
                )} (${
                  relationshipTypeDisplay[
                    appointmentCaregiver.patientRelatedPersonRelationship
                      .relationshipType
                  ]
                })`
              : relationshipTypeDisplay[appointmentCaregiver.relationshipType]

          return {
            name: nameDisplay,
            value: appointmentCaregiver.id,
            disabled: !shouldUseV2,
          }
        })
      : []),
  ].filter(Boolean)

  const [upsertCaregiverPresent] = useUpsertVisitCaregiverPresentMutation()
  const [deleteCaregiverPresent] = useDeleteVisitCaregiverPresentMutation()

  useUpdateEffect(() => {
    formMethods.reset({
      caregiversPresent: currentCaregiversPresent,
    })
  }, [visit.caregiversPresent, formMethods])

  const handleOnClickSelectedOption = (
    option: string,
    originalValue: string
  ) => {
    const selectedOption = option

    const isRelationshipTypeSelected = selectedOption in relationshipTypeDisplay

    const existingCaregiverSelected = visit.caregiversPresent?.find(
      (caregiver) =>
        caregiver.patientRelatedPersonRelationship?.id === selectedOption
    )

    const wasExistingCaregiverSelected = visit.caregiversPresent?.find(
      (caregiver) =>
        caregiver.patientRelatedPersonRelationship?.id === originalValue
    )

    const isExistingRelationshipSelected =
      visit.patient.patientRelatedPersonRelationships.find(
        (relationship) => relationship.id === selectedOption
      )

    const wasExistingRelationshipSelected =
      visit.patient.patientRelatedPersonRelationships.find(
        (relationship) => relationship.id === originalValue
      )

    void upsertCaregiverPresent({
      variables: {
        input: {
          appointmentId: visit.id,
          caregiverId: existingCaregiverSelected
            ? existingCaregiverSelected.id
            : wasExistingCaregiverSelected
              ? wasExistingCaregiverSelected.id
              : !(
                    isRelationshipTypeSelected &&
                    isExistingRelationshipSelected &&
                    wasExistingRelationshipSelected
                  )
                ? originalValue
                : null,
          patientRelatedPersonRelationshipId: isExistingRelationshipSelected
            ? selectedOption
            : wasExistingRelationshipSelected
              ? wasExistingRelationshipSelected.id
              : null,
          relationshipType: isRelationshipTypeSelected ? selectedOption : null,
        },
      },
      onCompleted: (_data) => {
        toast.success('Caregiver updated')
      },
    })
  }

  const handleOnRemoveSelectedOption = (option: string) => {
    const selectedOption = option

    if (selectedOption) {
      void deleteCaregiverPresent({
        variables: {
          input: {
            appointmentId: visit.id,
            caregiverToDeleteKey: selectedOption,
          },
        },
        onCompleted: (_data) => {
          toast.success('Caregiver deleted')
        },
      })
    }
  }

  const saveV2Updates = () => {
    const selectedOptions = formMethods
      .getValues('caregiversPresent')
      ?.filter(Boolean)

    const optionsToAdd = selectedOptions.filter(
      (option) => !currentCaregiversPresent.includes(option)
    )

    const optionsToRemove = currentCaregiversPresent.filter(
      (option) => !selectedOptions.includes(option)
    )

    for (const option of optionsToAdd) {
      handleOnClickSelectedOption(option, '')
    }

    for (const option of optionsToRemove) {
      handleOnRemoveSelectedOption(option)
    }
  }

  if (shouldUseV2) {
    return (
      <Form formMethods={formMethods}>
        <StackView gap={50}>
          <Typography textStyle="title" fontWeight="semibold">
            Caregivers Present
          </Typography>
          <DropdownV2Field
            isMultiSelect={true}
            menuItemProps={{
              showMenuItemCheckbox: true,
              showMenuItemSelectedOptionIcon: false,
            }}
            menuProps={{
              showMenuSearch: true,
            }}
            name="caregiversPresent"
            onSetValue={saveV2Updates}
            options={caregiverOptions.map(({ name, value, disabled }) => ({
              label: name,
              value: value,
              disabled,
              showMenuItemCheckbox: !disabled,
            }))}
            testId="caregivers-present-section"
          />
        </StackView>
      </Form>
    )
  }

  return (
    <Form formMethods={formMethods}>
      <StackView
        direction="col"
        fullWidth
        space={75}
        testId="caregivers-present-section"
      >
        <Typography textStyle="title" fontWeight="semibold">
          Caregivers Present
        </Typography>

        <MultiSelectComboBoxFieldArray
          onClickSelectedOption={handleOnClickSelectedOption}
          onRemoveSelectedOption={handleOnRemoveSelectedOption}
          name="caregiversPresent"
          options={caregiverOptions}
          addSelectionText="Add a caregiver"
        />
      </StackView>
    </Form>
  )
}
