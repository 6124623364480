import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import TextareaAutoSaveSection from 'src/components/molecules/TextareaAutoSaveSection/TextareaAutoSaveSection'
import { calculateAge } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { AssessmentDiagnosesSubSection } from 'src/pdf/components/visits'

import {
  useUpdateAppointmentDocumentationMutation,
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from '../../useVisit'

import PhysicalExamSection from './PhysicalExamSection'
import ReviewOfSystemsSection from './ReviewOfSystemsSection'

const InterimHistorySection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId, navigatePatientChart } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()
  const params = useParams()
  const location = useLocation()

  return (
    <TextareaAutoSaveSection
      testId="interim-history"
      title="Interim history and concerns"
      isRichText={true}
      macroPhraseSection="HPI"
      defaultValue={visit?.historyPresentIllness}
      onChange={(value: string) =>
        updateAppointmentDocumentation({
          variables: {
            input: {
              visitId: visit.id,
              value: value,
              documentationType: 'HISTORY_PRESENT_ILLNESS',
            },
          },
        })
      }
      callToAction={[
        {
          name: 'Update history',
          icon: ArrowTopRightOnSquareIcon,
          callback: () =>
            navigatePatientChart({ route: 'history', shouldOpenNewTab: true }),
        },
        {
          name: 'Edit Vitals',
          buttonStyle: 'secondary',
          callback: () =>
            navigate(
              sidepanelRoute(
                { route: `/encounters/${visit.encounter.id}/vitals/edit` },
                location,
                params
              )
            ),
        },
      ]}
      loading={loading}
      disabled={visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')}
    />
  )
}

const HistoryPresentIllnessSection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId, navigatePatientChart } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()

  return (
    <TextareaAutoSaveSection
      testId="hpi"
      title="History of present illness (HPI)"
      isRichText={true}
      macroPhraseSection="HPI"
      defaultValue={visit?.historyPresentIllness}
      onChange={(value: string) =>
        updateAppointmentDocumentation({
          variables: {
            input: {
              visitId: visit.id,
              value: value,
              documentationType: 'HISTORY_PRESENT_ILLNESS',
            },
          },
        })
      }
      callToAction={{
        name: 'Update history',
        callback: () =>
          navigatePatientChart({ route: 'history', shouldOpenNewTab: true }),
      }}
      loading={loading}
      disabled={visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')}
    />
  )
}

const DevelopmentalSurveillanceSection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId, navigateVisitFlow } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()

  return (
    <TextareaAutoSaveSection
      testId="developmental-surveillance"
      title="Developmental surveillance"
      isRichText={true}
      macroPhraseSection="DEVELOPMENTAL_SURVEILLANCE"
      defaultValue={visit?.developmentalSurveillance}
      onChange={(value: string) =>
        updateAppointmentDocumentation({
          variables: {
            input: {
              visitId: visit.id,
              value: value,
              documentationType: 'DEVELOPMENTAL_SURVEILLANCE',
            },
          },
        })
      }
      callToAction={{
        name: 'View intake',
        callback: () => navigateVisitFlow('intake'),
      }}
      loading={loading}
      disabled={visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')}
    />
  )
}

const AdolescentConfidentialSection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()

  if (calculateAge(visit.patient.birthDate).years < 10) return null
  return (
    <TextareaAutoSaveSection
      testId="adolescent-confidential"
      title="Adolescent confidential"
      description="Add optional adolescent confidential comments pertaining to this current visit. Adolescent confidential comments will only be available within the visit note for the adolescent patient, and will not be shared with any parents and/or caregivers involved with the patient's care."
      isRichText={true}
      macroPhraseSection="ADOLESCENT_CONFIDENTIAL"
      defaultValue={visit?.documentationAdolescentConfidential}
      onChange={(value: string) =>
        updateAppointmentDocumentation({
          variables: {
            input: {
              visitId: visit.id,
              value: value,
              documentationType: 'DOCUMENTATION_ADOLESCENT_CONFIDENTIAL',
            },
          },
        })
      }
      loading={loading}
      disabled={visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')}
    />
  )
}

const AssessmentSection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId, navigateVisitFlow } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()
  const location = useLocation()

  return (
    <StackView>
      <TextareaAutoSaveSection
        testId="assessment"
        title="Assessment"
        isRichText={true}
        macroPhraseSection="ASSESSMENT"
        defaultValue={visit?.assessment}
        onChange={(value: string) =>
          updateAppointmentDocumentation({
            variables: {
              input: {
                visitId: visit.id,
                value: value,
                documentationType: 'ASSESSMENT',
              },
            },
          })
        }
        callToAction={[
          {
            name: 'View visit diagnoses',
            icon: ArrowTopRightOnSquareIcon,
            callback: () => navigateVisitFlow('orders'),
          },
          {
            name: 'Add diagnosis',
            buttonStyle: 'secondary',
            callback: () =>
              navigate(
                sidepanelRoute(
                  { route: `/encounters/${visit.encounter.id}/diagnoses/new` },
                  location
                )
              ),
          },
        ]}
        loading={loading}
        disabled={
          visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')
        }
      />

      <AssessmentDiagnosesSubSection visit={visit} />
    </StackView>
  )
}

const PlanSection = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId, navigateVisitFlow } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()

  return (
    <TextareaAutoSaveSection
      testId="plan"
      title="Plan"
      isRichText={true}
      macroPhraseSection="PLAN"
      macroPhraseTypeaheadPosition="above"
      defaultValue={visit?.planOfCare}
      onChange={(value: string) =>
        updateAppointmentDocumentation({
          variables: {
            input: {
              visitId: visit.id,
              value: value,
              documentationType: 'PLAN_OF_CARE',
            },
          },
        })
      }
      callToAction={{
        name: 'View visit orders',
        callback: () => navigateVisitFlow('orders'),
      }}
      loading={loading}
      disabled={visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')}
    />
  )
}

const DocumentationPage = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)
  useUpdateVisitFlowTabForAppointment('documentation')
  if (!visit) return null

  return (
    <StackView space={100} className="max-w-3xl pt-4">
      <StackView space={50}>
        <Typography textStyle="heading">Visit documentation</Typography>
        <Typography textStyle="body" color="text-base-color-fg-muted">
          Complete documentation of the patient visit in respective sections
          below
        </Typography>
      </StackView>

      {visit.appointmentDefinitions
        .map(({ type }) => type)
        .includes('WELL_CHILD') ? (
        <>
          <InterimHistorySection />
          <DevelopmentalSurveillanceSection />
        </>
      ) : (
        <HistoryPresentIllnessSection />
      )}

      <ReviewOfSystemsSection
        canEdit={hasRole('PRACTITIONER') || visit.chartingStatus !== 'COMPLETE'}
      />
      <PhysicalExamSection
        canEdit={hasRole('PRACTITIONER') || visit.chartingStatus !== 'COMPLETE'}
      />

      <AdolescentConfidentialSection />
      <AssessmentSection />
      <PlanSection />
    </StackView>
  )
}

export default DocumentationPage
