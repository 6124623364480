import { useMemo, useRef } from 'react'

import {
  CheckCircleIcon,
  EyeIcon,
  PencilIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { Order } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table'
import {
  interpretationDisplay,
  interpretationToColor,
} from 'src/data/interpretations'
import { sidepanelRoute } from 'src/lib/routes'
import {
  usePatientOrdersWithObservationsQuery,
  isRelevantForEncounter,
} from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

const defaultColDef: ColDef = {
  cellStyle: { binHouseTest: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'cursor-pointer',
  resizable: false,
}

const EditInHouseTestsAction = ({
  order,
  encounterId,
}: {
  order: Order
  encounterId?: string
}) => {
  const params = useParams()
  const location = useLocation()
  const { id: patientId } = params

  const orderIsCompleted = order.status === 'COMPLETED'
  const resultExists = !isEmpty(order.observations)
  const orderIsRevoked = order.status === 'REVOKED'

  return (
    <Button
      buttonStyle="secondary"
      icon={
        orderIsCompleted && resultExists
          ? PencilIcon
          : orderIsRevoked
            ? EyeIcon
            : PlusIcon
      }
      text={
        orderIsCompleted && resultExists
          ? 'Edit'
          : orderIsRevoked
            ? 'View'
            : 'Result'
      }
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          navigate(
            sidepanelRoute(
              {
                route: encounterId
                  ? `/encounters/${encounterId}/inHouseTests/${order.id}/edit`
                  : `/patients/${patientId}/inHouseTests/${order.id}/edit`,
              },
              location,
              params
            )
          )
        }
      }}
    />
  )
}

const InHouseTestOrderCompletionStatus = ({ order }: { order: Order }) => {
  return (
    <StackView justifyContent="center" fullWidth={false}>
      {order.status === 'COMPLETED' ? (
        <CheckCircleIcon
          className="h-6 w-6 fill-success"
          data-testid="in-house-order-completion-checkmark"
        />
      ) : order.status === 'REVOKED' ? (
        <XCircleIcon
          className="h-6 w-6 fill-danger"
          data-testid="in-house-order-refused-checkmark"
        />
      ) : (
        <CheckCircleIcon
          className="h-6 w-6 fill-gray-300"
          data-testid="in-house-order-default-checkmark"
        />
      )}
    </StackView>
  )
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
enum ObservationInterpretation {
  ABNORMAL,
  NORMAL,
  INCONCLUSIVE,
}

const InterpretationBadge = ({ interpretation }) => {
  return (
    <Badge color={interpretationToColor[interpretation] ?? 'gray'}>
      {interpretationDisplay[interpretation]}
    </Badge>
  )
}

export const Interpretation = ({ order }) => {
  const orderIsPended =
    order.intent === 'PROPOSAL' && order.status !== 'REVOKED'

  const overallInterpretation: keyof typeof ObservationInterpretation =
    order.resultInterpretation

  return (
    <StackView
      key={order.id}
      alignItems="end"
      justifyContent="center"
      space={25}
      className="shrink"
      fullWidth={false}
    >
      {overallInterpretation && (
        <InterpretationBadge interpretation={overallInterpretation} />
      )}
      {orderIsPended && (
        <Badge color={'yellow'}>
          {orderIsPended
            ? 'Pended'
            : interpretationDisplay[overallInterpretation]}
        </Badge>
      )}
    </StackView>
  )
}

export const InHouseTestOrderDetails = ({ order }: { order: Order }) => {
  return (
    <StackView space={25} className="truncate">
      <Typography textStyle="title" className="truncate">
        {order.codeDisplay}
      </Typography>
      <StackView direction="row" space={50} alignItems="center">
        <Typography textStyle="description">{order.name}</Typography>
        <Typography textStyle="description">&#xB7;</Typography>
        <Typography textStyle="description">
          {format(new Date(order.createdAt), 'MMM dd, yyyy')}
        </Typography>
      </StackView>
    </StackView>
  )
}

const columnDefs: ColDef[] = [
  {
    colId: 'order',
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = new Date(nodeA.data.createdAt)
      const b = new Date(nodeB.data.createdAt)

      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    sortable: true,
    sort: 'desc',
    cellRenderer: ({
      data,
      context,
    }: {
      data
      context: Record<string, string>
    }) => {
      return (
        <StackView justifyContent="between" direction="row" space={100}>
          <InHouseTestOrderCompletionStatus order={data} />
          <InHouseTestOrderDetails order={data} />
          <Interpretation order={data} />
          <StackView
            direction="row"
            fullWidth={false}
            justifyContent="center"
            alignItems="center"
            gap={50}
          >
            <EditInHouseTestsAction
              order={data}
              encounterId={context.encounterId}
            />
          </StackView>
        </StackView>
      )
    },
  },
]

const InHouseTestsTable = ({
  testId,
  patientId,
  encounterId,
  emptyMessage = 'No In-house tests to display',
  pagination = false,
  onRowClicked,
}: {
  testId?: string
  patientId: string
  encounterId?: string
  emptyMessage?: string
  pagination?: boolean
  onRowClicked?: (e: RowClickedEvent<Order>) => void
}) => {
  const gridRef = useRef<AgGridReact>()

  const { ordersWithObservations } =
    usePatientOrdersWithObservationsQuery(patientId)

  const inHouseTests = useMemo(
    () =>
      ordersWithObservations?.filter(
        (order) =>
          order.category === 'IHT' && isRelevantForEncounter(encounterId, order)
      ),
    [encounterId, ordersWithObservations]
  )

  return (
    <Table
      testId={testId ?? 'in-house-tests-table'}
      innerRef={gridRef}
      rowData={inHouseTests}
      onRowClicked={onRowClicked}
      domLayout="autoHeight"
      rowHeight={76}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={pagination}
      noRowsOverlayComponentParams={{ message: emptyMessage }}
      context={{ encounterId }}
      hideHeader
    />
  )
}

export default InHouseTestsTable
