import { useEffect, useState } from 'react'

import { BoltIcon, UserCircleIcon } from '@heroicons/react/24/solid'
import { formatDisplayName } from 'common/utils'
import isUndefined from 'lodash/isUndefined'

import { Form } from '@redwoodjs/forms'
import { useLocation, useParams, navigate } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView'
import EFaxIcon from 'src/components/icons/EFaxIcon/EFaxIcon'
import MultiSelectDropdownField from 'src/components/molecules/MultiSelectDropdownField'
import Page from 'src/components/molecules/Page/LegacyPage'
import Tabs from 'src/components/molecules/Tabs'
import {
  outboundEfaxStatusFilterOptions,
  useOutboundEfaxFilter,
  useUpdateFilter,
} from 'src/hooks/useOutboundEfaxFilter/useOutboundEfaxFilter'
import { sidepanelRoute } from 'src/lib/routes'

export const USERS = gql`
  query Users {
    users {
      id
      tenantId
      givenName
      familyName
    }
  }
`

import { OutboundFaxesTable } from './OutboundFaxesTable/OutboundFaxesTable'

const OutboundFaxPage = () => {
  const { getCurrentUser } = useEmrAuth()

  const location = useLocation()
  const params = useParams()

  const [updatedUser, setUpdatedUser] = useState(null)
  const { data } = useQuery(USERS)
  useEffect(() => {
    getCurrentUser().then((user) => {
      setUpdatedUser(user)
    })
  }, [getCurrentUser])

  const outboundEfaxSenderFilterOptions = data?.users?.map((user) => ({
    name: formatDisplayName(user),
    value: user.id,
  }))

  const { faxSenderUserIds, includeStatuses } = useOutboundEfaxFilter()

  const updateFilter = useUpdateFilter()

  useEffect(() => {
    if (updatedUser && isUndefined(includeStatuses)) {
      updateFilter(
        'outboundEfaxStatusFilter',
        updatedUser.userSettings.outboundEfaxStatusFilter
      )
    }
  }, [includeStatuses, updateFilter, updatedUser])

  return (
    <Page
      tag={{ title: 'OutboundFax', description: 'OutboundFax page' }}
      title="Outbound E-fax"
      description="View the status of outbound faxes that you have sent or queued up to send."
      actions={[
        <Button
          key={'efax'}
          text={'Start e-fax'}
          icon={EFaxIcon}
          buttonStyle="secondary"
          onClick={() =>
            navigate(
              sidepanelRoute(
                {
                  route: '/send-fax',
                },
                location,
                params
              )
            )
          }
        />,
      ]}
    >
      <StackView className="relative z-20" direction="row" justifyContent="end">
        <Form>
          <div className="absolute right-core-space-0 top-core-space-200">
            <StackView direction="row" space={50}>
              <MultiSelectDropdownField
                testId="outbound-efax-status-filter"
                name="outboundEfaxStatusFilter"
                icon={BoltIcon}
                urlParamName="outboundEfaxStatusFilter"
                emptyDisplayText="Status"
                options={[...outboundEfaxStatusFilterOptions]}
                defaultValue={
                  updatedUser?.userSettings?.outboundEfaxStatusFilter ?? []
                }
                onClearFilter={() =>
                  updateFilter('outboundEfaxStatusFilter', [])
                }
                onToggleFilter={(values) =>
                  updateFilter('outboundEfaxStatusFilter', values)
                }
              />
              <MultiSelectDropdownField
                testId="outbound-efax-sender-filter"
                name="outboundEfaxSenderFilter"
                icon={UserCircleIcon}
                urlParamName="outboundEfaxSenderFilter"
                emptyDisplayText="Sender"
                options={outboundEfaxSenderFilterOptions ?? []}
                defaultValue={
                  updatedUser?.userSettings?.outboundEfaxSenderFilter ?? []
                }
                onClearFilter={() =>
                  updateFilter('outboundEfaxSenderFilter', [])
                }
                onToggleFilter={(values) =>
                  updateFilter('outboundEfaxSenderFilter', values)
                }
              />
            </StackView>
          </div>
        </Form>
      </StackView>

      <Box className="z-0">
        <Tabs
          tabs={[
            {
              name: 'Active',
              key: 'active',
              content: (
                <OutboundFaxesTable
                  filters={{ faxSenderUserIds, includeStatuses, active: true }}
                />
              ),
            },
            {
              name: 'Archived',
              key: 'archived',
              content: (
                <OutboundFaxesTable
                  filters={{
                    faxSenderUserIds,
                    includeStatuses,
                  }}
                />
              ),
            },
          ]}
        />
      </Box>
    </Page>
  )
}

export default OutboundFaxPage
