import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { AppointmentPatientCheckInQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import GuardianshipTypeSelectField from 'src/components/atoms/GuardianshipTypeSelectField/GuardianshipTypeSelectField'
import RelationshipTypeSelectField from 'src/components/atoms/RelationshipTypeSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext'

export const UPDATE_PATIENT_CAREGIVER_RELATIONSHIP = gql`
  mutation UpdatePatientCaregiverRelationship(
    $patientId: String!
    $relatedPersonId: String!
    $input: UpdatePatientCaregiverRelationshipInput!
  ) {
    updatePatientCaregiverRelationship(
      id: $patientId
      relatedPersonId: $relatedPersonId
      input: $input
    ) {
      id
      legalStatus
      patientRelatedPersonRelationships {
        id
        guardianshipType
        isGuarantor
        relationshipType
        verifiedAt
      }
    }
  }
`

const VERIFY_PATIENT_CAREGIVER_RELATIONSHIP = gql`
  mutation VerifyPatientCaregiverRelationship(
    $patientId: String!
    $relatedPersonId: String!
  ) {
    verifyPatientCaregiverRelationship(
      id: $patientId
      relatedPersonId: $relatedPersonId
    ) {
      id
      patientRelatedPersonRelationships {
        id
        verifiedAt
      }
    }
  }
`

const CaregiverRelationshipVerificationCard: React.FC<{
  relationship: AppointmentPatientCheckInQuery['appointment']['patient']['patientRelatedPersonRelationships'][0]
}> = ({ relationship }) => {
  const { relationshipType, guardianshipType, isGuarantor, relatedPerson } =
    relationship

  const {
    checkInVerification,
    appointmentData: { patient },
  } = useAppointmentCheckIn()

  const [verifyPatientCaregiverRelationship] = useMutation(
    VERIFY_PATIENT_CAREGIVER_RELATIONSHIP
  )

  const { verificationRequirement, lastVerifiedAt } =
    checkInVerification['CAREGIVER'][relationship.id]['RELATIONSHIP']

  const onVerifyPatientCaregiverRelationship = () => {
    verifyPatientCaregiverRelationship({
      variables: {
        patientId: patient.id,
        relatedPersonId: relatedPerson.id,
      },
    })
  }

  const preSubmitPatientCaregiverRelationship = (data) => {
    const { guardianshipType, isGuarantor, relationshipType } = data
    return {
      variables: {
        patientId: patient.id,
        relatedPersonId: relatedPerson.id,
        input: { guardianshipType, isGuarantor, relationshipType },
      },
    }
  }

  return (
    <DataVerificationCard
      title="Caregiver relationship"
      lastVerifiedAt={lastVerifiedAt}
      defaultOpen
      updateMutation={UPDATE_PATIENT_CAREGIVER_RELATIONSHIP}
      preSubmit={preSubmitPatientCaregiverRelationship}
      verificationOptions={{
        onVerify: onVerifyPatientCaregiverRelationship,
        verificationRequirement,
      }}
      data={[
        {
          name: `guardianshipType`,
          value: guardianshipType,
          displayValue: guardianshipTypeDisplay[guardianshipType],
          label: 'Guardianship status',
          required: true,
          formInputComponent: GuardianshipTypeSelectField,
        },
        {
          name: `isGuarantor`,
          displayValue: isGuarantor ? 'Yes' : 'No',
          value: isGuarantor,
          label: 'Guarantor status',
          required: true,
          inputProps: {
            includeEmptyOption: false,
            trueDisplay: 'Yes',
            falseDisplay: 'No',
          },
          formInputComponent: BooleanSelectField,
        },
        {
          name: `relationshipType`,
          value: relationshipType,
          displayValue: relationshipTypeDisplay[relationshipType],
          label: 'Relationship to patient',
          required: true,
          formInputComponent: RelationshipTypeSelectField,
        },
      ]}
    />
  )
}

export default CaregiverRelationshipVerificationCard
