import { useCallback, useState } from 'react'

import { CheckIcon } from '@heroicons/react/20/solid'
import { TrashIcon } from '@heroicons/react/20/solid'
import { CalendarIcon, CalendarDaysIcon } from '@heroicons/react/24/outline'
import {
  AppointmentStatus,
  UpdateAppointmentStatus,
  CancelAppointment,
  CancelAppointmentStatus,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Dropdown, { DropdownItem } from 'src/components/atoms/Dropdown/Dropdown'
import { Option } from 'src/components/atoms/Select/Select'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import CancelAppointmentModal from 'src/components/molecules/CancelAppointmentModal/CancelAppointmentModal'
import { StatusIndicator } from 'src/components/StatusIndicator/StatusIndicator'
import {
  CANCELLED_APPOINTMENT_STATUSES,
  appointmentStatusConfiguration,
  checkInEnabledStatuses,
  isAppointmentCancelled,
  nonCheckedInAppointmentStatuses,
} from 'src/data/appointmentStatus'
import {
  CANCEL_APPOINTMENT_MUTATION,
  UPDATE_APPOINTMENT_STATUS_MUTATION,
} from 'src/providers/context/AppointmentCheckInContext/schema'
import { useAppointmentManager } from 'src/providers/context/AppointmentsManagementContext'

interface Props {
  appointmentId: string
}

const GET_APPOINTMENT_STATUS_QUERY = gql`
  query GetAppointmentStatus($id: String!) {
    appointment: appointment(id: $id) {
      id
      status
      chiefComplaints
      start
      end
      chartingStatus
      visitComment
      patientId
      practitionerId
      locationId
      patient {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
        birthDate
        sexAtBirth
        active
        contactInformation {
          id
          mobileNumber
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
        primaryGuardian {
          id
          contactInformation {
            id
            homeAddress {
              id
              line1
              line2
              city
              state
              postalCode
            }
          }
        }
        patientRelatedPersonRelationships {
          id
          doesResideWith
          relationshipType
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
            contactInformation {
              id
              mobileNumber
            }
          }
        }
      }
      patientRegistrationIntent {
        id
        givenName
        familyName
        phoneNumber
        birthDate
        sexAtBirth
        email
        appointment {
          appointmentDefinitions {
            id
          }
        }
      }
      appointmentDefinitions {
        id
      }
    }
  }
`

const AppointmentStatusSelect = ({ appointmentId }: Props) => {
  const {
    appointmentToCancel,
    resetAppointmentToCancel,
    setAppointmentToCancel,
    selectedAppointmentEvent,
    initiateAppointmentCreation,
    allowRescheduleNow,
  } = useAppointmentManager()
  const [cancelAppointmentStatus, setCancelAppointmentStatus] =
    useState<CancelAppointmentStatus>()
  const { data, loading, error } = useQuery(GET_APPOINTMENT_STATUS_QUERY, {
    variables: {
      id: appointmentId,
    },
  })

  const [updateAppointmentStatusMutation] =
    useMutation<UpdateAppointmentStatus>(UPDATE_APPOINTMENT_STATUS_MUTATION)

  const [cancelAppointmentMutation] = useMutation<CancelAppointment>(
    CANCEL_APPOINTMENT_MUTATION
  )

  const updateAppointmentStatus = useCallback(
    (status: AppointmentStatus, callbackAfterComplete?: () => void) => {
      void updateAppointmentStatusMutation({
        variables: {
          id: appointmentId,
          input: { status },
        },
        refetchQueries: ['GetRoomOptionsForAppointment'],
        onCompleted: () => {
          toast.success('Appointment status updated')
          if (callbackAfterComplete) {
            callbackAfterComplete()
          }
        },
        onError: () => {
          toast.error('Failed to update appointment status')
        },
      })
    },
    [updateAppointmentStatusMutation, appointmentId]
  )

  const cancelAppointment = useCallback(
    (
      shouldCreateSchedulingCase?: boolean,
      callbackAfterComplete?: () => void
    ) => {
      void cancelAppointmentMutation({
        variables: {
          id: appointmentId,
          input: { shouldCreateSchedulingCase, cancelAppointmentStatus },
        },
        onCompleted: () => {
          toast.success('Appointment cancelled')
          if (callbackAfterComplete) {
            callbackAfterComplete()
          }
        },
        onError: () => {
          toast.error('Failed to cancel appointment')
        },
        refetchQueries: [
          'FindPractitionersAndAppointments',
          'GetRoomOptionsForAppointment',
        ],
      })
    },
    [cancelAppointmentMutation, cancelAppointmentStatus, appointmentId]
  )

  if (loading || error) return null

  const appointmentStatusOptions: Option[] = (
    checkInEnabledStatuses.includes(data.appointment.status)
      ? checkInEnabledStatuses
      : nonCheckedInAppointmentStatuses
  ).map((status) => {
    const configuration = appointmentStatusConfiguration[status]
    const { disabled, badgeColor } = configuration

    return {
      value: status,
      name: configuration.display,
      icon: {
        value: <StatusIndicator color={badgeColor} />,
        class: '',
      },
      selectedIcon: {
        value: <CheckIcon className="h-5 w-5" />,
        class: 'text-primary',
      },
      disabled,
    }
  })

  const onSelect = (status: AppointmentStatus) => {
    if (isAppointmentCancelled(status)) {
      setCancelAppointmentStatus(status as CancelAppointmentStatus)
    } else {
      updateAppointmentStatus(status)
    }
  }

  if (cancelAppointmentStatus)
    return (
      <Dropdown
        onClickOutside={() => {
          setCancelAppointmentStatus(undefined)
        }}
        mode="id"
        name="status-appointment"
        options={appointmentStatusOptions}
        selected={data.appointment.status}
        onSelect={onSelect}
        disabled={isAppointmentCancelled(data.appointment.status)}
        itemsContainerClassName="w-60"
      >
        {allowRescheduleNow && (
          <DropdownItem
            onSelect={() => {
              setAppointmentToCancel({
                appointmentId,
                cancellationType: 'now',
                cancelAppointmentCallback: () => {
                  cancelAppointment(false, () => {
                    initiateAppointmentCreation(
                      selectedAppointmentEvent,
                      data.appointment
                    )
                  })
                },
              })
              // The dropdown is being closed after pick. We are just delaying the
              //change that it should do to the 1st state.
              setTimeout(() => {
                setCancelAppointmentStatus(undefined)
              }, 500)
            }}
            defaultClasses="px-3 py-2"
          >
            <StackView
              space={75}
              direction="row"
              justifyContent="start"
              alignItems="center"
            >
              <CalendarIcon className="h-3 w-3" />
              <Typography>Reschedule now</Typography>
            </StackView>
          </DropdownItem>
        )}
        <DropdownItem
          defaultClasses="px-3 py-2"
          onSelect={() => {
            setAppointmentToCancel({
              appointmentId,
              cancellationType: 'later',
              cancelAppointmentCallback: () => {
                cancelAppointment(true)
              },
            })
            setTimeout(() => {
              setCancelAppointmentStatus(undefined)
            }, 500)
          }}
        >
          <StackView
            space={75}
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <CalendarDaysIcon className="h-3 w-3" />
            <Typography>Reschedule later</Typography>
          </StackView>
        </DropdownItem>
        <DropdownItem
          onSelect={() => {
            setAppointmentToCancel({
              appointmentId,
              cancellationType: 'never',
              cancelAppointmentCallback: () => {
                cancelAppointment(false)
              },
            })
            setCancelAppointmentStatus(undefined)
          }}
          defaultClasses="px-3 py-2 text-red-600 hover:!text-white hover:!bg-red-600 rounded-b-md"
        >
          <StackView
            space={75}
            direction="row"
            justifyContent="start"
            alignItems="center"
          >
            <TrashIcon className="h-3 w-3" />
            <Typography className="overflow-clip whitespace-nowrap">
              Cancel without rescheduling
            </Typography>
          </StackView>
        </DropdownItem>
      </Dropdown>
    )

  return (
    <>
      <Dropdown
        mode="id"
        disabled={isAppointmentCancelled(data.appointment.status)}
        dontCloseOnSelect={CANCELLED_APPOINTMENT_STATUSES}
        name="status-appointment"
        options={appointmentStatusOptions}
        selected={data.appointment.status}
        onSelect={onSelect}
        itemsContainerClassName="w-60"
      />

      {appointmentToCancel && (
        <CancelAppointmentModal
          appointmentToCancel={appointmentToCancel}
          onClose={resetAppointmentToCancel}
        />
      )}
    </>
  )
}

export default AppointmentStatusSelect
