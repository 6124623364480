import { isEmpty } from 'lodash'
import {
  CreatePatientPortalPayment,
  CreatePatientPortalPaymentVariables,
  OffsetPaginationInput,
  PatientPortalHistoricalPatientPayments,
  PatientPortalHistoricalPatientPaymentsVariables,
  PatientPortalPatientBalances,
  PatientPortalPatientBalancesVariables,
  PortalPaginatedPaymentsFilters,
  PatientPortalPatientBillingStatement,
  PatientPortalPatientBillingStatementVariables,
  GetPatientPortalTilledAccountIdQuery,
  GetPatientPortalTilledAccountIdQueryVariables,
  PortalPaginatedRefundsFilters,
  PatientPortalHistoricalPaginatedPatientRefunds,
  PatientPortalHistoricalPaginatedPatientRefundsVariables,
  PatientPortalPaymentsAndRefunds,
  PatientPortalPaymentsAndRefundsVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { usePatientPortalContext } from 'src/providers/context/PatientPortalContext/PatientPortalContext'

import { PATIENT_DETAILED_BILLING_STATEMENT_FRAGMENT } from '../usePatientBillingStatement/usePatientBillingStatement'
import {
  CANDID_PAYMENTS_FRAGMENT,
  DEVELO_CANDID_PATIENT_REFUNDS_FRAGMENT,
  PAYMENT_PLAN_PAYMENT_FRAGMENT,
  PAYMENT_PLAN_REFUND_FRAGMENT,
} from '../usePatientPayments/usePatientPayments'

const GET_PATIENT_PORTAL_PATIENT_BALANCES = gql`
  query PatientPortalPatientBalances($patientIds: [String!]!) {
    patientBalances(patientIds: $patientIds) {
      id
      totalBalanceCents
      patients {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
        patientRelatedPersonRelationships {
          id
          isGuarantor
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
          }
        }
        candidBalanceTotalCents
      }
    }
  }
`

const GET_PATIENT_PORTAL_PATIENT_BILLING_STATEMENT = gql`
  query PatientPortalPatientBillingStatement($patientIds: [String!]!) {
    patientPortalBillingStatement(patientIds: $patientIds) {
      ...BillingStatementFragment
    }
  }
  ${PATIENT_DETAILED_BILLING_STATEMENT_FRAGMENT}
`

const GET_PATIENT_PORTAL_PAYMENTS = gql`
  query PatientPortalHistoricalPatientPayments(
    $input: PortalPaginatedPaymentsInput!
  ) {
    patientPortalPaginatedPayments(input: $input) {
      pageInfo {
        currentPage
        pageCount
      }
      candidPatientPayments {
        id
        amountCents
        paidAt
        paymentTransactionId
        patient {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
        }
      }
    }
  }
`

const GET_PATIENT_PAGINATED_REFUNDS = gql`
  query PatientPortalHistoricalPaginatedPatientRefunds(
    $input: PortalPaginatedRefundsInput!
  ) {
    patientPortalPaginatedRefunds(input: $input) {
      pageInfo {
        currentPage
        pageCount
      }
      candidPatientRefunds {
        id
        amountCents
        createdAt
        paymentTransactionId
        patient {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
        }
      }
    }
  }
`

export const GET_PATIENT_PORTAL_PAYMENTS_AND_REFUNDS = gql`
  query PatientPortalPaymentsAndRefunds($patientIds: [String!]!) {
    patientPortalPaymentHistory(patientIds: $patientIds) {
      patientPayments {
        ...CandidPaymentsFragment
      }
      develoCandidPatientRefunds {
        ...DeveloCandidPatientRefundFragment
      }
      paymentPlanPaymentsForPatient {
        ...PaymentPlanPaymentFragment
      }
      paymentPlanRefundsForPatient {
        ...PaymentPlanRefundFragment
      }
    }
  }
  ${CANDID_PAYMENTS_FRAGMENT}
  ${DEVELO_CANDID_PATIENT_REFUNDS_FRAGMENT}
  ${PAYMENT_PLAN_PAYMENT_FRAGMENT}
  ${PAYMENT_PLAN_REFUND_FRAGMENT}
`

const CREATE_PATIENT_PORTAL_PAYMENT = gql`
  mutation CreatePatientPortalPayment(
    $input: CreatePatientPortalTilledPaymentInput!
  ) {
    createPatientPortalTilledPayment(input: $input)
  }
`

const GET_TILLED_PAYMENT_INTENT_QUERY = gql`
  query GetPatientPortalPaymentIntentQuery($tilledPaymentIntentId: String!) {
    patientPortalTilledPaymentIntent(
      tilledPaymentIntentId: $tilledPaymentIntentId
    ) {
      id
    }
  }
`

const GET_TILLED_ACCOUNT_ID_QUERY = gql`
  query GetPatientPortalTilledAccountIdQuery {
    patientPortalTilledAccountId
  }
`

export const usePatientBalancesQuery = ({
  patientIds,
}: {
  patientIds: string[]
}) => {
  const { data, ...rest } = useQuery<
    PatientPortalPatientBalances,
    PatientPortalPatientBalancesVariables
  >(GET_PATIENT_PORTAL_PATIENT_BALANCES, {
    variables: { patientIds },
    skip: isEmpty(patientIds),
  })

  return {
    patientBalances: data?.patientBalances,
    ...rest,
  }
}

export const usePatientPortalBillingStatementQuery = ({
  patientIds,
}: {
  patientIds: string[]
}) => {
  const { data, ...rest } = useQuery<
    PatientPortalPatientBillingStatement,
    PatientPortalPatientBillingStatementVariables
  >(GET_PATIENT_PORTAL_PATIENT_BILLING_STATEMENT, {
    variables: { patientIds },
    skip: isEmpty(patientIds),
  })

  return {
    billingStatement: data?.patientPortalBillingStatement,
    ...rest,
  }
}

export const usePatientPortalHistoricalPaymentsQuery = ({
  filters,
  paginationInput,
}: {
  filters: PortalPaginatedPaymentsFilters
  paginationInput: OffsetPaginationInput
}) => {
  const { data, ...rest } = useQuery<
    PatientPortalHistoricalPatientPayments,
    PatientPortalHistoricalPatientPaymentsVariables
  >(GET_PATIENT_PORTAL_PAYMENTS, {
    variables: {
      input: {
        filters,
        paginationInput,
      },
    },
    skip: isEmpty(filters.patientIds),
  })

  return {
    pageCount: data?.patientPortalPaginatedPayments.pageInfo.pageCount,
    currentPage: data?.patientPortalPaginatedPayments.pageInfo.currentPage,
    candidPayments: data?.patientPortalPaginatedPayments.candidPatientPayments,
    ...rest,
  }
}

export const usePatientPortalHistoricalRefundsQuery = ({
  filters,
  paginationInput,
}: {
  filters: PortalPaginatedRefundsFilters
  paginationInput: OffsetPaginationInput
}) => {
  const { data, ...rest } = useQuery<
    PatientPortalHistoricalPaginatedPatientRefunds,
    PatientPortalHistoricalPaginatedPatientRefundsVariables
  >(GET_PATIENT_PAGINATED_REFUNDS, {
    variables: {
      input: {
        filters,
        paginationInput,
      },
    },
    skip: isEmpty(filters.patientIds),
  })

  return {
    pageCount: data?.patientPortalPaginatedRefunds.pageInfo.pageCount,
    currentPage: data?.patientPortalPaginatedRefunds.pageInfo.currentPage,
    candidRefunds: data?.patientPortalPaginatedRefunds.candidPatientRefunds,
    ...rest,
  }
}

export const usePatientPortalPaymentsAndRefundsHistoryQuery = ({
  patientIds,
}: {
  patientIds: string[]
}) => {
  const { data, ...rest } = useQuery<
    PatientPortalPaymentsAndRefunds,
    PatientPortalPaymentsAndRefundsVariables
  >(GET_PATIENT_PORTAL_PAYMENTS_AND_REFUNDS, {
    variables: {
      patientIds,
    },
    skip: isEmpty(patientIds),
  })

  return {
    patientPayments: data?.patientPortalPaymentHistory?.patientPayments,
    develoCandidPatientRefunds:
      data?.patientPortalPaymentHistory?.develoCandidPatientRefunds,
    paymentPlanPaymentsForPatient:
      data?.patientPortalPaymentHistory?.paymentPlanPaymentsForPatient,
    paymentPlanRefundsForPatient:
      data?.patientPortalPaymentHistory?.paymentPlanRefundsForPatient,
    ...rest,
  }
}

export const usePatientPortalCreateTilledPaymentMutation = () => {
  return useMutation<
    CreatePatientPortalPayment,
    CreatePatientPortalPaymentVariables
  >(CREATE_PATIENT_PORTAL_PAYMENT)
}

export const usePatientPortalTilledAccountIdQuery = () => {
  const { data, ...rest } = useQuery<
    GetPatientPortalTilledAccountIdQuery,
    GetPatientPortalTilledAccountIdQueryVariables
  >(GET_TILLED_ACCOUNT_ID_QUERY)

  return {
    tilledAccountId: data?.patientPortalTilledAccountId,
    ...rest,
  }
}

export const usePatientPortalTilledPaymentQuery = (
  tilledPaymentIntentId: string
) => {
  const { tenant } = usePatientPortalContext()
  const POLLING_INTERVAL = 2000
  const { previousData, stopPolling, ...rest } = useQuery(
    GET_TILLED_PAYMENT_INTENT_QUERY,
    {
      variables: { tilledPaymentIntentId },
      skip: !tilledPaymentIntentId,
      onCompleted: async (data) => {
        if (previousData !== data) {
          stopPolling()
          navigate(
            routes.patientPortalPaymentSuccess({
              tenantPortalKey: tenant.portalKey,
            })
          )
        }
      },
      onError: () => {
        stopPolling()
      },
      pollInterval: tilledPaymentIntentId && POLLING_INTERVAL,
    }
  )

  return rest
}
