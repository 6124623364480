import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
  DocumentTextIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { appointmentTypeDisplay } from 'common/data/appointmentTypes'
import { isEmptyLexical } from 'common/lexical/lexical'
import {
  unitDisplay,
  getImperialDisplay,
  getMetricDisplay,
  evaluate,
  extractValue,
} from 'common/unitConverter/unitConverter'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { isNil } from 'lodash'
import sortBy from 'lodash/sortBy'
import { match } from 'ts-pattern'
import { AppointmentType, GetPatient, Observation } from 'types/graphql'

import { useLocation, useParams, navigate, Link } from '@redwoodjs/router'
import { MetaTags, useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import Divider from 'src/components/atoms/Divider'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { WithIndicator } from 'src/components/atoms/WithIndicator/WithIndicator'
import { PatientStatusBadge } from 'src/components/molecules/PatientStatusBadge/PatientStatusBadge'
import { Tooltip } from 'src/components/molecules/Tooltip/Tooltip'
import PatientInsuranceChip from 'src/components/PatientInsuranceChip/PatientInsuranceChip'
import { PatientMembershipCheckmark } from 'src/components/PatientMembershipCheckmark/PatientMembershipCheckmark'
import PatientNoInsuranceChip from 'src/components/PatientNoInsuranceChip/PatientNoInsuranceChip'
import { StatusIndicator } from 'src/components/StatusIndicator/StatusIndicator'
import { preferredPronounPatientHeaderDisplay } from 'src/data/preferredPronouns'
import { sexDisplay } from 'src/data/sexes'
import {
  calculateAge,
  formatAddress,
  formatAge,
  formatDateDisplay,
  formatPhoneNumber,
  toDecimal,
} from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { PatientForCanidDataElement } from 'src/pages/PatientChartsPage/PatientForCanidDataElement/PatientForCanidDataElement'
import { useAllergiesQuery } from 'src/pages/PatientChartsPage/PatientHistory/Allergies/useAllergies'
import {
  PATIENT_HEADER_SIZE,
  useLocalSettings,
} from 'src/providers/context/LocalSettingsContext'
import { sortInsuranceCoverages } from 'src/utils/insuranceCoverages'

import { PATIENT_STATUS_FRAGMENT } from '../PatientDemographics/fragments'

import { PatientTaskButton } from './PatientTaskButton'

type TopHeaderProps = {
  headerIsCollapsed: boolean
  onHeaderToggleClicked: () => void
} & PatientProps

type PatientProps = {
  patient: GetPatient['patient']
}

type InsuranceCoverageRendererProps = {
  insuranceCoverages: GetPatient['patient']['activeInsuranceCoverages']
  insuranceOptOutStatus: GetPatient['patient']['insuranceOptOutStatus']
  patientId: string
}

type ContactInformationRendererProps = {
  contactInformation: GetPatient['patient']['contactInformation']
  showHeading?: boolean
}

type PatientInfoBoxProps = {
  heading: string
  border?: boolean
}

type AllergyRendererProps = {
  patientId: string
}

type VisitBoxProps = {
  label: string
  appointment?:
    | GetPatient['patient']['nextAppointment']
    | GetPatient['patient']['mostRecentAppointment']
  wellChildAppointment?:
    | GetPatient['patient']['mostRecentWellChildAppointment']
    | GetPatient['patient']['mostRecentAppointment']
  lastOrNext: 'last' | 'next'
}

const GET_PATIENT = gql`
  query GetPatient($id: String!) {
    patient: patient(id: $id) {
      id
      ...PatientStatusFragment
      mrn
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
      nickname
      preferredName
      preferredPronouns
      birthDate
      sexAtBirth
      insuranceOptOutStatus
      practiceComment
      contactInformation {
        id
        mobileNumber
        homeNumber
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      primaryGuardian {
        id
        contactInformation {
          id
          mobileNumber
          homeNumber
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
      }
      activeInsuranceCoverages {
        id
        coordinationOfBenefitsType
        planType
        planName
        payer {
          id
          displayName
        }
        mostRecentEligibility {
          id
          status
        }
      }
      nextAppointment {
        id
        start
        chiefComplaints
        visitNoteFhirId
        appointmentDefinitions {
          id
          type
          name
        }
      }
      mostRecentAppointment {
        id
        start
        chiefComplaints
        visitNoteFhirId
        appointmentDefinitions {
          id
          type
          name
        }
        encounter {
          id
          primaryDiagnosis {
            id
            code {
              code
              system
              display
            }
          }
        }
      }
      mostRecentWellChildAppointment {
        id
        start
        chiefComplaints
        visitNoteFhirId
        appointmentDefinitions {
          id
          type
          name
        }
        encounter {
          id
          primaryDiagnosis {
            id
            code {
              code
              system
              display
            }
          }
        }
      }
      nextWellChildAppointment {
        id
        start
        chiefComplaints
        visitNoteFhirId
        appointmentDefinitions {
          id
          type
          name
        }
      }
      mostRecentHeightVital {
        value
        imperial
        percentile
        updatedAt
      }
      mostRecentWeightVital {
        value
        imperial
        percentile
        updatedAt
      }
      mostRecentBmiVital {
        value
        imperial
        percentile
        updatedAt
      }
      mostRecentBodyTemperatureVital {
        value
        imperial
        percentile
        updatedAt
      }
      mostRecentBloodPressureVital {
        value
        imperial
        percentile
        updatedAt
      }
      activeMembershipPlan {
        id
        startDate
        endDate
      }
    }
  }
  ${PATIENT_STATUS_FRAGMENT}
`

const formatPatientHeaderDisplayName = (patient) => {
  return (
    <>
      <Typography className="whitespace-nowrap" textStyle="title-s">
        {formatDisplayName(patient)}
      </Typography>
      {patient.preferredName ? (
        <Typography
          className="whitespace-nowrap"
          color="text-base-color-fg-subtle"
          textStyle="title-s"
        >
          {`(${patient.preferredName})`}
        </Typography>
      ) : null}
    </>
  )
}

const Separator: React.FC = () => {
  return <div className="h-core-space-100 border-core-border-width-10"></div>
}

const BulletSeparator: React.FC = () => {
  return <div>{'\u2022'}</div>
}

const TopHeader: React.FC<TopHeaderProps> = ({
  patient,
  headerIsCollapsed,
  onHeaderToggleClicked,
}: TopHeaderProps) => {
  const location = useLocation()
  const params = useParams()

  return (
    <StackView
      direction="row"
      space={100}
      alignItems="center"
      justifyContent="between"
      className={clsx([
        'text-gray-700',
        !headerIsCollapsed && 'border-b-core-border-width-10 pb-core-space-100',
      ])}
    >
      <StackView
        direction="row"
        space={50}
        alignItems="center"
        testId="patient-name-row"
        fullWidth={false}
      >
        {formatPatientHeaderDisplayName(patient)}

        <PatientMembershipCheckmark patient={patient} />

        <PatientStatusBadge patient={patient} />
      </StackView>

      <StackView
        direction="row"
        alignItems="center"
        space={50}
        fullWidth={false}
        className="shrink"
      >
        <Link
          to={sidepanelRoute(
            {
              route: `/patients/${patient.id}/allergies/new`,
            },
            location,
            params
          )}
        >
          <Typography textStyle="interface-strong-s">Allergies: </Typography>
        </Link>

        <AllergiesRenderer patientId={patient.id} />

        <Separator />

        <Typography textStyle="interface-default-s">
          {sexDisplay[patient.sexAtBirth][0]}
        </Typography>
        <Separator />
        <Typography
          className="whitespace-nowrap"
          textStyle="interface-default-s"
        >
          {formatAge(patient.birthDate)} ({formatDateDisplay(patient.birthDate)}
          )
        </Typography>
        <Separator />
        <Typography textStyle="interface-default-s">{patient.mrn}</Typography>
        <Separator />
        <InsuranceCoverageRenderer
          insuranceCoverages={patient.activeInsuranceCoverages}
          insuranceOptOutStatus={patient.insuranceOptOutStatus}
          patientId={patient.id}
        />
        <Separator />
        <PatientTaskButton patientId={patient.id} />
        <WithIndicator
          indicator={<StatusIndicator color="purple" />}
          showIndicator={!isEmptyLexical(patient.practiceComment)}
        >
          <Button
            buttonStyle="ghost"
            className="!p-0"
            onClick={() =>
              navigate(
                sidepanelRoute(
                  { route: `/patients/${patient.id}/practice-comment` },
                  location,
                  params
                )
              )
            }
            tooltip="Practice comments"
            tooltipPosition="left"
            tooltipPadding={25}
            buttonSize="s"
            icon={DocumentTextIcon}
          />
        </WithIndicator>
        <Separator />
        <Button
          testId="patient-header-toggle"
          buttonStyle="ghost"
          className="!p-core-space-0"
          onClick={() => onHeaderToggleClicked()}
          buttonSize="s"
          icon={headerIsCollapsed ? ChevronDoubleDownIcon : ChevronDoubleUpIcon}
        />
      </StackView>
    </StackView>
  )
}

const getTemperatureInFahrenheitAndCelsius = (value, precision = 1) =>
  match(value.unit)
    .with('degF', () => [
      toDecimal(value.value, 1),
      toDecimal(extractValue(evaluate(value.value, 'degF', 'Cel')), precision),
    ])
    .with('Cel', () => [
      toDecimal(extractValue(evaluate(value.value, 'Cel', 'degF')), precision),
      toDecimal(value.value, 1),
    ])
    .otherwise(() => {
      throw new Error('unexpected unit')
    })

const displayVitalPercentile = (vital: {
  percentile?: Observation['percentile']
}) => {
  if (isNil(vital) || isNil(vital.percentile)) {
    return ''
  }
  return `(${vital.percentile === '0' ? '0' : toDecimal(vital.percentile)}%)`
}

const vitalRenderer: {
  [key in
    | 'height'
    | 'weight'
    | 'bmi'
    | 'bodyTemperature'
    | 'bloodPressure']: (vital: {
    value?: Observation['value']
    percentile?: Observation['percentile']
  }) => JSX.Element
} = {
  height: (height) => {
    if (!height?.value) return
    return (
      <>
        {getImperialDisplay(height.value.value, height.value.unit, 1, false)}
        {' / '}
        {getMetricDisplay(
          height.value.value,
          height.value.unit,
          height.value.display,
          1
        )}{' '}
        {displayVitalPercentile(height)}
      </>
    )
  },
  weight: (weight) => {
    if (!weight?.value) return
    return (
      <>
        {getImperialDisplay(weight.value.value, weight.value.unit, 1, true)}
        {' / '}
        {getMetricDisplay(
          weight.value.value,
          weight.value.unit,
          weight.value.display,
          1
        )}{' '}
        {displayVitalPercentile(weight)}
      </>
    )
  },
  bmi: (bmi) => {
    if (!bmi?.value) return
    return (
      <>
        {toDecimal(bmi.value.value)} kg/m
        <sup>2</sup> {displayVitalPercentile(bmi)}
      </>
    )
  },
  bodyTemperature: (bodyTemperature) => {
    if (!bodyTemperature) {
      return
    }
    if (!bodyTemperature.value) {
      return
    }
    if (!['degF', 'Cel'].includes(bodyTemperature.value.unit)) {
      return
    }
    const [degF, degC] = getTemperatureInFahrenheitAndCelsius(
      bodyTemperature.value
    )

    return (
      <>
        {degF} {unitDisplay['degF']} / {degC} {unitDisplay['Cel']}
      </>
    )
  },
  bloodPressure: (bloodPressure) => {
    if (!bloodPressure?.value?.display) return

    const [systolic, diastolic] = bloodPressure.value.display.trim().split('/')

    return (
      <>
        {systolic} / {diastolic} {unitDisplay['mm[Hg]']}
      </>
    )
  },
}

const generateVitalsRow = (
  patient: GetPatient['patient']
): {
  key: 'height' | 'weight' | 'bmi' | 'bodyTemperature' | 'bloodPressure'
  value: JSX.Element
  updatedAt: string
}[] => {
  const {
    mostRecentHeightVital,
    mostRecentWeightVital,
    mostRecentBmiVital,
    mostRecentBodyTemperatureVital,
    mostRecentBloodPressureVital,
    birthDate,
  } = patient

  const patientAge = calculateAge(birthDate)

  return [
    { key: 'height' as const, vital: mostRecentHeightVital },
    { key: 'weight' as const, vital: mostRecentWeightVital },
    { key: 'bmi' as const, vital: mostRecentBmiVital, minimumAgeYears: 2 },
    { key: 'bodyTemperature' as const, vital: mostRecentBodyTemperatureVital },
    { key: 'bloodPressure' as const, vital: mostRecentBloodPressureVital },
  ]
    .filter(
      ({ vital, minimumAgeYears }) =>
        !isNil(vital) &&
        (!minimumAgeYears || patientAge.years >= minimumAgeYears)
    )
    .map(({ key, vital }) => {
      return {
        key,
        value: vitalRenderer[key](vital),
        updatedAt: vital.updatedAt,
      }
    })
}

const renderPatientVitals = (patient) => {
  const vitalsRow = generateVitalsRow(patient)

  if (!vitalsRow.length) {
    return (
      <Typography className="truncate" textStyle="interface-default-xs">
        No vitals recorded
      </Typography>
    )
  }
  return vitalsRow.map(({ key, value, updatedAt }, i) => (
    <>
      {i !== 0 ? <BulletSeparator /> : null}
      <Tooltip
        key={key}
        text={`Updated on ${format(
          parseISO(updatedAt),
          "MMM d, yyyy 'at' h:mm a"
        )}`}
      >
        {(props) => (
          <Typography
            className="truncate"
            textStyle="interface-default-xs"
            {...props}
          >
            {value}
          </Typography>
        )}
      </Tooltip>
    </>
  ))
}

const PatientInfo: React.FC<PatientProps> = ({ patient }: PatientProps) => {
  const contactInformation =
    patient.contactInformation || patient.primaryGuardian.contactInformation

  return (
    <StackView className="lg:w-8/12" fullWidth={false}>
      <PatientInfoBox
        heading={patient.preferredPronouns ? 'Pronouns:' : 'Address:'}
      >
        <PreferredPronounsRenderer patient={patient} />
        <AddressRenderer
          contactInformation={contactInformation}
          showHeading={!!patient.preferredPronouns}
        />
        <PhoneRenderer contactInformation={contactInformation} />
      </PatientInfoBox>
      <Divider />
      <PatientInfoBox heading="Vitals:">
        {renderPatientVitals(patient)}
      </PatientInfoBox>
    </StackView>
  )
}

const PatientInfoBox: React.FC<
  React.PropsWithChildren<PatientInfoBoxProps>
> = ({ heading, children }) => {
  return (
    <StackView
      direction="row"
      alignItems="center"
      space={50}
      className="h-core-space-200 py-core-space-50"
    >
      <Typography
        textStyle="interface-strong-xs"
        className="w-core-space-400 text-base-color-fg-muted"
      >
        {heading}
      </Typography>
      {children}
    </StackView>
  )
}

const Visits: React.FC<PatientProps> = ({ patient }: PatientProps) => {
  return (
    <StackView className="hidden w-4/12 lg:block" fullWidth={false}>
      <VisitBox
        label="Next:"
        appointment={patient.nextAppointment}
        wellChildAppointment={patient.nextWellChildAppointment}
        lastOrNext="next"
      />

      <Divider />

      <VisitBox
        label="Last:"
        appointment={patient.mostRecentAppointment}
        wellChildAppointment={patient.mostRecentWellChildAppointment}
        lastOrNext="last"
      />
    </StackView>
  )
}

const VisitBoxAppointmentRenderer = ({
  start,
  type,
}: {
  start: Date
  type: AppointmentType
}) => {
  return (
    <StackView direction="row" space={25} fullWidth={false} alignItems="center">
      <Typography
        className="whitespace-nowrap"
        textStyle="interface-default-xs"
      >
        {format(start, 'M/d/yyyy')}
      </Typography>
      <Typography
        className="whitespace-nowrap"
        textStyle="interface-default-xs"
        color="text-base-color-fg-subtle"
      >
        {type === 'WELL_CHILD' ? 'WC' : appointmentTypeDisplay[type]}
      </Typography>
    </StackView>
  )
}

const VisitBox: React.FC<VisitBoxProps> = ({
  label,
  appointment,
  wellChildAppointment,
  lastOrNext,
}: VisitBoxProps) => {
  const location = useLocation()
  const params = useParams()

  return (
    <StackView
      direction="row"
      alignItems="center"
      justifyContent="end"
      className="h-core-space-200 py-core-space-50"
    >
      <Typography
        className="w-core-space-300"
        textStyle="interface-strong-xs"
        color="text-base-color-fg-muted"
      >
        {label}
      </Typography>

      <Box className="w-11/12">
        <StackView
          direction="row"
          alignItems="center"
          space={50}
          fullWidth={false}
        >
          {wellChildAppointment ? (
            <>
              {wellChildAppointment.visitNoteFhirId ? (
                <Link
                  to={sidepanelRoute(
                    {
                      route: `/documents/patient-document/${wellChildAppointment.visitNoteFhirId}`,
                      width: 'medium',
                      overlay: true,
                      disableEdit: true,
                    },
                    location,
                    params
                  )}
                >
                  <VisitBoxAppointmentRenderer
                    start={new Date(wellChildAppointment.start)}
                    type="WELL_CHILD"
                  />
                </Link>
              ) : (
                <VisitBoxAppointmentRenderer
                  start={new Date(wellChildAppointment.start)}
                  type="WELL_CHILD"
                />
              )}
            </>
          ) : (
            <StackView
              direction="row"
              space={25}
              fullWidth={false}
              alignItems="center"
            >
              <Button
                buttonStyle="ghost"
                buttonSize="s"
                icon={ExclamationCircleIcon}
                iconColor="text-base-color-fg-attention"
                tooltip={
                  lastOrNext === 'last'
                    ? 'No well child visit completed at practice.'
                    : 'Next well child visit not yet scheduled.'
                }
                tooltipPadding={25}
              />
              <Typography
                className="whitespace-nowrap"
                textStyle="interface-default-xs"
                color="text-base-color-fg-attention"
              >
                TBD
              </Typography>
              <Typography
                className="whitespace-nowrap"
                textStyle="interface-default-xs"
                color="text-base-color-fg-attention"
              >
                WC
              </Typography>
            </StackView>
          )}

          {appointment &&
            appointment?.appointmentDefinitions[0].type !== 'WELL_CHILD' &&
            (wellChildAppointment || lastOrNext === 'last') && (
              <Typography>&bull;</Typography>
            )}

          {appointment &&
            appointment.appointmentDefinitions[0].type !== 'WELL_CHILD' && (
              <>
                {appointment.visitNoteFhirId ? (
                  <Link
                    to={sidepanelRoute(
                      {
                        route: `/documents/patient-document/${appointment.visitNoteFhirId}`,
                        width: 'medium',
                        overlay: true,
                        disableEdit: true,
                      },
                      location,
                      params
                    )}
                  >
                    <VisitBoxAppointmentRenderer
                      start={new Date(appointment.start)}
                      type={appointment.appointmentDefinitions[0].type}
                    />
                  </Link>
                ) : (
                  <VisitBoxAppointmentRenderer
                    start={new Date(appointment.start)}
                    type={appointment.appointmentDefinitions[0].type}
                  />
                )}
              </>
            )}
        </StackView>
      </Box>
    </StackView>
  )
}

const PreferredPronounsRenderer: React.FC<PatientProps> = ({
  patient,
}: PatientProps) => {
  if (patient.preferredPronouns) {
    return (
      <Typography className="truncate" textStyle="interface-default-xs">
        {preferredPronounPatientHeaderDisplay[patient.preferredPronouns]}
      </Typography>
    )
  }
}

const AddressRenderer: React.FC<ContactInformationRendererProps> = ({
  contactInformation,
  showHeading,
}: ContactInformationRendererProps) => {
  if (contactInformation?.homeAddress) {
    return (
      <>
        {showHeading ? (
          <>
            <Separator />
            <Typography
              className="w-core-space-300 whitespace-nowrap"
              textStyle="interface-strong-xs"
              color="text-base-color-fg-muted"
            >
              Address:
            </Typography>
          </>
        ) : null}
        <Typography className="truncate" textStyle="interface-default-xs">
          {formatAddress(contactInformation.homeAddress)}
        </Typography>
      </>
    )
  } else {
    return (
      <Typography className="truncate" textStyle="interface-default-xs">
        No address to display
      </Typography>
    )
  }
}

const PhoneRenderer: React.FC<ContactInformationRendererProps> = ({
  contactInformation,
}: ContactInformationRendererProps) => {
  if (contactInformation?.mobileNumber) {
    return (
      <>
        <Separator />
        <Typography
          className="w-core-space-300 whitespace-nowrap"
          textStyle="interface-strong-xs"
          color="text-base-color-fg-muted"
        >
          Mobile:
        </Typography>
        <Typography className="truncate" textStyle="interface-default-xs">
          {formatPhoneNumber(contactInformation.mobileNumber)}
        </Typography>
      </>
    )
  } else {
    return null
  }
}

const AllergiesRenderer: React.FC<AllergyRendererProps> = ({ patientId }) => {
  const { allergies, hasNoKnownAllergies, loading } =
    useAllergiesQuery(patientId)

  const textStyle = 'interface-default-s'

  if (loading) {
    return (
      <Typography className="truncate" textStyle={textStyle}>
        Loading...
      </Typography>
    )
  }

  if (hasNoKnownAllergies) {
    return (
      <Typography
        className="truncate"
        color="text-base-color-fg-success"
        textStyle={textStyle}
      >
        No known allergies
      </Typography>
    )
  }

  if (allergies.length === 0) {
    return (
      <Typography
        className="truncate"
        color="text-base-color-fg-success"
        textStyle={textStyle}
      >
        No allergies recorded
      </Typography>
    )
  }

  const activeAllergies = allergies.filter((a) => a.status === 'active')
  const sortedAllergies = sortBy(activeAllergies, [
    (a) => ['severe', 'moderate', 'mild'].indexOf(a.severity),
  ])

  if (activeAllergies.length === 0) {
    return (
      <Typography
        className="truncate"
        color="text-base-color-fg-success"
        textStyle={textStyle}
      >
        No active allergies
      </Typography>
    )
  }

  const sortedAllergiesDisplay = sortedAllergies
    .map(({ allergen }) => allergen.name)
    .join(', ')

  return (
    <Tooltip text={sortedAllergiesDisplay}>
      <Box className="max-w-[144px] truncate">
        <Typography color="text-base-color-fg-danger">
          {sortedAllergiesDisplay}
        </Typography>
      </Box>
    </Tooltip>
  )
}

const InsuranceCoverageRenderer: React.FC<InsuranceCoverageRendererProps> = ({
  insuranceCoverages,
  patientId,
  insuranceOptOutStatus,
}: InsuranceCoverageRendererProps) => {
  const sortedInsuranceCoverage = sortInsuranceCoverages(insuranceCoverages)

  if (sortedInsuranceCoverage.length) {
    return (
      <>
        {sortedInsuranceCoverage.slice(0, 3).map((insuranceCoverage, index) => (
          <PatientInsuranceChip
            key={`insuranceCoverage.${index}`}
            insuranceCoverage={insuranceCoverage}
            patientId={patientId}
            showPlanName={index === 0}
          />
        ))}
      </>
    )
  } else {
    return (
      <PatientNoInsuranceChip
        insuranceOptOutStatus={insuranceOptOutStatus}
        patientId={patientId}
      />
    )
  }
}

const PatientHeader: React.FC = () => {
  const { patientHeaderSize, saveSettings } = useLocalSettings()
  const isDefaultPatientHeaderSize =
    patientHeaderSize === PATIENT_HEADER_SIZE.DEFAULT

  const params = useParams()
  const { id: patientId } = params

  const { data, loading, error } = useQuery<GetPatient>(GET_PATIENT, {
    variables: {
      id: patientId,
    },
  })

  const onHeaderToggleClicked = () =>
    saveSettings({
      patientHeaderSize: isDefaultPatientHeaderSize
        ? PATIENT_HEADER_SIZE.SMALL
        : PATIENT_HEADER_SIZE.DEFAULT,
    })

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <div className="text-danger">Error</div>
  }

  return (
    <div
      data-testid="patient-header"
      className={clsx([
        'border-b-core-border-width-10 border-gray-200 px-core-space-150',
        !isDefaultPatientHeaderSize ? 'py-core-space-100' : 'pt-core-space-100',
      ])}
    >
      <MetaTags
        title={formatDisplayName(data.patient)}
        description="Patient chart"
      />
      <TopHeader
        patient={data.patient}
        headerIsCollapsed={!isDefaultPatientHeaderSize}
        onHeaderToggleClicked={() => onHeaderToggleClicked()}
      />

      {isDefaultPatientHeaderSize && (
        <StackView direction="row" alignItems="center" space={150}>
          <PatientInfo patient={data.patient} />
          <Visits patient={data.patient} />
        </StackView>
      )}

      <PatientForCanidDataElement patientId={data.patient.id} />
    </div>
  )
}

export default PatientHeader
