import Box from 'src/components/atoms/Box/Box'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'

import { HospitalVisitBillingSection } from './HospitalVisitBillingSection'
import { HospitalVisitDiagnosisSection } from './HospitalVisitDiagnosisSection'
import { HospitalVisitDocumentationSection } from './HospitalVisitDocumentationSection'
import { HospitalVisitHeader } from './HospitalVisitHeader'

export const PatientHospitalVisit = () => {
  return (
    <StackView alignItems="center" className="overflow-y-hidden">
      <HospitalVisitHeader />
      <StackView className="overflow-y-auto" alignItems="center">
        <StackView
          className="w-core-size-web-breakpoint-m p-core-space-150"
          fullWidth={false}
        >
          <SectionHeader
            title="Hospital visit"
            description="Add documentation and complete billing for your hospital visit."
          />
          <Box className="pt-core-space-150" />
          <Divider />
          <Box className="pt-core-space-200" />

          <StackView gap={200}>
            <HospitalVisitDocumentationSection />
            <Divider />
            <HospitalVisitDiagnosisSection />
            <Divider />
            <HospitalVisitBillingSection />
          </StackView>
        </StackView>
      </StackView>
    </StackView>
  )
}
