import { NoSymbolIcon } from '@heroicons/react/24/outline'

import Button from 'src/components/atoms/Button/Button'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

import useCreatePatientInDoctorFirst from './useCreatePatientInDoctorFirst'

const PatientNotInDoctorFirst: React.FC<{
  patientId: string
  refetch?: () => void
}> = ({ patientId, refetch }) => {
  const [createPatientInDoctorFirst, { loading }] =
    useCreatePatientInDoctorFirst({ patientId, refetch })
  return (
    <StackView justifyContent="center" alignItems="center" className="h-64">
      <NoSymbolIcon className="h-8 w-8 text-gray-400" />
      <Space space={50} />
      <Typography textStyle="subtitle">Patient not in DoctorFirst</Typography>
      <Typography color="text-base-color-fg-muted" className="text-center">
        Press the button below to create the patient in DoctorFirst. If the
        problem persists please contact your administrator.
      </Typography>
      <Space space={100} />
      <Button onClick={() => createPatientInDoctorFirst()} disabled={loading}>
        Create patient in DoctorFirst
      </Button>
    </StackView>
  )
}

export default PatientNotInDoctorFirst
