import { useState } from 'react'

import {
  ChevronDownIcon,
  ChevronUpIcon,
  PencilIcon,
} from '@heroicons/react/24/solid'
import { claimQualifierDisplay } from 'common/data/claimQualifiers'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { useParams } from 'react-router-dom'
import { PlaceOfService } from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import ChevronLink from 'src/components/atoms/ChevronLink/ChevronLink'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import { placeOfServiceDisplay } from 'src/data/placeOfService'
import { AddressJoinOption, formatAddress } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { useVisit } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'

import BillingSectionHeader from './BillingSectionHeader'

const BILLING_SUMMARY_QUERY = gql`
  query BillingSummaryQueryV2(
    $renderingProviderId: String!
    $shouldNotRetrieveRenderingProvider: Boolean!
    $serviceFacilityId: String!
    $shouldNotRetrieveServiceFacility: Boolean!
    $billingProviderId: String!
    $shouldNotRetrieveBillingProvider: Boolean!
  ) {
    practitionerClinicalDetail(id: $renderingProviderId)
      @skip(if: $shouldNotRetrieveRenderingProvider) {
      id
      npi
      practitioner {
        id
        givenName
        familyName
      }
    }
    location(id: $serviceFacilityId)
      @skip(if: $shouldNotRetrieveServiceFacility) {
      id
      name
      address {
        id
        line1
        line2
        city
        state
        postalCode
      }
    }
    billingProvider(id: $billingProviderId)
      @skip(if: $shouldNotRetrieveBillingProvider) {
      id
      name
      npi
    }
  }
`

const SUPERVISING_PROVIDER_QUERY = gql`
  query SupervisingProviderDetails($supervisingProviderId: String!) {
    practitionerClinicalDetail(id: $supervisingProviderId) {
      id
      npi
      practitioner {
        id
        givenName
        familyName
      }
    }
  }
`

const EMPTY_PLACEHOLDER = '-'

export interface BillingSummaryFormProps {
  claim: {
    renderingProviderId: string
    supervisingProviderId?: string
    placeOfService: PlaceOfService
    serviceFacilityId: string
    billingProviderId: string
    admissionDate?: string
    dischargeDate?: string
    box14Date?: string
    box14Qualifier?: string
    box19Content?: string
    submittedAt?: string
  }
}

const EditToggleButton = ({
  setIsExpanded,
}: {
  setIsExpanded: (isExpanded: boolean) => void
}) => {
  const location = useLocation()
  const params = useParams()

  const { appointmentId } = useVisit()

  return (
    <Button
      testId="edit-toggle-button"
      icon={PencilIcon}
      buttonStyle="secondary"
      text="Edit"
      onClick={() => {
        navigate(
          sidepanelRoute(
            {
              route: `/encounters/${appointmentId}/claim/edit-details`,
            },
            location,
            params
          )
        )
        setIsExpanded(true)
      }}
    />
  )
}

const BillingSummarySectionV2 = ({
  claim,
  isEditable,
}: BillingSummaryFormProps & { isEditable: boolean }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <StackView space={100}>
      <BillingSectionHeader
        title="Summary"
        subtitle="Review and edit claim-level details. This section includes optional fields that can be completed where necessary."
      >
        {isEditable && <EditToggleButton setIsExpanded={setIsExpanded} />}
        <ChevronLink
          className="h-5 w-5 flex-shrink-0 text-gray-800"
          aria-hidden="true"
          icon={isExpanded ? ChevronUpIcon : ChevronDownIcon}
          onClick={() => setIsExpanded(!isExpanded)}
        />
      </BillingSectionHeader>
      {isExpanded ? <BillingSummaryDisplay {...claim} /> : null}
    </StackView>
  )
}

const BillingSummaryDisplay = ({
  renderingProviderId,
  supervisingProviderId,
  placeOfService,
  serviceFacilityId,
  billingProviderId,
  admissionDate,
  dischargeDate,
  box14Date,
  box14Qualifier,
  box19Content,
}: BillingSummaryFormProps['claim']) => {
  const { data, error, loading } = useQuery(BILLING_SUMMARY_QUERY, {
    variables: {
      renderingProviderId,
      serviceFacilityId,
      billingProviderId,
      shouldNotRetrieveRenderingProvider: !renderingProviderId,
      shouldNotRetrieveServiceFacility: !serviceFacilityId,
      shouldNotRetrieveBillingProvider: !billingProviderId,
    },
    skip: !renderingProviderId && !serviceFacilityId && !billingProviderId,
  })

  const supervisingProviderQuery = useQuery(SUPERVISING_PROVIDER_QUERY, {
    variables: {
      supervisingProviderId,
    },
    skip: !supervisingProviderId,
  })

  if (loading || (supervisingProviderId && supervisingProviderQuery.loading))
    return <div>Loading...</div>
  if (error) return <div>{error.message}</div>

  const supervisingProvider =
    supervisingProviderQuery.data?.practitionerClinicalDetail

  return (
    <Card className="p-4" testId="billing-summary-section">
      <DataDisplayList
        data={[
          {
            label: 'Rendering provider',
            value: data?.practitionerClinicalDetail?.practitioner
              ? `${formatDisplayName(
                  data.practitionerClinicalDetail.practitioner
                )}  ·  ${data.practitionerClinicalDetail.npi}`
              : EMPTY_PLACEHOLDER,
          },
          {
            label: 'Supervising provider',
            value: supervisingProvider
              ? `${formatDisplayName(supervisingProvider?.practitioner)}  ·  ${
                  supervisingProvider?.npi
                }`
              : EMPTY_PLACEHOLDER,
          },
          {
            label: 'Billing provider',
            value: data?.billingProvider
              ? `${data.billingProvider.name} (${data.billingProvider.npi})`
              : EMPTY_PLACEHOLDER,
          },
          {
            label: 'Service facility location',
            value: data?.location ? (
              <StackView>
                <Typography>{data?.location?.name}</Typography>
                <Typography>
                  {formatAddress(
                    data?.location?.address,
                    AddressJoinOption.COMMA
                  )}
                </Typography>
              </StackView>
            ) : (
              EMPTY_PLACEHOLDER
            ),
          },
          {
            label: 'Place of service',
            value: placeOfServiceDisplay[placeOfService] ?? EMPTY_PLACEHOLDER,
          },
          {
            label: 'Hospitalization dates',
            value:
              admissionDate && dischargeDate
                ? format(parseISO(admissionDate), 'MMM d, yyyy') +
                  ' to ' +
                  format(parseISO(dischargeDate), 'MMM d, yyyy')
                : EMPTY_PLACEHOLDER,
            hide: placeOfService !== 'INPATIENT_HOSPITAL',
          },
          {
            label: 'Box 14: Date of current illness, injury, or pregnancy',
            value:
              (box14Date && format(parseISO(box14Date), 'MMM d, yyyy')) ??
              EMPTY_PLACEHOLDER,
          },
          {
            label: 'Box 14 qualifier',
            value:
              (box14Qualifier && claimQualifierDisplay[box14Qualifier]) ??
              EMPTY_PLACEHOLDER,
          },
          {
            label: 'Box 19',
            value: box19Content ?? EMPTY_PLACEHOLDER,
          },
        ]}
      />
    </Card>
  )
}

export default BillingSummarySectionV2
