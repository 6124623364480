import { paymentLineItemTypes } from 'common/data/paymentLineItemTypes'

import {
  DropdownField,
  DropdownFieldProps,
} from 'src/components/atoms/Dropdown/Dropdown'
import { BillingEncounterType } from 'src/components/molecules/BillingEncounterSelectField/BillingEncounterSelectField'

export type PaymentLineItemSelectFieldProps = DropdownFieldProps & {
  encounterType: BillingEncounterType
}

export const PaymentLineItemSelectField = ({
  encounterType,
  ...rest
}: PaymentLineItemSelectFieldProps) => {
  const appointmentLineItemTypes = paymentLineItemTypes.filter(
    (type) =>
      !['PATIENT_BILL', 'PAYMENT_PLAN', 'OUTSTANDING_BALANCE'].includes(
        type.value
      )
  )

  const directPatientChargeLineItemTypes = paymentLineItemTypes.filter(
    (type) => type.value === 'PATIENT_BILL'
  )

  return (
    <DropdownField
      {...rest}
      options={[
        ...(encounterType === 'APPOINTMENT_ENCOUNTER' ||
        encounterType === 'UNSUBMITTED_CLAIM'
          ? appointmentLineItemTypes
          : encounterType === 'DIRECT_PATIENT_CHARGE_ENCOUNTER'
            ? directPatientChargeLineItemTypes
            : paymentLineItemTypes),
      ]}
    />
  )
}

export default PaymentLineItemSelectField
