export const TENANT_LETTERHEAD_FRAGMENT = gql`
  fragment TenantLetterheadFragment on Tenant {
    id
    name
    websiteUrl
    portalUrl
    portalQRCodeDataUrl(darkColor: "#4947d0", lightColor: "#f2f4f7")
    featureFlags
    locations {
      id
      name
      description
      phoneNumber
      isExternalToPractice
      faxNumber {
        id
        number
      }
      address {
        id
        line1
        line2
        city
        postalCode
        state
      }
    }
  }
`

export const BILLING_STATEMENT_TENANT_LETTERHEAD_FRAGMENT = gql`
  fragment BillingStatementTenantLetterheadFragment on BillingStatementTenant {
    id
    name
    websiteUrl
    portalUrl
    portalQRCodeDataUrl(darkColor: "#4947d0", lightColor: "#f2f4f7")
    locations {
      id
      name
      description
      phoneNumber
      isExternalToPractice
      faxNumber {
        id
        number
      }
      address {
        id
        line1
        line2
        city
        postalCode
        state
      }
    }
  }
`

const PATIENT_DETAILS_INSURANCE_COVERAGE_FRAGMENT = gql`
  fragment PatientDetailsInsuranceCoverageFragment on InsuranceCoverage {
    id
    beneficiaryIsSubscriber
    payer {
      id
      displayName
    }
    planMemberIdentifier
    planName
    planType
    subscriberId
  }
`

export const PATIENT_DETAILS_FRAGMENT = gql`
  fragment PatientDetailsFragment on Patient {
    id
    birthDate
    namePrefix
    nameSuffix
    givenName
    middleName
    familyName
    sexAtBirth
    contactInformation {
      id
      homeAddress {
        id
        line1
        line2
        city
        postalCode
        state
        country
      }
    }
    patientRelatedPersonRelationships {
      id
      guardianshipType
      relationshipType
      doesResideWith
      relatedPerson {
        id
        birthDate
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
        contactInformation {
          id
          emailAddress
          mobileNumber
          homeAddress {
            id
            line1
            line2
            city
            postalCode
            state
            country
          }
        }
      }
    }
    primaryActiveInsuranceCoverage {
      ...PatientDetailsInsuranceCoverageFragment
    }
    secondaryActiveInsuranceCoverage {
      ...PatientDetailsInsuranceCoverageFragment
    }
  }
  ${PATIENT_DETAILS_INSURANCE_COVERAGE_FRAGMENT}
`

export const PATIENT_GUARDIAN_DETAILS_FRAGMENT = gql`
  fragment PatientGuardianDetailsFragment on Patient {
    id
    birthDate
    namePrefix
    nameSuffix
    givenName
    middleName
    familyName
    sexAtBirth
    contactInformation {
      id
      emailAddress
      primaryLanguage
      mobileNumber
      homeNumber
      homeAddress {
        id
        line1
        line2
        city
        postalCode
        state
      }
    }
    primaryGuardian {
      id
      contactInformation {
        id
        primaryLanguage
        homeAddress {
          id
          line1
          line2
          city
          postalCode
          state
        }
      }
    }
    patientRelatedPersonRelationships {
      id
      guardianshipType
      relationshipType
      relatedPerson {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
        contactInformation {
          id
          emailAddress
          mobileNumber
          homeNumber
        }
      }
    }
    insuranceCoverages {
      id
      status
      planName
      coordinationOfBenefitsType
      planMemberIdentifier
      payer {
        id
        displayName
      }
    }
  }
`

export const SIGNING_PRACTITIONER_FRAGMENT = gql`
  fragment SigningPractitionerFragment on Practitioner {
    id
    namePrefix
    nameSuffix
    givenName
    middleName
    familyName
    practitionerClinicalDetail {
      npi
    }
    user {
      id
      credentialsLabel
    }
  }
`
