import { Fragment } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { CalendarIcon } from '@heroicons/react/24/solid'
import { MbscDatepickerOptions } from '@mobiscroll/react'
import clsx from 'clsx'
import { format } from 'date-fns'

import DatePicker from 'src/components/molecules/DatePicker/DatePicker'

import Box from '../Box/Box'
import StackView from '../StackView/StackView'
import Typography from '../Typography/Typography'

export default function DatePickerFilter({
  onChange,
  className,
  disabled,
  error,
  value,
  ...rest
}: MbscDatepickerOptions) {
  const errorClasses = [
    'border-red-300 focus:border-danger',
    'focus:outline-none focus:ring-danger',
    'text-red-900 placeholder-red-300',
  ]
  const classes = [
    'block w-full justify-center rounded-md border py-2 pl-3 pr-4 text-base shadow-sm',
    'h-base-size-selectable-m',
    'text-gray-900',
    'rounded-md shadow-sm border-gray-300',
    'focus:border-primary focus:ring-primary',
    disabled && 'bg-gray-50',
    error && errorClasses,
  ]

  return (
    <Menu
      onSubmit={(e) => e.preventDefault()}
      as="div"
      data-testid="date-picker-filter"
      className={clsx('relative inline-block text-left')}
    >
      <Menu.Button
        className={clsx(...classes, className)}
        disabled={!!disabled}
        type="button"
      >
        <StackView direction="row" alignItems="center">
          <Box className="flex items-start justify-center self-center pr-3 leading-5">
            <CalendarIcon className="h-4 w-4 text-gray-400" />
          </Box>
          <Typography fontWeight="normal" noWrap>
            {value ? format(new Date(value), 'MMM dd, yyyy') : 'Select Date'}
          </Typography>
        </StackView>
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className="absolute right-0 mt-2 w-72 origin-top-right divide-y divide-gray-100 overflow-auto rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          style={{
            zIndex: 99,
          }}
        >
          <Box rounded className="front-desk-calendar-picker">
            <DatePicker
              id="date-picker-filter"
              display="inline"
              value={value}
              className="rounded-md shadow"
              onChange={onChange}
              theme="material"
              themeVariant="light"
              touchUi={true}
              {...rest}
            />
          </Box>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
