import { useRef, useEffect } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import sortBy from 'lodash/sortBy'
import { FindPastEncountersQuery } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Table from 'src/components/molecules/Table/Table'
import { CANCELLED_APPOINTMENT_STATUSES } from 'src/data/appointmentStatus'
import { sidepanelRoute } from 'src/lib/routes'

export const QUERY = gql`
  query FindPastEncountersQuery($filters: AppointmentFiltersInput) {
    appointments(filters: $filters) {
      id
      start
      end
      chiefComplaints
      appointmentDefinitions {
        id
        type
        name
      }
      practitioner {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
      }
      encounter {
        id
        primaryDiagnosis {
          id
          code {
            code
            display
            system
          }
        }
        additionalDiagnoses {
          id
          code {
            code
            display
            system
          }
        }
      }
      visitNoteFhirId
      location {
        id
        timezone
      }
      patient {
        id
      }
      claim {
        id
      }
    }
  }
`

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  cellClass: 'cursor-pointer',
  resizable: true,
  autoHeight: true,
  wrapText: true,
  minWidth: 180,
}

const PastEncounterTable = ({
  patientId,
  columnDefinitions,
}: {
  patientId: string
  columnDefinitions: ColDef[]
}) => {
  const location = useLocation()
  const params = useParams()
  const gridRef = useRef<AgGridReact>()
  const { data, loading } = useQuery<FindPastEncountersQuery>(QUERY, {
    variables: {
      filters: {
        patientId,
        excludeStatuses: CANCELLED_APPOINTMENT_STATUSES,
        includeChartingStatuses: ['COMPLETE'],
      },
    },
  })

  useEffect(() => {
    if (loading) {
      gridRef.current?.api?.showLoadingOverlay()
    } else if (data?.appointments?.length == 0) {
      gridRef.current?.api?.showNoRowsOverlay()
    } else {
      gridRef.current?.api?.hideOverlay()
    }
  }, [loading, data])

  const onGridReady = () => {
    if (loading) {
      gridRef.current?.api?.showLoadingOverlay()
    }
  }
  const rowData = data
    ? sortBy(
        data.appointments,
        (appointment) => new Date(appointment.start)
      ).reverse()
    : []

  return (
    <Table
      testId="past-encounters-table"
      innerRef={gridRef}
      rowData={rowData}
      domLayout="autoHeight"
      rowHeight={72}
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={columnDefinitions}
      animateRows={true}
      onGridReady={onGridReady}
      onRowClicked={(e) => {
        if (e.data.visitNoteFhirId) {
          navigate(
            sidepanelRoute(
              {
                route: `/documents/patient-document/${e.data.visitNoteFhirId}`,
                width: 'medium',
                overlay: true,
                disableEdit: true,
              },
              location,
              params
            )
          )
        } else {
          navigate(
            sidepanelRoute(
              { route: `/appointments/${e.data.id}/visit` },
              location,
              params
            )
          )
        }
      }}
    />
  )
}

export default PastEncounterTable
