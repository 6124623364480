import {
  ArrowTopRightOnSquareIcon,
  CalendarDaysIcon,
} from '@heroicons/react/24/solid'
import { format } from 'date-fns'

import { routes, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import {
  HealthPlanColumn,
  VisitActionColumn,
  EncounterDateAndTimeColumn,
  EncounterPrimaryDiagnosisColumn,
  EncounterAdditionalDiagnosisColumn,
  EncounterVisitTypeAndChiefComplaintColumn,
  EncounterPractitionerColumn,
  VisitDateAndTimeColumn,
  VisitLocationColumn,
  VisitCommentColumn,
  VisitPractitionerColumn,
  VisitStatusColumn,
  VisitTypeAndChiefComplaintColumn,
  RoomNumberColumn,
  EncounterActionColumn,
} from 'src/components/organisms/ScheduleTable/config'
import ScheduleTable from 'src/components/organisms/ScheduleTable/ScheduleTable'
import { StartVisitWarningProvider } from 'src/components/StartVisitWarningModals/StartVisitWarningModals'
import { CANCELLED_APPOINTMENT_STATUSES } from 'src/data/appointmentStatus'
import { openNewTab } from 'src/utils'

import { HospitalVisitsTable } from './HospitalVisitsTable'
import { NewHospitalVisitButton } from './NewHospitalVisitButton'
import PastEncounterTable from './PastEncounterTable'

const PatientVisits = () => {
  const params = useParams()
  const { id: patientId } = params

  return (
    <StartVisitWarningProvider>
      <StackView space={100} className="px-6 pb-8 pt-4">
        <Space />
        <StackView space={50}>
          <StackView
            direction="row"
            justifyContent="between"
            alignItems="center"
          >
            <Typography textStyle="title" fontWeight="semibold">
              Upcoming/current
            </Typography>

            <StackView gap={25} fullWidth={false} direction="row">
              <NewHospitalVisitButton patientId={patientId} />

              <Button
                buttonStyle="secondary"
                text="New visit"
                icon={CalendarDaysIcon}
                onClick={() =>
                  openNewTab(
                    routes.schedule({
                      scheduleDateFilter: format(new Date(), 'yyyy-MM-dd'),
                      scheduleViewType: 'CALENDAR',
                      sidepanelContext: JSON.stringify({
                        route: `/appointments/new`,
                        patientId,
                      }),
                    })
                  )
                }
              />
            </StackView>
          </StackView>
          <ScheduleTable
            testId="upcoming-visits-schedule-table"
            domLayout="autoHeight"
            filters={{
              patientId,
              excludeStatuses: CANCELLED_APPOINTMENT_STATUSES,
              excludeChartingStatuses: ['COMPLETE'],
            }}
            columnDefinitions={[
              VisitDateAndTimeColumn,
              VisitLocationColumn,
              VisitStatusColumn,
              VisitTypeAndChiefComplaintColumn,
              VisitPractitionerColumn,
              HealthPlanColumn,
              VisitCommentColumn,
              RoomNumberColumn,
              VisitActionColumn,
            ]}
          />
        </StackView>
        <StackView space={50}>
          <StackView
            direction="row"
            justifyContent="between"
            alignItems="center"
          >
            <Typography textStyle="title" fontWeight="semibold">
              Past complete
            </Typography>
            <Button
              buttonStyle="link"
              text="Docs"
              icon={ArrowTopRightOnSquareIcon}
              onClick={() =>
                openNewTab(
                  routes.patientChartsGlob({
                    id: patientId,
                    glob: 'documents',
                  })
                )
              }
            />
          </StackView>
          <PastEncounterTable
            patientId={patientId}
            columnDefinitions={[
              EncounterDateAndTimeColumn,
              EncounterPractitionerColumn,
              EncounterVisitTypeAndChiefComplaintColumn,
              EncounterPrimaryDiagnosisColumn,
              EncounterAdditionalDiagnosisColumn,
              EncounterActionColumn,
            ]}
          />
        </StackView>
        <StackView space={50}>
          <Typography textStyle="title" fontWeight="semibold">
            Cancelled/rescheduled
          </Typography>
          <ScheduleTable
            testId="cancelled-visits-schedule-table"
            domLayout="autoHeight"
            filters={{
              patientId,
              includeStatuses: CANCELLED_APPOINTMENT_STATUSES,
            }}
            columnDefinitions={[
              VisitDateAndTimeColumn,
              VisitLocationColumn,
              VisitStatusColumn,
              VisitTypeAndChiefComplaintColumn,
              VisitPractitionerColumn,
              HealthPlanColumn,
              VisitCommentColumn,
            ]}
          />
        </StackView>
        <HospitalVisitsTable patientId={patientId} />
      </StackView>
    </StartVisitWarningProvider>
  )
}

export default PatientVisits
