import { useRef } from 'react'

import {
  ArchiveBoxXMarkIcon,
  ArrowTopRightOnSquareIcon,
  ArrowUturnLeftIcon,
  BellAlertIcon,
  ChartBarIcon,
  CheckIcon,
  PencilIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/solid'
import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { assertUnreachable, formatDisplayName } from 'common/utils'
import { format, formatDistance, parseISO } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { OrderWithObservationsFragment } from 'types/graphql'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Badge from 'src/components/atoms/Badge'
import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button/Button'
import Link from 'src/components/atoms/Link/Link'
import StackView from 'src/components/atoms/StackView'
import { Circle } from 'src/components/molecules/MultiUserBadge'
import Table from 'src/components/molecules/Table'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { useSignOrders } from 'src/hooks/useSignOrders/useSignOrders'
import { EmrCurrentUser } from 'src/lib/auth'
import { sidepanelRoute } from 'src/lib/routes'
import { getOrderResultsRoute } from 'src/pages/PatientChartsPage/PatientVisits/pages/IntakePage/ScreeningsTable'

import OrdersTableOrderDetails from './OrdersTableOrderDetails'
import {
  useCompleteOrderMutation,
  useRevokeOrderMutation,
  useUpdateOrderMutation,
} from './usePatientOrders'

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'cursor-pointer',
  resizable: false,
}

const canSignOrder = ({
  currentUser,
  order,
}: {
  currentUser: EmrCurrentUser
  order: OrderWithObservationsFragment
}): boolean => {
  if (
    orderCategoryConfig[order.category].canBeSignedByNonPractitioner({
      featureFlags: currentUser.featureFlags,
    })
  )
    return true

  return currentUser.roles.includes('PRACTITIONER')
}

const SignOrderAction = ({
  order,
  encounterId,
}: {
  order: OrderWithObservationsFragment
  encounterId?: string
}) => {
  const [signOrders] = useSignOrders([order.id], encounterId)
  const [updateOrder] = useUpdateOrderMutation()
  const { currentUser } = useEmrAuth()

  const canSign = canSignOrder({
    currentUser,
    order,
  })

  return (
    <Button
      testId="sign-order-action"
      buttonStyle={canSign ? undefined : 'secondary'}
      icon={canSign ? PencilSquareIcon : PencilIcon}
      floatingTooltip="Sign"
      tooltipPosition="top"
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()

          if (currentUser.featureFlags.includes('SIGN_ORDERS_V2')) {
            signOrders()
          } else {
            void updateOrder({
              variables: {
                input: { orderId: order.id, intent: 'ORDER', encounterId },
              },
            })
          }
        }
      }}
    />
  )
}

const CompleteOrderAction = ({
  order,
  encounterId,
}: {
  order: OrderWithObservationsFragment
  encounterId?: string
}) => {
  const [completeOrder] = useCompleteOrderMutation()
  const [updateOrder] = useUpdateOrderMutation()
  const { currentUser } = useEmrAuth()
  const onClick = currentUser.featureFlags.includes(
    'REVOKE_OR_COMPLETE_HEALTH_GORILLA_ORDER'
  )
    ? () => {
        void completeOrder({
          variables: {
            orderId: order.id,
            encounterId,
          },
        })
      }
    : () => {
        void updateOrder({
          variables: {
            input: { orderId: order.id, status: 'COMPLETED', encounterId },
          },
        })
      }

  return (
    <Button
      testId="complete-order-action"
      buttonStyle="secondary"
      icon={CheckIcon}
      floatingTooltip="Complete"
      tooltipPosition="top"
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          void onClick()
        }
      }}
    />
  )
}

const CancelOrderAction = ({
  order,
  encounterId,
}: {
  order: OrderWithObservationsFragment
  encounterId?: string
}) => {
  const [revokeOrder] = useRevokeOrderMutation()
  const [updateOrder] = useUpdateOrderMutation()
  const { currentUser } = useEmrAuth()
  const onClick = currentUser.featureFlags.includes(
    'REVOKE_OR_COMPLETE_HEALTH_GORILLA_ORDER'
  )
    ? () => {
        void revokeOrder({
          variables: {
            orderId: order.id,
            encounterId,
          },
        })
      }
    : () => {
        void updateOrder({
          variables: {
            input: { orderId: order.id, status: 'REVOKED', encounterId },
          },
        })
      }

  return (
    <Button
      testId="cancel-order-action"
      buttonStyle="secondary"
      icon={ArchiveBoxXMarkIcon}
      floatingTooltip="Cancel"
      tooltipPosition="top"
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          void onClick()
        }
      }}
    />
  )
}

const RevokeOrderAction = ({
  order,
  encounterId,
}: {
  order: OrderWithObservationsFragment
  encounterId?: string
}) => {
  const [revokeOrder] = useRevokeOrderMutation()
  const [updateOrder] = useUpdateOrderMutation()
  const { currentUser } = useEmrAuth()
  const onClick = currentUser.featureFlags.includes(
    'REVOKE_OR_COMPLETE_HEALTH_GORILLA_ORDER'
  )
    ? () => {
        void revokeOrder({
          variables: {
            orderId: order.id,
            encounterId,
          },
        })
      }
    : () => {
        void updateOrder({
          variables: {
            input: { orderId: order.id, status: 'REVOKED', encounterId },
          },
        })
      }

  return (
    <Button
      testId="revoke-order-action"
      buttonStyle="secondary"
      icon={ArrowUturnLeftIcon}
      floatingTooltip="Revoke"
      tooltipPosition="top"
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          void onClick()
        }
      }}
    />
  )
}

const EditOrderAction = ({
  order,
  encounterId,
}: {
  order: OrderWithObservationsFragment
  encounterId?: string
}) => {
  const params = useParams()
  const location = useLocation()
  const { id: patientId } = params

  const { currentUser } = useEmrAuth()

  const canSign = canSignOrder({
    currentUser,
    order,
  })
  return (
    <Button
      testId="edit-order-action"
      buttonStyle={canSign ? 'secondary' : undefined}
      icon={PencilIcon}
      floatingTooltip="Edit"
      tooltipPosition="top"
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          navigate(
            sidepanelRoute(
              {
                route: encounterId
                  ? `/encounters/${encounterId}/orders/${order.id}/edit`
                  : `/patients/${patientId}/orders/${order.id}/edit`,
              },
              location,
              params
            )
          )
        }
      }}
    />
  )
}

const ViewOrderResultsAction = ({
  order,
  encounterId,
}: {
  order: OrderWithObservationsFragment
  encounterId?: string
}) => {
  const params = useParams()
  const location = useLocation()
  return (
    <Button
      testId="view-order-results-action"
      buttonStyle="secondary"
      icon={ChartBarIcon}
      floatingTooltip="Results"
      tooltipPosition="top"
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          navigate(
            sidepanelRoute(
              {
                route: getOrderResultsRoute({
                  order,
                  encounterId,
                }),
              },
              location,
              params
            )
          )
        }
      }}
    />
  )
}

const OrderActions = ({
  order,
  encounterId,
}: {
  order: OrderWithObservationsFragment
  encounterId?: string
}) => {
  const { currentUser } = useEmrAuth()

  const orderAllowsResults =
    order.category === 'SCN' || order.category === 'IHT'

  const orderHasResults =
    !isEmpty(order.observations) ||
    !isEmpty(order.questionnaireResults) ||
    !isEmpty(order.resultsForQuestionnaires) ||
    !isEmpty(order.diagnosticReport)

  if (currentUser.roles.includes('PRACTITIONER')) {
    switch (order.intent) {
      case 'PROPOSAL':
        return order.status === 'ACTIVE' ? (
          <>
            {orderHasResults && (
              <ViewOrderResultsAction order={order} encounterId={encounterId} />
            )}
            <EditOrderAction order={order} encounterId={encounterId} />
            <CancelOrderAction order={order} encounterId={encounterId} />
            <SignOrderAction order={order} encounterId={encounterId} />
          </>
        ) : (
          <>
            {orderHasResults && (
              <ViewOrderResultsAction order={order} encounterId={encounterId} />
            )}
            <SignOrderAction order={order} encounterId={encounterId} />
          </>
        )
      case 'ORDER':
        return order.status === 'ACTIVE' &&
          (order.category !== 'HG_LAB' ||
            currentUser.featureFlags.includes(
              'REVOKE_OR_COMPLETE_HEALTH_GORILLA_ORDER'
            )) ? (
          <>
            {(orderHasResults || orderAllowsResults) && (
              <ViewOrderResultsAction order={order} encounterId={encounterId} />
            )}
            <RevokeOrderAction order={order} encounterId={encounterId} />
            {!['VAX', 'SCH'].includes(order.category) && (
              <CompleteOrderAction order={order} encounterId={encounterId} />
            )}
            {order.category === 'SCH' && (
              <Link
                to={routes.cases({
                  glob: `schedule/${
                    order.schedulingCase.id
                  }/details?scheduleLocationFilter=${
                    order.schedulingCase.locationId
                  }&schedulePractitionerFilter=${
                    order.schedulingCase.visitPractitionerId
                  }&scheduleDateFilter=${format(
                    parseISO(order.schedulingCase.firstAvailableTargetDate),
                    'yyyy-MM-dd'
                  )}`,
                })}
                text="Task"
                icon={ArrowTopRightOnSquareIcon}
                testId="order-scheduling-case-link"
              />
            )}
          </>
        ) : (
          <>
            {orderHasResults && (
              <ViewOrderResultsAction order={order} encounterId={encounterId} />
            )}
          </>
        )
      default:
        assertUnreachable(order.intent)
    }
  } else {
    if (order.intent === 'PROPOSAL' && order.status === 'ACTIVE') {
      return (
        <>
          {orderHasResults && (
            <ViewOrderResultsAction order={order} encounterId={encounterId} />
          )}
          {orderCategoryConfig[order.category].canBeSignedByNonPractitioner({
            featureFlags: currentUser.featureFlags,
          }) ? (
            <>
              <EditOrderAction order={order} encounterId={encounterId} />
              <CancelOrderAction order={order} encounterId={encounterId} />
              <SignOrderAction order={order} encounterId={encounterId} />
            </>
          ) : (
            <>
              <CancelOrderAction order={order} encounterId={encounterId} />
              <EditOrderAction order={order} encounterId={encounterId} />
            </>
          )}
        </>
      )
    } else if (order.status === 'ACTIVE') {
      return (
        orderAllowsResults && (
          <ViewOrderResultsAction order={order} encounterId={encounterId} />
        )
      )
    } else {
      return (
        orderHasResults && (
          <ViewOrderResultsAction order={order} encounterId={encounterId} />
        )
      )
    }
  }
}

export const RequesterDetails = ({
  order,
}: {
  order: OrderWithObservationsFragment
}) => {
  const minutesSinceCreated = formatDistance(
    new Date(),
    new Date(order.createdAt)
  )

  return (
    <StackView alignItems="end" space={25} fullWidth={false}>
      <Badge className="pr-0.5" color="light-gray">
        <StackView direction="row" space={50} alignItems="center">
          <Box>{formatDisplayName(order.requester)}</Box>
          <Box>
            <Circle
              color="bg-core-color-teal-50"
              text={`${order.requester.givenName.charAt(
                0
              )}${order.requester.familyName.charAt(0)}`}
            />
          </Box>
        </StackView>
      </Badge>
      <Badge
        color="dark-gray"
        icon={BellAlertIcon}
        text={`${minutesSinceCreated}`}
      />
    </StackView>
  )
}

const columnDefs: ColDef<OrderWithObservationsFragment>[] = [
  {
    colId: 'order',
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = new Date(nodeA.data.createdAt)
      const b = new Date(nodeB.data.createdAt)

      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    sortable: true,
    sort: 'desc',
    cellRenderer: ({
      data,
      context,
    }: {
      data: OrderWithObservationsFragment
      context: Record<string, string>
    }) => {
      return (
        <StackView justifyContent="between" direction="row" space={100}>
          <OrdersTableOrderDetails order={data} />
          <RequesterDetails order={data} />
          {!context.hideActions && (
            <StackView
              direction="row"
              fullWidth={false}
              justifyContent="center"
              alignItems="center"
              gap={50}
            >
              <OrderActions order={data} encounterId={context.encounterId} />
            </StackView>
          )}
        </StackView>
      )
    },
  },
]

const OrdersTable = ({
  orders,
  testId,
  onRowClicked,
  encounterId,
  emptyMessage = 'No orders to display',
  pagination = false,
  hideActions = false,
}: {
  patientId: string
  encounterId?: string
  orders: OrderWithObservationsFragment[]
  emptyMessage?: string
  testId?: string
  pagination?: boolean
  onRowClicked?: (e: RowClickedEvent<OrderWithObservationsFragment>) => void
  hideActions?: boolean
}) => {
  const gridRef = useRef<AgGridReact>()

  return (
    <Table<OrderWithObservationsFragment>
      testId={testId ?? 'orders-table'}
      innerRef={gridRef}
      rowData={orders}
      onRowClicked={onRowClicked}
      domLayout="autoHeight"
      rowHeight={76}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={pagination}
      noRowsOverlayComponentParams={{ message: emptyMessage }}
      context={{ encounterId, hideActions }}
      hideHeader
    />
  )
}

export default OrdersTable
