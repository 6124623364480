import { useEffect } from 'react'

import type {
  CreatePatientChartAccess,
  RecentlyViewedPatientsQuery,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import { PATIENT_STATUS_FRAGMENT } from '../PatientChartsPage/PatientDemographics/fragments'

const RECENTLY_VIEWED_PATIENTS_QUERY = gql`
  query RecentlyViewedPatientsQuery {
    recentlyViewedPatients {
      id
      ...PatientStatusFragment
      mrn
      sexAtBirth
      birthDate
      namePrefix
      givenName
      middleName
      familyName
      nameSuffix
      contactInformation {
        id
        emailAddress
        emailAddress
        faxNumber
        mobileNumber
        homeNumber
        workNumber
      }
      primaryGuardian {
        id
        namePrefix
        givenName
        middleName
        familyName
        nameSuffix
        contactInformation {
          id
          emailAddress
          faxNumber
          mobileNumber
          homeNumber
          workNumber
        }
      }
      patientRelatedPersonRelationships {
        id
        guardianshipType
        relationshipType
        isGuarantor
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          contactInformation {
            id
            emailAddress
            faxNumber
            mobileNumber
            homeNumber
            workNumber
          }
        }
      }
      mostRecentAppointment {
        id
        start
      }
      openAppointments {
        id
        mostRecentVistFlowTab
        chartingStatus
      }
    }
  }
  ${PATIENT_STATUS_FRAGMENT}
`

const CREATE_PATIENT_CHART_ACCESS_MUTATION = gql`
  mutation CreatePatientChartAccess($input: CreatePatientChartAccessInput!) {
    createPatientChartAccess(input: $input) {
      id
      patientId
      practitionerId
    }
  }
`

export const useLogPatientChartAccess = (patientId: string) => {
  const [createPatientChartAccess] = useMutation<CreatePatientChartAccess>(
    CREATE_PATIENT_CHART_ACCESS_MUTATION,
    {
      onCompleted: () => {},
      onError: (_error) => {},
    }
  )

  useEffect(() => {
    createPatientChartAccess({
      variables: { input: { patientId } },
    })
  }, [patientId, createPatientChartAccess])

  return
}

export const useRecentlyViewedPatientsQuery = () => {
  const { data, loading, error } = useQuery<RecentlyViewedPatientsQuery>(
    RECENTLY_VIEWED_PATIENTS_QUERY
  )
  return {
    recentlyViewedPatients: data?.recentlyViewedPatients || [],
    loading,
    error,
  }
}
