import { useParams } from 'react-router-dom'
import {
  ReadPatientMembershipPlan,
  ReadPatientMembershipPlanVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { navigate, useLocation } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import InputField from 'src/components/atoms/InputField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import EmptyDataContent from 'src/components/molecules/EmptyDataContent/EmptyDataContent'
import FormInputList from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import TilledCardDetails, {
  useTilledCardDetailsRef,
} from 'src/components/TilledCardDetails/TilledCardDetails'
import { useUpdatePatientMembershipPlanPaymentMethod } from 'src/hooks/useUpdatePatientMembershipPlanPaymentMethod/useUpdatePatientMembershipPlanPaymentMethod'
import { sidepanelRoute } from 'src/lib/routes'

const QUERY = gql`
  query ReadPatientMembershipPlan($patientId: String!) {
    patient(id: $patientId) {
      id
      activeMembershipPlan {
        id
      }
    }
  }
`

const SidepanelPatientMembershipPlanChangePaymentMethodHelper = ({
  patientMembershipPlanId,
}: {
  patientMembershipPlanId: string
}) => {
  const params = useParams()
  const location = useLocation()
  const { patientId } = params

  const { currentUser } = useEmrAuth()

  const [updateMembershipPlanPaymentMethod] =
    useUpdatePatientMembershipPlanPaymentMethod()

  const tilledCardDetailsRef = useTilledCardDetailsRef()

  const formMethods = useForm({
    defaultValues: {
      cardHolder: '',
      zip: '',
    },
  })

  const onSubmit = async (data) => {
    const paymentMethod =
      await tilledCardDetailsRef?.current?.createPaymentMethod({
        cardHolder: data.cardHolder,
        zip: data.zip,
      })
    if (!paymentMethod.ok) return

    await updateMembershipPlanPaymentMethod({
      variables: {
        input: {
          patientMembershipPlanId,
          tilledPaymentMethodId: paymentMethod.id,
        },
      },
      onCompleted: () => {
        navigate(
          sidepanelRoute(
            {
              route: `/patient/${patientId}/memberships`,
            },
            location,
            params
          )
        )
      },
    })
  }

  return (
    <SidepanelPage
      testId="patient-membership-plan-change-payment-method"
      header="Change payment method"
      description="This payment method will be saved and used for the duration of the membership plan."
    >
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        footerProps={{
          disabled:
            !formMethods.formState.isValid ||
            formMethods.formState.isSubmitting,
          submitting: formMethods.formState.isSubmitting,
          submitText: 'Save',
        }}
      >
        <StackView className="py-core-space-150" space={100}>
          <FormInputList
            title="Card details"
            subtitle="Select the term and enter card details for the membership plan. Once you confirm these details the membership plan will begin today."
            divider={false}
            items={[
              {
                name: 'cardHolder',
                label: 'Cardholder full name',
                required: true,
                direction: 'col',
                formInputComponent: InputField,
              },
            ]}
          />

          <TilledCardDetails
            tilledCardDetailsRef={tilledCardDetailsRef}
            tilledAccountId={currentUser.tilledAccountId}
          />

          <FormInputList
            items={[
              {
                name: 'zip',
                label: 'Billing zip code',
                required: true,
                direction: 'col',
                formInputComponent: InputField,
              },
            ]}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

const SidepanelPatientMembershipPlanChangePaymentMethod = () => {
  const { patientId } = useParams()

  const { data } = useQuery<
    ReadPatientMembershipPlan,
    ReadPatientMembershipPlanVariables
  >(QUERY, {
    variables: {
      patientId,
    },
  })

  if (!data) return <LoadingSpinner />

  if (!data.patient.activeMembershipPlan) {
    return (
      <EmptyDataContent message="Patient does not have an active membership plan" />
    )
  }

  return (
    <SidepanelPatientMembershipPlanChangePaymentMethodHelper
      patientMembershipPlanId={data.patient.activeMembershipPlan.id}
    />
  )
}

export default SidepanelPatientMembershipPlanChangePaymentMethod
