import { useEffect, useState } from 'react'

import { Bars3Icon, CalendarDaysIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import {
  useScheduleFilter,
  useUpdateFilter,
} from 'src/hooks/useScheduleFilter/useScheduleFilter'

const SchedulePicker = () => {
  const { getCurrentUser } = useEmrAuth()
  const { viewType } = useScheduleFilter()
  const updateFilter = useUpdateFilter()
  const [updatedUser, setUpdatedUser] = useState(null)

  useEffect(() => {
    getCurrentUser().then((user) => {
      setUpdatedUser(user)
    })
  }, [getCurrentUser])

  useEffect(() => {
    if (updatedUser && isUndefined(viewType)) {
      updateFilter(
        'scheduleViewType',
        updatedUser.userSettings.scheduleViewType,
        {
          saveSettings: true,
        }
      )
    }
  }, [updateFilter, updatedUser, viewType])

  const inactiveClasses = ['bg-white text-gray-700 hover:bg-gray-50 rounded-md']
  const activeClasses = ['bg-gray-100 text-gray-400 hover:bg-gray-200']
  const isCalendarActive = viewType === 'CALENDAR'
  return (
    <div className="isolate inline-flex rounded-md border border-gray-300">
      <Box className="border-r border-gray-300">
        <Button
          buttonStyle="ghost"
          onClick={() =>
            updateFilter('scheduleViewType', 'CALENDAR', { saveSettings: true })
          }
          accessibilityText="Table view"
          data-testid="schedule-calendar-view-btn"
          className={clsx(
            isCalendarActive
              ? activeClasses
              : [...inactiveClasses, 'rounded-r-none'],
            'relative inline-flex h-full items-center rounded-none rounded-l-md px-2 py-2 text-sm font-medium focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary'
          )}
        >
          <CalendarDaysIcon className="h-5 w-5" aria-hidden="true" />
        </Button>
      </Box>
      <Button
        onClick={() =>
          updateFilter('scheduleViewType', 'LIST', { saveSettings: true })
        }
        className={clsx(
          !isCalendarActive
            ? activeClasses
            : [...inactiveClasses, 'rounded-l-none'],
          'relative inline-flex h-full items-center rounded-none rounded-r-md  px-2 py-2 text-sm font-medium focus:z-10 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary'
        )}
        buttonStyle="ghost"
        accessibilityText="Calendar view"
        data-testid="schedule-list-view-btn"
      >
        <Bars3Icon className="h-5 w-5" aria-hidden="true" />
      </Button>
    </div>
  )
}

export default SchedulePicker
