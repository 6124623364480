import { useEffect, useState } from 'react'

import {
  GetGuarantorBillingStatement,
  GetGuarantorBillingStatementVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import { PATIENT_DETAILED_BILLING_STATEMENT_FRAGMENT } from 'src/hooks/usePatientBillingStatement/usePatientBillingStatement'
import PrintPatientDetailedBillingStatement from 'src/pdf/components/PrintPatientDetailedBillingStatement'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'

import { useSidepanelCaregiverFinancialsContext } from './SidepanelCaregiverFinancialsContext'

const QUERY = gql`
  query GetGuarantorBillingStatement($relatedPersonId: String!) {
    relatedPerson(id: $relatedPersonId) {
      id
      billingStatement {
        ...BillingStatementFragment
      }
    }
  }
  ${PATIENT_DETAILED_BILLING_STATEMENT_FRAGMENT}
`

export const StatementTab = () => {
  const { guarantor } = useSidepanelCaregiverFinancialsContext()

  const { data, loading } = useQuery<
    GetGuarantorBillingStatement,
    GetGuarantorBillingStatementVariables
  >(QUERY, {
    variables: {
      relatedPersonId: guarantor.id,
    },
  })

  const [isGenerating, setIsGenerating] = useState(false)
  const [generated, setGenerated] = useState<URL>()
  const generatePDF = useGeneratePDF()

  useEffect(() => {
    if (generated) return
    if (isGenerating) return
    if (!data?.relatedPerson?.billingStatement) return

    setIsGenerating(true)

    void generatePDF({
      component: (
        <PrintPatientDetailedBillingStatement
          billingStatement={data.relatedPerson.billingStatement}
        />
      ),
      callback: (doc) => {
        setIsGenerating(false)
        setGenerated(doc.output('bloburl'))
      },
    })
  }, [data, generated, isGenerating, generatePDF])

  return (
    <StackView className="h-full p-core-space-150">
      {loading || isGenerating ? (
        <LoadingSpinner />
      ) : generated ? (
        <FileDisplay
          type="application/pdf"
          url={generated}
          showDownloadLink={false}
        />
      ) : (
        'This caregiver does not have a billing statement to display.'
      )}
    </StackView>
  )
}
