import { LocalDate } from '@js-joda/core'
import clsx from 'clsx'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import { formatPhoneNumber } from 'react-phone-number-input'
import { match } from 'ts-pattern'
import type {
  PatientDetailsFragment,
  PatientDetailsInsuranceCoverageFragment,
  PatientItemizedBillingHistory,
} from 'types/graphql'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { sexDisplay } from 'src/data/sexes'
import { formatAddress } from 'src/lib/formatters'
import { HEALTH_PLAN_TYPE_DISPLAY } from 'src/utils/insuranceCoverages'

import { LetterHead, SubHeading } from './common'
import { AddressAndPortalLinkSection } from './common/AddressAndPortalLinkSection'
import {
  EncountersAndPaymentPlanSection,
  SummarySection,
} from './PrintPatientDetailedBillingStatement'

const MessageSection = ({
  patientName,
  startDate,
  endDate,
  generatedAt,
}: {
  patientName: string
  startDate: LocalDate
  endDate: LocalDate
  generatedAt: Date
}) => {
  return (
    <>
      <StackView className="grow" space={50}>
        <Typography textStyle="title-l">
          Billing history for {patientName}
        </Typography>
        <Typography textStyle="body-m" color="text-base-color-fg-muted">
          {startDate.format(dateTimeFormatter('MM/dd/yyyy'))} to{' '}
          {endDate.format(dateTimeFormatter('MM/dd/yyyy'))} dates of service
        </Typography>
      </StackView>

      <Typography textStyle="body-s" color="text-base-color-fg-muted">
        Created on {format(generatedAt, "MM/dd/yyyy 'at' h:mma")}
      </Typography>
    </>
  )
}

const MessageAndSummarySection = ({
  finalizedBalanceCents,
  patientName,
  finalizedCharges,
  finalizedCredits,
  pendingCharges,
  pendingCredits,
  startDate,
  endDate,
  generatedAt,
  showPendingChargesAndCredits,
}: {
  finalizedBalanceCents: number
  patientName: string
  finalizedCharges: number
  finalizedCredits: number
  pendingCharges: number
  pendingCredits: number
  startDate: LocalDate
  endDate: LocalDate
  generatedAt: Date
  showPendingChargesAndCredits: boolean
}) => {
  if (showPendingChargesAndCredits) {
    return (
      <StackView gap={100}>
        <MessageSection
          patientName={patientName}
          startDate={startDate}
          endDate={endDate}
          generatedAt={generatedAt}
        />

        <StackView direction="row" gap={100}>
          <SummarySection
            headerText="Pending charges summary"
            charges={pendingCharges}
            credits={pendingCredits}
            variant="pending"
          />
          <SummarySection
            headerText="Finalized charges summary"
            charges={finalizedCharges}
            credits={finalizedCredits}
            patientBalanceCents={finalizedBalanceCents}
            hasPaymentPlan={false}
            variant="finalized"
          />
        </StackView>
      </StackView>
    )
  }

  return (
    <StackView space={150} direction="row" alignItems="stretch">
      <Box flex="6/12">
        <StackView className="h-full" alignItems="stretch" space={50}>
          <MessageSection
            patientName={patientName}
            startDate={startDate}
            endDate={endDate}
            generatedAt={generatedAt}
          />
        </StackView>
      </Box>

      <SummarySection
        charges={finalizedCharges}
        credits={finalizedCredits}
        patientBalanceCents={finalizedBalanceCents}
        hasPaymentPlan={false}
      />
    </StackView>
  )
}

const PatientSummary = ({ patient }: { patient: PatientDetailsFragment }) => {
  const contactInformation =
    patient.contactInformation ??
    patient.patientRelatedPersonRelationships.find(
      (relationship) => relationship.doesResideWith
    )?.relatedPerson?.contactInformation

  return (
    <StackView className="p-core-space-75" gap={25}>
      <SubHeading>Patient</SubHeading>
      <Typography textStyle="interface-default-s">
        {formatDisplayName(patient)}
      </Typography>

      <StackView direction="row" gap={50}>
        <StackView gap={25}>
          <StackView direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Date of birth:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {jsDateToLocalDate(patient.birthDate).format(
                dateTimeFormatter('MM/dd/yyyy')
              )}
            </Typography>
          </StackView>
          <StackView direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Sex at birth:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {sexDisplay[patient.sexAtBirth]}
            </Typography>
          </StackView>
        </StackView>

        <StackView gap={25}>
          <StackView direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Home address:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {formatAddress(contactInformation?.homeAddress)}
            </Typography>
          </StackView>
        </StackView>
      </StackView>
    </StackView>
  )
}

const GuardianSummary = ({ patient }: { patient: PatientDetailsFragment }) => {
  const guardians = patient.patientRelatedPersonRelationships.filter(
    (relationship) => relationship.guardianshipType !== 'NON_GUARDIAN'
  )

  if (isEmpty(guardians)) {
    return null
  }

  return (
    <StackView className="p-core-space-75" gap={25}>
      <SubHeading>Guardian(s)</SubHeading>
      <StackView direction="row" wrap>
        {guardians.map((guardian) => (
          <GuardianDisplay key={guardian.id} guardian={guardian} />
        ))}
      </StackView>
    </StackView>
  )
}

const GuardianDisplay = ({
  guardian,
}: {
  guardian: PatientDetailsFragment['patientRelatedPersonRelationships'][number]
}) => {
  return (
    <StackView className="w-1/2 pb-core-space-75" fullWidth={false} gap={25}>
      <Typography textStyle="interface-default-s">
        {formatDisplayName(guardian.relatedPerson)}
      </Typography>
      <StackView direction="row" gap={50}>
        <Typography className="w-1/2" textStyle="interface-strong-xs">
          Relationship to patient:
        </Typography>
        <Typography textStyle="interface-default-xs">
          {relationshipTypeDisplay[guardian.relationshipType]}
        </Typography>
      </StackView>

      <StackView direction="row" gap={50}>
        <Typography className="w-1/2" textStyle="interface-strong-xs">
          Mobile number:
        </Typography>
        <Typography textStyle="interface-default-xs">
          {formatPhoneNumber(
            guardian.relatedPerson.contactInformation?.mobileNumber
          )}
        </Typography>
      </StackView>

      <StackView direction="row" gap={50} wrap>
        <Typography className="w-1/2" textStyle="interface-strong-xs">
          Email address:
        </Typography>
        <Typography textStyle="interface-default-xs">
          {guardian.relatedPerson.contactInformation?.emailAddress}
        </Typography>
      </StackView>
    </StackView>
  )
}

const InsuranceSummary = ({
  patient,
}: {
  patient: PatientItemizedBillingHistory['patient']
}) => {
  if (
    !patient?.primaryActiveInsuranceCoverage &&
    !patient?.secondaryActiveInsuranceCoverage
  )
    return null

  const hasMultipleInsurances =
    patient.primaryActiveInsuranceCoverage &&
    patient.secondaryActiveInsuranceCoverage

  return (
    <StackView className="p-core-space-75" gap={25}>
      <SubHeading>Insurance</SubHeading>
      <StackView direction="row" gap={50}>
        <InsuranceDisplay
          insuranceCoverage={patient.primaryActiveInsuranceCoverage}
          patient={patient}
          type="primary"
          renderInsuranceType={hasMultipleInsurances ? 'multiple' : 'single'}
        />
        <InsuranceDisplay
          insuranceCoverage={patient.secondaryActiveInsuranceCoverage}
          patient={patient}
          type="secondary"
          renderInsuranceType={hasMultipleInsurances ? 'multiple' : 'single'}
        />
      </StackView>
    </StackView>
  )
}

const InsuranceDisplay = ({
  insuranceCoverage,
  patient,
  type,
  renderInsuranceType,
}: {
  insuranceCoverage: PatientDetailsInsuranceCoverageFragment
  patient: PatientDetailsFragment
  type: 'primary' | 'secondary'
  renderInsuranceType: 'single' | 'multiple'
}) => {
  if (!insuranceCoverage) return null

  const policyholder = insuranceCoverage.beneficiaryIsSubscriber
    ? patient
    : patient.patientRelatedPersonRelationships.find(
        (relationship) =>
          relationship.relatedPerson.id === insuranceCoverage.subscriberId
      )?.relatedPerson

  return (
    <StackView gap={25}>
      {renderInsuranceType === 'multiple' && (
        <Typography textStyle="interface-default-s">
          {match(type)
            .with('primary', () => 'Primary')
            .with('secondary', () => 'Secondary')
            .exhaustive()}
        </Typography>
      )}

      <div className={clsx([renderInsuranceType === 'single' && 'columns-2'])}>
        <StackView gap={25}>
          <StackView className="break-inside-avoid" direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Member ID:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {insuranceCoverage.planMemberIdentifier}
            </Typography>
          </StackView>

          <StackView className="break-inside-avoid" direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Insurance provider:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {insuranceCoverage.payer.displayName}
            </Typography>
          </StackView>

          <StackView
            className="break-before-avoid break-inside-avoid"
            direction="row"
            gap={50}
          >
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Plan name:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {insuranceCoverage.planName}
            </Typography>
          </StackView>

          <StackView className="break-inside-avoid" direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Health plan type:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {HEALTH_PLAN_TYPE_DISPLAY[insuranceCoverage.planType]}
            </Typography>
          </StackView>

          <StackView className="break-inside-avoid" direction="row" gap={50}>
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Policyholder:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {formatDisplayName(policyholder)}
            </Typography>
          </StackView>

          <StackView
            className="break-inside-avoid pb-core-space-25"
            direction="row"
            gap={50}
          >
            <Typography className="w-1/2" textStyle="interface-strong-xs">
              Policyholder date of birth:
            </Typography>
            <Typography className="w-1/2" textStyle="interface-default-xs">
              {jsDateToLocalDate(policyholder.birthDate).format(
                dateTimeFormatter('MM/dd/yyyy')
              )}
            </Typography>
          </StackView>
        </StackView>
      </div>
    </StackView>
  )
}

const PatientDetails = ({
  patient,
}: {
  patient: PatientItemizedBillingHistory['patient']
}) => {
  return (
    <StackView gap={75}>
      <Typography textStyle="title-xs">
        Patient, guardian, and insurance summary
      </Typography>
      <Box border rounded>
        <StackView divider>
          <PatientSummary patient={patient} />
          <GuardianSummary patient={patient} />
          <InsuranceSummary patient={patient} />
        </StackView>
      </Box>
    </StackView>
  )
}

const PrintPatientItemizedBillingHistory = ({
  billingStatement,
  endDate,
  generatedAt,
  patient,
  startDate,
  testId,
}: {
  billingStatement: PatientItemizedBillingHistory['billingStatement']
  endDate: LocalDate
  generatedAt: Date
  patient: PatientItemizedBillingHistory['patient']
  startDate: LocalDate
  testId?: string
}) => {
  return (
    <StackView space={100} data-testid={testId}>
      <LetterHead tenant={billingStatement.tenant} />
      <MessageAndSummarySection
        finalizedCharges={billingStatement.finalizedChargeCents}
        finalizedCredits={-billingStatement.finalizedCreditCents}
        pendingCharges={billingStatement.pendingChargeCents}
        pendingCredits={-billingStatement.pendingCreditCents}
        endDate={endDate}
        generatedAt={generatedAt}
        finalizedBalanceCents={billingStatement.finalizedBalanceCents}
        patientName={formatDisplayName(patient)}
        startDate={startDate}
        showPendingChargesAndCredits={
          !isEmpty(billingStatement.pendingBillingStatementEncounters)
        }
      />
      {billingStatement.displayPortalLink ? (
        <AddressAndPortalLinkSection
          name={formatDisplayName(billingStatement.guarantor)}
          mailingAddress={billingStatement.guarantor.mailingAddress}
          portal={
            patient.tenant.portalUrl
              ? {
                  url: patient.tenant.portalUrl,
                  qrCodeDataUrl: patient.tenant.portalQRCodeDataUrl,
                }
              : null
          }
        />
      ) : null}
      <PatientDetails patient={patient} />
      <EncountersAndPaymentPlanSection
        finalizedBillingStatementEncounters={
          billingStatement.finalizedBillingStatementEncounters
        }
        pendingBillingStatementEncounters={
          billingStatement.pendingBillingStatementEncounters
        }
        currentPaymentPlan={null}
        endDate={endDate}
        startDate={startDate}
        tenantName={billingStatement.tenant.name}
      />
    </StackView>
  )
}

export default PrintPatientItemizedBillingHistory
