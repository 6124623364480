import { useParams } from 'react-router-dom'
import {
  SendMembershipPlanPaymentLink,
  SendMembershipPlanPaymentLinkVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { PortalLinkTextRecipientMultiRadioButtonField } from 'src/components/PortalLinkTextRecipientMultiRadioButtonField/PortalLinkTextRecipientMultiRadioButtonField'
import { useOpenSidepanel } from 'src/hooks/useOpenSidepanel/useOpenSidepanel'

const SEND_PAYMENT_LINK = gql`
  mutation SendMembershipPlanPaymentLink(
    $patientId: String!
    $toContactInformationId: String!
  ) {
    sendPatientMembershipPlanPaymentLink(
      patientId: $patientId
      toContactInformationId: $toContactInformationId
    ) {
      id
    }
  }
`

const SidepanelPatientMembershipPlanSendPaymentLink = () => {
  const { patientId } = useParams()
  const formMethods = useForm()
  const [sendPaymentLink] = useMutation<
    SendMembershipPlanPaymentLink,
    SendMembershipPlanPaymentLinkVariables
  >(SEND_PAYMENT_LINK)
  const openSidepanel = useOpenSidepanel()

  const onSubmit = async (data) => {
    await sendPaymentLink({
      variables: {
        patientId,
        toContactInformationId: data.toContactInformationId,
      },
      onCompleted: () => {
        openSidepanel({
          route: `/patient/${patientId}/memberships`,
        })
        toast.success('Payment link sent')
      },
    })
  }

  return (
    <SidepanelPage
      header="Send payment link"
      description="Select the recipient who should receive the text message-based payment link."
    >
      <SidepanelForm
        formMethods={formMethods}
        onSubmit={onSubmit}
        footerProps={{
          submitting: formMethods.formState.isSubmitting,
          submitText: 'Send',
        }}
      >
        <FormInputList
          items={[
            {
              name: 'toContactInformationId',
              label: 'Recipient',
              subtitle:
                'Select the patient or one of their caregivers. This individual can update the payment method on file and pay the outstanding balance.',
              required: true,
              formInputComponent: PortalLinkTextRecipientMultiRadioButtonField,
              direction: 'col',
              inputProps: {
                radioStyle: 'card' as const,
                radioPosition: 'right' as const,
                patientId,
                portalFeature: 'BILLING',
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPatientMembershipPlanSendPaymentLink
