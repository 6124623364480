import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { races } from 'src/data/races'

export const RaceSelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={races.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default RaceSelectField
