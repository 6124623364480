import { isEmptyLexical } from 'common/lexical/lexical'

import { emptyPlaceholder } from 'src/lib/formatters'

import { useGetLexicalHtml } from '../WysiwygField/hooks/useGetLexicalHtml'

export const WysiwygDisplay = ({ text }: { text: string }) => {
  const html = useGetLexicalHtml(text)

  return isEmptyLexical(text) ? emptyPlaceholder : html
}
