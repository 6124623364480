import { formatDisplayName } from 'common/utils'
import { AppointmentPatientCheckInQuery } from 'types/graphql'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext/AppointmentCheckInContext'

import CaregiverAdditionalDemographicsVerificationCard from './CaregiverAdditionalDemographicsVerificationCard'
import CaregiverContactInformationVerificationCard from './CaregiverContactInformationVerificationCard'
import CaregiverCoreDemographicsVerificationCard from './CaregiverCoreDemographicsVerificationCard'
import CaregiverRelationshipVerificationCard from './CaregiverRelationshipVerificationCard'

const CaregiverVerificationSection: React.FC<{
  relationship: AppointmentPatientCheckInQuery['appointment']['patient']['patientRelatedPersonRelationships'][0]
  onRemove: () => void
}> = ({ relationship, onRemove }) => {
  const {
    appointmentData: { patient },
  } = useAppointmentCheckIn()

  return (
    <>
      <StackView direction="row" justifyContent="between" className="py-4">
        <Typography textStyle="heading">
          {formatDisplayName(relationship.relatedPerson)}
        </Typography>
        <Button
          buttonStyle="secondary"
          onClick={onRemove}
          disabled={
            relationship.doesResideWith ||
            relationship.relatedPerson.id === patient.primaryGuardianId ||
            relationship.isGuarantor ||
            relationship.relatedPerson.id === patient.primaryGuarantorId
          }
        >
          Remove
        </Button>
      </StackView>
      <StackView space={75}>
        <CaregiverRelationshipVerificationCard relationship={relationship} />

        <Typography textStyle="title" className="py-4">
          Contact
        </Typography>
        <CaregiverContactInformationVerificationCard
          relationship={relationship}
        />

        <Typography textStyle="title" className="py-4">
          Demographics
        </Typography>
        <CaregiverCoreDemographicsVerificationCard
          relationship={relationship}
        />
        <CaregiverAdditionalDemographicsVerificationCard
          relationship={relationship}
        />
      </StackView>
    </>
  )
}

export default CaregiverVerificationSection
