import { LocalSettings } from 'src/providers/context/LocalSettingsContext'

export function restoreSettings() {
  let settings: LocalSettings = null

  try {
    const storedData = localStorage.getItem('settings')

    if (storedData) {
      settings = JSON.parse(storedData)
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    localStorage.removeItem('settings')
  }

  return settings
}

export function storeSettings(settings: LocalSettings) {
  localStorage.setItem('settings', JSON.stringify(settings))
}
