import { format, parseISO } from 'date-fns'

import { useParams } from '@redwoodjs/router'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'

import LabsTable from './Labs/LabsTable'
import { useLabsQuery } from './Labs/useLabs'
import ScreensTable from './Screens/ScreensTable'
import { useScreensQuery } from './Screens/useScreens'
import VitalsHeader from './Vitals/VitalsHeader'
import VitalsTable from './Vitals/VitalsTable'

const PatientResults = () => {
  const params = useParams()
  const { id: patientId } = params

  const { labsLastUpdated } = useLabsQuery(patientId)
  const { screensLastUpdated } = useScreensQuery(patientId)

  return (
    <StackView space={150} className="px-6 pb-8 pt-4">
      <StackView space={75}>
        <VitalsHeader />
        <VitalsTable patientId={patientId} />
      </StackView>
      <StackView space={75}>
        <StackView>
          <StackView direction="row" space={50} alignItems="center">
            <Typography textStyle="title" fontWeight="semibold">
              Labs
            </Typography>
          </StackView>
          {labsLastUpdated ? (
            <Typography
              textStyle="description"
              color="text-base-color-fg-muted"
            >
              Last updated {format(parseISO(labsLastUpdated), 'MM/dd/yyyy')}
            </Typography>
          ) : null}
        </StackView>
        <LabsTable patientId={patientId} />
      </StackView>

      <StackView space={75} className="pb-4">
        <StackView>
          <StackView direction="row" space={50} alignItems="center">
            <Typography textStyle="title" fontWeight="semibold">
              Screens
            </Typography>
          </StackView>
          {screensLastUpdated ? (
            <Typography
              textStyle="description"
              color="text-base-color-fg-muted"
            >
              Last updated {format(parseISO(screensLastUpdated), 'MM/dd/yyyy')}
            </Typography>
          ) : null}
        </StackView>
        <ScreensTable patientId={patientId} />
      </StackView>
    </StackView>
  )
}

export default PatientResults
