import { useEffect } from 'react'

import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import {
  PatientDemographicsFragment,
  RelationshipType,
  UpdateInsuranceCoverageInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import RelationshipTypeSelectField from 'src/components/atoms/RelationshipTypeSelectField'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import RelatedPersonSelectCell from 'src/components/RelatedPerson/RelatedPersonSelectCell'
import { formatDateDisplay } from 'src/lib/formatters'
import { UPDATE_INSURANCE_STATUS_MUTATION } from 'src/providers/context/AppointmentCheckInContext/schema'

type VerificationFormState = UpdateInsuranceCoverageInput & {
  subscriber: {
    id: string
    birthDate: string
    relationshipType: RelationshipType
  }
}

const InsurancePolicyholderCard: React.FC<{
  insurance: PatientDemographicsFragment['insuranceCoverages'][0]
  patient: PatientDemographicsFragment
}> = ({ insurance, patient }) => {
  const relationships = patient.patientRelatedPersonRelationships
  const memberCaregiverOptedOutAt = patient.caregiverOptedOutAt

  const relationship = relationships.find(
    (relationship) => relationship.relatedPerson.id === insurance.subscriber?.id
  )

  const formMethods = useForm<VerificationFormState>({
    defaultValues: {
      beneficiaryIsSubscriber: insurance.beneficiaryIsSubscriber,
      subscriber: {
        id: insurance.subscriber?.id,
        birthDate: insurance.subscriber?.birthDate,
        relationshipType: relationship?.relationshipType,
      },
    },
  })

  const beneficiaryIsSubscriberInput = formMethods.watch(
    'beneficiaryIsSubscriber'
  )
  const policyholderIdInput = formMethods.watch('subscriber.id')

  useEffect(() => {
    const relationship = relationships.find(
      (relationship) => relationship.relatedPerson.id === policyholderIdInput
    )

    if (relationship) {
      formMethods.setValue(
        'subscriber.birthDate',
        formatDateDisplay(relationship.relatedPerson.birthDate)
      )
      formMethods.setValue(
        'subscriber.relationshipType',
        relationship.relationshipType
      )
    }
  }, [formMethods, policyholderIdInput, relationships])

  useEffect(() => {
    if (beneficiaryIsSubscriberInput) {
      formMethods.unregister('subscriber')
    }
  }, [formMethods, beneficiaryIsSubscriberInput])

  const preSubmit = (data: VerificationFormState) => {
    const { subscriber, ...input } = data
    return {
      variables: {
        id: insurance.id,
        input: { subscriberId: subscriber?.id || null, ...input },
      },
    }
  }

  return (
    <DataVerificationCard<VerificationFormState, UpdateInsuranceCoverageInput>
      title="Policyholder information"
      updateMutation={UPDATE_INSURANCE_STATUS_MUTATION}
      preSubmit={preSubmit}
      formMethods={formMethods}
      defaultOpen
      data={[
        {
          name: 'beneficiaryIsSubscriber',
          label: 'Patient is policy holder',
          inputProps: {
            validation: {
              validate: (beneficiaryIsSubscriber) =>
                !beneficiaryIsSubscriber && memberCaregiverOptedOutAt
                  ? 'Patient needs a caregiver to select a policy holder. If no caregivers, the patient must either be the policy holder or opt out of insurance coverage.'
                  : true,
            },
          },
          value: insurance.beneficiaryIsSubscriber,
          formInputComponent: CheckboxField,
        },
        {
          name: 'subscriber.id',
          label: 'Policyholder',
          required: true,
          hideFormInput: beneficiaryIsSubscriberInput,
          hide: insurance.beneficiaryIsSubscriber,
          value: insurance.subscriber && insurance.subscriber?.id,
          displayValue:
            insurance.subscriber && formatDisplayName(insurance.subscriber),
          inputProps: {
            patientId: patient.id,
          },
          formInputComponent: RelatedPersonSelectCell,
        },
        {
          name: 'subscriber.birthDate',
          label: 'Policyholder date of birth',
          message:
            'Please confirm that the policyholder date of birth matches the caregiver date of birth that is kept on file. If it does not, please go back and update the caregiver date of birth.',
          required: true,
          hideFormInput: beneficiaryIsSubscriberInput,
          hide: insurance.beneficiaryIsSubscriber,
          inputProps: {
            disabled: true,
          },
          value: insurance.subscriber?.birthDate,
          displayValue: formatDateDisplay(insurance.subscriber?.birthDate),
          formInputComponent: DatePickerField,
        },
        {
          name: 'subscriber.relationshipType',
          label: 'Relationship to patient',
          required: true,
          hideFormInput: beneficiaryIsSubscriberInput,
          hide: insurance.beneficiaryIsSubscriber,
          inputProps: {
            disabled: true,
          },
          value: relationship?.relationshipType,
          displayValue: relationshipTypeDisplay[relationship?.relationshipType],
          formInputComponent: RelationshipTypeSelectField,
        },
      ]}
    />
  )
}

export default InsurancePolicyholderCard
