import { ArrowPathIcon, PaperAirplaneIcon } from '@heroicons/react/20/solid'
import capitalize from 'lodash/capitalize'
import { useParams } from 'react-router-dom'

import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Alert from 'src/components/molecules/Alert/Alert'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { PatientMembershipPlanCard } from 'src/components/PatientMembershipPlanCard/PatientMembershipPlanCard'
import { useOpenSidepanel } from 'src/hooks/useOpenSidepanel/useOpenSidepanel'
import { usePatientMemberships } from 'src/hooks/usePatientMemberships/usePatientMemberships'

import { CancelPatientMembershipButton } from './CancelPatientMembershipButton'
import { MembershipPaymentHistorySection } from './MembershipPaymentHistorySection'

export const CurrentPatientMembershipPlanTab = () => {
  const { patientId } = useParams()
  const { data } = usePatientMemberships({ patientId })
  const openSidepanel = useOpenSidepanel()

  if (!data) return null

  const activePlan = data?.patient?.activeMembershipPlan

  if (!activePlan) return null

  const paymentMethod = activePlan.tilledPaymentMethod

  const brand = paymentMethod?.brand ? capitalize(paymentMethod.brand) : null
  const last4 = paymentMethod?.last4
  const holderName = paymentMethod?.holderName
  const expirationMonth = paymentMethod?.expirationMonth
    ?.toString()
    .padStart(2, '0')
  const expirationYear = paymentMethod?.expirationYear?.toString().slice(-2)

  return (
    <StackView gap={150} className="py-core-space-150">
      <StackView gap={100}>
        <SectionHeader
          size="s"
          title="Current membership"
          actions={
            activePlan.hasPaymentError ? (
              <Button
                testId="send-payment-link-btn"
                text="Send payment link"
                icon={PaperAirplaneIcon}
                buttonStyle="secondary"
                onClick={() =>
                  openSidepanel({
                    route: `/patient/${patientId}/membership/send-payment-link`,
                  })
                }
              />
            ) : null
          }
        />
        <PatientMembershipPlanCard plan={activePlan} />
        <Card
          verticalPadding={150}
          horizontalPadding={100}
          testId="membership-plan-payment-method-card"
        >
          <StackView gap={100}>
            <StackView
              direction="row"
              alignItems="center"
              justifyContent="between"
            >
              {paymentMethod ? (
                <StackView>
                  <Typography textStyle="interface-strong-s">
                    {brand} ending in {last4}
                  </Typography>
                  <Typography
                    textStyle="interface-default-xs"
                    color="text-base-color-fg-subtle"
                  >
                    {holderName} · {expirationMonth}/{expirationYear}
                  </Typography>
                </StackView>
              ) : (
                <Typography textStyle="interface-strong-s">
                  No payment method
                </Typography>
              )}

              <Button
                icon={ArrowPathIcon}
                text="Change"
                buttonStyle="secondary"
                onClick={() => {
                  openSidepanel({
                    route: `/patient/${patientId}/membership/change-payment-method`,
                    width: 'small',
                  })
                }}
              />
            </StackView>

            {activePlan.hasPaymentError ? (
              <Alert
                style="danger"
                title="There is an error with the existing payment method."
              />
            ) : null}
          </StackView>
        </Card>
      </StackView>

      <Divider />

      <MembershipPaymentHistorySection />

      <Divider />

      <StackView gap={100}>
        <SectionHeader
          size="s"
          title="Destructive actions"
          description="These changes are permanent, use caution when removing."
        />
        <CancelPatientMembershipButton
          patientMembershipPlanId={activePlan.id}
        />
      </StackView>
    </StackView>
  )
}
