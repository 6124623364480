import { useState } from 'react'

import { Redirect, routes, useParams } from '@redwoodjs/router'

import { usePatientPortalAuth } from 'src/auth'
import { usePatientPortalContext } from 'src/providers/context/PatientPortalContext/PatientPortalContext'

import { PatientPortalLoginCodeStep } from './PatientPortalLoginCodeStep'
import { PatientPortalLoginPhoneNumberStep } from './PatientPortalLoginPhoneNumberStep'

const PatientPortalLoginPage = () => {
  const { isAuthenticated } = usePatientPortalAuth()
  const { redirectTo: redirectToParam, loginMobileNumber } = useParams()
  const { tenant } = usePatientPortalContext()
  const redirectTo =
    redirectToParam ||
    routes.patientPortalHome({ tenantPortalKey: tenant.portalKey })

  const [mobileNumber, setMobileNumber] = useState('')

  if (isAuthenticated)
    return <Redirect to={redirectTo} options={{ replace: true }} />

  if (mobileNumber)
    return <PatientPortalLoginCodeStep mobileNumber={mobileNumber} />

  return (
    <PatientPortalLoginPhoneNumberStep
      onRequestCodeSuccess={({ mobileNumber }) => setMobileNumber(mobileNumber)}
      loginMobileNumber={loginMobileNumber}
    />
  )
}

export default PatientPortalLoginPage
