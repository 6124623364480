import { match } from 'ts-pattern'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'

import StackView from 'src/components/atoms/StackView'
import PatientLayout, {
  PatientLayoutProps,
} from 'src/layouts/PatientLayout/PatientLayout'

const getLastPath = (pathname: string): string => {
  const [lastPath] = pathname.split('/').reverse()
  return lastPath
}

const shouldShowBackButton = (pathname: string): boolean => {
  const lastPath = getLastPath(pathname)
  return match(lastPath)
    .with('intro', () => false)
    .with('confirmed', () => false)
    .otherwise(() => true)
}

const getProgressPercentage = (pathname: string): number => {
  const lastPath = getLastPath(pathname)
  return match(lastPath)
    .with('select-patient', () => 25)
    .with('visit-reason', () => 50)
    .with('visit-details', () => 75)
    .with('confirmed', () => 100)
    .otherwise(() => 0)
}

const getGoBackTitle = (pathname: string): string => {
  const lastPath = getLastPath(pathname)
  return match(lastPath)
    .with('select-patient', () => 'Intro')
    .with('visit-reason', () => 'Patient information')
    .with('visit-details', () => 'Visit reason')
    .otherwise(() => '')
}

const goBack = ({
  pathname,
  tenantPortalKey,
  patientId,
}: {
  pathname: string
  tenantPortalKey: string
  patientId: string
}): (() => void) => {
  const [lastPath] = pathname.split('/').reverse()
  return () =>
    match(lastPath)
      .with('select-patient', () =>
        navigate(routes.portalPatientSelfSchedulingIntro({ tenantPortalKey }))
      )
      .with('visit-reason', () =>
        navigate(
          routes.portalPatientSelfSchedulingSelectPatient({ tenantPortalKey })
        )
      )
      .with('visit-details', () =>
        navigate(
          routes.portalPatientSelfSchedulingVisitReason({
            tenantPortalKey,
            patientId,
          })
        )
      )
      .with('confirmed', () =>
        navigate(
          routes.portalPatientSelfSchedulingVisitDetails({
            tenantPortalKey,
            patientId,
          })
        )
      )
      .otherwise(() => {})
}

const buildPatientLayoutProps = ({
  pathname,
  tenantPortalKey,
  patientId,
}: {
  pathname: string
  tenantPortalKey: string
  patientId: string
}): PatientLayoutProps => {
  return {
    showProgressBar: true,
    progressBarPercentage: getProgressPercentage(pathname),
    navTitle: getGoBackTitle(pathname),
    showBackButton: shouldShowBackButton(pathname),
    backButtonOnClick: goBack({ pathname, tenantPortalKey, patientId }),
    showExitButton: !pathname.includes('confirmed'),
    exitButtonText: 'Cancel',
    exitButtonOnClick: () =>
      navigate(routes.patientPortalHome({ tenantPortalKey })),
  }
}

const PortalPatientSelfSchedulingLayout = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { pathname } = useLocation()
  const { tenantPortalKey, patientId } = useParams()
  return (
    <PatientLayout
      {...buildPatientLayoutProps({ pathname, tenantPortalKey, patientId })}
    >
      <StackView space={75}>{children}</StackView>
    </PatientLayout>
  )
}
export default PortalPatientSelfSchedulingLayout
