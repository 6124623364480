import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { menstrualPains } from 'src/data/menstrualPains'

export const MenstrualPainSelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      includeEmptyOption={true}
      options={menstrualPains.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default MenstrualPainSelectField
