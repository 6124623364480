import type { Ethnicity } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const ethnicityDisplay: {
  [key in Ethnicity]: string
} = {
  CUBAN: 'Cuban',
  MEXICAN: 'Mexican',
  PUERTO_RICAN: 'Puerto Rican',
  HISPANIC_OR_LATINO: 'Hispanic or Latino',
  NOT_HISPANIC_OR_LATINO: 'Not Hispanic or Latino',
  OTHER: 'Other',
  ASKED_BUT_UNKNOWN: 'Unknown',
}

export const ethnicities = nameValueArrayFromObject<Ethnicity>(ethnicityDisplay)
