import { LocalDate, LocalTime, convert } from '@js-joda/core'
import { dateTimeFormatter } from 'common/data/date'
import { paymentLineItemTypesDisplay } from 'common/data/paymentLineItemTypes'
import { paymentMethodTypeDisplay } from 'common/data/paymentMethodTypes'
import { formatDisplayName, formatMoneyInCents } from 'common/utils'
import { format } from 'date-fns'
import compareDesc from 'date-fns/compareDesc'
import type {
  CandidPaymentsFragment,
  DeveloCandidPatientRefundFragment,
  PatientPayments,
  PaymentLineItemType,
  PaymentMethodType,
  PaymentPlanPaymentFragment,
  PaymentPlanRefundFragment,
  TenantLetterheadFragment,
  PatientPortalPatientDetails,
} from 'types/graphql'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography, { TypographyColor } from 'src/components/atoms/Typography'

import { LetterHead } from './common'
import { AddressAndPortalLinkSection } from './common/AddressAndPortalLinkSection'

const SummarySectionRow = ({
  title,
  value,
  color = 'text-base-color-fg-muted',
  testId,
}: {
  title: string
  value: number
  color?: TypographyColor
  testId?: string
}) => {
  return (
    <StackView
      direction="row"
      className="p-core-space-75"
      justifyContent="between"
    >
      <Typography
        textStyle="interface-default-s"
        color="text-base-color-fg-muted"
      >
        {title}
      </Typography>
      <Typography
        textStyle="interface-default-s"
        color={color}
        data-testid={testId}
      >
        {formatMoneyInCents(value)}
      </Typography>
    </StackView>
  )
}

export const SummarySection = ({
  payments,
  refunds,
}: {
  payments: number
  refunds: number
}) => {
  return (
    <Box rounded border className="min-w-[300px]">
      <Box
        color="bg-base-color-bg-subtle"
        horizontalPadding={75}
        verticalPadding={50}
      >
        <Typography
          textStyle="interface-strong-xs"
          color="text-base-color-fg-muted"
        >
          Summary
        </Typography>
      </Box>
      <StackView divider>
        <SummarySectionRow
          title="Payments"
          value={payments}
          testId="payments"
        />
        <SummarySectionRow
          title="Refunds"
          value={refunds}
          color={
            refunds > 0
              ? 'text-base-color-fg-success'
              : 'text-base-color-fg-default'
          }
          testId="amount-due"
        />
      </StackView>
    </Box>
  )
}

const columnDefs = {
  paymentDate: {
    headerText: 'Payment date',
    className: 'w-core-space-700',
  },
  amount: {
    headerText: 'Amount',
    className: 'w-core-space-700',
  },
  charge: {
    headerText: 'Charge',
    className: 'w-core-space-2000',
  },
  paymentType: {
    headerText: 'Line item',
    className: 'w-core-space-1200',
  },
  paymentMethod: {
    headerText: 'Payment method',
    className: 'w-core-space-1200',
  },
} as const

type Column = keyof typeof columnDefs

const TableHeaderCell = ({ column }: { column: Column }) => {
  return (
    <Box className={columnDefs[column].className}>
      <Typography
        textStyle="interface-strong-xs"
        color="text-base-color-fg-muted"
      >
        {columnDefs[column].headerText}
      </Typography>
    </Box>
  )
}

const TableRowCell = ({
  text,
  subtext,
  color = 'text-base-color-fg-muted',
  testId,
  column,
}: {
  text: string
  subtext?: string
  color?: TypographyColor
  testId?: string
  column: keyof typeof columnDefs
}) => {
  return (
    <StackView
      data-testid={testId}
      className={columnDefs[column].className}
      fullWidth={false}
    >
      <Typography textStyle={'interface-default-s'} color={color}>
        {text ?? '-'}
      </Typography>
      {subtext && (
        <Typography
          textStyle="interface-default-xs"
          color={color ?? 'text-base-color-fg-subtle'}
        >
          {subtext}
        </Typography>
      )}
    </StackView>
  )
}

interface Payment {
  id: string
  createdAt: Date
  amountCents: number
  charge?: string
  chargeDate?: Date
  paymentType?: PaymentLineItemType
  paymentMethod?: PaymentMethodType
}

const getChargeDateFromPayment = (
  payment: PatientPayments['patientPayments'][number]
): Date | null => {
  return payment.appointment
    ? new Date(payment.appointment.start)
    : payment.directPatientChargeEncounter
      ? new Date(payment.directPatientChargeEncounter.dateOfService)
      : null
}

const PrintPatientDetailedPaymentHistory = ({
  patient,
  patientPayments,
  develoCandidPatientRefunds,
  paymentPlanPaymentsForPatient,
  paymentPlanRefundsForPatient,
  startDate,
  endDate,
  generatedAt,
  tenant,
  testId,
}: {
  patient:
    | PatientPayments['patient']
    | PatientPortalPatientDetails['patientPortalPatient']
  patientPayments: CandidPaymentsFragment[]
  develoCandidPatientRefunds: DeveloCandidPatientRefundFragment[]
  paymentPlanPaymentsForPatient: PaymentPlanPaymentFragment[]
  paymentPlanRefundsForPatient: PaymentPlanRefundFragment[]
  startDate: LocalDate
  endDate: LocalDate
  generatedAt: Date
  tenant: TenantLetterheadFragment
  testId?: string
}) => {
  const allPayments: Payment[] = [
    ...patientPayments.map((payment) => {
      return {
        id: payment.id,
        createdAt: new Date(payment.paidAt),
        amountCents: payment.amountCents,
        paymentType: payment.paymentLineItemType,
        paymentMethod: payment.paymentMethodType,
        charge: payment.chargeDisplay,
        chargeDate: getChargeDateFromPayment(payment),
      }
    }),
    ...develoCandidPatientRefunds
      .filter((refund) => refund.status === 'SUCCESSFUL')
      .map((refund) => {
        return {
          id: refund.id,
          createdAt: new Date(refund.createdAt),
          amountCents: -1 * refund.amountCents,
          paymentType: refund.candidPatientPayment.paymentLineItemType,
          paymentMethod: refund.candidPatientPayment.paymentMethodType,
          charge: refund.chargeDisplay,
          chargeDate: getChargeDateFromPayment(refund.candidPatientPayment),
        }
      }),
    ...paymentPlanPaymentsForPatient
      .filter((payment) => payment.status === 'SUCCESSFUL')
      .map((payment) => {
        return {
          id: payment.id,
          createdAt: new Date(payment.createdAt),
          amountCents: payment.amountCents,
          charge: payment.chargeDisplay,
        }
      }),
    ...paymentPlanRefundsForPatient
      .filter((refund) => refund.status === 'SUCCESSFUL')
      .map((refund) => {
        return {
          id: refund.id,
          createdAt: new Date(refund.createdAt),
          amountCents: refund.amountCents,
          charge: refund.chargeDisplay,
        }
      }),
  ]

  const paymentsToRender = allPayments
    .filter((payment) => {
      return (
        payment.paymentMethod !== 'PAYMENT_PLAN_CREATION' &&
        payment.createdAt > convert(startDate.atStartOfDay()).toDate() &&
        payment.createdAt < convert(endDate.atTime(LocalTime.MAX)).toDate()
      )
    })
    .sort((a, b) => compareDesc(a.createdAt, b.createdAt))

  const totalPaid = paymentsToRender
    .filter((payment) => payment.amountCents > 0)
    .reduce((acc, payment) => acc + payment.amountCents, 0)
  const totalRefunded = paymentsToRender
    .filter((payment) => payment.amountCents < 0)
    .reduce((acc, payment) => acc + payment.amountCents, 0)

  const startDateString = startDate.format(dateTimeFormatter('MM/dd/yyyy'))
  const endDateString = endDate.format(dateTimeFormatter('MM/dd/yyyy'))

  const headers: Column[] = [
    'paymentDate',
    'amount',
    'charge',
    'paymentType',
    'paymentMethod',
  ]

  const guarantor = patient.primaryGuarantor ?? patient

  return (
    <StackView space={150} data-testid={testId}>
      <LetterHead tenant={tenant} />

      <StackView direction="row">
        <StackView gap={50} className="flex-grow">
          <Typography textStyle="title-l">
            Payment history for {formatDisplayName(patient)}
          </Typography>

          <Typography textStyle="title-s">
            {startDateString} to {endDateString} payment dates
          </Typography>

          <Typography color="text-base-color-fg-subtle">
            Created on {format(generatedAt, "MM/dd/yyyy 'at' h:mm aa")}
          </Typography>
        </StackView>

        <SummarySection payments={totalPaid} refunds={totalRefunded} />
      </StackView>

      {tenant.featureFlags.includes('PORTAL_LINK_IN_BILLING_PDF') ? (
        <AddressAndPortalLinkSection
          name={formatDisplayName(guarantor)}
          mailingAddress={guarantor.contactInformation.mailingAddress}
          portal={
            tenant.portalUrl
              ? {
                  url: tenant.portalUrl,
                  qrCodeDataUrl: tenant.portalQRCodeDataUrl,
                }
              : null
          }
        />
      ) : null}

      <StackView gap={50}>
        <Typography textStyle="title-s">
          Payments made from {startDateString} to {endDateString}
        </Typography>

        <StackView
          className="bg-base-color-bg-subtle px-core-space-75 py-core-space-50"
          direction="row"
        >
          {headers.map((column) => (
            <TableHeaderCell key={column} column={column} />
          ))}
        </StackView>
        {paymentsToRender.map((payment) => {
          const color =
            payment.amountCents < 0 ? 'text-base-color-fg-success' : undefined
          return (
            <StackView
              key={payment.id}
              direction="row"
              className="p-core-space-75"
              alignItems="center"
            >
              <TableRowCell
                column="paymentDate"
                text={format(payment.createdAt, 'MM/dd/yyyy')}
                color={color}
              />
              <TableRowCell
                column="amount"
                text={formatMoneyInCents(payment.amountCents)}
                color={color}
              />
              <TableRowCell
                column="charge"
                text={payment.charge}
                subtext={
                  payment.chargeDate
                    ? format(payment.chargeDate, 'MM/dd/yyyy')
                    : undefined
                }
                color={color}
              />
              <TableRowCell
                column="paymentType"
                text={
                  payment.paymentType
                    ? paymentLineItemTypesDisplay[payment.paymentType]
                    : undefined
                }
                color={color}
              />
              <TableRowCell
                column="paymentMethod"
                text={
                  payment.paymentMethod
                    ? paymentMethodTypeDisplay[payment.paymentMethod]
                    : undefined
                }
                color={color}
              />
            </StackView>
          )
        })}
      </StackView>
    </StackView>
  )
}

export default PrintPatientDetailedPaymentHistory
