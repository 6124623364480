import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { ethnicities } from 'src/data/ethnicities'

export const EthnicitySelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      options={ethnicities.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default EthnicitySelectField
