import { useState, useRef, useEffect, useCallback } from 'react'

import { Combobox } from '@headlessui/react'
import { BuildingOfficeIcon, XMarkIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'
import isUndefined from 'lodash/isUndefined'

import { useFormContext } from '@redwoodjs/forms'

import { useEmrAuth } from 'src/auth'
import Checkbox from 'src/components/atoms/Checkbox/Checkbox'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import {
  useScheduleFilter,
  useUpdateFilter,
} from 'src/hooks/useScheduleFilter/useScheduleFilter'

const baseFilterClasses = [
  'relative',
  'inline-flex',
  'items-center',
  'text-sm',
  'text-gray-700',
  'font-medium',
  'bg-white',
  'hover:bg-gray-300',
  'border',
  'border-gray-300',
  'focus:z-10',
  'focus:ring-0',
  'focus:outline-none',
  'cursor-pointer',
]

const LocationFilter = ({ locations, defaultDisplayText, name }) => {
  const { getCurrentUser } = useEmrAuth()
  const { register, unregister } = useFormContext()
  const { locationIds: selectedLocationIds } = useScheduleFilter()
  const updateFilter = useUpdateFilter()

  const filterRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [updatedUser, setUpdatedUser] = useState(null)

  useEffect(() => {
    getCurrentUser().then((user) => {
      setUpdatedUser(user)
    })
  }, [getCurrentUser])

  useEffect(() => {
    if (updatedUser && isUndefined(selectedLocationIds)) {
      updateFilter(
        'scheduleLocationFilter',
        updatedUser.userSettings.scheduleLocationFilter,
        {
          saveSettings: true,
        }
      )
    }
  }, [selectedLocationIds, updateFilter, updatedUser])

  const handleClickOutsideFilter = useCallback((event) => {
    if (filterRef.current && !filterRef.current.contains(event.target)) {
      setOpen(false)
    }
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideFilter)
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideFilter)
    }
  }, [handleClickOutsideFilter])

  const clearFilter = () => {
    updateFilter('scheduleLocationFilter', [], { saveSettings: true })
  }

  const toggleFilter = ({ id: locationId }) => {
    const newValue = selectedLocationIds.includes(locationId)
      ? selectedLocationIds.filter((id) => id != locationId)
      : selectedLocationIds.concat([locationId])
    updateFilter('scheduleLocationFilter', newValue, { saveSettings: true })
  }

  const renderFilterText = () => {
    const selectedLocations = locations.filter((location) =>
      selectedLocationIds.includes(location.id)
    )
    if (selectedLocations.length === 0) {
      return defaultDisplayText
    } else if (selectedLocations.length === 1) {
      return selectedLocations[0].name
    } else {
      return `${
        selectedLocations.length
      } ${defaultDisplayText[0].toLowerCase()}${defaultDisplayText.slice(1)}`
    }
  }
  register(name)
  useEffect(() => {
    return () => {
      unregister(name)
    }
  }, [unregister, name])
  if (!selectedLocationIds) return null

  return (
    <Combobox as="div" data-testid="location-filter">
      <div className={clsx('relative')} ref={filterRef}>
        <span className="isolate inline-flex rounded-md shadow-sm">
          <button
            onClick={() => {
              setOpen(true)
            }}
            type="button"
            className={clsx(
              ...baseFilterClasses,
              'px-3',
              'py-2',
              'h-base-size-selectable-m',
              'rounded-l-md'
            )}
          >
            <BuildingOfficeIcon
              className="-ml-1 mr-2 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <Typography noWrap>{renderFilterText()}</Typography>
          </button>
          <button
            type="button"
            onClick={clearFilter}
            data-testid="clear-location-filter"
            className={clsx(
              ...baseFilterClasses,
              'px-2',
              'py-2',
              '-ml-px',
              'h-base-size-selectable-m',
              'rounded-r-md'
            )}
          >
            <XMarkIcon
              data-testid="clear-location-filter-btn"
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </span>
        {open && (
          <Combobox.Options
            static
            className={clsx(
              'absolute',
              'bg-white',
              'right-0',
              'z-10',
              'py-1',
              'mt-2',
              'w-56',
              'overflow-auto',
              'rounded-md',
              'shadow-lg'
            )}
          >
            {locations.map((location) => {
              const { id: locationId, name } = location
              return (
                <Combobox.Option
                  key={locationId}
                  value={name}
                  onClick={() => toggleFilter(location)}
                  className={({ active }) =>
                    clsx(
                      'relative',
                      'py-2',
                      'px-3',
                      'cursor-default',
                      active ? 'bg-gray-300 text-gray-700' : 'text-gray-700'
                    )
                  }
                  data-testid="combobox-locationOptions"
                >
                  <StackView direction="row" alignItems="center">
                    <Checkbox
                      id={`cb-${locationId}`}
                      name={`cb-${locationId}`}
                      checked={selectedLocationIds.includes(locationId)}
                      value={locationId}
                    />
                    <Typography className="-ml-1">{name}</Typography>
                  </StackView>
                </Combobox.Option>
              )
            })}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}

export default LocationFilter
