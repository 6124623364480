import {
  DetailedHTMLProps,
  HTMLAttributes,
  ReactElement,
  useEffect,
  useState,
} from 'react'

import { PlusIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import {
  RegisterOptions,
  useWatch,
  useFormContext,
  Controller,
} from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button'
import Label from 'src/components/atoms/Label/Label'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'

import FormBox from '../FormBox/FormBox'

type RadioPosition = 'left' | 'right'

type RadioStyle = 'default' | 'card'

interface RadioButtonProps
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string
  name: string
  description?: string
  labelClassName?: string
  value?: string
  checked?: boolean
  extraElement?: ReactElement
  disabled?: boolean
  radioPosition?: RadioPosition
  radioStyle?: RadioStyle
}

const cardClassName = ({ checked }: { checked: boolean }): string =>
  clsx(
    'py-base-space-selectable-inset-m',
    'px-base-space-selectable-inset-l',
    'rounded-base-border-radius-selectable-s',
    'border-base-border-width-selectable-s',
    checked
      ? [
          'bg-comp-toggle-color-primary-selected-enabled-primary-bg',
          'border-comp-toggle-color-primary-selected-enabled-border',
          'hover:bg-comp-toggle-color-primary-selected-hover-bg',
          'hover:border-comp-toggle-color-primary-selected-hover-border',
          'focus-within:bg-comp-toggle-color-primary-selected-focused-bg',
          'focus-within:border-comp-toggle-color-primary-selected-focused-border',
        ]
      : [
          'bg-comp-toggle-color-primary-unselected-enabled-primary-bg',
          'border-comp-toggle-color-primary-unselected-enabled-border',
          'hover:bg-comp-toggle-color-primary-unselected-hover-bg',
          'hover:border-comp-toggle-color-primary-unselected-hover-border',
          'focus-within:bg-comp-toggle-color-primary-unselected-focused-bg',
          'focus-within:border-comp-toggle-color-primary-unselected-focused-border',
        ]
  )

const RadioButton = ({
  label,
  name,
  description,
  extraElement: Extra = undefined,
  className,
  labelClassName,
  checked,
  value,
  disabled,
  radioPosition = 'left',
  radioStyle,
  ...rest
}: RadioButtonProps): ReactElement => {
  const radioClasses = clsx([
    radioStyle !== 'card' ? 'mt-core-space-25' : undefined,
    'h-core-space-100 w-core-space-100',
    'border-base-border-width-selectable-s',

    'text-comp-toggle-color-primary-selected-enabled-border',

    'bg-comp-toggle-color-primary-unselected-enabled-primary-bg',
    'border-comp-toggle-color-primary-unselected-enabled-border',

    'hover:bg-comp-toggle-color-primary-unselected-hover-bg',
    'hover:border-comp-toggle-color-primary-unselected-hover-border',
    'hover:text-comp-toggle-color-primary-selected-hover-border',

    'focus:bg-comp-toggle-color-primary-unselected-focused-bg',
    'focus:border-comp-toggle-color-primary-unselected-focused-border',
    'focus:ring-1',
    'focus:ring-comp-toggle-color-primary-selected-enabled-border',
  ])
  const labelClasses = [
    'text-comp-toggle-color-primary-unselected-enabled-primary-fg',
  ]

  return (
    <Label
      htmlFor={`${name}.${value}`}
      cleanDefaultClasses={!!labelClassName}
      className={clsx(labelClasses, labelClassName)}
    >
      <StackView
        key={value}
        direction="row"
        className={clsx(
          disabled && 'opacity-base-opacity-disabled',
          radioStyle === 'card' && cardClassName({ checked })
        )}
        gap={50}
        alignItems={radioStyle === 'card' ? 'center' : 'start'}
        reverse={radioPosition === 'right'}
      >
        <input
          disabled={disabled}
          value={value}
          name={name}
          checked={checked}
          id={`${name}.${value}`}
          className={clsx(radioClasses, className)}
          type="radio"
          {...rest}
        />
        <StackView>
          {label}
          {Extra}
          {description && (
            <Typography
              textStyle="interface-default-xs"
              color="text-comp-toggle-color-primary-unselected-enabled-primary-fg"
            >
              {description}
            </Typography>
          )}
        </StackView>
      </StackView>
    </Label>
  )
}

export interface RadioButtonFieldProps extends RadioButtonProps {
  validation?: RegisterOptions
  name: string
  value: string
  description?: string
  disabled?: boolean
}

export const RadioButtonField = ({
  name,
  value,
  description,
  validation,
  disabled,
  onChange,
  ...rest
}: RadioButtonFieldProps) => {
  const { setValue, register, unregister } = useFormContext()

  register(name, validation)
  const watchedValue = useWatch({
    name,
  })

  useEffect(() => {
    return () => validation?.shouldUnregister && unregister(name)
  }, [name, unregister, validation?.shouldUnregister])

  return (
    <RadioButton
      name={name}
      value={value || name}
      description={description}
      onChange={(event) => {
        if (onChange) {
          onChange(event)
        } else {
          setValue(name, event.target['value'], { shouldDirty: true })
        }
      }}
      checked={watchedValue === value}
      disabled={disabled}
      {...rest}
    />
  )
}

export interface MultiRadioButtonFieldProps {
  validation?: RegisterOptions
  defaultValue?: string
  values: {
    value: string
    label: string
    description?: string
    disabled?: boolean
  }[]
  name: string
  direction?: 'col' | 'row'
  labelClassName?: string
  disabled?: boolean
  onChange?: (unknown) => void
  radioStyle?: RadioStyle
  radioPosition?: RadioPosition
}

export const MultiRadioButtonField = ({
  values,
  defaultValue,
  name,
  validation,
  direction = 'col',
  disabled,
  onChange,
  radioStyle,
  radioPosition,
  ...rest
}: MultiRadioButtonFieldProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      rules={validation}
      defaultValue={defaultValue}
      render={({ field }) => {
        return (
          <StackView space={50} direction={direction} data-testid={name}>
            {values.map(
              ({ value, label, description, disabled: valueDisabled }) => {
                return (
                  <RadioButton
                    {...rest}
                    radioStyle={radioStyle}
                    onChange={(e) => {
                      onChange?.(e)
                      field.onChange(e.target['value'])
                    }}
                    disabled={valueDisabled ?? disabled}
                    key={value}
                    name={name}
                    value={value}
                    label={label}
                    description={description}
                    radioPosition={radioPosition}
                    checked={value === field.value}
                  />
                )
              }
            )}
          </StackView>
        )
      }}
    />
  )
}

export interface RadioButtonCardTableFieldProps {
  validation?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >
  values: {
    value: string
    label: string
  }[]
  name: string
  columns: column[]
  allowAddingNewOptions?: boolean
  onStartToAddOption?: () => void
  onSaveAddingOption?: () => void
  NewOptionFormInputList?: () => ReactElement
  newOptionFormIsValid?: boolean
}

type column = {
  id: string
  heading: string
  basis?:
    | '1/12'
    | '1/3'
    | '2/3'
    | '1/4'
    | '1/12'
    | '2/12'
    | '3/12'
    | '4/12'
    | '5/12'
    | '6/12'
    | '7/12'
    | '8/12'
    | '9/12'
    | '10/12'
    | '11/12'
    | 'full'
    | 'auto'
  columnRenderer: ({ data }) => ReactElement
}

export const RadioButtonCardTableField = ({
  values,
  name,
  validation,
  columns,
  allowAddingNewOptions,
  onStartToAddOption,
  onSaveAddingOption,
  NewOptionFormInputList,
  newOptionFormIsValid,
  ...rest
}: RadioButtonCardTableFieldProps) => {
  const [addingOption, setAddingOption] = useState(false)
  return (
    <Box color="bg-base-color-bg-default" border rounded>
      <Box
        color="bg-base-color-bg-subtle"
        className="rounded-t-md border-b"
        padding={50}
      >
        <StackView space={50} direction="row">
          <StackView space={50} className="basis-2/12">
            <Typography
              className="uppercase"
              textStyle="description"
              fontWeight="medium"
            >
              Select
            </Typography>
          </StackView>

          {columns.map((column) => (
            <StackView
              key={column.id}
              className={`basis-${column.basis || 'auto'}`}
            >
              <Typography
                className="truncate uppercase"
                textStyle="description"
                fontWeight="medium"
              >
                {column.heading}
              </Typography>
            </StackView>
          ))}
        </StackView>
      </Box>

      <StackView className="p-2">
        {values.map(({ value, label }, index) => (
          <StackView
            direction="row"
            key={value}
            space={50}
            className="min-h-8 py-2"
            alignItems="center"
          >
            <StackView alignItems="center" className="basis-2/12">
              <RadioButtonField
                {...rest}
                validation={validation}
                name={name}
                value={value}
                label={label}
              />
            </StackView>
            {columns.map((column) => (
              <StackView
                key={column.id}
                direction="row"
                alignItems="center"
                className={`basis-${column.basis || 'auto'}`}
              >
                <column.columnRenderer data={values[index]} />
              </StackView>
            ))}
          </StackView>
        ))}
      </StackView>

      {allowAddingNewOptions && !addingOption && (
        <Button
          icon={PlusIcon}
          text="Add storage location and funding source"
          buttonStyle="ghost"
          className="mx-3 text-sm"
          onClick={() => {
            setAddingOption(true)
            if (onStartToAddOption) onStartToAddOption()
          }}
        />
      )}

      {allowAddingNewOptions && addingOption && NewOptionFormInputList && (
        <FormBox
          InputList={NewOptionFormInputList}
          onSave={() => {
            setAddingOption(false)
            if (onSaveAddingOption) onSaveAddingOption()
          }}
          onCancel={() => setAddingOption(false)}
          isValid={newOptionFormIsValid}
          testId="radio-button-card-field-add-options"
        />
      )}
    </Box>
  )
}

export default RadioButton
