import type { CreateContactInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import InheritedAddressField from 'src/components/Address/InheritedAddressField/InheritedAddressField'
import InputField from 'src/components/atoms/InputField/InputField'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import ContactOrganizationSelectFieldCell from 'src/components/molecules/ContactOrganizationSelectFieldCell'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const CREATE_CONTACT_MUTATION = gql`
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      id
    }
  }
`

const CreateContact = () => {
  const { closeSidePanel } = useSidepanel()
  const [createContact, { loading: creatingContact }] = useMutation(
    CREATE_CONTACT_MUTATION,
    {
      onCompleted: () => {
        toast.success('Contact created')
        closeSidePanel()
      },
      refetchQueries: ['ContactOrganizationsQuery'],
    }
  )
  const formMethods = useForm<CreateContactInput>({
    shouldUnregister: true,
    defaultValues: {
      useOrganizationAddress: false,
    },
  })

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Add Contact',
        submitting: creatingContact,
      }}
      formMethods={formMethods}
      onSubmit={async (value) => {
        createContact({ variables: { input: value } })
      }}
    >
      <FormInputList
        items={[
          {
            name: 'name',
            label: 'Full name',
            required: true,
            formInputComponent: InputField,
          },
          {
            name: 'contactOrganizationId',
            label: 'Organization',
            required: true,
            formInputComponent: ContactOrganizationSelectFieldCell,
          },
          {
            name: 'address',
            label: 'Address',
            formInputComponent: InheritedAddressField,
            inputProps: {
              useInheritedAddressName: 'useOrganizationAddress',
              useInheritedAddressDescription: 'Same as organization address',
            },
          },
          {
            name: 'faxNumber',
            label: 'Fax number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'emailAddress',
            label: 'Email address',
            formInputComponent: InputField,
          },
          {
            name: 'mobileNumber',
            label: 'Mobile number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'homeNumber',
            label: 'Home number',
            formInputComponent: PhoneInputField,
          },
          {
            name: 'workNumber',
            label: 'Work number',
            formInputComponent: PhoneInputField,
          },
        ]}
      />
    </SidepanelForm>
  )
}

export default CreateContact
