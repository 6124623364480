import { useQuery } from '@apollo/client'
import {
  GetPatientActiveTaskCount,
  GetPatientActiveTaskCountVariables,
} from 'types/graphql'

const QUERY = gql`
  query GetPatientActiveTaskCount($patientId: String!) {
    patientActiveTaskCount(patientId: $patientId)
  }
`

export const usePatientActiveTaskCount = ({
  patientId,
}: {
  patientId: string
}): number => {
  const oneMinuteInMs = 60 * 1000

  const { data } = useQuery<
    GetPatientActiveTaskCount,
    GetPatientActiveTaskCountVariables
  >(QUERY, {
    variables: {
      patientId,
    },
    pollInterval: oneMinuteInMs,
  })

  return data?.patientActiveTaskCount ?? 0
}
