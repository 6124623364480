import { useRef } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import sortBy from 'lodash/sortBy'
import { Appointment, AppointmentFiltersInput } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Table from 'src/components/molecules/Table/Table'
import { sidepanelRoute } from 'src/lib/routes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import { useAppointmentScheduleQuery } from './useAppointmentSchedule'

type ScheduleTableProps = {
  filters: AppointmentFiltersInput
  columnDefinitions: ColDef[]
  domLayout?: 'autoHeight' | 'normal'
  testId?: string
  pagination?: boolean
}

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  cellClass: 'cursor-pointer truncate',
  resizable: true,
  autoHeight: true,
}

const ScheduleTable = ({
  filters,
  columnDefinitions,
  domLayout = 'normal',
  testId = 'schedule-table',
  pagination = false,
}: ScheduleTableProps) => {
  const location = useLocation()
  const params = useParams()
  const gridRef = useRef<AgGridReact>(null)
  const { sidepanelContext } = useSidepanel()
  const { data } = useAppointmentScheduleQuery(
    !filters.includeStatuses?.length ||
      filters.includeStatuses?.includes('CANCELLED')
      ? filters
      : {
          ...filters,
          excludeStatuses: filters.excludeStatuses
            ? [...filters.excludeStatuses, 'CANCELLED']
            : ['CANCELLED'],
        }
  )

  const getRowStyle = ({ data }: { data: Appointment }) => {
    if (data.status === 'CANCELLED')
      return {
        background: '#f1f5f9',
        textDecoration: 'line-through !important',
      }
  }

  const selectedRowId = sidepanelContext?.route?.split('/appointments/')[1]

  const rowData = data
    ? sortBy(
        data.appointments,
        (appointment) => new Date(appointment.start)
      ).map((appointment) => ({
        ...appointment,
        route: sidepanelRoute(
          { route: `/appointments/${appointment.id}/visit` },
          location
        ),
      }))
    : undefined

  return (
    <Table
      testId={testId}
      innerRef={gridRef}
      rowData={rowData}
      domLayout={domLayout}
      selectedRowId={selectedRowId}
      getRowStyle={getRowStyle}
      rowHeight={72}
      headerHeight={36}
      onRowClicked={(e) => {
        navigate(
          sidepanelRoute(
            { route: `/appointments/${e.data.id}/visit` },
            location,
            params
          )
        )
      }}
      defaultColDef={defaultColDef}
      columnDefs={columnDefinitions}
      pagination={pagination}
    />
  )
}

export default ScheduleTable
