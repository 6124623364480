import { useMutation } from '@apollo/client'

import { toast } from '@redwoodjs/web/toast'

const SAVE_DOCUMENT_CASE_ANNOTATED_DOCUMENT_PDF_MUTATION = gql`
  mutation SaveDocumentCaseAnnotatedDocumentPdf(
    $input: SaveDocumentCaseAnnotatedDocumentPdfInput!
  ) {
    saveDocumentCaseAnnotatedDocumentPdf(input: $input) {
      id
      binary {
        id
        content {
          contentType
          url
        }
      }
    }
  }
`

export const useSaveDocumentCaseAnnotatedDocumentPdf = () => {
  return useMutation(SAVE_DOCUMENT_CASE_ANNOTATED_DOCUMENT_PDF_MUTATION, {
    onCompleted: () => {
      toast.success('Successfully saved document.')
    },
    onError: () => toast.error('Failed to save document.'),
  })
}

export default useSaveDocumentCaseAnnotatedDocumentPdf
