import {
  ListMembershipsForPatient,
  ListMembershipsForPatientVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

export const PATIENT_MEMBERSHIP_PLAN_FRAGMENT = gql`
  fragment PatientMembershipPlanFragment on PatientMembershipPlan {
    id
    membershipPlan {
      id
      name
      annualAmountCents
      quarterlyAmountCents
      monthlyAmountCents
    }
    frequency
    startDate
    endDate
    tilledPaymentMethod {
      id
      brand
      holderName
      last4
      expirationMonth
      expirationYear
    }
    totalAmountPaidCents
    hasPaymentError
  }
`

const QUERY = gql`
  query ListMembershipsForPatient($id: String!) {
    patient(id: $id) {
      id
      activeMembershipPlan {
        ...PatientMembershipPlanFragment
      }
      pastMembershipPlans {
        ...PatientMembershipPlanFragment
      }
    }
  }
  ${PATIENT_MEMBERSHIP_PLAN_FRAGMENT}
`

export const usePatientMemberships = ({ patientId }: { patientId: string }) => {
  return useQuery<
    ListMembershipsForPatient,
    ListMembershipsForPatientVariables
  >(QUERY, {
    variables: {
      id: patientId,
    },
  })
}
