import { DateTimeFormatter } from '@js-joda/core'
import { jsDateToLocalDate } from 'common/data/date'
import {
  evaluate,
  extractValue,
  imperialToMetricMap,
  metricToImperialDisplayMap,
  metricToImperialMap,
  Unit,
  unitDisplay,
  unitEnumToUCUMUnitMap,
} from 'common/unitConverter/unitConverter'
import { isEmpty } from 'lodash'
import { UnitInput, WeightInput } from 'types/graphql'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { emptyPlaceholder } from 'src/lib/formatters'
import { VerticalDivider } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/BirthHistory'
import {
  useBirthHistoryQuery,
  VALUE_DISPLAY_PRECISION,
} from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'

import { UnitValueDisplay } from './BirthHistoryV2'

export const PatientSectionV2 = ({ patientId }: { patientId: string }) => {
  const { birthHistoryFormValues } = useBirthHistoryQuery(patientId)

  if (isEmpty(birthHistoryFormValues)) return null

  const {
    gestationalAgeAtBirth,
    birthWeight,
    birthHeadCircumference,
    birthLength,
    dischargeWeight,
    dischargeDate,
    lastTotalSerumBilirubin,
    totalSerumBilirubinDate,
  } = birthHistoryFormValues

  return (
    <StackView space={75}>
      <Typography color="text-base-color-fg-default" textStyle="title-xs">
        General
      </Typography>
      <StackView
        testId="birth-history-patient-section"
        direction="row"
        space={75}
      >
        <StackView direction="col" className="w-1/4" space={75}>
          <UnitValueDisplay
            label="Gestational age at birth"
            input={gestationalAgeAtBirth}
            displayAsInteger={true}
            direction="col"
          />
          <WeightDisplay label="Discharge weight" input={dischargeWeight} />
        </StackView>
        <VerticalDivider />
        <StackView direction="col" className="w-1/4" space={75}>
          <WeightDisplay label="Birth weight" input={birthWeight} />
          <ValueDisplay
            type="DATE"
            label="Discharge date"
            input={dischargeDate}
          />
        </StackView>
        <VerticalDivider />
        <StackView direction="col" className="w-1/4" space={75}>
          <UnitValueDisplay label="Birth length" input={birthLength} />
          <UnitValueDisplay
            label="Last total serum bilirubin"
            input={lastTotalSerumBilirubin}
            direction="col"
          />
        </StackView>
        <VerticalDivider />
        <StackView direction="col" className="w-1/4" space={75}>
          <UnitValueDisplay
            label="Birth head circumference"
            input={birthHeadCircumference}
          />
          <ValueDisplay
            type="DATE"
            label="Total serum bilirubin date"
            input={totalSerumBilirubinDate}
          />
        </StackView>
      </StackView>
    </StackView>
  )
}

type ValueType = 'WEIGHT' | 'DATE'

const ValueDisplay = ({
  type,
  label,
  input,
}: {
  type: ValueType
  label: string
  input: string | UnitInput | WeightInput
}) => {
  if (!input) {
    return (
      <StackView direction="col">
        <Typography color="text-base-color-fg-muted">{label}</Typography>
        <StackView space={25} direction="row">
          <Typography textStyle="interface-strong-m">
            {emptyPlaceholder}
          </Typography>
        </StackView>
      </StackView>
    )
  }

  return buildValueDisplay({ type, label, input })
}

const buildValueDisplay = ({
  type,
  label,
  input,
  precision = VALUE_DISPLAY_PRECISION,
}: {
  type: ValueType
  label: string
  input: string | UnitInput | WeightInput
  precision?: number
}) => {
  if (type === 'WEIGHT') {
    return (
      <WeightDisplay
        label={label}
        input={input as WeightInput}
        precision={precision}
      />
    )
  } else if (type === 'DATE') {
    return (
      <StackView direction="col">
        <Typography color="text-base-color-fg-muted">{label}</Typography>
        <Typography textStyle="interface-strong-s">
          {jsDateToLocalDate(input as Date).format(
            DateTimeFormatter.ofPattern('MM/dd/yyyy')
          )}
        </Typography>
      </StackView>
    )
  }
}

const WeightDisplay = ({
  label,
  input,
  precision = VALUE_DISPLAY_PRECISION,
}: {
  label: string
  input: WeightInput
  precision?: number
}) => {
  if (!input) return null

  const { wholeUnit } = input

  const isConvertibleWholeUnit =
    wholeUnit?.unit in metricToImperialMap ||
    wholeUnit?.unit in imperialToMetricMap

  if (!isConvertibleWholeUnit) {
    return (
      <Typography textStyle="interface-strong-s">
        {wholeUnit.value
          ? `${Number(wholeUnit.value).toFixed(precision)} ${
              unitDisplay[
                unitEnumToUCUMUnitMap[wholeUnit.unit] ?? wholeUnit.unit
              ]
            }`
          : emptyPlaceholder}
      </Typography>
    )
  }
  const wholeValueInImperial = wholeUnit?.value
    ? extractValue(
        evaluate(
          wholeUnit.value,
          wholeUnit.unit,
          metricToImperialMap[wholeUnit.unit] ?? wholeUnit.unit
        )
      )
    : null

  const wholeValueInMetric = wholeUnit?.value
    ? extractValue(
        evaluate(
          wholeUnit.value,
          wholeUnit.unit,
          imperialToMetricMap[wholeUnit.unit] ?? wholeUnit.unit
        )
      )
    : null

  const [beforeDecimal, afterDecimal] = (wholeValueInImperial ?? '').split('.')

  let splitUnit: Unit
  let afterDecimalValueInSplitUnit: string

  // only metric to imperial because only showing split display for g -> lbs, oz
  if (afterDecimal && wholeUnit.unit in metricToImperialDisplayMap) {
    splitUnit = metricToImperialDisplayMap[wholeUnit.unit].find(
      (unit) => unit !== metricToImperialMap[wholeUnit.unit]
    )
    afterDecimalValueInSplitUnit = extractValue(
      evaluate(
        `0.${afterDecimal}`,
        metricToImperialMap[wholeUnit.unit],
        splitUnit
      )
    )
  }

  return (
    <StackView direction="col">
      <Typography color="text-base-color-fg-muted">{label}</Typography>
      <StackView space={25} direction="row">
        <Typography textStyle="interface-strong-s">
          {wholeValueInImperial
            ? `${Number(beforeDecimal).toFixed(precision)} ${
                unitDisplay[
                  metricToImperialMap[wholeUnit.unit] ?? wholeUnit.unit
                ]
              } ${
                afterDecimalValueInSplitUnit
                  ? `${Number(afterDecimalValueInSplitUnit).toFixed(
                      precision
                    )} ${splitUnit}`
                  : ''
              }`
            : emptyPlaceholder}
        </Typography>
        <Typography
          textStyle="interface-default-xs"
          className="leading-snug"
          color="text-base-color-fg-subtle"
        >
          {wholeValueInMetric
            ? `(${Number(wholeValueInMetric).toFixed(precision)} ${
                unitDisplay[
                  imperialToMetricMap[wholeUnit.unit] ?? wholeUnit.unit
                ]
              })`
            : null}
        </Typography>
      </StackView>
    </StackView>
  )
}
