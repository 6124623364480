export interface WithIndicatorProps {
  children: React.ReactNode
  indicator: React.ReactNode
  showIndicator?: boolean
}

export const WithIndicator = ({
  children,
  indicator,
  showIndicator = true,
}: WithIndicatorProps) => (
  <div className="relative">
    {children}
    {showIndicator ? (
      <div className="absolute -right-core-size-12 top-core-size-0">
        {indicator}
      </div>
    ) : null}
  </div>
)
