import { useEmrAuth } from 'src/auth'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import TextareaAutoSaveSection from 'src/components/molecules/TextareaAutoSaveSection/TextareaAutoSaveSection'

import {
  useUpdateAppointmentDocumentationMutation,
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from '../../useVisit'

const SimplifiedDocumentationPage = () => {
  const { hasRole } = useEmrAuth()
  const { appointmentId } = useVisit()
  const { visit, loading } = useVisitQuery(appointmentId)
  useUpdateVisitFlowTabForAppointment('documentation')
  const [updateAppointmentDocumentation] =
    useUpdateAppointmentDocumentationMutation()
  if (!visit) return null

  return (
    <StackView space={100} className="max-w-3xl pt-4">
      <StackView space={50}>
        <Typography textStyle="heading">Visit documentation</Typography>
        <Typography textStyle="body" color="text-base-color-fg-muted">
          Complete visit summary documentation for the current visit.
        </Typography>
      </StackView>
      <TextareaAutoSaveSection
        testId="visit-summary"
        title="Visit summary"
        isRichText={true}
        macroPhraseSection="VISIT_SUMMARY"
        macroPhraseTypeaheadPosition="above"
        defaultValue={visit?.visitSummary}
        onChange={(value: string) =>
          updateAppointmentDocumentation({
            variables: {
              input: {
                visitId: visit.id,
                value: value,
                documentationType: 'VISIT_SUMMARY',
              },
            },
          })
        }
        loading={loading}
        disabled={
          visit.chartingStatus === 'COMPLETE' && !hasRole('PRACTITIONER')
        }
      />
    </StackView>
  )
}

export default SimplifiedDocumentationPage
