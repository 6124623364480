import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { ChargeItemModifier as ChargeItemModifierGQL } from 'types/graphql'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { Tooltip } from 'src/components/molecules/Tooltip/Tooltip'
import { chargeItemModifierDisplay } from 'src/data/chargeItemModifiers'

interface ChargeItemModifierProps {
  modifier: ChargeItemModifierGQL
}
const ChargeItemModifier: React.FC<ChargeItemModifierProps> = ({
  modifier,
}) => {
  const code = modifier.replace('MODIFIER_', '')
  return (
    <StackView direction="row" space={50} alignItems="center">
      <Typography>{code}</Typography>
      <Tooltip placement="right" text={chargeItemModifierDisplay[modifier]}>
        <InformationCircleIcon
          className="h-3 w-3 fill-gray-400"
          aria-hidden="true"
        />
      </Tooltip>
    </StackView>
  )
}

export default ChargeItemModifier
