import { XMarkIcon } from '@heroicons/react/20/solid'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/atoms/Button/Button'
import Modal from 'src/components/molecules/Modal/Modal'
import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'

const CANCEL = gql`
  mutation CancelPatientMembership($id: String!) {
    cancelPatientMembershipPlan(id: $id) {
      id
      endDate
    }
  }
`

export const CancelPatientMembershipButton = ({
  patientMembershipPlanId,
}: {
  patientMembershipPlanId: string
}) => {
  const [modalState, waitForConfirmation] = useConfirmation()
  const [cancelMembershipMutation] = useMutation(CANCEL)

  const cancelMembership = async () => {
    const { confirmed } = await waitForConfirmation()
    if (!confirmed) return

    await cancelMembershipMutation({
      variables: {
        id: patientMembershipPlanId,
      },
      onCompleted: () => {
        toast.success('Membership cancelled')
      },
      refetchQueries: ['ListMembershipsForPatient'],
    })
  }

  return (
    <>
      <Button
        icon={XMarkIcon}
        text="Cancel membership"
        buttonStyle="secondary"
        onClick={cancelMembership}
      />
      <Modal
        modalStyle="danger"
        isOpen={modalState.isConfirming}
        title="Cancel membership"
        content="Are you sure you want to cancel this membership? This cannot be undone."
        primaryButton={{
          text: 'Cancel membership',
          onClick: modalState.confirm,
        }}
        setIsOpen={(isOpen) => {
          if (!isOpen) {
            modalState.cancel()
          }
        }}
      />
    </>
  )
}
