import React, { useMemo } from 'react'

import { PencilIcon, PlusIcon } from '@heroicons/react/24/solid'
import { observationConcepts } from 'common/cdr/concepts/observations/index'
import {
  unitDisplay,
  unitEnumToUCUMUnitMap,
} from 'common/unitConverter/unitConverter'
import isEmpty from 'lodash/isEmpty'
import isPlainObject from 'lodash/isPlainObject'
import { useParams } from 'react-router-dom'
import {
  UpsertIntakeVitalsSummaryInput,
  UpsertPatientVitalsInput,
} from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import Divider from 'src/components/atoms/Divider'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { vitalLabels } from 'src/components/VisitIntakeVital'
import { emptyPlaceholder } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'
import { buildEmptyVitals } from 'src/pages/PatientChartsPage/PatientResults/SidepanelPatientVitalsAdd'
import {
  getVitalsForDate,
  accumulateUpsertVitalsInput,
} from 'src/pages/PatientChartsPage/PatientResults/SidepanelPatientVitalsEdit'
import { useVitalsQuery } from 'src/pages/PatientChartsPage/PatientResults/Vitals/useVitals'
import { NotRecordedIllustration } from 'src/pages/PatientChartsPage/PatientVisits/pages/IntakePage/IntakePage'
import {
  useVisit,
  useVisitQuery,
  useVisitVitalsQuery,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'

const areVitalsEmpty = (vitals) => {
  if (!vitals) return true
  return isEmpty(
    Object.keys(vitals).filter(
      (vital) =>
        !isEmpty(
          vitals[vital].value ??
            vitals[vital]['wholeUnit']?.value ??
            vitals[vital]['partialUnit']?.value
        )
    )
  )
}

const buildVitalsDependentButtonProps = (vitalsAreEmpty: boolean) => {
  return {
    text: vitalsAreEmpty ? 'Add' : 'Edit',
    icon: vitalsAreEmpty ? PlusIcon : PencilIcon,
  }
}

const Header = ({
  vitalsAreEmpty,
  encounterId,
}: {
  vitalsAreEmpty: boolean
  encounterId
}) => {
  const params = useParams()
  const location = useLocation()
  return (
    <StackView direction="row" justifyContent="between">
      <Typography textStyle={'title-s'}>Vitals</Typography>
      <Button
        data-testid="edit-vitals-button"
        buttonStyle="secondary"
        {...buildVitalsDependentButtonProps(vitalsAreEmpty)}
        onClick={() =>
          navigate(
            sidepanelRoute(
              { route: `/encounters/${encounterId}/vitals/edit` },
              location,
              params
            )
          )
        }
      />
    </StackView>
  )
}

export const PatientVitalsDisplay: React.FC = () => {
  const { appointmentId } = useVisit()
  const { visit: visitFromAppointment } = useVisitQuery(appointmentId)

  const { encounterId } = useParams()
  const { visit: visitFromEncounter } = useVisitVitalsQuery(encounterId)

  const visit = visitFromAppointment ?? visitFromEncounter

  const { vitalsByDateAndCode, loading: loadingVitals } = useVitalsQuery(
    visit?.patient?.id
  )

  const patientVitalsForVisitDate = getVitalsForDate(
    visit?.start,
    vitalsByDateAndCode
  )

  const persistedVisitIntakeVitals = useMemo(() => {
    return patientVitalsForVisitDate.reduce(
      (vitals, vital) => accumulateUpsertVitalsInput(vitals, vital),
      {} as UpsertIntakeVitalsSummaryInput
    )
  }, [patientVitalsForVisitDate])

  if (!visit?.encounter?.id) return null

  const vitalsAreEmpty = areVitalsEmpty(persistedVisitIntakeVitals)

  return (
    <StackView data-testid="patient-vitals" className="h-full" space={50}>
      <StackView space={100}>
        <Header
          vitalsAreEmpty={vitalsAreEmpty}
          encounterId={visit.encounter.id}
        />
        {loadingVitals ? (
          <Box className="content-center justify-center">
            <LoadingSpinner />
          </Box>
        ) : vitalsAreEmpty && vitalsByDateAndCode ? (
          <EmptyVitals />
        ) : vitalsByDateAndCode ? (
          <PatientVitalsTable
            vitals={{
              ...buildEmptyVitals(),
              ...persistedVisitIntakeVitals,
            }}
          />
        ) : null}
      </StackView>
    </StackView>
  )
}

const buildVitalDisplayRowContent = (vital) => {
  if (vital.systolic?.value && vital.diastolic?.value) {
    return (
      <Typography textStyle="body-s" color="text-base-color-fg-muted">
        {`${vital.systolic.value} / ${vital.diastolic.value} ${
          unitDisplay[unitEnumToUCUMUnitMap[vital.systolic.unit]]
        }`}
      </Typography>
    )
  } else if (vital.wholeUnit?.value || vital.partialUnit?.value) {
    return (
      <Typography textStyle="body-s" color="text-base-color-fg-muted">
        {`${vital.wholeUnit?.value || '0'} ${
          unitDisplay[vital.wholeUnit?.unit]
        } ${
          vital.partialUnit?.value
            ? `${vital.partialUnit?.value} ${
                unitDisplay[vital.partialUnit?.unit]
              }`
            : ''
        } `}
      </Typography>
    )
  } else if (!isEmpty(vital.value)) {
    return (
      <Typography textStyle="body-s" color="text-base-color-fg-muted">
        {`${vital.value || '0'} ${
          unitDisplay[vital.unit] ||
          unitDisplay[unitEnumToUCUMUnitMap[vital.unit]]
        }${vital.type ? ` • ${observationConcepts[vital.type].display}` : ''}
          `}
      </Typography>
    )
  } else {
    return emptyPlaceholder
  }
}

const buildVitalDisplayRow = (vital, testId: string) => {
  return (
    <StackView testId={testId} space={25} direction="row">
      {buildVitalDisplayRowContent(vital)}
    </StackView>
  )
}

export const PatientVitalsTable = ({
  vitals,
}: {
  vitals: UpsertIntakeVitalsSummaryInput | UpsertPatientVitalsInput
}) => {
  return (
    vitals && (
      <StackView space={100} className="rounded border p-core-space-100">
        {Object.keys(vitals).map((key, index) => {
          if (!isPlainObject(vitals[key])) {
            return null
          }
          return (
            <>
              {index > 0 ? <Divider /> : null}
              <StackView
                key={key}
                direction="row"
                space={50}
                justifyContent="between"
              >
                <Box className="w-1/2">
                  <Typography
                    textStyle="interface-strong-s"
                    color="text-base-color-fg-subtle"
                  >
                    {vitalLabels[key]}
                  </Typography>
                </Box>
                <StackView direction="row" space={25} justifyContent="start">
                  {buildVitalDisplayRow(vitals[key], key)}
                </StackView>
              </StackView>
            </>
          )
        })}
      </StackView>
    )
  )
}

const EmptyVitals = () => {
  const params = useParams()
  const location = useLocation()
  const { encounterId } = useParams()

  return (
    <StackView space={100} fullWidth>
      <StackView space={50} justifyContent="center" alignItems="center">
        <NotRecordedIllustration />
        <Typography textStyle="title-xs">No vitals recorded</Typography>
        <Typography textStyle="body-s" color="text-base-color-fg-muted">
          Please add visit vitals by clicking the button below.
        </Typography>
        <Button
          buttonStyle="secondary"
          text="Add"
          icon={PlusIcon}
          onClick={() =>
            navigate(
              sidepanelRoute(
                { route: `/encounters/${encounterId}/vitals/edit` },
                location,
                params
              )
            )
          }
        />
      </StackView>
    </StackView>
  )
}
