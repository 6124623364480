import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { pick, isEmpty } from 'lodash'
import type {
  FindScreeningToolQuery,
  FindScreeningToolQueryVariables,
  ScreeningToolType,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField/InputField'
import ScreeningToolTypeSelectField from 'src/components/atoms/ScreeningToolTypeSelectField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { QUERY as FIND_SCREENING_TOOLS_QUERY } from 'src/components/ScreeningToolCell'
import { screeningToolTypeDisplay } from 'src/data/screeningToolTypes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

import SidepanelForm from '../molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from '../molecules/SidepanelPage/SidepanelPage'

export const QUERY = FIND_SCREENING_TOOLS_QUERY

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindScreeningToolQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

const UPDATE_SCREENING_TOOL_MUTATION = gql`
  mutation UpdateScreeningTool(
    $id: String!
    $input: UpdateScreeningToolInput!
  ) {
    updateScreeningTool(id: $id, input: $input) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

type FormData = {
  type?: ScreeningToolType
  title?: string
  /**
   * A list of files (max length 1) or undefined. Useful for the user to upload a single file.
   *
   * This matches the type used by `FileInput`
   */
  files?: FileList
}

export const Success = ({
  screeningTool,
}: CellSuccessProps<
  FindScreeningToolQuery,
  FindScreeningToolQueryVariables
>) => {
  const { closeSidePanel } = useSidepanel()
  const [updateScreeningTool, { loading: updatingScreeningTool }] = useMutation(
    UPDATE_SCREENING_TOOL_MUTATION,
    {
      onCompleted: () => {
        toast.success(`Screening tool updated`)
        closeSidePanel()
      },
      refetchQueries: ['ScreeningToolsQuery'],
    }
  )
  const formMethods = useForm<FormData>({
    defaultValues: pick(screeningTool, 'type', 'title'),
    shouldUnregister: true,
  })
  const {
    formState: { dirtyFields },
  } = formMethods

  const hasDirtyFields = !isEmpty(dirtyFields)

  const onSubmit = async (value: FormData) => {
    const dirtyValues: FormData = Object.fromEntries(
      Object.entries(value).filter(([key, _]) => dirtyFields[key])
    )
    const { files, ...input } = dirtyValues
    if (files !== undefined && files.length === 1) {
      input['binary'] = {
        content: await base64EncodeFile(files[0]),
        contentType: files[0].type,
      }
    }

    updateScreeningTool({
      variables: { input, id: screeningTool.id },
    })
  }

  return (
    <SidepanelPage
      testId="screening-tool-edit"
      header={`Screening tool: ${screeningToolTypeDisplay[screeningTool.type]}`}
      subHeader={screeningTool.title}
      description={`Last modified on ${format(
        new Date(screeningTool.updatedAt),
        'PP'
      )} · Last modified by ${formatDisplayName(screeningTool.updatedBy)}`}
    >
      <SidepanelForm
        footerProps={{
          disabled: !hasDirtyFields,
          submitText: 'Save',
          submitting: updatingScreeningTool,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'type',
              label: 'Document sub-type',
              formInputComponent: ScreeningToolTypeSelectField,
            },
            {
              name: 'title',
              label: 'Document title',
              formInputComponent: InputField,
              required: false,
            },

            {
              name: 'files',
              label: 'Document upload',
              formInputComponent: FileInput,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}
