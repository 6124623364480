import { namePrefixDisplay } from 'common/data/namePrefixes'
import { nameSuffixDisplay } from 'common/data/nameSuffixes'
import { formatDisplayName } from 'common/utils'
import { parseISO } from 'date-fns'
import type {
  PatientDemographicsFragment,
  UpdatePatientInput,
} from 'types/graphql'

import InputField from 'src/components/atoms/InputField'
import NamePrefixSelectField from 'src/components/atoms/NamePrefixSelectField/NamePrefixSelectField'
import NameSuffixSelectField from 'src/components/atoms/NameSuffixSelectField/NameSuffixSelectField'
import SexSelectField from 'src/components/atoms/SexSelectField/SexSelectField'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import RelatedPersonSelectCell from 'src/components/RelatedPerson/RelatedPersonSelectCell'
import { sexDisplay } from 'src/data/sexes'
import { formatDateDisplay, formatDateFieldValue } from 'src/lib/formatters'

import { UPDATE_PATIENT_DEMOGRAPHICS_MUTATION } from '../usePatient'

const PatientCoreDemographicsCard = ({
  patient,
}: {
  patient: PatientDemographicsFragment
}) => {
  const preSubmit = (input: UpdatePatientInput) => {
    return { variables: { id: patient.id, input } }
  }

  return (
    <DataVerificationCard<UpdatePatientInput>
      title="Core demographics"
      updateMutation={UPDATE_PATIENT_DEMOGRAPHICS_MUTATION}
      preSubmit={preSubmit}
      editButtonTestId="patient-core-demographics-card-edit-button"
      defaultOpen
      data={[
        {
          name: 'namePrefix',
          label: 'Prefix',
          value: patient.namePrefix,
          displayValue: namePrefixDisplay[patient.namePrefix],
          formInputComponent: NamePrefixSelectField,
        },
        {
          name: 'givenName',
          label: 'First name',
          required: true,
          value: patient.givenName,
          formInputComponent: InputField,
        },
        {
          name: 'middleName',
          label: 'Middle name',
          value: patient.middleName,
          formInputComponent: InputField,
        },
        {
          name: 'familyName',
          label: 'Family name',
          required: true,
          value: patient.familyName,
          formInputComponent: InputField,
        },
        {
          name: 'nameSuffix',
          label: 'Suffix',
          value: patient.nameSuffix,
          displayValue: nameSuffixDisplay[patient.nameSuffix],
          formInputComponent: NameSuffixSelectField,
        },
        {
          name: 'preferredName',
          label: 'Preferred name',
          value: patient.preferredName,
          formInputComponent: InputField,
        },
        {
          name: 'birthDate',
          label: 'Date of birth',
          required: true,
          value: patient.birthDate,
          inputProps: {
            emptyAs: null,
            validation: {
              setValueAs: (value) => formatDateFieldValue(value),
              validate: (value) => {
                return parseISO(value) > new Date()
                  ? 'Date of birth must be in the past'
                  : null
              },
            },
          },
          displayValue: formatDateDisplay(patient.birthDate),
          formInputComponent: DatePickerField,
        },
        {
          name: 'sexAtBirth',
          label: 'Sex at birth',
          required: true,
          value: patient.sexAtBirth,
          displayValue: sexDisplay[patient.sexAtBirth],
          formInputComponent: SexSelectField,
        },
        {
          name: 'primaryGuardianId',
          label: 'Primary guardian',
          hide: !!patient.caregiverOptedOutAt,
          hideFormInput: !!patient.caregiverOptedOutAt,
          required: true,
          value: patient.primaryGuardian?.id,
          inputProps: {
            patientId: patient.id,
          },
          displayValue: formatDisplayName(patient.primaryGuardian),
          formInputComponent: RelatedPersonSelectCell,
        },
        {
          name: 'primaryGuarantorId',
          label: 'Primary guarantor',
          hide: !!patient.caregiverOptedOutAt,
          hideFormInput: !!patient.caregiverOptedOutAt,
          required: true,
          value: patient.primaryGuarantor?.id,
          inputProps: {
            patientId: patient.id,
          },
          displayValue: formatDisplayName(patient.primaryGuarantor),
          formInputComponent: RelatedPersonSelectCell,
        },
      ]}
    />
  )
}

export default PatientCoreDemographicsCard
