import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Dropdown from 'src/components/atoms/Dropdown/Dropdown'
import { sidepanelRoute } from 'src/lib/routes'

export const PrintVisitDocumentsDropdown = ({
  visitId,
  hasAdolescentConfidentialInfo,
}: {
  visitId: string
  hasAdolescentConfidentialInfo: boolean
}) => {
  const location = useLocation()
  const params = useParams()

  const options = hasAdolescentConfidentialInfo
    ? [
        {
          value: 'adolescent-confidential-heading',
          heading: true,
          disabled: true,
          name: 'Adolescent confidential',
        },
        {
          value: 'print-visit-note-adolescent-confidential',
          path: 'print-visit-note',
          name: 'Print confidential note',
          hideAdolescentConfidentialInfo: false,
        },
        {
          value: 'print-avs-adolescent-confidential',
          path: 'print-avs',
          name: 'Print confidential AVS',
          hideAdolescentConfidentialInfo: false,
        },
        {
          value: 'general-heading',
          heading: true,
          disabled: true,
          name: 'General',
        },
        {
          value: 'print-visit-note-general',
          path: 'print-visit-note',
          name: 'Print note',
          hideAdolescentConfidentialInfo: true,
        },
        {
          value: 'print-avs-general',
          path: 'print-avs',
          name: 'Print AVS',
          hideAdolescentConfidentialInfo: true,
        },
      ]
    : [
        {
          value: 'print-visit-note',
          path: 'print-visit-note',
          name: 'Print note',
          hideAdolescentConfidentialInfo: true,
        },
        {
          value: 'print-avs',
          path: 'print-avs',
          name: 'Print AVS',
          hideAdolescentConfidentialInfo: true,
        },
      ]

  return (
    <Dropdown
      name="print-visit-documents"
      text="Print"
      options={options}
      onSelect={(value) => {
        const option = options.find((opt) => opt.value === value)

        navigate(
          sidepanelRoute(
            {
              route: `/visit/${visitId}/${option.path}`,
              width: 'medium',
              overlay: true,
              hideAdolescentConfidentialInfo:
                option.hideAdolescentConfidentialInfo,
            },
            location,
            params
          )
        )
      }}
    />
  )
}
