import { assertUnreachable } from 'common/utils'
import { MedicationIframeNotAvailableReason } from 'types/graphql'

import EmptyDataContent from '../molecules/EmptyDataContent'
import PatientNotInDoctorFirst from '../molecules/PatientNotInDoctorFirst/PatientNotInDoctorFirst'

const MedicationIframeResponseError = ({
  notAvailableReason,
  patientId,
  refetch,
}: {
  notAvailableReason: MedicationIframeNotAvailableReason
  patientId?: string
  refetch?: () => void
}) => {
  switch (notAvailableReason) {
    case 'USER_ACCOUNT_MISSING':
      return (
        <EmptyDataContent
          message="No Account"
          details="You don't have an account with DoctorFirst. Please contact support to get an account provisioned."
        />
      )
    case 'INCORRECT_CONFIGURATION_FOR_TENANT':
      return (
        <EmptyDataContent
          message="Incorrect Configuration"
          details="Your practice does not have the correct configuration for DoctorFirst. Please contact support."
        />
      )
    case 'PATIENT_NOT_FOUND':
      return <PatientNotInDoctorFirst patientId={patientId} refetch={refetch} />
    case 'OTHER':
      return (
        <EmptyDataContent
          message="Something went wrong"
          details="An error occurred while loading the DoctorFirst iframe. Please try again later."
        />
      )
    default:
      assertUnreachable(notAvailableReason)
  }
}

export default MedicationIframeResponseError
