import { useRef } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { format, parseISO } from 'date-fns'
import sortBy from 'lodash/sortBy'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'
import {
  allergySeverityDisplay,
  allergySeverityBadgeColor,
} from 'src/data/allergySeverities'
import {
  allergyStatusBadgeColor,
  allergyStatusDisplay,
} from 'src/data/allergyStatuses'
import { sidepanelRoute } from 'src/lib/routes'

import { useAllergiesQuery } from './useAllergies'

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'cursor-pointer',
  resizable: true,
  minWidth: 180,
}

const columnDefs: ColDef[] = [
  {
    colId: 'name',
    headerName: 'Name',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center">
          <Typography
            className="overflow-hidden overflow-ellipsis"
            textStyle="subtitle"
            size="m"
            fontWeight="semibold"
          >
            {data.allergen.name}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'category',
    headerName: 'Category',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center">
          <Typography className="capitalize">
            {data.allergen.category.join(' / ')}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'status',
    headerName: 'Status',
    minWidth: 120,
    width: 120,
    cellRenderer: ({ data }) => {
      return (
        <StackView justifyContent="center" fullWidth={false}>
          <Badge
            size="l"
            text={allergyStatusDisplay[data.status]}
            color={allergyStatusBadgeColor[data.status]}
          />
        </StackView>
      )
    },
  },
  {
    colId: 'manifestation',
    headerName: 'Manifestation',
    cellRenderer: ({ data }) => {
      if (!data.manifestation) return null

      return (
        <StackView className="h-full" justifyContent="center">
          <Typography>{data.manifestation.display}</Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'severity',
    headerName: 'Severity',
    minWidth: 120,
    width: 120,
    cellRenderer: ({ data }) => {
      if (!data.severity) return null

      return (
        <StackView justifyContent="center" fullWidth={false}>
          <Badge
            size="l"
            text={allergySeverityDisplay[data.severity]}
            color={allergySeverityBadgeColor[data.severity]}
          />
        </StackView>
      )
    },
  },
  {
    colId: 'lastUpdated',
    headerName: 'Last updated',
    cellRenderer: ({ data }) => {
      return (
        <StackView className="h-full" justifyContent="center">
          <Typography>
            {format(parseISO(data.updatedAt), 'MM/dd/yyyy')}
          </Typography>
        </StackView>
      )
    },
  },
]

const AllergiesTable = ({ patientId }: { patientId: string }) => {
  const gridRef = useRef<AgGridReact>()
  const { allergies } = useAllergiesQuery(patientId)
  const params = useParams()
  const location = useLocation()

  const rowData = sortBy(allergies, [
    (a) => ['active', 'inactive', 'resolved'].indexOf(a.status),
    (a) => ['severe', 'moderate', 'mild'].indexOf(a.severity),
  ])

  return (
    <Table
      testId="allergies-table"
      innerRef={gridRef}
      rowData={rowData}
      onRowClicked={(e) =>
        navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/allergies/${e.data.allergen.code}`,
            },
            location,
            params
          )
        )
      }
      domLayout="autoHeight"
      rowHeight={50}
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      noRowsOverlayComponentParams={{ message: 'No allergies recorded' }}
    />
  )
}

export default AllergiesTable
