import type { WeekdayKey } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const weekdayDisplay: {
  [key in WeekdayKey]: string
} = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
}

export const weekdays = nameValueArrayFromObject<WeekdayKey>(weekdayDisplay)
