import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useParams } from 'react-router-dom'

import { navigate, routes, useParams as useRWParams } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'
import SubmitClaimLoadingModal from 'src/components/SubmitClaimLoadingModal/SubmitClaimLoadingModal'

const SUBMIT_BILLING = gql`
  mutation SubmitHospitalVisitBilling($id: String!) {
    submitHospitalVisitBilling(id: $id) {
      id
    }
  }
`

export const HospitalVisitSubmitBillingButton = () => {
  const { id } = useRWParams()
  const { hospitalVisitId } = useParams()
  const [submitBilling, { loading }] = useMutation(SUBMIT_BILLING)

  return (
    <>
      <PopoverButton
        buttonProps={{
          text: 'Submit billing codes',
          buttonStyle: 'primary',
          iconPlacement: 'right',
          icon: ChevronDownIcon,
          testId: 'hospital-visit-attempt-submit-billing-btn',
        }}
        renderPanel={({ close }) => (
          <StackView space={25} className="p-4">
            <Typography textStyle="heading">
              Ready to submit billing codes?
            </Typography>
            <Typography textStyle="description">
              Once finalized visit billing codes are submitted, you will no
              longer be able to add, remove, or modify billing codes for the
              visit. Both billing codes linked to new orders and diagnosis
              updates will no longer show up in this billing section.
            </Typography>
            <StackView
              direction="row"
              justifyContent="end"
              space={50}
              className="py-4"
            >
              <Button
                text="Cancel and review"
                onClick={close}
                buttonStyle="secondary"
              />
              <Button
                testId="hospital-visit-confirm-submit-billing-btn"
                text="Submit billing codes"
                buttonStyle="primary"
                loading={loading}
                onClick={() => {
                  submitBilling({
                    variables: {
                      id: hospitalVisitId,
                    },
                    onCompleted: () => {
                      toast.success('Billing submitted')
                      navigate(
                        routes.patientChartsGlob({
                          id,
                          glob: 'visits',
                        })
                      )
                    },
                  })
                }}
              />
            </StackView>
          </StackView>
        )}
      />
      <SubmitClaimLoadingModal loading={loading} />
    </>
  )
}
