import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
} from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/solid'
import { format, parseISO } from 'date-fns'
import { FindPatientVisit } from 'types/graphql'

import { Link, useParams } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import {
  useUpdateVisitClinicalReviewMutation,
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'

type ReviewBoxProps = {
  title: string
  reviewKey: string
  link: string
  top?: boolean
  bottom?: boolean
  onReviewSection: (key: string) => void
  visitData: FindPatientVisit['appointment']
}

const ReviewBox = ({
  title,
  reviewKey,
  link,
  top = false,
  bottom = false,
  onReviewSection,
  visitData,
}: ReviewBoxProps) => {
  const reviewedAt = visitData[`${reviewKey}ReviewedAt`]
  const reviewedBy = visitData[`${reviewKey}ReviewedBy`]
  return (
    <Box
      border
      padding={75}
      className={top ? 'rounded-t-lg' : bottom ? 'rounded-b-lg' : ''}
    >
      <StackView direction="row" alignItems="center">
        <StackView>
          <Typography textStyle="title">{title}</Typography>
          {reviewedAt ? (
            <Typography color="text-base-color-fg-muted">
              Last reviewed on {format(parseISO(reviewedAt), 'MMM d, yyyy')} by{' '}
              {reviewedBy?.givenName} {reviewedBy?.familyName}
            </Typography>
          ) : (
            <Typography color="text-base-color-fg-muted">
              Never reviewed
            </Typography>
          )}
        </StackView>
        <StackView className="w-min" direction="row">
          <Link to={link} target="_blank">
            <Button
              className="w-min whitespace-nowrap"
              buttonStyle="link"
              icon={ArrowTopRightOnSquareIcon}
              text={`View ${title.toLowerCase()}`}
            />
          </Link>

          {reviewedAt ? (
            <Button
              className="w-min whitespace-nowrap"
              buttonStyle="secondary"
              onClick={() => onReviewSection(reviewKey)}
              testId={`review-${reviewKey}`}
              icon={CheckCircleIcon}
              iconColor="text-base-color-fg-success"
              text="Reviewed"
            />
          ) : (
            <Button
              className="w-min whitespace-nowrap"
              buttonStyle="secondary"
              onClick={() => onReviewSection(reviewKey)}
              testId={`review-${reviewKey}`}
              icon={CheckCircleIcon}
              text="Reviewed"
            />
          )}
        </StackView>
      </StackView>
    </Box>
  )
}

const ClinicalReviewPage = () => {
  const { id: patientId } = useParams()
  const { appointmentId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)

  useUpdateVisitFlowTabForAppointment('clinical')

  const allReviewKeys = [
    'immunizations',
    'growthCharts',
    'history',
    'results',
    'documents',
  ]

  const [updateVisitClinicalReview] = useUpdateVisitClinicalReviewMutation()

  const onReview = (key) => {
    updateVisitClinicalReview({
      variables: {
        input: {
          appointmentId: visit.id,
          keysToUpdate: [key],
        },
      },
      onCompleted: () => {
        toast.success('Section reviewed')
      },
    })
  }

  const onReviewAll = () => {
    updateVisitClinicalReview({
      variables: {
        input: {
          appointmentId: visit.id,
          keysToUpdate: allReviewKeys,
        },
      },
      refetchQueries: ['FindPatientVisit'],
      onCompleted: () => {
        toast.success('All sections reviewed')
      },
    })
  }

  return (
    <StackView space={100} className="max-w-3xl pt-4">
      <StackView direction="row" justifyContent="between" alignItems="start">
        <StackView>
          <Typography textStyle="heading">Clinical review</Typography>
          <Typography textStyle="body" color="text-base-color-fg-muted">
            When you are complete, mark each section as reviewed.
          </Typography>
        </StackView>
        <StackView justifyContent="end" fullWidth={false}>
          <Button
            className="w-min whitespace-nowrap"
            buttonStyle="secondary"
            onClick={() => onReviewAll()}
            testId="review-all"
            icon={CheckIcon}
            text="Mark all as reviewed"
          />
        </StackView>
      </StackView>

      <Box rounded border>
        <ReviewBox
          top
          title="History"
          reviewKey="history"
          link={`/patient-charts/${patientId}/history`}
          onReviewSection={(key) => onReview(key)}
          visitData={visit}
        />
        <ReviewBox
          title="Results"
          reviewKey="results"
          link={`/patient-charts/${patientId}/results`}
          onReviewSection={(key) => onReview(key)}
          visitData={visit}
        />
        <ReviewBox
          title="Immunization"
          reviewKey="immunizations"
          link={`/patient-charts/${patientId}/immunization`}
          onReviewSection={(key) => onReview(key)}
          visitData={visit}
        />
        <ReviewBox
          title="Growth charts"
          reviewKey="growthCharts"
          link={`/patient-charts/${patientId}/growth-chart`}
          onReviewSection={(key) => onReview(key)}
          visitData={visit}
        />
        <ReviewBox
          bottom
          title="Documents"
          reviewKey="documents"
          link={`/patient-charts/${patientId}/documents`}
          onReviewSection={(key) => onReview(key)}
          visitData={visit}
        />
      </Box>
    </StackView>
  )
}

export default ClinicalReviewPage
