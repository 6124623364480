import { useParams } from '@redwoodjs/router'

import Typography from 'src/components/atoms/Typography/Typography'
import { PatientSiblings } from 'src/components/organisms/PatientSiblings/PatientSiblings'

import { usePatientDemographicsQuery } from '../usePatient'

const SiblingsTab = () => {
  const { id } = useParams()

  const { patient } = usePatientDemographicsQuery(id)

  if (!patient) return null

  if (!patient.siblings?.length) {
    return (
      <Typography textStyle="title">This patient has no siblings.</Typography>
    )
  }

  return <PatientSiblings patient={patient} />
}

export default SiblingsTab
