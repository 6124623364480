import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'

import { navigate, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

import {
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from '../../useVisit'

import VaccineAdministrationOrderTable from './VaccineAdministrationOrderTable'

const VaccineAdministrationPage = () => {
  const { id: patientId } = useParams()
  const { appointmentId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)

  useUpdateVisitFlowTabForAppointment('vaccines')

  return (
    <>
      <StackView
        direction="row"
        justifyContent="between"
        alignItems="start"
        className="pt-4"
      >
        <StackView>
          <Typography textStyle="heading">Vaccine administration</Typography>
          <Typography textStyle="body" color="text-base-color-fg-muted">
            Document administration of vaccines against pended or active signed
            vaccine orders for this visit. Vaccines can only be administered if
            there are unexpired doses available at the visit clinic location.
          </Typography>
        </StackView>
        <StackView justifyContent="end" fullWidth={false}>
          <Button
            className="w-min whitespace-nowrap hover:text-gray-700"
            buttonStyle="link"
            onClick={() => {
              navigate(`/patient-charts/${patientId}/visits/${visit.id}/orders`)
            }}
            text="View orders"
            icon={ArrowTopRightOnSquareIcon}
          />
        </StackView>
      </StackView>

      <StackView className="pt-4">
        <VaccineAdministrationOrderTable />
      </StackView>
    </>
  )
}

export default VaccineAdministrationPage
