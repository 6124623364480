import { useParams } from '@redwoodjs/router'

import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView/StackView'

import { usePatientDemographicsQuery } from '../usePatient'

import InsuranceOptOutCard from './InsuranceOptOutCard'
import InsurancesTabs from './InsurancesTabs'

const InsurancesTab = () => {
  const { id } = useParams()
  const { patient } = usePatientDemographicsQuery(id)

  if (!patient) return null

  return (
    <StackView space={75} className="max-w-2xl">
      <InsuranceOptOutCard patient={patient} />
      <Divider />
      <InsurancesTabs patient={patient} />
    </StackView>
  )
}

export default InsurancesTab
