import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react'

import clsx from 'clsx'

import Box from 'src/components/atoms/Box'

export interface StackViewProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children: ReactNode
  direction?: 'col' | 'row'
  wrap?: boolean
  reverse?: boolean
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch'
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly'
  space?: 0 | 25 | 50 | 75 | 100 | 125 | 150 | 175 | 200 | 2400
  gap?: 0 | 25 | 50 | 75 | 100 | 125 | 150 | 175 | 200 | 2400
  divider?: boolean
  dividerColor?: string
  fullWidth?: boolean
  className?: string
  testId?: string
}

const alignItemsMapping = {
  start: 'items-start',
  end: 'items-end',
  center: 'items-center',
  baseline: 'items-baseline',
  stretch: 'items-stretch',
}
const justifyContentMapping = {
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
}
const spaceMapping = {
  col: {
    0: 'space-y-core-space-0',
    25: 'space-y-core-space-25',
    50: 'space-y-core-space-50',
    75: 'space-y-core-space-75',
    100: 'space-y-core-space-100',
    125: 'space-y-core-space-125',
    150: 'space-y-core-space-150',
    175: 'space-y-core-space-175',
    200: 'space-y-core-space-200',
    2400: 'space-y-core-space-2400',
  },
  row: {
    0: 'space-x-core-space-0',
    25: 'space-x-core-space-25',
    50: 'space-x-core-space-50',
    75: 'space-x-core-space-75',
    100: 'space-x-core-space-100',
    125: 'space-x-core-space-125',
    150: 'space-x-core-space-150',
    175: 'space-x-core-space-175',
    200: 'space-x-core-space-200',
    2400: 'space-x-core-space-2400',
  },
}
const gapMapping = {
  0: 'gap-y-core-space-0 gap-x-core-space-0',
  25: 'gap-y-core-space-25 gap-x-core-space-25',
  50: 'gap-y-core-space-50 gap-x-core-space-50',
  75: 'gap-y-core-space-75 gap-x-core-space-75',
  100: 'gap-y-core-space-100 gap-x-core-space-100',
  125: 'gap-y-core-space-125 gap-x-core-space-125',
  150: 'gap-y-core-space-150 gap-x-core-space-150',
  175: 'gap-y-core-space-175 gap-x-core-space-175',
  200: 'gap-y-core-space-200 gap-x-core-space-200',
  2400: 'gap-y-core-space-2400 gap-x-core-space-2400',
}

const StackView = ({
  children,
  direction = 'col',
  reverse = false,
  fullWidth = true,
  space,
  gap,
  alignItems = 'stretch',
  wrap = false,
  divider = false,
  dividerColor = 'divide-base-color-border-subtle',
  justifyContent = 'start',
  className,
  testId,
  ...rest
}: StackViewProps) => {
  const classes = [
    'flex',
    direction === 'col' ? 'flex-col' : 'flex-row',
    direction === 'col' && reverse && 'flex-col-reverse',
    direction === 'row' && reverse && 'flex-row-reverse',
    alignItemsMapping[alignItems],
    justifyContentMapping[justifyContent],
    direction === 'col' && divider
      ? `divide-y ${dividerColor}`
      : direction === 'row' && divider
        ? `divide-x ${dividerColor}`
        : '',
    fullWidth && 'w-full',
    wrap && 'flex-wrap',
    space !== undefined && spaceMapping[direction][space],
    gap !== undefined && gapMapping[gap],
    className ?? '',
  ]
  return (
    <div className={clsx(classes)} data-testid={testId} {...rest}>
      {Array.isArray(children)
        ? children.map((child, i) => {
            if (direction === 'col' && child?.type?.displayName === 'Box')
              return <Box key={i} {...child.props} isCol />
            return child
          })
        : children}
    </div>
  )
}

export default StackView
