import { useMemo, useRef } from 'react'

import {
  CheckCircleIcon,
  EyeIcon,
  PencilIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'
import { ColDef, RowClickedEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import {
  Interpretation as GraphQLInterpretation,
  OverallQuestionnaireResult,
} from 'types/graphql'
import { Order, OrderWithObservationsFragment } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table'
import { hearingScreeningCodes } from 'src/components/Order/CompleteHearingScreeningOrderSidepanelForm/HearingScreeningTable'
import { questionnaireCodes } from 'src/components/Order/CompleteQuestionnaireOrderSidepanelForm/Questionnaire'
import { visionScreeningCodes } from 'src/components/Order/CompleteVisionScreeningOrderSidepanelForm/VisionScreeningTable'
import {
  interpretationDisplay,
  interpretationToColor,
} from 'src/data/interpretations'
import { sidepanelRoute } from 'src/lib/routes'
import {
  usePatientOrdersWithObservationsQuery,
  isRelevantForEncounter,
} from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

const defaultColDef: ColDef = {
  cellStyle: { binHouseTest: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'cursor-pointer',
  resizable: false,
}

const orderHasResult = (order: OrderWithObservationsFragment) => {
  return orderHasOverallResult(order) || orderHasDetailedResult(order)
}

const orderHasOverallResult = (order: OrderWithObservationsFragment) => {
  return !isEmpty(order.observations)
}

const orderHasDetailedResult = (order: OrderWithObservationsFragment) => {
  return (
    !isEmpty(order.questionnaireResults) ||
    !isEmpty(order.resultsForQuestionnaires)
  )
}

const orderIsQuestionnaire = (order: OrderWithObservationsFragment) => {
  return order.category === 'SCN' && questionnaireCodes.includes(order.code)
}

export const getOrderResultsRoute = ({
  order,
  encounterId,
  route,
}: {
  order: OrderWithObservationsFragment
  encounterId: string
  route?: 'edit' | 'view'
}) => {
  if (order.diagnosticReport) {
    return `/documents/diagnostic-report/${order.diagnosticReport.id}`
  }

  const isInHouseTestOrder = order.category === 'IHT'
  const isScreeningOrder = order.category === 'SCN'

  const isQuestionnaire =
    isScreeningOrder && questionnaireCodes.includes(order.code)
  route = route
    ? route
    : orderIsQuestionnaire(order) && orderHasDetailedResult(order)
      ? 'view'
      : 'edit'
  const isVisionScreening =
    isScreeningOrder && visionScreeningCodes.includes(order.code)
  const isHearingScreening =
    isScreeningOrder && hearingScreeningCodes.includes(order.code)

  const screeningsPath = isQuestionnaire
    ? 'questionnaires'
    : isInHouseTestOrder
      ? 'inHouseTests'
      : isVisionScreening
        ? 'visionScreenings'
        : isHearingScreening
          ? 'hearingScreenings'
          : 'screenings'

  return `/encounters/${encounterId}/${screeningsPath}/${order.id}/${route}`
}

const EditScreeningsAction = ({
  order,
  encounterId,
}: {
  order: Order
  encounterId?: string
}) => {
  const params = useParams()
  const location = useLocation()

  const orderIsCompleted = order.status === 'COMPLETED'
  const resultExists = orderHasResult(order)
  const orderIsRevoked = order.status === 'REVOKED'

  return (
    <Button
      buttonStyle="secondary"
      icon={
        orderIsCompleted && resultExists
          ? PencilIcon
          : orderIsRevoked
            ? EyeIcon
            : PlusIcon
      }
      text={
        orderIsCompleted && resultExists
          ? 'Edit'
          : orderIsRevoked
            ? 'View'
            : 'Result'
      }
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          navigate(
            sidepanelRoute(
              {
                route: getOrderResultsRoute({
                  order,
                  encounterId,
                  route: orderIsCompleted && resultExists ? 'edit' : undefined,
                }),
              },
              location,
              params
            )
          )
        }
      }}
    />
  )
}

const ScreeningCompletionStatus = ({ order }: { order: Order }) => {
  return (
    <StackView justifyContent="center" fullWidth={false}>
      {order.status === 'COMPLETED' ? (
        <CheckCircleIcon
          className="h-6 w-6 fill-success"
          data-testid="screening-order-completion-checkmark"
        />
      ) : order.status === 'REVOKED' ? (
        <XCircleIcon
          className="h-6 w-6 fill-danger"
          data-testid="screening-order-refused-checkmark"
        />
      ) : (
        <CheckCircleIcon
          className="h-6 w-6 fill-gray-300"
          data-testid="screening-order-default-checkmark"
        />
      )}
    </StackView>
  )
}

const normalInterpretations: GraphQLInterpretation[] = [
  'NORMAL',
  'ASQ_ON_SCHEDULE',
]

const isEveryOverallResultWithInterpretationNormal = (
  overallQuestionnaireResults: OverallQuestionnaireResult[]
) => {
  if (!overallQuestionnaireResults) return false

  return overallQuestionnaireResults
    .filter(
      (result: OverallQuestionnaireResult) =>
        result.score !== null && result.interpretation
    )
    .every((result: OverallQuestionnaireResult) =>
      normalInterpretations.includes(result.interpretation)
    )
}

export const Interpretation = ({ order }) => {
  const orderIsPended =
    order.intent === 'PROPOSAL' && order.status !== 'REVOKED'

  const orderResultInterpretation = order.resultInterpretation

  const questionnaireTotalScoreInterpretation =
    order.questionnaireResults?.overall?.find(
      (result: OverallQuestionnaireResult) => result.isForTotal
    )?.interpretation

  const overallInterpretation: GraphQLInterpretation = orderResultInterpretation
    ? orderResultInterpretation
    : questionnaireTotalScoreInterpretation
      ? questionnaireTotalScoreInterpretation
      : isEveryOverallResultWithInterpretationNormal(
            order.questionnaireResults?.overall
          )
        ? 'NORMAL'
        : undefined

  return (
    <StackView
      key={order.id}
      alignItems="end"
      justifyContent="center"
      space={25}
      fullWidth={false}
    >
      {overallInterpretation && (
        <Badge color={interpretationToColor[overallInterpretation]}>
          {interpretationDisplay[overallInterpretation]}
        </Badge>
      )}
      {orderIsPended && (
        <Badge color={'yellow'}>
          {orderIsPended
            ? 'Pended'
            : interpretationDisplay[overallInterpretation]}
        </Badge>
      )}
    </StackView>
  )
}

export const ScreeningDetails = ({ order }: { order: Order }) => {
  return (
    <StackView space={25} className="truncate">
      <Typography textStyle="title" className="truncate">
        {order.codeDisplay}
      </Typography>
      <StackView direction="row" space={50} alignItems="center">
        <Typography textStyle="description">{order.name}</Typography>
        <Typography textStyle="description">&#xB7;</Typography>
        <Typography textStyle="description">
          {format(new Date(order.createdAt), 'MMM dd, yyyy')}
        </Typography>
      </StackView>
    </StackView>
  )
}

const columnDefs: ColDef[] = [
  {
    colId: 'order',
    comparator(_valueA, _valueB, nodeA, nodeB) {
      const a = new Date(nodeA.data.createdAt)
      const b = new Date(nodeB.data.createdAt)

      if (a < b) return -1
      if (a > b) return 1
      return 0
    },
    sortable: true,
    sort: 'desc',
    cellRenderer: ({
      data,
      context,
    }: {
      data
      context: Record<string, string>
    }) => {
      return (
        <StackView justifyContent="between" direction="row" space={100}>
          <ScreeningCompletionStatus order={data} />
          <ScreeningDetails order={data} />
          <Interpretation order={data} />
          <StackView
            direction="row"
            fullWidth={false}
            justifyContent="center"
            alignItems="center"
            gap={50}
          >
            <EditScreeningsAction
              order={data}
              encounterId={context.encounterId}
            />
          </StackView>
        </StackView>
      )
    },
  },
]

const ScreeningsTable = ({
  testId,
  patientId,
  encounterId,
  emptyMessage = 'No Screenings to display',
  pagination = false,
  onRowClicked,
}: {
  testId?: string
  patientId: string
  encounterId?: string
  emptyMessage?: string
  pagination?: boolean
  onRowClicked?: (e: RowClickedEvent<Order>) => void
}) => {
  const gridRef = useRef<AgGridReact>()

  const { ordersWithObservations } =
    usePatientOrdersWithObservationsQuery(patientId)

  const screenings = useMemo(
    () =>
      ordersWithObservations?.filter(
        (order) =>
          order.category === 'SCN' && isRelevantForEncounter(encounterId, order)
      ),
    [encounterId, ordersWithObservations]
  )

  return (
    <Table
      testId={testId ?? 'screening-tests-table'}
      innerRef={gridRef}
      rowData={screenings}
      onRowClicked={onRowClicked}
      domLayout="autoHeight"
      rowHeight={76}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={pagination}
      noRowsOverlayComponentParams={{ message: emptyMessage }}
      context={{ encounterId }}
      hideHeader
    />
  )
}

export default ScreeningsTable
