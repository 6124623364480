import { PlusIcon } from '@heroicons/react/20/solid'

import { useLocation, useParams, navigate } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { sidepanelRoute } from 'src/lib/routes'

import HistoricalPatientChargesTable from './HistoricalPatientChargesTable'

export const PatientChargesSection = ({
  patientId,
  appointmentId,
  subtitle,
}: {
  patientId: string
  appointmentId?: string
  subtitle: string
}) => {
  const params = useParams()
  const location = useLocation()

  return (
    <StackView gap={100}>
      <StackView direction="row" alignItems="center">
        <StackView space={25}>
          <Typography textStyle="title-s" className="pt-core-space-100">
            Charges
          </Typography>
          <Typography textStyle="body-s" color="text-base-color-fg-muted">
            {subtitle}
          </Typography>
        </StackView>
        <Button
          testId="add-patient-charge"
          buttonStyle="secondary"
          buttonSize="m"
          icon={PlusIcon}
          text="Add"
          onClick={() => {
            navigate(
              sidepanelRoute(
                {
                  route: `/patients/${patientId}/charges/add`,
                  overlay: true,
                  appointmentId,
                },
                location,
                params
              )
            )
          }}
        />
      </StackView>
      <HistoricalPatientChargesTable
        patientId={patientId}
        appointmentId={appointmentId}
      />
    </StackView>
  )
}
