import { ArrowsRightLeftIcon } from '@heroicons/react/20/solid'
import { CheckIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import MenuButton from 'src/components/molecules/MenuButton/MenuButton'

interface Option {
  code: string
  description: string
  id: string
}

interface SwapButtonProps {
  selectedOptionId: string
  options: Option[]
  swap: (option: Option) => void
}

const SwapButton: React.FC<SwapButtonProps> = ({
  selectedOptionId,
  options,
  swap,
}) => (
  <MenuButton
    options={options}
    buttonProps={{
      icon: ArrowsRightLeftIcon,
      text: 'Swap',
      buttonStyle: 'secondary',
    }}
    renderOption={({ option }) => (
      <SwapOption
        option={option}
        selected={option.id === selectedOptionId}
        onClick={() => {
          swap(option)
        }}
      />
    )}
  />
)

const SwapOption: React.FC<{
  option: Option
  selected: boolean
  onClick: () => void
}> = ({ option, selected, onClick }) => {
  return (
    <StackView
      onClick={onClick}
      direction="row"
      className={clsx('p-2', selected && 'bg-green-50')}
      space={50}
    >
      <Typography
        fontWeight={selected ? 'medium' : 'normal'}
        className="shrink-0 basis-12"
      >
        {option.code}
      </Typography>
      <Typography textStyle="description">{option.description}</Typography>
      <Box className="h-100 flex shrink-0 basis-5 items-center">
        {selected && <CheckIcon className="h-5 w-5 text-primary" />}
      </Box>
    </StackView>
  )
}

export default SwapButton
