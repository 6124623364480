import { isEmpty } from 'lodash'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { emptyPlaceholder } from 'src/lib/formatters'
import { useBirthHistoryQuery } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'

const Row = ({ label, display }: { label: string; display: string }) => {
  return (
    <StackView
      direction="row"
      justifyContent="between"
      className="pt-core-space-100"
    >
      <Typography
        color="text-base-color-fg-muted"
        size="xs"
        className="self-center uppercase"
      >
        {label}
      </Typography>
      <Typography>{display ?? emptyPlaceholder}</Typography>
    </StackView>
  )
}

const PrenatalLabResultsSection = ({ prenatalLabResults }) => {
  return (
    <StackView
      space={50}
      direction="col"
      justifyContent="between"
      className="pt-core-space-100"
      testId="birth-history-prenatal-lab-results-section"
    >
      <Typography
        color="text-base-color-fg-muted"
        size="xs"
        className="uppercase"
      >
        Prenatal labs
      </Typography>
      <StackView space={75} className="rounded border p-core-space-50">
        <Row
          label="Group B strep"
          display={prenatalLabResults?.streptococcusGroupB?.display}
        />
        <Row
          label="Human immunodeficiency virus (HIV)"
          display={prenatalLabResults?.hiv?.display}
        />
        <Row
          label="Chlamydia trachomatis"
          display={prenatalLabResults?.chlamydia?.display}
        />
        <Row
          label="Neisseria gonorrhoeae"
          display={prenatalLabResults?.gonorrhoeae?.display}
        />
        <Row
          label="Treponema pallidum (Syphilis)"
          display={prenatalLabResults?.syphilis?.display}
        />
        <Row label="Rubella" display={prenatalLabResults?.rubella?.display} />
      </StackView>
    </StackView>
  )
}

const PregnancyDetailsSections = ({ naturalMotherHistory }) => {
  return (
    <>
      <Row
        label="Age at birth"
        display={
          naturalMotherHistory?.ageAtPatientBirth?.display?.a
            ? `${naturalMotherHistory?.ageAtPatientBirth?.display?.a} years`
            : emptyPlaceholder
        }
      />
      <Row
        label="Routine prenatal care"
        display={
          naturalMotherHistory?.hadRoutinePrenatalCare?.display ??
          emptyPlaceholder
        }
      />
      <Row
        label="Pregnancy complications"
        display={
          naturalMotherHistory?.hadPregnancyComplications?.display ??
          emptyPlaceholder
        }
      />
    </>
  )
}

export const NaturalMotherHistorySection = ({
  patientId,
}: {
  patientId: string
}) => {
  const { birthHistory } = useBirthHistoryQuery(patientId)
  if (isEmpty(birthHistory)) return null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { naturalMotherHistory } = birthHistory as any

  return (
    <StackView
      space={50}
      className="w-1/2"
      divider
      testId="birth-history-natural-mother-section"
      justifyContent="between"
    >
      <Typography color="text-base-color-fg-muted">Natural Mother</Typography>
      <PregnancyDetailsSections naturalMotherHistory={naturalMotherHistory} />
      <PrenatalLabResultsSection
        prenatalLabResults={naturalMotherHistory.prenatalLabResults}
      />
    </StackView>
  )
}
