import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { bloodTypes } from 'src/data/bloodTypes'

export const BloodTypeSelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      includeEmptyOption={true}
      options={bloodTypes.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default BloodTypeSelectField
