import { useEffect } from 'react'

import { useScript } from 'usehooks-ts'

import { toast } from '@redwoodjs/web/toast'

import { TILLED_BASE_URL, TILLED_PUBLISHABLE_KEY } from 'src/lib/constants'

import { TilledFieldOptionsType } from './tilledFieldOptions'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Tilled: any
  }
}

export type TilledCardDetailsRefType = React.MutableRefObject<{
  tilled?: Window['Tilled']
  form?: Window['Tilled']['form']
  fields: Record<string, string>
  initiated: boolean
  createPaymentMethod?: (args: {
    cardHolder: string
    zip: string
  }) => Promise<{ ok: true; id: string } | { ok: false; error: unknown }>
}>

const useTilled = (
  accountId: string,
  tilledCardDetailsRef: TilledCardDetailsRefType,
  options: TilledFieldOptionsType
) => {
  const { fieldOptions } = options

  const status = useScript('https://js.tilled.com/v2', {
    removeOnUnmount: true,
    id: 'TilledJs',
  })

  const initTilled = async () => {
    tilledCardDetailsRef.current.initiated = true

    // Create a new tilled instance
    if (!tilledCardDetailsRef.current.tilled) {
      tilledCardDetailsRef.current.tilled = new window.Tilled(
        TILLED_PUBLISHABLE_KEY,
        accountId,
        {
          sandbox: TILLED_BASE_URL.includes('sandbox'),
          log_level: 0,
        }
      )
      tilledCardDetailsRef.current.createPaymentMethod = async ({
        cardHolder,
        zip,
      }) => {
        try {
          const paymentMethod =
            await tilledCardDetailsRef?.current?.tilled?.createPaymentMethod({
              type: 'card',
              billing_details: {
                name: cardHolder,
                address: {
                  country: 'US',
                  zip,
                },
              },
            })

          return { ok: true, id: paymentMethod.id }
        } catch (error) {
          console.error('There was an error with the payment method', error)
          toast.error('There was an error with the payment method')
          return { ok: false, error }
        }
      }
    }

    tilledCardDetailsRef.current.form =
      await tilledCardDetailsRef.current.tilled.form({
        payment_method_type: 'card',
      })

    // teardown to remove old fields
    tilledCardDetailsRef.current.form.teardown()

    // loop through fields and inject them
    Object.entries(tilledCardDetailsRef.current.fields).forEach((entry) => {
      const [field, fieldId] = entry
      const fieldElement = document.getElementById(fieldId.slice(1))

      // Create new fields and inject them
      if (fieldElement.childElementCount === 0)
        tilledCardDetailsRef.current.form
          .createField(field, fieldOptions ? fieldOptions : {})
          .inject(fieldElement)
    })

    // Build the form
    tilledCardDetailsRef.current.form.build()
  }

  const teardown = () => {
    if (tilledCardDetailsRef.current.form) {
      tilledCardDetailsRef.current.form.teardown(() => {
        tilledCardDetailsRef.current.form = undefined
      })
    }
  }

  useEffect(() => {
    if (
      status === 'ready' &&
      accountId &&
      !tilledCardDetailsRef.current.initiated
    ) {
      void initTilled()
    }

    return teardown
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, accountId])
}

export default useTilled
