import { useContext, useState } from 'react'

import merge from 'lodash/merge'
import { AppointmentType } from 'types/graphql'

import { useForm, UseFormReturn } from '@redwoodjs/forms'

import { PortalPatientSelfScheduleForm } from 'src/pages/PortalPatientSelfScheduling/types'
import { createNamedContext } from 'src/utils'

type SelfScheduledAppointmentDetails = PortalPatientSelfScheduleForm & {
  patientName: string
  locationAddress: string
  locationName: string
  startDateTime: Date
  practitionerName: string
  appointmentType: AppointmentType
}

const PatientPortalSelfSchedulingContext = createNamedContext<{
  formMethods: UseFormReturn<PortalPatientSelfScheduleForm>
  appointmentDetails: SelfScheduledAppointmentDetails
  saveAppointmentDetails: (
    updated: Partial<SelfScheduledAppointmentDetails>
  ) => void
  restoreAppointmentDetails: () => SelfScheduledAppointmentDetails
}>('PatientPortalSelfSchedulingContext')

export function usePatientPortalSelfSchedulingContext() {
  const context = useContext(PatientPortalSelfSchedulingContext)
  return context
}

export function restoreAppointmentDetails(): SelfScheduledAppointmentDetails {
  let appointmentDetails = null

  try {
    const storedData = localStorage.getItem('self-schedule-appointment-details')

    if (storedData) {
      appointmentDetails = JSON.parse(storedData)
    }
  } catch (err) {
    console.error('Failed to restore self-scheduling appointment details', err)
    localStorage.removeItem('self-schedule-appointment-details')
  }

  return appointmentDetails
}

export function storeAppointmentDetails(
  details: Partial<SelfScheduledAppointmentDetails>
) {
  localStorage.setItem(
    'self-schedule-appointment-details',
    JSON.stringify(details)
  )
}

export function PatientPortalSelfSchedulingContextProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const formMethods = useForm<PortalPatientSelfScheduleForm>({
    mode: 'onSubmit',
    defaultValues: {
      patientId: undefined,
      locationId: undefined,
      timeSlotId: undefined,
      visitReason: undefined,
      patientSelfSchedulingAppointmentType: undefined,
    },
  })

  const [appointmentDetails, setAppointmentDetails] =
    useState<SelfScheduledAppointmentDetails>()

  const handleSaveAppointmentDetails = (
    updatedAppointmentDetails: Partial<SelfScheduledAppointmentDetails>
  ) => {
    const mergedAppointmentDetails = merge(
      {},
      appointmentDetails,
      updatedAppointmentDetails
    )
    setAppointmentDetails(mergedAppointmentDetails)
    storeAppointmentDetails(mergedAppointmentDetails)
  }

  return (
    <PatientPortalSelfSchedulingContext.Provider
      value={{
        formMethods,
        appointmentDetails: appointmentDetails ?? restoreAppointmentDetails(),
        saveAppointmentDetails: handleSaveAppointmentDetails,
        restoreAppointmentDetails,
      }}
    >
      {children}
    </PatientPortalSelfSchedulingContext.Provider>
  )
}

export default PatientPortalSelfSchedulingContext
