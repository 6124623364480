import { parseISO } from 'date-fns'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import AddressFormInputList from 'src/components/Address/AddressFormInputList/AddressFormInputList'
import InheritedAddressField from 'src/components/Address/InheritedAddressField/InheritedAddressField'
import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import Box from 'src/components/atoms/Box/Box'
import Button, { Submit } from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import EthnicitySelectField from 'src/components/atoms/EthnicitySelectField/EthnicitySelectField'
import GenderIdentitySelectField from 'src/components/atoms/GenderIdentitySelectField/GenderIdentitySelectField'
import GuardianshipTypeSelectField from 'src/components/atoms/GuardianshipTypeSelectField/GuardianshipTypeSelectField'
import InputField from 'src/components/atoms/InputField'
import LanguageSelectField from 'src/components/atoms/LanguageSelectField/LanguageSelectField'
import MaritalStatusSelectField from 'src/components/atoms/MaritalStatusSelectField/MaritalStatusSelectField'
import NamePrefixSelectField from 'src/components/atoms/NamePrefixSelectField/NamePrefixSelectField'
import NameSuffixSelectField from 'src/components/atoms/NameSuffixSelectField/NameSuffixSelectField'
import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import PreferredPronounsSelectField from 'src/components/atoms/PreferredPronounSelectField/PreferredPronounsSelectField'
import RaceSelectField from 'src/components/atoms/RaceSelectField/RaceSelectField'
import RelationshipTypeSelectField from 'src/components/atoms/RelationshipTypeSelectField/RelationshipTypeSelectField'
import SexualOrientationSelectField from 'src/components/atoms/SexualOrientationSelectField/SexualOrientationSelectField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import ExistingCaregiverSearchField from 'src/components/molecules/ExistingCaregiverSearchField/ExistingCaregiverSearchField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { formatDateFieldValue } from 'src/lib/formatters'

const CREATE_NEW_PATIENT_CAREGIVER_MUTATION = gql`
  mutation CreateNewCaregiverMutation(
    $patientId: String!
    $input: CreateNewPatientCaregiverInput!
  ) {
    createNewPatientCaregiver(id: $patientId, input: $input) {
      id
      patientRelatedPersonRelationships {
        id
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          familyName
        }
      }
    }
  }
`

const CREATE_EXISTING_PATIENT_CAREGIVER_MUTATION = gql`
  mutation CreateExistingCaregiverMutation(
    $patientId: String!
    $input: CreateExistingPatientCaregiverInput!
  ) {
    createExistingPatientCaregiver(id: $patientId, input: $input) {
      id
      patientRelatedPersonRelationships {
        id
        relationshipType
        relatedPerson {
          id
          namePrefix
          givenName
          familyName
        }
      }
    }
  }
`

const NewCaregiverForm = ({ patient, displayName, onRemove, onComplete }) => {
  const DEFAULT_CAREGIVER_VALUES = {
    isExistingCaregiver: true,
    useHomeAddressAsMailingAddress: true,
  }
  const formMethods = useForm({
    defaultValues: DEFAULT_CAREGIVER_VALUES,
  })

  const [createNewPatientCaregiver, { loading: creatingNewPatientCaregiver }] =
    useMutation(CREATE_NEW_PATIENT_CAREGIVER_MUTATION, {
      onCompleted: () => {
        toast.success('Patient caregiver created')
        onComplete()
      },
      refetchQueries: [
        'AppointmentPatientCheckInQuery',
        'FindPatientDemographicsQuery',
      ],
    })

  const [
    createExistingPatientCaregiver,
    { loading: creatingExistingPationtCaregiver },
  ] = useMutation(CREATE_EXISTING_PATIENT_CAREGIVER_MUTATION, {
    onCompleted: () => {
      toast.success('Patient caregiver created')
      onComplete()
    },
    refetchQueries: [
      'AppointmentPatientCheckInQuery',
      'FindPatientDemographicsQuery',
    ],
  })

  const isExistingCaregiver = formMethods.watch('isExistingCaregiver')

  const onSubmit = (data) => {
    const { isExistingCaregiver, ...input } = data

    if (isExistingCaregiver) {
      createExistingPatientCaregiver({
        variables: {
          patientId: patient.id,
          input: {
            relatedPersonId: input.relatedPersonId,
            guardianshipType: input.guardianshipType,
            isGuarantor: input.isGuarantor,
            relationshipType: input.relationshipType,
          },
        },
      })
    } else {
      const { useHomeAddressAsMailingAddress } = input
      if (useHomeAddressAsMailingAddress) {
        delete input.mailingAddress
      }

      createNewPatientCaregiver({
        variables: {
          patientId: patient.id,
          input: input,
        },
      })
    }
  }

  return (
    <Form formMethods={formMethods} onSubmit={onSubmit}>
      <StackView direction="row" justifyContent="between" className="py-4">
        <Typography textStyle="heading">{displayName}</Typography>
        <Button buttonStyle="secondary" onClick={onRemove}>
          Remove
        </Button>
      </StackView>

      <StackView space={75}>
        <Card title="Existing caregiver">
          <Box horizontalPadding={100}>
            <FormInputList
              items={[
                {
                  name: 'isExistingCaregiver',
                  label: 'Is this an existing caregiver in the system?',
                  required: true,
                  formInputComponent: BooleanSelectField,
                  inputProps: {
                    trueDisplay: 'Yes',
                    falseDisplay: 'No',
                    includeEmptyOption: false,
                  },
                },
                {
                  name: 'relatedPerson',
                  label: 'Existing caregiver',
                  formInputComponent: ExistingCaregiverSearchField,
                  hide: !isExistingCaregiver,
                  required: true,
                  message:
                    'Search by any combination of first name, last name, or date of birth (mm/dd/yyyy)',
                  inputProps: {
                    validation: {
                      shouldUnregister: true,
                    },
                  },
                },
              ]}
            />
          </Box>
        </Card>
        <Card title="Caregiver relationship">
          <Box horizontalPadding={100}>
            <FormInputList
              items={[
                {
                  name: 'guardianshipType',
                  label: 'Guardianship status',
                  required: true,
                  formInputComponent: GuardianshipTypeSelectField,
                },
                {
                  name: 'isGuarantor',
                  label: 'Guarantor status',
                  required: true,
                  inputProps: {
                    includeEmptyOption: false,
                    defaultValue: 'false',
                    trueDisplay: 'Yes',
                    falseDisplay: 'No',
                  },
                  formInputComponent: BooleanSelectField,
                },
                {
                  name: 'relationshipType',
                  label: 'Relationship to patient',
                  required: true,
                  formInputComponent: RelationshipTypeSelectField,
                },
              ]}
            />
          </Box>
        </Card>

        {!isExistingCaregiver && (
          <>
            <Card title="Core demographics">
              <Box horizontalPadding={100}>
                <FormInputList
                  items={[
                    {
                      name: 'namePrefix',
                      label: 'Prefix',
                      formInputComponent: NamePrefixSelectField,
                    },
                    {
                      name: 'givenName',
                      label: 'First Name',
                      required: true,
                      formInputComponent: InputField,
                    },
                    {
                      name: 'middleName',
                      label: 'Middle Name',
                      formInputComponent: InputField,
                    },
                    {
                      name: 'familyName',
                      label: 'Family Name',
                      required: true,
                      formInputComponent: InputField,
                    },
                    {
                      name: 'nameSuffix',
                      label: 'Suffix',
                      formInputComponent: NameSuffixSelectField,
                    },
                    {
                      name: 'preferredName',
                      label: 'Preferred Name',
                      formInputComponent: InputField,
                    },
                    {
                      name: 'birthDate',
                      label: 'Date of birth',
                      required: true,
                      inputProps: {
                        type: 'date',
                        emptyAs: null,
                        validation: {
                          setValueAs: (value) => formatDateFieldValue(value),
                          validate: (value) => {
                            return parseISO(value) > new Date()
                              ? 'Date of birth must be in the past'
                              : null
                          },
                        },
                      },
                      formInputComponent: DatePickerField,
                    },
                    {
                      name: 'maritalStatus',
                      label: 'Marital Status',
                      formInputComponent: MaritalStatusSelectField,
                    },
                    {
                      name: 'occupation',
                      label: 'Occupation',
                      formInputComponent: InputField,
                    },
                    {
                      name: 'employer',
                      label: 'Current employer',
                      formInputComponent: InputField,
                    },
                  ]}
                />
              </Box>
            </Card>
            <Card title="Contact information">
              <Box horizontalPadding={100}>
                <FormInputList
                  items={[
                    {
                      name: 'mobileNumber',
                      label: 'Mobile number',
                      required: true,
                      formInputComponent: PhoneInputField,
                    },
                    {
                      name: 'homeNumber',
                      label: 'Home number',
                      formInputComponent: PhoneInputField,
                    },
                    {
                      name: 'workNumber',
                      label: 'Work number',
                      formInputComponent: PhoneInputField,
                    },
                    {
                      name: 'emailAddress',
                      label: 'Email address',
                      inputProps: {
                        type: 'email',
                      },
                      formInputComponent: InputField,
                    },
                    {
                      name: 'faxNumber',
                      label: 'Fax number',
                      formInputComponent: PhoneInputField,
                    },
                    {
                      name: 'homeAddress',
                      label: 'Home address',
                      alignItems: 'start',
                      inputProps: {
                        name: 'homeAddress',
                      },
                      formInputComponent: AddressFormInputList,
                    },
                    {
                      name: 'mailingAddress',
                      label: 'Mailing address',
                      alignItems: 'start',
                      inputProps: {
                        name: 'mailingAddress',
                        useInheritedAddressName:
                          'useHomeAddressAsMailingAddress',
                        useInheritedAddressDescription:
                          'Use home address as mailing address',
                      },
                      formInputComponent: InheritedAddressField,
                    },
                    {
                      name: 'primaryLanguage',
                      label: 'Primary language',
                      required: true,
                      inputProps: {
                        defaultValue: 'EN_US',
                      },
                      formInputComponent: LanguageSelectField,
                    },
                    {
                      name: 'secondaryLanguage',
                      label: 'Secondary language',
                      formInputComponent: LanguageSelectField,
                    },
                  ]}
                />
              </Box>
            </Card>

            <Card title="Additional demographics">
              <Box horizontalPadding={100}>
                <FormInputList
                  items={[
                    {
                      name: 'genderIdentity',
                      label: 'Gender Identity',
                      formInputComponent: GenderIdentitySelectField,
                    },
                    {
                      name: 'sexualOrientation',
                      label: 'Sexual Orientation',
                      formInputComponent: SexualOrientationSelectField,
                    },
                    {
                      name: 'preferredPronouns',
                      label: 'Preferred Pronouns',
                      formInputComponent: PreferredPronounsSelectField,
                    },
                    {
                      name: 'race',
                      label: 'Race',
                      formInputComponent: RaceSelectField,
                    },
                    {
                      name: 'ethnicity',
                      label: 'Ethnicity',
                      formInputComponent: EthnicitySelectField,
                    },
                    {
                      name: 'socialSecurityNumber',
                      label: 'Social Security Number',
                      formInputComponent: InputField,
                    },
                  ]}
                />
              </Box>
            </Card>
          </>
        )}
        <StackView space={50} direction="row" justifyContent="end">
          <Submit
            buttonStyle="primary"
            loading={
              creatingNewPatientCaregiver || creatingExistingPationtCaregiver
            }
          >
            Save
          </Submit>
        </StackView>
      </StackView>
    </Form>
  )
}

export default NewCaregiverForm
