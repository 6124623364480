import { isEmpty } from 'lodash'

import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { emptyPlaceholder } from 'src/lib/formatters'
import { useBirthHistoryQuery } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'

export const AboRhBloodTypingSection = ({
  patientId,
}: {
  patientId: string
}) => {
  const { birthHistory } = useBirthHistoryQuery(patientId)

  if (isEmpty(birthHistory)) return null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { bloodType, naturalMotherHistory } = birthHistory as any
  return (
    <StackView
      space={50}
      divider
      className="w-1/2"
      testId="birth-history-bloodtyping-section"
    >
      <Typography color="text-base-color-fg-muted">
        ABO and Rh blood typing
      </Typography>
      <StackView
        direction="row"
        justifyContent="between"
        className="pt-core-space-100"
      >
        <Typography
          size="xs"
          color="text-base-color-fg-muted"
          className="self-center uppercase"
        >
          Patient
        </Typography>
        <Typography>{bloodType?.display ?? emptyPlaceholder}</Typography>
      </StackView>
      <StackView
        direction="row"
        justifyContent="between"
        className="pt-core-space-100"
      >
        <Typography
          size="xs"
          color="text-base-color-fg-muted"
          className="self-center uppercase"
        >
          Natural mother
        </Typography>
        <Typography>
          {naturalMotherHistory?.bloodType?.display ?? emptyPlaceholder}
        </Typography>
      </StackView>
      <StackView
        direction="row"
        justifyContent="between"
        className="pt-core-space-100"
      >
        <Typography
          size="xs"
          color="text-base-color-fg-muted"
          className="self-center uppercase"
        >
          Direct antiglobulin test (DAT)
        </Typography>
        <Typography>
          {naturalMotherHistory?.directAntiglobulinTestResult?.display ??
            emptyPlaceholder}
        </Typography>
      </StackView>
    </StackView>
  )
}
