import PatientMedicationsCell from 'src/pages/PatientChartsPage/PatientMedicationsCell'

import {
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from '../../useVisit'

const PatientMedicationsPage = () => {
  const { appointmentId, patientId } = useVisit()
  const {
    visit: {
      encounter: { id: encounterId },
    },
  } = useVisitQuery(appointmentId)

  useUpdateVisitFlowTabForAppointment('medications')

  return (
    <div className="h-full w-full pt-4">
      <PatientMedicationsCell patientId={patientId} encounterId={encounterId} />
    </div>
  )
}

export default PatientMedicationsPage
