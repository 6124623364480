import { useRef } from 'react'

import {
  ArrowPathIcon,
  ArchiveBoxArrowDownIcon,
} from '@heroicons/react/20/solid'
import { GridOptions } from 'ag-grid-community'
import type { AgGridReact } from 'ag-grid-react'
import { format } from 'date-fns'
import {
  PaginatedOutboundFaxesQuery,
  OutboundEfaxFiltersInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useLocation, navigate, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'
import OutboundFaxStatus from 'src/components/atoms/OutboundFaxStatus/OutboundFaxStatus'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Table from 'src/components/molecules/Table/Table'
import { useArchiveFax } from 'src/hooks/useArchiveFax/useArchiveFax'
import { useResendFax } from 'src/hooks/useResendFax/useResendFax'
import { formatPhoneNumber } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

export const PAGINATED_OUTBOUND_FAXES_QUERY = gql`
  query PaginatedOutboundFaxesQuery(
    $input: PaginatedOutboundFaxInput!
    $filters: OutboundEfaxFiltersInput
  ) {
    paginatedOutboundFaxes(input: $input, filters: $filters) {
      total
      page
      pages
      pageSize
      outboundFaxes {
        id
        createdAt
        archivedAt
        status
        pagesCount
        attemptsMade
        faxNumber
        recipient {
          id
          name
        }
        outboundFaxRequest {
          id
          attentionTo
          user {
            id
            givenName
            familyName
          }
        }
      }
    }
  }
`

type OutboundFaxesTableProps = {
  filters?: OutboundEfaxFiltersInput
}

export const OutboundFaxesTable = ({ filters }: OutboundFaxesTableProps) => {
  const gridRef = useRef<AgGridReact>()
  const location = useLocation()
  const params = useParams()

  const { watch, reset } = useForm({
    defaultValues: {
      page: 1,
    },
  })

  const currentPageNumber = watch('page')
  const { data } = useQuery<PaginatedOutboundFaxesQuery>(
    PAGINATED_OUTBOUND_FAXES_QUERY,
    {
      variables: { input: { page: currentPageNumber }, filters },
    }
  )

  const gridOptions: GridOptions = {
    getRowId: ({ data }) => data.id,
    rowHeight: 72,
    onRowClicked: (e) => {
      navigate(
        sidepanelRoute(
          {
            route: `/outbound-fax/${e.data.id}`,
          },
          location,
          params
        )
      )
    },
    suppressRowClickSelection: true,
    suppressCellFocus: true,
    columnDefs: [
      {
        headerName: 'status',
        cellRenderer: ({ data }) => <OutboundFaxStatus status={data.status} />,
      },
      {
        headerName: 'From',
        valueGetter: ({ data }) =>
          `${data.outboundFaxRequest.user.givenName} ${data.outboundFaxRequest.user.familyName}`,
      },
      {
        headerName: 'To',
        cellRenderer: ({ data }) => (
          <StackView>
            <Typography>{data.recipient.name}</Typography>
            <Typography>{formatPhoneNumber(data.faxNumber)}</Typography>
          </StackView>
        ),
      },
      {
        field: 'outboundFaxRequest.attentionTo',
        headerName: 'attention to',
      },
      {
        headerName: 'pages count',
        valueGetter: ({ data }) => data.pagesCount ?? 'TBD',
      },
      {
        headerName: 'attempts made',
        valueGetter: ({ data }) => data.attemptsMade ?? 0,
      },
      {
        headerName: 'date and time',
        valueGetter: ({ data }) => {
          const date = new Date(data.createdAt)
          return format(date, 'PP') + ' at ' + format(date, 'p')
        },
      },
      {
        headerName: '',
        cellRenderer: ({ data }) => {
          const failed = data.status === 'FAILED'
          const archived = data.archivedAt

          if (!failed && archived) {
            return null
          }
          return (
            <StackView direction="row" space={50}>
              {failed && !archived ? (
                <ResendFaxButton outboundFaxId={data.id} />
              ) : null}
              {!archived ? <ArchiveFaxButton outboundFaxId={data.id} /> : null}
            </StackView>
          )
        },
      },

      {
        headerName: '',
        cellRenderer: ({ data, context }) => {
          if (context.active) {
            return
          }
          return (
            <StackView>
              <Typography>Archived on</Typography>
              <Typography>
                {format(new Date(data.archivedAt), "PP 'at' p")}
              </Typography>
            </StackView>
          )
        },
      },
    ],
  }
  const handleClickPaginationButton = (pageIndex: number) => {
    reset({
      page: pageIndex + 1,
    })
  }

  return (
    <StackView className="relative">
      <Table
        innerRef={gridRef}
        domLayout="autoHeight"
        gridOptions={gridOptions}
        rowData={data?.paginatedOutboundFaxes?.outboundFaxes ?? []}
        totalPages={data?.paginatedOutboundFaxes?.pages}
        page={data?.paginatedOutboundFaxes?.page}
        onClickPaginationButton={(page) => handleClickPaginationButton(page)}
        useCustomPaginationPanel
        context={{ active: filters?.active }}
      />
    </StackView>
  )
}

const ResendFaxButton = ({ outboundFaxId }: { outboundFaxId: string }) => {
  const [resendFax, { loading }] = useResendFax(outboundFaxId)
  return (
    <Button
      testId="resend-fax-button"
      buttonStyle="secondary"
      disabled={loading}
      icon={ArrowPathIcon}
      floatingTooltip="Re-send"
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = (e) => {
          e.stopPropagation()
          void resendFax()
        }
      }}
    />
  )
}

const ArchiveFaxButton = ({ outboundFaxId }: { outboundFaxId: string }) => {
  const [archiveFax, { loading }] = useArchiveFax(outboundFaxId)

  return (
    <Button
      testId="archive-fax-button"
      buttonStyle="secondary"
      disabled={loading}
      icon={ArchiveBoxArrowDownIcon}
      floatingTooltip="Archive"
      innerRef={(ref) => {
        if (!ref) return
        ref.onclick = async (e) => {
          e.stopPropagation()
          void archiveFax()
        }
      }}
    />
  )
}
