import {
  PatientDemographicsFragment,
  UpdateInsuranceCoverageInput,
} from 'types/graphql'

import CoordinationOfBenefitsSelectField from 'src/components/atoms/CoordinationOfBenefitsSelectField/CoordinationOfBenefitsSelectField'
import InsuranceCoverageStatusSelectField from 'src/components/atoms/InsuranceCoverageStatusSelectField/InsuranceCoverageStatusSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import { coordinationOfBenefitTypeDisplay } from 'src/data/coordinationOfBenefitTypes'
import { insuranceCoverageStatusDisplay } from 'src/data/insuranceCoverageStatuses'
import { UPDATE_INSURANCE_STATUS_MUTATION } from 'src/providers/context/AppointmentCheckInContext/schema'

const InsuranceStatusCard: React.FC<{
  insurance: PatientDemographicsFragment['insuranceCoverages'][0]
}> = ({ insurance }) => {
  const preSubmit = (data: UpdateInsuranceCoverageInput) => {
    const { ...input } = data
    return { variables: { id: insurance.id, input } }
  }

  return (
    <DataVerificationCard<UpdateInsuranceCoverageInput>
      title="Insurance status"
      updateMutation={UPDATE_INSURANCE_STATUS_MUTATION}
      preSubmit={preSubmit}
      defaultOpen
      data={[
        {
          name: 'status',
          label: 'Status',
          required: true,
          value: insurance.status,
          displayValue: insuranceCoverageStatusDisplay[insurance.status],
          formInputComponent: InsuranceCoverageStatusSelectField,
        },
        {
          name: 'coordinationOfBenefitsType',
          label: 'Type',
          required: true,
          value: insurance.coordinationOfBenefitsType,
          displayValue:
            coordinationOfBenefitTypeDisplay[
              insurance.coordinationOfBenefitsType
            ],
          formInputComponent: CoordinationOfBenefitsSelectField,
        },
      ]}
    />
  )
}

export default InsuranceStatusCard
