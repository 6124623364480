import uniqBy from 'lodash/uniqBy'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { FavoriteOrderButton } from 'src/components/FavoriteOrderButton/FavoriteOrderButton'
import AutomationNotification from 'src/components/molecules/AutomationNotification/AutomationNotification'
import CreateOrderDropdown from 'src/components/molecules/CreateOrderDropdown/CreateOrderDropdown'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import Modal from 'src/components/molecules/Modal'
import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'
import { useSignOrders } from 'src/hooks/useSignOrders/useSignOrders'
import { sidepanelRoute } from 'src/lib/routes'
import OrdersTable from 'src/pages/PatientChartsPage/PatientOrders/OrdersTable'
import { usePatientOrdersWithObservationsQuery } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'

import {
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from '../../useVisit'

import VisitDiagnosesTable from './VisitDiagnosesTable'

const OrdersDiagnosesPage = () => {
  const params = useParams()
  const location = useLocation()
  const { appointmentId, patientId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)
  const { pendedOrders, activeOrders } =
    usePatientOrdersWithObservationsQuery(patientId)
  const { completedOrders, revokedOrders, nonCancelledOrders } =
    usePatientOrdersWithObservationsQuery(patientId, visit?.encounter?.id)
  const diagnosisAutomationActions = visit?.encounter?.chargeItems
    ?.flatMap((chargeItem) => chargeItem.automationActions)
    .filter((action) => action.__typename === 'DiagnosisAutomationAction')
  const deduplicatedDiagnosisAutomationActions = uniqBy(
    diagnosisAutomationActions,
    (action) => action.ruleId
  )

  const { hasRole } = useEmrAuth()

  const [signOrders, { loading }] = useSignOrders(
    pendedOrders?.map((order) => order.id),
    visit.encounter.id
  )

  const [signAllPendedOrdersModalState, waitForConfirmation] = useConfirmation({
    onConfirm: () => signOrders(),
  })

  useUpdateVisitFlowTabForAppointment('orders')

  if (!visit) return null

  const openOrderSidepanelView = (e) =>
    navigate(
      sidepanelRoute(
        {
          route: `/patients/${patientId}/orders/${e.data.id}`,
        },
        location,
        params
      )
    )

  return (
    <StackView space={100} className="pr-4 pt-4">
      <StackView space={50} className="pr-4">
        <Typography textStyle="heading" fontWeight="medium">
          Visit orders and diagnoses
        </Typography>
        <Typography textStyle="body" color="text-base-color-fg-muted">
          Displayed below are pended or active signed visit orders and visit
          diagnoses, based on template(s) that may have been applied as well as
          orders that have already been placed. As new orders and diagnoses are
          added during the visit, they will also be added to the patient&apos;s
          chart.
        </Typography>
      </StackView>
      <StackView
        direction="row"
        gap={50}
        alignItems="center"
        justifyContent="between"
        className="border-b pb-2"
      >
        <Typography textStyle="title" size="xl" fontWeight="semibold">
          Visit orders
        </Typography>
        <StackView
          direction="row"
          gap={50}
          justifyContent="end"
          fullWidth={false}
        >
          <FeatureFlagged flagName="FAVORITE_ORDERS">
            <FavoriteOrderButton
              patientId={patientId}
              encounterId={visit?.encounter?.id}
            />
          </FeatureFlagged>
          <CreateOrderDropdown
            route={`/encounters/${visit.encounter.id}/orders/`}
          />
        </StackView>
      </StackView>
      <StackView space={50}>
        <StackView
          direction="row"
          justifyContent="between"
          alignItems="center"
          space={50}
        >
          <Typography textStyle="title" fontWeight="semibold">
            Pended
          </Typography>
          {hasRole('PRACTITIONER') && pendedOrders?.length ? (
            <Button
              onClick={waitForConfirmation}
              buttonStyle="secondary"
              disabled={loading}
              loading={loading}
            >
              Sign all
            </Button>
          ) : null}
        </StackView>

        <OrdersTable
          testId="pended-orders-table"
          patientId={patientId}
          emptyMessage="No pended orders placed"
          onRowClicked={openOrderSidepanelView}
          encounterId={visit.encounter.id}
          orders={pendedOrders}
        />
      </StackView>

      <StackView space={50}>
        <Typography textStyle="title" fontWeight="semibold">
          Active
        </Typography>
        <OrdersTable
          testId="active-orders-table"
          patientId={patientId}
          emptyMessage="No active orders placed"
          onRowClicked={openOrderSidepanelView}
          encounterId={visit.encounter.id}
          orders={activeOrders}
        />
      </StackView>

      {completedOrders?.length > 0 && (
        <StackView space={50}>
          <Typography textStyle="title" fontWeight="semibold">
            Complete
          </Typography>
          <OrdersTable
            testId="completed-orders-table"
            patientId={patientId}
            onRowClicked={openOrderSidepanelView}
            orders={completedOrders}
            encounterId={visit.encounter.id}
          />
        </StackView>
      )}

      {revokedOrders?.length > 0 && (
        <StackView space={50}>
          <Typography textStyle="title" fontWeight="semibold">
            Revoked
          </Typography>
          <OrdersTable
            testId="revoked-orders-table"
            patientId={patientId}
            onRowClicked={openOrderSidepanelView}
            orders={revokedOrders}
            encounterId={visit.encounter.id}
          />
        </StackView>
      )}

      <StackView
        direction="row"
        space={50}
        alignItems="center"
        justifyContent="between"
        className="pt-8"
      >
        <StackView space={50} className="pr-4">
          <Typography textStyle="title" fontWeight="semibold">
            Visit diagnoses
          </Typography>
          <Typography textStyle="body" color="text-base-color-fg-muted">
            Visit diagnoses may be pulled in based on orders that have been
            placed. Only one visit diagnosis can be tagged as primary. Of note,
            the last template that is applied may overlay a new primary visit
            diagnosis on top of the preceding primary visit diagnosis selection.
          </Typography>
        </StackView>
        <Button
          data-testid="create-diagnosis-button"
          buttonStyle="primary"
          text="Add diagnosis"
          onClick={() =>
            navigate(
              sidepanelRoute(
                { route: `/encounters/${visit.encounter.id}/diagnoses/new` },
                location
              )
            )
          }
        />
      </StackView>
      {deduplicatedDiagnosisAutomationActions
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((action) => (
          <AutomationNotification
            key={action.id}
            action={action}
            testId={`diagnosis-alert-${action.ruleId}`}
          />
        ))}

      <VisitDiagnosesTable
        encounterId={visit.encounter.id}
        primaryDiagnosis={visit.encounter.primaryDiagnosis}
        additionalDiagnoses={visit.encounter.additionalDiagnoses}
        orders={nonCancelledOrders}
      />

      <Modal
        title="Are you sure you would like to sign all pended orders?"
        modalStyle="warning"
        isOpen={signAllPendedOrdersModalState.isConfirming}
        primaryButton={{
          text: 'Continue',
          onClick: signAllPendedOrdersModalState.confirm,
        }}
        content="This action can only be undone by individually revoking a signed order."
        setIsOpen={(isOpen) => {
          if (isOpen) {
            return
          }
          signAllPendedOrdersModalState.cancel()
        }}
        hideIcon
      />
    </StackView>
  )
}

export default OrdersDiagnosesPage
