import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { pick, isEmpty } from 'lodash'
import type {
  FindPracticeAdminDocumentQuery,
  FindPracticeAdminDocumentQueryVariables,
  PracticeAdminDocumentType,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import InputField from 'src/components/atoms/InputField/InputField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { QUERY as FIND_PRACTICE_ADMIN_DOCUMENT_QUERY } from 'src/components/PracticeAdminDocumentCell'
import { practiceAdminDocumentTypeDisplay } from 'src/data/practiceAdminDocumentTypes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

import FileInput from '../atoms/FileInput/FileInput'
import PracticeAdminDocumentTypeSelectField from '../atoms/PracticeAdminDocumentTypeSelectField/PracticeAdminDocumentTypeSelectField'
import SidepanelForm from '../molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from '../molecules/SidepanelPage/SidepanelPage'

export const QUERY = FIND_PRACTICE_ADMIN_DOCUMENT_QUERY

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPracticeAdminDocumentQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

const UPDATE_PRACTICE_ADMIN_DOCUMENT_MUTATION = gql`
  mutation UpdatePracticeAdminDocument(
    $id: String!
    $input: UpdatePracticeAdminDocumentInput!
  ) {
    updatePracticeAdminDocument(id: $id, input: $input) {
      id
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      type
      title
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

type FormData = {
  type?: PracticeAdminDocumentType
  title?: string
  /**
   * A list of files (max length 1) or undefined. Useful for the user to upload a single file.
   *
   * This matches the type used by `FileInput`
   */
  files?: FileList
}

export const Success = ({
  practiceAdminDocument,
}: CellSuccessProps<
  FindPracticeAdminDocumentQuery,
  FindPracticeAdminDocumentQueryVariables
>) => {
  const { closeSidePanel } = useSidepanel()
  const [
    updatePracticeAdminDocument,
    {
      loading: updatingPracticeAdminDocument,
      error: updatePracticeAdminDocumentError,
    },
  ] = useMutation(UPDATE_PRACTICE_ADMIN_DOCUMENT_MUTATION, {
    onCompleted: () => {
      toast.success(`Practice admin document updated`)
      closeSidePanel()
    },
    refetchQueries: ['PracticeAdminDocumentsQuery'],
  })
  const formMethods = useForm<FormData>({
    defaultValues: pick(practiceAdminDocument, 'type', 'title'),
    shouldUnregister: true,
  })
  const {
    formState: { dirtyFields },
  } = formMethods
  const type = formMethods.watch('type')
  const hasDirtyFields = !isEmpty(dirtyFields)

  const onSubmit = async (value: FormData) => {
    const dirtyValues: FormData = Object.fromEntries(
      Object.entries(value).filter(([key, _]) => dirtyFields[key])
    )
    const { files, ...input } = dirtyValues
    if (files !== undefined && files.length === 1) {
      input['binary'] = {
        content: await base64EncodeFile(files[0]),
        contentType: files[0].type,
      }
    }
    updatePracticeAdminDocument({
      variables: { input, id: practiceAdminDocument.id },
    })
  }

  return (
    <SidepanelPage
      testId="practice-admin-document-edit"
      header={`Practice admin: ${
        practiceAdminDocumentTypeDisplay[practiceAdminDocument.type]
      }`}
      subHeader={practiceAdminDocument.title}
      description={`Last modified on ${format(
        new Date(practiceAdminDocument.updatedAt),
        'PP'
      )} · Last modified by ${formatDisplayName(
        practiceAdminDocument.updatedBy
      )}`}
    >
      <SidepanelForm
        footerProps={{
          disabled: !hasDirtyFields,
          submitText: 'Save',
          submitting: updatingPracticeAdminDocument,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
        error={updatePracticeAdminDocumentError}
      >
        <FormInputList
          items={[
            {
              name: 'type',
              label: 'Document sub-type',
              formInputComponent: PracticeAdminDocumentTypeSelectField,
            },
            {
              name: 'title',
              label: 'Document title',
              formInputComponent: InputField,
              required: type === 'OTHER',
            },

            {
              name: 'files',
              label: 'Document upload',
              formInputComponent: FileInput,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}
