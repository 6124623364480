const PrimaryCheckmarkIcon = ({
  className,
  testId,
}: {
  className?: string
  testId?: string
}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      data-testid={testId}
    >
      <rect width="20" height="20" fill="transparent" />
      <path
        d="M10 2L11.4203 2.85988L13.0615 2.60896L14.0446 3.9469L15.6569 4.34315L16.0531 5.95545L17.391 6.93853L17.1401 8.57974L18 10L17.1401 11.4203L17.391 13.0615L16.0531 14.0446L15.6569 15.6569L14.0446 16.0531L13.0615 17.391L11.4203 17.1401L10 18L8.57974 17.1401L6.93853 17.391L5.95545 16.0531L4.34315 15.6569L3.9469 14.0446L2.60896 13.0615L2.85988 11.4203L2 10L2.85988 8.57974L2.60896 6.93853L3.9469 5.95545L4.34315 4.34315L5.95545 3.9469L6.93853 2.60896L8.57974 2.85988L10 2Z"
        fill="#5D5BD7"
      />
      <path
        d="M7.13736 10.0504L8.75019 11.6635L13.1755 7.23784C13.3279 7.08544 13.5346 6.99982 13.7502 6.99982C13.9657 6.99982 14.1724 7.08544 14.3248 7.23784C14.4772 7.39025 14.5628 7.59695 14.5628 7.81249C14.5628 8.02803 14.4772 8.23473 14.3248 8.38714L9.32502 13.3869C9.24956 13.4625 9.15995 13.5224 9.06131 13.5633C8.96267 13.6042 8.85694 13.6252 8.75017 13.6252C8.6434 13.6252 8.53768 13.6042 8.43903 13.5633C8.3404 13.5224 8.25079 13.4625 8.17533 13.3869L5.98803 11.1996C5.83562 11.0472 5.75 10.8405 5.75 10.625C5.75 10.4095 5.83562 10.2027 5.98803 10.0503C6.14043 9.89794 6.34714 9.81232 6.56267 9.81232C6.77821 9.81232 6.98495 9.89797 7.13736 10.0504Z"
        fill="white"
      />
    </svg>
  )
}

export default PrimaryCheckmarkIcon
