import { PencilIcon } from '@heroicons/react/20/solid'
import { ColDef } from 'ag-grid-community'
import { assertUnreachable } from 'common/utils'
import { DiagnosisCodeFragment, EncounterBillingFragment } from 'types/graphql'

import Badge from 'src/components/atoms/Badge/Badge'
import Box from 'src/components/atoms/Box/Box'
import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import ChargeItemModifier from 'src/components/ChargeItemModifier/ChargeItemModifier'
// import DiagnosisActionButtons from 'src/components/DiagnosisActionButtons/DiagnosisActionButtons'
import BillingCodeSwapButton from 'src/components/molecules/BillingCodeSwapButton/BillingCodeSwapButton'

export type RowData = EncounterBillingFragment['chargeItems'][0]

export const getColumnDefs: (
  primaryDiagnosis: DiagnosisCodeFragment,
  editBillingCode: (chargeItemId: string) => void
) => ColDef<RowData>[] = (primaryDiagnosis, editBillingCode) => [
  {
    colId: 'billing',
    headerName: 'Billing code and description',
    flex: 4,
    autoHeight: true,
    cellRenderer: ({ data }: { data: RowData }) => (
      <DiagnosisDetails
        code={data.billingCode.code}
        display={data.billingCode.description}
      />
    ),
  },
  {
    headerName: 'units',
    field: 'quantity',
    maxWidth: 100,
  },
  {
    colId: 'modifiers',
    headerName: 'modifiers',
    cellStyle: { overflow: 'visible' },
    cellRenderer: ({ data }) => (
      <StackView space={50}>
        {data.modifiers.map((modifier) => (
          <ChargeItemModifier key={modifier} modifier={modifier} />
        ))}
      </StackView>
    ),
    maxWidth: 150,
  },
  {
    colId: 'order',
    headerName: 'order',
    field: 'service.order.name',
    maxWidth: 150,
  },
  {
    colId: 'diagnosis',
    headerName: 'Diagnosis code and description',
    autoHeight: true,
    flex: 4,
    cellRenderer: ({ data }: { data: RowData }) => {
      const diagnoses = [...getDiagnosesForService(data.service)]
      if (
        primaryDiagnosis &&
        data.isPrimary &&
        !diagnoses.map((d) => d.code).includes(primaryDiagnosis.code)
      ) {
        // primary billing codes should always have the primary diagnosis
        diagnoses.unshift(primaryDiagnosis)
      }

      diagnoses.sort((a, b) => {
        if (a.code === primaryDiagnosis?.code) {
          return -1
        }
        if (b.code === primaryDiagnosis?.code) {
          return 1
        }
        return 0
      })

      return (
        <StackView space={50} className="my-2">
          {diagnoses.map(({ code, description }) => (
            <DiagnosisDetails
              key={code}
              code={code}
              display={description}
              isPrimary={code === primaryDiagnosis?.code}
            />
          ))}
        </StackView>
      )
    },
  },
  {
    colId: 'actions',
    headerName: 'Actions',
    cellStyle: { overflow: 'visible' },
    minWidth: 220,
    cellRenderer: ({ data }: { data: RowData }) => {
      return (
        <StackView direction="row" space={50}>
          {data.billingCode.billingCodeGroupId && (
            <BillingCodeSwapButton
              billingCodeId={data.billingCode.id}
              billingGroupId={data.billingCode.billingCodeGroupId}
              chargeItemId={data.id}
            />
          )}
          <Button
            icon={PencilIcon}
            buttonStyle="secondary"
            text="Edit"
            onClick={() => {
              editBillingCode(data.id)
            }}
          />
        </StackView>
      )
    },
  },
]

interface DiagnosisDetailsProps {
  code: string
  display: string
  isPrimary?: boolean
}

const DiagnosisDetails: React.FC<DiagnosisDetailsProps> = ({
  code,
  display,
  isPrimary = false,
}) => {
  return (
    <StackView testId="diagnosis-details" space={25} className="my-2 shrink">
      <StackView direction="row" space={50} alignItems="center" wrap>
        <Typography textStyle="title">{code}</Typography>
        {isPrimary && (
          <Badge testId="primary-diagnosis-badge" color="green">
            Primary
          </Badge>
        )}
      </StackView>
      <Box fullWidth className="whitespace-normal">
        <Typography textStyle="description">{display}</Typography>
      </Box>
    </StackView>
  )
}

const getDiagnosesForService = (
  service: RowData['service']
): DiagnosisCodeFragment[] => {
  const serviceType = service.__typename
  let diagnoses: DiagnosisCodeFragment[]
  switch (serviceType) {
    case 'Procedure':
    case 'Immunization':
      diagnoses = service.order?.diagnosisCodes ?? []
      break
    case 'NoLinkedService':
      diagnoses = service.conditions?.map((c) => c.diagnosisCode) ?? []
      break
    default:
      assertUnreachable(serviceType)
  }
  return diagnoses
}
