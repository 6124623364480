import { useState } from 'react'

import { periodicityStage } from 'common/data/periodicityStage'
import { PeriodicityStage } from 'types/graphql'

import Dropdown from 'src/components/atoms/Dropdown'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'

export const SET_APPOINTMENT_PERIODICITY_DEFAULT_MUTATION = gql`
  mutation SetAppointmentPeriodicityDefault(
    $appointmentId: String!
    $periodicityStage: PeriodicityStage!
    $documentationType: SetAppointmentPeriodicityDefaultDocumentationType!
    $systems: [String!]
  ) {
    setAppointmentPeriodicityDefault(
      appointmentId: $appointmentId
      periodicityStage: $periodicityStage
      documentationType: $documentationType
      systems: $systems
    ) {
      id
      reviewOfSystems
      physicalExam
    }
  }
`

export const SET_VISIT_TEMPLATE_PERIODICITY_DEFAULT_MUTATION = gql`
  mutation SetVisitTemplatePeriodicityDefault(
    $visitTemplateId: String!
    $periodicityStage: PeriodicityStage!
    $documentationType: SetVisitTemplatePeriodicityDefaultDocumentationType!
  ) {
    setVisitTemplatePeriodicityDefault(
      visitTemplateId: $visitTemplateId
      periodicityStage: $periodicityStage
      documentationType: $documentationType
    ) {
      id
      reviewOfSystems
      physicalExam
    }
  }
`

const PeriodicityDefaultsDropdown = ({
  onChange,
}: {
  onChange: (value: PeriodicityStage) => void
}) => {
  const [value, setValue] = useState(null)
  const options = [
    { name: 'Periodicity', value: null, disabled: true },
    ...periodicityStage,
  ]

  const selectPeriodicityDefault = (value) => {
    setValue(value)
    onChange(value)
  }

  return (
    <FeatureFlagged flagName="PERIODICITY_DEFAULTS">
      <div className="w-40">
        <Dropdown
          name="periodicity-defaults-dropdown"
          mode="id"
          options={options}
          onSelect={selectPeriodicityDefault}
          selected={value}
        />
      </div>
    </FeatureFlagged>
  )
}
export default PeriodicityDefaultsDropdown
