import { Fragment, useEffect, useRef, useState } from 'react'

import { ArrowPathIcon, IdentificationIcon } from '@heroicons/react/20/solid'
import {
  CalendarIcon,
  CheckCircleIcon,
  DocumentTextIcon,
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/solid'
import { formatMoneyInCents } from 'common/utils'
import { formatDisplayName } from 'common/utils'
import {
  GetCurrentPatientPaymentPlan,
  GetPatientCopayQuery,
} from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { navigate, useLocation, useParams } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import Button from 'src/components/atoms/Button'
import Card from 'src/components/atoms/Card'
import Divider from 'src/components/atoms/Divider/Divider'
import InputField from 'src/components/atoms/InputField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import BaseModal from 'src/components/molecules/Modal/BaseModal'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import SidepanelPortal from 'src/components/organisms/SidepanelPortal/SidepanelPortal'
import { useGetCurrentPaymentPlan } from 'src/hooks/usePatientPaymentPlans/usePatientPaymentPlans'
import {
  usePatientOutstandingBalance,
  usePatientPaymentsQuery,
} from 'src/hooks/usePatientPayments/usePatientPayments'
import { sidepanelRoute } from 'src/lib/routes'
import {
  PATIENT_LAST_COPAY_AUDIT_RECORD_FRAGMENT,
  UPDATE_PATIENT_COPAY_MUTATION,
} from 'src/pages/PatientChartsPage/PatientDemographics/usePatient'
import SidepanelMakePayment from 'src/pages/Sidepanel/SidepanelMakePayment/SidepanelMakePayment'

import { DateRangeField } from '../molecules/DatePicker'
import Modal from '../molecules/Modal/Modal'
import PopoverButton from '../PopoverButton/PopoverButton'

import HistoricalPatientPaymentsTable from './components/HistoricalPatientPaymentsTable'
import PatientBalanceSection from './components/PatientBalanceSection'
import { PatientChargesSection } from './components/PatientChargesSection'

const POLLING_INTERVAL = 1000
const CLOSE_AFTER_SUCCESS_TIMEOUT = 5000
const FAILURE_TIMEOUT = 60000

type PaymentProcessingModalVariantType = 'POLLING' | 'SUCCESS' | 'FAIL'
type ModalType = 'PAYMENT_HISTORY_FILTER' | 'BILLING_HISTORY_FILTER'

const GET_TILLED_PAYMENT_INTENT_QUERY = gql`
  query GetTilledPaymentIntentQuery($tilledPaymentIntentId: String!) {
    tilledPaymentIntent(id: $tilledPaymentIntentId) {
      id
    }
  }
`

const GET_PATIENT_COPAY_QUERY = gql`
  query GetPatientCopayQuery($id: String!) {
    patient(id: $id) {
      id
      copayCents
      ...PatientLastCopayAuditRecordFragment
      activeMembershipPlan {
        id
      }
      pastMembershipPlans {
        id
      }
    }
  }
  ${PATIENT_LAST_COPAY_AUDIT_RECORD_FRAGMENT}
`

const PaymentProcessingModalContent = ({
  variant,
  closeModal,
}: {
  variant: PaymentProcessingModalVariantType
  closeModal: () => void
}) => {
  switch (variant) {
    case 'POLLING':
      return (
        <StackView space={100}>
          <LoadingSpinner size="xl" />
          <Typography textStyle="title-xs">Payment processing...</Typography>
          <Typography textStyle="body-s" color="text-base-color-fg-subtle">
            Please do not refresh this page or navigate away while the payment
            is processing.
          </Typography>
        </StackView>
      )
    case 'SUCCESS':
      return (
        <StackView space={100}>
          <StackView direction="row" justifyContent="center">
            <CheckCircleIcon className="h-base-size-icon-xl w-base-size-icon-xl fill-base-color-fg-success" />
          </StackView>
          <Typography textStyle="title-xs">Payment successful!</Typography>
          <Typography textStyle="body-s" color="text-base-color-fg-subtle">
            The payment was successful, your screen will refresh in a moment.
          </Typography>
          <Button buttonStyle="secondary" onClick={closeModal}>
            Continue
          </Button>
        </StackView>
      )
    case 'FAIL':
      return (
        <StackView space={100}>
          <StackView direction="row" justifyContent="center">
            <ExclamationCircleIcon className="h-base-size-icon-xl w-base-size-icon-xl fill-base-color-fg-danger" />
          </StackView>
          <Typography textStyle="title-xs">Payment error</Typography>
          <Typography textStyle="body-s" color="text-base-color-fg-subtle">
            There was an error with the payment method chosen.
          </Typography>
          <Button buttonStyle="secondary" onClick={closeModal}>
            Try again
          </Button>
        </StackView>
      )
  }
}

const PaymentProcessingModal = ({
  isOpen,
  variant,
  closeModal,
}: {
  isOpen: boolean
  variant: PaymentProcessingModalVariantType
  closeModal: () => void
}) => {
  return (
    <BaseModal isOpen={isOpen} setIsOpen={() => {}}>
      <Card className="max-w-sm" contentClassName="p-core-space-150">
        <PaymentProcessingModalContent
          variant={variant}
          closeModal={closeModal}
        />
      </Card>
    </BaseModal>
  )
}

const OutstandingBalanceMoreButton = ({
  patientId,
  patientBalanceTotalCents,
  currentPaymentPlan,
  currentMembershipPlan,
  pastMembershipPlans,
  setModalType,
}: {
  patientId: string
  patientBalanceTotalCents: number
  currentPaymentPlan: GetCurrentPatientPaymentPlan['patient']['currentPaymentPlan']
  currentMembershipPlan: GetPatientCopayQuery['patient']['activeMembershipPlan']
  pastMembershipPlans: GetPatientCopayQuery['patient']['pastMembershipPlans']
  setModalType: (modalType: ModalType) => void
}) => {
  const { currentUser } = useEmrAuth()
  const params = useParams()
  const location = useLocation()
  const shouldShowBillingStatement = patientBalanceTotalCents > 0
  const shouldShowCreatePaymentPlan =
    patientBalanceTotalCents > 0 &&
    !currentPaymentPlan &&
    !currentMembershipPlan

  const shouldShowViewPaymentPlan = !!currentPaymentPlan?.id
  const shouldShowItemizedBillingHistory = true
  const shouldShowViewMembershipPlan =
    currentUser.patientMembershipsEnabled &&
    (currentMembershipPlan || pastMembershipPlans?.length)
  const shouldShowCreateMembershipPlan =
    currentUser.patientMembershipsEnabled && !currentMembershipPlan

  const options = [
    {
      show: shouldShowBillingStatement,
      section: 'billing',
      text: 'View billing statement',
      icon: DocumentTextIcon,
      onClick: () => {
        navigate(
          sidepanelRoute(
            {
              route: `/billing-statement/${patientId}`,
              width: 'medium',
              overlay: true,
            },
            location,
            params
          )
        )
      },
    },
    {
      show: shouldShowItemizedBillingHistory,
      text: 'View billing history',
      section: 'billing',
      icon: ArrowPathIcon,
      onClick: () => {
        setModalType('BILLING_HISTORY_FILTER')
      },
    },
    {
      show: shouldShowCreateMembershipPlan,
      text: 'Set up membership',
      section: 'membership',
      icon: IdentificationIcon,
      onClick: () => {
        navigate(
          sidepanelRoute(
            {
              route: `/patient/${patientId}/membership/new`,
              width: 'small',
            },
            location,
            params
          )
        )
      },
    },

    {
      show: shouldShowViewMembershipPlan,
      text: 'View membership',
      section: 'membership',
      icon: IdentificationIcon,
      onClick: () => {
        navigate(
          sidepanelRoute(
            {
              route: `/patient/${patientId}/memberships`,
              width: 'small',
            },
            location,
            params
          )
        )
      },
    },
    {
      show: shouldShowCreatePaymentPlan,
      text: 'Set up payment plan',
      section: 'paymentPlan',
      icon: CalendarIcon,
      onClick: () => {
        navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/payment-plan/create`,
              overlay: true,
            },
            location,
            params
          )
        )
      },
    },
    {
      show: shouldShowViewPaymentPlan,
      text: 'View payment plan',
      section: 'paymentPlan',
      icon: CalendarIcon,
      onClick: () => {
        navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/payment-plan/${currentPaymentPlan.id}`,
              overlay: true,
            },
            location,
            params
          )
        )
      },
    },
  ]

  const visibleOptions = options.filter((option) => option.show)

  if (!visibleOptions.length) return null

  return (
    <>
      <div className="relative">
        <PopoverButton
          testId="outstanding-balance-more-button"
          panelXPosition="right"
          panelWidth="w-fit"
          buttonProps={{
            buttonStyle: 'secondary',
            icon: EllipsisHorizontalIcon,
            iconPlacement: 'right',
            text: 'More',
          }}
          renderPanel={({ close }) => (
            <StackView space={25} className="w-fit">
              {visibleOptions.map((option, i) => {
                const previousOption = visibleOptions[i - 1]
                const isNewSection =
                  previousOption && previousOption.section !== option.section

                return (
                  <Fragment key={option.text}>
                    {isNewSection ? <Divider /> : null}
                    <Button
                      buttonStyle="ghost"
                      text={option.text}
                      icon={option.icon}
                      justifyContent="start"
                      onClick={() => {
                        option.onClick()
                        close()
                      }}
                    />
                  </Fragment>
                )
              })}
            </StackView>
          )}
        />
      </div>
    </>
  )
}

const PaymentHistoryFilterModal = ({
  patientId,
  isOpen,
  closeModal: closeModalProp,
}: {
  patientId: string
  isOpen: boolean
  closeModal: () => void
}) => {
  const params = useParams()
  const location = useLocation()
  const formMethods = useForm()

  const { start, end } = formMethods.watch()

  const closeModal = (isOpen: boolean) => {
    if (!isOpen) {
      formMethods.reset()
    }

    closeModalProp()
  }

  return (
    <Form formMethods={formMethods}>
      <Modal
        testId="payment-history-range-modal"
        isOpen={isOpen}
        setIsOpen={closeModal}
        hideIcon
        title="View patient's payment history"
        modalStyle="warning"
        content={
          <StackView>
            <DateRangeField
              label="Payment date range"
              nameStart="start"
              nameEnd="end"
            />
          </StackView>
        }
        primaryButton={{
          text: 'View payment history',
          onClick: () => {
            navigate(
              sidepanelRoute(
                {
                  route: `/patients/${patientId}/payments/history/${start}/${end}`,
                  width: 'medium',
                  overlay: true,
                },
                location,
                params
              )
            )
          },
          disabled: !start || !end,
        }}
      />
    </Form>
  )
}

const BillingHistoryFilterModal = ({
  patientId,
  isOpen,
  closeModal: closeModalProp,
}: {
  patientId: string
  isOpen: boolean
  closeModal: () => void
}) => {
  const params = useParams()
  const location = useLocation()
  const formMethods = useForm()

  const { start, end } = formMethods.watch()

  const closeModal = (isOpen: boolean) => {
    if (!isOpen) {
      formMethods.reset()
    }

    closeModalProp()
  }

  return (
    <Form formMethods={formMethods}>
      <Modal
        testId="billing-history-range-modal"
        isOpen={isOpen}
        setIsOpen={closeModal}
        hideIcon
        title="View patient's billing history"
        modalStyle="warning"
        content={
          <StackView>
            <DateRangeField
              label="Dates of service range"
              nameStart="start"
              nameEnd="end"
            />
          </StackView>
        }
        primaryButton={{
          text: 'View billing history',
          onClick: () => {
            navigate(
              sidepanelRoute(
                {
                  route: `/patients/${patientId}/billing/history/${start}/${end}`,
                  width: 'medium',
                  overlay: true,
                },
                location,
                params
              )
            )
          },
          disabled: !start || !end,
        }}
      />
    </Form>
  )
}

const PatientFinancials = ({
  patientId,
  title,
}: {
  patientId: string
  title?: string
}) => {
  const { currentUser } = useEmrAuth()
  const [paymentSidepanelIsOpen, setPaymentSidepanelIsOpen] =
    useState<boolean>(false)
  const [paymentStatus, setPaymentStatus] =
    useState<PaymentProcessingModalVariantType | null>(null)
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null)
  const successTimeout = useRef<NodeJS.Timeout | void>()
  const failureTimeout = useRef<NodeJS.Timeout | void>()
  const [modalType, setModalType] = useState<ModalType>(null)

  const { data: patientData } = useQuery(GET_PATIENT_COPAY_QUERY, {
    variables: { id: patientId },
  })

  const { currentPaymentPlan } = useGetCurrentPaymentPlan(patientId)

  const {
    totalOutstandingBalanceCents,
    refetch: getPatientOutstandingBalance,
  } = usePatientOutstandingBalance({ patientId })

  const paymentsQuery = usePatientPaymentsQuery(patientId)

  const payments = [
    ...(paymentsQuery.candidPayments ?? []),
    ...(paymentsQuery.develoCandidPatientRefunds ?? []),
    ...(paymentsQuery.paymentPlanPayments ?? []),
  ]

  const { previousData } = useQuery(GET_TILLED_PAYMENT_INTENT_QUERY, {
    variables: { tilledPaymentIntentId: paymentIntentId },
    skip: paymentStatus !== 'POLLING' || !paymentIntentId,
    onCompleted: async (data) => {
      const previousPaymentDataLength =
        previousData?.tilledPaymentIntent?.length ?? 0
      const paymentDataLength = data?.tilledPaymentIntent?.length ?? 0
      if (paymentDataLength > previousPaymentDataLength) {
        await getPatientOutstandingBalance()
        await paymentsQuery.refetch()
        setPaymentStatus('SUCCESS')
      }
    },
    onError: () => {
      setPaymentStatus('FAIL')
    },
    pollInterval: paymentStatus === 'POLLING' && POLLING_INTERVAL,
  })

  useEffect(() => {
    if (paymentStatus === 'SUCCESS') {
      successTimeout.current = setTimeout(() => {
        setPaymentStatus(null)
      }, CLOSE_AFTER_SUCCESS_TIMEOUT)
    }

    if (paymentStatus === 'POLLING') {
      failureTimeout.current = setTimeout(() => {
        setPaymentStatus('FAIL')
      }, FAILURE_TIMEOUT)
    }
  }, [failureTimeout, paymentStatus])

  useEffect(() => {
    if (!paymentStatus) {
      successTimeout.current =
        successTimeout.current && clearTimeout(successTimeout.current)
    }
  }, [paymentStatus, successTimeout])

  useEffect(() => {
    if (paymentStatus !== 'POLLING') {
      failureTimeout.current =
        failureTimeout.current && clearTimeout(failureTimeout.current)
    }
  }, [paymentStatus, failureTimeout])

  const lastCopayAuditRecord = patientData?.patient?.lastCopayAuditRecord

  return (
    <StackView space={100} className="py-core-space-100">
      <StackView space={50}>
        <Typography textStyle="title-s">{title}</Typography>
        <Typography textStyle="body-s" color="text-base-color-fg-muted">
          An overview of the patient’s outstanding balance and financial
          history.
        </Typography>
      </StackView>

      <Divider />

      <StackView
        direction="row"
        space={100}
        alignItems="center"
        className="pt-core-space-100"
      >
        <StackView space={25}>
          <Typography textStyle="title-xs">Outstanding balance</Typography>
          <Typography textStyle="body-s" color="text-base-color-fg-muted">
            The balance that you have incurred over time without payment.
          </Typography>
        </StackView>
        <StackView space={75} direction="row" className="w-min">
          <OutstandingBalanceMoreButton
            patientId={patientId}
            patientBalanceTotalCents={totalOutstandingBalanceCents}
            currentPaymentPlan={currentPaymentPlan}
            currentMembershipPlan={patientData?.patient?.activeMembershipPlan}
            pastMembershipPlans={
              patientData?.patient?.pastMembershipPlans ?? []
            }
            setModalType={setModalType}
          />

          <Button
            testId="record-payment-button"
            onClick={() => setPaymentSidepanelIsOpen(true)}
          >
            Record payment
          </Button>
        </StackView>
      </StackView>

      <PatientBalanceSection patientId={patientId} />

      <DataVerificationCard
        title="Co-pay details"
        updateMutation={UPDATE_PATIENT_COPAY_MUTATION}
        defaultOpen
        lastUpdatedAt={
          lastCopayAuditRecord
            ? new Date(patientData?.patient?.lastCopayAuditRecord?.createdAt)
            : undefined
        }
        preSubmit={({ ...input }) => {
          return { variables: { id: patientId, input } }
        }}
        data={[
          {
            name: 'copayCents',
            label: 'Co-pay',
            value: patientData?.patient.copayCents / 100,
            displayValue: formatMoneyInCents(patientData?.patient.copayCents),
            displayTooltipProps: lastCopayAuditRecord
              ? {
                  title: `Last updated: ${[
                    new Date(lastCopayAuditRecord.createdAt).toLocaleString(),
                    lastCopayAuditRecord.practitioner
                      ? formatDisplayName(lastCopayAuditRecord.practitioner)
                      : lastCopayAuditRecord.insuranceCoverageEligibilityId
                        ? 'Eligibility check'
                        : null,
                  ]
                    .filter(Boolean)
                    .join(' • ')}`,
                  text: `Prior entry: ${
                    lastCopayAuditRecord.lastCopayCents
                      ? formatMoneyInCents(lastCopayAuditRecord.lastCopayCents)
                      : 'N/A'
                  }`,
                }
              : patientData?.patient.copayCents
                ? {
                    title: 'Last updated: N/A',
                    text: 'Prior entry: N/A',
                  }
                : undefined,
            formInputComponent: InputField,
            inputProps: {
              type: 'number',
              min: 0,
              step: 0.01,
              placeholder: '0.00',
              validation: {
                setValueAs: (value) => {
                  return Math.round(value * 100)
                },
              },
            },
          },
        ]}
      />

      <PatientChargesSection
        patientId={patientId}
        subtitle="See a history of charges for this patient"
      />

      <StackView alignItems="center" direction="row">
        <StackView space={25}>
          <Typography textStyle="title-s" className="pt-core-space-100">
            Recorded payments
          </Typography>
          <Typography textStyle="body-s" color="text-base-color-fg-muted">
            See history of the payments made for this patient.
          </Typography>
        </StackView>

        {payments.length > 0 &&
          currentUser.featureFlags.includes('DETAILED_PAYMENT_HISTORY') && (
            <Button
              buttonStyle="secondary"
              icon={ArrowPathIcon}
              onClick={() => setModalType('PAYMENT_HISTORY_FILTER')}
              text="Payment history"
            />
          )}
      </StackView>

      <HistoricalPatientPaymentsTable patientId={patientId} />

      <SidepanelPortal
        showSidepanelPortal={paymentSidepanelIsOpen}
        portalContext={{
          width: 'small',
          closePortal: () => setPaymentSidepanelIsOpen(false),
          afterSidepanelFormSubmit: (paymentIntentId: string) => {
            setPaymentIntentId(paymentIntentId)
            setPaymentStatus('POLLING')
          },
        }}
      >
        <SidepanelMakePayment patientId={patientId} />
      </SidepanelPortal>

      <PaymentProcessingModal
        isOpen={paymentStatus !== null}
        variant={paymentStatus as PaymentProcessingModalVariantType}
        closeModal={() => {
          setPaymentStatus(null)
        }}
      />

      <PaymentHistoryFilterModal
        patientId={patientId}
        isOpen={modalType === 'PAYMENT_HISTORY_FILTER'}
        closeModal={() => setModalType(null)}
      />

      <BillingHistoryFilterModal
        patientId={patientId}
        isOpen={modalType === 'BILLING_HISTORY_FILTER'}
        closeModal={() => setModalType(null)}
      />
    </StackView>
  )
}

export default PatientFinancials
