import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { genderIdentities } from 'src/data/genderIdentities'

export const GenderIdentitySelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      emptyAs={null}
      includeEmptyOption={true}
      options={genderIdentities.map(({ name, value }) => ({
        value,
        display: name,
      }))}
    />
  )
}

export default GenderIdentitySelectField
