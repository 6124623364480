export interface ConditionalWrapperProps {
  shouldWrap: boolean
  wrapper: (children: React.ReactNode) => React.ReactNode
  children: React.ReactNode
}

export const ConditionalWrapper = ({
  shouldWrap,
  wrapper,
  children,
}: ConditionalWrapperProps) => (shouldWrap ? wrapper(children) : children)
