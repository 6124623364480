import { navigate, useLocation, useParams } from '@redwoodjs/router'

import { sidepanelRoute } from 'src/lib/routes'
import { SidepanelRouteContext } from 'src/utils/sidepanel'

export const useOpenSidepanel = () => {
  const location = useLocation()
  const params = useParams()

  return (sidepanelContext: SidepanelRouteContext) => {
    navigate(sidepanelRoute(sidepanelContext, location, params))
  }
}
