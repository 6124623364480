import {
  RequestPatientPortalLoginCode,
  RequestPatientPortalLoginCodeVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import PhoneInputField from 'src/components/atoms/PhoneInputField/PhoneInputField'
import Typography from 'src/components/atoms/Typography/Typography'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { formatPhoneNumber } from 'src/lib/formatters'
import { usePatientPortalContext } from 'src/providers/context/PatientPortalContext/PatientPortalContext'
import { useEffectOnce } from 'src/utils'

import { PatientPortalLoginPageLayout } from './PatientPortalLoginPageLayout'

const REQUEST_CODE = gql`
  mutation RequestPatientPortalLoginCode(
    $input: RequestPatientPortalLoginCodeInput!
  ) {
    requestPatientPortalLoginCode(input: $input) {
      ok
    }
  }
`

export const PatientPortalLoginPhoneNumberStep = ({
  onRequestCodeSuccess,
  loginMobileNumber,
}: {
  onRequestCodeSuccess: (data: { mobileNumber: string }) => void
  loginMobileNumber?: string
}) => {
  const { tenant } = usePatientPortalContext()

  const [requestLoginCode, { loading: isRequestingCode }] = useMutation<
    RequestPatientPortalLoginCode,
    RequestPatientPortalLoginCodeVariables
  >(REQUEST_CODE)

  const sendCode = (data) =>
    requestLoginCode({
      variables: {
        input: {
          tenantId: tenant.id,
          mobileNumber: data.mobileNumber,
        },
      },
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        onRequestCodeSuccess({ mobileNumber: data.mobileNumber })
      },
    })

  useEffectOnce(() => {
    if (loginMobileNumber) {
      sendCode({ mobileNumber: loginMobileNumber })
    }
  })

  return (
    <PatientPortalLoginPageLayout
      title="Welcome back"
      description="We'll use your mobile phone number to find your account if you have one."
      tenantId={tenant.id}
      submitButton={{
        text: 'Next',
        loading: isRequestingCode,
      }}
      onSubmit={(data) => sendCode(data)}
      extraDetails={
        <Typography
          color="text-base-color-fg-subtle"
          className="text-center"
        >{`Need help? Don't hesitate to reach out with questions at ${formatPhoneNumber(
          tenant.primaryPhoneNumber
        )}.`}</Typography>
      }
    >
      <FormInputList
        className="py-0"
        divider={false}
        items={[
          {
            name: 'mobileNumber',
            label: 'Mobile phone number',
            required: true,
            direction: 'col',
            formInputComponent: PhoneInputField,
            inputProps: {
              size: 'l',
              defaultValue: loginMobileNumber,
            },
          },
        ]}
      />
    </PatientPortalLoginPageLayout>
  )
}
