import { useQuery } from '@apollo/client'
import { isNil } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  GetSupervisingProviderForAppointment,
  GetSupervisingProviderForAppointmentVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import BillingProviderSelectField, {
  useDefaultBillingProvider,
} from 'src/components/atoms/BillingProviderSelectField/BillingProviderSelectField'
import Box14QualifierSelectField from 'src/components/atoms/ClaimQualifierSelectField/Box14QualifierSelectField'
import FilteredPlaceOfServiceSelectField from 'src/components/atoms/FilteredPlaceOfServiceSelectField/FilteredPlaceOfServiceSelectField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import PractitionerClinicalDetailSelectField from 'src/components/atoms/PractitionerClinicalDetailSelectField/PractitionerClinicalDetailSelectField'
import TextAreaField from 'src/components/atoms/TextAreaField'
import LocationSelectCell from 'src/components/Location/LocationSelectCell'
import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import FormInputList from 'src/components/molecules/FormInputList'
import NotAvailable from 'src/components/molecules/NotAvailable/NotAvailable'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  getBillingSummaryDefaultValues,
  SUPERVISING_PROVIDER_QUERY,
} from 'src/pages/PatientChartsPage/PatientVisits/pages/BillingPage/BillingPage'
import { BillingSummaryFormProps } from 'src/pages/PatientChartsPage/PatientVisits/pages/BillingPage/sections/BillingSummarySectionV2'
import { useVisitQuery } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const UPSERT_CLAIM = gql`
  mutation UpsertClaim($encounterId: String!, $input: UpsertClaimInput!) {
    upsertClaim(encounterId: $encounterId, input: $input) {
      id
      renderingProviderId
      renderingProvider {
        npi
        practitioner {
          id
          givenName
          familyName
        }
      }
      supervisingProviderId
      supervisingProvider {
        npi
        practitioner {
          id
          givenName
          familyName
        }
      }
      billingProvider {
        id
        name
      }
      placeOfService
      serviceFacilityId
      serviceFacility {
        id
        name
        address {
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
      hospitalServiceFacilityId
      hospitalServiceFacility {
        id
        name
        address {
          line1
          line2
          city
          state
          postalCode
          country
        }
      }
      admissionDate
      dischargeDate
      box14Date
      box14Qualifier
      box19Content
    }
  }
`

const SidepanelClinicVisitClaimDetailsEditHelper = ({
  appointmentId,
}: {
  appointmentId: string
}) => {
  const { visit, loading: loadingVisit } = useVisitQuery(appointmentId)

  const {
    data: supervisingPractitioner,
    loading: loadingSupervisingPractitioner,
  } = useQuery<
    GetSupervisingProviderForAppointment,
    GetSupervisingProviderForAppointmentVariables
  >(SUPERVISING_PROVIDER_QUERY, {
    variables: {
      appointmentId: visit.id,
    },
  })

  const defaultBillingProviderId = useDefaultBillingProvider()

  const { claimId: claimIdParam } = useParams()
  const claimId = claimIdParam ?? visit?.encounter?.claim?.id

  if (loadingVisit || loadingSupervisingPractitioner) {
    return <LoadingSpinner />
  }

  return (
    <SidepanelClinicVisitClaimDetailsEditInputs
      claimId={claimId}
      encounterId={visit.encounter?.id}
      defaultValues={getBillingSummaryDefaultValues({
        visit,
        defaultBillingProviderId,
        supervisingPractitioner,
      })}
    />
  )
}

const SidepanelClinicVisitClaimDetailsEditInputs = ({
  claimId,
  encounterId,
  defaultValues,
}: {
  claimId: string
  encounterId: string
  defaultValues: BillingSummaryFormProps['claim']
}) => {
  const { currentUser } = useEmrAuth()

  const formMethods = useForm<BillingSummaryFormProps['claim']>({
    defaultValues,
  })
  const { closeSidePanel } = useSidepanel()

  const [upsertClaim, { loading }] = useMutation(UPSERT_CLAIM)

  if (!currentUser.featureFlags.includes('UPSERT_CLAIM')) {
    return <NotAvailable />
  }

  const box14Date = formMethods.watch('box14Date')
  const box14Qualifier = formMethods.watch('box14Qualifier')
  return (
    <SidepanelPage
      header="Edit billing summary"
      description="Review and edit claim-level details. This section includes optional fields that can be completed where necessary."
    >
      <SidepanelForm
        onSubmit={async (data) => {
          void upsertClaim({
            variables: {
              encounterId,
              input: {
                ...data,
                claimId,
              },
            },
            onCompleted: () => {
              toast.success('Claim details updated')
              closeSidePanel()
            },
          })
        }}
        formMethods={formMethods}
        footerProps={{
          submitText: 'Save',
          submitting: loading,
        }}
      >
        {formMethods.formState.isLoading ? (
          <LoadingSpinner />
        ) : (
          <FormInputList
            divider={false}
            items={[
              {
                label: 'Rendering provider',
                name: 'renderingProviderId',
                required: true,
                direction: 'col',
                formInputComponent: PractitionerClinicalDetailSelectField,
                inputProps: {
                  excludeSchedulingOnlyPractitioners: true,
                },
              },
              {
                label: 'Supervising provider',
                name: 'supervisingProviderId',
                required: false,
                direction: 'col',
                formInputComponent: PractitionerClinicalDetailSelectField,
                inputProps: {
                  excludeSchedulingOnlyPractitioners: true,
                },
              },
              {
                label: 'Billing provider',
                name: 'billingProviderId',
                required: true,
                direction: 'col',
                formInputComponent: BillingProviderSelectField,
              },
              {
                label: 'Service facility location',
                name: 'serviceFacilityId',
                required: true,
                direction: 'col',
                formInputComponent: LocationSelectCell,
              },
              {
                label: 'Place of service',
                name: 'placeOfService',
                required: true,
                direction: 'col',
                formInputComponent: FilteredPlaceOfServiceSelectField,
                inputProps: {
                  optionFilter: ({ value }) => value !== 'INPATIENT_HOSPITAL',
                },
              },
              {
                label: 'Box 14: Date of current illness, injury, or pregnancy',
                name: 'box14Date',
                required: !isNil(box14Qualifier),
                direction: 'col',
                formInputComponent: DatePickerFieldThatWorks,
              },
              {
                label: 'Box 14 qualifier',
                required: !isNil(box14Date),
                name: 'box14Qualifier',
                direction: 'col',
                formInputComponent: Box14QualifierSelectField,
              },
              {
                label: 'Box 19: Additional claim information',
                name: 'box19Content',
                direction: 'col',
                formInputComponent: TextAreaField,
              },
            ]}
          />
        )}
      </SidepanelForm>
    </SidepanelPage>
  )
}

const SidepanelClinicVisitClaimDetailsEdit = () => {
  const { appointmentId } = useParamsRW()

  if (!appointmentId) {
    return <NotAvailable />
  }
  return (
    <SidepanelClinicVisitClaimDetailsEditHelper appointmentId={appointmentId} />
  )
}

export default SidepanelClinicVisitClaimDetailsEdit
