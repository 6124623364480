import { ReactElement } from 'react'

import { compact } from 'lodash'
import { ChargeItemFragment } from 'types/graphql'

import Alert from '../Alert/Alert'

type AutomationAction = ChargeItemFragment['automationActions'][number]

const AutomationNotification = ({
  action,
  testId,
}: {
  action: AutomationAction
  testId?: string
}): ReactElement => {
  const heading = action.heading
  const title = action?.title
  const description = action.descriptionBullets
    ?.map((bullet) => `• ${bullet}`)
    .join('\n')

  return (
    <Alert
      title={heading}
      description={compact([title, description]).join('\n')}
      style="info"
      backgroundStyle="white"
      testId={testId}
    />
  )
}

export default AutomationNotification
