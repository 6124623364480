import { DeletePatientScoreInput } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'
const UPSERT_PATIENT_SCORE_MUTATION = gql`
  mutation DeletePatientScore($input: DeletePatientScoreInput!) {
    deletePatientScore(input: $input) {
      id
    }
  }
`
export const useDeletePatientScore = () => {
  return useMutation<DeletePatientScoreInput>(UPSERT_PATIENT_SCORE_MUTATION, {
    onCompleted: () => {
      toast.success('Patient score removed')
    },
    refetchQueries: ['PatientScores'],
  })
}
