import type { LocationsQueryForSelect } from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import SelectField, {
  SelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { AddressJoinOption, formatAddress } from 'src/lib/formatters'

export const QUERY = gql`
  query LocationsQueryForSelect {
    locations {
      id
      name
      address {
        id
        line1
        line2
        city
        state
        postalCode
        country
      }
    }
  }
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  locations,
  name,
  testId,
  required,
}: CellSuccessProps<LocationsQueryForSelect> & SelectFieldProps) => {
  return (
    <SelectField
      name={name}
      data-testid={testId}
      emptyAs={null}
      required={required}
      options={[
        ...locations.map((location) => {
          return {
            value: location.id,
            display: `${location.name}: ${formatAddress(
              location.address,
              AddressJoinOption.COMMA
            )}`,
          }
        }),
      ]}
    />
  )
}
