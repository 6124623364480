import { match } from 'ts-pattern'

import StackView from 'src/components/atoms/StackView/StackView'
import Typography, {
  TextStyle,
} from 'src/components/atoms/Typography/Typography'

export interface SectionHeaderProps {
  title: string
  description?: string
  actions?: React.ReactNode
  size?: 'xl' | 'l' | 'm' | 's' | 'xs'
}

export const SectionHeader = ({
  title,
  description,
  actions,
  size = 'm',
}: SectionHeaderProps) => {
  const titleStyle = match<typeof size, TextStyle>(size)
    .with('xl', () => 'title-l')
    .with('l', () => 'title-m')
    .with('m', () => 'title-s')
    .with('s', () => 'title-xs')
    .with('xs', () => 'interface-strong-s')
    .exhaustive()

  const bodyStyle = match<typeof size, TextStyle>(size)
    .with('xl', 'l', 'm', () => 'body-s')
    .with('s', 'xs', () => 'body-xs')
    .exhaustive()

  return (
    <StackView
      direction="row"
      justifyContent="between"
      alignItems="start"
      gap={100}
    >
      <StackView gap={50}>
        <Typography textStyle={titleStyle}>{title}</Typography>
        {description ? (
          <Typography color="text-base-color-fg-muted" textStyle={bodyStyle}>
            {description}
          </Typography>
        ) : null}
      </StackView>

      {actions}
    </StackView>
  )
}
