import { formatMoneyInCents } from 'common/utils'
import { format } from 'date-fns'
import { capitalize } from 'lodash'
import { useParams } from 'react-router-dom'
import { GetPatientPaymentPlan, PaymentPlanPaymentStatus } from 'types/graphql'

import { toast } from '@redwoodjs/web/toast'

import Badge from 'src/components/atoms/Badge/Badge'
import Box from 'src/components/atoms/Box'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { PAYMENT_STATUS } from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'
import PatientPaymentPlanBalanceCard from 'src/components/PatientPaymentPlanBalanceCard/PatientPaymentPlanBalanceCard'
import PatientPaymentPlanPaymentMethodCard from 'src/components/PatientPaymentPlanPaymentMethodCard/PatientPaymentPlanPaymentMethodCard'
import {
  useCancelPatientPaymentPlan,
  useGetPatientPaymentPlan,
  useGetPatientPaymentPlanConfig,
} from 'src/hooks/usePatientPaymentPlans/usePatientPaymentPlans'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const DetailsSection = ({
  patientPaymentPlan,
}: {
  patientPaymentPlan: GetPatientPaymentPlan['patientPaymentPlan']
}) => {
  return (
    <StackView space={100}>
      <Typography textStyle="title-xs">Details</Typography>
      <PatientPaymentPlanBalanceCard
        patientPaymentPlanId={patientPaymentPlan?.id}
      />
      <PatientPaymentPlanPaymentMethodCard
        patientPaymentPlanId={patientPaymentPlan?.id}
      />
    </StackView>
  )
}

const UpcomingPaymentPlanPayments = ({
  payments,
}: {
  payments: GetPatientPaymentPlan['patientPaymentPlan']['upcomingPaymentPlanPayments']
}) => {
  if (payments.length === 0) return null
  return (
    <StackView testId="upcoming-payment-plan-payments" space={100}>
      <Typography textStyle="title-xs">Upcoming payments</Typography>

      <StackView space={25}>
        {payments.map((payment, index) => (
          <PaymentDisplay
            key={`payment-display-${index}`}
            date={new Date(payment.dueAt)}
            brand={payment.paymentMethod?.brand}
            last4={payment.paymentMethod?.last4}
            amountCents={payment.amountCents}
            status="PRE_APPROVED"
          />
        ))}
      </StackView>
    </StackView>
  )
}

const PastPaymentPlanPayments = ({
  payments,
}: {
  payments: GetPatientPaymentPlan['patientPaymentPlan']['paymentPlanPayments']
}) => {
  if (payments.length === 0) return null
  return (
    <StackView testId="past-payment-plan-payments" space={100}>
      <Typography textStyle="title-xs">Past payments</Typography>

      <StackView space={25}>
        {payments.map((payment) => (
          <PaymentDisplay
            key={payment.id}
            date={new Date(payment.createdAt)}
            brand={payment.paymentMethod?.brand}
            last4={payment.paymentMethod?.last4}
            amountCents={payment.amountCents}
            status={payment.status}
          />
        ))}
      </StackView>
    </StackView>
  )
}

const PaymentDisplay = ({
  date,
  brand,
  last4,
  amountCents,
  status,
}: {
  date: Date
  brand: string
  last4: string
  amountCents: number
  status: PaymentPlanPaymentStatus | 'PRE_APPROVED'
}) => {
  return (
    <Box
      testId="payment-display"
      verticalPadding={75}
      horizontalPadding={100}
      rounded
      border
    >
      <StackView direction="row" space={100} alignItems="center">
        <StackView>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {format(date, 'PP')}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {capitalize(brand)} ending in {last4}
          </Typography>
        </StackView>
        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
        >
          {formatMoneyInCents(amountCents)}
        </Typography>
        <Badge
          color={PAYMENT_STATUS[status].color}
          text={PAYMENT_STATUS[status].label}
          size="s"
          showDot
        />
      </StackView>
    </Box>
  )
}

const SidepanelPatientPaymentPlanView = () => {
  const { closeSidePanel } = useSidepanel()
  const params = useParams()
  const { patientPaymentPlanId } = params

  const { patientPaymentPlan, loading } =
    useGetPatientPaymentPlan(patientPaymentPlanId)
  const { cancelPaymentPlan, loading: cancellingPaymentPlan } =
    useCancelPatientPaymentPlan(patientPaymentPlanId)
  const { paymentPlanCancellationDirectPatientChargeBillingCodeId } =
    useGetPatientPaymentPlanConfig()

  const showCancelPaymentPlanButton =
    paymentPlanCancellationDirectPatientChargeBillingCodeId

  return (
    <SidepanelPage
      testId="patient-payment-plan-view"
      header="Payment plan"
      description="View details about the payment plan for the patient."
      loading={loading}
    >
      <StackView className="grow p-core-space-150" space={150}>
        <DetailsSection patientPaymentPlan={patientPaymentPlan} />

        {patientPaymentPlan?.upcomingPaymentPlanPayments.length > 0 && (
          <>
            <Divider />
            <UpcomingPaymentPlanPayments
              payments={patientPaymentPlan.upcomingPaymentPlanPayments}
            />
          </>
        )}

        {patientPaymentPlan?.paymentPlanPayments.length > 0 && (
          <>
            <Divider />
            <PastPaymentPlanPayments
              payments={patientPaymentPlan.paymentPlanPayments}
            />
          </>
        )}

        {showCancelPaymentPlanButton && (
          <DestructiveAction
            title="Destructive actions"
            description="These changes are permanent, use caution when removing."
            buttonText="Cancel payment plan"
            doDestructiveAction={async () => {
              await cancelPaymentPlan({
                variables: { id: patientPaymentPlanId },
                onCompleted: () => {
                  toast.success('Payment plan has been cancelled')
                  closeSidePanel()
                },
              })
            }}
            modal={{
              title: 'Are you sure?',
              content: `Cancelling the current payment plan is permanent, use caution when removing.`,
              confirmText: 'Cancel payment plan',
              hideIcon: true,
              testId: 'cancel-payment-plan-modal',
            }}
            destructiveActionIsLoading={cancellingPaymentPlan}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientPaymentPlanView
