import { NoSymbolIcon } from '@heroicons/react/24/solid'
import { observationConcepts } from 'common/cdr/concepts/observations/index'
import capitalize from 'lodash/capitalize'
import { SocialHistory } from 'types/graphql'

import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { useGetLexicalHtml } from 'src/components/atoms/WysiwygField/hooks/useGetLexicalHtml'

export const SocialHistoryNoRowsOverlay = () => {
  return (
    <StackView justifyContent="center" alignItems="center" className="h-96">
      <NoSymbolIcon className="h-8 w-8 text-gray-400" />
      <Space space={50} />
      <Typography textStyle="subtitle">No Social history recorded</Typography>
    </StackView>
  )
}

export const SocialHistoryCategoryColumn = {
  colId: 'category',
  headerName: 'Category',
  cellRenderer: ({ data }) => {
    return (
      <StackView className="h-full" justifyContent="center">
        <Typography fontWeight="semibold">{data?.category}</Typography>
      </StackView>
    )
  },
}

const formatStringWithPunctuation = ({
  input,
  punctuation,
  invertAfter = true,
  brackets = false,
}: {
  input: string
  punctuation: string
  invertAfter: boolean
  brackets: boolean
}) => {
  if (!input || input.length <= 1 || !punctuation)
    return brackets ? `(${input})` : input

  // handle strings like MDMA (3,4-Methylenedioxymethamphetamine)
  // assumes the punctuation and brackets are in the 'correct' place
  // i.e. not strings like `,)test(
  if (
    input.includes(punctuation) &&
    input.includes('(') &&
    input.includes(')')
  ) {
    return input
  }

  const parts = input.split(punctuation)
  if (parts.length === 1) return input

  const prevAfterPunctuation = parts[1].trim()
  const prevBeforePunctuation = parts[0].trim()

  if (!invertAfter && !brackets) {
    return `${prevBeforePunctuation} ${prevAfterPunctuation}`
  }

  const newBeforePunctuation = invertAfter
    ? capitalize(prevAfterPunctuation)
    : capitalize(prevBeforePunctuation)

  const newAfterPunctuation =
    invertAfter && brackets
      ? `(${capitalize(prevBeforePunctuation)})`
      : invertAfter
        ? capitalize(prevBeforePunctuation)
        : brackets
          ? `(${capitalize(prevAfterPunctuation)})`
          : prevAfterPunctuation

  return `${newBeforePunctuation} ${newAfterPunctuation}`
}

const emptyPlaceholder = '\u2014'

const LexicalHTML = ({ data }): React.JSX.Element => {
  const html = useGetLexicalHtml(data?.answer?.display)
  return html
}

export const SocialHistoryValueColumn = {
  colId: 'value',
  headerName: 'Value',
  wrapText: true,

  cellRenderer: ({ data }) => {
    if (!data || (!data.display && !data.answer && !data.answers)) {
      return <Typography>{emptyPlaceholder}</Typography>
    }

    return (
      <StackView className="h-full" justifyContent="center">
        {data.display && (
          <Typography className="capitalize">{data.display}</Typography>
        )}
        {data.answer && data.category !== 'Comment' && (
          <Typography className="capitalize">{data.answer.display}</Typography>
        )}
        {data.answer && data.category === 'Comment' && (
          <LexicalHTML data={data} />
        )}
        {data.answers && data.questionCode && (
          <Typography>
            {data.questionCode ===
              observationConcepts['DRUG_USE_STATUS'].code &&
              data.answers
                .filter(
                  (answer) =>
                    answer.code === observationConcepts['PRESENCE_YES'].code
                )
                .map((answer) => answer.questionDisplay)
                .map((answer) =>
                  formatStringWithPunctuation({
                    input: answer,
                    punctuation: ',',
                    invertAfter: true,
                    brackets: true,
                  })
                )
                .join(', ')}

            {data.questionCode ===
              observationConcepts['FOREIGN_TRAVEL_HISTORY'].code &&
              data.answers
                .map((answer) => answer.display)
                .map((answer) =>
                  formatStringWithPunctuation({
                    input: answer,
                    punctuation: ',',
                    invertAfter: true,
                    brackets: false,
                  })
                )
                .join(', ')}
          </Typography>
        )}
      </StackView>
    )
  },
}

export const keyToCategoryDisplay: {
  [key in keyof SocialHistory]: string
} = {
  travelHistory: 'Travel History',
  smokingTobaccoUse: 'Smoking Status',
  smokelessTobaccoUse: 'Smokeless Tobacco Use',
  vapingUse: 'E-Cigarette / Vaping Status',
  alcoholUse: 'Alcohol Use',
  drugUse: 'Recreational and/or Illicit Drug Use',
  comment: 'Comment',
}

export type Answer = {
  category?: string
  questionCode?: string
  questionDisplay?: string
  display?: string
  code?: string
  lastUpdatedAt?: string // UTC
}

export type SocialHistoryResponse = {
  category: string
  questionCode: string
  lastUpdatedAt?: string // UTC
  answer?: Answer
  answers?: Answer[]
}
