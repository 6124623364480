import { compact } from 'lodash'
import { useParams } from 'react-router-dom'
import {
  MarkChargeItemDoNotBill,
  DiagnosisCode,
  FindChargeItem,
  ChargeItemFragment,
  UpdateChargeItemForEncounter,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import ChargeItemModifierMultiSelectField from 'src/components/atoms/ChargeItemModifierMultiSelectField/ChargeItemModifierMultiSelectField'
import InputField from 'src/components/atoms/InputField/InputField'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import GenericFormFieldArray from 'src/components/molecules/GenericFormFieldArray/GenericFormFieldArray'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import BillingCodeSearchField from 'src/components/organisms/BillingCodeSearchField/BillingCodeSearchField'
import DiagnosisSearchField from 'src/components/organisms/DiagnosisSearchField/DiagnosisSearchField'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import { CHARGE_ITEM_FRAGMENT } from 'src/pages/PatientChartsPage/PatientVisits/fragments'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const UPDATE_CHARGE_ITEM_MUTATION = gql`
  mutation UpdateChargeItemForEncounter(
    $chargeItemId: String!
    $input: UpdateChargeItemInput!
  ) {
    updateChargeItem(chargeItemId: $chargeItemId, input: $input) {
      id
      ...ChargeItemFragment
    }
  }
  ${CHARGE_ITEM_FRAGMENT}
`

const MARK_CHARGE_ITEM_DO_NOT_BILL_MUTATION = gql`
  mutation MarkChargeItemDoNotBill($chargeItemId: String!) {
    markChargeItemDoNotBill(id: $chargeItemId) {
      id
      doNotBill
    }
  }
`

const useMarkChargeItemDoNotBill = (chargeItemId: string) => {
  return useMutation<MarkChargeItemDoNotBill>(
    MARK_CHARGE_ITEM_DO_NOT_BILL_MUTATION,
    {
      refetchQueries: ['FindPatientVisit'],
      variables: {
        chargeItemId,
      },
      onCompleted: () => {
        toast.success('Billing code successfully removed')
      },
    }
  )
}

const FIND_CHARGE_ITEM_QUERY = gql`
  query FindChargeItem($chargeItemId: String!) {
    chargeItem(id: $chargeItemId) {
      ...ChargeItemFragment
    }
  }
  ${CHARGE_ITEM_FRAGMENT}
`

const useFindChargeItem = (chargeItemId: string) => {
  return useQuery<FindChargeItem>(FIND_CHARGE_ITEM_QUERY, {
    variables: {
      chargeItemId,
    },
  })
}

export const useUpdateChargeItem = () => {
  return useMutation<UpdateChargeItemForEncounter>(
    UPDATE_CHARGE_ITEM_MUTATION,
    {
      refetchQueries: ['FindPatientVisit'],
      onCompleted: () => {
        toast.success('Billing code updated successfully')
      },
    }
  )
}

const SidepanelChargeItemEdit = () => {
  const { chargeId: chargeItemId } = useParams()
  const { patientId } = useIsPatientChart()
  const { closeSidePanel } = useSidepanel()
  if (!chargeItemId) {
    closeSidePanel()
  }
  if (!patientId) {
    closeSidePanel()
  }

  const { data, loading } = useFindChargeItem(chargeItemId)

  if (loading) {
    return <LoadingSpinner />
  }
  const { chargeItem } = data
  const chargeType = getChargeType(chargeItem)

  return (
    <SidepanelPage
      testId="sidepanel-charge-item-edit"
      header={`${headerTextByType[chargeType]} ${chargeItem.billingCode.code}`}
      description={
        chargeItem.isPrimary
          ? 'This is the primary billing code for the visit'
          : descriptionTextByType[chargeType]
      }
    >
      <ChargeItemForm chargeItem={chargeItem} patientId={patientId} />
    </SidepanelPage>
  )
}

const ChargeItemForm = ({
  chargeItem,
  patientId,
}: {
  chargeItem: ChargeItemFragment
  patientId: string
}) => {
  const [updateChargeItem, { loading: updatingChargeItem }] =
    useUpdateChargeItem()
  const [markChargeItemDoNotBill, { loading: markingChargeItemDoNotBill }] =
    useMarkChargeItemDoNotBill(chargeItem.id)
  const { closeSidePanel } = useSidepanel()
  const formMethods = useForm({
    defaultValues: {
      diagnosisCodes:
        chargeItem.service.__typename === 'NoLinkedService'
          ? chargeItem.service.conditions?.map((c) => ({
              code: c.diagnosisCode,
            }))
          : chargeItem.service.order.diagnosisCodes?.map((diagnosisCode) => ({
              code: diagnosisCode,
            })),
      ...chargeItem,
    },
  })

  const onSubmit = async (data) => {
    const { billingCode, diagnosisCodes, modifiers, quantity } = data

    const diagnosisCodeIds = diagnosisCodes
      ? compact(diagnosisCodes.map((diagnosisCode) => diagnosisCode.code.id))
      : []

    await updateChargeItem({
      variables: {
        chargeItemId: chargeItem.id,
        input: {
          diagnosisCodeIds,
          billingCodeId: billingCode.id,
          modifiers: compact(modifiers),
          quantity,
        },
      },
    })
    closeSidePanel()
  }

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Save',
        submitting: updatingChargeItem,
      }}
      onSubmit={onSubmit}
      formMethods={formMethods}
    >
      <FormInputList
        items={[
          {
            name: 'billingCode',
            label: 'Billing code',
            subtitle: chargeItem.billingCode.isVisitCode
              ? 'Only Well Child and E&M primary billing codes are available.'
              : undefined,
            required: true,
            direction: 'col',
            formInputComponent: BillingCodeSearchField,
            inputProps: {
              searchSet: chargeItem.billingCode.isVisitCode
                ? 'VISIT'
                : 'NON_VISIT',
              encounterId: getEncounterId(chargeItem),
            },
          },
          {
            name: 'quantity',
            label: 'Billing code units',
            required: true,
            direction: 'col',
            formInputComponent: InputField,
            inputProps: {
              type: 'number',
              defaultValue: 1,
            },
          },
          {
            name: 'modifiers',
            label: 'Billing code modifier(s)',
            direction: 'col',
            formInputComponent: ChargeItemModifierMultiSelectField,
          },
          {
            name: 'diagnosisCodes',
            label: 'Diagnosis code(s)',
            subtitle: chargeItem.isPrimary
              ? 'The primary diagnosis code for the visit is always linked to the primary billing code. Additional diagnosis codes can be added here.'
              : undefined,
            required: !chargeItem.isPrimary,
            formInputComponent: GenericFormFieldArray,
            direction: 'col',
            inputProps: {
              formInputComponent: DiagnosisSearchField,
              nestedName: 'code',
              addButtonLabel: 'Add additional diagnosis',
              inputProps: {
                disableUserFilterSelection: true,
                defaultFilters: ['ENCOUNTER_ACTIVE'],
                patientId,
                encounterId: getEncounterId(chargeItem),
                searchSet: 'ALL',
                nestedValidation: {
                  validate: (value: DiagnosisCode) => {
                    return !value ? 'Diagnosis code is required' : true
                  },
                },
              },
            },
          },
        ]}
      />
      <DestructiveAction
        title="Destructive Actions"
        description="These changes are permanent, use caution when removing."
        buttonText="Remove billing code"
        modal={{
          title: 'Remove billing code',
          content: `Are you sure you would like to remove billing code ${chargeItem.billingCode.code}: ${chargeItem.billingCode.description}? This action cannot be undone.`,
          confirmText: 'Remove',
        }}
        doDestructiveAction={async () => {
          await markChargeItemDoNotBill()
        }}
        destructiveActionIsLoading={markingChargeItemDoNotBill}
        postDestructiveAction={closeSidePanel}
      />
    </SidepanelForm>
  )
}

enum ChargeType {
  VISIT,
  ADDITIONAL,
  ORDER_LINKED,
}

const getEncounterId = (chargeItem: ChargeItemFragment) =>
  chargeItem.service.__typename === 'NoLinkedService'
    ? chargeItem.service.encounterId
    : chargeItem.service.order.encounterReference.split('/')[1]

const getChargeType = (chargeItem: ChargeItemFragment) => {
  if (chargeItem.service.__typename !== 'NoLinkedService') {
    return ChargeType.ORDER_LINKED
  }
  if (chargeItem.billingCode.isVisitCode) {
    return ChargeType.VISIT
  }
  return ChargeType.ADDITIONAL
}

const headerTextByType: { [key in ChargeType]: string } = {
  [ChargeType.VISIT]: 'Edit visit type billing code',
  [ChargeType.ADDITIONAL]: 'Edit additional billing code',
  [ChargeType.ORDER_LINKED]: 'Edit order-linked billing code',
}

const descriptionTextByType: { [key in ChargeType]: string } = {
  [ChargeType.VISIT]: 'This billing code is a visit level billing code.',
  [ChargeType.ADDITIONAL]:
    'These billing codes would be in addition to primary billing code(s) and order-linked billing code(s) already included for the visit.',
  [ChargeType.ORDER_LINKED]:
    'Only modifiers can be edited for this order-linked billing code. Diagnosis code and other order-related edits must be completed through the orders and diagnoses section.',
}

export default SidepanelChargeItemEdit
