import { Fragment } from 'react'

import {
  CheckCircleIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  PencilIcon,
} from '@heroicons/react/24/solid'
import { formatDate } from '@mobiscroll/react'
import clsx from 'clsx'
import { appointmentTypeDisplay } from 'common/data/appointmentTypes'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { Outlet, useParams as useNestedParams } from 'react-router-dom'
import { EncounterMode, FindPatientVisit } from 'types/graphql'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import AppointmentRoomSelect from 'src/components/atoms/AppointmentRoomSelect/AppointmentRoomSelect'
import AppointmentStatusSelect from 'src/components/atoms/AppointmentStatusSelect'
import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import ChevronLink from 'src/components/atoms/ChevronLink/ChevronLink'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import TemplateSelectButton from 'src/components/molecules/TemplateSelectButton/TemplateSelectButton'
import {
  VISIT_STEPPER_SIZE,
  useLocalSettings,
} from 'src/providers/context/LocalSettingsContext'

import {
  BillingOrdersWarningModal,
  useBillingOrdersWarningConfirmation,
} from './BillingOrdersWarningModal'
import { EncounterModeDropdown } from './EncounterModeDropdown'
import BillingPage from './pages/BillingPage/BillingPage'
import ClinicalReviewPage from './pages/ClinicalReviewPage/ClinicalReviewPage'
import VisitDocumentation from './pages/DocumentationPage'
import IntakePage from './pages/IntakePage/IntakePage'
import OrdersDiagnosesPage from './pages/OrdersDiagnosesPage'
import PatientEducationPage from './pages/PatientEducationPage/PatientEducationPage'
import PatientMedicationsPage from './pages/PatientMedicationsPage/PatientMedicationsPage'
import ReviewPage from './pages/ReviewPage/ReviewPage'
import SimplifiedDocumentationPage from './pages/SimplifiedDocumentationPage/SimplifiedDocumentationPage'
import VaccineAdministrationPage from './pages/VaccineAdministrationPage/VaccineAdministrationPage'
import VisitChargesPage from './pages/VisitChargesPage/VisitChargesPage'
import {
  PendedOrdersWarningModal,
  usePendedOrdersConfirmation,
} from './PendedOrdersWarningModal'
import { useSignVisitV2 } from './useSignVisit'
import { useVisit, useVisitQuery } from './useVisit'
import VisitAudioRecorder from './VisitAudioRecorder'

interface VisitFlowNavItem {
  label: string
  to: string
  abbreviation: string
  element: JSX.Element
  encounterModes: EncounterMode[]
}

export const visitFlowNavConfig: VisitFlowNavItem[] = [
  {
    label: 'Intake, tests and screenings',
    to: 'intake',
    abbreviation: 'IN',
    element: <IntakePage />,
    encounterModes: ['STANDARD', 'DIRECT_PRIMARY_CARE'],
  },
  {
    label: 'Vaccine administration',
    to: 'vaccines',
    abbreviation: 'VA',
    element: <VaccineAdministrationPage />,
    encounterModes: ['STANDARD', 'DIRECT_PRIMARY_CARE'],
  },
  {
    label: 'Clinical review',
    to: 'clinical',
    abbreviation: 'CR',
    element: <ClinicalReviewPage />,
    encounterModes: ['STANDARD', 'DIRECT_PRIMARY_CARE'],
  },
  {
    label: 'Orders and diagnoses',
    to: 'orders',
    abbreviation: 'OD',
    element: <OrdersDiagnosesPage />,
    encounterModes: ['STANDARD', 'SIMPLIFIED', 'DIRECT_PRIMARY_CARE'],
  },
  {
    label: 'Documentation',
    to: 'documentation',
    abbreviation: 'DO',
    element: <VisitDocumentation />,
    encounterModes: ['STANDARD', 'DIRECT_PRIMARY_CARE'],
  },
  {
    label: 'Documentation',
    to: 'simplified-documentation',
    abbreviation: 'DO',
    element: <SimplifiedDocumentationPage />,
    encounterModes: ['SIMPLIFIED'],
  },
  {
    label: 'Medications',
    to: 'medications',
    abbreviation: 'ME',
    element: <PatientMedicationsPage />,
    encounterModes: ['STANDARD', 'SIMPLIFIED', 'DIRECT_PRIMARY_CARE'],
  },
  {
    label: 'Patient education',
    to: 'education',
    abbreviation: 'PE',
    element: <PatientEducationPage />,
    encounterModes: ['STANDARD', 'SIMPLIFIED', 'DIRECT_PRIMARY_CARE'],
  },
  {
    label: 'Charges',
    to: 'charges',
    abbreviation: 'CH',
    element: <VisitChargesPage />,
    encounterModes: ['SIMPLIFIED', 'DIRECT_PRIMARY_CARE'],
  },
  {
    label: 'Billing',
    to: 'billing',
    abbreviation: 'BL',
    element: <BillingPage />,
    encounterModes: ['STANDARD'],
  },
  {
    label: 'Visit review',
    to: 'review',
    abbreviation: 'VR',
    element: <ReviewPage />,
    encounterModes: ['STANDARD', 'DIRECT_PRIMARY_CARE'],
  },
]

export type VisitFlowRoute = (typeof visitFlowNavConfig)[number]['to']

const VisitHeader = ({
  patientId,
  visit,
  currentPractitioner,
}: {
  patientId: string
  visit: FindPatientVisit['appointment']
  currentPractitioner: FindPatientVisit['practitioner']
}) => {
  const { hasRole, currentUser } = useEmrAuth()
  const [signVisit, { loading: signingVisit }] = useSignVisitV2()
  const [
    billingWarningConfirmationState,
    waitForBillingWarningConfirmation,
    billingStateIsLoading,
  ] = useBillingOrdersWarningConfirmation({
    patientId,
    appointmentId: visit?.id,
  })
  const [pendedOrdersConfirmationState, waitForPendedOrdersConfirmation] =
    usePendedOrdersConfirmation({
      patientId,
      appointmentId: visit?.id,
      orders: visit?.encounter?.orders ?? [],
    })

  return (
    <>
      <StackView
        direction="row"
        justifyContent="between"
        alignItems="center"
        className="border-b border-gray-100 px-4 py-2"
      >
        <StackView
          className="w-8/12"
          direction="row"
          gap={25}
          wrap
          fullWidth={false}
        >
          <ChevronLink
            text="Back to visits"
            className="pr-2"
            onClick={() => {
              navigate(
                routes.patientChartsGlob({
                  id: patientId,
                  glob: 'visits',
                })
              )
            }}
          />
          <Typography noWrap>
            {format(parseISO(visit.start), 'MMM dd, yyyy')}
          </Typography>
          <Typography>·</Typography>
          <Typography noWrap>
            {formatDate('h:mmA', parseISO(visit.start))}
          </Typography>
          <Typography>·</Typography>
          <Typography noWrap>
            {formatDisplayName(visit.practitioner)}
          </Typography>
          <Typography>·</Typography>
          {visit.appointmentDefinitions.map(({ id, type }) => (
            <Fragment key={id}>
              <Typography noWrap>{appointmentTypeDisplay[type]}</Typography>
            </Fragment>
          ))}
        </StackView>
        <StackView
          direction="row"
          gap={25}
          fullWidth={false}
          alignItems="center"
        >
          <FeatureFlagged flagName="SIMPLIFIED_VISIT">
            <EncounterModeDropdown visit={visit} />
          </FeatureFlagged>
          {visit.chartingStatus === 'COMPLETE' &&
          hasRole('PRACTITIONER') &&
          !currentUser.featureFlags.includes('SIMPLIFIED_VISIT') ? (
            <Button
              testId="addend-visit-button"
              text="Addend note"
              className="!py-1.5"
              icon={PencilIcon}
              disabled={billingStateIsLoading}
              loading={signingVisit}
              onClick={async () => {
                const { confirmed: billingWarningConfirmed } =
                  await waitForBillingWarningConfirmation()
                if (!billingWarningConfirmed) return

                const { confirmed: pendedOrdersWarningConfirmed } =
                  await waitForPendedOrdersConfirmation()
                if (!pendedOrdersWarningConfirmed) return

                return signVisit({
                  visit,
                  currentPractitioner,
                })
              }}
            />
          ) : (
            <>
              <AppointmentStatusSelect appointmentId={visit.id} />
              <AppointmentRoomSelect appointmentId={visit.id} />
            </>
          )}

          <Box className="mr-1 border-r pr-2">
            <TemplateSelectButton />
          </Box>

          <FeatureFlagged flagName="AI_DOCUMENTATION">
            <Box>
              <VisitAudioRecorder
                key={visit?.encounter?.visitAudioRecordings?.length}
                appointmentId={visit.id}
              />
            </Box>
          </FeatureFlagged>

          <FeatureFlagged flagName="SIMPLIFIED_VISIT">
            <Button
              testId={
                visit.chartingStatus === 'COMPLETE'
                  ? 'addend-visit-button'
                  : 'sign-visit-button'
              }
              text={
                visit.chartingStatus === 'COMPLETE'
                  ? 'Addend note'
                  : 'Sign note'
              }
              className="!py-1.5"
              icon={PencilIcon}
              disabled={billingStateIsLoading}
              loading={signingVisit}
              onClick={async () => {
                const { confirmed: billingWarningConfirmed } =
                  await waitForBillingWarningConfirmation()
                if (!billingWarningConfirmed) return

                const { confirmed: pendedOrdersWarningConfirmed } =
                  await waitForPendedOrdersConfirmation()
                if (!pendedOrdersWarningConfirmed) return

                return signVisit({
                  visit,
                  currentPractitioner,
                })
              }}
            />
          </FeatureFlagged>
        </StackView>
      </StackView>
      <BillingOrdersWarningModal
        isOpen={billingWarningConfirmationState.isConfirming}
        onConfirm={billingWarningConfirmationState.confirm}
        onCancel={billingWarningConfirmationState.cancel}
        action="SIGN_VISIT"
      />
      <PendedOrdersWarningModal
        isOpen={pendedOrdersConfirmationState.isConfirming}
        onConfirm={pendedOrdersConfirmationState.confirm}
        onCancel={pendedOrdersConfirmationState.cancel}
        action="SIGN_VISIT"
      />
    </>
  )
}

const CheckOrUncheckIcon = ({ checked }: { checked: boolean }) => {
  return (
    <>
      {checked ? (
        <CheckCircleIcon className="-mr-[2px] h-[24px] w-[24px] fill-success" />
      ) : (
        <div className="h-5 w-5 rounded-full border"></div>
      )}
    </>
  )
}

const StepperNavLink = ({
  label,
  route,
  collapsed,
  description,
  visit,
}: {
  label: string
  route: VisitFlowRoute
  collapsed?: boolean
  description?: string
  visit: FindPatientVisit['appointment']
}) => {
  const location = useLocation()
  const { navigateVisitFlow } = useVisit()
  const match = location.pathname.includes(route)

  return (
    <Typography
      className="hover:cursor-pointer"
      onClick={() => navigateVisitFlow(route)}
    >
      {collapsed ? (
        <Box
          className="flex h-5 w-5 items-center justify-center rounded-full"
          color={match ? 'bg-base-color-fg-brand' : 'bg-base-color-fg-subtle'}
        >
          <Typography color="text-base-color-fg-emphasis" size="xs">
            {label}
          </Typography>
        </Box>
      ) : (
        <StackView direction="row" alignItems="center" wrap>
          <Box
            className="-ml-[18px] h-2 w-2 rounded-full"
            color={match ? 'bg-base-color-fg-brand' : 'bg-base-color-fg-subtle'}
          />
          <Typography
            color={
              match ? 'text-base-color-fg-brand' : 'text-base-color-fg-subtle'
            }
            className="ml-4"
            noWrap
          >
            {label}
          </Typography>

          {description && (
            <Typography
              color="text-base-color-fg-muted"
              size="xs"
              noWrap
              className="grow text-right"
            >
              {description}
            </Typography>
          )}

          {match && route === 'clinical' && (
            <StackView className="w-100 pt-core-space-50" space={25}>
              <StackView direction="row">
                <Typography
                  className="grow pl-4"
                  color="text-base-color-fg-muted"
                >
                  History
                </Typography>
                <CheckOrUncheckIcon checked={!!visit.historyReviewedAt} />
              </StackView>
              <StackView direction="row">
                <Typography
                  className="grow pl-4"
                  color="text-base-color-fg-muted"
                >
                  Results
                </Typography>
                <CheckOrUncheckIcon checked={!!visit.resultsReviewedAt} />
              </StackView>
              <StackView direction="row" alignItems="center">
                <Typography
                  className="grow pl-4"
                  color="text-base-color-fg-muted"
                >
                  Immunization
                </Typography>
                <CheckOrUncheckIcon checked={!!visit.immunizationsReviewedAt} />
              </StackView>
              <StackView direction="row">
                <Typography
                  className="grow pl-4"
                  color="text-base-color-fg-muted"
                >
                  Growth charts
                </Typography>
                <CheckOrUncheckIcon checked={!!visit.growthChartsReviewedAt} />
              </StackView>
              <StackView direction="row">
                <Typography
                  className="grow pl-4"
                  color="text-base-color-fg-muted"
                >
                  Documents
                </Typography>
                <CheckOrUncheckIcon checked={!!visit.documentsReviewedAt} />
              </StackView>
            </StackView>
          )}
        </StackView>
      )}
    </Typography>
  )
}

const Stepper = ({
  visit,
}: {
  patientId: string
  visit: FindPatientVisit['appointment']
}) => {
  const { visitStepperSize, saveSettings } = useLocalSettings()
  const isDefaultVisitStepperSize =
    visitStepperSize === VISIT_STEPPER_SIZE.DEFAULT

  const onToggleClicked = () =>
    saveSettings({
      visitStepperSize: isDefaultVisitStepperSize
        ? VISIT_STEPPER_SIZE.SMALL
        : VISIT_STEPPER_SIZE.DEFAULT,
    })

  const clinicalReviewNumItems = [
    visit.immunizationsReviewedAt,
    visit.growthChartsReviewedAt,
    visit.historyReviewedAt,
    visit.resultsReviewedAt,
    visit.documentsReviewedAt,
  ].filter(Boolean).length

  return (
    <StackView
      className="border-r p-4"
      space={50}
      fullWidth={false}
      data-testid="visit-flow-stepper"
    >
      <StackView direction="row" className="pl-2">
        {!isDefaultVisitStepperSize ? (
          <ChevronLink
            testId="visit-flow-stepper-toggle"
            icon={ChevronDoubleRightIcon}
            onClick={() => onToggleClicked()}
          />
        ) : (
          <ChevronLink
            testId="visit-flow-stepper-toggle"
            text="Collapse"
            icon={ChevronDoubleLeftIcon}
            onClick={() => onToggleClicked()}
          />
        )}
      </StackView>
      <StackView
        direction="row"
        className={clsx(
          !isDefaultVisitStepperSize ? 'w-[32px]' : 'w-[240px]',
          'overflow-y-auto transition-[width]'
        )}
        fullWidth={false}
      >
        {!isDefaultVisitStepperSize ? (
          <StackView
            className="w-[32px] rounded-full bg-gray-100 py-2"
            space={75}
            alignItems="center"
          >
            {visitFlowNavConfig
              .filter((item) =>
                item.encounterModes.includes(visit.encounterMode)
              )
              .map((item) => (
                <StepperNavLink
                  key={item.to}
                  label={item.abbreviation}
                  collapsed={!isDefaultVisitStepperSize}
                  route={item.to}
                  visit={visit}
                />
              ))}
          </StackView>
        ) : (
          <>
            <Box
              className="h-full w-[32px] rounded-full"
              color="bg-base-color-bg-subtle"
            />
            <StackView space={75} className="my-2">
              {visitFlowNavConfig
                .filter((item) =>
                  item.encounterModes.includes(visit.encounterMode)
                )
                .map((item) => (
                  <StepperNavLink
                    key={item.to}
                    label={item.label}
                    route={item.to}
                    description={
                      item.to === 'clinical'
                        ? `(${clinicalReviewNumItems} of 5 Reviewed)`
                        : undefined
                    }
                    visit={visit}
                  />
                ))}
            </StackView>
          </>
        )}
      </StackView>
    </StackView>
  )
}

const VisitFlowLayout = () => {
  const { id: patientId } = useParams()
  const { appointmentId } = useNestedParams()
  const { visit, currentPractitioner } = useVisitQuery(appointmentId)

  if (!visit) {
    return null
  }

  return (
    <>
      <StackView className="h-full">
        <VisitHeader
          patientId={patientId}
          visit={visit}
          currentPractitioner={currentPractitioner}
        />
        <StackView direction="row" className="h-full overflow-hidden" gap={100}>
          <Stepper patientId={patientId} visit={visit} />
          <StackView
            alignItems="center"
            className="h-full overflow-y-auto pb-8 pl-2 pr-4"
          >
            <Outlet />
          </StackView>
        </StackView>
      </StackView>
    </>
  )
}

export default VisitFlowLayout
