import { RegisterOptions } from '@redwoodjs/forms'

import {
  ComboboxField,
  ComboboxOption,
} from 'src/components/atoms/ComboboxField/ComboboxField'

interface Payer {
  id: string
  displayName: string
  candidPayerId: string
  isCommon: boolean
}

export const PayerSelectFieldBase = ({
  payers,
  name,
  className,
  validation,
}: {
  payers: Payer[]
  name: string
  className?: string
  validation?: RegisterOptions
}) => {
  const options = payers.flatMap(
    ({ id, displayName, candidPayerId, isCommon }, i) => {
      const previousPayer = payers[i - 1]

      const isFirst = i === 0

      const header: ComboboxOption | null =
        isFirst && isCommon
          ? { heading: true, name: 'Common', value: 'common' }
          : previousPayer?.isCommon && !isCommon
            ? { heading: true, name: 'All other payers', value: 'all_others' }
            : null

      return [
        header,
        {
          value: id,
          name: `${displayName} (${candidPayerId})`,
        },
      ].filter(Boolean)
    }
  )

  return (
    <ComboboxField
      className={className}
      name={name}
      validation={validation}
      options={options}
    />
  )
}
