import { PlusIcon } from '@heroicons/react/20/solid'
import {
  CreateHospitalVisit,
  CreateHospitalVisitVariables,
  ListHospitalLocations,
  ListHospitalLocationsVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import Button from 'src/components/atoms/Button/Button'

const QUERY = gql`
  query ListHospitalLocations {
    hospitalLocations {
      id
    }
  }
`

const MUTATION = gql`
  mutation CreateHospitalVisit($patientId: String!) {
    createHospitalVisit(patientId: $patientId) {
      id
    }
  }
`

export const NewHospitalVisitButton = ({ patientId }: { patientId }) => {
  const { data } = useQuery<
    ListHospitalLocations,
    ListHospitalLocationsVariables
  >(QUERY)
  const [createHospitalVisit, { loading }] = useMutation<
    CreateHospitalVisit,
    CreateHospitalVisitVariables
  >(MUTATION)

  if (!data?.hospitalLocations?.length) return null

  return (
    <Button
      text="Hospital visit"
      buttonStyle="secondary"
      icon={PlusIcon}
      onClick={() => {
        createHospitalVisit({
          variables: {
            patientId,
          },
          onCompleted: (result) => {
            navigate(
              routes.patientChartsGlob({
                id: patientId,
                glob: `visits/hospital/${result.createHospitalVisit.id}`,
              })
            )
          },
        })
      }}
      loading={loading}
      disabled={loading}
    />
  )
}
