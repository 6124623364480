import { formatDisplayName } from 'common/utils'
import omit from 'lodash/omit'
import { FindPractitionerClinicalDetails } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'

const QUERY = gql`
  query FindPractitionerClinicalDetails {
    practitionerClinicalDetails {
      id
      npi
      isSchedulingOnly
      practitioner {
        id
        givenName
        familyName
      }
    }
  }
`

export const PractitionerClinicalDetailSelectField = (
  props: RWSelectFieldProps & { excludeSchedulingOnlyPractitioners?: boolean }
) => {
  const { data, loading } = useQuery<FindPractitionerClinicalDetails>(QUERY)

  if (loading) return null

  return (
    <SelectField
      {...omit(props, 'excludeSchedulingOnlyPractitioners')}
      emptyAs={null}
      options={data.practitionerClinicalDetails
        .filter(
          ({ isSchedulingOnly }) =>
            !(props.excludeSchedulingOnlyPractitioners && isSchedulingOnly)
        )
        .map(({ id, npi, practitioner }) => ({
          value: id,
          display: `${formatDisplayName(practitioner)} (${npi})`,
        }))}
    />
  )
}

export default PractitionerClinicalDetailSelectField
