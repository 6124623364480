import { recipientNameMaxLength, notesMaxLength } from 'common/data/faxes'
import { format, isBefore, isSameDay } from 'date-fns'
import compact from 'lodash/compact'
import startCase from 'lodash/startCase'
import { FaxDocumentInput } from 'types/graphql'

import Divider from 'src/components/atoms/Divider'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  TypeAheadOption,
  TypeaheadField,
} from 'src/components/molecules/Typeahead'
import { useGetOrderPerformers } from 'src/components/OrderSidepanelForm/useOrderPerformers'
import { useContactsQueryAsTypeaheadOptions } from 'src/hooks/useContactsQuery/useContactsQuery'
import useFaxablePracticeBinariesQuery from 'src/hooks/useFaxablePracticeBinariesQuery/useFaxablePracticeBinariesQuery'
import useIsPatientChart from 'src/hooks/useIsPatientChart/useIsPatientChart'
import useSendFax from 'src/hooks/useSendFax/useSendFax'
import { usePatientDocumentsQuery } from 'src/pages/PatientChartsPage/PatientDocuments/usePatientDocuments'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import { faxDocumentFromKey, faxDocumentToKey } from './utils'

const descendingDayComparator = (a, b) => {
  const createdAtA = new Date(a)
  const createdAtB = new Date(b)
  if (!isSameDay(createdAtA, createdAtB)) {
    if (isBefore(createdAtA, createdAtB)) {
      return 1
    } else {
      return -1
    }
  }
  return 0
}

const _calculateGroupMemberSortingOrder = (sortingCriteria: number[]) => {
  return sortingCriteria.find((value) => value !== 0) ?? 0
}

const calculateGroupMemberSortingOrder = (a, b) => {
  if (a.__typename === 'DiagnosticReport') {
    const dayComparisonResult = descendingDayComparator(a.issuedAt, b.issuedAt)
    return dayComparisonResult !== 0
      ? dayComparisonResult
      : _calculateGroupMemberSortingOrder([
          a.category &&
            a.category.toString().localeCompare(b.category?.toString()),
          a.name && a.name.toString().localeCompare(b.name?.toString()),
        ])
  } else {
    const dayComparisonResult = descendingDayComparator(
      a.createdAt,
      b.createdAt
    )
    return dayComparisonResult !== 0
      ? dayComparisonResult
      : _calculateGroupMemberSortingOrder([
          a.subType?.code &&
            a.subType.code
              .toString()
              .localeCompare(b.subType?.code?.toString()),
          a.title && a.title.toString().localeCompare(b.title?.toString()),
        ])
  }
}

const useGetDocumentOptions = (
  patientId: string | undefined
): TypeAheadOption[] => {
  const { binaries: practiceBinaries } = useFaxablePracticeBinariesQuery({
    skip: !!patientId,
  })

  const {
    documents: patientDocuments,
    diagnosticReports: patientDiagnosticReports,
  } = usePatientDocumentsQuery(patientId)

  if (patientId) {
    const documentOptions = (patientDocuments ? [...patientDocuments] : [])
      .sort((a, b) => calculateGroupMemberSortingOrder(a, b))
      .map((document) => ({
        label: format(new Date(document.createdAt), 'MM/dd/yyyy'),
        supportingLabel: compact([
          document.title,
          document.subType?.display,
        ]).join(' \u2022 '),
        value: faxDocumentToKey({
          documentType: 'BINARY',
          documentId: document.id,
        }),
        headerKey: document.type?.code,
        headerLabel: document.type?.display,
      }))
      .sort((a, b) => a.headerLabel.localeCompare(b.headerLabel))

    const diagnosticReportOptions = (
      patientDiagnosticReports ? [...patientDiagnosticReports] : []
    )
      .sort((a, b) => calculateGroupMemberSortingOrder(a, b))
      .map((diagnosticReport) => ({
        label: format(new Date(diagnosticReport.issuedAt), 'MM/dd/yyyy'),
        supportingLabel: compact([
          diagnosticReport.presentedForm?.title,
          diagnosticReport.category
            ? startCase(diagnosticReport.category)
            : null,
        ]).join(' \u2022 '),
        value: faxDocumentToKey({
          documentType: 'DIAGNOSTIC_REPORT',
          documentId: diagnosticReport.id,
        }),
        headerKey: 'clinical-result',
        headerLabel: 'Clinical Result',
      }))
      .sort((a, b) => a.headerLabel.localeCompare(b.headerLabel))

    return [...documentOptions, ...diagnosticReportOptions]
  }

  return (practiceBinaries ? [...practiceBinaries] : [])
    .sort((a, b) => calculateGroupMemberSortingOrder(a, b))
    ?.map((binary) => ({
      label: format(
        new Date(binary.documentInformation?.createdAt),
        'MM/dd/yyyy'
      ),
      supportingLabel: compact([
        binary.documentInformation?.title,
        binary.documentInformation?.subType,
      ]).join(' \u2022 '),
      value: faxDocumentToKey({
        documentType: 'BINARY',
        documentId: binary.id,
      }),
      headerKey: binary.documentInformation?.type,
      headerLabel: binary.documentInformation?.type,
    }))
    .sort((a, b) => a.headerLabel.localeCompare(b.headerLabel))
}

const SidepanelSendFax = () => {
  const {
    sidepanelContext: {
      contactIds: contactIdsParameter,
      binaryIds: binaryIdsParameter,
      documents: documentsParameter,
      primaryDocument: primaryDocumentParameter,
      orderId: orderIdParameter,
      patientId: patientIdParameter,
    },
    closeSidePanel,
  } = useSidepanel()

  const params = useIsPatientChart()
  const patientId = (patientIdParameter as string) ?? params.patientId
  const orderId = orderIdParameter as string

  const { existingContactPerformer, loading: gettingOrderPerformers } =
    useGetOrderPerformers(orderId)

  const contactIds: string[] | undefined = contactIdsParameter
    ? JSON.parse(contactIdsParameter as string)
    : undefined
  const parsedBinaryIds: string[] | undefined = binaryIdsParameter
    ? JSON.parse(binaryIdsParameter as string)
    : undefined
  const parsedDocuments: FaxDocumentInput[] = documentsParameter
    ? JSON.parse(documentsParameter as string)
    : undefined
  const primaryDocument: {
    name: string
    binaryId: string
    documentType: 'BINARY'
  } = primaryDocumentParameter
    ? JSON.parse(primaryDocumentParameter as string)
    : undefined

  const documentKeys =
    parsedDocuments?.map(faxDocumentToKey) ??
    parsedBinaryIds?.map((id) =>
      faxDocumentToKey({ documentType: 'BINARY', documentId: id })
    ) ??
    []

  const [sendFaxes, { loading: sendingFaxes }] = useSendFax({
    afterCompleted: closeSidePanel,
  })

  const { contactOptions } = useContactsQueryAsTypeaheadOptions()
  const documentOptions = useGetDocumentOptions(patientId)

  const onSubmit = async (value) => {
    const formDocuments = value.documentKeys
      .map(faxDocumentFromKey)
      .filter(({ documentId }) => !!documentId)

    const allDocuments = primaryDocument
      ? [
          {
            documentId: primaryDocument.binaryId,
            documentType: primaryDocument.documentType,
          },
          ...formDocuments,
        ]
      : formDocuments

    void sendFaxes({
      variables: {
        input: {
          ...value,
          documentKeys: undefined,
          documents: allDocuments,
          orderIds: orderIdParameter ? [orderIdParameter] : undefined,
        },
      },
    })
  }

  return (
    <SidepanelPage
      header="Send e-fax"
      testId="sidepanel-outbound-fax-create"
      description={
        primaryDocument
          ? `Send ${primaryDocument.name} via e-fax plus any additional documents to at least one recipient.`
          : 'Send one or more documents via e-fax to at least one recipient.'
      }
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Send e-fax',
          submitting: sendingFaxes,
          disabled: gettingOrderPerformers,
        }}
        key={`${contactIdsParameter}:${binaryIdsParameter}:${documentsParameter}`}
        onSubmit={onSubmit}
        config={{
          defaultValues: {
            contactIds: compact([
              ...(contactIds ?? []),
              existingContactPerformer?.contactId,
            ]),
            documentKeys: documentKeys ?? [],
          },
        }}
      >
        <StackView space={150} className="py-core-space-150">
          <StackView space={50}>
            <Typography textStyle="title-xs">Recipient details</Typography>
            <FormInputList
              className="py-core-space-0"
              items={[
                {
                  name: 'contactIds',
                  label: 'Recipient',
                  required: true,
                  formInputComponent: TypeaheadField,
                  direction: 'col',
                  message:
                    "If there isn't a fax number linked to a recipient you must add them before sending an e-fax. If they are a patient or caregiver please add them in their chart, otherwise add the recipient to your contacts.",
                  inputProps: {
                    options: contactOptions,
                    includeCheckbox: true,
                    placeholder: 'Search for contacts',
                  },
                },
              ]}
            />
          </StackView>
          <Divider />
          <StackView space={50}>
            <Typography textStyle="title-xs">Cover letter details</Typography>
            <Typography textStyle="body-s" color="text-base-color-fg-muted">
              While the Attention to and Notes fields are optional, all e-faxes
              will include the Disclaimer referencing the Health Insurance
              Portability & Accountability Act (HIPAA) Privacy Rule.
            </Typography>
            <FormInputList
              className="py-core-space-0"
              divider={false}
              items={[
                {
                  name: 'attentionTo',
                  label: 'Attention to',
                  formInputComponent: InputField,
                  direction: 'col',
                  inputProps: {
                    validation: {
                      maxLength: recipientNameMaxLength,
                    },
                  },
                },
                {
                  name: 'notes',
                  label: 'Notes',
                  formInputComponent: TextAreaField,
                  direction: 'col',
                  inputProps: {
                    validation: {
                      maxLength: notesMaxLength,
                    },
                  },
                },
              ]}
            />
            <StackView>
              <Typography
                textStyle="interface-default-s"
                color="text-base-color-fg-muted"
              >
                Disclaimer
              </Typography>
              <Typography textStyle="body-xs" color="text-base-color-fg-muted">
                IMPORTANT: This facsimile transmission contains confidential
                information, some or all of which may be protected health
                information as defined by the federal Health Insurance
                Portability & Accountability Act (HIPAA) Privacy Rule. This
                transmission is intended for the exclusive use of the individual
                or entity to whom it is addressed and may contain information
                that is proprietary, privileged, confidential and/or exempt from
                disclosure under applicable law. If you are not the intended
                recipient (or an employee or agent responsible for delivering
                this facsimile transmission to the intended recipient), you are
                hereby notified that any disclosure, dissemination, distribution
                or copying of this information is strictly prohibited and may be
                subject to legal restriction or sanction. Please notify the
                sender by telephone (number listed above) to arrange the return
                or destruction of the information and all copies.
              </Typography>
            </StackView>
          </StackView>

          <Divider />

          <Typography textStyle="title-xs">
            {primaryDocument ? 'Additional Documents' : 'Documents'}
          </Typography>

          <FormInputList
            className="py-core-space-0 pb-core-space-2000"
            items={[
              {
                name: 'documentKeys',
                label: primaryDocument ? 'Additional Documents' : 'Documents',
                required: !primaryDocument,
                direction: 'col',
                formInputComponent: TypeaheadField,
                inputProps: {
                  includeCheckbox: true,
                  placeholder: 'Search by document type, title or date...',
                  options: documentOptions,
                },
              },
            ]}
          />
        </StackView>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelSendFax
