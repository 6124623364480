import { useWatch } from '@redwoodjs/forms'

import { Submit } from '../Button/Button'
import Label from '../Label'
import TextAreaField, {
  RWTextAreaFieldProps,
} from '../TextAreaField/TextAreaField'

interface Props extends RWTextAreaFieldProps {
  profileImage?: string
  altTextImage?: string
  name: string
  submitButtonText: string
  placeholder?: string
  onSubmit?: () => void
  disabled?: boolean
}

function Comment({
  profileImage,
  altTextImage,
  submitButtonText,
  placeholder,
  name,
  disabled,
  ...props
}: Props) {
  const text = useWatch({
    name,
  })
  return (
    <div className="flex items-start space-x-4">
      {profileImage && (
        <div className="flex-shrink-0">
          <img
            className="inline-block h-10 w-10 rounded-full"
            src={profileImage}
            alt={altTextImage}
          />
        </div>
      )}
      <div className="min-w-0 flex-1">
        <div className="relative">
          <div className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-success focus-within:ring-1 focus-within:ring-success">
            <Label htmlFor="comment" className="sr-only">
              Add your comment
            </Label>
            <TextAreaField
              rows={3}
              name={name}
              className="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm"
              placeholder={placeholder}
              {...props}
            />

            {/* Spacer element to match the height of the toolbar */}
            <div className="py-2" aria-hidden="true">
              {/* Matches height of button in toolbar (1px border + 36px content height) */}
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>
          <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
            <div className="flex items-center space-x-5">
              <div className="flex items-center">
                {/* <button
                  type="button"
                  className="-m-2.5 flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                >
                  <PaperClipIcon className="h-5 w-5" aria-hidden="true" />
                  <span className="sr-only">Attach a file</span>
                </button> */}
              </div>
            </div>
            <div className="flex-shrink-0">
              <Submit
                data-testid="comment-submit"
                buttonStyle="primary"
                disabled={!text || disabled}
                loading={false}
              >
                {submitButtonText}
              </Submit>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comment
