import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { FindPatientVisit, UpdateAppointmentInput } from 'types/graphql'

import { Controller, Form, useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import ChiefComplaintSelectField from 'src/components/atoms/ChiefComplaintSelectField/ChiefComplaintSelectField'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { useUpdateVisitChiefComplaintsMutation } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'

const DEBOUNCE_MS = 150

export const ChiefComplaintSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const formMethods = useForm<UpdateAppointmentInput>({
    defaultValues: {
      chiefComplaints: visit.chiefComplaints,
    },
  })
  const [updateChiefComplaints] = useUpdateVisitChiefComplaintsMutation()
  const saveUpdates = () => {
    void updateChiefComplaints({
      variables: {
        id: visit.id,
        input: {
          chiefComplaints: formMethods
            .getValues('chiefComplaints')
            ?.filter(Boolean),
        },
      },
      onCompleted: () => {
        toast.success('Chief complaint updated')
      },
    })
  }

  const appointmentDefinition = visit.appointmentDefinitions[0]
  return (
    <Form formMethods={formMethods}>
      <Controller
        name="chiefComplaints"
        render={({ field: { name } }) => (
          <StackView
            direction="col"
            fullWidth
            space={75}
            testId="chief-complaints-section"
          >
            <Typography textStyle="title" fontWeight="semibold">
              Chief Complaints
            </Typography>
            {!isEmpty(appointmentDefinition) && (
              <ChiefComplaintSelectField
                name={name}
                appointmentDefinitionId={appointmentDefinition.id}
                onChange={debounce(saveUpdates, DEBOUNCE_MS)}
                onRemoveSelectedOption={debounce(saveUpdates, DEBOUNCE_MS)}
                listAllComplaints
              />
            )}
          </StackView>
        )}
      />
    </Form>
  )
}
