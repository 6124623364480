import { XMarkIcon } from '@heroicons/react/24/solid'
import { DateTimeFormatter, LocalDate } from '@js-joda/core'
import { Locale } from '@js-joda/locale_en-us'
import { paymentLineItemTypesDisplay } from 'common/data/paymentLineItemTypes'
import { formatMoneyInCents } from 'common/utils'
import { format } from 'date-fns'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { CandidPatientRefundStatus } from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge/Badge'
import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import DataDisplayList from 'src/components/molecules/DataDisplayList'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { CHARGE_TYPES } from 'src/components/PatientFinancials/components/HistoricalPatientChargesTable'
import {
  PAYMENT_TYPES,
  PAYMENT_STATUS,
} from 'src/components/PatientFinancials/components/HistoricalPatientPaymentsTable'
import {
  useDeletePatientChargeByIdMutation,
  usePatientChargeByIdQuery,
} from 'src/hooks/usePatientCharges/usePatientCharges'
import { usePatientPaymentQuery } from 'src/hooks/usePatientPayments/usePatientPayments'
import { usePatientRefundQuery } from 'src/hooks/usePatientRefunds/usePatientRefunds'
import { sidepanelRoute } from 'src/lib/routes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const PaymentOrRefundBoxContent = ({
  amountCents,
  chargeDisplay,
  id,
  paidAt,
  status,
  variant,
}: {
  amountCents: number
  chargeDisplay: string
  id: string
  paidAt: string
  status: 'SUCCESSFUL' | CandidPatientRefundStatus
  variant: 'payment' | 'refund'
}) => {
  const params = useParams()
  const location = useLocation()
  const { patientId } = params

  const paymentType =
    amountCents > 0 ? 'PAYMENT' : amountCents === 0 ? 'NET_ZERO' : 'REFUND'

  return (
    <Box
      key={id}
      horizontalPadding={100}
      verticalPadding={75}
      rounded
      border
      className="cursor-pointer"
      onClick={() => {
        navigate(
          sidepanelRoute(
            {
              route: match(variant)
                .with('payment', () => `/patients/${patientId}/payments/${id}`)
                .with('refund', () => `/patients/${patientId}/refunds/${id}`)
                .exhaustive(),
            },
            location,
            params
          )
        )
      }}
    >
      <StackView
        space={100}
        direction="row"
        alignItems="center"
        justifyContent="between"
      >
        <StackView>
          <Typography
            textStyle="interface-strong-s"
            color="text-base-color-fg-muted"
            className="text-ellipsis"
          >
            {chargeDisplay}
          </Typography>
          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {format(new Date(paidAt), 'PP')}
          </Typography>
        </StackView>

        <Typography
          textStyle="interface-default-s"
          color="text-base-color-fg-muted"
        >
          {formatMoneyInCents(amountCents)}
        </Typography>
        <StackView space={25} direction="row">
          <Badge
            testId="payment-type-badge"
            color={PAYMENT_TYPES[paymentType].color}
            text={PAYMENT_TYPES[paymentType].label}
          />
          <Badge
            testId="payment-status-badge"
            color={PAYMENT_STATUS[status].color}
            text={PAYMENT_STATUS[status].label}
            showDot
          />
        </StackView>
      </StackView>
    </Box>
  )
}

const PaymentBox = ({ paymentId }: { paymentId: string }) => {
  const { payment } = usePatientPaymentQuery(paymentId)

  if (!payment) return null
  return (
    <PaymentOrRefundBoxContent
      amountCents={payment.amountCents}
      chargeDisplay={payment.chargeDisplay}
      id={payment.id}
      paidAt={payment.paidAt}
      status="SUCCESSFUL"
      variant="payment"
    />
  )
}

const RefundBox = ({ refundId }: { refundId: string }) => {
  const { refund } = usePatientRefundQuery({
    develoCandidPatientRefundId: refundId,
  })

  if (!refund) return null
  return (
    <PaymentOrRefundBoxContent
      amountCents={-refund.amountCents}
      chargeDisplay={refund.chargeDisplay}
      id={refund.id}
      paidAt={refund.createdAt}
      status={refund.status}
      variant="refund"
    />
  )
}

const SidepanelPatientChargeView = () => {
  const params = useParams()
  const location = useLocation()
  const { patientChargeId, patientId } = params
  const { charge, loading } = usePatientChargeByIdQuery(patientChargeId)
  const { deleteCharge, loading: deletingCharge } =
    useDeletePatientChargeByIdMutation(patientChargeId)
  const { closeSidePanel } = useSidepanel()

  if (!charge && loading) return <LoadingSpinner />
  if (!charge) return null

  return (
    <SidepanelPage
      testId="patient-charge-view"
      header={charge.description ?? 'Patient Charge'}
      description={LocalDate.parse(charge.dateOfService).format(
        DateTimeFormatter.ofPattern('MM/dd/yyyy').withLocale(Locale.US)
      )}
    >
      <StackView className="grow p-core-space-150" space={50}>
        {charge.paymentStatus !== 'DELETED' && (
          <StackView direction="row" space={50}>
            <Button
              text="Edit"
              buttonStyle="secondary"
              onClick={() => {
                navigate(
                  sidepanelRoute(
                    {
                      route: `/patients/${patientId}/charges/${charge.id}/edit`,
                    },
                    location,
                    params
                  )
                )
              }}
            />
          </StackView>
        )}

        <DataDisplayList
          title="Details"
          leftColumnWidth="lg"
          data={[
            {
              label: 'Charge or billing code & description',
              value: charge && (
                <StackView space={25}>
                  <Typography
                    textStyle="body-s"
                    color="text-base-color-fg-muted"
                    className="!mb-0"
                  >
                    {charge.description}
                  </Typography>
                </StackView>
              ),
            },
            {
              label: 'Charge type',
              value:
                charge &&
                paymentLineItemTypesDisplay[charge.paymentLineItemType],
            },
            {
              label: 'Amount',
              value: charge && formatMoneyInCents(charge.chargeAmountCents),
            },
            {
              label: 'Status',
              value: charge && (
                <Badge color={CHARGE_TYPES[charge.paymentStatus].color} showDot>
                  {CHARGE_TYPES[charge.paymentStatus].label}
                </Badge>
              ),
            },
          ]}
        />
        {charge.candidPatientPayments.length > 0 && (
          <StackView space={100} className="pt-core-space-100">
            <Typography textStyle="title-xs">Recorded payments</Typography>

            <StackView space={25} testId="recorded-payments">
              {charge.candidPatientPayments.map((payment) => (
                <PaymentBox
                  key={payment.id}
                  paymentId={payment.candidPatientPaymentId}
                />
              ))}
            </StackView>
          </StackView>
        )}

        {charge.candidPatientRefunds.length > 0 && (
          <StackView space={100} className="pt-core-space-100">
            <Typography textStyle="title-xs">Recorded refunds</Typography>

            <StackView space={25} testId="recorded-refunds">
              {charge.candidPatientRefunds.map((refund) => (
                <RefundBox key={refund.id} refundId={refund.id} />
              ))}
            </StackView>
          </StackView>
        )}

        {charge.isDeletable && (
          <DestructiveAction
            title="Destructive actions"
            description="These changes are permanent, use caution when removing."
            buttonText="Delete charge"
            buttonIcon={XMarkIcon}
            modal={{
              title: 'Are you sure?',
              content: `Deleting a patient charge is permanent, use caution when removing.`,
              confirmText: 'Delete charge',
              hideIcon: true,
              testId: 'delete-charge-modal',
            }}
            doDestructiveAction={async () => {
              await deleteCharge()
            }}
            destructiveActionIsLoading={deletingCharge}
            postDestructiveAction={closeSidePanel}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelPatientChargeView
