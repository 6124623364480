import { datadogRum } from '@datadog/browser-rum'

import { DevFatalErrorPage } from '@redwoodjs/web/dist/components/DevFatalErrorPage'

import Button from 'src/components/atoms/Button/Button'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { useEffectOnce } from 'src/utils'

import { isChunkLoadingError, isDynamicModuleError } from './utils'

const FatalErrorPage = ({ error }) => {
  useEffectOnce(() => {
    datadogRum.addError(error, {
      isFatalErrorPage: true,
    })
  })

  // https://github.com/redwoodjs/redwood/issues/9352#issuecomment-1791385077
  if (
    (isChunkLoadingError(error) || isDynamicModuleError(error)) &&
    !window.location.href.includes('reload=1')
  ) {
    if (window.location.href.includes('?')) {
      window.location.href = window.location.href + '&reload=1'
    } else {
      window.location.href = window.location.href + '?reload=1'
    }
    return
  }

  return (
    <StackView className="h-full" alignItems="center" justifyContent="center">
      <StackView alignItems="center" className="max-w-[540px]">
        <Illustration />
        <Space space={200} />
        <StackView gap={50}>
          <Typography textStyle="title-l" className="text-center">
            Oops! Something went wrong.
          </Typography>
          <Typography
            textStyle="interface-default-m"
            color="text-base-color-fg-muted"
            className="text-center"
          >
            We&apos;re sorry, but something went wrong on our end. Navigate back
            to your Develo home dashboard, or refresh the current page.
          </Typography>
        </StackView>
        <Space space={150} />
        <Button
          buttonStyle="primary"
          text="Go back home"
          buttonSize="l"
          onClick={() => window.location.assign('/')}
        />
      </StackView>
    </StackView>
  )
}

const Illustration = () => (
  <svg
    width="220"
    height="161"
    viewBox="0 0 220 161"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="110" cy="80.5" r="80" fill="#EAECF0" />
    <ellipse
      cx="30.3946"
      cy="22.0339"
      rx="7.23684"
      ry="7.24576"
      fill="#F2F4F7"
    />
    <ellipse
      cx="26.0525"
      cy="143.644"
      rx="10.1316"
      ry="10.1441"
      fill="#F2F4F7"
    />
    <ellipse
      cx="209.868"
      cy="45.2204"
      rx="10.1316"
      ry="10.1441"
      fill="#F2F4F7"
    />
    <ellipse
      cx="193.947"
      cy="6.09324"
      rx="5.78947"
      ry="5.79661"
      fill="#F2F4F7"
    />
    <ellipse
      cx="193.947"
      cy="137.297"
      rx="5.78947"
      ry="5.79661"
      fill="#F2F4F7"
    />
    <g filter="url(#filter0_dd_7001_1697)">
      <path
        d="M112.486 21.5C95.7495 21.5 80.9448 29.7345 71.9354 42.3564C68.9934 41.6655 65.9253 41.3 62.7714 41.3C40.8063 41.3 23 59.0295 23 80.9C23 102.77 40.8063 120.5 62.7714 120.5L162.2 120.5C181.419 120.5 197 104.987 197 85.85C197 66.7133 181.419 51.2 162.2 51.2C160.835 51.2 159.488 51.2783 158.164 51.4305C150.546 33.825 132.961 21.5 112.486 21.5Z"
        fill="#F9FAFB"
      />
      <ellipse
        cx="62.7714"
        cy="80.9"
        rx="39.7714"
        ry="39.6"
        fill="url(#paint0_linear_7001_1697)"
      />
      <ellipse
        cx="112.486"
        cy="71"
        rx="49.7143"
        ry="49.5"
        fill="url(#paint1_linear_7001_1697)"
      />
      <ellipse
        cx="162.2"
        cy="85.85"
        rx="34.8"
        ry="34.65"
        fill="url(#paint2_linear_7001_1697)"
      />
    </g>
    <g filter="url(#filter1_b_7001_1697)">
      <rect
        x="82"
        y="87.5"
        width="56"
        height="56"
        rx="28"
        fill="#344054"
        fillOpacity="0.4"
      />
      <path
        d="M110 102.5C107.429 102.5 104.915 103.262 102.778 104.691C100.64 106.119 98.9735 108.15 97.9896 110.525C97.0056 112.901 96.7482 115.514 97.2498 118.036C97.7514 120.558 98.9895 122.874 100.808 124.692C102.626 126.51 104.942 127.749 107.464 128.25C109.986 128.752 112.599 128.494 114.975 127.51C117.35 126.526 119.381 124.86 120.809 122.722C122.238 120.585 123 118.071 123 115.5C122.996 112.053 121.626 108.749 119.188 106.312C116.751 103.874 113.447 102.504 110 102.5ZM109 109.5C109 109.235 109.105 108.98 109.293 108.793C109.48 108.605 109.735 108.5 110 108.5C110.265 108.5 110.52 108.605 110.707 108.793C110.895 108.98 111 109.235 111 109.5V116.5C111 116.765 110.895 117.02 110.707 117.207C110.52 117.395 110.265 117.5 110 117.5C109.735 117.5 109.48 117.395 109.293 117.207C109.105 117.02 109 116.765 109 116.5V109.5ZM110 122.5C109.703 122.5 109.413 122.412 109.167 122.247C108.92 122.082 108.728 121.848 108.614 121.574C108.501 121.3 108.471 120.998 108.529 120.707C108.587 120.416 108.73 120.149 108.939 119.939C109.149 119.73 109.416 119.587 109.707 119.529C109.998 119.471 110.3 119.501 110.574 119.614C110.848 119.728 111.082 119.92 111.247 120.167C111.412 120.413 111.5 120.703 111.5 121C111.5 121.398 111.342 121.779 111.061 122.061C110.779 122.342 110.398 122.5 110 122.5Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_7001_1697"
        x="3"
        y="21.5"
        width="214"
        height="139"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect1_dropShadow_7001_1697"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_7001_1697"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feMorphology
          radius="4"
          operator="erode"
          in="SourceAlpha"
          result="effect2_dropShadow_7001_1697"
        />
        <feOffset dy="20" />
        <feGaussianBlur stdDeviation="12" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_7001_1697"
          result="effect2_dropShadow_7001_1697"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_7001_1697"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_7001_1697"
        x="74"
        y="79.5"
        width="72"
        height="72"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_7001_1697"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_7001_1697"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_7001_1697"
        x1="32.2326"
        y1="54.7357"
        x2="102.258"
        y2="120.802"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0D5DD" />
        <stop offset="0.350715" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7001_1697"
        x1="74.3123"
        y1="38.2946"
        x2="161.844"
        y2="120.877"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0D5DD" />
        <stop offset="0.350715" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_7001_1697"
        x1="135.479"
        y1="62.9562"
        x2="196.751"
        y2="120.764"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D0D5DD" />
        <stop offset="0.350715" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

export default DevFatalErrorPage || FatalErrorPage
