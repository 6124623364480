import { useEffect, useState } from 'react'

import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist'

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.mjs`

export const useConvertPDFToImages = (url: string) => {
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const convertPdfToImages = async (url) => {
      const images = []
      const pdf = await getDocument(url).promise
      const canvas = document.createElement('canvas')
      for (let i = 0; i < pdf.numPages; i++) {
        const page = await pdf.getPage(i + 1)
        const viewport = page.getViewport({ scale: 4 })
        const context = canvas.getContext('2d')
        canvas.height = viewport.height
        canvas.width = viewport.width
        await page.render({ canvasContext: context, viewport: viewport })
          .promise
        images.push(canvas.toDataURL())
      }
      setImages(images)
      setLoading(false)
    }

    void convertPdfToImages(url)
  }, [url])

  return { images, loading }
}
