import type { Race } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const raceDisplay: {
  [key in Race]: string
} = {
  WHITE: 'White',
  ASIAN: 'Asian',
  BLACK_OR_AFRICAN_AMERICAN: 'Black or African American',
  AMERICAN_INDIAN_OR_ALASKA_NATIVE: 'American Indian or Alaska Native',
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER:
    'Native Hawaiian or other Pacific Islander',
  OTHER: 'Other',
  ASKED_BUT_UNKNOWN: 'Unknown',
}

export const races = nameValueArrayFromObject<Race>(raceDisplay)
