import { useParams } from '@redwoodjs/router'

import Divider from 'src/components/atoms/Divider'
import StackView from 'src/components/atoms/StackView/StackView'
import checkAge from 'src/lib/checkAge'
import { calculateAge } from 'src/lib/formatters'

import { usePatientDemographicsQuery } from '../usePatient'

import CaregiversTabs from './CaregiversTabs'
import PatientCaregiverOptOutCard from './PatientCaregiverOptOutCard'

const CaregiversTab = () => {
  const { id } = useParams()
  const { patient } = usePatientDemographicsQuery(id)

  if (!patient) return null

  const ageRestrictedAbilities = checkAge(calculateAge(patient.birthDate))

  const canOptOut =
    ageRestrictedAbilities.canBeEmancipatedMinor ||
    ageRestrictedAbilities.canBeindependentAdult

  return (
    <StackView space={75} className="max-w-2xl">
      {canOptOut && (
        <>
          <PatientCaregiverOptOutCard patient={patient} />
          <Divider />
        </>
      )}
      <CaregiversTabs patient={patient} />
    </StackView>
  )
}

export default CaregiversTab
