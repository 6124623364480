import { useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'
import { LocalDate } from '@js-joda/core'
import { dateTimeFormatter } from 'common/data/date'
import {
  costPerBillingPeriodInCents,
  membershipPaymentFrequencyOptions,
} from 'common/data/membershipPlans'
import { formatDollarsToCents, formatMoneyInCents } from 'common/utils'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import {
  CreatePatientMembershipPlanTypeInput,
  ListMembershipPlans,
  ListMembershipPlansVariables,
  MembershipPlanPaymentFrequency,
  SetUpPatientMembershipPlan,
  SetUpPatientMembershipPlanVariables,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import Card from 'src/components/atoms/Card/Card'
import InputField from 'src/components/atoms/InputField/InputField'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import { FormInputList } from 'src/components/molecules/FormInputList'
import { ListItemText } from 'src/components/molecules/ListRowItem/ListItemText'
import { ListRowItem } from 'src/components/molecules/ListRowItem/ListRowItem'
import { MoneyInputField } from 'src/components/molecules/MoneyInputField/MoneyInputField'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { PortalLinkTextRecipientMultiRadioButtonField } from 'src/components/PortalLinkTextRecipientMultiRadioButtonField/PortalLinkTextRecipientMultiRadioButtonField'
import TilledCardDetails, {
  useTilledCardDetailsRef,
} from 'src/components/TilledCardDetails/TilledCardDetails'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

type MembershipPlan = ListMembershipPlans['membershipPlans'][number]

interface MembershipPlanDetails {
  membershipPlanId: string
  membershipPlan?: MembershipPlan
  customPlanName?: string
  customPlanAmountDollars?: number
  frequency: MembershipPlanPaymentFrequency
  startDate: string
}

const QUERY = gql`
  query ListMembershipPlans {
    membershipPlans {
      id
      name
      annualAmountCents
      quarterlyAmountCents
      monthlyAmountCents
    }
  }
`

const CREATE_PLAN = gql`
  mutation SetUpPatientMembershipPlan(
    $input: CreatePatientMembershipPlanInput!
  ) {
    createPatientMembershipPlan(input: $input) {
      id
    }
  }
`

const CUSTOM_MEMBERSHIP_ID = 'custom'

const isFrequencyDisabled = ({
  plan,
  frequency,
}: {
  plan: ListMembershipPlans['membershipPlans'][number] | undefined
  frequency: MembershipPlanPaymentFrequency
}): boolean => {
  if (!plan) return false

  return match(frequency)
    .with('ANNUALLY', () => !plan.annualAmountCents)
    .with('QUARTERLY', () => !plan.quarterlyAmountCents)
    .with('MONTHLY', () => !plan.monthlyAmountCents)
    .exhaustive()
}

const MembershipDetailsStep = ({ onSubmit }) => {
  const formMethods = useForm<MembershipPlanDetails>()

  const { data } = useQuery<ListMembershipPlans, ListMembershipPlansVariables>(
    QUERY
  )
  const plans = data?.membershipPlans ?? []

  const selectedMembershipPlanId = formMethods.watch('membershipPlanId')
  const selectedPlan = plans.find(
    (plan) => plan.id === selectedMembershipPlanId
  )
  const frequency = formMethods.watch('frequency')

  useEffect(() => {
    if (
      frequency &&
      isFrequencyDisabled({
        plan: selectedPlan,
        frequency,
      })
    ) {
      formMethods.resetField('frequency')
    }
  }, [selectedPlan, frequency, formMethods])

  return (
    <SidepanelForm
      divider={false}
      formMethods={formMethods}
      footerProps={{
        submitting: formMethods.formState.isSubmitting,
        submitText: 'Continue',
      }}
      onSubmit={(data) => {
        const selectedPlan = plans.find(
          (plan) => plan.id === data.membershipPlanId
        )

        onSubmit({
          ...data,
          membershipPlan: selectedPlan,
        })
      }}
    >
      <StackView className="py-core-space-150" gap={100}>
        <SectionHeader
          title="Membership details"
          description="Specify key details regarding the patient's membership."
        />

        <FormInputList
          divider={false}
          items={[
            {
              name: 'membershipPlanId',
              label: 'Membership tier',
              subtitle: 'Choose one from the available options.',
              subtitleProps: {
                className: '!mb-0',
              },
              required: true,
              direction: 'col',
              formInputComponent: MultiRadioButtonField,
              inputProps: {
                radioStyle: 'card',
                radioPosition: 'right',
                values: plans
                  .map((plan) => ({
                    value: plan.id,
                    label: plan.name,
                    description: [
                      [plan.annualAmountCents, 'year'],
                      [plan.quarterlyAmountCents, 'quarter'],
                      [plan.monthlyAmountCents, 'month'],
                    ]
                      .filter(([cost]) => !!cost)
                      .map(
                        ([cost, unit]: [number, string]) =>
                          `${formatMoneyInCents(cost)} per ${unit}`
                      )
                      .join(', '),
                  }))
                  .concat([
                    {
                      value: CUSTOM_MEMBERSHIP_ID,
                      label: 'Custom membership',
                      description: 'Set up a custom membership arrangement.',
                    },
                  ]),
              },
            },
            {
              name: 'customPlanName',
              label: 'Custom membership title',
              hide: selectedMembershipPlanId !== CUSTOM_MEMBERSHIP_ID,
              required: selectedMembershipPlanId === CUSTOM_MEMBERSHIP_ID,
              direction: 'col',
              formInputComponent: InputField,
            },
            {
              name: 'customPlanAmountDollars',
              label: 'Membership amount',
              hide: selectedMembershipPlanId !== CUSTOM_MEMBERSHIP_ID,
              required: selectedMembershipPlanId === CUSTOM_MEMBERSHIP_ID,
              direction: 'col',
              formInputComponent: MoneyInputField,
            },
            {
              name: 'frequency',
              label: 'Membership payment frequency',
              subtitle: 'Choose one from the available options.',
              subtitleProps: {
                className: '!mb-0',
              },
              required: true,
              direction: 'col',
              formInputComponent: MultiRadioButtonField,
              inputProps: {
                radioStyle: 'card',
                radioPosition: 'right',
                values: membershipPaymentFrequencyOptions.map((option) => ({
                  value: option.value,
                  label: option.name,
                  disabled: isFrequencyDisabled({
                    plan: selectedPlan,
                    frequency: option.value,
                  }),
                })),
              },
            },
            {
              name: 'startDate',
              label: 'Membership start date',
              required: true,
              direction: 'col',
              formInputComponent: DatePickerFieldThatWorks,
              message: "This must be today's date or a future date.",
              inputProps: {
                validation: {
                  validate: (value) => {
                    return !LocalDate.parse(value).isBefore(LocalDate.now())
                  },
                },
              },
            },
          ]}
        />
      </StackView>
    </SidepanelForm>
  )
}

const enterCardDetails = 'enter_card_details'
const paymentLink = 'payment_link'

export const PaymentDetailsStep = ({
  membershipPlanDetails,
}: {
  membershipPlanDetails: MembershipPlanDetails
}) => {
  const {
    membershipPlanId,
    membershipPlan,
    frequency,
    customPlanAmountDollars,
    customPlanName,
  } = membershipPlanDetails
  const { closeSidePanel } = useSidepanel()
  const { patientId } = useParams()
  const startDate = LocalDate.parse(membershipPlanDetails.startDate)
  const today = LocalDate.now()

  const formMethods = useForm()
  const { currentUser } = useEmrAuth()
  const tilledCardDetailsRef = useTilledCardDetailsRef()

  const [createPlan] = useMutation<
    SetUpPatientMembershipPlan,
    SetUpPatientMembershipPlanVariables
  >(CREATE_PLAN)

  const totalDue =
    membershipPlanId === CUSTOM_MEMBERSHIP_ID
      ? customPlanAmountDollars * 100
      : costPerBillingPeriodInCents({
          plan: membershipPlan,
          frequency,
        })

  const paymentType = formMethods.watch('membershipPaymentType')

  return (
    <SidepanelForm
      divider={false}
      formMethods={formMethods}
      footerProps={{
        submitting: formMethods.formState.isSubmitting,
        submitText: 'Submit',
      }}
      onSubmit={async (data) => {
        const plan: CreatePatientMembershipPlanTypeInput =
          membershipPlanId !== CUSTOM_MEMBERSHIP_ID
            ? {
                membershipPlanId,
              }
            : {
                custom: {
                  name: customPlanName,
                  amountCents: formatDollarsToCents(customPlanAmountDollars),
                },
              }

        await createPlan({
          variables: {
            input: {
              plan,
              patientId,
              startDate: membershipPlanDetails.startDate,
              frequency: membershipPlanDetails.frequency,
              paymentMethod: await match(data.membershipPaymentType)
                .with(enterCardDetails, async () => {
                  const result =
                    await tilledCardDetailsRef?.current?.createPaymentMethod({
                      cardHolder: data.cardHolder,
                      zip: data.zip,
                    })
                  if (!result.ok) {
                    throw new Error('Failed to create payment method')
                  }
                  return { tilledPaymentMethodId: result.id }
                })
                .otherwise(() => ({
                  sendPaymentLinkToContactInformationId:
                    data.toContactInformationId,
                })),
            },
          },
          refetchQueries: ['GetPatientCopayQuery'],
          onCompleted: () => {
            closeSidePanel()
            toast.success('Patient membership created')
          },
        })
      }}
    >
      <StackView className="py-core-space-150" gap={100}>
        <SectionHeader
          title="Payment details"
          description="Specify how payment will be processed for the patient's membership. Payment will start from the membership start date."
        />

        <Card>
          <ListRowItem>
            <ListItemText
              title={`Total due ${
                startDate.equals(today)
                  ? 'today'
                  : `on ${startDate.format(dateTimeFormatter('M/d/yyyy'))}`
              }`}
            />

            <Typography>{formatMoneyInCents(totalDue)}</Typography>
          </ListRowItem>
        </Card>

        <StackView gap={100}>
          <FormInputList
            divider={false}
            items={[
              {
                name: 'membershipPaymentType',
                label: 'Membership payment',
                subtitle: 'Choose one from the available options.',
                direction: 'col' as const,
                formInputComponent: MultiRadioButtonField,
                inputProps: {
                  radioStyle: 'card',
                  radioPosition: 'right',
                  values: [
                    {
                      value: enterCardDetails,
                      label: 'Credit or debit card manual entry',
                      description:
                        'Process and record a payment with manually entered card details.',
                    },
                    {
                      value: paymentLink,
                      label: 'Send a payment link',
                      description: 'Send to patient or caregiver.',
                    },
                  ],
                },
                required: true,
              },
              paymentType === enterCardDetails
                ? {
                    name: 'cardHolder',
                    label: 'Cardholder full name',
                    direction: 'col' as const,
                    formInputComponent: InputField,
                    required: true,
                  }
                : null,
            ].filter(Boolean)}
          />

          {paymentType === enterCardDetails ? (
            <TilledCardDetails
              tilledCardDetailsRef={tilledCardDetailsRef}
              tilledAccountId={currentUser?.tilledAccountId}
            />
          ) : null}

          <FormInputList
            items={[
              paymentType === enterCardDetails
                ? {
                    name: 'zip',
                    label: 'Billing zip code',
                    direction: 'col' as const,
                    formInputComponent: InputField,
                    required: true,
                  }
                : null,
              paymentType === paymentLink
                ? {
                    name: 'toContactInformationId',
                    label: 'Recipient',
                    required: true,
                    direction: 'col' as const,
                    formInputComponent:
                      PortalLinkTextRecipientMultiRadioButtonField,
                    inputProps: {
                      radioStyle: 'card',
                      radioPosition: 'right',
                      patientId,
                      portalFeature: 'BILLING',
                    },
                  }
                : null,
            ].filter(Boolean)}
          />
        </StackView>
      </StackView>
    </SidepanelForm>
  )
}

const SidepanelPatientMembershipCreate = () => {
  const [membershipPlanDetails, setMembershipPlanDetails] =
    useState<MembershipPlanDetails | null>(null)

  return (
    <SidepanelPage
      header="Set up membership"
      description="Set up the patient's membership plan."
    >
      {membershipPlanDetails ? (
        <PaymentDetailsStep membershipPlanDetails={membershipPlanDetails} />
      ) : (
        <MembershipDetailsStep onSubmit={setMembershipPlanDetails} />
      )}
    </SidepanelPage>
  )
}

export default SidepanelPatientMembershipCreate
