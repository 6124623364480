import { Route, Routes, useParams } from 'react-router-dom'

import { routes, useLocation } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box/Box'
import StackView from 'src/components/atoms/StackView/StackView'
import TabNav from 'src/components/molecules/TabNav/TabNav'
import { ORDER_FRAGMENT } from 'src/pages/PatientChartsPage/PatientOrders/usePatientOrders'
import { PDFAnnotationContextProvider } from 'src/providers/context/PDFAnnotationContext'

import ActivityTab from '../../ActivityTab/ActivityTab'
import CaseHeader from '../../CaseHeader/CaseHeader'

import DetailsTab from './DetailsTab/DetailsTab'
import TabsLayout from './TabsLayout/TabsLayout'

export const FIND_DOCUMENT_CASE_DETAILS_QUERY = gql`
  query FindDocumentCaseDetails($documentCaseId: String!) {
    documentCase(id: $documentCaseId) {
      signedPracticeForm {
        id
        signedAt
        signedByPatient {
          id
          givenName
          familyName
        }
        signedByRelatedPerson {
          id
          givenName
          familyName
          patientRelatedPersonRelationships {
            relationshipType
          }
        }
        practiceForm {
          version
          practiceFormDefinition {
            type
          }
        }
      }
      inboundFax {
        id
        documentReferenceId
        documoMessageId
        faxedTo
        faxedFrom
        binary {
          id
          content {
            contentType
            url
          }
        }
        from {
          id
          faxNumber
          name
          contactOrganizationId
        }
        faxNumber {
          id
          number
          name
        }
      }
      binary {
        id
        medplumId
        content {
          contentType
          url
        }
        clinicalResultsDocument {
          id
          orders {
            ...OrderFragment
            patient {
              id
              givenName
              familyName
            }
          }
        }
        practiceForm {
          id
          createdAt
          isCurrent
          version
          practiceFormDefinition {
            id
            type
            isRequired
          }
        }
        practiceAdminDocument {
          id
          title
          type
          createdAt
        }
        externalRequest {
          id
          type
          title
          createdAt
        }
        screeningTool {
          id
          type
          title
          createdAt
        }
        practiceLetter {
          id
          type
          title
          createdAt
        }
        patientEducationDocument {
          id
          title
          type
          createdAt
        }
        patientForm {
          id
          title
          type
          createdAt
        }
        inboundFax {
          id
        }
      }
      id
      resolvedAt
      baseCaseId
      code
      type
      subType
      title
      typeSpecificInputs
      comment
      signedByPractitionerId
      signedByPractitioner {
        id
        givenName
        familyName
      }
      resolvedBy {
        id
        givenName
        familyName
      }
      patient {
        id
        givenName
        familyName
      }
      resolvedStatus
      baseCase {
        id
        createdAt
        updatedAt
        assignedUser {
          id
          givenName
          familyName
        }
        assignedTaskUserPoolId
        assignedTaskUserPool {
          id
          displayName
          users {
            id
            givenName
            familyName
            userType {
              id
              name
            }
          }
        }
        createdBy {
          id
          familyName
          givenName
        }
        dueAt
      }
      contacts {
        id
      }
    }
  }
  ${ORDER_FRAGMENT}
`

const DocumentCase = () => {
  const { documentCaseId } = useParams()
  const location = useLocation()
  const { data, loading } = useQuery(FIND_DOCUMENT_CASE_DETAILS_QUERY, {
    variables: {
      documentCaseId,
    },
    skip: !documentCaseId,
  })
  if (loading || !documentCaseId || !data || !data.documentCase) return null
  const rootPath = routes.cases

  return (
    <Box className="relative h-full w-full">
      <StackView className="relative">
        <CaseHeader
          id={data.documentCase?.id}
          baseCase={data.documentCase?.baseCase}
          caseType={data.documentCase?.__typename}
          resolvedStatus={data.documentCase?.resolvedStatus}
          resolvedAt={data.documentCase?.resolvedAt}
          patient={data.documentCase?.patient}
          code={data.documentCase?.code}
        />
        <TabNav
          id="tabnav-document-case-details"
          className="relative px-5"
          rootPath={rootPath({ glob: `document/${documentCaseId}` })}
          tabs={[
            {
              name: 'Details',
              key: 'details',
              to: rootPath({ glob: `document/${documentCaseId}/details` }),
            },
            {
              name: 'Activity',
              key: 'activity',
              to: rootPath({ glob: `document/${documentCaseId}/activity` }),
            },
          ]}
        />
      </StackView>

      <PDFAnnotationContextProvider>
        <TabsLayout documentCase={data.documentCase}>
          <Routes location={location}>
            <Route path="/details" element={<DetailsTab data={data} />} />
            <Route
              path="activity"
              element={
                <ActivityTab
                  resolvedStatus={data.documentCase.resolvedStatus}
                  baseCaseId={data.documentCase.baseCaseId}
                  createdAt={data.documentCase.baseCase.createdAt}
                  resolvedAt={data.documentCase.resolvedAt}
                  resolvedBy={data.documentCase.resolvedBy}
                  createdBy={data.documentCase.baseCase.createdBy}
                />
              }
            />
          </Routes>
        </TabsLayout>
      </PDFAnnotationContextProvider>
    </Box>
  )
}

export default DocumentCase
