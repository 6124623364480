import { useRef } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { orderBy } from 'lodash'
import { FamilyMemberHistory } from 'types/graphql'

import { navigate, useLocation, useParams } from '@redwoodjs/router'

import AreaLoader from 'src/components/atoms/AreaLoader/AreaLoader'
import Table, { TableProps } from 'src/components/molecules/Table/Table'
import { sidepanelRoute } from 'src/lib/routes'

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'py-2 cursor-pointer',
  resizable: true,
  autoHeight: true,
  minWidth: 180,
}

const FamilyHistoryTable = ({
  testId,
  rowData,
  ...rest
}: Omit<TableProps<FamilyMemberHistory>, 'innerRef'>) => {
  const gridRef = useRef<AgGridReact>()

  const params = useParams()
  const patientId = params.id
  const location = useLocation()

  const sortedRowData = rowData
    ? orderBy(Object.values(rowData), ['updatedAt'], ['desc'])
    : undefined

  return (
    <Table
      testId={testId}
      innerRef={gridRef}
      rowData={sortedRowData}
      onRowClicked={(e) => {
        return navigate(
          sidepanelRoute(
            {
              route: `/patients/${patientId}/familyHistory/${e.data.id}/edit`,
            },
            location,
            params
          )
        )
      }}
      domLayout="autoHeight"
      rowHeight={50}
      headerHeight={36}
      defaultColDef={defaultColDef}
      loadingOverlayComponent={AreaLoader}
      noRowsOverlayComponentParams={{ message: 'No Family history recorded' }}
      {...rest}
    />
  )
}

export default FamilyHistoryTable
