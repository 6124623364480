import { PencilIcon } from '@heroicons/react/20/solid'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { formatDisplayName } from 'common/utils'
import { useParams } from 'react-router-dom'

import { navigate, useLocation } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import Card from 'src/components/atoms/Card/Card'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { DataDisplayList } from 'src/components/molecules/DataDisplayList'
import { SectionHeader } from 'src/components/molecules/SectionHeader/SectionHeader'
import { placeOfServiceDisplay } from 'src/data/placeOfService'
import { AddressJoinOption, formatAddress } from 'src/lib/formatters'
import { sidepanelRoute } from 'src/lib/routes'

import { useHospitalVisit } from './useHospitalVisit'

export const HospitalVisitClaimSubsection = () => {
  const location = useLocation()
  const params = useParams()
  const { data } = useHospitalVisit()

  if (!data?.hospitalVisit) return

  const visit = data.hospitalVisit
  const { claim } = visit

  return (
    <StackView gap={100}>
      <SectionHeader
        size="s"
        title="Summary"
        description="Review and edit claim-level details."
        actions={
          <Button
            testId="hospital-visit-edit-claim-btn"
            buttonStyle="secondary"
            text="Edit"
            icon={PencilIcon}
            onClick={() => {
              navigate(
                sidepanelRoute(
                  {
                    route: `/claim/${claim.id}/edit-details`,
                  },
                  location,
                  params
                )
              )
            }}
          />
        }
      />
      <Card className="px-core-space-100">
        <DataDisplayList
          testId="hospital-visit-claim-details"
          data={[
            {
              label: 'Rendering provider',
              value: claim.renderingProvider
                ? `${formatDisplayName(
                    claim.renderingProvider.practitioner
                  )}  ·  ${claim.renderingProvider.npi}`
                : '-',
            },
            {
              label: 'Supervising provider',
              value: claim.supervisingProvider
                ? `${formatDisplayName(
                    claim.supervisingProvider.practitioner
                  )}  ·  ${claim.supervisingProvider.npi}`
                : '-',
            },
            {
              label: 'Billing provider',
              value: claim.billingProvider?.name ?? '-',
            },
            {
              label: 'Service facility location',
              value: claim.hospitalServiceFacility ? (
                <StackView>
                  <Typography>{claim.hospitalServiceFacility.name}</Typography>
                  <Typography>
                    {formatAddress(
                      claim.hospitalServiceFacility.address,
                      AddressJoinOption.COMMA
                    )}
                  </Typography>
                </StackView>
              ) : (
                '-'
              ),
            },
            {
              label: 'Place of service',
              value: claim.placeOfService
                ? placeOfServiceDisplay[claim.placeOfService]
                : '-',
            },
            {
              label: 'Hospital admission date',
              value: claim.admissionDate
                ? jsDateToLocalDate(claim.admissionDate).format(
                    dateTimeFormatter('MMM d, yyyy')
                  )
                : '-',
            },
            {
              label: 'Hospital discharge date',
              value: claim.dischargeDate
                ? jsDateToLocalDate(claim.dischargeDate).format(
                    dateTimeFormatter('MMM d, yyyy')
                  )
                : '-',
            },
          ]}
        />
      </Card>
    </StackView>
  )
}
