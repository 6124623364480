import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import { emptyPlaceholder } from 'src/lib/formatters'

import { ColumnDisplay } from '../ColumnDisplay'

import { useMenstrualHistoryQuery } from './useMenstrualHistory'

const VerticalDivider = () => <div className="mx-4 w-[1px] bg-gray-100"></div>
const emptyPlaceholderEntry = {
  display: emptyPlaceholder,
  value: null,
  enumValue: null,
}
const MenstrualHistory = ({
  patientId,
  testId,
}: {
  patientId: string
  testId: string
}) => {
  const { menstrualHistory } = useMenstrualHistoryQuery(patientId)

  const { numberOfPadsUsed, numberOfTamponsUsed } = menstrualHistory

  const numberOfPadsOrTamponsPerDay =
    numberOfPadsUsed && numberOfTamponsUsed
      ? Number(numberOfPadsUsed.value) + Number(numberOfTamponsUsed.value)
      : numberOfPadsUsed
        ? Number(numberOfPadsUsed.value)
        : numberOfTamponsUsed
          ? Number(numberOfTamponsUsed.value)
          : undefined

  const padsOrTamponsPerDayEntry = {
    display:
      numberOfPadsOrTamponsPerDay &&
      parseFloat(numberOfPadsOrTamponsPerDay.toFixed(2)).toString(),
    value: null,
    enumValue: null,
  }

  return (
    <Box
      horizontalPadding={100}
      verticalPadding={75}
      className="rounded border border-gray-200 pb-4"
    >
      <StackView space={75} className="pb-4" data-testid={testId}>
        <StackView direction="row">
          <ColumnDisplay
            label="Menarche"
            entry={menstrualHistory.ageAtMenarche ?? emptyPlaceholderEntry}
            useUnitDisplay={true}
          />
          <VerticalDivider />

          <ColumnDisplay
            label="LMP date"
            entry={
              menstrualHistory?.lastMenstrualPeriodDate ?? emptyPlaceholderEntry
            }
          />
          <VerticalDivider />

          <ColumnDisplay
            label="LMP duration"
            entry={
              menstrualHistory?.lastMenstrualPeriodDuration ??
              emptyPlaceholderEntry
            }
            useUnitDisplay={true}
          />
          <VerticalDivider />

          <ColumnDisplay
            label="Regularity"
            entry={menstrualHistory.regularity}
          />
        </StackView>
        <StackView direction="row">
          <ColumnDisplay
            label="Flow"
            entry={menstrualHistory.menstrualFlow ?? emptyPlaceholderEntry}
          />
          <VerticalDivider />
          <ColumnDisplay
            label="Average number of pads or tampons per day since period onset"
            entry={padsOrTamponsPerDayEntry ?? emptyPlaceholderEntry}
          />
          <VerticalDivider />

          <ColumnDisplay
            label="Clots present"
            entry={menstrualHistory.clotsPresent}
          />
          <VerticalDivider />

          <ColumnDisplay
            label="Pain"
            entry={menstrualHistory.menstrualPain ?? emptyPlaceholderEntry}
          />
        </StackView>
      </StackView>
    </Box>
  )
}

export default MenstrualHistory
