import { XMarkIcon } from '@heroicons/react/20/solid'
import { isEmpty } from 'lodash'

import { RegisterOptions, useFormContext } from '@redwoodjs/forms'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import { useEffectOnce } from 'src/utils'

import { InputProps } from '../FormInputList'

export interface FlatFieldArrayProps {
  name: string
  addButtonLabel: string
  // This type could be improved to indicate that it is an *input* component (not an arbitrary react component)
  FormInputComponent: React.FC<InputProps>
  formInputComponentProps?: Partial<InputProps>
  allowEmpty?: boolean
  validation?: RegisterOptions
}

function FlatFieldArray({
  name,
  formInputComponentProps,
  addButtonLabel,
  FormInputComponent,
  allowEmpty = false,
  validation,
}: FlatFieldArrayProps) {
  const { watch, setValue, register } = useFormContext()
  register(
    name,
    validation
      ? {
          ...validation,
          validate: validation.validate
            ? validation.validate
            : validation.required
              ? (value) => {
                  if (value.length === 0 || value.some((v) => v === '' || !v)) {
                    return validation.required as string
                  }
                }
              : undefined,
        }
      : undefined
  )
  const values = watch(name)

  useEffectOnce(() => {
    if (!allowEmpty && isEmpty(values)) {
      setValue(name, [''])
    }
  })

  return (
    <StackView space={50}>
      {values &&
        values.map((value, index) => {
          const fullName = `${name}.${index}`
          return (
            <StackView
              key={index + value}
              direction="row"
              space={50}
              alignItems="center"
            >
              <Box grow>
                <FormInputComponent
                  name={fullName}
                  {...formInputComponentProps}
                />
              </Box>
              <Button
                data-testid="remove-item-button"
                buttonStyle="ghost"
                icon={XMarkIcon}
                onClick={() => {
                  const updated = values
                  updated.splice(index, 1)
                  setValue(name, updated)
                }}
                className="!text-gray-400"
              />
            </StackView>
          )
        })}
      <Button
        buttonStyle="secondary"
        className="border-dashed !text-gray-400"
        text={addButtonLabel}
        onClick={() => setValue(name, [...(values || []), ''])}
      />
    </StackView>
  )
}

export default FlatFieldArray
