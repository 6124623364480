import type { CreateExternalRequestInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import ExternalRequestTypeSelectField from 'src/components/atoms/ExternalRequestTypeSelectField/ExternalRequestTypeSelectField'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import InputField from 'src/components/atoms/InputField'
import FormInputList, {
  Item,
} from 'src/components/molecules/FormInputList/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

const CREATE_EXTERNAL_REQUEST_MUTATION = gql`
  mutation CreateExternalRequest($input: CreateExternalRequestInput!) {
    createExternalRequest(input: $input) {
      id
    }
  }
`

export const externalRequestInputListItems: (opts?: {
  type?: string
  patientId?: string
  isDocumentCase?: boolean
}) => Item[] = () => [
  {
    name: 'type',
    label: 'Document sub-type',
    required: true,
    formInputComponent: ExternalRequestTypeSelectField,
  },
  {
    name: 'title',
    label: 'Document title',
    formInputComponent: InputField,
    required: false,
  },
]

const CreateExternalRequest = () => {
  const { closeSidePanel } = useSidepanel()
  const [createExternalRequest, { loading: creatingExternalRequest }] =
    useMutation(CREATE_EXTERNAL_REQUEST_MUTATION, {
      onCompleted: () => {
        toast.success('External request created')
        closeSidePanel()
      },
      refetchQueries: ['ExternalRequestsQuery'],
    })
  const formMethods = useForm<CreateExternalRequestInput>()

  return (
    <SidepanelForm
      formMethods={formMethods}
      onSubmit={async (value) => {
        const { binary: binaryInput, ...input } = value

        input['binary'] = {
          content: await base64EncodeFile(binaryInput[0]),
          contentType: binaryInput[0].type,
        }
        createExternalRequest({ variables: { input } })
      }}
      footerProps={{
        submitText: 'Upload document',
        submitting: creatingExternalRequest,
      }}
    >
      <FormInputList
        items={[
          ...externalRequestInputListItems(),
          {
            name: 'binary',
            label: 'Document upload',
            required: true,
            formInputComponent: FileInput,
          },
        ]}
      />
    </SidepanelForm>
  )
}

export default CreateExternalRequest
