import type { PreferredPronouns } from 'types/graphql'

import { nameValueArrayFromObject } from './utils'

export const preferredPronounDisplay: {
  [key in PreferredPronouns]: string
} = {
  CO: 'co/co/cos/cos/coself',
  EN: 'en/en/ens/ens/enself',
  EY: 'ey/em/eir/eirs/emself',
  HE: 'he/him/his/his/himself',
  SHE: 'she/her/her/hers/herself',
  THEY: 'they/them/their/theirs/themselves',
  VE: 've/vis/ver/ver/verself',
  XIE: 'xie/hir ("here")/hir/hirs/hirself',
  YO: 'yo/yo/yos/yos/yoself',
  ZE: 'ze/zir/zir/zirs/zirself',
}

export const preferredPronounPatientHeaderDisplay: {
  [key in PreferredPronouns]: string
} = {
  CO: 'Co, co, cos, cos, coself',
  EN: 'En, en, ens, ens, enself',
  EY: 'Ey, em, eir, eirs, emself',
  HE: 'He, him, his, his, himself',
  SHE: 'She, her, her, hers,herself',
  THEY: 'They, them, their, theirs, themselves',
  VE: 'Ve, vis, ver, ver, verself',
  XIE: 'Xie, hir ("here"), hir, hirs, hirself',
  YO: 'Yo, yo, yos, yos, yoself',
  ZE: 'Ze, zir, zir, zirs, zirself',
}

export const preferredPronouns = nameValueArrayFromObject<PreferredPronouns>(
  preferredPronounDisplay
)
