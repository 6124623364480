import { match } from 'ts-pattern'

import { navigate, routes } from '@redwoodjs/router'

import Typography from 'src/components/atoms/Typography'
import Modal from 'src/components/molecules/Modal'
import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'

import { useVisitBillingState } from './useVisitBillingState'

export const BillingOrdersWarningModal = ({
  isOpen,
  onConfirm,
  onCancel,
  action,
}: {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  action?: 'SIGN_VISIT' | 'SUBMIT_BILLING_CODES'
}) => {
  return (
    <Modal
      testId="visit-orders-billing-warning-modal"
      isOpen={isOpen}
      title="Open billing notifications"
      content={
        <Typography
          textStyle="body-s"
          color="text-base-color-fg-subtle"
          testId="visit-orders-billing-warning"
        >
          {match(action)
            .with(
              'SIGN_VISIT',
              () =>
                'There are open billing warning notifications that may need to be addressed prior to signing of the visit note.'
            )
            .with(
              'SUBMIT_BILLING_CODES',
              () =>
                'There are open billing warning notifications that may need to be addressed prior to submission of billing codes for this visit.'
            )
            .exhaustive()}
        </Typography>
      }
      modalStyle="warning"
      primaryButton={{
        text: match(action)
          .with('SIGN_VISIT', () => 'Sign note')
          .with('SUBMIT_BILLING_CODES', () => 'Submit billing codes')
          .exhaustive(),
        onClick: onConfirm,
      }}
      setIsOpen={(isOpen) => {
        if (isOpen) {
          return
        }

        onCancel()
      }}
    />
  )
}

export const useBillingOrdersWarningConfirmation = ({
  patientId,
  appointmentId,
}: {
  patientId: string
  appointmentId: string
}) => {
  const { data, loading } = useVisitBillingState()
  const [state, waitForConfirmation] = useConfirmation()

  const checkForBillingWarning = async () => {
    if (data.visit.encounterMode === 'SIMPLIFIED') return { confirmed: true }
    if (!data.showOrderBillingWarning) return { confirmed: true }

    const result = await waitForConfirmation()

    if (!result.confirmed) {
      navigate(
        routes.patientChartsGlob({
          id: patientId,
          glob: `visits/${appointmentId}/billing`,
        })
      )
    }

    return result
  }

  return [state, checkForBillingWarning, loading] as const
}
