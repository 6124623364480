import { useParams } from 'react-router-dom'
import { CreatePayerAddressInput } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import CountrySelectField from 'src/components/atoms/CountrySelectField/CountrySelectField'
import InputField from 'src/components/atoms/InputField'
import StateSelectField from 'src/components/atoms/StateSelectField/StateSelectField'
import FormInputList from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const CREATE_PAYER_ADDRESS = gql`
  mutation createPayerAddress($id: String!, $input: CreatePayerAddressInput!) {
    createPayerAddress(id: $id, input: $input) {
      id
      addresses {
        id
        purpose
        line1
        line2
        city
        country
        district
        postalCode
        state
        zipPlusFour
      }
    }
  }
`

const SidepanelPayerAddressCreate = () => {
  const { payerId } = useParams()
  const { closeSidePanel } = useSidepanel()
  const [createPayerAddress, { loading }] = useMutation(CREATE_PAYER_ADDRESS, {
    onCompleted: () => {
      toast.success(`Patient address added`)
      closeSidePanel()
    },
  })
  const formMethods = useForm()

  if (!payerId) {
    return <SidepanelNotAvailable />
  }

  const onSubmit = async (input: CreatePayerAddressInput) => {
    createPayerAddress({
      variables: { id: payerId, input },
    })
  }

  return (
    <SidepanelPage
      testId="sidepanel-payer-address-create"
      header="Add new mailing address"
      description="Add a new mailing address for the payer. Specify the purpose of the new mailing address per what the payer has laid out."
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Save',
          submitting: loading,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'purpose',
              label: 'Purpose of mailing address',
              required: true,
              formInputComponent: InputField,
            },
            {
              name: 'line1',
              label: 'Street address',
              required: true,
              formInputComponent: InputField,
            },
            {
              name: 'line2',
              label: 'Apt, suite, etc',
              formInputComponent: InputField,
            },
            {
              name: 'city',
              label: 'City',
              required: true,
              formInputComponent: InputField,
            },
            {
              name: 'state',
              label: 'State',
              required: true,
              formInputComponent: StateSelectField,
            },
            {
              name: 'postalCode',
              label: 'Postal code',
              required: true,
              formInputComponent: InputField,
            },
            {
              name: 'country',
              label: 'Country',
              required: true,
              formInputComponent: CountrySelectField,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPayerAddressCreate
