import { useRef } from 'react'

import { PencilIcon } from '@heroicons/react/24/solid'
import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { OrderFragment, VisitConditionFragment } from 'types/graphql'

import { navigate, useLocation } from '@redwoodjs/router'

import Badge from 'src/components/atoms/Badge'
import Button from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import DiagnosisCodeSwapButton from 'src/components/molecules/DiagnosisCodeSwapButton/DiagnosisCodeSwapButton'
import Table from 'src/components/molecules/Table'
import { sidepanelRoute } from 'src/lib/routes'

type RowData = VisitConditionFragment & {
  isPrimary?: boolean
  orders?: OrderFragment[]
}

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'cursor-pointer',
  resizable: false,
}

const ActionButtons: React.FC<{ data: RowData; encounterId: string }> = ({
  data,
  encounterId,
}) => {
  const { id, isPrimary, diagnosisCode } = data
  const location = useLocation()

  return (
    <>
      {isPrimary && diagnosisCode.diagnosisCodeGroupId && (
        <DiagnosisCodeSwapButton
          conditionId={id}
          encounterId={encounterId}
          diagnosisCodeGroupId={diagnosisCode.diagnosisCodeGroupId}
          diagnosisCodeId={diagnosisCode.id}
        />
      )}
      <Button
        buttonStyle="secondary"
        icon={PencilIcon}
        text="Edit"
        onClick={() =>
          navigate(
            sidepanelRoute(
              {
                route: `/encounters/${encounterId}/diagnoses/${id}/edit`,
              },
              location
            )
          )
        }
      />
    </>
  )
}

interface DiagnosisDetailsProps {
  code: string
  display: string
  orders?: OrderFragment[]
  isPrimary?: boolean
}

const DiagnosisDetails: React.FC<DiagnosisDetailsProps> = ({
  code,
  display,
  orders,
  isPrimary = false,
}) => {
  return (
    <StackView space={25} className="truncate">
      <Typography textStyle="title" className="truncate">
        {display}
      </Typography>
      <StackView direction="row" space={50} alignItems="center" wrap>
        <Typography textStyle="description">{code}</Typography>
        {orders && !!orders.length && (
          <>
            <Typography textStyle="description">•</Typography>
            {orders.map((order) => (
              <Typography key={order.id} textStyle="description">
                {order.name}
              </Typography>
            ))}
          </>
        )}
        {isPrimary && <Badge color="green">Primary</Badge>}
      </StackView>
    </StackView>
  )
}

const columnDefs: ColDef<RowData>[] = [
  {
    colId: 'diagnosis',
    cellRenderer: ({
      data,
      context,
    }: {
      data: RowData
      context: { encounterId: string }
    }) => {
      return (
        <StackView justifyContent="between" direction="row" space={100}>
          <DiagnosisDetails
            code={data.code.code}
            display={data.code.display}
            isPrimary={data.isPrimary}
            orders={data.orders}
          />
          <StackView
            direction="row"
            fullWidth={false}
            justifyContent="center"
            alignItems="center"
            gap={50}
            className="grow"
          >
            <ActionButtons data={data} encounterId={context.encounterId} />
          </StackView>
        </StackView>
      )
    },
  },
]

const VisitDiagnosesTable = ({
  encounterId,
  primaryDiagnosis,
  additionalDiagnoses,
  orders,
}: {
  encounterId: string
  primaryDiagnosis?: RowData
  additionalDiagnoses?: RowData[]
  orders?: OrderFragment[]
}) => {
  const gridRef = useRef<AgGridReact>()

  let rowData: RowData[] = []

  if (primaryDiagnosis) {
    rowData.push({
      ...primaryDiagnosis,
      isPrimary: true,
      orders: orders?.filter((order) =>
        order.diagnosisCodes?.some(
          (diagnosisCode) => diagnosisCode.code === primaryDiagnosis.code.code
        )
      ),
    })
  }

  if (additionalDiagnoses) {
    rowData = rowData.concat(
      additionalDiagnoses.map((diagnosis) => ({
        ...diagnosis,
        orders: orders?.filter((order) =>
          order.diagnosisCodes?.some(
            (diagnosisCode) => diagnosisCode.code === diagnosis.code.code
          )
        ),
      }))
    )
  }

  return (
    <Table<RowData>
      testId="visit-diagnoses-table"
      innerRef={gridRef}
      rowData={rowData}
      domLayout="autoHeight"
      rowHeight={76}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      pagination={false}
      context={{ encounterId }}
      hideHeader
    />
  )
}

export default VisitDiagnosesTable
