import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'

import { useLocation, useParams, navigate } from '@redwoodjs/router'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import EFaxIcon from 'src/components/icons/EFaxIcon/EFaxIcon'
import Modal from 'src/components/molecules/Modal'
import SidepanelPage, {
  SidepanelPageProps,
} from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'
import { sidepanelRoute } from 'src/lib/routes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import Button from '../../atoms/Button'

export type GenericDisplayDocument = {
  updatedAt: string
  title?: string
  updatedBy?: {
    givenName?: string
    familyName?: string
  }
  binary: {
    id: string
    content: {
      url: string
      contentType: string
    }
  }
}

interface SidepanelDocumentPageProps extends SidepanelPageProps {
  document: GenericDisplayDocument
  editRoute?: string
  faxable?: boolean
  onDelete?: () => void
}

const SidepanelDocumentPage = ({
  description,
  subHeader,
  document,
  faxable,
  editRoute,
  onDelete,
  ...rest
}: SidepanelDocumentPageProps) => {
  const location = useLocation()
  const params = useParams()

  const { closeSidePanel } = useSidepanel()

  const { binary } = document

  const [deleteDocumentModalState, waitForConfirmation] = useConfirmation({
    onCancel: () => {},
    onConfirm: () => {
      if (onDelete) {
        onDelete()
      }
      closeSidePanel()
    },
  })

  return (
    <SidepanelPage
      subHeader={subHeader ?? document.title}
      description={
        description ??
        `Last modified on ${format(new Date(document.updatedAt), 'PP')}${
          document.updatedBy
            ? ` · Last modified by ${formatDisplayName(document.updatedBy)}`
            : ''
        }`
      }
      {...rest}
    >
      <StackView className="grow p-core-space-50" space={75}>
        <StackView direction="row" space={50}>
          {faxable && (
            <Button
              buttonStyle="secondary"
              icon={EFaxIcon}
              text="E-fax"
              onClick={() =>
                navigate(
                  sidepanelRoute(
                    {
                      route: '/send-fax',
                      documents: JSON.stringify([
                        {
                          documentId: binary.id,
                          documentType: 'BINARY',
                        },
                      ]),
                    },
                    location,
                    params
                  )
                )
              }
            />
          )}
          {editRoute && (
            <Button
              buttonStyle="secondary"
              icon={PencilIcon}
              text="Edit"
              onClick={() =>
                navigate(sidepanelRoute({ route: editRoute }, location, params))
              }
            />
          )}
          {onDelete ? (
            <Button
              buttonStyle="secondary"
              icon={TrashIcon}
              text="Delete"
              onClick={waitForConfirmation}
            />
          ) : null}
        </StackView>
        <FileDisplay
          url={binary.content.url}
          type={binary.content.contentType}
        />
      </StackView>

      <Modal
        isOpen={deleteDocumentModalState.isConfirming}
        title={`Delete document`}
        content={
          <StackView space={25}>
            <Typography>
              {`Are you sure you want to delete the document "${rest.header}"?`}
            </Typography>
            <Typography>This action cannot be undone.</Typography>
          </StackView>
        }
        modalStyle="danger"
        primaryButton={{
          text: 'Delete',
          onClick: deleteDocumentModalState.confirm,
        }}
        setIsOpen={(isOpen) => {
          if (isOpen) {
            return
          }
          deleteDocumentModalState.cancel()
        }}
      />
    </SidepanelPage>
  )
}

export default SidepanelDocumentPage
