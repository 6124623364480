import { useState } from 'react'

import {
  encounterModeOptions,
  encounterModeToDisplay,
} from 'common/data/appointments'
import { match } from 'ts-pattern'
import {
  EncounterMode,
  FindPatientVisit,
  SetEncounterMode,
  SetEncounterModeVariables,
  TenantForEncounterModeDropdown,
  TenantForEncounterModeDropdownVariables,
} from 'types/graphql'

import { useMutation, useQuery } from '@redwoodjs/web'

import Dropdown from 'src/components/atoms/Dropdown/Dropdown'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import Modal from 'src/components/molecules/Modal/Modal'
import { useConfirmation } from 'src/hooks/useConfirmation/useConfirmation'

const QUERY = gql`
  query TenantForEncounterModeDropdown {
    tenant {
      id
      billingMode
    }
  }
`

const SET_ENCOUNTER_MODE = gql`
  mutation SetEncounterMode(
    $appointmentId: String!
    $encounterMode: EncounterMode!
  ) {
    setAppointmentEncounterMode(
      appointmentId: $appointmentId
      encounterMode: $encounterMode
    ) {
      id
      encounterMode
    }
  }
`

export const EncounterModeDropdown = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const { data } = useQuery<
    TenantForEncounterModeDropdown,
    TenantForEncounterModeDropdownVariables
  >(QUERY)
  const [modalState, waitForConfirmation] = useConfirmation()
  const [switchingToEncounterMode, setSwitchingToEncounterMode] =
    useState<EncounterMode>('SIMPLIFIED')

  const [setEncounterMode, { loading }] = useMutation<
    SetEncounterMode,
    SetEncounterModeVariables
  >(SET_ENCOUNTER_MODE)

  const billingMode = data?.tenant.billingMode

  if (!billingMode) return null

  return (
    <>
      <Dropdown
        name="encounter-mode"
        text={encounterModeToDisplay[visit.encounterMode]}
        options={data?.tenant ? encounterModeOptions(data.tenant) : []}
        selected={visit.encounterMode}
        disabled={visit.chartingStatus !== 'OPEN'}
        onSelect={async (value: EncounterMode) => {
          if (value === visit.encounterMode) return

          setSwitchingToEncounterMode(value)

          const { confirmed } = await waitForConfirmation()

          if (!confirmed) return

          void setEncounterMode({
            variables: {
              appointmentId: visit.id,
              encounterMode: value,
            },
          })
        }}
      />
      <Modal
        isOpen={modalState.isConfirming || loading}
        modalStyle="warning"
        hideIcon
        title={match(switchingToEncounterMode)
          .with('STANDARD', () => 'Switch to a standard visit')
          .with('SIMPLIFIED', () => 'Switch to a simplified visit')
          .with(
            'DIRECT_PRIMARY_CARE',
            () => 'Switch to a DPC & concierge visit'
          )
          .exhaustive()}
        content={match({
          encounterMode: switchingToEncounterMode,
          billingMode,
        })
          .with({ encounterMode: 'STANDARD' }, () => (
            <StackView>
              <Typography textStyle="body-s" color="text-base-color-fg-subtle">
                Are you sure you want to switch to a standard visit that can be
                billed to the patient&apos;s health plan?
              </Typography>
              <Typography textStyle="body-s" color="text-base-color-fg-subtle">
                While patient chart updates completed so far during this visit
                are saved, past visit documentation is not included in your
                standard visit note.
              </Typography>
            </StackView>
          ))
          .with(
            { billingMode: 'FEE_FOR_SERVICE', encounterMode: 'SIMPLIFIED' },
            () => (
              <StackView className="text-base-color-fg-subtle">
                <Typography
                  textStyle="body-s"
                  color="text-base-color-fg-subtle"
                >
                  Are you sure you want to switch to a simplified visit that
                  cannot be billed to the patient&apos;s health plan?
                </Typography>
                <Typography
                  textStyle="body-s"
                  color="text-base-color-fg-subtle"
                >
                  While patient chart updates completed so far during this visit
                  are saved, past visit documentation is not included in your
                  simplified visit note.
                </Typography>
              </StackView>
            )
          )
          .with(
            { billingMode: 'DIRECT_PRIMARY_CARE', encounterMode: 'SIMPLIFIED' },
            () => (
              <StackView className="text-base-color-fg-subtle">
                <Typography
                  textStyle="body-s"
                  color="text-base-color-fg-subtle"
                >
                  Are you sure you want to switch to a simplified visit
                  experience?
                </Typography>
                <Typography
                  textStyle="body-s"
                  color="text-base-color-fg-subtle"
                >
                  While patient chart updates completed so far during this visit
                  are saved, past visit documentation is not included in your
                  simplified visit note.
                </Typography>
              </StackView>
            )
          )
          .with({ encounterMode: 'DIRECT_PRIMARY_CARE' }, () => (
            <StackView className="text-base-color-fg-subtle">
              <Typography textStyle="body-s" color="text-base-color-fg-subtle">
                Are you sure you want to switch to a more extensive DPC &
                concierge visit experience?
              </Typography>
              <Typography textStyle="body-s" color="text-base-color-fg-subtle">
                While patient chart updates completed so far during this visit
                are saved, past visit documentation is not included in your DPC
                & concierge visit note.
              </Typography>
            </StackView>
          ))
          .exhaustive()}
        primaryButton={{
          text: 'Continue and switch',
          onClick: modalState.confirm,
          loading,
        }}
        setIsOpen={(isOpen) => {
          if (isOpen) return

          modalState.cancel()
        }}
      />
    </>
  )
}
