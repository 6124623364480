import Box from 'src/components/atoms/Box'
import Divider from 'src/components/atoms/Divider/Divider'
import StackView from 'src/components/atoms/StackView'
import { ColumnDisplay } from 'src/pages/PatientChartsPage/PatientHistory/ColumnDisplay'

import { ContraceptiveSummary } from './ContraceptiveSummary'
import { SexualIntercourseSummary } from './SexualIntercourseSummary'
import { useSexualHistoryQuery } from './useSexualHistory'

const VerticalDivider = () => <div className="mx-4 w-[1px] bg-gray-100"></div>

const SexualHistory = ({
  patientId,
  testId,
}: {
  patientId: string
  testId: string
}) => {
  const { sexualHistory } = useSexualHistoryQuery(patientId)
  if (!sexualHistory) return null

  return (
    <Box
      horizontalPadding={100}
      verticalPadding={75}
      className="rounded border border-gray-200 pb-4"
    >
      <StackView space={75} className="pb-4" data-testid={testId}>
        <StackView direction="row">
          <ColumnDisplay label="Status" entry={sexualHistory.status} />
          <VerticalDivider />

          <ColumnDisplay
            label="Total partners"
            entry={sexualHistory.partners?.total}
          />
          <VerticalDivider />

          <ColumnDisplay
            label="Total partners in past 3 months"
            entry={sexualHistory.partners?.inLastThreeMonths}
          />
          <VerticalDivider />

          <ColumnDisplay
            label="Total partners in past 12 months"
            entry={sexualHistory.partners?.inLastTwelveMonths}
          />
        </StackView>
        <Divider />
        <StackView direction="row">
          <ColumnDisplay
            label="Sexual orientation"
            entry={sexualHistory.sexualOrientation}
          />
          <VerticalDivider />

          <ColumnDisplay
            label="Gender identity"
            entry={sexualHistory.genderIdentity}
          />
          <VerticalDivider />

          <ColumnDisplay
            label="Sexual intercourse"
            component={
              <SexualIntercourseSummary
                sexualIntercourse={sexualHistory?.sexualIntercourse}
              />
            }
          />
          <VerticalDivider />

          <ColumnDisplay
            label="Contraceptives"
            component={
              <ContraceptiveSummary
                contraceptives={sexualHistory?.contraceptives}
              />
            }
          />
        </StackView>
      </StackView>
    </Box>
  )
}

export default SexualHistory
