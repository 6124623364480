import type {
  PatientDemographicsFragment,
  UpdatePatientInput,
} from 'types/graphql'

import EthnicitySelectField from 'src/components/atoms/EthnicitySelectField/EthnicitySelectField'
import InputField from 'src/components/atoms/InputField'
import PreferredPronounsSelectField from 'src/components/atoms/PreferredPronounSelectField/PreferredPronounsSelectField'
import RaceSelectField from 'src/components/atoms/RaceSelectField/RaceSelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard/DataVerificationCard'
import { ethnicityDisplay } from 'src/data/ethnicities'
import { preferredPronounDisplay } from 'src/data/preferredPronouns'
import { raceDisplay } from 'src/data/races'
import { UPDATE_PATIENT_ADDITIONAL_DEMOGRAPHICS } from 'src/pages/AppointmentCheckInPage/CheckInPatientTab/PatientAdditionalDemographicsVerificationCard'

export const PatientAdditionalDemographicsCard = ({
  patient,
}: {
  patient: PatientDemographicsFragment
}) => {
  const preSubmit = (input: UpdatePatientInput) => {
    return { variables: { id: patient.id, input } }
  }

  return (
    <DataVerificationCard<UpdatePatientInput>
      title="Additional demographics"
      updateMutation={UPDATE_PATIENT_ADDITIONAL_DEMOGRAPHICS}
      preSubmit={preSubmit}
      data={[
        {
          name: 'preferredPronouns',
          label: 'Preferred pronouns',
          value: patient.preferredPronouns,
          displayValue: preferredPronounDisplay[patient.preferredPronouns],
          formInputComponent: PreferredPronounsSelectField,
        },
        {
          name: 'race',
          label: 'Race',
          value: patient.race,
          displayValue: raceDisplay[patient.race],
          formInputComponent: RaceSelectField,
        },
        {
          name: 'ethnicity',
          label: 'Ethnicity',
          value: patient.ethnicity,
          displayValue: ethnicityDisplay[patient.ethnicity],
          formInputComponent: EthnicitySelectField,
        },
        {
          name: 'socialSecurityNumber',
          label: 'Social security number',
          value: patient.socialSecurityNumber,
          displayValue: patient.socialSecurityNumber,
          formInputComponent: InputField,
        },
      ]}
    />
  )
}

export default PatientAdditionalDemographicsCard
