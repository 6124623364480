import React, { useMemo } from 'react'

import { LocalDate, ZoneOffset, ZoneId, convert } from '@js-joda/core'
import { observationConcepts } from 'common/cdr/concepts/observations/index'
import {
  EMPTY_VITAL_VALUE_DISPLAY,
  HEAD_CIRCUMFERENCE_REQUIRED_AGE_LIMIT,
  temperatureTypeCodeToEnumMap,
  UpsertVitalsInput,
  vitalsCodes,
  VitalsCode,
} from 'common/cdr/concepts/observations/vitals/index'
import {
  isSplitUnit,
  ucumUnitToUnitEnumMap,
} from 'common/unitConverter/unitConverter'
import { format, formatDistance, parseISO } from 'date-fns'
import isEmpty from 'lodash/isEmpty'
import { splitUnit, unit } from 'mathjs'
import { useParams } from 'react-router-dom'
import {
  FindPatientVisit,
  Observation,
  TemperatureSourceCodingKey,
  UpsertPatientVitalsInput,
  Unit,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { useParams as useParamsRW } from '@redwoodjs/router'
import { toast } from '@redwoodjs/web/toast'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import ButtonFooter from 'src/components/molecules/ButtonFooter/ButtonFooter'
import DestructiveAction from 'src/components/molecules/DestructiveAction/DestructiveAction'
import FeatureFlagged from 'src/components/molecules/FeatureFlagged/FeatureFlagged'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import {
  vitalLabels,
  vitalObservationDisplayToField,
} from 'src/components/VisitIntakeVital'
import { BloodPressureInput } from 'src/components/VisitIntakeVital/BloodPressureInput'
import { BodyHeightInput } from 'src/components/VisitIntakeVital/BodyHeightInput'
import { BodyTemperatureInput } from 'src/components/VisitIntakeVital/BodyTemperatureInput'
import { BodyWeightInput } from 'src/components/VisitIntakeVital/BodyWeightInput'
import { FractionOfInspiredOxygenInput } from 'src/components/VisitIntakeVital/FractionOfInspiredOxygenInput'
import { HeadCircumferenceInput } from 'src/components/VisitIntakeVital/HeadCircumferenceInput'
import { HeartRateInput } from 'src/components/VisitIntakeVital/HeartRateInput'
import { PulseOximetryInput } from 'src/components/VisitIntakeVital/PulseOximetryInput'
import { RespiratoryRateInput } from 'src/components/VisitIntakeVital/RespiratoryRateInput'
import { useDeletePatientVitals } from 'src/hooks/useDeletePatientVitals/useDeletePatientVitals'
import { calculateAge, formatDateFieldValue } from 'src/lib/formatters'
import {
  useUpsertPatientVitalsMutation,
  useVitalsQuery,
} from 'src/pages/PatientChartsPage/PatientResults/Vitals/useVitals'
import {
  useVisitQuery,
  useVisitVitalsQuery,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

const VITALS_ROUNDING_PRECISION = 3
const formatVitalValue = (value: string | undefined) => {
  return parseFloat(Number(value).toFixed(VITALS_ROUNDING_PRECISION)).toString()
}

const buildFormState = (
  vitals: FindPatientVisit['appointment']['encounter'],
  isSplitBodyWeight: boolean
) => {
  return {
    bodyHeight: buildVitalValueFormState({
      value: vitals?.bodyHeight?.value,
      defaultUnit: 'in',
    }),
    bodyWeight: buildBodyWeightFormState(
      vitals?.bodyWeight?.value,
      isSplitBodyWeight
    ),
    headCircumference: buildVitalValueFormState({
      value: vitals?.headCircumference?.value,
      defaultUnit: 'in',
    }),
    bodyTemperature: {
      ...buildVitalValueFormState({
        value: vitals?.bodyTemperature?.value,
        defaultUnit: 'degF',
      }),
      type:
        temperatureTypeCodeToEnumMap[
          vitals?.bodyTemperature?.coding?.[1]?.code
        ] || ('ORAL_TEMPERATURE' as TemperatureSourceCodingKey),
    },
    bloodPressure: buildBloodPressureFormState(vitals?.bloodPressure),
    heartRate: buildVitalValueFormState({
      value: vitals?.heartRate?.value,
      defaultUnit: 'BEATS_PER_MINUTE',
    }),
    respiratoryRate: buildVitalValueFormState({
      value: vitals?.respiratoryRate?.value,
      defaultUnit: 'BREATHS_PER_MINUTE',
    }),
    pulseOximetry: buildVitalValueFormState({
      value: vitals?.pulseOximetry?.value,
      defaultUnit: 'PERCENT',
    }),
    fractionOfInspiredOxygen: buildVitalValueFormState({
      value: vitals?.fractionOfInspiredOxygen?.value,
      defaultUnit: 'PERCENT',
    }),
  }
}

const buildBodyWeightFormState = (
  bodyWeightValue: Observation['value'],
  isSplitBodyWeight: boolean
): {
  wholeUnit: {
    value: string
    unit: Unit
  }
  partialUnit: {
    value: string
    unit: Unit
  }
} => {
  if (isSplitBodyWeight) {
    if (!bodyWeightValue) {
      return {
        wholeUnit: {
          value: EMPTY_VITAL_VALUE_DISPLAY,
          unit: 'lbs',
        },
        partialUnit: {
          value: EMPTY_VITAL_VALUE_DISPLAY,
          unit: 'oz',
        },
      }
    }

    const [whole, partial] = splitUnit(
      unit(bodyWeightValue.value, bodyWeightValue.unit),
      [unit('lbs'), unit('oz')]
    ).map((u) => u.format({ notation: 'fixed', precision: 1 }))
    const [wholeValue, wholeUnit] = whole.split(' ')
    const [partialValue, partialUnit] = partial.split(' ')
    return {
      wholeUnit: {
        value: wholeValue.split(' ')[0],
        unit: wholeUnit as Unit,
      },
      partialUnit: {
        value: partialValue.split(' ')[0],
        unit: partialUnit as Unit,
      },
    }
  }

  return {
    wholeUnit: buildVitalValueFormState({
      value: bodyWeightValue,
      defaultUnit: 'lbs',
    }),
    partialUnit: {
      value: EMPTY_VITAL_VALUE_DISPLAY,
      unit: 'oz',
    },
  }
}

const buildBloodPressureFormState = (
  bloodPressure: FindPatientVisit['appointment']['encounter']['bloodPressure']
) => {
  return {
    systolic: buildVitalValueFormState({
      value: bloodPressure?.components?.find(
        (component) =>
          component.code?.code ===
          observationConcepts['BLOOD_PRESSURE_SYSTOLIC'].code
      )?.value,
      defaultUnit: 'mmHg',
    }),
    diastolic: buildVitalValueFormState({
      value: bloodPressure?.components?.find(
        (component) =>
          component.code?.code ===
          observationConcepts['BLOOD_PRESSURE_DIASTOLIC'].code
      )?.value,
      defaultUnit: 'mmHg',
    }),
  }
}

const buildVitalValueFormState = ({
  value,
  defaultUnit,
}: {
  value?: Observation['value']
  defaultUnit?: Unit
}) => {
  if (!value) {
    return {
      value: EMPTY_VITAL_VALUE_DISPLAY,
      unit: defaultUnit,
    }
  }
  return {
    value: formatVitalValue(value.value),
    unit: ucumUnitToUnitEnumMap[value.unit] || value.unit || defaultUnit,
  }
}

export const accumulateUpsertVitalsInput = (
  vitals: UpsertVitalsInput,
  vital: { name: string; unit: string; value: string | number }
): UpsertVitalsInput => {
  const { name, unit, value } = vital
  if (
    name === 'bodyHeight' ||
    name === 'headCircumference' ||
    name === 'bodyTemperature'
  ) {
    vitals[name] = {
      unit: unit as Unit,
      value: value.toString(),
    }
  } else if (name === 'bodyWeight') {
    const [wholeUnitValue, _wholeUnitUnit, partialUnitValue, partialValueUnit] =
      value.toString().split(' ')
    vitals[name] =
      unit === 'lbs' || unit === 'kg'
        ? {
            wholeUnit: {
              unit: unit,
              value: wholeUnitValue,
            },
            partialUnit:
              unit === 'lbs'
                ? {
                    unit: partialValueUnit ? (partialValueUnit as Unit) : 'oz',
                    value: partialUnitValue ?? '0',
                  }
                : undefined,
          }
        : undefined
  } else if (name === 'bloodPressure') {
    const [systolicValue, diastolicValue] = value.toString().split('/')
    vitals[name] = {
      systolic: {
        unit: 'mmHg',
        value: systolicValue.trim(),
      },
      diastolic: {
        unit: 'mmHg',
        value: diastolicValue.trim(),
      },
    }
  } else if (name === 'heartRate') {
    vitals[name] = {
      unit: 'BEATS_PER_MINUTE',
      value: value.toString(),
    }
  } else if (name === 'respiratoryRate') {
    vitals[name] = {
      unit: 'BREATHS_PER_MINUTE',
      value: value.toString(),
    }
  } else if (name === 'pulseOximetry' || name === 'fractionOfInspiredOxygen') {
    vitals[name] = {
      unit: 'PERCENT',
      value: value.toString(),
    }
  }
  return vitals
}

export const getVitalsForDate = (
  dateISO: string,
  vitalsByDateAndCode: {
    name: string
    unit: string
    type?: string
    effectiveAt: Date
    code: string
    dataByDate: {
      [key: string]: string
    }
    idByDate: {
      [key: string]: string
    }
  }[]
): {
  id: string
  name: string
  unit: string
  value: string
  type?: string
  effectiveAt: Date
}[] => {
  if (!dateISO || !vitalsByDateAndCode?.length) return []
  return vitalsByDateAndCode
    .map((vital) => {
      const date = formatDateFieldValue(dateISO)
      if (
        date in vital.dataByDate &&
        vitalsCodes.includes(vital.code as VitalsCode)
      ) {
        return {
          id: vital.idByDate[date],
          name: vitalObservationDisplayToField[vital.name],
          unit: vital.unit,
          value: vital.dataByDate[date],
          effectiveAt: vital.effectiveAt,
        }
      }
    })
    .filter(Boolean)
}

export const SidepanelPatientVitalsEdit: React.FC = () => {
  const [upsertPatientVitalsMutation, { loading: updating }] =
    useUpsertPatientVitalsMutation()

  const { appointmentId } = useParamsRW()
  const { patientId, encounterId, date } = useParams()

  const {
    visit: visitFromAppointment,
    intakeVitalsLastUpdated: intakeVitalsLastUpdatedFromAppointment,
  } = useVisitQuery(appointmentId)

  const { vitalsByDateAndCode } = useVitalsQuery(patientId)

  const vitalsForDate = getVitalsForDate(date, vitalsByDateAndCode)

  const {
    visit: visitFromEncounter,
    intakeVitalsLastUpdated: intakeVitalsLastUpdatedFromEncounter,
  } = useVisitVitalsQuery(encounterId)

  const { closeSidePanel } = useSidepanel()

  const visit = visitFromAppointment ?? visitFromEncounter
  const intakeVitalsLastUpdated =
    intakeVitalsLastUpdatedFromAppointment ??
    intakeVitalsLastUpdatedFromEncounter

  const patientAge = calculateAge(visit?.patient?.birthDate)
  const isHeadCircumferenceRequired =
    patientAge && patientAge.years <= HEAD_CIRCUMFERENCE_REQUIRED_AGE_LIMIT

  const persistedPatientVitals = useMemo(() => {
    if (vitalsForDate.length) {
      return vitalsForDate.reduce(
        (vitals, vital) => accumulateUpsertVitalsInput(vitals, vital),
        {} as UpsertPatientVitalsInput
      )
    }
  }, [vitalsForDate])

  const [deletePatientVitals, { loading: deletingPatientVitals }] =
    useDeletePatientVitals()

  const persistedBodyWeightPartialValue =
    persistedPatientVitals?.['bodyWeight']?.partialUnit?.value
  const isSplitBodyWeight =
    (!isEmpty(persistedBodyWeightPartialValue) &&
      persistedBodyWeightPartialValue !== '0') ||
    (visit?.encounter?.bodyWeight &&
      isSplitUnit(visit?.encounter?.bodyWeight?.value?.display)) ||
    isHeadCircumferenceRequired

  const persistedVisitVitals = buildFormState(
    visit?.encounter,
    isSplitBodyWeight
  )

  const defaultValues = useMemo(
    () => ({
      ...persistedVisitVitals,
      ...persistedPatientVitals,
    }),
    [persistedVisitVitals, persistedPatientVitals]
  )

  const onSubmit = async (
    input: Omit<UpsertPatientVitalsInput, 'patientId'>
  ) => {
    await upsertPatientVitalsMutation({
      variables: {
        encounterId: visit?.encounter.id,
        input: {
          ...input,
          patientId,
          effectiveDateTime: convert(
            LocalDate.parse(date)
              .atStartOfDay()
              .atZone(ZoneId.SYSTEM)
              .withZoneSameInstant(ZoneOffset.UTC)
          ).toDate(),
        },
      },
      refetchQueries: ['FindPatientVitals', 'GetPatient'],
      onCompleted: () => {
        toast.success('Vitals updated')
        closeSidePanel()
      },
    })
  }
  const formMethods = useForm<Omit<UpsertPatientVitalsInput, 'patientId'>>({
    defaultValues,
  })

  return (
    <SidepanelForm
      footerElement={
        <StackView justifyContent="between" direction="row">
          <StackView direction="row">
            {intakeVitalsLastUpdated && (
              <StackView justifyContent="center">
                <Typography textStyle="normal" color="text-base-color-fg-muted">
                  {`Last updated: ${formatDistance(
                    new Date(),
                    new Date(intakeVitalsLastUpdated),
                    { includeSeconds: true }
                  )} ago`}
                </Typography>
              </StackView>
            )}
          </StackView>
          <ButtonFooter
            submitText={'Save'}
            submitButtonStyle={'primary'}
            disabled={updating}
            submitting={updating}
            secondaryButton={{
              text: 'Discard Changes',
              onClick: closeSidePanel,
              position: 'right',
              buttonStyle: 'ghost',
            }}
            submitButtonTestId="sidepanel-confirm-btn"
          />
        </StackView>
      }
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <StackView data-testid="patient-vitals" className="h-full" space={50}>
        <Box className="h-full w-full">
          <FormInputList
            items={[
              {
                name: 'bodyHeight',
                showFieldError: false,
                label: vitalLabels['bodyHeight'],
                direction: 'col',
                formInputComponent: BodyHeightInput,
                subtitleProps: {
                  className: 'text-sm',
                },
                inputProps: {
                  setValueAsString: true,
                },
              },
              {
                name: 'bodyWeight',
                showFieldError: false,
                label: vitalLabels['bodyWeight'],
                direction: 'col',
                formInputComponent: BodyWeightInput,
                inputProps: {
                  allowPartialInput: isSplitBodyWeight ?? false,
                },
              },
              {
                name: 'headCircumference',
                showFieldError: false,
                label: vitalLabels['headCircumference'],
                direction: 'col',
                formInputComponent: HeadCircumferenceInput,
              },
              {
                name: 'bodyTemperature',
                showFieldError: false,
                label: vitalLabels['bodyTemperature'],
                direction: 'col',
                formInputComponent: BodyTemperatureInput,
              },
              {
                name: 'bloodPressure',
                label: vitalLabels['bloodPressure'],
                direction: 'col',
                formInputComponent: BloodPressureInput,
              },
              {
                name: 'heartRate',
                showFieldError: false,
                label: vitalLabels['heartRate'],
                direction: 'col',
                formInputComponent: HeartRateInput,
              },
              {
                name: 'respiratoryRate',
                showFieldError: false,
                label: vitalLabels['respiratoryRate'],
                direction: 'col',
                formInputComponent: RespiratoryRateInput,
              },
              {
                name: 'pulseOximetry',
                showFieldError: false,
                label: vitalLabels['pulseOximetry'],
                direction: 'col',
                formInputComponent: PulseOximetryInput,
              },
              {
                name: 'fractionOfInspiredOxygen',
                showFieldError: false,
                label: vitalLabels['fractionOfInspiredOxygen'],
                direction: 'col',
                formInputComponent: FractionOfInspiredOxygenInput,
              },
            ]}
          />
          <FeatureFlagged flagName="DELETE_PATIENT_VITALS">
            <DestructiveAction
              title="Destructive actions"
              description="These changes are permanent, use caution when removing."
              buttonText="Remove vitals"
              modal={{
                title: `Remove vitals for ${format(
                  parseISO(date),
                  'MMM dd, yyyy'
                )} `,
                content: `Are you sure you want to remove the vitals for this date? This cannot be undone.`,
                confirmText: 'Remove',
              }}
              doDestructiveAction={async () => {
                await deletePatientVitals({
                  variables: {
                    input: {
                      patientId,
                      vitalsIds: vitalsForDate.map(({ id }) => id),
                    },
                  },
                  onCompleted: () => {
                    toast.success('Vitals deleted')
                  },
                })
              }}
              destructiveActionIsLoading={deletingPatientVitals}
              postDestructiveAction={closeSidePanel}
            />
          </FeatureFlagged>
        </Box>
      </StackView>
    </SidepanelForm>
  )
}
