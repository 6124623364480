import { navigate, useLocation, useParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import { WithIndicator } from 'src/components/atoms/WithIndicator/WithIndicator'
import BulletedListIcon from 'src/components/icons/BulletedListIcon/BulletedListIcon'
import { StatusIndicator } from 'src/components/StatusIndicator/StatusIndicator'
import { usePatientActiveTaskCount } from 'src/hooks/usePatientActiveTaskCount/usePatientActiveTaskCount'
import { sidepanelRoute } from 'src/lib/routes'

export const PatientTaskButton = ({ patientId }: { patientId: string }) => {
  const params = useParams()
  const location = useLocation()

  const patientActiveTaskCount = usePatientActiveTaskCount({ patientId })

  return (
    <WithIndicator
      indicator={<StatusIndicator color="purple" />}
      showIndicator={patientActiveTaskCount > 0}
    >
      <Button
        testId="patient-tasks-btn"
        buttonStyle="ghost"
        className="!p-0"
        onClick={() =>
          navigate(
            sidepanelRoute(
              { route: `/patients/${patientId}/tasks` },
              location,
              params
            )
          )
        }
        tooltip="Tasks"
        tooltipPosition="left"
        tooltipPadding={25}
        buttonSize="s"
        icon={BulletedListIcon}
      />
    </WithIndicator>
  )
}
