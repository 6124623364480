import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { formatDisplayName } from 'common/utils'
import { Address, RelationshipType } from 'types/graphql'

import { RegisterOptions, useFormContext } from '@redwoodjs/forms'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import { FieldLabel } from 'src/components/atoms/Label/Label'
import StackView from 'src/components/atoms/StackView/StackView'
import { formatAddress } from 'src/lib/formatters'

type Relationship = {
  relatedPerson: Partial<{
    id: string
    namePrefix: string
    givenName: string
    middleName: string
    familyName: string
    nameSuffix: string
    contactInformation: {
      homeAddress: Partial<Address>
    }
  }>
  relationshipType: RelationshipType
}
export interface PatientResidesWithSelectFieldProps {
  name: string
  patientRelatedPersonRelationships: Relationship[]
  defaultSelections: string[]
  validation?: RegisterOptions
}

const PatientResidesWithSelectField: React.FC<
  PatientResidesWithSelectFieldProps
> = ({ name, patientRelatedPersonRelationships, validation }) => {
  const { register, setValue, watch } = useFormContext()

  register(name, validation)

  const livesAloneName = `${name}.livesAlone`
  const selectionState = watch(`${name}`)

  const handleSelection = (event) => {
    const { id, checked } = event.target

    setValue(id, checked)
    if (id === livesAloneName && checked) {
      for (const selectedId of Object.keys(selectionState)) {
        if (!livesAloneName.includes(selectedId))
          setValue(`${name}.${selectedId}`, false)
      }
    } else {
      setValue(livesAloneName, false)
    }
  }

  return (
    <StackView space={50}>
      <StackView className="flex" direction="row">
        <CheckboxField onChange={handleSelection} name={livesAloneName} />
        <FieldLabel name={livesAloneName} className="pl-3">
          Does not live with caregiver
        </FieldLabel>
      </StackView>

      {patientRelatedPersonRelationships.map((relationship) => {
        return (
          <StackView
            className="flex"
            key={relationship.relatedPerson.id}
            direction="row"
          >
            <CheckboxField
              onChange={handleSelection}
              name={`${name}.${relationship.relatedPerson.id}`}
            />
            <FieldLabel
              name={`${name}.${relationship.relatedPerson.id}`}
              className="pl-3"
            >
              {formatDisplayName(relationship.relatedPerson)},{' '}
              {relationshipTypeDisplay[relationship.relationshipType]} (
              {formatAddress(
                relationship.relatedPerson.contactInformation.homeAddress
              )}
              )
            </FieldLabel>
          </StackView>
        )
      })}
    </StackView>
  )
}

export default PatientResidesWithSelectField
