import CVXDetails from 'common/cdr/concepts/immunizations/CVXDetails'
import { orderTypeToConcept } from 'common/cdr/concepts/serviceRequests/index'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { getTargetDateDisplay } from 'common/targetDate'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import { compact } from 'lodash'
import {
  OrderCategory,
  OrderWithObservationsFragment,
  VisitTemplateOrderFragment,
  SchedulingCaseResolutionStatus,
  TargetDate,
  ChiefComplaint,
  VisitTemplateSchedulingOrderPractitionerType,
  VisitTemplateSchedulingOrderLocationType,
  NamePrefix,
  NameSuffix,
} from 'types/graphql'

import Badge from 'src/components/atoms/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import {
  interpretationDisplay,
  interpretationToColor,
} from 'src/data/interpretations'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { formatDateDisplay } from 'src/lib/formatters'

type OrderPatient = {
  birthDate: string
}

type SchedulingCase = {
  appointmentDefinitions: {
    code?: string
    duration?: number
  }[]
  newAppointment?: {
    start: string
  }
  resolvedStatus?: SchedulingCaseResolutionStatus
  targetDate?: TargetDate
  visitPractitioner?: {
    givenName: string
    familyName: string
  }
  location: {
    name: string
  }
  chiefComplaints?: ChiefComplaint[]
}

type VisitTemplateSchedulingOrder = {
  appointmentDefinitions?: {
    duration?: number
    code?: string
  }[]
  targetDate?: TargetDate
  chiefComplaints?: ChiefComplaint[]
  visitPractitionerType: VisitTemplateSchedulingOrderPractitionerType
  locationType: VisitTemplateSchedulingOrderLocationType
  visitPractitioner?: {
    namePrefix?: NamePrefix
    givenName: string
    familyName: string
    nameSuffix?: NameSuffix
  }
  location?: {
    name: string
  }
}

const renderOrderResultInterpretation = ({
  resultInterpretation,
  questionnaireResults,
}: {
  resultInterpretation?: OrderWithObservationsFragment['resultInterpretation']
  questionnaireResults?: OrderWithObservationsFragment['questionnaireResults']
}) => {
  const interpretation =
    resultInterpretation ??
    questionnaireResults?.overall?.[0]?.interpretation ??
    undefined

  if (!interpretation) return null

  return (
    <Badge color={interpretationToColor[interpretation]}>
      {interpretationDisplay[interpretation]}
    </Badge>
  )
}

export const getOrderTitle = ({
  category,
  codeDisplay,
  schedulingCase,
  visitTemplateSchedulingOrders,
  createdAt,
  code,
  freeTextCode,
  patient,
}: {
  category: OrderCategory
  codeDisplay?: string
  schedulingCase?: SchedulingCase
  visitTemplateSchedulingOrders?: VisitTemplateSchedulingOrder[]
  createdAt?: string
  code?: string
  freeTextCode?: string
  patient?: OrderPatient
}) => {
  let title: string
  switch (category) {
    case 'SCH':
      title = 'Scheduling'
      if (schedulingCase) {
        const apptCodesString = schedulingCase.appointmentDefinitions
          .map((ad) => {
            return `${ad.code}${ad.duration}`
          })
          .join(', ')
        const apptStart = schedulingCase.newAppointment?.start

        if (schedulingCase?.resolvedStatus === 'RESOLVED' && apptStart) {
          title = `${apptCodesString} on ${formatDateDisplay(
            apptStart
          )} at ${format(parseISO(apptStart), 'h:mmaaa')}`
        } else {
          title = `Schedule ${apptCodesString} ${getTargetDateDisplay({
            baseDate: parseISO(createdAt),
            targetDate: schedulingCase.targetDate,
            patientBirthDate: parseISO(patient.birthDate),
          })}`
        }
      } else if (visitTemplateSchedulingOrders) {
        const { appointmentDefinitions, targetDate } =
          visitTemplateSchedulingOrders[0]
        const apptCodesString = appointmentDefinitions
          .map((ad) => {
            return `${ad.code}${ad.duration}`
          })
          .join(', ')

        title = `Schedule ${apptCodesString} ${getTargetDateDisplay({
          baseDate: new Date(),
          targetDate: targetDate,
          relative: true,
        })}`
      }
      break
    case 'REF':
      if (code === 'OTHER' && freeTextCode) {
        title = `Other: ${freeTextCode}`
      } else if (code === 'OTHER') {
        title = `Other`
      } else {
        title = orderTypeToConcept[code]?.display
      }
      break
    case 'VAX':
      title = CVXDetails[code]?.develoVaccineName
      break
    case 'HG_LAB':
      title = codeDisplay
      break
    default:
      title = orderTypeToConcept[code]?.display
      break
  }

  return title
}

const TitleRow = ({
  category,
  schedulingCase,
  visitTemplateSchedulingOrders,
  code,
  codeDisplay,
  freeTextCode,
  createdAt,
  intent,
  showPendedBadge = false,
  patient,
}: {
  category: OrderCategory
  schedulingCase?: SchedulingCase
  visitTemplateSchedulingOrders?: VisitTemplateSchedulingOrder[]
  code?: string
  codeDisplay?: string
  freeTextCode?: string
  createdAt?: string
  intent?: string
  showPendedBadge?: boolean
  patient?: OrderPatient
}) => {
  return (
    <StackView direction="row" space={50} alignItems="center">
      <Typography textStyle="title" className="truncate">
        {getOrderTitle({
          category,
          codeDisplay,
          schedulingCase,
          visitTemplateSchedulingOrders,
          createdAt,
          code,
          freeTextCode,
          patient,
        })}
      </Typography>
      {showPendedBadge && schedulingCase && intent && intent !== 'ORDER' && (
        <Badge color="yellow" className="capitalize">
          Pended
        </Badge>
      )}
    </StackView>
  )
}

const SchedulingOrderBottomRowAdditionalDetails = ({
  schedulingCase,
  visitTemplateSchedulingOrders,
}: {
  schedulingCase?: SchedulingCase
  visitTemplateSchedulingOrders?: VisitTemplateSchedulingOrder[]
}) => {
  let visitPractitionerDisplay: string,
    locationDisplay: string,
    chiefComplaintsDisplay: string

  if (schedulingCase) {
    visitPractitionerDisplay = formatDisplayName(
      schedulingCase.visitPractitioner
    )
    locationDisplay = schedulingCase.location.name
    chiefComplaintsDisplay = schedulingCase.chiefComplaints
      .map((complaint) => chiefComplaintDisplay[complaint])
      .join(', ')
  } else if (visitTemplateSchedulingOrders) {
    const {
      chiefComplaints,
      visitPractitionerType,
      locationType,
      visitPractitioner,
      location,
    } = visitTemplateSchedulingOrders[0]
    visitPractitionerDisplay =
      visitPractitionerType === 'CURRENT'
        ? 'Current practitioner'
        : visitPractitionerType === 'PRIMARY'
          ? 'Primary practitioner'
          : formatDisplayName(visitPractitioner)
    locationDisplay =
      locationType === 'CURRENT' ? 'Current location' : location?.name
    chiefComplaintsDisplay = chiefComplaints
      .map((complaint) => chiefComplaintDisplay[complaint])
      .join(', ')
  }
  return (
    <>
      {(schedulingCase || visitTemplateSchedulingOrders) && (
        <Typography textStyle="description">
          {/* Bullet at beginning of string to separate from order name only for schedulingCase */}
          {schedulingCase && '\u2022 '}
          {compact([
            visitPractitionerDisplay,
            locationDisplay,
            chiefComplaintsDisplay,
          ]).join(' \u2022 ')}
        </Typography>
      )}
    </>
  )
}

const BottomRow = ({
  category,
  name,
  type,
  cptCode,
  cptCodeDescription,
  ...order
}: {
  category: OrderCategory
  name?: string
  type: 'VisitTemplateOrder' | 'Order'
  cptCode?: string
  cptCodeDescription?: string
}) => {
  const categoryConfig = orderCategoryConfig[category]

  return (
    <StackView direction="row" space={25} alignItems="center">
      {name && <Typography textStyle="description">{name}</Typography>}

      {type === 'VisitTemplateOrder' && (
        <Typography textStyle="description">
          {[cptCode, cptCodeDescription].filter(Boolean).join(' \u2022 ')}
        </Typography>
      )}

      {category === 'SCH' && (
        <SchedulingOrderBottomRowAdditionalDetails {...order} />
      )}
      <Badge
        color={categoryConfig.type === 'internal' ? 'dark-gray' : 'blue'}
        className={type === 'Order' ? '!ml-2' : ''}
      >
        {categoryConfig.display}
      </Badge>
      {renderOrderResultInterpretation({ ...order })}
    </StackView>
  )
}

const OrdersTableOrderDetails = ({
  order,
  showPendedBadge = false,
}: {
  order: OrderWithObservationsFragment | VisitTemplateOrderFragment
  showPendedBadge?: boolean
}) => {
  const category =
    order.__typename === 'VisitTemplateOrder'
      ? order.orderCategory
      : order.category

  return (
    <StackView space={25} className="truncate">
      <TitleRow
        category={category}
        showPendedBadge={showPendedBadge}
        {...order}
      />
      <BottomRow category={category} type={order.__typename} {...order} />
    </StackView>
  )
}

export default OrdersTableOrderDetails
