import { calculateAge } from '@medplum/core'
import { EMPTY_OBSERVATION_VALUE_PLACEHOLDER } from 'common/cdr/concepts/observations/index'
import { appointmentTypeDisplay } from 'common/data/appointmentTypes'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import { isEmptyLexical } from 'common/lexical/lexical'
import { unitDisplay } from 'common/unitConverter/unitConverter'
import { formatDisplayName } from 'common/utils'
import { format, parseISO } from 'date-fns'
import capitalize from 'lodash/capitalize'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import { FindPatientVisit } from 'types/graphql'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { allergySeverityDisplay } from 'src/data/allergySeverities'
import { orderCategoryConfig } from 'src/data/orderCategories'
import { OrganSystem, organSystemDisplay } from 'src/data/organSystems'
import { organSystems } from 'src/data/organSystems'
import { sexDisplay } from 'src/data/sexes'
import { toDecimal } from 'src/lib/formatters'
import { getOrderTitle } from 'src/pages/PatientChartsPage/PatientOrders/OrdersTableOrderDetails'
import { HtmlStrings } from 'src/pages/PatientChartsPage/PatientVisits/useSignVisit'

import { LabeledValue, Signature, SubHeading, SubSection } from '../common'

export const VisitDetails = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  return (
    <Box
      color="bg-base-color-bg-subtle"
      padding={50}
      data-testid="visit-details"
    >
      <StackView
        direction="row"
        justifyContent="between"
        space={50}
        className="pb-2"
      >
        <StackView>
          <SubHeading>Patient name</SubHeading>
          <Typography size="xs">{formatDisplayName(visit.patient)}</Typography>
          <Typography size="xs">
            {sexDisplay[visit.patient.sexAtBirth]} &bull;{' '}
            {format(parseISO(visit.patient.birthDate), 'M/d/yyyy')}
          </Typography>
        </StackView>

        <StackView>
          <SubHeading>{'Caregiver(s)'}</SubHeading>
          {visit.caregiversPresent.map((caregiver) => {
            if (caregiver.patientRelatedPersonRelationship?.relatedPerson) {
              return (
                <Typography key={caregiver.id} size="xs">{`${formatDisplayName(
                  caregiver.patientRelatedPersonRelationship.relatedPerson
                )} (${
                  relationshipTypeDisplay[
                    caregiver.patientRelatedPersonRelationship.relationshipType
                  ]
                })`}</Typography>
              )
            }
            return (
              <Typography key={caregiver.id} size="xs">
                {relationshipTypeDisplay[caregiver.relationshipType]}
              </Typography>
            )
          })}
        </StackView>

        <StackView>
          <SubHeading>Practice staff involved</SubHeading>
          <Typography size="xs">
            {formatDisplayName(visit.practitioner)} (Visit pracitioner)
          </Typography>

          {visit.usersInvolved.map((user) => (
            <Typography key={user.id} size="xs">
              {formatDisplayName(user)}
            </Typography>
          ))}
        </StackView>
      </StackView>
      <StackView
        direction="row"
        justifyContent="between"
        space={50}
        className="pb-2"
      >
        <StackView>
          <SubHeading>Visit date & time</SubHeading>
          <Typography size="xs">
            {format(parseISO(visit.start), 'MMM d, yyyy')} at{' '}
            {format(parseISO(visit.start), 'h:mma')}
          </Typography>
        </StackView>
        <StackView>
          <SubHeading>Visit location</SubHeading>
          <Typography size="xs">{visit.location.name}</Typography>
        </StackView>
        <StackView>
          <SubHeading>Visit type(s)</SubHeading>
          <Typography size="xs">
            {visit.appointmentDefinitions
              .map((a) => appointmentTypeDisplay[a.type])
              .join(', ')}
          </Typography>
        </StackView>
      </StackView>
    </Box>
  )
}

export const ChiefComplaintSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  if (visit.chiefComplaints.length === 0) return null
  return (
    <SubSection header="Chief complaint" testId="chief-complaint">
      <StackView className="pb-2">
        <Typography>
          {visit.chiefComplaints
            .map((complaint) => chiefComplaintDisplay[complaint])
            .join(', ')}
        </Typography>
      </StackView>
    </SubSection>
  )
}

export const HistorySubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  if (
    isEmptyLexical(visit.historyPresentIllness) &&
    isEmptyLexical(visit.developmentalSurveillance)
  )
    return null

  return (
    <SubSection testId="history">
      {visit.appointmentDefinitions
        .map(({ type }) => type)
        .includes('WELL_CHILD') ? (
        <StackView direction="row" space={50} className="pb-2">
          <StackView>
            <SubHeading>Interim History</SubHeading>
            <div
              dangerouslySetInnerHTML={{
                __html: htmlStrings.historyPresentIllness,
              }}
            ></div>
          </StackView>

          <StackView>
            <SubHeading>Developmental Surveillance</SubHeading>
            <div
              dangerouslySetInnerHTML={{
                __html: htmlStrings.developmentalSurveillance,
              }}
            ></div>
          </StackView>
        </StackView>
      ) : (
        <StackView className="pb-2">
          <SubHeading>History of present illness (HPI)</SubHeading>
          <div
            dangerouslySetInnerHTML={{
              __html: htmlStrings.historyPresentIllness,
            }}
          ></div>
        </StackView>
      )}
    </SubSection>
  )
}

export const VisitSummarySubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  if (isEmptyLexical(visit.visitSummary)) return null

  return (
    <SubSection testId="visit-summary">
      <StackView className="pb-2">
        <SubHeading>Visit summary</SubHeading>
        <div
          dangerouslySetInnerHTML={{
            __html: htmlStrings.visitSummary,
          }}
        ></div>
      </StackView>
    </SubSection>
  )
}

export const PastMedicalHistorySubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  if (isEmptyLexical(visit.patient.pastMedicalHistory)) return null

  return (
    <SubSection testId="past-medical-history">
      <StackView className="pb-2">
        <SubHeading>Past medical & surgical history</SubHeading>
        <div
          dangerouslySetInnerHTML={{
            __html: htmlStrings.pastMedicalHistory,
          }}
        ></div>
      </StackView>
    </SubSection>
  )
}

export const ReviewOfSystemsSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const ros = visit.reviewOfSystems

  if (!ros) return null

  const keys = Object.keys(ros)
  const sortedSystems = sortBy(keys, (system) =>
    organSystems.indexOf(system as OrganSystem)
  )
  const findings = sortedSystems.map((system) => {
    return {
      ...ros[system],
      system,
    }
  })

  if (findings.length === 0) return null

  return (
    <SubSection header="Review of systems" testId="review-of-systems">
      <StackView direction="row" wrap className="pb-2">
        {findings.map((finding) => (
          <StackView className="w-1/2 pb-6 pr-4" key={finding.system}>
            <Typography size="m">
              {organSystemDisplay[finding.system]}
            </Typography>
            <LabeledValue
              label="Reports"
              value={
                finding.reports.length > 0 ? finding.reports.join(', ') : '-'
              }
            />
            <LabeledValue
              label="Denies"
              value={
                finding.denies.length > 0 ? finding.denies.join(', ') : '-'
              }
            />
          </StackView>
        ))}
      </StackView>
    </SubSection>
  )
}

export const AllergiesSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const allergies = visit.patient.allergies.filter(
    (allergy) => allergy.status === 'active'
  )
  return (
    <SubSection header="Allergies" testId="allergies">
      <StackView direction="row" wrap className="pb-2">
        {visit.patient.hasNoKnownAllergies ? (
          <Typography>No known allergies</Typography>
        ) : allergies.length === 0 ? (
          <Typography>No allergies recorded</Typography>
        ) : (
          <>
            {allergies.map((allergy) => (
              <StackView
                className="w-1/2 pb-6 pr-4"
                key={allergy.allergen.code}
              >
                <Typography size="m">{allergy.allergen.name}</Typography>
                {allergy.manifestation && (
                  <LabeledValue
                    label="Manifests"
                    value={allergy.manifestation.display}
                  />
                )}

                {allergy.severity && (
                  <LabeledValue
                    label="Severity"
                    value={allergySeverityDisplay[allergy?.severity]}
                  />
                )}
              </StackView>
            ))}
          </>
        )}
      </StackView>
    </SubSection>
  )
}

export const ActiveMedicationsSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const patientMedications = visit.patient.medications ?? []

  if (patientMedications.length === 0) return null

  return (
    <SubSection header="Active Medications" testId="medications">
      <StackView direction="row" wrap className="py-2">
        {patientMedications
          .map((medication) => ({
            ...medication,
            isNew: medication.encounterId === visit.encounter.id,
          }))
          .sort((a, b) =>
            a.isNew === b.isNew
              ? a.drug.description.localeCompare(b.drug.description)
              : a.isNew
                ? -1
                : 1
          )
          .map((medication) => (
            <StackView className="w-1/2 pb-6 pr-4" key={medication.id}>
              <Typography size="m" className="pb-2">
                {medication.drug.description}
                {medication.encounterId === visit.encounter.id && ' *New*'}
              </Typography>
              {medication.drug.form ? (
                <LabeledValue label="Type" value={medication.drug.form} />
              ) : null}
              {medication.drug.strength ? (
                <LabeledValue
                  label="Strength"
                  value={medication.drug.strength}
                />
              ) : null}
              {(medication.dosage?.text ||
                medication.dosage?.patientInstruction) && (
                <LabeledValue
                  label="Directions"
                  value={[
                    medication.dosage?.text,
                    medication.dosage?.patientInstruction,
                  ]
                    .filter(Boolean)
                    .join('\n')}
                />
              )}
            </StackView>
          ))}
      </StackView>
    </SubSection>
  )
}

export const ImmunizationsSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const completedImmunizations = visit.encounter?.orders.filter(
    (order) => order.category === 'VAX' && order.status === 'COMPLETED'
  )
  return (
    <SubSection header="Today's immunizations" testId="immunizations">
      <StackView className="pb-2">
        {completedImmunizations?.length === 0 ? (
          <Typography>No immunizations administered today</Typography>
        ) : (
          <>
            {completedImmunizations?.map((immunization) => (
              <Typography key={immunization.id}>
                {immunization.codeDisplay}
              </Typography>
            ))}
          </>
        )}
      </StackView>
    </SubSection>
  )
}

export const DiagnosesSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const activeConditions = visit.patient.conditions.filter(
    (condition) =>
      condition.clinicalStatus === 'active' &&
      !condition.code.code?.startsWith('Z')
  )
  return (
    <SubSection header="Active patient diagnoses" testId="diagnoses">
      <StackView direction="row" wrap className="pb-2">
        {activeConditions.length === 0 ? (
          <Typography>No active diagnoses</Typography>
        ) : (
          <>
            {uniqBy(activeConditions, (c) => c.code.code).map((condition) => (
              <StackView className="w-1/2 pb-6 pr-4" key={condition.id}>
                <Typography size="m">{condition.code.display}</Typography>
                <Typography color="text-base-color-fg-muted">
                  {condition.code.code}
                </Typography>
              </StackView>
            ))}
          </>
        )}
      </StackView>
    </SubSection>
  )
}

export const SocialHistorySubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const travelHistory = visit.patient.socialHistory.travelHistory
  const smokingTobaccoUse = visit.patient.socialHistory.smokingTobaccoUse
  const vapingUse = visit.patient.socialHistory.vapingUse
  const smokelessTobaccoUse = visit.patient.socialHistory.smokelessTobaccoUse
  const alcoholUse = visit.patient.socialHistory.alcoholUse

  const drugUse = visit.patient.socialHistory.drugUse
  let drugKeys = []
  if (drugUse) {
    drugKeys = Object.keys(drugUse)
  }

  const usedDrugs = drugKeys
    .map((drug) => {
      if (drugUse[drug]?.value?.display === 'Yes') {
        return {
          ...drugUse[drug],
          drug,
        }
      }
      return false
    })
    .filter((drug) => !!drug)
    .map((drug) => drug.code.display)
    .join(' \u2022 ')

  if (
    !travelHistory &&
    !smokingTobaccoUse &&
    !vapingUse &&
    !smokelessTobaccoUse &&
    !alcoholUse &&
    usedDrugs.length === 0
  )
    return null

  return (
    <SubSection header="Social history" testId="social-history">
      <StackView direction="row" wrap className="pb-2">
        {travelHistory && (
          <StackView className="w-1/2 pb-6 pr-4">
            <Typography size="m">Travel history</Typography>
            <Typography color="text-base-color-fg-muted">
              {travelHistory.components
                .map((c) => c.value.display)
                .join(' \u2022 ')}
            </Typography>
          </StackView>
        )}

        {smokingTobaccoUse && (
          <StackView className="w-1/2 pb-6 pr-4">
            <Typography size="m">Smoking status</Typography>
            <Typography color="text-base-color-fg-muted">
              {smokingTobaccoUse.value.display}
            </Typography>
          </StackView>
        )}

        {vapingUse && (
          <StackView className="w-1/2 pb-6 pr-4">
            <Typography size="m">E-Cigarette / Vaping status</Typography>
            <Typography color="text-base-color-fg-muted">
              {vapingUse.value.display}
            </Typography>
          </StackView>
        )}

        {smokelessTobaccoUse && (
          <StackView className="w-1/2 pb-6 pr-4">
            <Typography size="m">Smokeless tobacco use</Typography>
            <Typography color="text-base-color-fg-muted">
              {smokelessTobaccoUse.value.display}
            </Typography>
          </StackView>
        )}

        {alcoholUse && (
          <StackView className="w-1/2 pb-6 pr-4">
            <Typography size="m">Alchohol use</Typography>
            <Typography color="text-base-color-fg-muted">
              {alcoholUse.value.display}
            </Typography>
          </StackView>
        )}

        {usedDrugs.length > 0 && (
          <StackView className="w-1/2 pb-6 pr-4">
            <Typography size="m">
              Recreational and/or illicit drug use
            </Typography>
            <Typography color="text-base-color-fg-muted">
              {usedDrugs}
            </Typography>
          </StackView>
        )}
      </StackView>
    </SubSection>
  )
}

export const VitalsSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const bodyHeight = visit.encounter?.bodyHeight
  const bodyWeight = visit.encounter?.bodyWeight
  const headCircumference = visit.encounter?.headCircumference
  const bodyTemperature = visit.encounter?.bodyTemperature
  const bloodPressure = visit.encounter?.bloodPressure
  const heartRate = visit.encounter?.heartRate
  const respiratoryRate = visit.encounter?.respiratoryRate
  const bmi = visit.encounter?.bmi
  const bodyTemperatureMethod = bodyTemperature?.coding.find(
    (coding) => coding.display !== 'Body Temperature'
  )?.display
  const pulseOximetry = visit.encounter?.pulseOximetry

  const noVitalsRecorded =
    !bodyHeight &&
    !bodyWeight &&
    !headCircumference &&
    !bodyTemperature &&
    !bloodPressure &&
    !heartRate &&
    !respiratoryRate &&
    !bmi &&
    !pulseOximetry

  return (
    <SubSection header="Vitals" testId="vitals">
      <StackView direction="row" wrap className="pb-2">
        {noVitalsRecorded ? (
          <Typography>No vitals recorded</Typography>
        ) : (
          <>
            {bodyWeight && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">Weight</Typography>
                <Typography color="text-base-color-fg-muted">
                  {bodyWeight.value.display} &bull;{' '}
                  {toDecimal(bodyWeight.percentile)}%
                </Typography>
              </StackView>
            )}

            {bmi && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">BMI</Typography>
                <Typography color="text-base-color-fg-muted">
                  {toDecimal(bmi.value.value)} &bull;{' '}
                  {toDecimal(bmi.percentile)}%
                </Typography>
              </StackView>
            )}

            {bodyHeight && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">Height</Typography>
                <Typography color="text-base-color-fg-muted">
                  {bodyHeight.value.display} &bull;{' '}
                  {toDecimal(bodyHeight.percentile)}%
                </Typography>
              </StackView>
            )}

            {bloodPressure && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">Blood pressure</Typography>
                <Typography color="text-base-color-fg-muted">
                  {bloodPressure.value.display}
                </Typography>
              </StackView>
            )}

            {heartRate && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">Pulse</Typography>
                <Typography color="text-base-color-fg-muted">
                  {heartRate.value.value} {unitDisplay[heartRate.value.unit]}
                </Typography>
              </StackView>
            )}

            {headCircumference && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">Head circumference</Typography>
                <Typography color="text-base-color-fg-muted">
                  {headCircumference.value.display} &bull;{' '}
                  {toDecimal(headCircumference.percentile)}%
                </Typography>
              </StackView>
            )}

            {respiratoryRate && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">Resp rate</Typography>
                <Typography color="text-base-color-fg-muted">
                  {respiratoryRate.value.value}{' '}
                  {unitDisplay[respiratoryRate.value.unit]}
                </Typography>
              </StackView>
            )}
            {bodyTemperature && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">Temperature</Typography>
                <Typography color="text-base-color-fg-muted">
                  {bodyTemperature.value.value}{' '}
                  {unitDisplay[bodyTemperature.value.unit]} &bull;{' '}
                  {bodyTemperatureMethod}
                </Typography>
              </StackView>
            )}
            {pulseOximetry && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">Pulse oximetry</Typography>
                <Typography color="text-base-color-fg-muted">
                  {pulseOximetry.value.value}{' '}
                  {unitDisplay[pulseOximetry.value.unit]}
                </Typography>
              </StackView>
            )}
          </>
        )}
      </StackView>
    </SubSection>
  )
}

export const PhysicalExamSubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  const pe = visit.physicalExam

  if (!pe) return null

  const keys = Object.keys(pe)
  const sortedSystems = sortBy(keys, (system) =>
    organSystems.indexOf(system as OrganSystem)
  )
  const peArray = sortedSystems.map((system) => {
    return {
      value: pe[system],
      system,
    }
  })

  return (
    <SubSection header="Physical exam" testId="physical-exam">
      <StackView className="pb-2">
        {peArray.map((system) => (
          <StackView key={system.system} className="pb-6">
            <Typography size="m">
              {organSystemDisplay[system.system]}
            </Typography>
            <div
              className="mb-1"
              dangerouslySetInnerHTML={{
                __html: htmlStrings.physicalExam[system.system],
              }}
            ></div>
          </StackView>
        ))}
      </StackView>
    </SubSection>
  )
}

export const InHouseTestsSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const orders = visit.encounter?.orders.filter(
    (order) =>
      (order.category === 'IHT' || order.category === 'SCN') &&
      order.status === 'COMPLETED'
  )

  if (orders?.length === 0) return null

  return (
    <SubSection header="In-house tests and screenings" testId="in-house-tests">
      <StackView direction="row" wrap className="pb-2">
        {orders?.map((order) => {
          const observations =
            order.observations?.filter(
              (observation) =>
                observation.interpretation?.display ||
                (observation.value?.value &&
                  observation.value.value !==
                    EMPTY_OBSERVATION_VALUE_PLACEHOLDER)
            ) ?? []
          // The same note is saved on every observation so just pick the first one
          const observationNote = observations[0]?.notes

          return (
            <StackView className="w-1/2 pb-6 pr-4" key={order.id}>
              <Typography size="m">{order.codeDisplay}</Typography>
              {observations.map((observation) => {
                return (
                  <Typography
                    key={observation.id}
                    color="text-base-color-fg-muted"
                  >
                    {[
                      capitalize(observation.interpretation?.display ?? ''),
                      observations.length > 1 ? observation.name : null,
                      observation.value &&
                      observation.value.value !==
                        EMPTY_OBSERVATION_VALUE_PLACEHOLDER
                        ? observation.value.display
                        : '',
                    ]
                      .filter(Boolean)
                      .join(' \u2022 ')}
                  </Typography>
                )
              })}
              {observationNote ? (
                <Typography color="text-base-color-fg-muted">
                  {observationNote}
                </Typography>
              ) : null}
            </StackView>
          )
        })}
      </StackView>
    </SubSection>
  )
}

export const AssessmentNotesSubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  if (isEmptyLexical(visit?.assessment)) return null
  return (
    <SubSection header="Notes" testId="assessment-notes">
      <StackView className="pb-2">
        <div
          dangerouslySetInnerHTML={{
            __html: htmlStrings.assessment,
          }}
        ></div>
      </StackView>
    </SubSection>
  )
}

export const AssessmentDiagnosesSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const primaryDiagnosis = visit.encounter?.primaryDiagnosis
  const additionalDiagnoses = visit.encounter?.additionalDiagnoses
  return (
    <SubSection header="Visit diagnoses" testId="assessment-diagnoses">
      <StackView direction="row" wrap className="pb-2">
        {!primaryDiagnosis && additionalDiagnoses?.length === 0 ? (
          <Typography>No visit diagnoses reported today</Typography>
        ) : (
          <>
            {primaryDiagnosis && (
              <StackView className="w-1/2 pb-6 pr-4">
                <Typography size="m">
                  {primaryDiagnosis.code.display}
                </Typography>
                <Typography color="text-base-color-fg-muted">
                  {primaryDiagnosis.code.code} &bull; Primary
                </Typography>
              </StackView>
            )}
            {additionalDiagnoses?.map((diagnosis) => (
              <StackView className="w-1/2 pb-6 pr-4" key={diagnosis.id}>
                <Typography size="m">{diagnosis.code.display}</Typography>
                <Typography color="text-base-color-fg-muted">
                  {diagnosis.code.code}
                </Typography>
              </StackView>
            ))}
          </>
        )}
      </StackView>
    </SubSection>
  )
}

export const PlanNotesSubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  if (isEmptyLexical(visit?.planOfCare)) return null
  return (
    <SubSection header="Notes" testId="plan-notes">
      <StackView className="pb-2">
        <div
          dangerouslySetInnerHTML={{
            __html: htmlStrings.planOfCare,
          }}
        ></div>
      </StackView>
    </SubSection>
  )
}

export const PlanOrdersSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const orders = visit.encounter?.orders.filter(
    (order) => order.status !== 'REVOKED'
  )
  return (
    <SubSection header="Orders" testId="orders">
      <StackView direction="row" wrap className="pb-2">
        {orders?.length === 0 ? (
          <Typography>No planned orders</Typography>
        ) : (
          <>
            {orders?.map((order) => (
              <StackView className="w-1/2 pb-6 pr-4" key={order.id}>
                <StackView direction="row" space={50}>
                  {order.status === 'COMPLETED' ? (
                    <img
                      className="mt-4 h-3 w-auto"
                      src="/img/check-circle-icon-green.png"
                      alt="Done"
                    />
                  ) : (
                    <img
                      className="mt-4 h-3 w-auto"
                      src="/img/check-circle-icon-gray.png"
                      alt="Not done"
                    />
                  )}

                  <Typography size="m">{getOrderTitle(order)}</Typography>
                </StackView>

                <StackView className="ml-5">
                  <Typography color="text-base-color-fg-muted">
                    {order.name} &bull;{' '}
                    {orderCategoryConfig[order.category].display}
                  </Typography>
                  {order.category === 'SCH' ? (
                    <>
                      <Typography color="text-base-color-fg-muted">
                        {formatDisplayName(
                          order.schedulingCase?.visitPractitioner
                        )}{' '}
                        &bull; {order.schedulingCase?.location?.name}
                      </Typography>
                      <Typography color="text-base-color-fg-muted">
                        {order.schedulingCase?.appointmentDefinitions
                          .map((ad) => `${ad.code}${ad.duration}`)
                          .join(', ')}{' '}
                        {order.schedulingCase?.chiefComplaints.length > 0 && (
                          <>
                            &bull;{' '}
                            {order.schedulingCase?.chiefComplaints
                              .map(
                                (complaint) => chiefComplaintDisplay[complaint]
                              )
                              .join(', ')}
                          </>
                        )}
                      </Typography>
                    </>
                  ) : null}
                </StackView>
              </StackView>
            ))}
          </>
        )}
      </StackView>
    </SubSection>
  )
}

export const PatientEducationNotesSubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  if (isEmptyLexical(visit.patientEducationAdditionalNotes)) return null
  return (
    <SubSection header="General notes" testId="patient-education-notes">
      <StackView className="pb-2">
        <div
          dangerouslySetInnerHTML={{
            __html: htmlStrings.patientEducationAdditionalNotes,
          }}
        ></div>
      </StackView>
    </SubSection>
  )
}

export const PatientEducationAdolescentConfidentialSubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  if (
    isEmptyLexical(visit.patientEducationAdolescentConfidential) ||
    calculateAge(visit.patient.birthDate).years < 10
  )
    return null
  return (
    <SubSection
      header="Adolescent confidential"
      testId="patient-education-adolescent-confidential"
    >
      <StackView className="pb-2">
        <div
          dangerouslySetInnerHTML={{
            __html: htmlStrings.patientEducationAdolescentConfidential,
          }}
        ></div>
      </StackView>
    </SubSection>
  )
}

export const PatientEducationDocumentsSubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  if (visit.patientEducationDocuments.length === 0) return null
  return (
    <SubSection header="Handouts" testId="patient-education-documents">
      <StackView direction="row" wrap className="pb-2">
        {visit.patientEducationDocuments.map((document) => (
          <StackView className="w-1/2 pb-6 pr-4" key={document.id}>
            <Typography size="m">{document.title}</Typography>
          </StackView>
        ))}
      </StackView>
    </SubSection>
  )
}

export const FamilyHistorySubSection = ({
  visit,
}: {
  visit: FindPatientVisit['appointment']
}) => {
  const familyMemberHistories = visit.patient.familyMemberHistories
  if (familyMemberHistories.length === 0) return null
  return (
    <SubSection header="Family history" testId="family-history">
      <StackView direction="row" wrap className="pb-2">
        {familyMemberHistories.map((history) => (
          <StackView className="w-1/2 pb-6 pr-4" key={history.id}>
            <Typography size="m">
              {history.conditions[0].icd10Description}
            </Typography>
            <Typography color="text-base-color-fg-muted">
              {history.conditions[0].icd10Code}
            </Typography>
          </StackView>
        ))}
      </StackView>
    </SubSection>
  )
}

export const VisitFooter = ({
  visit,
  currentPractitioner,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  currentPractitioner: FindPatientVisit['practitioner']
  htmlStrings: HtmlStrings
}) => {
  return (
    <>
      {visit.appointmentSignatures.map((signature, index) => (
        <Signature
          key={signature.id}
          signedAt={signature.updatedAt}
          practitioner={signature.signedBy}
          attestationHTML={
            htmlStrings.appointmentSignatures[signature.id].attestationHTML
          }
          hideHeading={index !== 0}
          additionalHeadingText={
            index === 0
              ? undefined
              : `Addended by ${formatDisplayName(signature.signedBy)}`
          }
        />
      ))}
      {/* Our current signature */}
      <Signature
        signedAt={new Date().toISOString()}
        practitioner={currentPractitioner}
        attestationHTML={htmlStrings.attestation}
        hideHeading
        additionalHeadingText={
          visit.appointmentSignatures.length === 0
            ? undefined
            : `Addended by ${formatDisplayName(currentPractitioner)}`
        }
      />
    </>
  )
}

export const DocumentationAdolescentConfidentialSubSection = ({
  visit,
  htmlStrings,
}: {
  visit: FindPatientVisit['appointment']
  htmlStrings: HtmlStrings
}) => {
  if (isEmptyLexical(visit.documentationAdolescentConfidential)) return null
  return (
    <SubSection header="Notes" testId="documentation-adolescent-confidential">
      <StackView className="pb-2">
        <div
          dangerouslySetInnerHTML={{
            __html: htmlStrings.documentationAdolescentConfidential,
          }}
        ></div>
      </StackView>
    </SubSection>
  )
}
