import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { LocalDate } from '@js-joda/core'
import { dateTimeFormatter } from 'common/data/date'
import { formatDisplayName } from 'common/utils'

import { navigate, routes, useParams as useRWParams } from '@redwoodjs/router'

import Button from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

import { HospitalVisitSubmitBillingButton } from './HospitalVisitSubmitBillingButton'
import { useHospitalVisit } from './useHospitalVisit'

const formatVisitDates = ({
  admissionDate,
  dischargeDate,
}: {
  admissionDate: LocalDate | null
  dischargeDate: LocalDate | null
}): string | null => {
  const formatter = dateTimeFormatter('MMM d, yyyy')
  if (admissionDate && dischargeDate && admissionDate.equals(dischargeDate)) {
    return admissionDate.format(formatter)
  }

  if (admissionDate && dischargeDate) {
    return [
      admissionDate.format(formatter),
      '\u2192',
      dischargeDate.format(formatter),
    ].join('')
  }

  if (admissionDate) return admissionDate.format(formatter)

  if (dischargeDate) return dischargeDate.format(formatter)

  return null
}

export const HospitalVisitHeader = () => {
  const { id: patientId } = useRWParams()
  const { data } = useHospitalVisit()

  const visit = data?.hospitalVisit
  const claim = visit?.claim

  const admissionDate = claim?.admissionDate
    ? LocalDate.parse(claim.admissionDate)
    : null
  const dischargeDate = claim?.dischargeDate
    ? LocalDate.parse(claim.dischargeDate)
    : null

  return (
    <StackView
      direction="row"
      alignItems="center"
      className="border-b-core-border-width-10 px-core-space-100 py-core-space-50 "
      justifyContent="between"
      gap={50}
    >
      <StackView direction="row" gap={50} alignItems="center">
        <Button
          aria-label="Go back to visits"
          buttonStyle="ghost"
          buttonSize="xs"
          icon={ArrowLeftIcon}
          onClick={() =>
            navigate(
              routes.patientChartsGlob({ id: patientId, glob: 'visits' })
            )
          }
        />

        <Typography
          textStyle="interface-strong-xs"
          color="text-base-color-fg-muted"
        >
          {[
            formatVisitDates({ admissionDate, dischargeDate }),
            claim?.renderingProvider?.practitioner
              ? formatDisplayName(claim.renderingProvider.practitioner)
              : null,
            'Hospital visit',
          ]
            .filter(Boolean)
            .join(' \u2022 ')}
        </Typography>
      </StackView>

      <HospitalVisitSubmitBillingButton />
    </StackView>
  )
}
