import type {
  DoctorFirstPatientScreenVariant,
  FindPatientMedicationURLQuery,
} from 'types/graphql'
import { useUnmount } from 'usehooks-ts'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import MedicationIframeResponseError from 'src/components/MedicationIframeResponseError/MedicationIframeResponseError'
import useGetUpdatedDoctorFirstDefaultPharmacy from 'src/hooks/useGetUpdatedDoctorFirstDefaultPharmacy/useGetUpdatedDoctorFirstDefaultPharmacy'
import usePullMedicationsFromDoctorFirst from 'src/hooks/usePullMedicationsFromDoctorFirst/usePullMedicationsFromDoctorFirst'

export { default as Loading } from 'src/components/atoms/LoadingSpinner'

export const QUERY = gql`
  query FindPatientMedicationURLQuery(
    $patientId: String!
    $encounterId: String
    $variant: DoctorFirstPatientScreenVariant!
  ) {
    patient(id: $patientId) {
      id
      medicationsURL(encounterId: $encounterId, variant: $variant) {
        ... on MedicationIframeResponseSuccess {
          url
        }
        ... on MedicationIframeResponseError {
          notAvailableReason
        }
      }
    }
  }
`

export const beforeQuery = ({
  patientId,
  encounterId,
  shouldUseDoctorFirst,
  variant,
}: {
  patientId: string
  encounterId?: string
  shouldUseDoctorFirst: boolean
  variant?: DoctorFirstPatientScreenVariant
}) => {
  return {
    variables: {
      patientId,
      encounterId,
      shouldUseDoctorFirst,
      variant: variant ?? 'MEDICATIONS',
    },
  }
}

export const Empty = () => <div>Empty</div>

export const Failure = ({ error }: CellFailureProps) => (
  <div className="text-danger">Error: {error?.message}</div>
)

export const Success = ({
  queryResult,
  patient,
}: CellSuccessProps<FindPatientMedicationURLQuery>) => {
  if (patient.medicationsURL.__typename === 'MedicationIframeResponseError') {
    return (
      <MedicationIframeResponseError
        notAvailableReason={patient.medicationsURL.notAvailableReason}
        patientId={patient.id}
        refetch={queryResult.refetch}
      />
    )
  }

  return (
    <DoctorFirstIframe
      url={patient.medicationsURL.url}
      patientId={patient.id}
    />
  )
}

const DoctorFirstIframe = ({
  url,
  patientId,
}: {
  url: string
  patientId: string
}) => {
  const [getUpdatedDoctorFirstDefaultPharmacy] =
    useGetUpdatedDoctorFirstDefaultPharmacy(patientId)
  const [pullMedicationsFromDoctorFirst] =
    usePullMedicationsFromDoctorFirst(patientId)
  useUnmount(() => {
    void getUpdatedDoctorFirstDefaultPharmacy()
    void pullMedicationsFromDoctorFirst()
  })

  return <iframe title="DoctorFirst" src={url} height="100%" width="100%" />
}
