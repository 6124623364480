import { relationshipTypeDisplay } from 'common/data/relationshipTypes'
import {
  hasAccessToPortalForPatient,
  PatientPortalFeature,
} from 'common/patientPortalAuth'
import { formatDisplayName } from 'common/utils'
import { match } from 'ts-pattern'
import {
  GetPortalLinkRecipientOptions,
  GetPortalLinkRecipientOptionsVariables,
} from 'types/graphql'

import { routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'
import { guardianshipTypeDisplay } from 'src/data/guardianshipTypes'
import { HOST_URL } from 'src/lib/constants'
import { formatPhoneNumber } from 'src/lib/formatters'
import { PATIENT_STATUS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientDemographics/fragments'

import StackView from '../atoms/StackView/StackView'
import Alert from '../molecules/Alert/Alert'
import {
  MultiRadioButtonField,
  MultiRadioButtonFieldProps,
} from '../molecules/RadioButton'

const QUERY = gql`
  query GetPortalLinkRecipientOptions($patientId: String!) {
    patient(id: $patientId) {
      id
      mrn
      ...PatientStatusFragment
      birthDate
      givenName
      familyName
      portalUserStatus
      contactInformation {
        id
        mobileNumber
      }
      patientRelatedPersonRelationships {
        id
        relationshipType
        guardianshipType
        isGuarantor
        relatedPersonPortalAccessOverride
        relatedPerson {
          id
          givenName
          familyName
          portalUserStatus
          contactInformation {
            id
            mobileNumber
          }
        }
      }
    }
  }
  ${PATIENT_STATUS_FRAGMENT}
`

export type PortalLinkTextRecipientMultiRadioButtonFieldProps = {
  patientId: string
  portalFeature?: PatientPortalFeature
} & Omit<MultiRadioButtonFieldProps, 'values'>

export const PortalLinkTextRecipientMultiRadioButtonField = ({
  patientId,
  portalFeature,
  ...rest
}: PortalLinkTextRecipientMultiRadioButtonFieldProps) => {
  const { currentUser } = useEmrAuth()
  const { data } = useQuery<
    GetPortalLinkRecipientOptions,
    GetPortalLinkRecipientOptionsVariables
  >(QUERY, {
    variables: {
      patientId,
    },
  })

  const allRelationships = data?.patient
    ? [
        {
          patient: data.patient,
          relationshipType: 'SELF' as const,
          person: data.patient,
        },
        ...(data.patient.patientRelatedPersonRelationships ?? []).map(
          (relationship) => ({
            ...relationship,
            person: relationship.relatedPerson,
            patient: data.patient,
          })
        ),
      ]
    : []

  const validRelationships = allRelationships.filter(
    (relationship) =>
      relationship.person.portalUserStatus === 'ACTIVE' &&
      match(portalFeature)
        .with('MESSAGES', (feature) =>
          hasAccessToPortalForPatient({
            user: {
              familyMessageTasksEnabled: currentUser.familyMessageTasksEnabled,
              relationships: [relationship],
            },
            patientId,
            feature,
          })
        )
        .otherwise((feature) =>
          hasAccessToPortalForPatient({
            user: {
              relationships: [relationship],
            },
            patientId,
            feature,
          })
        )
  )

  return (
    <StackView>
      {data?.patient && !validRelationships.length ? (
        <Alert
          style="danger"
          title="There are no active or eligible family portal accounts associated with this patient. Please resolve the error before trying to send a message."
          link={{
            text: 'Resolve error',
            to: `${HOST_URL}${routes.familyPortalAdmin({
              userType: 'patient',
              query: data.patient.mrn,
            })}`,
            target: '_blank',
            rel: 'noopener noreferrer',
          }}
        />
      ) : null}
      <MultiRadioButtonField
        {...rest}
        values={validRelationships.map((relationship) => {
          const { person } = relationship
          return {
            value: person.contactInformation.id,
            label: `${formatDisplayName(person)}: ${formatPhoneNumber(person.contactInformation.mobileNumber)}`,
            description:
              relationship.relationshipType === 'SELF'
                ? 'Patient'
                : [
                    [
                      guardianshipTypeDisplay[relationship.guardianshipType],
                      relationship.guardianshipType !== 'NON_GUARDIAN'
                        ? 'guardian'
                        : null,
                    ]
                      .filter(Boolean)
                      .join(' '),
                    relationshipTypeDisplay[relationship.relationshipType],
                    relationship.isGuarantor ? 'Guarantor' : null,
                  ]
                    .filter(Boolean)
                    .join(' • '),
          }
        })}
      />
    </StackView>
  )
}
