import { GetPatientForCanid, Sex } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { useEmrAuth } from 'src/auth'

const GET_PATIENT_FOR_CANID = gql`
  query GetPatientForCanid($id: String!) {
    patient: patient(id: $id) {
      id
      tenantId
      mrn
      givenName
      familyName
      birthDate
      sexAtBirth
      contactInformation {
        id
        homeAddress {
          id
          line1
          line2
          city
          state
          postalCode
        }
      }
      primaryGuardian {
        id
        contactInformation {
          id
          homeAddress {
            id
            line1
            line2
            city
            state
            postalCode
          }
        }
      }
    }
  }
`

type PatientForCanid = {
  mrn: string
  practiceId: string
  givenName: string
  familyName: string
  birthDate: string
  sexAtBirth: Sex
  address: {
    line1: string
    line2?: string
    city: string
    state: string
    postalCode: string
  }
}

const PatientForCanidDataElementInternal: React.FC<{ patientId: string }> = ({
  patientId,
}) => {
  const { data, loading } = useQuery<GetPatientForCanid>(
    GET_PATIENT_FOR_CANID,
    {
      variables: {
        id: patientId,
      },
    }
  )

  if (loading) return null

  const {
    id: _patientId,
    tenantId,
    contactInformation,
    primaryGuardian,
    ...rest
  } = data.patient

  const resolvedContactInfo =
    contactInformation ?? primaryGuardian?.contactInformation
  const { id: _addressId, ...address } = resolvedContactInfo.homeAddress

  const patientForCanid: PatientForCanid = {
    ...rest,
    practiceId: tenantId,
    address,
  }

  return (
    <data
      id="canid-patient"
      className="hidden"
      value={JSON.stringify({ patient: patientForCanid })}
    />
  )
}

export const PatientForCanidDataElement: React.FC<{ patientId: string }> = ({
  patientId,
}) => {
  const { currentUser } = useEmrAuth()

  if (currentUser.vaccineManagementServiceProvider === 'CANID') {
    return <PatientForCanidDataElementInternal patientId={patientId} />
  }

  return null
}
