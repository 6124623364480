import { useMutation } from '@apollo/client'

import { toast } from '@redwoodjs/web/toast'

const CREATE_PATIENT_IN_DOCTOR_FIRST_MUTATION = gql`
  mutation CreatePatientInDoctorFirstMutation($patientId: String!) {
    createPatientInDoctorFirst(id: $patientId) {
      id
    }
  }
`

export const useCreatePatientInDoctorFirst = ({
  patientId,
  refetch,
}: {
  patientId: string
  refetch: () => void
}) => {
  return useMutation(CREATE_PATIENT_IN_DOCTOR_FIRST_MUTATION, {
    variables: { patientId },
    onCompleted: () => {
      toast.success('Patient successfully created in DoctorFirst')
      refetch?.()
    },
    onError: (e) =>
      toast.error(`Failed to create patient in DoctorFirst. ${e.message}`),
  })
}

export default useCreatePatientInDoctorFirst
