import { observationConcepts } from 'common/cdr/concepts/observations/index'

import StackView from 'src/components/atoms/StackView'
import Typography, { TextStyle } from 'src/components/atoms/Typography'

const ListItem = ({
  value,
  textStyle = 'normal',
  shouldShow = true,
}: {
  value: string
  textStyle: TextStyle
  shouldShow?: boolean
}) => {
  return shouldShow && value ? (
    <li className=" list-none pl-4 -indent-4 before:pr-1 before:content-['\2012']">
      <Typography textStyle={textStyle}>{value}</Typography>
    </li>
  ) : null
}

const emptyPlaceholder = '\u2014'

export const SexualIntercourseSummary = ({
  sexualIntercourse,
}): JSX.Element => {
  if (!sexualIntercourse) return <Typography>{emptyPlaceholder}</Typography>

  const hasAnswers = Object.keys(sexualIntercourse).some((key) => {
    return sexualIntercourse[key] === true
  })

  return hasAnswers ? (
    <StackView className="pt-1">
      <ListItem
        value={observationConcepts['SEXUAL_INTERCOURSE_ORAL'].display}
        textStyle="subtitle"
        shouldShow={sexualIntercourse.oral}
      />

      <ListItem
        value={observationConcepts['SEXUAL_INTERCOURSE_VAGINAL'].display}
        textStyle="subtitle"
        shouldShow={sexualIntercourse.vaginal}
      />

      <ListItem
        value={observationConcepts['SEXUAL_INTERCOURSE_ANAL'].display}
        textStyle="subtitle"
        shouldShow={sexualIntercourse.anal}
      />
    </StackView>
  ) : (
    <Typography>{emptyPlaceholder}</Typography>
  )
}
