import { useRef } from 'react'

import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import AreaLoader from 'src/components/atoms/AreaLoader/AreaLoader'
import Table, { TableProps } from 'src/components/molecules/Table/Table'
import { SocialHistoryResponse } from 'src/pages/PatientChartsPage/PatientHistory/SocialHistory/config'

const defaultColDef: ColDef = {
  cellStyle: { border: 'none', display: 'flex', alignItems: 'center' },
  cellClass: 'py-2 cursor-pointer',
  resizable: true,
  autoHeight: true,
  minWidth: 180,
}

const SocialHistoryTable = ({
  testId,
  columnDefs,
  noRowsOverlayComponent,
  rowData,
  ...rest
}: Omit<TableProps<SocialHistoryResponse>, 'innerRef'>) => {
  const gridRef = useRef<AgGridReact>()

  return (
    <Table
      testId={testId}
      innerRef={gridRef}
      rowData={rowData}
      domLayout="autoHeight"
      rowHeight={50}
      headerHeight={36}
      defaultColDef={defaultColDef}
      columnDefs={columnDefs}
      loadingOverlayComponent={AreaLoader}
      noRowsOverlayComponent={noRowsOverlayComponent}
      {...rest}
    />
  )
}

export default SocialHistoryTable
