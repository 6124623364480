import { isEmpty } from 'lodash'
import { UpsertBirthHistoryInputWithUnitInputs } from 'types/graphql'

import Divider from 'src/components/atoms/Divider'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { labTestResultDisplay } from 'src/data/labTestResults'
import { presenceIndicatorDisplay } from 'src/data/presenceIndicators'
import { useBirthHistoryQuery } from 'src/pages/PatientChartsPage/PatientHistory/BirthHistory/useBirthHistory'

import { RowDisplay, UnitValueDisplay } from './BirthHistoryV2'

const PrenatalLabResultsSectionV2 = ({ prenatalLabResults }) => {
  const {
    streptococcusGroupB,
    hiv,
    chlamydia,
    gonorrhoeae,
    syphilis,
    rubella,
  } = prenatalLabResults

  return (
    <StackView
      space={75}
      className="rounded border p-core-space-75"
      divider
      testId="birth-history-prenatal-lab-results-section"
    >
      <Typography color="text-base-color-fg-default" textStyle="title-xs">
        Prenatal labs
      </Typography>
      <RowDisplay
        label="Group B strep"
        value={labTestResultDisplay[streptococcusGroupB]}
      />
      <RowDisplay
        label="Human immunodeficiency virus (HIV)"
        value={labTestResultDisplay[hiv]}
      />
      <RowDisplay
        label="Chlamydia trachomatis"
        value={labTestResultDisplay[chlamydia]}
      />
      <RowDisplay
        label="Neisseria gonorrhoeae"
        value={labTestResultDisplay[gonorrhoeae]}
      />
      <RowDisplay
        label="Treponema pallidum (syphilis)"
        value={labTestResultDisplay[syphilis]}
      />
      <RowDisplay label="Rubella" value={labTestResultDisplay[rubella]} />
    </StackView>
  )
}

const PregnancyDetailsSectionsV2 = ({
  naturalMotherHistory,
}: {
  naturalMotherHistory: UpsertBirthHistoryInputWithUnitInputs['naturalMotherHistory']
}) => {
  const {
    ageAtPatientBirth,
    hadRoutinePrenatalCare,
    hadPregnancyComplications,
  } = naturalMotherHistory

  return (
    <StackView space={75} divider>
      <UnitValueDisplay
        label="Mother's age at birth"
        input={ageAtPatientBirth}
        displayAsInteger={true}
        direction="row"
      />
      <RowDisplay
        label="Routine prenatal care"
        value={presenceIndicatorDisplay[hadRoutinePrenatalCare]}
      />
      <RowDisplay
        label="Pregnancy complications"
        value={presenceIndicatorDisplay[hadPregnancyComplications]}
      />
    </StackView>
  )
}

export const NaturalMotherHistorySectionV2 = ({
  patientId,
}: {
  patientId: string
}) => {
  const { birthHistoryFormValues } = useBirthHistoryQuery(patientId)

  if (isEmpty(birthHistoryFormValues)) return null
  const { naturalMotherHistory } = birthHistoryFormValues

  return (
    <StackView
      space={75}
      className="w-1/2"
      testId="birth-history-natural-mother-section"
    >
      <Typography color="text-base-color-fg-default" textStyle="title-xs">
        Natural Mother
      </Typography>
      <Divider />
      <PregnancyDetailsSectionsV2 naturalMotherHistory={naturalMotherHistory} />
      <PrenatalLabResultsSectionV2
        prenatalLabResults={naturalMotherHistory.prenatalLabResults}
      />
    </StackView>
  )
}
