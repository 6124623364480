import { useMemo, useRef, useState } from 'react'

import { uniqBy } from 'lodash'
import {
  DiagnosisCodeFilter,
  DiagnosisCodeSearchSet,
  SearchDiagnosisCodes,
} from 'types/graphql'

import { RegisterOptions } from '@redwoodjs/forms'
import { useQuery } from '@redwoodjs/web'

import { DropdownV2Option } from 'src/components/atoms/DropdownV2/DropdownV2'

import { AdvancedSearchV2Field } from '../AdvancedSearchV2Field/AdvancedSearchV2Field'

export const SEARCH_DIAGNOSIS_CODES_QUERY = gql`
  query SearchDiagnosisCodes(
    $input: DiagnosisCodeSearchInput!
    $filters: [DiagnosisCodeFilter!]
  ) {
    searchDiagnosisCodes(input: $input, filters: $filters) {
      id
      code
      description
    }
  }
`

const menuFilterOptionsDisplay: {
  [key in DiagnosisCodeFilter]: string
} = {
  USER_FAVORITE: 'Favorite diagnoses',
  PATIENT_ACTIVE: 'Patient active diagnoses',
  ENCOUNTER_ACTIVE: 'Active diagnoses for this visit',
  ENCOUNTER_COMMON: 'Common diagnoses for current visit type',
  FAMILY_HISTORY: 'Family history diagnoses',
}

export type DiagnosisSearchV2FieldProps = {
  name: string
  patientId: string
  searchSet: DiagnosisCodeSearchSet
  encounterId?: string
  validation?: RegisterOptions
  disableUserFilterSelection?: boolean
  disableFilters?: boolean
  defaultFilters?: DiagnosisCodeFilter[]
  defaultSearching?: boolean
}

const DiagnosisSearchV2Field = ({
  name,
  patientId,
  searchSet,
  encounterId,
  validation,
  disableUserFilterSelection,
  disableFilters,
  defaultFilters,
  defaultSearching,
}: DiagnosisSearchV2FieldProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [searchFilters, setSearchFilters] = useState<string[]>([])
  const previousOptionsRef = useRef<DropdownV2Option[]>([])

  const { data, loading } = useQuery(SEARCH_DIAGNOSIS_CODES_QUERY, {
    variables: {
      input: { searchQuery, patientId, encounterId, searchSet },
      filters: searchFilters,
    },
  })

  const filteredOptions: DropdownV2Option[] = useMemo(
    () =>
      data?.searchDiagnosisCodes?.map(
        (o: SearchDiagnosisCodes['searchDiagnosisCodes'][number]) => ({
          label: o.code,
          secondaryLabel: o.description,
          value: o.code,
        })
      ) ?? [],

    [data?.searchDiagnosisCodes]
  )

  const options: DropdownV2Option[] = useMemo(() => {
    const previousOptions = previousOptionsRef.current
    const currentOptions = uniqBy(
      [...previousOptions, ...filteredOptions],
      'value'
    )
    previousOptionsRef.current = currentOptions
    return currentOptions
  }, [filteredOptions])

  const menuFilterOptions = Object.keys(menuFilterOptionsDisplay)
    .map((k) => ({
      label: menuFilterOptionsDisplay[k],
      value: k as DiagnosisCodeFilter,
    }))
    .filter((option) => {
      if (option.value === 'FAMILY_HISTORY')
        return defaultFilters?.includes('FAMILY_HISTORY')

      if (!patientId) return ['USER_FAVORITE'].includes(option.value)

      if (encounterId) return true

      return !['ENCOUNTER_COMMON', 'ENCOUNTER_ACTIVE'].includes(option.value)
    })
  const filtersAvailable = menuFilterOptions.map((option) => option.value)

  const initialFilters = defaultFilters
    ? filtersAvailable.filter((v) => defaultFilters.includes(v))
    : filtersAvailable

  if (defaultFilters) {
    if (initialFilters.length !== defaultFilters.length) {
      // eslint-disable-next-line no-console
      console.warn(
        `Invalid defaultFilters provided to DiagnosisSearchField. Invalid values: ${defaultFilters.filter(
          (v) => !initialFilters.includes(v)
        )}`
      )
    }
  }

  return (
    <AdvancedSearchV2Field
      addMoreButtonText="Add diagnosis"
      defaultSearching={defaultSearching}
      name={name}
      options={options}
      sidepanelProps={{
        defaultMenuFilters: disableFilters ? [] : initialFilters,
        filteredOptions,
        menuProps: {
          emptySearchResultsMessage: 'No diagnosis codes to display',
          menuFilters:
            disableUserFilterSelection || disableFilters
              ? []
              : menuFilterOptions,
          menuItemsLoading: loading,
          menuSearchPlaceholder: 'Search by diagnosis code or keyword',
        },
        onUpdateMenuFilters: setSearchFilters,
        onUpdateSearchInput: setSearchQuery,
        sidepanelHeader: 'Add diagnosis code',
        sidepanelDescription: 'Select a diagnosis code',
      }}
      testId="diagnosis-search-field"
      validation={validation}
    />
  )
}

export default DiagnosisSearchV2Field
