import { formatDisplayName } from 'common/utils'
import {
  PatientLegalStatus,
  UpdatePatientCaregiverOptOutInput,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'

import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import SelectField from 'src/components/atoms/SelectField/SelectField'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import RelatedPersonSelectCell from 'src/components/RelatedPerson/RelatedPersonSelectCell'
import { patientLegalStatusDisplay } from 'src/data/patientLegalStatuses'
import checkAge from 'src/lib/checkAge'
import { calculateAge } from 'src/lib/formatters'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext'

export const UPDATE_PATIENT_CAREGIVER_OPTOUT_MUTATION = gql`
  mutation UpdatePatientCaregiverOptOutMutation(
    $patientId: String!
    $input: UpdatePatientCaregiverOptOutInput!
  ) {
    updatePatientCaregiverOptOut(id: $patientId, input: $input) {
      id
      legalStatus
      insuranceOptOutStatus
      primaryGuardian {
        id
      }
      primaryGuarantor {
        id
      }
      caregiverOptedOutAt
    }
  }
`

export const getPatientLegalStatusOptions = (
  birthDate: string
): Array<{
  value: PatientLegalStatus
  display: string
}> => {
  const ageRestrictedAbilities = checkAge(calculateAge(birthDate))

  const options = [
    {
      value: 'DEPENDENT' as PatientLegalStatus,
      display: patientLegalStatusDisplay['DEPENDENT'],
    },
  ]

  if (ageRestrictedAbilities.canBeEmancipatedMinor) {
    options.push({
      value: 'EMANCIPATED_MINOR' as PatientLegalStatus,
      display: patientLegalStatusDisplay['EMANCIPATED_MINOR'],
    })
  }

  if (ageRestrictedAbilities.canBeindependentAdult) {
    options.push({
      value: 'INDEPENDENT_ADULT' as PatientLegalStatus,
      display: patientLegalStatusDisplay['INDEPENDENT_ADULT'],
    })
  }

  return options
}

const PatientCaregiverOptOutCard = () => {
  const {
    appointmentData: { patient },
  } = useAppointmentCheckIn()

  const formMethods = useForm<UpdatePatientCaregiverOptOutInput>({
    defaultValues: {
      caregiverOptOut: patient.caregiverOptedOutAt ? true : false,
      primaryGuardianId: patient.primaryGuardian?.id,
      primaryGuarantorId: patient.primaryGuarantor?.id,
    },
    shouldUnregister: true,
  })

  const caregiverOptOut = formMethods.watch('caregiverOptOut')

  const preSubmit = (data) => {
    const input = { ...data }

    return { variables: { patientId: patient.id, input } }
  }

  return (
    <DataVerificationCard<UpdatePatientCaregiverOptOutInput>
      title="Caregiver opt-out"
      updateMutation={UPDATE_PATIENT_CAREGIVER_OPTOUT_MUTATION}
      preSubmit={preSubmit}
      formMethods={formMethods}
      defaultOpen
      data={[
        {
          name: 'caregiverOptOut',
          label: 'Is the patient opting-out?',
          required: true,
          value: caregiverOptOut,
          displayValue: patient.caregiverOptedOutAt ? 'Yes' : 'No',
          inputProps: {
            includeEmptyOption: false,
            trueDisplay: 'Yes',
            falseDisplay: 'No',
          },
          formInputComponent: BooleanSelectField,
        },
        {
          name: 'primaryGuardianId',
          label: 'Primary guardian',
          required: true,
          hide: !!patient.caregiverOptedOutAt,
          hideFormInput: caregiverOptOut,
          value: patient.primaryGuardian?.id,
          displayValue:
            patient.primaryGuardian &&
            formatDisplayName(patient.primaryGuardian),
          inputProps: {
            includeEmptyOption: false,
            patientId: patient.id,
          },
          formInputComponent: RelatedPersonSelectCell,
        },
        {
          name: 'primaryGuarantorId',
          label: 'Primary guarantor',
          required: true,
          hide: !!patient.caregiverOptedOutAt,
          hideFormInput: caregiverOptOut,
          value: patient.primaryGuarantor?.id,
          displayValue:
            patient.primaryGuarantor &&
            formatDisplayName(patient.primaryGuardian),
          inputProps: {
            includeEmptyOption: false,
            patientId: patient.id,
          },
          formInputComponent: RelatedPersonSelectCell,
        },
        {
          name: 'legalStatus',
          label: 'Guardianship status',
          value: patient.legalStatus,
          hide: !patient.caregiverOptedOutAt,
          hideFormInput: !caregiverOptOut,
          displayValue: patientLegalStatusDisplay[patient.legalStatus],
          required: true,
          inputProps: {
            options: getPatientLegalStatusOptions(patient.birthDate),
            validation: {
              validate: (value: PatientLegalStatus) => {
                return value === 'DEPENDENT'
                  ? 'Dependents cannot opt-out'
                  : null
              },
            },
          },
          formInputComponent: SelectField,
        },
        {
          name: 'guarantorStatus',
          label: 'Guarantor status',
          hide: !patient.caregiverOptedOutAt,
          hideFormInput: !caregiverOptOut,
          value: 'self-guarantor',
          displayValue: 'Self guarantor',
          required: true,
          inputProps: {
            includeEmptyOption: false,
            options: [
              {
                value: 'self-guarantor',
                display: 'Self guarantor',
              },
            ],
          },
          formInputComponent: SelectField,
        },
      ]}
    />
  )
}

export default PatientCaregiverOptOutCard
