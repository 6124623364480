import { namePrefixDisplay } from 'common/data/namePrefixes'
import { nameSuffixDisplay } from 'common/data/nameSuffixes'
import { parseISO } from 'date-fns'
import { AppointmentPatientCheckInQuery } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import InputField from 'src/components/atoms/InputField'
import MaritalStatusSelectField from 'src/components/atoms/MaritalStatusSelectField/MaritalStatusSelectField'
import NamePrefixSelectField from 'src/components/atoms/NamePrefixSelectField/NamePrefixSelectField'
import NameSuffixSelectField from 'src/components/atoms/NameSuffixSelectField/NameSuffixSelectField'
import { DatePickerField } from 'src/components/molecules/DatePicker'
import DataVerificationCard from 'src/components/organisms/DataVerificationCard'
import { maritalStatusDisplay } from 'src/data/maritalStatuses'
import { formatDateDisplay, formatDateFieldValue } from 'src/lib/formatters'
import { useAppointmentCheckIn } from 'src/providers/context/AppointmentCheckInContext'

const VERIFY_PATIENT_CAREGIVER_CORE_DEMOGRAPHICS = gql`
  mutation VerifyPatientCaregiverCoreDemographicsMutation(
    $patientId: String!
    $relatedPersonId: String!
  ) {
    verifyPatientCaregiverCoreDemographics(
      id: $patientId
      relatedPersonId: $relatedPersonId
    ) {
      id
      patientRelatedPersonRelationships {
        id
        relatedPerson {
          id
          coreDemographicsVerifiedAt
        }
      }
    }
  }
`
export const UPDATE_PATIENT_CAREGIVER_CORE_DEMOGRAPHICS = gql`
  mutation UpdatePatientCaregiverCoreDemographics(
    $patientId: String!
    $relatedPersonId: String!
    $input: UpdatePatientCaregiverCoreDemographicsInput!
  ) {
    updatePatientCaregiverCoreDemographics(
      id: $patientId
      relatedPersonId: $relatedPersonId
      input: $input
    ) {
      id
      patientRelatedPersonRelationships {
        id
        verifiedAt
        relatedPerson {
          id
          namePrefix
          givenName
          middleName
          familyName
          nameSuffix
          preferredName
          birthDate
          maritalStatus
          occupation
          employer
        }
      }
    }
  }
`

const CaregiverCoreDemographicsVerificationCard: React.FC<{
  relationship: AppointmentPatientCheckInQuery['appointment']['patient']['patientRelatedPersonRelationships'][0]
}> = ({ relationship }) => {
  const { relatedPerson } = relationship

  const {
    checkInVerification,
    appointmentData: { patient },
  } = useAppointmentCheckIn()

  const [verifyPatientCaregiverCoreDemographics] = useMutation(
    VERIFY_PATIENT_CAREGIVER_CORE_DEMOGRAPHICS
  )

  const { verificationRequirement, lastVerifiedAt } =
    checkInVerification['CAREGIVER'][relationship.id]['CORE_DEMOGRAPHICS']

  const onVerify = () => {
    verifyPatientCaregiverCoreDemographics({
      variables: {
        patientId: patient.id,
        relatedPersonId: relatedPerson.id,
      },
    })
  }

  const preSubmit = (data) => {
    const {
      namePrefix,
      givenName,
      middleName,
      familyName,
      nameSuffix,
      preferredName,
      birthDate,
      maritalStatus,
      occupation,
      employer,
    } = data

    return {
      variables: {
        patientId: patient.id,
        relatedPersonId: relatedPerson.id,
        input: {
          namePrefix,
          givenName,
          middleName,
          familyName,
          nameSuffix,
          preferredName,
          birthDate,
          maritalStatus,
          occupation,
          employer,
        },
      },
    }
  }

  return (
    <DataVerificationCard
      title="Core demographics"
      lastVerifiedAt={lastVerifiedAt}
      defaultOpen
      updateMutation={UPDATE_PATIENT_CAREGIVER_CORE_DEMOGRAPHICS}
      preSubmit={preSubmit}
      verificationOptions={{
        onVerify: onVerify,
        verificationRequirement,
      }}
      data={[
        {
          name: 'namePrefix',
          value: relatedPerson.namePrefix,
          displayValue: namePrefixDisplay[relatedPerson.namePrefix],
          label: 'Prefix',

          formInputComponent: NamePrefixSelectField,
        },
        {
          name: 'givenName',
          value: relatedPerson.givenName,
          label: 'First Name',
          required: true,
          formInputComponent: InputField,
        },
        {
          name: 'middleName',
          value: relatedPerson.middleName,
          label: 'Middle Name',
          formInputComponent: InputField,
        },
        {
          name: 'familyName',
          value: relatedPerson.familyName,
          label: 'Family Name',
          required: true,
          formInputComponent: InputField,
        },
        {
          name: 'nameSuffix',
          value: relatedPerson.nameSuffix,
          displayValue: nameSuffixDisplay[relatedPerson.nameSuffix],
          label: 'Suffix',
          formInputComponent: NameSuffixSelectField,
        },
        {
          name: 'preferredName',
          value: relatedPerson.preferredName,
          label: 'Preferred Name',
          formInputComponent: InputField,
        },
        {
          name: 'birthDate',
          value: relatedPerson.birthDate,
          displayValue: formatDateDisplay(relatedPerson.birthDate),
          label: 'Date of birth',
          required: true,
          inputProps: {
            emptyAs: null,
            validation: {
              setValueAs: (value) => formatDateFieldValue(value),
              validate: (value) => {
                return parseISO(value) > new Date()
                  ? 'Date of birth must be in the past'
                  : null
              },
            },
          },
          formInputComponent: DatePickerField,
        },
        {
          name: 'maritalStatus',
          value: relatedPerson.maritalStatus,
          displayValue: maritalStatusDisplay[relatedPerson.maritalStatus],
          label: 'Marital Status',
          formInputComponent: MaritalStatusSelectField,
        },
        {
          name: 'occupation',
          value: relatedPerson.occupation,
          label: 'Occupation',
          formInputComponent: InputField,
        },
        {
          name: 'employer',
          value: relatedPerson.employer,
          label: 'Current employer',
          formInputComponent: InputField,
        },
      ]}
    />
  )
}

export default CaregiverCoreDemographicsVerificationCard
