import { useParams } from 'react-router-dom'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import { DatePickerFieldThatWorks } from 'src/components/molecules/DatePicker'
import { FormInputList } from 'src/components/molecules/FormInputList'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import {
  usePatientChargeByIdQuery,
  useUpdatePatientChargeMutation,
} from 'src/hooks/usePatientCharges/usePatientCharges'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelPatientChargeEdit = () => {
  const { patientChargeId } = useParams()
  const { charge, loading } = usePatientChargeByIdQuery(patientChargeId)
  const [updatePatientCharge, { loading: updatingCharge }] =
    useUpdatePatientChargeMutation()

  const { closeSidePanel } = useSidepanel()

  const formMethods = useForm()

  const onSubmit = async (data) => {
    await updatePatientCharge({
      variables: {
        input: {
          id: patientChargeId,
          dateOfService: data.dateOfService,
        },
      },
      onCompleted: () => {
        toast.success('Charge updated')
        closeSidePanel()
      },
    })
  }

  if (!loading && !charge) {
    return <SidepanelNotAvailable />
  }

  return (
    <SidepanelPage
      testId="patient-charge-edit"
      header={charge ? `Edit ${charge.description}` : 'Edit charge'}
      description="Make changes to the charge’s date."
      loading={loading}
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Save',
          submitting: updatingCharge,
        }}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          className="py-core-space-150"
          title="Details"
          divider={false}
          items={[
            {
              name: 'dateOfService',
              label: 'Charge date',
              required: true,
              formInputComponent: DatePickerFieldThatWorks,
              direction: 'col',
              inputProps: {
                defaultValue: charge?.dateOfService,
              },
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}

export default SidepanelPatientChargeEdit
