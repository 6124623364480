import { ComponentProps } from 'react'

import clsx from 'clsx'

import { InputField as RWInputField } from '@redwoodjs/forms'

export type RWInputFieldProps = ComponentProps<typeof RWInputField>

export type InputFieldSizeType = 's' | 'm' | 'l'

export interface InputFieldProps extends Omit<RWInputFieldProps, 'size'> {
  iconLeft?: React.FC<React.ComponentProps<'svg'>>
  iconRight?: React.FC<React.ComponentProps<'svg'>>
  testId?: string
  onClickIconLeft?: () => void
  onClickIconRight?: () => void
  fullWidth?: boolean
  isFormElement?: boolean
  size?: InputFieldSizeType
}

const RenderIcon = ({
  Icon,
  onClickIcon,
  position,
  size,
}: {
  Icon: React.FC<React.ComponentProps<'svg'>>
  onClickIcon?: () => void
  position: 'left' | 'right'
  size: InputFieldSizeType
}) => {
  const iconContainerClasses = [
    'absolute',
    'h-full',
    'top-0 ',
    'flex items-center',
    'z-10',
    position === 'left' && size === 's' && 'left-core-space-50',
    position === 'right' && size === 's' && 'right-core-space-50',
    position === 'left' && size === 'm' && 'left-core-space-75',
    position === 'right' && size === 'm' && 'right-core-space-75',
    position === 'left' && size === 'l' && 'left-core-space-75',
    position === 'right' && size === 'l' && 'right-core-space-75',
  ]
  const iconClasses = [
    'fill-base-color-fg-default',
    'h-base-size-icon-xs w-base-size-icon-xs',
    !onClickIcon ? 'pointer-events-none' : 'cursor-pointer',
  ]
  return (
    <div className={clsx(iconContainerClasses)}>
      <Icon
        className={clsx(iconClasses, onClickIcon && 'cursor-pointer')}
        aria-hidden="true"
        onClick={onClickIcon}
      />
    </div>
  )
}

export const getInputFieldContainerClasses = ({
  fullWidth = false,
  disabled = false,
}: {
  fullWidth?: boolean
  disabled?: boolean
}) => {
  return [
    'relative',
    fullWidth && 'w-full',
    disabled && 'opacity-base-opacity-disabled pointer-events-none',
  ]
}

export const getInputFieldClasses = ({
  size,
  hasIconLeft = false,
  hasIconRight = false,
}: {
  size: InputFieldSizeType
  hasIconLeft?: boolean
  hasIconRight?: boolean
}) => {
  const classes = [
    'block w-full',
    'focus:outline-none',
    'focus:ring-0',

    'bg-comp-textfield-color-clean-enabled-bg',
    'border-base-border-width-selectable-s',
    'border-comp-textfield-color-clean-enabled-border',
    'py-base-space-selectable-inset-none',
    'shadow-base-box-shadow-selectable-s',
    'rounded-base-border-radius-selectable-s',
    'placeholder-comp-textfield-color-clean-enabled-placeholder',
    'text-comp-textfield-color-clean-enabled-content',
    '[appearance:textfield]',
    '[&::-webkit-inner-spin-button]:appearance-none',
    '[&::-webkit-outer-spin-button]:appearance-none',

    'hover:bg-comp-textfield-color-clean-hover-bg',
    'hover:border-comp-textfield-color-clean-hover-border',
    'hover:placeholder-comp-textfield-color-clean-hover-placeholder',
    'hover:text-comp-textfield-color-clean-hover-content',

    'focus:bg-comp-textfield-color-clean-focused-bg',
    'focus:border-comp-textfield-color-clean-focused-border',
    'focus:shadow-base-box-shadow-clean-focus-border',
    'focus:placeholder-comp-textfield-color-clean-focused-placeholder',
    'focus:text-comp-textfield-color-clean-focused-content',
  ]

  switch (size) {
    case 's':
      classes.push(
        'h-base-size-selectable-s',
        'px-base-space-selectable-inset-s',
        'text-base-typography-interface-default-l-font-size',
        'font-base-typography-interface-default-l-font-weight',
        'font-base-typography-interface-default-l-font-family',
        'leading-base-typography-interface-default-l-line-height',
        'md:text-base-typography-interface-default-xs-font-size',
        'md:font-base-typography-interface-default-xs-font-weight',
        'md:font-base-typography-interface-default-xs-font-family',
        'md:leading-base-typography-interface-default-xs-line-height',
        hasIconLeft && '!pl-core-space-200',
        hasIconRight && '!pr-core-space-200'
      )
      break
    case 'm':
      classes.push(
        'h-base-size-selectable-m',
        'px-base-space-selectable-inset-m',
        'text-base-typography-interface-default-l-font-size',
        'font-base-typography-interface-default-l-font-weight',
        'font-base-typography-interface-default-l-font-family',
        'leading-base-typography-interface-default-l-line-height',
        'md:text-base-typography-interface-default-s-font-size',
        'md:font-base-typography-interface-default-s-font-weight',
        'md:font-base-typography-interface-default-s-font-family',
        'md:leading-base-typography-interface-default-s-line-height',
        hasIconLeft && '!pl-core-space-225',
        hasIconRight && '!pr-core-space-225'
      )
      break
    case 'l':
      classes.push(
        'h-base-size-selectable-l',
        'px-base-space-selectable-inset-m',
        'text-base-typography-interface-default-l-font-size',
        'font-base-typography-interface-default-l-font-weight',
        'font-base-typography-interface-default-l-font-family',
        'leading-base-typography-interface-default-l-line-height',
        'md:text-base-typography-interface-default-m-font-size',
        'md:font-base-typography-interface-default-m-font-weight',
        'md:font-base-typography-interface-default-m-font-family',
        'md:leading-base-typography-interface-default-m-line-height',
        hasIconLeft && '!pl-core-space-225',
        hasIconRight && '!pr-core-space-225'
      )
      break
  }
  return classes
}

export const inputFieldErrorClasses = [
  'bg-comp-textfield-color-dirty-enabled-bg',
  'border-comp-textfield-color-dirty-enabled-border',
  'placeholder-comp-textfield-color-dirty-enabled-placeholder',
  'text-comp-textfield-color-dirty-enabled-content',

  'hover:bg-comp-textfield-color-dirty-hover-bg',
  'hover:border-comp-textfield-color-dirty-hover-border',
  'hover:placeholder-comp-textfield-color-dirty-hover-placeholder',
  'hover:text-comp-textfield-color-dirty-hover-content',

  'focus:bg-comp-textfield-color-dirty-focused-bg',
  'focus:border-comp-textfield-color-dirty-focused-border',
  'focus:shadow-base-box-shadow-dirty-focus-border',
  'focus:placeholder-comp-textfield-color-dirty-focused-placeholder',
  'focus:text-comp-textfield-color-dirty-focused-content',
]

const InputField = ({ name, ...rest }: InputFieldProps) => {
  return <Input name={name} {...rest} isFormElement />
}

export const Input = ({
  type = 'text',
  name,
  testId,
  emptyAs = null,
  iconLeft: IconLeft,
  iconRight: IconRight,
  onClickIconLeft,
  onClickIconRight,
  disabled,
  className,
  errorClassName,
  fullWidth = false,
  isFormElement = false,
  size = 'm',
  ...rest
}: InputFieldProps) => {
  return (
    <div
      className={clsx(getInputFieldContainerClasses({ fullWidth, disabled }))}
    >
      {IconLeft && (
        <RenderIcon
          Icon={IconLeft}
          onClickIcon={onClickIconLeft}
          position="left"
          size={size}
        />
      )}
      {IconRight && (
        <RenderIcon
          Icon={IconRight}
          onClickIcon={onClickIconRight}
          position="right"
          size={size}
        />
      )}
      {isFormElement ? (
        <RWInputField
          name={name}
          type={type}
          data-testid={testId}
          disabled={disabled}
          emptyAs={emptyAs}
          className={clsx(
            ...getInputFieldClasses({
              size,
              hasIconLeft: !!IconLeft,
              hasIconRight: !!IconRight,
            }),
            className
          )}
          errorClassName={clsx(
            ...inputFieldErrorClasses,
            ...getInputFieldClasses({
              size,
              hasIconLeft: !!IconLeft,
              hasIconRight: !!IconRight,
            }),
            errorClassName
          )}
          onWheel={(e) => {
            if (type === 'number') {
              // Prevent the input value change
              e.currentTarget.blur()

              // Refocus immediately, on the next tick (after the current function is done)
              setTimeout(() => {
                e.currentTarget?.focus()
              }, 0)
            }
          }}
          {...rest}
        />
      ) : (
        <input
          name={name}
          type={type}
          data-testid={testId}
          disabled={disabled}
          className={clsx(
            ...getInputFieldClasses({
              size,
              hasIconLeft: !!IconLeft,
              hasIconRight: !!IconRight,
            }),
            className
          )}
          {...rest}
        />
      )}
    </div>
  )
}

export default InputField
