import { useEffect } from 'react'

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import isEmpty from 'lodash/isEmpty'
import { MacroPhraseSection } from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'

import Button, { ButtonStyle } from 'src/components/atoms/Button'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField'
import Typography from 'src/components/atoms/Typography'
import WysiwygField from 'src/components/atoms/WysiwygField/WysiwygField'
import { useDebouncedCallback } from 'src/utils'

type CallToAction = {
  name: string
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  buttonStyle?: ButtonStyle
  callback: () => void
}

type Props = {
  title: string
  subtitle?: string
  titleStyle?: 'title' | 'label'
  onChange: (value: string) => void
  defaultValue?: string
  delay?: number
  callToAction?: CallToAction | CallToAction[]
  testId?: string
  description?: string
  isRichText?: boolean
  macroPhraseSection?: MacroPhraseSection
  macroPhraseTypeaheadPosition?: 'above' | 'below'
  loading: boolean
  disabled?: boolean
}
const TextareaAutoSaveSection: React.FC<Props> = ({
  title,
  subtitle,
  onChange,
  defaultValue = '',
  delay = 300,
  callToAction,
  testId,
  description,
  isRichText = false,
  titleStyle = 'title',
  macroPhraseSection,
  macroPhraseTypeaheadPosition,
  loading,
  disabled = false,
}) => {
  const formMethods = useForm({
    defaultValues: {
      textareaSection: defaultValue,
    },
  })
  const input = formMethods.watch('textareaSection')

  const debouncedCallback = useDebouncedCallback(() => {
    onChange(input)
  }, delay)

  useEffect(() => {
    if (formMethods.formState.isDirty) {
      debouncedCallback()
    }
  }, [debouncedCallback, formMethods.formState.isDirty, input])

  return (
    <StackView space={50} className="py-2" data-testid={testId}>
      <StackView direction="row" justifyContent="between" alignItems="center">
        <StackView direction="row" alignItems="center" space={50}>
          {titleStyle === 'label' ? (
            <Typography
              textStyle="normal"
              color="text-base-color-fg-muted"
              fontWeight="medium"
            >
              {title}
            </Typography>
          ) : (
            <Typography
              textStyle="title"
              className="min-w-fit leading-7"
              fontWeight="semibold"
            >
              {title}
            </Typography>
          )}

          {subtitle && (
            <Typography
              textStyle="description"
              color="text-base-color-fg-muted"
            >
              {subtitle}
            </Typography>
          )}
        </StackView>

        {Array.isArray(callToAction) && callToAction.length > 1 && (
          <StackView direction="row" justifyContent="end" alignItems="center">
            {callToAction.map((callToAction) => {
              return (
                <StackView
                  className="w-min"
                  key={callToAction.name}
                  direction="row"
                  justifyContent="end"
                >
                  <Button
                    buttonStyle={callToAction.buttonStyle ?? 'link'}
                    icon={callToAction.icon}
                    text={callToAction.name}
                    className="h-8 w-min"
                    onClick={callToAction.callback}
                  />
                </StackView>
              )
            })}
          </StackView>
        )}
        {!Array.isArray(callToAction) && !isEmpty(callToAction) && (
          <Button
            buttonStyle="link"
            icon={ArrowTopRightOnSquareIcon}
            text={callToAction.name}
            className="h-8"
            onClick={callToAction.callback}
          />
        )}
      </StackView>
      {description && (
        <Typography textStyle="body-s" color="text-base-color-fg-muted">
          {description}
        </Typography>
      )}
      <Form formMethods={formMethods}>
        {isRichText ? (
          <WysiwygField
            testId={`${testId}-wysiwyg`}
            name="textareaSection"
            defaultValue={input}
            onChange={debouncedCallback}
            macroPhraseSection={macroPhraseSection}
            macroPhraseTypeaheadPosition={macroPhraseTypeaheadPosition}
            loading={loading}
            disabled={disabled}
          />
        ) : (
          <TextAreaField name="textareaSection" rows={5} />
        )}
      </Form>
    </StackView>
  )
}

export default TextareaAutoSaveSection
