import { useMemo, useState } from 'react'

import { NoSymbolIcon } from '@heroicons/react/20/solid'
import { formatDisplayName } from 'common/utils'
import sortBy from 'lodash/sortBy'
import { PatientDemographicsFragment } from 'types/graphql'

import Button from 'src/components/atoms/Button'
import Space from 'src/components/atoms/Space/Space'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import InsuranceEligibility from 'src/components/InsuranceEligibility/InsuranceEligibility'
import Tabs from 'src/components/molecules/Tabs/Tabs'
import { coordinationOfBenefitTypeDisplay } from 'src/data/coordinationOfBenefitTypes'
import { insuranceCoverageStatusDisplay } from 'src/data/insuranceCoverageStatuses'
import CreateInsuranceForm from 'src/pages/AppointmentCheckInPage/CheckInInsuranceTab/CreateInsuranceForm'

import InsuranceMemberCard from './InsuranceMemberCard'
import InsurancePlanCard from './InsurancePlanCard'
import InsurancePolicyholderCard from './InsurancePolicyholderCard'
import InsuranceStatusCard from './InsuranceStatusCard'

const InsurancesTabs = ({
  patient,
}: {
  patient: PatientDemographicsFragment
}) => {
  const [newInsurances, setNewInsurances] = useState([])

  const addNewInsurance = () => setNewInsurances((prev) => [...prev, {}])

  const removeTab = (index: number) => {
    setNewInsurances((prev) => prev.filter((_, i) => i !== index))
  }

  const insuranceTabs = useMemo(() => {
    const existingInsuranceTabs = sortBy(patient.insuranceCoverages, [
      'status',
      'coordinationOfBenefitsType',
    ]).map((insurance) => {
      const name = `${
        coordinationOfBenefitTypeDisplay[insurance.coordinationOfBenefitsType]
      } (${insuranceCoverageStatusDisplay[insurance.status]})`

      return {
        key: insurance.id,
        name,
        content: (
          <StackView space={100} className="pt-8">
            <Typography textStyle="heading">{`${
              coordinationOfBenefitTypeDisplay[
                insurance.coordinationOfBenefitsType
              ]
            } Insurance (${
              insuranceCoverageStatusDisplay[insurance.status]
            })`}</Typography>
            <InsuranceStatusCard insurance={insurance} />

            <Typography textStyle="title" className="pt-4">
              Member and Policyholder
            </Typography>
            <InsuranceMemberCard patient={patient} insurance={insurance} />
            <InsurancePolicyholderCard
              patient={patient}
              insurance={insurance}
            />
            <InsurancePlanCard patient={patient} insurance={insurance} />
            <InsuranceEligibility
              insuranceId={insurance.id}
              insuranceEligibility={insurance.mostRecentEligibility}
            />
          </StackView>
        ),
      }
    })

    const newInsurancesTabs = newInsurances.map((_insurance, idx) => {
      const name = `New Insurance ${idx || ''}`

      return {
        key: `new-${idx}`,
        name,
        content: (
          <CreateInsuranceForm
            patientId={patient.id}
            memberName={formatDisplayName(patient)}
            memberBirthDate={patient.birthDate}
            relationships={patient.patientRelatedPersonRelationships}
            memberCaregiverOptedOutAt={patient.caregiverOptedOutAt}
            displayName={name}
            onCancel={() => removeTab(idx)}
            onComplete={() => removeTab(idx)}
          />
        ),
      }
    })

    return [...existingInsuranceTabs, ...newInsurancesTabs]
  }, [newInsurances, patient])

  if (patient.insuranceOptedOutAt) return null

  if (insuranceTabs.length == 0) {
    return (
      <StackView justifyContent="center" alignItems="center" className="h-56">
        <NoSymbolIcon className="h-8 w-8 text-gray-400" />
        <Space space={50} />
        <Typography textStyle="subtitle">No insurance</Typography>
        <Typography color="text-base-color-fg-muted" className="text-center">
          Try contacting the patient and/or caregivers to enter this
          information.
        </Typography>
        <Space space={100} />
        <Button onClick={addNewInsurance}>Add Insurance</Button>
      </StackView>
    )
  }

  return (
    <Tabs tabs={insuranceTabs} style="button">
      <StackView
        direction="row"
        justifyContent="end"
        fullWidth={false}
        className="grow"
      >
        <Button onClick={addNewInsurance}>Add Insurance</Button>
      </StackView>
    </Tabs>
  )
}

export default InsurancesTabs
