import { useMemo } from 'react'

import maxBy from 'lodash/maxBy'
import { FindPatientLabs } from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { OBSERVATION_FRAGMENT } from '../../PatientVisits/fragments'

const LABS_QUERY = gql`
  query FindPatientLabs($id: String!) {
    patient(id: $id) {
      id
      labs {
        ...ObservationFragment
      }
    }
  }
  ${OBSERVATION_FRAGMENT}
`

export const useLabsQuery = (patientId: string) => {
  const { data, loading, error } = useQuery<FindPatientLabs>(LABS_QUERY, {
    variables: { id: patientId },
  })

  const labsLastUpdated = useMemo(() => {
    return data?.patient?.labs?.length > 0
      ? maxBy(data.patient.labs, (a) => a.updatedAt).updatedAt
      : null
  }, [data])

  return {
    labs: data?.patient?.labs,
    labsLastUpdated,
    loading,
    error,
  }
}
