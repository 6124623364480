import { Ref } from 'react'

import { ArrowUpTrayIcon } from '@heroicons/react/20/solid'
import clsx from 'clsx'

import { FileField, Label, useFormContext } from '@redwoodjs/forms'

import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'

import { RWInputFieldProps } from '../InputField'

const FileInput = React.forwardRef<
  HTMLInputElement,
  RWInputFieldProps & { ref?: Ref<HTMLInputElement> }
>((props, ref) => {
  const { watch } = useFormContext()
  const files = watch(props.name)

  return (
    <div className="relative mt-5 md:col-span-2 md:mt-0">
      <div className="sm:overflow-hidden sm:rounded-md">
        <div>
          <div
            className={clsx(
              'mt-1 flex justify-center rounded-md border-2',
              'border-dashed border-gray-300 px-6 pb-6 pt-5'
            )}
          >
            <div className="w-100 relative flex-col space-y-1 text-center">
              {files?.length ? (
                files.length === 1 ? (
                  <FileDisplay
                    url={URL.createObjectURL(files[0])}
                    name={files[0].name}
                    type={files[0].type}
                  />
                ) : (
                  <div className="flex flex-1 flex-col">
                    <Typography
                      textStyle="subtitle"
                      className="underline"
                    >{`${files.length} file selected`}</Typography>
                  </div>
                )
              ) : null}
              <div className="inline-flex text-sm text-gray-600">
                <Label
                  name={props.name}
                  className={clsx(
                    'relative cursor-pointer rounded-md font-medium text-primary',
                    'focus-within:outline-none focus-within:ring-1 focus-within:ring-primary focus-within:ring-offset-2 hover:text-primary-dark'
                  )}
                >
                  <StackView direction="row" space={25}>
                    <ArrowUpTrayIcon className="h-5 w-5 text-gray-400" />
                    <span>Upload a file or drag and drop</span>
                  </StackView>
                </Label>
              </div>
              <p className="text-xs text-gray-500">PDF, PNG, JPG up to 50MB</p>
            </div>
          </div>
        </div>
      </div>
      <FileField
        ref={ref}
        className="absolute left-0 top-0 h-full w-full opacity-0"
        name={props.name}
        data-testid="file-input"
        {...props}
      />
    </div>
  )
})

export default FileInput
