export const LIVES_ALONE_MIN_AGE = 13
export const PERSONAL_CONTACT_INFORMATION_MIN_AGE = 13
export const EMANCIPATED_MINOR_MIN_AGE = 13
export const EMANCIPATED_MINOR_MAX_AGE = 17
export const INDEPENDENT_ADULT_MIN_AGE = 18

const checkAge = (age: Duration) => ({
  canLiveAlone: LIVES_ALONE_MIN_AGE <= age.years,
  canHavePersonalContactInformation:
    PERSONAL_CONTACT_INFORMATION_MIN_AGE <= age.years,
  canBeEmancipatedMinor:
    EMANCIPATED_MINOR_MIN_AGE <= age.years &&
    age.years <= EMANCIPATED_MINOR_MAX_AGE,
  canBeindependentAdult: INDEPENDENT_ADULT_MIN_AGE <= age.years,
})

export default checkAge
