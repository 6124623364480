import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import { formatMoneyInCents } from 'common/utils'

import Box from 'src/components/atoms/Box'
import Button from 'src/components/atoms/Button'
import Divider from 'src/components/atoms/Divider'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import { Tooltip } from 'src/components/molecules/Tooltip/Tooltip'
import { PatientMembershipPlanCard } from 'src/components/PatientMembershipPlanCard/PatientMembershipPlanCard'
import PatientPaymentPlanBalanceCard from 'src/components/PatientPaymentPlanBalanceCard/PatientPaymentPlanBalanceCard'
import { usePatientOutstandingBalance } from 'src/hooks/usePatientPayments/usePatientPayments'

export const BalanceDisplay = ({
  testId,
  balance,
  cardTitle,
  tooltipTitle,
  tooltipText,
}: {
  testId: string
  balance: number
  cardTitle: string
  tooltipTitle: string
  tooltipText: string
}) => {
  return (
    <StackView space={25} className="p-core-space-75" testId={testId}>
      <StackView direction="row" alignItems="center" justifyContent="between">
        <Typography
          textStyle="interface-strong-xs"
          color="text-base-color-fg-subtle"
        >
          {cardTitle}
        </Typography>
        <Tooltip title={tooltipTitle} text={tooltipText} maxWidth={200}>
          <Button
            buttonSize="s"
            buttonStyle="ghost"
            icon={QuestionMarkCircleIcon}
          />
        </Tooltip>
      </StackView>
      <Typography
        textStyle="interface-strong-l"
        color={
          balance > 0
            ? 'text-base-color-fg-danger'
            : 'text-base-color-fg-success'
        }
      >
        {formatMoneyInCents(balance)}
      </Typography>
    </StackView>
  )
}

const PatientBalanceSection = ({ patientId }: { patientId: string }) => {
  const {
    totalOutstandingBalanceCents,
    candidBalanceTotalCents,
    currentPaymentPlan,
    currentMembershipPlan,
    loading,
  } = usePatientOutstandingBalance({
    patientId,
  })

  if (loading) return <LoadingSpinner />

  return (
    <Box border rounded>
      <StackView>
        <StackView direction="row" divider>
          <BalanceDisplay
            testId="total-balance"
            balance={totalOutstandingBalanceCents}
            cardTitle="Total balance"
            tooltipTitle="Total balance"
            tooltipText="The total current balance which includes the balance remaining on a payment plan and any additional charges."
          />

          {currentPaymentPlan && candidBalanceTotalCents > 0 && (
            <BalanceDisplay
              testId="additional-balance"
              balance={candidBalanceTotalCents}
              cardTitle="Additional balance"
              tooltipTitle="Additional balance"
              tooltipText="Pay off the total balance or the additional balance or cancel the current and create a new payment plan."
            />
          )}
        </StackView>

        {currentMembershipPlan && (
          <>
            <Divider />

            <PatientMembershipPlanCard
              plan={currentMembershipPlan}
              border={false}
            />
          </>
        )}

        {currentPaymentPlan && (
          <>
            <Divider />

            <PatientPaymentPlanBalanceCard
              patientPaymentPlanId={currentPaymentPlan.id}
              cardTitle="Payment plan"
              border={false}
            />
          </>
        )}
      </StackView>
    </Box>
  )
}

export default PatientBalanceSection
