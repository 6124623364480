import SelectField, {
  RWSelectFieldProps,
} from 'src/components/atoms/SelectField/SelectField'
import { placeOfServiceOptions, placeOfServices } from 'src/data/placeOfService'

export const PlaceOfServiceSelectField = (props: RWSelectFieldProps) => {
  return (
    <SelectField
      {...props}
      options={placeOfServiceOptions
        .filter(({ value }) => placeOfServices[value].isPriority)
        .map(({ name, value }) => ({
          value,
          display: name,
        }))}
    />
  )
}

export default PlaceOfServiceSelectField
