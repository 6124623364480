import { useEffect, useState } from 'react'

import { useParams } from 'react-router-dom'

import { useParams as useRedwoodParams } from '@redwoodjs/router'

import { useEmrAuth } from 'src/auth'
import Box from 'src/components/atoms/Box'
import FileDisplay from 'src/components/atoms/FileDisplay/FileDisplay'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { usePatientDetailedBillingStatementQuery } from 'src/hooks/usePatientBillingStatement/usePatientBillingStatement'
import PrintPatientDetailedBillingStatement from 'src/pdf/components/PrintPatientDetailedBillingStatement'
import { useGeneratePDF } from 'src/pdf/useGeneratePDF'
import { isCI, isDev } from 'src/utils'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

const SidepanelBillingStatement = () => {
  const { currentUser } = useEmrAuth()
  const { patientId } = useParams()
  const { testing } = useRedwoodParams()

  const {
    data: detailedBillingStatementData,
    loading: detailedBillingStatementLoading,
  } = usePatientDetailedBillingStatementQuery({
    patientId,
  })

  const [isGenerating, setIsGenerating] = useState(false)
  const generatePDF = useGeneratePDF()
  const [generated, setGenerated] = useState<URL>()

  useEffect(() => {
    if (detailedBillingStatementData) {
      setIsGenerating(true)
      void generatePDF({
        component: (
          <PrintPatientDetailedBillingStatement
            billingStatement={
              detailedBillingStatementData?.patient?.billingStatement
            }
          />
        ),
        callback: (doc) => {
          setIsGenerating(false)
          setGenerated(doc.output('bloburl'))
        },
      })
    }
  }, [generatePDF, detailedBillingStatementData, currentUser.featureFlags])

  if (!patientId) return <SidepanelNotAvailable />

  if (detailedBillingStatementLoading) return <LoadingSpinner />

  if (!detailedBillingStatementData) return null

  return (
    <SidepanelPage
      header="Billing statement"
      description="Please review the billing statement with the patient and/or caregiver(s) for the outstanding balance outlined below."
    >
      <StackView className="h-full" space={50}>
        {isGenerating ? (
          <Box padding={100}>
            <LoadingSpinner />
          </Box>
        ) : !isGenerating && testing && (isDev || isCI) ? (
          // This should only display when we are testing the page on dev
          <PrintPatientDetailedBillingStatement
            billingStatement={
              detailedBillingStatementData?.patient?.billingStatement
            }
            testId="print-billing-statement"
          />
        ) : (
          <FileDisplay
            type="application/pdf"
            url={generated}
            showDownloadLink={false}
          />
        )}
      </StackView>
    </SidepanelPage>
  )
}

export default SidepanelBillingStatement
