import { useRef, useState } from 'react'

import { ColDef } from 'ag-grid-community'
import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { formatPhoneNumber } from 'react-phone-number-input'
import {
  ListAppointmentNotifications,
  ListAppointmentNotificationsVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { OutboundTextMessageStatusBadge } from 'src/components/molecules/OutboundTextMessageStatusBadge/OutboundTextMessageStatusBadge'
import Table from 'src/components/molecules/Table/Table'

const QUERY = gql`
  query ListAppointmentNotifications(
    $appointmentId: String!
    $pagination: OffsetPaginationInput
  ) {
    appointmentNotifications(
      appointmentId: $appointmentId
      pagination: $pagination
    ) {
      count
      pageInfo {
        pageCount
        currentPage
        hasNextPage
        limit
      }
      appointmentNotifications {
        id
        contactInformation {
          id
          patient {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
          }
          relatedPerson {
            id
            namePrefix
            givenName
            middleName
            familyName
            nameSuffix
          }
        }
        outboundTextMessage {
          id
          finishedAt
          createdAt
          mobileNumber
          status
          errorCode
        }
        sentAutomatically
        sentByUser {
          id
          givenName
          familyName
        }
      }
    }
  }
`

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    wordBreak: 'break-word',
  },
  headerClass: 'develo-ag-header',
  resizable: true,
  autoHeight: true,
  wrapText: true,
}

type RowData =
  ListAppointmentNotifications['appointmentNotifications']['appointmentNotifications'][number]

interface ColumnDef extends ColDef<RowData> {
  cellRenderer?: (args: { data: RowData }) => React.ReactNode
}
const columnDefinitions: ColumnDef[] = [
  {
    colId: 'date',
    headerName: 'Date and time',
    minWidth: 200,
    cellRenderer: ({ data }) => {
      const sentAt =
        data.outboundTextMessage.finishedAt ??
        data.outboundTextMessage.createdAt
      return (
        <StackView>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {format(new Date(sentAt), "MM/dd/yyyy 'at' hh:mm aa")}
          </Typography>

          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {data.sentAutomatically
              ? 'Sent automatically'
              : data.sentByUser
                ? `Sent manually by ${formatDisplayName(data.sentByUser)}`
                : 'Sent manually'}
          </Typography>
        </StackView>
      )
    },
  },
  {
    colId: 'recipient',
    headerName: 'Recipient',
    cellRenderer: ({ data }) => {
      const recipient =
        data.contactInformation?.relatedPerson ??
        data.contactInformation?.patient

      return (
        <StackView>
          <Typography
            textStyle="interface-default-s"
            color="text-base-color-fg-muted"
          >
            {formatDisplayName(recipient)}
          </Typography>

          <Typography
            textStyle="interface-default-xs"
            color="text-base-color-fg-subtle"
          >
            {formatPhoneNumber(data.outboundTextMessage.mobileNumber)}
          </Typography>
        </StackView>
      )
    },
  },

  {
    colId: 'status',
    headerName: 'Status',
    maxWidth: 180,
    cellRenderer: ({ data }) => (
      <OutboundTextMessageStatusBadge
        outboundTextMessage={data.outboundTextMessage}
      />
    ),
  },
]

export const AppointmentNotificationHistoryTable = ({
  appointmentId,
}: {
  appointmentId: string
}) => {
  const [page, setPage] = useState(1)

  const { data, loading } = useQuery<
    ListAppointmentNotifications,
    ListAppointmentNotificationsVariables
  >(QUERY, {
    variables: {
      appointmentId,
      pagination: {
        page,
      },
    },
    skip: !appointmentId,
  })

  const gridRef = useRef()

  const rowData = data?.appointmentNotifications?.appointmentNotifications ?? []
  const pageInfo = data?.appointmentNotifications?.pageInfo

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Table
      testId="appointment-notification-history-table"
      innerRef={gridRef}
      rowData={rowData}
      columnDefs={columnDefinitions}
      rowHeight={72}
      headerHeight={36}
      domLayout="autoHeight"
      defaultColDef={defaultColDef}
      page={page}
      totalPages={pageInfo?.pageCount}
      pagination
      onClickPaginationButton={(newPage) => {
        setPage(newPage + 1)
      }}
      useCustomPaginationPanel
    />
  )
}
