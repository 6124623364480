import { Fragment } from 'react'

import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { formatDisplayName, formatMoneyInCents } from 'common/utils'
import type {
  FindAppointmentDetails,
  FindAppointmentDetailsVariables,
} from 'types/graphql'

import type { CellSuccessProps, CellFailureProps } from '@redwoodjs/web'

import AppointmentConfirmationSelect from 'src/components/atoms/AppointmentConfirmationSelect/AppointmentConfirmationSelect'
import AppointmentRoomSelect from 'src/components/atoms/AppointmentRoomSelect/AppointmentRoomSelect'
import AppointmentStatusSelect from 'src/components/atoms/AppointmentStatusSelect'
import Box from 'src/components/atoms/Box'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography/Typography'
import { PatientMembershipCheckmark } from 'src/components/PatientMembershipCheckmark/PatientMembershipCheckmark'
import { isAppointmentCheckedIn } from 'src/data/appointmentStatus'
import { insuranceOptOutStatusDisplay } from 'src/data/insuranceOptOutStatuses'
import { usePatientOutstandingBalance } from 'src/hooks/usePatientPayments/usePatientPayments'
import { formatAge, formatDateDisplay } from 'src/lib/formatters'
import { PATIENT_STATUS_FRAGMENT } from 'src/pages/PatientChartsPage/PatientDemographics/fragments'
import {
  InsuranceCoverageLineItem,
  sortInsuranceCoverages,
} from 'src/utils/insuranceCoverages'

import { PatientStatusBadge } from '../PatientStatusBadge/PatientStatusBadge'

export const QUERY = gql`
  query FindAppointmentDetails($id: String!) {
    appointment: appointment(id: $id) {
      id
      start
      end
      chiefComplaints
      visitComment
      status
      chartingStatus
      isPatientRegistered
      confirmedAt
      location {
        id
        name
        timezone
        rooms {
          id
          name
        }
      }
      room {
        id
      }
      patientId
      practitioner {
        id
        namePrefix
        nameSuffix
        givenName
        middleName
        familyName
      }
      patientId
      practitioner {
        id
        namePrefix
        nameSuffix
        givenName
        middleName
        familyName
      }
      patient {
        id
        ...PatientStatusFragment
        namePrefix
        nameSuffix
        givenName
        middleName
        familyName
        preferredName
        sexAtBirth
        birthDate
        insuranceOptedOutAt
        insuranceOptOutStatus
        copayCents
        activeMembershipPlan {
          id
          startDate
          endDate
        }
        currentPaymentPlan {
          id
          remainingBalanceCents
        }
        activeInsuranceCoverages {
          id
          status
          startDate
          planName
          coordinationOfBenefitsType
          payer {
            id
            displayName
          }
          mostRecentEligibility {
            id
            status
          }
        }
      }
      patientRegistrationIntent {
        id
        phoneNumber
        email
        givenName
        familyName
        sexAtBirth
        birthDate
      }
      patientFacingTasks {
        __typename
        ... on PatientCheckInTask {
          id
          isComplete
          completedAt
          lastSentAt
          lastSentToNumber
        }
        ... on PatientRegistrationTask {
          id
          isComplete
          completedAt
          lastSentAt
          lastSentToNumber
        }
        ... on PatientScreeningTask {
          id
          isComplete
          questionnaireName
          questionnaireCode
          questionnaireIntendedFor
          completedAt
          lastSentAt
          lastSentToNumber
        }
      }
      appointmentDefinitions {
        id
        name
        code
        type
      }
    }
  }
  ${PATIENT_STATUS_FRAGMENT}
`

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindAppointmentDetailsVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

const PatientOutstandingBalance = ({
  patientId,
  hasPaymentPlan,
}: {
  patientId: string
  hasPaymentPlan: boolean
}) => {
  const { totalOutstandingBalanceCents, candidBalanceTotalCents, loading } =
    usePatientOutstandingBalance({ patientId })

  if (loading) {
    return <LoadingSpinner size="xs" />
  }

  const balance = hasPaymentPlan
    ? candidBalanceTotalCents
    : totalOutstandingBalanceCents

  const formattedBalance = formatMoneyInCents(balance, { defaultValue: '-' })

  return (
    <StackView direction="row" alignItems="center" space={25}>
      <Typography
        textStyle={balance ? 'interface-strong-s' : 'interface-default-s'}
        color={
          balance > 0
            ? 'text-base-color-fg-danger'
            : balance < 0
              ? 'text-base-color-fg-success'
              : 'text-base-color-fg-muted'
        }
      >
        {formattedBalance}
      </Typography>

      {balance > 0 ? (
        <ExclamationCircleIcon
          className="h-base-size-icon-xs w-base-size-icon-xs fill-base-color-fg-danger"
          aria-hidden="true"
        />
      ) : null}
    </StackView>
  )
}

export const Success = ({
  appointment,
}: CellSuccessProps<
  FindAppointmentDetails,
  FindAppointmentDetailsVariables
>) => {
  const patient = appointment.isPatientRegistered
    ? appointment.patient
    : appointment.patientRegistrationIntent

  return (
    <StackView data-testid="side-panel-appointment-details" space={50}>
      <StackView
        direction="row"
        alignItems="center"
        gap={50}
        justifyContent="between"
        wrap
      >
        <StackView fullWidth={false} space={25} alignItems="start">
          {appointment.patientRegistrationIntent && (
            <Typography noWrap color="text-base-color-fg-brand" size="xs">
              New patient
            </Typography>
          )}
          {appointment.patient ? (
            <PatientStatusBadge patient={appointment.patient} />
          ) : null}
          <StackView
            direction="row"
            space={50}
            alignItems="center"
            fullWidth={false}
          >
            <Typography
              className="leading-8 text-gray-900"
              textStyle="title"
              fontWeight="bold"
            >
              {formatDisplayName(patient, { showPreferredName: true })}
            </Typography>
            {appointment.patient ? (
              <PatientMembershipCheckmark patient={appointment.patient} />
            ) : null}
          </StackView>
        </StackView>
        <StackView direction="row" space={50} fullWidth={false}>
          <Box>
            {isAppointmentCheckedIn(appointment.status) ? (
              <AppointmentRoomSelect appointmentId={appointment.id} />
            ) : (
              <AppointmentConfirmationSelect
                appointmentId={appointment.id}
                confirmedAt={appointment.confirmedAt}
              />
            )}
          </Box>
          <Box>
            <AppointmentStatusSelect appointmentId={appointment.id} />
          </Box>
        </StackView>
      </StackView>

      <StackView className="flex-grow flex-wrap" direction="row" space={50}>
        <Typography color="text-base-color-fg-muted">
          {`${patient.sexAtBirth[0]}${patient.sexAtBirth
            .slice(1)
            .toLowerCase()}`}
        </Typography>
        <Typography color="text-base-color-fg-muted">∙</Typography>
        <Typography
          color="text-base-color-fg-muted"
          className="whitespace-nowrap"
        >
          {formatAge(patient.birthDate)} ({formatDateDisplay(patient.birthDate)}
          )
        </Typography>
        <Typography color="text-base-color-fg-muted">∙</Typography>
        <Typography
          color="text-base-color-fg-muted"
          className="whitespace-nowrap"
        >
          {`Co-pay: ${formatMoneyInCents(
            (patient as FindAppointmentDetails['appointment']['patient'])
              .copayCents,
            { defaultValue: '-' }
          )}`}
        </Typography>
        <Typography color="text-base-color-fg-muted">∙</Typography>
        {appointment.patient?.currentPaymentPlan ? (
          <>
            <Typography
              color="text-base-color-fg-muted"
              className="whitespace-nowrap"
            >
              {`Payment plan: ${formatMoneyInCents(
                appointment.patient.currentPaymentPlan.remainingBalanceCents,
                { defaultValue: '-' }
              )}`}
            </Typography>
            <Typography color="text-base-color-fg-muted">∙</Typography>
          </>
        ) : null}
        {appointment.patient ? (
          <StackView
            direction="row"
            space={25}
            testId="appointment-header-outstanding-balance"
            fullWidth={false}
          >
            <Typography
              color="text-base-color-fg-muted"
              className="whitespace-nowrap"
            >
              {appointment.patient.currentPaymentPlan
                ? 'Add. balance:'
                : 'Outstanding Balance:'}
            </Typography>
            <PatientOutstandingBalance
              patientId={appointment.patient.id}
              hasPaymentPlan={!!appointment.patient.currentPaymentPlan}
            />
          </StackView>
        ) : null}
      </StackView>
      {appointment.isPatientRegistered && (
        <StackView direction="row" wrap gap={50}>
          {appointment.patient.insuranceOptedOutAt ? (
            <Typography>
              {
                insuranceOptOutStatusDisplay[
                  appointment.patient.insuranceOptOutStatus
                ]
              }
            </Typography>
          ) : (
            sortInsuranceCoverages(
              appointment.patient.activeInsuranceCoverages
            ).map((coverage, index) => (
              <Fragment key={coverage.id}>
                <InsuranceCoverageLineItem
                  coverage={coverage}
                  fullWidth={false}
                />
                {index <
                  appointment.patient.activeInsuranceCoverages.length - 1 && (
                  <Typography color="text-base-color-fg-muted">∙</Typography>
                )}
              </Fragment>
            ))
          )}
        </StackView>
      )}
    </StackView>
  )
}
