import { useEffect } from 'react'

import clsx from 'clsx'
import { isJSONString } from 'common/utils'
import { useParams } from 'react-router-dom'
import { Sex } from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import Box from 'src/components/atoms/Box'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { RadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from 'src/components/molecules/SidepanelPage/SidepanelPage'
import { completeOrganSystemDisplay } from 'src/data/organSystems'
import { rosSystemFindings } from 'src/data/rosSystemFindings'
import useIsPatientVisit from 'src/hooks/useIsPatientVisit/useIsPatientVisit'
import { ReviewOfSystemFinding } from 'src/pages/PatientChartsPage/PatientVisits/pages/DocumentationPage/ReviewOfSystemsSection'
import {
  useUpdateAppointmentDocumentationMutation,
  useVisitQuery,
} from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import {
  useUpdateVisitTemplateRosMutation,
  useVisitTemplateQuery,
} from 'src/pages/VisitTemplatePage/useVisitTemplates'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

import SidepanelNotAvailable from '../SidepanelNotAvailable/SidepanelNotAvailable'

type reviewOfSystemResult = 'reports' | 'denies' | 'na'
type FormData = {
  [key: string]: reviewOfSystemResult
}

const buildDefaultValues = (
  options: string[],
  finding: ReviewOfSystemFinding
) => {
  if (!options) return {}

  const defaultValues = options.reduce((acc, option) => {
    acc[option] = 'na'
    return acc
  }, {} as FormData)

  finding?.denies?.forEach((item) => {
    defaultValues[item] = 'denies'
  })

  finding?.reports?.forEach((item) => {
    defaultValues[item] = 'reports'
  })

  return defaultValues
}

const ReviewOfSystemsEditDisplay = ({
  onSubmit,
  submitting,
  reviewOfSystems,
  patientSexAtBirth,
  renderSidepanelNotAvailable,
}: {
  onSubmit: (data: FormData) => void
  submitting: boolean
  reviewOfSystems: unknown
  patientSexAtBirth?: Sex
  renderSidepanelNotAvailable: boolean
}) => {
  const { system } = useParams()

  const systemFindingOptions =
    rosSystemFindings[
      system === 'GU'
        ? patientSexAtBirth === 'FEMALE'
          ? 'GU_F'
          : 'GU_M'
        : system
    ]

  const formMethods = useForm<FormData>({
    defaultValues: {
      ...buildDefaultValues(systemFindingOptions, reviewOfSystems[system]),
    },
  })

  const findings = formMethods.watch()
  useEffect(() => {
    formMethods.reset({
      ...buildDefaultValues(systemFindingOptions, reviewOfSystems[system]),
    })
    // want this effect to only happen when the system changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system])

  if (renderSidepanelNotAvailable) {
    return <SidepanelNotAvailable />
  }

  return (
    <SidepanelPage
      key={system}
      testId="sidepanel-order-create"
      header={completeOrganSystemDisplay[system]}
      description="Review and record your findings below"
    >
      <SidepanelForm
        footerProps={{
          submitText: 'Save and close',
          submitting: submitting,
        }}
        onSubmit={onSubmit}
        formMethods={formMethods}
      >
        <Box grow>
          {systemFindingOptions.map((option: string) => {
            const value = findings[option]
            return (
              <StackView
                data-testid="ros-finding-input"
                key={option}
                direction="row"
                justifyContent="between"
                className={clsx(
                  'mt-2 grow p-2',
                  value === 'reports'
                    ? 'bg-red-50'
                    : value === 'denies'
                      ? 'bg-green-50'
                      : 'bg-gray-50'
                )}
              >
                <Typography
                  className={
                    value === 'reports'
                      ? 'text-red-800'
                      : value === 'denies'
                        ? 'text-primary-dark'
                        : 'text-gray-700'
                  }
                >
                  {option}
                </Typography>
                <StackView
                  direction="row"
                  justifyContent="end"
                  space={75}
                  fullWidth={false}
                >
                  <RadioButtonField
                    name={option}
                    value="reports"
                    label="Reports"
                    className="!text-danger"
                    labelClassName={clsx(
                      '!ml-2',
                      value === 'reports' ? '!text-danger' : '!text-gray-400'
                    )}
                  />
                  <RadioButtonField
                    name={option}
                    value="denies"
                    label="Denies"
                    className="!text-success"
                    labelClassName={clsx(
                      '!ml-2',
                      value === 'denies' ? '!text-success' : '!text-gray-400'
                    )}
                  />
                  <RadioButtonField
                    name={option}
                    value="na"
                    label="N/A"
                    className="!text-gray-500"
                    labelClassName={clsx(
                      '!ml-2',
                      value === 'na' ? '!text-gray-700' : '!text-gray-400'
                    )}
                  />
                </StackView>
              </StackView>
            )
          })}
        </Box>
      </SidepanelForm>
    </SidepanelPage>
  )
}

export const SidepanelVisitReviewOfSystemEdit = () => {
  const { appointmentId, isPatientVisit } = useIsPatientVisit()
  const { system } = useParams()
  const { visit } = useVisitQuery(appointmentId)

  const reviewOfSystems = visit?.reviewOfSystems ?? {}

  const [
    updateAppointmentDocumentation,
    { loading: updatingAppointmentDocumentation },
  ] = useUpdateAppointmentDocumentationMutation()
  const { closeSidePanel } = useSidepanel()

  const onSubmit = (data: FormData) => {
    const json = JSON.stringify({
      ...(reviewOfSystems as object),
      [system]: {
        reports: Object.keys(data).filter((key) => data[key] === 'reports'),
        denies: Object.keys(data).filter((key) => data[key] === 'denies'),
      },
    })
    updateAppointmentDocumentation({
      variables: {
        input: {
          visitId: visit?.id,
          value: json,
          documentationType: 'REVIEW_OF_SYSTEMS',
        },
      },
      onCompleted: () => {
        toast.success('ROS updated')
        closeSidePanel()
      },
    })
  }

  return (
    <ReviewOfSystemsEditDisplay
      onSubmit={onSubmit}
      submitting={updatingAppointmentDocumentation}
      reviewOfSystems={reviewOfSystems}
      patientSexAtBirth={visit?.patient?.sexAtBirth}
      renderSidepanelNotAvailable={
        visit && (!isPatientVisit || !completeOrganSystemDisplay[system])
      }
    />
  )
}

export const SidepanelTemplateReviewOfSystemEdit = () => {
  const { system, templateId } = useParams()
  const { template } = useVisitTemplateQuery(templateId)

  const reviewOfSystems =
    template?.reviewOfSystems && isJSONString(template?.reviewOfSystems)
      ? JSON.parse(template.reviewOfSystems.toString())
      : template?.reviewOfSystems
        ? template.reviewOfSystems
        : {}

  const [updateVisitTemplateRos, { loading: updatingVisitTemplateRos }] =
    useUpdateVisitTemplateRosMutation()
  const { closeSidePanel } = useSidepanel()

  const onSubmit = (data: FormData) => {
    const json = {
      ...reviewOfSystems,
      [system]: {
        reports: Object.keys(data).filter((key) => data[key] === 'reports'),
        denies: Object.keys(data).filter((key) => data[key] === 'denies'),
      },
    }

    updateVisitTemplateRos({
      variables: {
        id: template.id,
        reviewOfSystems: json,
      },
      onCompleted: () => {
        toast.success('ROS updated')
        closeSidePanel()
      },
    })
  }

  return (
    <ReviewOfSystemsEditDisplay
      onSubmit={onSubmit}
      submitting={updatingVisitTemplateRos}
      reviewOfSystems={reviewOfSystems}
      renderSidepanelNotAvailable={!template}
    />
  )
}
