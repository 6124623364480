import { VaccineManagementServiceProvider } from 'types/graphql'

export const isDeveloVaccineInventoryEnabled = ({
  vaccineManagementServiceProvider,
}: {
  vaccineManagementServiceProvider: VaccineManagementServiceProvider
}) => {
  return (
    vaccineManagementServiceProvider === 'DEVELO' ||
    vaccineManagementServiceProvider === 'VAXCARE_PRIVATE'
  )
}
