import { formatDisplayName } from 'common/utils'
import { format } from 'date-fns'
import { isEmpty } from 'lodash'
import type {
  FindPracticeFormQuery,
  FindPracticeFormQueryVariables,
  PracticeFormType,
} from 'types/graphql'

import { useForm } from '@redwoodjs/forms'
import { CellSuccessProps, CellFailureProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import BooleanSelectField from 'src/components/atoms/BooleanSelectField/BooleanSelectField'
import InputField from 'src/components/atoms/InputField/InputField'
import PracticeFormTypeSelectField from 'src/components/atoms/PracticeFormTypeSelectField/PracticeFormTypeSelectField'
import FormInputList from 'src/components/molecules/FormInputList/FormInputList'
import { QUERY as FIND_PRACTICE_FORM_QUERY } from 'src/components/PracticeFormCell'
import { practiceFormTypeDisplay } from 'src/data/practiceformTypes'
import { useSidepanel } from 'src/providers/context/SidepanelContext'
import { base64EncodeFile } from 'src/utils'

import FileInput from '../atoms/FileInput/FileInput'
import SidepanelForm from '../molecules/SidepanelForm/SidepanelForm'
import SidepanelPage from '../molecules/SidepanelPage/SidepanelPage'

export const QUERY = FIND_PRACTICE_FORM_QUERY

export const Loading = () => <div>Loading...</div>

export const Empty = () => <div>Empty</div>

export const Failure = ({
  error,
}: CellFailureProps<FindPracticeFormQueryVariables>) => (
  <div className="text-danger">Error: {error?.message}</div>
)

const UPDATE_PRACTICE_FORM_MUTATION = gql`
  mutation UpdatePracticeForm($id: String!, $input: UpdatePracticeFormInput!) {
    updatePracticeForm(id: $id, input: $input) {
      id
      version
      isCurrent
      updatedAt
      updatedBy {
        id
        givenName
        familyName
      }
      practiceFormDefinition {
        id
        isRequired
        type
      }
      binary {
        id
        content {
          url
          contentType
        }
      }
    }
  }
`

type FormData = {
  type?: PracticeFormType
  isRequired?: boolean
  version?: string
  files?: FileList
}

export const Success = ({
  practiceForm,
}: CellSuccessProps<FindPracticeFormQuery, FindPracticeFormQueryVariables>) => {
  const { closeSidePanel } = useSidepanel()
  const [
    updatePracticeForm,
    { loading: updatingPracticeForm, error: updatePracticeFormError },
  ] = useMutation(UPDATE_PRACTICE_FORM_MUTATION, {
    onCompleted: () => {
      toast.success(`Practice form updated`)
      closeSidePanel()
    },
    refetchQueries: ['PracticeFormsQuery'],
  })

  const formMethods = useForm<FormData>({
    defaultValues: {
      type: practiceForm.practiceFormDefinition.type,
      version: `${practiceForm.version}.0`,
      isRequired: practiceForm.practiceFormDefinition.isRequired,
    },
    shouldUnregister: true,
  })
  const {
    formState: { dirtyFields },
  } = formMethods

  const hasDirtyFields = !isEmpty(dirtyFields)

  const onSubmit = async (value: FormData) => {
    const dirtyValues: FormData = Object.fromEntries(
      Object.entries(value).filter(([key, _]) => dirtyFields[key])
    )
    const { files, ...input } = dirtyValues
    if (files !== undefined && files.length === 1) {
      input['binary'] = {
        content: await base64EncodeFile(files[0]),
        contentType: files[0].type,
      }
    }
    updatePracticeForm({
      variables: { input, id: practiceForm.id },
    })
  }

  return (
    <SidepanelPage
      testId="practice-form-edit"
      header={`Practice form: ${
        practiceFormTypeDisplay[practiceForm.practiceFormDefinition.type]
      }`}
      subHeader={`Version ${practiceForm.version} ${
        practiceForm.isCurrent && practiceForm.practiceFormDefinition.isRequired
          ? '(Required for all patients to sign)'
          : ''
      }`}
      description={`Last modified on ${format(
        new Date(practiceForm.updatedAt),
        'PP'
      )} · Last modified by ${formatDisplayName(practiceForm.updatedBy)}`}
    >
      <SidepanelForm
        footerProps={{
          disabled: !hasDirtyFields,
          submitText: 'Save',
          submitting: updatingPracticeForm,
        }}
        error={updatePracticeFormError}
        formMethods={formMethods}
        onSubmit={onSubmit}
      >
        <FormInputList
          items={[
            {
              name: 'type',
              label: 'Document sub-type',
              formInputComponent: PracticeFormTypeSelectField,
              inputProps: {
                disabled: true,
              },
              message:
                'Document sub-types cannot be edited. If this form has the wrong sub-type, please upload a new practice form.',
            },
            {
              name: 'version',
              label: 'Template version',
              inputProps: {
                disabled: true,
              },
              formInputComponent: InputField,
            },
            {
              name: 'isRequired',
              label: 'Check-in requirement',
              required: true,
              inputProps: {
                trueDisplay: 'Required',
                falseDisplay: 'Optional',
              },
              formInputComponent: BooleanSelectField,
            },
            {
              name: 'files',
              label: 'Document upload',
              formInputComponent: FileInput,
            },
          ]}
        />
      </SidepanelForm>
    </SidepanelPage>
  )
}
