import '@js-joda/timezone'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { displayAppointmentTimeRange } from 'common/data/appointments'
import { chiefComplaintDisplay } from 'common/data/chiefComplaints'
import { formatDisplayName } from 'common/utils'
import { formatPhoneNumber } from 'react-phone-number-input'
import {
  FindPractitionersAndAppointments,
  GetPatientAppointmentInsuranceInformation,
  GetPatientAppointmentInsuranceInformationVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import Box from 'src/components/atoms/Box'
import Card from 'src/components/atoms/Card'
import LoadingSpinner from 'src/components/atoms/LoadingSpinner/LoadingSpinner'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import { insuranceOptOutStatusDisplay } from 'src/data/insuranceOptOutStatuses'
import { formatAge, formatDateDisplay } from 'src/lib/formatters'
import {
  InsuranceCoverageLineItem,
  sortInsuranceCoverages,
} from 'src/utils/insuranceCoverages'

export type ScheduleEventTooltipState = {
  id: string
  title: string
  appointmentObject: FindPractitionersAndAppointments['appointments'][0]
}

const DataRow = ({ label, data }) => {
  return (
    <StackView direction="row" alignItems="start">
      <Box className="shrink-0 basis-5/12">
        <Typography>{label}</Typography>
      </Box>
      <Box>{data}</Box>
    </StackView>
  )
}

const GET_INSURANCE_INFO_QUERY = gql`
  query GetPatientAppointmentInsuranceInformation($patientId: String!) {
    patient(id: $patientId) {
      id
      insuranceOptOutStatus
      activeInsuranceCoverages {
        id
        coordinationOfBenefitsType
        payer {
          id
          displayName
        }
        mostRecentEligibility {
          id
          status
        }
      }
    }
  }
`

const PatientCard = ({
  appointment,
}: {
  appointment: FindPractitionersAndAppointments['appointments'][0]
}) => {
  const { data, loading: insuranceIsLoading } = useQuery<
    GetPatientAppointmentInsuranceInformation,
    GetPatientAppointmentInsuranceInformationVariables
  >(GET_INSURANCE_INFO_QUERY, {
    variables: {
      patientId: appointment?.patientId,
    },
    skip: !appointment?.patientId,
  })

  const insuranceOptOutStatus = data?.patient?.insuranceOptOutStatus
  const insuranceCoverages = data?.patient?.activeInsuranceCoverages ?? []

  return (
    <Card
      data-testid="schedule-event-tooltip"
      className="rounded-lg shadow"
      color="bg-base-color-bg-default"
      header={
        <StackView alignItems="center" direction="row" space={25}>
          <StackView alignItems="center" direction="row" space={25}>
            <Typography textStyle="subtitle">
              {formatDisplayName(appointment.patient)}
            </Typography>
            <CheckCircleIcon
              className={clsx(
                'h-5 w-5 flex-shrink-0',
                appointment.confirmedAt ? 'text-success' : 'text-gray-400'
              )}
            />
          </StackView>
          {appointment.patientRegistrationIntent && (
            <Typography noWrap color="text-base-color-fg-brand" size="xs">
              New patient
            </Typography>
          )}
        </StackView>
      }
    >
      <Box padding={100}>
        <StackView space={50}>
          <DataRow
            label="Age (DOB)"
            data={
              <Typography>
                {formatAge(appointment.patient.birthDate)} (
                {formatDateDisplay(appointment.patient.birthDate)})
              </Typography>
            }
          />
          <DataRow
            label="Time"
            data={
              <Typography>
                {displayAppointmentTimeRange(appointment)}
              </Typography>
            }
          />
          <DataRow
            label="Location"
            data={<Typography>{appointment.location.name}</Typography>}
          />
          <DataRow
            label="Visit type"
            data={
              <Typography>
                {appointment.appointmentDefinitions
                  .map(({ name }) => name)
                  .join(', ')}
              </Typography>
            }
          />
          <DataRow
            label="Chief complaint"
            data={
              <Typography>
                {appointment.chiefComplaints
                  .map((complaint) => chiefComplaintDisplay[complaint])
                  .join(', ')}
              </Typography>
            }
          />
          <DataRow
            label="Coverage"
            data={
              insuranceIsLoading ? (
                <LoadingSpinner />
              ) : insuranceOptOutStatus ? (
                <Typography>
                  {insuranceOptOutStatusDisplay[insuranceOptOutStatus]}
                </Typography>
              ) : (
                sortInsuranceCoverages(insuranceCoverages).map((coverage) => (
                  <InsuranceCoverageLineItem
                    key={coverage.id}
                    coverage={coverage}
                    alignItems="start"
                  />
                ))
              )
            }
          />
          <DataRow
            label="Visit comment"
            data={<Typography>{appointment.visitComment}</Typography>}
          />
        </StackView>
      </Box>
    </Card>
  )
}

const PatientRegistrationCard = ({
  appointment,
}: {
  appointment: FindPractitionersAndAppointments['appointments'][0]
}) => {
  if (!appointment.patientRegistrationIntent) return null

  const patientRegistrationIntent = appointment.patientRegistrationIntent

  return (
    <Card
      data-testid="schedule-event-tooltip"
      className="rounded-lg shadow"
      color="bg-base-color-bg-default"
      header={
        <StackView alignItems="center" direction="row" space={25}>
          <StackView alignItems="center" direction="row" space={25}>
            <Typography textStyle="subtitle">
              {formatDisplayName(patientRegistrationIntent)}
            </Typography>
            <CheckCircleIcon
              className={clsx(
                'h-5 w-5 flex-shrink-0',
                appointment.confirmedAt ? 'text-success' : 'text-gray-400'
              )}
            />
          </StackView>
          <Typography noWrap color="text-base-color-fg-brand" size="xs">
            New patient
          </Typography>
        </StackView>
      }
    >
      <Box padding={100}>
        <StackView space={50}>
          <DataRow
            label="Age (DOB)"
            data={
              <Typography>
                {formatAge(appointment.patientRegistrationIntent.birthDate)} (
                {formatDateDisplay(
                  appointment.patientRegistrationIntent.birthDate
                )}
                )
              </Typography>
            }
          />
          <DataRow
            label="Time"
            data={
              <Typography>
                {displayAppointmentTimeRange(appointment)}
              </Typography>
            }
          />
          <DataRow
            label="Location"
            data={<Typography>{appointment.location.name}</Typography>}
          />
          <DataRow
            label="Visit type"
            data={
              <Typography>
                {appointment.appointmentDefinitions
                  .map(({ name }) => name)
                  .join(', ')}
              </Typography>
            }
          />
          <DataRow
            label="Chief complaint"
            data={
              <Typography>
                {appointment.chiefComplaints
                  .map((complaint) => chiefComplaintDisplay[complaint])
                  .join(', ')}
              </Typography>
            }
          />
          <DataRow
            label="Contact details"
            data={
              <StackView space={50}>
                <Typography>
                  {formatPhoneNumber(patientRegistrationIntent.phoneNumber)}
                </Typography>
                <Typography>{patientRegistrationIntent.email}</Typography>
              </StackView>
            }
          />
          <DataRow
            label="Visit comment"
            data={<Typography>{appointment.visitComment}</Typography>}
          />
        </StackView>
      </Box>
    </Card>
  )
}

const ScheduleEventTooltip = ({
  tooltipData,
}: {
  tooltipData: ScheduleEventTooltipState
}) => {
  const appointment = tooltipData?.appointmentObject

  return (
    <div
      id="floating"
      style={{
        position: 'fixed',
        zIndex: 999,
        boxSizing: 'border-box',
        width: 420,
        visibility: tooltipData ? 'visible' : 'hidden',
      }}
    >
      {tooltipData &&
        tooltipData.id !== 'creating-appointment' &&
        appointment && (
          <div>
            {appointment.isPatientRegistered ? (
              <PatientCard appointment={appointment} />
            ) : (
              <PatientRegistrationCard appointment={appointment} />
            )}
          </div>
        )}
      <Box
        id="arrow"
        style={{
          position: 'absolute',
          width: 0,
          height: 0,
          borderStyle: 'solid',
          borderWidth: '0 8px 8px 8px',
          borderColor: 'transparent transparent white transparent',
          zIndex: 9999,
        }}
      />
    </div>
  )
}

export default ScheduleEventTooltip
