import { useRef } from 'react'

import { ColDef } from 'ag-grid-community'
import { dateTimeFormatter, jsDateToLocalDate } from 'common/data/date'
import { formatDisplayName } from 'common/utils'
import { compareDesc } from 'date-fns'
import {
  ListPatientHospitalVisits,
  ListPatientHospitalVisitsVariables,
} from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'
import { useQuery } from '@redwoodjs/web'

import Badge from 'src/components/atoms/Badge/Badge'
import StackView from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'
import Table from 'src/components/molecules/Table/Table'
import {
  EncounterPrimaryDiagnosisColumn,
  EncounterAdditionalDiagnosisColumn,
} from 'src/components/organisms/ScheduleTable/config'

const QUERY = gql`
  query ListPatientHospitalVisits($patientId: String!) {
    patient(id: $patientId) {
      id
      hospitalVisits {
        id
        claim {
          id
          admissionDate
          dischargeDate
          submittedAt
          renderingProvider {
            id
            practitioner {
              id
              givenName
              familyName
            }
          }
          encounter {
            id
            primaryDiagnosis {
              id
              code {
                code
                display
                system
              }
            }
            additionalDiagnoses {
              id
              code {
                code
                display
                system
              }
            }
          }
        }
      }
    }
  }
`

const defaultColDef: ColDef = {
  cellStyle: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
  },
  cellClass: 'cursor-pointer truncate',
  resizable: true,
  autoHeight: true,
}

export const HospitalVisitsTable = ({ patientId }: { patientId: string }) => {
  const gridRef = useRef()
  const { data } = useQuery<
    ListPatientHospitalVisits,
    ListPatientHospitalVisitsVariables
  >(QUERY, {
    variables: {
      patientId,
    },
  })

  const visits = (data?.patient?.hospitalVisits ?? [])
    .map((visit) => ({
      ...visit,
      encounter: visit.claim.encounter,
    }))
    .sort((a, b) => {
      if (!a.claim.dischargeDate) return -1
      if (!b.claim.dischargeDate) return 1

      return compareDesc(
        new Date(a.claim.dischargeDate),
        new Date(b.claim.dischargeDate)
      )
    })

  if (!visits.length) return null

  return (
    <StackView space={50}>
      <Typography textStyle="title" fontWeight="semibold">
        Hospital visits
      </Typography>
      <Table
        innerRef={gridRef}
        testId="hospital-visits-table"
        domLayout="autoHeight"
        rowData={visits}
        onRowClicked={(row) => {
          if (row.data.claim.submittedAt) return

          navigate(
            routes.patientChartsGlob({
              id: patientId,
              glob: `visits/hospital/${row.data.id}`,
            })
          )
        }}
        defaultColDef={defaultColDef}
        columnDefs={[
          {
            colId: 'dischargeDate',
            headerName: 'Discharge date',
            cellRenderer: ({ data }) => {
              return data.claim.admissionDate
                ? jsDateToLocalDate(data.claim.dischargeDate).format(
                    dateTimeFormatter('MM/dd/yyyy')
                  )
                : '-'
            },
          },
          {
            colId: 'renderingProvider',
            headerName: 'Visit practitioner',
            cellRenderer: ({ data }) => {
              return data.claim.renderingProvider
                ? formatDisplayName(data.claim.renderingProvider.practitioner)
                : '-'
            },
          },
          EncounterPrimaryDiagnosisColumn,
          EncounterAdditionalDiagnosisColumn,
          {
            colId: 'claimStatus',
            headerName: 'Claim status',
            minWidth: 110,
            cellRenderer: ({ data }) => {
              const isSubmitted = !!data.claim.submittedAt

              return (
                <Badge
                  testId="claim-status-badge"
                  text={isSubmitted ? 'Submitted' : 'Open'}
                  color={isSubmitted ? 'green' : 'yellow'}
                  showDot
                />
              )
            },
          },
        ]}
      />
    </StackView>
  )
}
