import { FloatingPortal, offset, useFloating } from '@floating-ui/react'
import { Popover } from '@headlessui/react'

import { Chip, ChipProps } from '../Chip/Chip'

interface PopoverChipProps {
  chipProps: Omit<ChipProps, 'onClick'>
  renderPanel: ({ close }) => React.ReactNode
}

export const PopoverChip: React.FC<PopoverChipProps> = ({
  chipProps,
  renderPanel,
}) => {
  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-end',
    middleware: [offset({ mainAxis: 4 })],
  })

  return (
    <Popover>
      <Popover.Button ref={refs.setReference}>
        {() => <Chip {...chipProps} />}
      </Popover.Button>
      <Popover.Panel>
        {({ open, close }) =>
          open ? (
            <FloatingPortal>
              <div
                style={{ ...floatingStyles, zIndex: 100 }}
                ref={refs.setFloating}
              >
                {renderPanel({ close })}
              </div>
            </FloatingPortal>
          ) : null
        }
      </Popover.Panel>
    </Popover>
  )
}
