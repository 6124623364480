import { useEffect, useMemo } from 'react'

import { unitEnumToUCUMUnitMap } from 'common/unitConverter/unitConverter'
import { compact, isEmpty, omit } from 'lodash'
import {
  Finding,
  ObservationDefinition,
  OrderWithObservationsFragment,
} from 'types/graphql'

import { RegisterOptions, useForm } from '@redwoodjs/forms'
import { toast } from '@redwoodjs/web/toast'

import { CheckboxField } from 'src/components/atoms/Checkbox'
import Divider from 'src/components/atoms/Divider'
import { DropdownField } from 'src/components/atoms/Dropdown'
import { FieldLabel } from 'src/components/atoms/Label'
import Space from 'src/components/atoms/Space'
import StackView from 'src/components/atoms/StackView'
import TextAreaField from 'src/components/atoms/TextAreaField/TextAreaField'
import Typography from 'src/components/atoms/Typography/Typography'
import FieldError from 'src/components/FieldError/FieldError'
import { InputFieldWithSelectableUnit } from 'src/components/molecules/InputFieldWithUnits/InputFieldWithUnits'
import { MultiRadioButtonField } from 'src/components/molecules/RadioButton'
import SidepanelForm from 'src/components/molecules/SidepanelForm/SidepanelForm'
import { useCompleteScreeningOrderMutation } from 'src/pages/PatientChartsPage/PatientVisits/useVisit'
import { useSidepanel } from 'src/providers/context/SidepanelContext'

export const OrderRefusal = ({
  order,
  description,
}: {
  order: OrderWithObservationsFragment
  description: string
}) => {
  return (
    <StackView space={75}>
      <Space />
      <Typography textStyle="title" fontWeight="semibold">
        {description}
      </Typography>
      <Divider />
      <StackView space={50} justifyContent="center">
        <Typography textStyle="subtitle"> Select all that apply</Typography>
        <CheckboxField
          disabled={order.doNotPerform}
          name="refusalReasons"
          value="refusalByPatient"
          label={'Refusal by the patient'}
        />
        <CheckboxField
          disabled={order.doNotPerform}
          name="refusalReasons"
          value="refusalByCaregiver"
          label={'Refusal by the caregiver'}
        />
        <CheckboxField
          disabled={order.doNotPerform}
          name="refusalReasons"
          value="uncooperativePatient"
          label={'Uncooperative patient'}
        />
        <CheckboxField
          disabled={order.doNotPerform}
          name="refusalReasons"
          value="contraindicatedForPatient"
          label={'Contraindicated for patient'}
        />
        <CheckboxField
          disabled={order.doNotPerform}
          name="refusalReasons"
          value="underCareOfAnotherProvider"
          label={'Under care of another provider'}
        />
      </StackView>
      <Divider />
    </StackView>
  )
}

const FindingInput = ({
  name,
  observationDefinition,
  hideLabel,
  disabled,
  dropdown = true,
  direction = 'row',
  validation,
}: {
  name: string
  observationDefinition: ObservationDefinition
  hideLabel?: boolean
  disabled?: boolean
  dropdown?: boolean
  direction?: 'col' | 'row'
  validation?: RegisterOptions
}) => {
  if (isEmpty(observationDefinition)) return null

  const { permittedDataType, permittedUnit, label } = observationDefinition

  return (
    <StackView space={50}>
      {!hideLabel && label && (
        <FieldLabel name={`${name}`} className="font-semibold">
          <Typography textStyle="subtitle" fontWeight="semibold">
            {label}
          </Typography>
        </FieldLabel>
      )}
      {permittedDataType === 'Quantity' && permittedUnit && (
        <InputFieldWithSelectableUnit
          disabled={disabled}
          validation={{
            shouldUnregister: true,
          }}
          name={`${name}.unitInput`}
          unitOptions={[
            {
              name: unitEnumToUCUMUnitMap[permittedUnit], // for display
              value: permittedUnit,
            },
          ]}
          setValueAsString
        />
      )}
      {permittedDataType === 'CodeableConcept' && (
        <>
          {dropdown ? (
            <DropdownField
              disabled={disabled}
              name={`${name}.answerCode`}
              options={observationDefinition.validCodedValueSet.map(
                (coding) => {
                  return { name: coding.display, value: coding.code }
                }
              )}
            />
          ) : (
            <>
              <MultiRadioButtonField
                direction={direction}
                disabled={disabled}
                labelClassName="font-normal"
                name={`${name}.answerCode`}
                validation={validation}
                values={observationDefinition.validCodedValueSet.map(
                  (coding) => {
                    return { label: coding.display, value: coding.code }
                  }
                )}
              />
              {validation ? <FieldError name={`${name}.answerCode`} /> : null}
            </>
          )}
        </>
      )}
    </StackView>
  )
}

export const OverallFindingInput = ({
  observationDefinition,
  disabled,
}: {
  observationDefinition: ObservationDefinition
  disabled?: boolean
}) => {
  return (
    <>
      <FieldLabel name={'overallFinding'}>
        <Typography textStyle="subtitle" fontWeight="semibold">
          Overall Finding
        </Typography>
      </FieldLabel>
      <FindingInput
        key={observationDefinition.id}
        disabled={disabled}
        hideLabel={true}
        name="overallFinding"
        observationDefinition={observationDefinition}
        dropdown={false}
        direction="col"
        validation={{
          validate: (value) => {
            return !value ? 'Please select an overall finding' : true
          },
        }}
      />
    </>
  )
}

const ScreeningFindings = ({
  order,
}: {
  order: OrderWithObservationsFragment
}) => {
  if (isEmpty(order) || isEmpty(order.observationDefinitions)) return null

  const { observationDefinitions } = order

  return (
    <StackView space={75}>
      <Typography textStyle="title" fontWeight="semibold">
        Findings
      </Typography>
      <Divider />
      <StackView space={50}>
        {observationDefinitions.length === 1 ? ( // Single answer - Overall Finding
          <>
            <OverallFindingInput
              observationDefinition={observationDefinitions[0]}
              disabled={order.doNotPerform}
            />
            <Divider />
          </>
        ) : (
          'No definitions for this observation'
        )}
      </StackView>
    </StackView>
  )
}

export const ScreeningNotes = ({ order, title = 'Notes' }) => {
  return (
    <StackView space={50}>
      <FieldLabel name="notes">
        <Typography textStyle="subtitle">{title}</Typography>
      </FieldLabel>
      <TextAreaField disabled={order.doNotPerform} name="notes" rows={5} />
    </StackView>
  )
}

const buildQuantityFinding = ({
  observationDefinition,
  value,
}: {
  observationDefinition: ObservationDefinition
  value: number | string
}): Finding => {
  if (isEmpty(observationDefinition)) return

  return {
    questionCode: observationDefinition.questionCode,
    unitInput: {
      value: value?.toString(),
      unit: observationDefinition.permittedUnit,
    },
  }
}

const buildCodeableConceptFinding = ({
  observationDefinition,
  value,
}: {
  observationDefinition: ObservationDefinition
  value: number | string
}): Finding => {
  if (isEmpty(observationDefinition)) return

  return {
    questionCode: observationDefinition.questionCode,
    answerCode: value?.toString(),
  }
}

const buildFinding = ({
  observationDefinition,
  value,
}: {
  observationDefinition: ObservationDefinition
  value: number | string
}): Finding => {
  if (observationDefinition.permittedDataType === 'Quantity') {
    return buildQuantityFinding({ observationDefinition, value })
  } else if (observationDefinition.permittedDataType === 'CodeableConcept') {
    return buildCodeableConceptFinding({ observationDefinition, value })
  }
  return { questionCode: observationDefinition.questionCode }
}

const buildDefaultFinding = ({
  observationDefinition,
  observationValue,
}: {
  observationDefinition: ObservationDefinition
  observationValue: number | string
}): Finding => {
  return buildFinding({
    observationDefinition,
    value: observationValue ?? '',
  })
}

const buildDefaultFindings = ({
  order,
  orderCode,
}: {
  order: OrderWithObservationsFragment
  orderCode?: string
}) => {
  if (isEmpty(order.observationDefinitions)) return []

  // for each observation definition on the order:
  // find the observation on the order following that definition
  // and retrieve its value to build the appropriate Finding
  return compact(
    order.observationDefinitions.map((observationDefinition) => {
      return {
        orderCode,
        ...buildDefaultFinding({
          observationDefinition: observationDefinition,
          observationValue: order.observations.filter((observation) => {
            return (
              observation.coding[0].code === observationDefinition.questionCode
            )
          })[0]?.value?.value,
        }),
      }
    })
  )
}

export const CompleteScreeningOrderSidepanelForm = ({
  patientId,
  encounterId,
  order,
}: {
  patientId: string
  encounterId: string
  order: OrderWithObservationsFragment
}) => {
  const [completeScreeningOrder, { loading: completingOrder }] =
    useCompleteScreeningOrderMutation()

  const { closeSidePanel } = useSidepanel()

  const defaultFormValues = useMemo(() => {
    return {
      notes:
        order.observations?.[0]?.notes ??
        order.resultsForQuestionnaires?.[0]?.notes ??
        '',
      overallFinding: buildDefaultFindings({
        order: order,
        orderCode: order.code,
      })[0],
      refusalReasons: order.refusalReasons || {
        refusalByPatient: false,
        refusalByCaregiver: false,
        uncooperativePatient: false,
        contraindicatedForPatient: false,
        underCareOfAnotherProvider: false,
      },
    }
  }, [order])

  const formMethods = useForm({
    defaultValues: defaultFormValues,
  })

  useEffect(() => {
    formMethods.reset(defaultFormValues)
  }, [defaultFormValues, formMethods])

  const refusalReasons = formMethods.watch('refusalReasons')

  const doNotPerform = Object.values(omit(refusalReasons, '__typename')).some(
    Boolean
  )

  return (
    <SidepanelForm
      footerProps={{
        submitText: 'Save',
        cancelText: order.doNotPerform ? 'Close' : 'Discard changes',
        disabled: order.doNotPerform,
        submitting: completingOrder,
      }}
      formMethods={formMethods}
      onSubmit={async (input) => {
        await completeScreeningOrder({
          variables: {
            input: {
              ...input,
              orderId: order.id,
              patientId,
              encounterId,
              refusalReasons: omit(input.refusalReasons, '__typename'),
            },
          },
          onCompleted: () => {
            toast.success('Screening results updated')
            closeSidePanel()
          },
        })
      }}
    >
      <StackView space={75}>
        <OrderRefusal order={order} description="Screening refusal" />
        {!doNotPerform && (
          <>
            <ScreeningFindings order={order} />
            <ScreeningNotes order={order} />
          </>
        )}
      </StackView>
    </SidepanelForm>
  )
}

export default CompleteScreeningOrderSidepanelForm
