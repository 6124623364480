import { GetUpdatedDoctorFirstDefaultPharmacy } from 'types/graphql'
import { PHARMACY_FRAGMENT } from 'web/src/hooks/useRefetchPatientPharmacy/useRefetchPatientPharmacy'

import { useMutation } from '@redwoodjs/web'

const MUTATION = gql`
  mutation GetUpdatedDoctorFirstDefaultPharmacy($id: String!) {
    pullDoctorFirstDefaultPharmacy(id: $id) {
      id
      defaultPharmacy {
        ...PharmacyFragment
      }
    }
  }
  ${PHARMACY_FRAGMENT}
`

const useGetUpdatedDoctorFirstDefaultPharmacy = (patientId: string) => {
  return useMutation<GetUpdatedDoctorFirstDefaultPharmacy>(MUTATION, {
    variables: { id: patientId },
  })
}

export default useGetUpdatedDoctorFirstDefaultPharmacy
