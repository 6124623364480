import { useEffect, useState } from 'react'

import { ChevronDownIcon } from '@heroicons/react/24/solid'
import {
  FindPatientVisit,
  GetSupervisingProviderForAppointment,
  GetSupervisingProviderForAppointmentVariables,
  SubmitClaimForEncounter,
  SubmitClaimForEncounterVariables,
  UpsertAndSubmitClaim,
  UpsertAndSubmitClaimVariables,
} from 'types/graphql'

import { Form, useForm } from '@redwoodjs/forms'
import { useMutation, useQuery } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { useEmrAuth } from 'src/auth'
import { useDefaultBillingProvider } from 'src/components/atoms/BillingProviderSelectField/BillingProviderSelectField'
import Button, { Submit } from 'src/components/atoms/Button/Button'
import StackView from 'src/components/atoms/StackView/StackView'
import Typography from 'src/components/atoms/Typography'
import Alert from 'src/components/molecules/Alert/Alert'
import { ToggleField } from 'src/components/molecules/Toggle/Toggle'
import PopoverButton from 'src/components/PopoverButton/PopoverButton'
import SubmitClaimLoadingModal from 'src/components/SubmitClaimLoadingModal/SubmitClaimLoadingModal'

import {
  BillingOrdersWarningModal,
  useBillingOrdersWarningConfirmation,
} from '../../BillingOrdersWarningModal'
import { CLAIM_FRAGMENT } from '../../fragments'
import {
  PendedOrdersWarningModal,
  usePendedOrdersConfirmation,
} from '../../PendedOrdersWarningModal'
import {
  useUpdateVisitFlowTabForAppointment,
  useVisit,
  useVisitQuery,
} from '../../useVisit'
import { useVisitBillingState } from '../../useVisitBillingState'

import AdditionalBillingSection from './sections/AdditionalBillingSection'
import BillingSummarySection from './sections/BillingSummarySection'
import BillingSummarySectionV2, {
  BillingSummaryFormProps,
} from './sections/BillingSummarySectionV2'
import OrderBillingSection from './sections/OrderBillingSection'
import VisitBillingSection from './sections/VisitBillingSection'

const SUBMIT_CLAIM_FOR_ENCOUNTER_MUTATION = gql`
  mutation SubmitClaimForEncounter(
    $encounterId: String!
    $input: CreateClaimInput!
  ) {
    submitClaimForEncounter(encounterId: $encounterId, input: $input) {
      id
      claim {
        ...ClaimFragment
      }
    }
  }
  ${CLAIM_FRAGMENT}
`

const UPSERT_AND_SUBMIT_CLAIM_MUTATION = gql`
  mutation UpsertAndSubmitClaim(
    $encounterId: String!
    $input: UpsertClaimInput!
  ) {
    upsertAndSubmitClaim(encounterId: $encounterId, input: $input) {
      id
      claim {
        ...ClaimFragment
      }
    }
  }
  ${CLAIM_FRAGMENT}
`

const useSubmitClaimForEncounter = () => {
  return useMutation<SubmitClaimForEncounter>(
    SUBMIT_CLAIM_FOR_ENCOUNTER_MUTATION,
    {
      onCompleted: () => {
        toast.success('Claim submitted')
      },
      onError: (error) => {
        const errorMessages = error.message.split(', ')
        const display = ['Error submitting claim', ...errorMessages].join(
          '\n * '
        )
        toast.error(display)
      },
    }
  )
}

const useUpsertAndSubmitClaim = () => {
  return useMutation<UpsertAndSubmitClaim>(UPSERT_AND_SUBMIT_CLAIM_MUTATION, {
    onCompleted: () => {
      toast.success('Claim submitted')
    },
    onError: (error) => {
      const errorMessages = error.message.split(', ')
      const display = ['Error submitting claim', ...errorMessages].join('\n * ')
      toast.error(display)
    },
  })
}

export const SUPERVISING_PROVIDER_QUERY = gql`
  query GetSupervisingProviderForAppointment($appointmentId: String!) {
    appointment(id: $appointmentId) {
      id
      defaultSupervisingPractitionerClinicalDetail {
        id
        practitioner {
          id
          givenName
          familyName
        }
      }
      shouldDefaultSupervisingAsRendering
    }
  }
`

const BillingPage = () => {
  const { currentUser } = useEmrAuth()
  const { appointmentId } = useVisit()
  const { visit } = useVisitQuery(appointmentId)

  const defaultBillingProviderId = useDefaultBillingProvider()

  const supervisingPractitionerQuery = useQuery<
    GetSupervisingProviderForAppointment,
    GetSupervisingProviderForAppointmentVariables
  >(SUPERVISING_PROVIDER_QUERY, {
    variables: {
      appointmentId: visit.id,
    },
  })

  const { renderingProviderId, supervisingProviderId, placeOfService } =
    getBillingSummaryDefaultValues({
      visit,
      defaultBillingProviderId,
      supervisingPractitioner: supervisingPractitionerQuery.data,
    })

  const [billingWarningConfirmationState, waitForBillingWarningConfirmation] =
    useBillingOrdersWarningConfirmation({
      patientId: visit?.patient?.id,
      appointmentId: visit?.id,
    })
  const [pendedOrdersConfirmationState, waitForPendedOrdersConfirmation] =
    usePendedOrdersConfirmation({
      patientId: visit?.patient?.id,
      appointmentId: visit?.id,
      orders: visit?.encounter?.orders ?? [],
    })

  useUpdateVisitFlowTabForAppointment('billing')

  const {
    data: {
      primaryDiagnosis,
      chargeItemsForVisit,
      additionalChargeItems,
      claimContainsSupportingInfo,
    },
    loading: billingStateIsLoading,
  } = useVisitBillingState()

  const [submitClaimForEncounter, { loading: submittingClaim }] =
    useSubmitClaimForEncounter()

  const [upsertAndSubmitClaim, { loading: submittingClaimV2 }] =
    useUpsertAndSubmitClaim()

  const [doNotBillButtonIsDisabled, setDoNotBillButtonIsDisabled] =
    useState(false)

  const formMethods = useForm<
    SubmitClaimForEncounterVariables | UpsertAndSubmitClaimVariables
  >()

  useEffect(() => {
    if (visit) {
      const claimHasBeenSubmitted = currentUser.featureFlags.includes(
        'UPSERT_CLAIM'
      )
        ? !!visit.encounter?.claim?.submittedAt
        : !!visit.encounter?.claim
      const patientHasNoInsurance =
        !!visit.patient?.insuranceOptedOutAt || false

      formMethods.reset({
        input: {
          claimId: visit?.encounter?.claim?.id,
          renderingProviderId,
          supervisingProviderId,
          isSelfPay: claimHasBeenSubmitted
            ? visit.encounter.claim?.isSelfPay
            : patientHasNoInsurance,
          placeOfService,
          serviceFacilityId: visit.location?.id,
          billingProviderId: defaultBillingProviderId,
          box14Date: visit?.encounter?.claim?.box14Date,
          box14Qualifier: visit?.encounter?.claim?.box14Qualifier,
          box19Content: visit?.encounter?.claim?.box19Content,
        },
        encounterId: visit?.encounter?.id,
      })

      setDoNotBillButtonIsDisabled(
        claimHasBeenSubmitted || patientHasNoInsurance
      )
    }
  }, [
    visit,
    formMethods,
    defaultBillingProviderId,
    renderingProviderId,
    supervisingProviderId,
    currentUser.featureFlags,
    placeOfService,
  ])

  if (billingStateIsLoading) return null

  if (!visit) return null

  if (!visit.encounter) {
    throw new Error('Encounter not found')
  }

  const onSubmit = async (
    data: SubmitClaimForEncounterVariables | UpsertAndSubmitClaimVariables
  ) => {
    const { confirmed: billingWarningConfirmed } =
      await waitForBillingWarningConfirmation()
    if (!billingWarningConfirmed) return

    const { confirmed: pendedOrdersWarningConfirmed } =
      await waitForPendedOrdersConfirmation()
    if (!pendedOrdersWarningConfirmed) return

    if (data.input.placeOfService !== 'INPATIENT_HOSPITAL') {
      delete data.input.admissionDate
      delete data.input.dischargeDate
    }

    if (currentUser.featureFlags.includes('UPSERT_CLAIM')) {
      await upsertAndSubmitClaim({
        variables: data,
      })
    } else {
      await submitClaimForEncounter({
        variables: data,
      })
    }
  }

  return (
    <>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <StackView space={200}>
          <StackView
            direction="row"
            justifyContent="between"
            className="sticky top-0 z-10 border-b bg-white py-4"
          >
            <Typography textStyle="heading">Billing</Typography>
            <StackView
              direction="row"
              fullWidth
              space={50}
              justifyContent="end"
              alignItems="center"
            >
              <ToggleField
                name="input.isSelfPay"
                invertSelection
                activeText="Bill health plan"
                inactiveText="Bill health plan"
                disabled={doNotBillButtonIsDisabled}
              />

              {!visit.encounter.claim?.submittedAt &&
                currentUser.roles.includes('CHARGE_CAPTURE') && (
                  <PopoverButton
                    buttonProps={{
                      text: 'Submit billing codes',
                      buttonStyle: 'primary',
                      iconPlacement: 'right',
                      icon: ChevronDownIcon,
                      disabled: !formMethods.formState.isValid,
                    }}
                    renderPanel={({ close }) => (
                      <StackView space={25} className="p-4">
                        <Typography textStyle="heading">
                          Ready to submit billing codes?
                        </Typography>
                        <Typography textStyle="description">
                          Once finalized visit billing codes are submitted, you
                          will no longer be able to add, remove, or modify
                          billing codes for the visit. Both billing codes linked
                          to new orders and diagnosis updates will no longer
                          show up in this billing section.
                        </Typography>
                        <StackView
                          direction="row"
                          justifyContent="end"
                          space={50}
                          className="py-4"
                        >
                          <Button
                            text="Cancel and review"
                            onClick={close}
                            buttonStyle="secondary"
                          />
                          <Submit
                            testId="submit-billing-codes-confirm"
                            text="Submit billing codes"
                            buttonStyle="primary"
                            loading={submittingClaim || submittingClaimV2}
                          />
                        </StackView>
                      </StackView>
                    )}
                  />
                )}
            </StackView>
          </StackView>
          {!!visit.encounter.claim?.submittedAt && (
            <Alert
              style="info"
              title="Billing codes have been submitted."
              description="You can no longer add, remove, or modify billing codes for the visit. Both billing codes linked to new orders and diagnosis updates will not show up in this billing section."
            />
          )}

          {currentUser.featureFlags.includes('UPSERT_CLAIM') ? (
            <BillingSummarySectionV2
              claim={getBillingSummaryDefaultValues({
                visit,
                defaultBillingProviderId,
                supervisingPractitioner: supervisingPractitionerQuery.data,
              })}
              isEditable={!visit.encounter.claim?.submittedAt}
            />
          ) : (
            <BillingSummarySection
              claim={
                claimContainsSupportingInfo
                  ? {
                      id: visit.encounter.claim.id,
                      placeOfService: visit.encounter.claim.placeOfService,
                      billingProviderId:
                        visit.encounter.claim.billingProviderId,
                      supervisingProviderId:
                        visit.encounter.claim.supervisingProviderId,
                      serviceFacilityId:
                        visit.encounter.claim.serviceFacilityId,
                      renderingProviderId:
                        visit.encounter.claim.renderingProviderId,
                      admissionDate: visit.encounter.claim.admissionDate,
                      dischargeDate: visit.encounter.claim.dischargeDate,
                    }
                  : undefined
              }
              currentFormInputValues={formMethods.getValues()?.input}
            />
          )}
          <VisitBillingSection
            encounterId={visit.encounter.id}
            chargeItems={chargeItemsForVisit}
            primaryDiagnosis={primaryDiagnosis}
            isEditable={!visit.encounter.claim?.submittedAt}
          />
          <OrderBillingSection />
          <AdditionalBillingSection
            encounterId={visit.encounter.id}
            chargeItems={additionalChargeItems}
            primaryDiagnosis={primaryDiagnosis}
            isEditable={!visit.encounter.claim?.submittedAt}
          />
        </StackView>
      </Form>

      <BillingOrdersWarningModal
        isOpen={billingWarningConfirmationState.isConfirming}
        onConfirm={billingWarningConfirmationState.confirm}
        onCancel={billingWarningConfirmationState.cancel}
        action="SUBMIT_BILLING_CODES"
      />

      <PendedOrdersWarningModal
        isOpen={pendedOrdersConfirmationState.isConfirming}
        onConfirm={pendedOrdersConfirmationState.confirm}
        onCancel={pendedOrdersConfirmationState.cancel}
        action="SUBMIT_BILLING_CODES"
      />

      <SubmitClaimLoadingModal loading={submittingClaim || submittingClaimV2} />
    </>
  )
}

export default BillingPage

export const getBillingSummaryDefaultValues = ({
  visit,
  defaultBillingProviderId,
  supervisingPractitioner,
}: {
  visit: FindPatientVisit['appointment']
  defaultBillingProviderId: string
  supervisingPractitioner: GetSupervisingProviderForAppointment
}): BillingSummaryFormProps['claim'] => {
  if (visit.encounter.claim) {
    return {
      placeOfService: visit.encounter.claim.placeOfService,
      billingProviderId: visit.encounter.claim.billingProviderId,
      supervisingProviderId: visit.encounter.claim.supervisingProviderId,
      serviceFacilityId: visit.encounter.claim.serviceFacilityId,
      renderingProviderId: visit.encounter.claim.renderingProviderId,
      admissionDate: visit.encounter.claim.admissionDate,
      dischargeDate: visit.encounter.claim.dischargeDate,
      box14Date: visit.encounter.claim.box14Date,
      box14Qualifier: visit.encounter.claim.box14Qualifier,
      box19Content: visit.encounter.claim.box19Content,
    }
  }

  return deriveDefaultValues({
    visit,
    defaultBillingProviderId,
    supervisingPractitioner,
  })
}

const deriveDefaultValues = ({
  visit,
  defaultBillingProviderId,
  supervisingPractitioner,
}: {
  visit: FindPatientVisit['appointment']
  defaultBillingProviderId: string
  supervisingPractitioner: GetSupervisingProviderForAppointment
}): BillingSummaryFormProps['claim'] => {
  const isNurseVisit = visit?.appointmentDefinitions.some(
    ({ type }) => type === 'NURSE'
  )
  const isTelehealthVisit = visit?.appointmentDefinitions?.some(({ type }) =>
    [
      'TELEMEDICINE_EM',
      'TELEMEDICINE_OTHER',
      'TELEMEDICINE_WC',
      'PHONE',
    ].includes(type)
  )

  const renderingProviderIsSchedulingOnly =
    visit?.practitioner?.practitionerClinicalDetail?.isSchedulingOnly
  const supervisingProviderId =
    supervisingPractitioner?.appointment
      ?.defaultSupervisingPractitionerClinicalDetail?.id
  const shouldDefaultSupervisingAsRendering =
    supervisingPractitioner?.appointment?.shouldDefaultSupervisingAsRendering
  const renderingProviderId =
    supervisingProviderId &&
    (shouldDefaultSupervisingAsRendering ||
      isNurseVisit ||
      renderingProviderIsSchedulingOnly)
      ? supervisingProviderId
      : visit?.practitioner?.practitionerClinicalDetail?.id

  const placeOfService = isTelehealthVisit
    ? 'TELEHEALTH_HOME'
    : visit?.location?.placeOfService

  return {
    renderingProviderId: renderingProviderId,
    supervisingProviderId: supervisingProviderId,
    serviceFacilityId: visit?.location?.id,
    billingProviderId: defaultBillingProviderId,
    placeOfService,
    admissionDate: null,
    dischargeDate: null,
    box14Date: null,
    box14Qualifier: null,
    box19Content: null,
  }
}
