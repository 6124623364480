import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  CreditCardIcon,
  Squares2X2Icon,
  IdentificationIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid'
import { sortBy } from 'lodash'
import {
  CoordinationOfBenefitsType,
  HealthPlanType,
  InsuranceCoveragePlanStatus,
} from 'types/graphql'

import StackView, { StackViewProps } from 'src/components/atoms/StackView'
import Typography from 'src/components/atoms/Typography'

const COORDINATION_OF_BENEFITS_ORDER: CoordinationOfBenefitsType[] = [
  'PRIMARY',
  'SECONDARY',
  'TERTIARY',
]

export const COORDINATION_OF_BENEFITS_DISPLAY: {
  [key in CoordinationOfBenefitsType]: string
} = {
  PRIMARY: 'Primary',
  SECONDARY: 'Secondary',
  TERTIARY: 'Tertiary',
}

export const HEALTH_PLAN_STATUS_ICONS: {
  [key in InsuranceCoveragePlanStatus]: React.FunctionComponent<
    React.ComponentProps<'svg'>
  >
} = {
  UNKNOWN: ExclamationTriangleIcon,
  INACTIVE: ExclamationCircleIcon,
  ACTIVE_COVERAGE: CheckCircleIcon,
}

export const HEALTH_PLAN_STATUS_COLORS: {
  [key in InsuranceCoveragePlanStatus]: string
} = {
  UNKNOWN: 'fill-base-color-bg-attention-emphasis',
  INACTIVE: 'fill-base-color-bg-danger-emphasis',
  ACTIVE_COVERAGE: 'fill-base-color-bg-success-emphasis',
}

export const HEALTH_PLAN_STATUS_DISPLAY: {
  [key in InsuranceCoveragePlanStatus]: string
} = {
  UNKNOWN: 'Unknown',
  INACTIVE: 'Not eligible',
  ACTIVE_COVERAGE: 'Eligible',
}

export const HEALTH_PLAN_TYPE_ICONS: {
  [key in HealthPlanType | 'UNKNOWN']: React.FunctionComponent<
    React.ComponentProps<'svg'>
  >
} = {
  COMMERCIAL: CreditCardIcon,
  MEDICAID: IdentificationIcon,
  MARKETPLACE: Squares2X2Icon,
  NON_MEDICAL: Squares2X2Icon,
  OTHER_MEDICAL: Squares2X2Icon,
  UNKNOWN: QuestionMarkCircleIcon,
}

export const HEALTH_PLAN_TYPE_DISPLAY: {
  [key in HealthPlanType | 'UNKNOWN']: string
} = {
  COMMERCIAL: 'Commercial',
  MEDICAID: 'Medicaid',
  MARKETPLACE: 'Marketplace',
  NON_MEDICAL: 'Non-medical',
  OTHER_MEDICAL: 'Other',
  UNKNOWN: 'Unknown plan type',
}

export const sortInsuranceCoverages = <
  T extends {
    coordinationOfBenefitsType: CoordinationOfBenefitsType
  },
>(
  insuranceCoverages: T[]
): T[] => {
  return sortBy(insuranceCoverages, [
    (i) => COORDINATION_OF_BENEFITS_ORDER.indexOf(i.coordinationOfBenefitsType),
  ])
}

export const getHealthPlanIcon = (
  status: InsuranceCoveragePlanStatus = 'UNKNOWN',
  size = 5
) => {
  if (!status) status = 'UNKNOWN'
  const Icon = HEALTH_PLAN_STATUS_ICONS[status]

  return (
    <Icon
      className={`h-${size} w-${size} flex-shrink-0 ${HEALTH_PLAN_STATUS_COLORS[status]}`}
    />
  )
}

export const InsuranceCoverageLineItem = ({
  coverage,
  iconSize,
  ...rest
}: {
  coverage: {
    payer: {
      displayName: string
    }
    mostRecentEligibility?: {
      status?: InsuranceCoveragePlanStatus
    }
  }
  iconSize?: number
} & Omit<StackViewProps, 'children'>) => {
  return (
    <StackView gap={50} className="whitespace-normal" direction="row" {...rest}>
      {getHealthPlanIcon(coverage.mostRecentEligibility?.status, iconSize)}
      <Typography>{coverage.payer.displayName}</Typography>
    </StackView>
  )
}
